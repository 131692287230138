import React from 'react';
import { FormSectionTitle } from '../Components/Typography/Headlines';
import { Typography } from '@material-ui/core';
import MeetingLocationsGrid from '../MeetingLocations/Components/MeetingLocationsGrid';
import AddressFields from '../Components/Inputs/AddressFields';
import ProfessionalPageWrapper from './Components/ProfessionalPageWrapper';
import { LOCATION_DISTANCE_CHOICES, LOCATION_CHANGE_DISCLAIMER } from '../constants';
import AddButton from '../Components/Buttons/AddButton';
import FormSelectInput from '../Components/Inputs/Form/FormSelectInput';

const MeetingLocationsComponent = props => (
    <div>
        <div>
            <FormSectionTitle title="Meeting locations" />
        </div>
        <Typography>
            Please add the locations you'd be happy to meet client from a choice of{' '}
            <b>'my office', 'client's home'</b> or <b>'other'.</b>
        </Typography>
        <MeetingLocationsGrid {...props} />
        <AddButton
            resource="meeting-locations"
            label="Add another location"
            to={{ pathname: '/meeting-locations/create' }}
        />
    </div>
);

const LocationChild = props => {
    return (
        <div>
            <FormSectionTitle title="Address" />
            <AddressFields />
            <FormSectionTitle title="Location" />
            <Typography variant="body1">
                Add the locations you will meet clients from and how far you're willing to travel
            </Typography>
            <br />
            <FormSelectInput
                label="Distance you're willing to travel"
                source="travel_distance"
                choices={LOCATION_DISTANCE_CHOICES}
            />
            <MeetingLocationsComponent {...props} />
        </div>
    );
};

const Location = props => {
    return (
        <ProfessionalPageWrapper {...props}>
            <LocationChild {...props} />
            <Typography variant="caption">{LOCATION_CHANGE_DISCLAIMER}</Typography>
        </ProfessionalPageWrapper>
    );
};

export default Location;
