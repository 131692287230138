import React from 'react';
import { useForm } from 'react-final-form';
import _ from 'lodash';
import cn from 'classnames';
import { SimpleForm, FormDataConsumer, SaveButton } from 'react-admin';
import FormToolbar from '../Components/Forms/FormToolbar';
import PageContent from '../AppLayout/PageContent';
import RegisterHero from './RegisterHero';
import OurStandardsList from './Components/OurStandardsList';
import RegisterReputationPowerSection from './Sections/RegisterReputationPowerSection';
import RegisterReputationToolsSection from './Sections/RegisterReputationToolsSection';
import RegisterTestimonialsSection from './Sections/RegisterTestimonialsSection';
import PasswordInput from '../Components/Inputs/PasswordInput';
import {
    RegisterTitleField,
    RegisterFirstNameField,
    RegisterLastNameField,
    RegisterVerticalField,
    RegisterEmailField,
    RegisterFinancialAdviceOffered,
    RegisterMortgageAdviceOffered,
    RegisterTermsCheckbox,
} from '../Components/Inputs/RegisterFields';
import {FA_INVESTMENT_ADVICE, MA_INVESTMENT_ADVICE, VERTICAL} from '../constants';
import { useRegisterUser } from '../state/hooks';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import * as analytics from '../analytics';
import VerifiedSection from "./Sections/VerifiedSection";
import PricingSection from "./Sections/PricingSection";
import ElevationSection from "./Sections/ElevationSection";

const useStyles = makeStyles(theme => ({
    container: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row-reverse',
            padding: `0 ${theme.spacing(2)}px`,
        },
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        top: `-${theme.spacing(15)}px`,
        right: 0,
        backgroundColor: theme.palette.secondary.main,
        textAlign: 'center',
        padding: `${theme.spacing(4)}px 0`,
        maxWidth: theme.spacing(61),
        margin: '0 auto',

        '& h2': {
            margin: 0,
        },

        [theme.breakpoints.only('xs')]: {
            top: `-${theme.spacing(5)}px`,
            margin: `0 ${theme.spacing(2)}px`,
        },
    },
    registerForm: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        flexDirection: 'column',
        marginTop: theme.spacing(2),

        '& > div:first-child': {
            padding: `0 ${theme.spacing(5)}px`,
        },

        '& > div:last-child': {
            display: 'none',
        },

        '& [data-shrink=true]': {
            color: theme.palette.primary.main,
        },

        [theme.breakpoints.down('sm')]: {
            width: '100%',

            '& > div:nth-child(2) > div': {
                background: 'none',
            },
        },

        [theme.breakpoints.down('xs')]: {
            '& > div:first-child': {
                padding: `0 ${theme.spacing(2)}px`,
            },
        },
    },
    registerInput: {
        margin: `4px 0 16px`,
        textAlign: 'left',
        maxWidth: '100%',

        '& > label': {
            fontSize: '16px',
            zIndex: 1,
            pointerEvents: 'none',
        },

        '& > div': {
            borderRadius: 4,
            backgroundColor: `${theme.palette.primary.contrastText} !important`,
            maxWidth: '100% !important',
            '&:hover': {
                backgroundColor: `${theme.palette.secondary.light} !important`,
            }
        },

        '& > p': {
            display: 'none',
        },
    },
    termsInput: {
        margin: 0,

        '& span': {
            fontWeight: 400,

            '& a': {
                fontWeight: 500,
                borderBottom: `1px solid ${theme.palette.primary.main}`,
            },
        },
    },
    radioInput: {
        '& label svg': {
            color: theme.palette.secondary.main,
        },
        '& > legend': {
            marginBottom: theme.spacing(1),
        },
        '& > div': {
            boxSizing: 'border-box',
            paddingLeft: 10,
        },
    },
    financialAdviserDropdowns: {
        '& > fieldset > div': {
            display: 'flex',
            flexDirection: 'column',
            padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        },
    },
    setUpFirmParagraph: {
        padding: `0 ${theme.spacing(5)}px`,
        textAlign: 'left',

        '& span': {
            fontWeight: 'bold',
        },

        [theme.breakpoints.down('xs')]: {
            padding: `0 ${theme.spacing(2)}px 0`,
        },
    },
    toolbar: {
        margin: `${theme.spacing(2)}px 0`,
        padding: `0 ${theme.spacing(5)}px 0`,

        [theme.breakpoints.down('xs')]: {
            padding: `0 ${theme.spacing(2)}px 0`,
        },
    },
    button: {
        width: '100%',
        height: theme.spacing(6),

        '&.Mui-disabled': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            opacity: 0.75,
        },
    },
    errorPasswordBorder: {
        '& > div > input': {
            borderColor: theme.palette.error.main,
        },
    },
}));

const RegisterFormToolbar = ({ loading, ...props }) => {
    const classes = useStyles();
    const form = useForm();
    const formValues = form.getState().values;
    const vertical = _.get(formValues, 'vertical');

    return (
        <FormToolbar className={classes.toolbar} {...props}>
            <SaveButton
                className={classes.button}
                disabled={loading}
                label="Apply now"
                onClick={() => {
                    analytics.track(vertical, { category: 'Registration' });
                }}
            />
        </FormToolbar>
    );
};

const Register = () => {
    const classes = useStyles();
    const [errors, loading, setPayload] = useRegisterUser();
    const passwordError = _.get(errors, 'plainPassword', false);

    const handleFinancialCoach = record => {
        record.vertical = VERTICAL.FINANCIAL_ADVISER;
        record.financial_advice = FA_INVESTMENT_ADVICE.FINANCIAL_COACH;
        record.mortgage_advice = MA_INVESTMENT_ADVICE.NONE;
    };

    const handleSave = record => {
        if (record.vertical === VERTICAL.FINANCIAL_COACH) {
            handleFinancialCoach(record);
        }
        setPayload(record);
    };

    const handleClick = (scrollTo) => element =>{
        const anchor = document.getElementById(scrollTo);
        window.scrollTo({
            top: anchor.offsetTop,
            left: 0,
            behavior: 'smooth',
        });
    };

    return (
        <PageContent>
            <RegisterHero
                title="Power your business with client feedback"
                subtitle="Financial Advisers | Mortgage Advisers | Accountants | Legal Advisers"
            />
            <div>
                <Grid container className={classes.container}>
                    <Grid item xs={12} sm={12} md={12} lg={4}>
                        <Card className={classes.card} id="register-form">
                            <Typography variant="h4" component="h2">
                                Start your application today
                            </Typography>
                            <SimpleForm
                                className={classes.registerForm}
                                save={handleSave}
                                toolbar={<RegisterFormToolbar loading={loading} />}
                            >
                                <RegisterTitleField fullWidth className={classes.registerInput} />
                                <RegisterFirstNameField
                                    fullWidth
                                    className={classes.registerInput}
                                />
                                <RegisterLastNameField
                                    fullWidth
                                    className={classes.registerInput}
                                />
                                <RegisterVerticalField
                                    fullWidth
                                    className={classes.registerInput}
                                />
                                <FormDataConsumer>
                                    {({ formData }) => {
                                        if (formData.vertical !== VERTICAL.FINANCIAL_ADVISER)
                                            return null;

                                        return (
                                            <div className={classes.financialAdviserDropdowns}>
                                                <RegisterFinancialAdviceOffered
                                                    className={cn(
                                                        classes.registerInput,
                                                        classes.radioInput
                                                    )}
                                                />
                                                <RegisterMortgageAdviceOffered
                                                    className={cn(
                                                        classes.registerInput,
                                                        classes.radioInput
                                                    )}
                                                />
                                            </div>
                                        );
                                    }}
                                </FormDataConsumer>
                                <RegisterEmailField className={classes.registerInput} />
                                <PasswordInput
                                    fullWidth
                                    className={cn(
                                        classes.registerInput,
                                        passwordError && classes.errorPasswordBorder
                                    )}
                                    hasError={passwordError}
                                />
                                <RegisterTermsCheckbox fullWidth className={classes.termsInput} />
                            </SimpleForm>
                            <Typography variant="body1" className={classes.setUpFirmParagraph}>
                                <span>Looking to set up your firm?</span> Simply email <a href="mailto:firms@vouchedfor.co.uk">firms@vouchedfor.co.uk</a>
                            </Typography>
                        </Card>
                    </Grid>

                    <Grid item md={12} lg={8}>
                        <OurStandardsList handleClick={handleClick}/>
                    </Grid>
                </Grid>
                <RegisterReputationToolsSection />
                <RegisterReputationPowerSection handleClick={handleClick}/>
                <VerifiedSection />
                <RegisterTestimonialsSection />
                <PricingSection />
                <ElevationSection />
            </div>
        </PageContent>
    );
};
export default Register;
