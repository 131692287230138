import { createReplaceVariable } from '../utils';

/**
 * TODO: Make keys all caps
 */
const API_KEYS = {
    api_url: createReplaceVariable('https://api.vouchedfor.co.uk') + '/v2/account',
    elevation_url: createReplaceVariable('https://elevation.vouchedfor.co.uk') +'/account',
    public_api_url: createReplaceVariable('https://api.vouchedfor.co.uk') + '/v2/public',
    accounts_url: createReplaceVariable('https://accounts.vouchedfor.co.uk'),
    consumer_url: createReplaceVariable('https://www.vouchedfor.co.uk'),
    login_url: createReplaceVariable('https://api.vouchedfor.co.uk') + '/v2/account/auth/login',
    cdn: createReplaceVariable('https://cdn.vouchedfor.co.uk'),
    professional: createReplaceVariable('https://professional.vouchedfor.co.uk') + '/v2',
    google_analytics_key: createReplaceVariable('UA-52740656-1'),
    mopinion_key: createReplaceVariable('ejdbsncszfd1ohrh870i6yz0134yqcsnmuk'),
    linkedin_pixel_key: createReplaceVariable('478506'),
    downgrade_form_url: createReplaceVariable('https://processmaker-admin-vf.vouchedfor.co.uk/sysworkflow/en/cat/6482588825e5e78fb815873091833654/1162068505e5e78fc392ef5069375512.php'),
    survey_api_url: createReplaceVariable('https://survey-api.vouchedfor.co.uk') + '/submission',
    roma_url: createReplaceVariable('https://roma.vouchedfor.co.uk'),
    vf_2023_widget_launch_date: createReplaceVariable('2023-03-08'),
    microsoft_clarity: createReplaceVariable('h4dcmdws0k'),
    gtag_key: createReplaceVariable('G-5E6ZYDLL5Z'),
    mixpanel_project_token: createReplaceVariable('98a5fa87f8195e724b5d29cc350b75e8'),
};

export const INTEGRATION_KEYS = {
    sentry_dsn: createReplaceVariable('https://164d8d6e037f4f44ad49879d2daf1823@o89905.ingest.sentry.io/4504914947670016'),
    sentry_env: createReplaceVariable('prod')
}

export default API_KEYS;
