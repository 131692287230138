import React, { useState } from 'react';
import DialogBase from '../Components/Dialogs/DialogBase';
import { makeStyles } from '@material-ui/core/styles';
import DowngradeForm from './DowngradeForm';

const useStyles = makeStyles({
    dialog: {
        '& > .MuiDialogActions-root': {
            display: 'none'
        },
    },
});

const DowngradeDialogModal = props => {
    const classes = useStyles();
    const { open, setOpen } = props
    const [ canClose, setCanClose ] = useState(true)

    const handleClose = async () => {
        setOpen(false)
    };

    return (
        <>
            <DialogBase
                modalWidth={680}
                open={open}
                message={<DowngradeForm changeCanClose={(cls) => setCanClose(cls)} close={() => setOpen(false)} />}
                onClose={handleClose}
                hideClose={!canClose}
                className={classes.dialog}
            />
        </>
    );
};

export default DowngradeDialogModal;
