import React, { useState } from 'react';
import { useForm, FormSpy } from 'react-final-form';
import { useDispatch } from 'react-redux';
import {
    SimpleForm,
    useTranslate,
    useNotify,
    useMutation,
    refreshView,
} from 'react-admin';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconSave from '@material-ui/icons/Save';
import Button from '../Buttons/CustomButton';

const useStyles = makeStyles(() => ({
    dialogContent: {
        '& .MuiFormControl-root': {
            maxWidth: 'initial',
            padding: 0,
        },
    },
    dialog: {
        '& .MuiPaper-root': {
            padding: 0,
            margin: 0,
        },
        '& .MuiDialogTitle-root': {
            paddingTop: 0,
            paddingBottom: 0,

        },
    },
    dialogTitle: {
        '& .MuiDialogTitle-root': {
            margin: 0,
            padding: 0
        },
    },
}));

const FormSubmitButton = ({
                              record = {},
                              type = 'update',
                              resource = '',
                              label = 'save',
                              startIcon = <IconSave/>,
                              mutatePayload = null,
                              refresh = true,
                              invalidForm = false,
                              handleSuccess = () => {
                              },
                              handleFailure = () => {
                              },
                              handleFinish = () => {
                              },
                          }) => {
    const [loading, setLoading] = useState(false);
    const [mutate] = useMutation();
    const notify = useNotify();
    const dispatch = useDispatch();
    const form = useForm();

    const handleSubmit = () => {
        if (!resource) {
            return;
        }

        setLoading(true);

        mutate({
            type,
            resource,
            payload: getQueryPayload(),
        }, {
            onSuccess: () => {
                handleSuccess();

                form.reset();

                onRequestEnd();
            },
            onFailure: (error) => {
                handleFailure(error);

                notify(error.message, 'error');

                onRequestEnd();
            },
        });
    };

    const onRequestEnd = () => {
        setLoading(false);

        if (refresh) {
            dispatch(refreshView());
        }
        handleFinish();
    };

    const getQueryPayload = () => {
        const formValues = form.getState().values;
        const payload = {};

        if (resource === 'firm') {
            payload.id = record.id;
        }

        payload.data = {
            'file': formValues.file
        };

        return payload;
    };

    return (
        <Button
            disabled={invalidForm || loading}
            onClick={handleSubmit}
            startIcon={startIcon}
            color="tertiary"
            variant="contained"
        >
            {label}
        </Button>
    );
};

const DialogButtonContent = ({
                                 form,
                                 resource,
                                 type,
                                 record,
                                 popupButtonSaveLabel,
                                 popupButtonSaveIcon,
                                 mutatePayload,
                                 refresh = true,
                                 disableSave = false,
                                 setShowDialog = () => {
                                 },
                                 handleSuccess = () => {
                                 },
                                 handleFailure = () => {
                                 },
                                 handleFinish = () => {
                                 },
                             }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const [invalidForm, setInvalidForm] = useState(false);

    return (
        <>
            <FormSpy
                subscription={{ invalid: true }}
                onChange={({ invalid }) => setInvalidForm(invalid)}
            />
            <DialogContent className={classes.dialogContent}>
                {form}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowDialog(false)}>
                    {translate('ra.action.cancel')}
                </Button>
                <FormSubmitButton
                    type={type}
                    resource={resource}
                    startIcon={popupButtonSaveIcon}
                    mutatePayload={mutatePayload}
                    record={record}
                    handleSuccess={() => {
                        setShowDialog(false);

                        handleSuccess();
                    }}
                    handleFailure={handleFailure}
                    handleFinish={handleFinish}
                    refresh={refresh}
                    label={popupButtonSaveLabel}
                    invalidForm={disableSave || invalidForm}
                />
            </DialogActions>
        </>
    );
};

const DialogButton = (props) => {
    const [showDialog, setShowDialog] = useState(false);
    const {
        name,
        icon,
        buttonColour = 'secondaru',
        useSimpleForm = false,
        shouldShowButton = true,
        buttonVariant = 'outlined',
    } = props;
    const contentProps = {
        ...props,
        setShowDialog,
    };

    const classes = useStyles();

    if (!shouldShowButton) {
        return null;
    }

    return (
        <>
            <Button
                color={buttonColour}
                onClick={() => setShowDialog(true)}
                startIcon={icon}
                size="medium"
                variant={buttonVariant}
                style={{ margin: 8, marginLeft: 0 }}
            >
                {name}
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={() => setShowDialog(false)}
                aria-label={name}
                className={classes.dialog}
            >
                <DialogTitle className={classes.dialogTitle}>{name}</DialogTitle>
                {
                    useSimpleForm ?
                        <SimpleForm toolbar={null}>
                            <div style={{ width: 'auto' }}>
                                <DialogButtonContent {...contentProps} />
                            </div>
                        </SimpleForm>
                        : <DialogButtonContent {...contentProps} />
                }
            </Dialog>
        </>
    );
};

export default DialogButton;
