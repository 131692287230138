import React from 'react';
import _ from 'lodash';
import { List, Responsive, Datagrid, TextField, DateField } from 'react-admin';
import ReferralPageWrapper from './Components/ReferralPageWrapper';

import SimpleList from '../Components/Lists/SimpleList';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const CustomerReferralListWrapper = props => (
    <div>
        <CardContent>
            <Datagrid {...props}>
                <DateField label="Date sent" source="created_at" />
                <TextField label="Client name" source="customer.name" sortable={false} />
                <TextField label="Client email" source="customer.email" sortable={false} />
            </Datagrid>
        </CardContent>
    </div>
);

const CustomerReferralList = props => {
    return (
        <ReferralPageWrapper variant="edit" {...props} id="no-id">
            <div>
                <Typography variant="h5" color="secondary">
                    See who you have sent referral requests to already
                </Typography>
                <Typography variant="body1">
                    Note: you can select “all new reviewers” from the dropdown on the ‘send referral
                    requests’ page and it will only send to new reviewers since you last sent
                    referral requests!
                </Typography>
                <List
                    {...props}
                    title={null}
                    actions={null}
                    bulkActionButtons={false}
                    sort={{ field: 'created_at', order: 'DESC' }}
                >
                    <Responsive
                        medium={<CustomerReferralListWrapper />}
                        small={
                            <SimpleList
                                primaryText={record =>
                                    `Customer Name: ${_.get(record, ['customer', 'name'])}`
                                }
                                secondaryText={record =>
                                    `Customer Email: ${_.get(record, ['customer', 'email'])}`
                                }
                            />
                        }
                    />
                </List>
            </div>
        </ReferralPageWrapper>
    );
};

export default CustomerReferralList;
