import React from 'react';
import { Typography } from '@material-ui/core';
import { FormSectionTitle } from '../Components/Typography/Headlines';
import ProfessionalPageWrapper from './Components/ProfessionalPageWrapper';import FormLongTextInput from '../Components/Inputs/Form/FormLongTextInput';

const DisclaimerChild = () => {
    return (
        <div>
            <FormSectionTitle title="Disclaimer" />
            <Typography gutterBottom>
                Add a disclaimer to your profile - this will show at the bottom
                of the page
            </Typography>
            <FormLongTextInput label="Disclaimer" source="disclaimer" />
        </div>
    );
};

const Disclaimer = props => {
    return (
        <ProfessionalPageWrapper {...props}>
            <DisclaimerChild {...props} />
        </ProfessionalPageWrapper>
    );
};

export default Disclaimer;
