import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(2),
    },
}));

const EmailSubject = ({ content, subject = true }) => {
    const classes = useStyles();

    return (
        <Typography className={classes.root}>
            <b>{subject ? `Subject: ` : ''}</b>
            {content}
        </Typography>
    );
};

export default EmailSubject;
