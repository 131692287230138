import React from 'react';
import ProfessionalPageActions from './ProfessionalPageActions';
import ProfessionalTabs from './ProfessionalTabs';

const ProfessionalPageHeader = () => {
    return (
        <>
            <ProfessionalPageActions title="Profile" />
            <ProfessionalTabs />
        </>
    );
};

export default ProfessionalPageHeader;
