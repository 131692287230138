export const ICONS = {
    // ':waving_hand:': '👋 &nbsp;',
    // ':clap_hands:': '👏 &nbsp;',
    // ':hands_up:': '🙌 &nbsp;',
    ':waving_hand:': '',
    ':clap_hands:': '',
    ':hands_up:': '',
    ':tick:': `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"  class='tick'>
        <path class="st0" d="M12.2,0.4C9.1,3.7,7.3,7.7,6.3,10.6C5.5,9,4.4,7,3.6,5.7C3.2,5,2.3,4.9,1.6,5.3C1,5.8,0.8,6.6,1.2,7.3
            c1.6,2.5,4.2,7.8,4.2,7.9C5.7,15.7,6.2,16,6.7,16c0.1,0,0.1,0,0.2,0c0.6-0.1,1.1-0.5,1.2-1.1c0-0.1,1.4-7.5,6.2-12.4
            c0.5-0.6,0.5-1.5,0-2C14,0.1,13.6,0,13.3,0C12.9,0,12.5,0.1,12.2,0.4" />
        </svg>`,
    // ':party_popper:': '🎉',
    ':party_popper:': '',
    ':raising_hands:' : '🙌',
    ':envelope:' : '✉️'

};
