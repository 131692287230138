import React from 'react';
import { connect } from 'react-redux';
import DashboardListCard from './DashboardListCard';
import { setDashboardInfluencedCount } from '../../../state/actions';

const DashboardInfluencedCard = ({ setDashboardInfluencedCount }) => (
    <DashboardListCard
        resource="customers/potential-clients"
        title="Latest influenced"
        renderSubTitle={() => {
            return 'See which of your clients said your reviews helped them decide to work with you';
        }}
        id="latest-influenced-card"
        onFinishLoading={count => {
            setDashboardInfluencedCount(count);
        }}
    />
);

export default connect(
    null,
    { setDashboardInfluencedCount }
)(DashboardInfluencedCard);
