import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {Card, CardActions, CardContent, Typography, makeStyles} from '@material-ui/core';
import FootNote from '../../Components/Typography/FootNote';
import Grid from '@material-ui/core/Grid';

const propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    content: PropTypes.any,
    buttons: PropTypes.any,
};

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.secondary.midDarkBlue,
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        color: theme.palette.primary.contrastText,
    },
    content: {
        padding: '0 !important',
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    actions: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        marginTop: theme.spacing(2),
        padding: 0,
    },
    buttons: {
        margin: 0,
    },
    marginBottom: {
        marginBottom: theme.spacing(2),
    },
    footNote: {
        color: theme.palette.primary.contrastText,
    },
    bannerImage: {
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
    },
}));

const OnboardingBannerBase = (props) => {
    const { title, content, buttons, note, bannerImage } = props;
    const classes  = useStyles();
    const showCardActions = Boolean(buttons) || Boolean(note);

    return (
        <Card className={classes.root}>
            <Grid container spacing={1}>
                <Grid item md={ bannerImage ? 6 : 12} lg={bannerImage ? 6 : 12} sm={12} style={bannerImage && { justifyContent: "center",display: "flex", flexDirection: "column"}}>
                    <CardContent className={classes.content}>
                        <Typography className={classes.title} color="secondary" component="h3">
                            <b>{ title }</b>
                        </Typography>
                        { content }
                    </CardContent>
                    {
                        showCardActions &&
                        <CardActions className={classes.actions}>
                            {
                                Boolean(buttons) &&
                                <div className={cn({ [classes.buttons]: true, [classes.marginBottom]: Boolean(note) })}>
                                    { buttons }
                                </div>
                            }
                            {
                                Boolean(note) &&
                                <FootNote content={note} className={classes.footNote} />
                            }
                        </CardActions>
                    }
                </Grid>
                {
                    bannerImage &&
                    <Grid item md={6} lg={6} sm={12} className={classes.bannerImage}>
                        <img src={bannerImage}/>
                    </Grid>
                }
            </Grid>
        </Card>
    );
};

OnboardingBannerBase.propTypes = propTypes;

export default OnboardingBannerBase;
