import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReviewStats from './ReviewStats';
import ReferralStats from './ReferralStats';
import VisitStats from './VisitStats';
import InfluencedStats from './InfluencedStats';
import EmptyData from './EmptyData';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
    },
}));

const DashboardData = props => {
    const classes = useStyles();
    const { gka } = props;

    const renderDashboarData = () => {
        switch (gka) {
            case 'reviews':
                return <ReviewStats {...props} />;
            case 'referrers':
                return <ReferralStats {...props} />;
            case 'visits':
                return <VisitStats {...props} />;
            case 'influences':
                return <InfluencedStats {...props} />;
            default:
                return <EmptyData />;
        }
    };

    return <div className={classes.root}>{renderDashboarData()}</div>;
};

export default DashboardData;
