import React from 'react';
import { connect } from 'react-redux';
import { TopToolbar } from 'react-admin';
import BackButton from '../../Components/Buttons/BackButton';
import ErrorButton from '../../Components/Buttons/ErrorButton';

const EnquiriesReportActions = props => {
    const { id, resource, professionalId } = props;
    const enquiriesUrl = `/enquiries-list/${professionalId}`;

    return (
        <TopToolbar>
            <BackButton href={enquiriesUrl} />
            {resource !== 'enquiry/report' && (
                <ErrorButton color="primary" href={`/enquiry/report/${id}`}>
                    Report
                </ErrorButton>
            )}
        </TopToolbar>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        professionalId: basicInfo.id,
    };
};

export default connect(mapStateToProps)(EnquiriesReportActions);
