import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userLogin } from 'react-admin';
import MyLayout from '../MyLayout';
import MyTheme from '../MyTheme';
import LoginForm from './LoginForm';
import PageContent from '../AppLayout/PageContent';
import { CONTACT } from '../constants';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Button from "../Components/Buttons/CustomButton";
import { createReplaceVariable } from '../utils';

const styles = theme => ({
    formContainer: {
        background: MyTheme.palette.secondary.light,

        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(5),
        },
    },
    points: {
        margin: `0 auto ${theme.spacing(5)}px auto`,
    },
    registerLink: {
        textDecoration: 'none',
    },
    secondSection: {
        margin: `0 auto ${theme.spacing(2)}px auto`,
        textAlign: 'center',

    },
});

class Login extends Component {
    componentDidMount() {
        localStorage.clear();
    }

    handleSubmit = record => {
        this.props.userLogin({
            username: record.email,
            password: record.password,
        }, "/");
    };

    render() {
        const { classes, width, loading } = this.props;
        const sectionProps = {
            direction: isWidthDown('sm', width) ? 'row' : 'column',
            justify: isWidthDown('sm', width) ? 'flex-start' : 'center',
        };

        return (
            <MyLayout theme={MyTheme}>
                <PageContent>
                    <Grid container className={classes.formContainer}>
                        <Grid
                            container
                            item
                            sm={12}
                            md={12}
                            justify={'center'}
                        >
                            <LoginForm
                                handleSubmit={this.handleSubmit}
                                loading={loading}
                            />
                        </Grid>
                    </Grid>
                    <div className={classes.secondSection}>
                        <div className={classes.points}>
                            <Typography variant="body1">
                               <h3><b>Are you managing a firm?</b></h3>
                            </Typography>
                            <Button
                                variant="contained"
                                color="tertiary"
                                href= {createReplaceVariable('https://firms.vouchedfor.co.uk') + '/login'}
                                className={classes.button}
                            >
                                Log in to your firm portal
                            </Button>
                        </div>

                        <Typography variant="body1">
                            To log in, you need to use a modern browser: the latest versions of
                        </Typography>
                        <Typography variant="body1">
                            Chrome, Firefox, Safari, Edge are supported
                        </Typography>
                        <Typography variant="body1">
                            <b>Need help?</b> Call us on {CONTACT.PHONE_NUMBER}
                        </Typography>
                    </div>
                </PageContent>
            </MyLayout>
        );
    }
}

const mapStateToProps = ({ admin }) => {
    return {
        loading: admin.loading,
    };
};

export default connect(
    mapStateToProps,
    { userLogin }
)(withStyles(styles)(withWidth()(Login)));
