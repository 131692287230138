import React from 'react';
import PageActions from '../../AppLayout/PageActions';
import ReferralsTabs from './ReferralsTabs';

const ReferralPageHeader = () => {
    return (
        <>
            <PageActions title="Referrals" />
            <ReferralsTabs />
        </>
    );
};

export default ReferralPageHeader;
