import React from 'react'
import { Fragment } from 'react';
import { SimpleForm, required, FormDataConsumer, RadioButtonGroupInput, CheckboxGroupInput } from 'react-admin';
import FormLongTextInput from '../../Components/Inputs/Form/FormLongTextInput';
import { Typography } from '@material-ui/core';
import FormToolbar from '../../Components/Toolbars/FormToolbar';
import withStyles from '@material-ui/core/styles/withStyles';
import { texts } from './ReviewReport'
import { ExternalLink } from '../../Components/Links'
const styles = theme => ({
    wrapper: {
        '& .MuiTypography-body1': {
            fontSize: "14px",
        },
        '& .pro-support-link': {
            color: theme.palette.secondary.main,
            textDecoration: 'none'
        },
    },
    form: {
        '& > div': {
            padding: 0
        },

        '& legend': {
            display: 'none !important',
        },
    },
    formRadioSelect: {
        '& .MuiFormGroup-row': {
            flexDirection: 'column'
        },
        '& .Mui-checked': {
            '& .MuiIconButton-label': {
                color: theme.palette.secondary.main,
            },
        },


    },
    formCheckbox: {
        '& .Mui-checked': {
            '& .MuiIconButton-label': {
                color: theme.palette.secondary.main,
            },
        },
    },
    formToolbar: {
        marginTop: 0,
        display: 'flex',
        justifyContent: 'center',
        '& .MuiButton-contained': {
            width: "50%",
        },
        spacer: {
            display: "none"
        }

    },
});

const ReviewReportForm = ({
    reportType,
    classes,
    save = () => {}
}) => {

    return (
        <div className={classes.wrapper}>
            <SimpleForm
                className={classes.form}
                save={save}
                toolbar={<FormToolbar label="Submit" className={classes.formToolbar} />}
            >
                <RadioButtonGroupInput
                    className={classes.formRadioSelect}
                    source='report_reason'
                    validate={[required('Please select a reason for reporting this review')]}
                    choices={texts[reportType].selectList}
                />
                <FormDataConsumer>
                    {({ formData }) => (
                        formData.report_reason !== 'other' && (
                            <Fragment>
                                <Typography>Please enter any relevant comments</Typography>
                                <FormLongTextInput
                                    label=''
                                    source="report_comment" rows="5" />
                            </Fragment>

                        )
                    )}
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData }) => (
                        formData.report_reason === 'other' && (
                            <Fragment>
                                <Typography>Please read a full list of reasons  <ExternalLink href="https://support.vouchedfor.co.uk/article/76-when-would-vouchedfor-remove-a-review">we would remove a review</ExternalLink> on our support website</Typography>
                                <CheckboxGroupInput
                                    className={classes.formCheckbox}
                                    source="read_review_remove_terms"
                                    choices={[{ id: 'read_review_remove_terms', name: 'I have read the above guidance and wish to continue reporting this review' }]}
                                    validate={[required("Please confirm that you've read the above guidance")]}
                                />
                            </Fragment>

                        )
                    )}
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData }) => (
                        formData.read_review_remove_terms && (
                            <Fragment>
                                
                                <Typography><strong>One last step</strong> - Please read our guidance on <ExternalLink href="https://support.vouchedfor.co.uk/article/269-what-should-i-do-if-i-get-a-negative-review">publicly replying to a negative review</ExternalLink></Typography>
                                <CheckboxGroupInput
                                    className={classes.formCheckbox}
                                    source="read_reply_negative_review_terms"
                                    choices={[{ id: 'read_reply_negative_review_terms', name: 'I have read the above guidance and wish to continue reporting this review' }]}
                                    validate={[required("Please confirm that you've read the above guidance")]}
                                />
                            </Fragment>
                        ))}
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData }) => {
                        return (
                            formData.read_reply_negative_review_terms && (
                                <Fragment>
                                    <Typography>Please enter the reason you would like to report this</Typography>
                                    <FormLongTextInput
                                        validate={[required("Please enter the reason you would like to report this")]}
                                        label=''
                                        source="report_other_comment" rows="5" />
                                </Fragment>
                            )
                        )
                    }}
                </FormDataConsumer>

            </SimpleForm>
        </div>

    );
};


export default withStyles(styles)(ReviewReportForm);

