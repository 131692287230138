import React from 'react';
import _ from 'lodash';
import { makeStyles, Typography, Grid, Button } from '@material-ui/core';
import { ExternalLink } from '../../../Components/Links';

const useStyles = makeStyles(theme => ({
    group: {},
    sigList: {
        width: 'auto',
        margin: 'auto',
    },
    sigItem: {
        display: 'flex',
        alignItems: 'center',
    },
    sigLink: {
        display: 'flex',
    },
    sigImage: {
        width: '100%',
    },
}));

const EmailSignatureGroup = ({ onSignatureClick, name = '', list = [] }) => {
    const classes = useStyles();

    return (
        <div className={classes.group}>
            {name && (
                <Typography>
                    <b>{_.startCase(name)}</b>
                </Typography>
            )}
            <Grid container spacing={2} className={classes.sigList}>
                {list.map(item => (
                    <Grid item md={4} className={classes.sigItem}>
                        <Button>
                            <ExternalLink
                                className={classes.sigLink}
                                href={item.image_url}
                                onClick={onSignatureClick(item)}
                            >
                                <img className={classes.sigImage} src={item.image_url} alt="" />
                            </ExternalLink>
                        </Button>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default EmailSignatureGroup;
