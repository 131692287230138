import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { FormDataConsumer, required } from 'react-admin';
import InviteSubmitButton from "../Content/InviteSubmitButton";
import ReviewInviteEmailTemplate from '../Content/ReviewInviteEmailTemplate';
import { emailInputValiation } from '../../validation';
import ReviewInviteProspectInfo from "../Content/ReviewInviteProspectInfo";
import FormLongTextInput from "../../Components/Inputs/Form/FormLongTextInput";
import Typography from '@material-ui/core/Typography';
import { useSetReviewInvitationTemplateCopy } from "../../state/hooks";
import StepsTitle from '../Content/StepsTitle'
import DisclaimerCheckbox from "../Content/DisclaimerCheckbox";
import ReviewInviteMultipleFields from "../Content/ReviewInviteMultipleFields";
import ReviewInviteRecipientFields from "../Content/ReviewInviteRecipientFields";
import ElevationPlanCheckbox from '../Content/ElevationPlanCheckbox';
import { hasApplicationFeature } from '../../utils';
import { FEATURE_ELEVATION_CHECKBOX } from '../../constants';

const ReviewImportTestimonialEmailTemplate = ({ inviteType }) => {
    useSetReviewInvitationTemplateCopy(inviteType);

    return <ReviewInviteEmailTemplate />;
};

const initialState = {
    allEmailsValid: false,
    grid: [],
    isFormValid: false,
    invalidEmails: [],
    isBoxChecked: false,
    isElevationBoxChecked: false,
}

const ReviewInviteFormContent = ({inviteType, invalidForm, activeType, professionalEmail}) => {

    const [formState, setFormState] = useState(initialState);
    const [resetGrid, setResetGrid] = useState(false);

    useEffect(() => {
        if(resetGrid) setResetGrid(false);
        validateForm();
        }
    , [formState.isBoxChecked, formState.allEmailsValid,resetGrid]);

    useEffect(() => {
        setFormState(initialState)
        setResetGrid(true)
    },[activeType])

    const validateForm = () => {
        activeType === 'testimonial' ? setFormState({...formState, isFormValid: formState.isBoxChecked }) : setFormState({...formState, isFormValid: formState.isBoxChecked && formState.allEmailsValid });
    };

    const handleError = emails => {
        setFormState({...formState, invalidEmails: emails , isBoxChecked: false, isElevationBoxChecked: false});
    };

    const onRowChange = grid => {
        const allEmailsValid = Boolean(grid)
            ? grid.every(row => Boolean(row.client_email) && emailInputValiation(row.client_email))
            : false;
        setFormState({...formState, allEmailsValid, grid: Boolean(grid) ? grid : [] });
    };

    const onCheckboxChange = () => {
        setFormState({...formState, isBoxChecked: !formState.isBoxChecked});
    };

    const onElevationCheckBoxChange = () => {
        setFormState({...formState, isElevationBoxChecked: !formState.isElevationBoxChecked});
    }

    const onSuccess = () => {
        setFormState(initialState);
        setResetGrid(true);
        if(inviteType === 'testimonial') {
            setTimeout(() => {  window.location.reload(); }, 1000);
        }
    };

    return (
        <>
            {inviteType === 'first_impression' && <ReviewInviteProspectInfo/>}
            <StepsTitle title={'Step 2: Who would you like to invite?'}/>
            {inviteType !== 'testimonial' &&
            <Typography variant="body1" color="textPrimary">
                Invite one person or copy and paste a list in using CTRL+V
            </Typography>
            }
            {inviteType === 'testimonial' ? (
                    <>
                        <ReviewInviteRecipientFields />
                        <FormLongTextInput
                        source="testimonial"
                        label="Enter your client's review here"
                        validate={[required()]}
                        />
                    </>
                ) :
                (
                    <ReviewInviteMultipleFields
                        onRowChange={onRowChange}
                        resetGrid={resetGrid}
                        allEmailsValid={formState.allEmailsValid}
                        grid={formState.grid}
                    />
                )}
            <StepsTitle title={'Step 3: Customise the email you would like to send'}/>
            <ReviewImportTestimonialEmailTemplate inviteType={inviteType}/>
            <Typography variant="body1" color="secondary">
                Replies to the review invite email will be sent to {professionalEmail}
            </Typography>
            <StepsTitle title={'Step 4: Confirmation'}/>
            <Typography>Please note that we conduct rigorous checks on all reviews to verify their authenticity. This includes spot checks where we may ask you to provide evidence that the reviewer is a client or prospect of yours.</Typography>
            <DisclaimerCheckbox handleChange={onCheckboxChange} value={formState.isBoxChecked}/>
            {hasApplicationFeature(FEATURE_ELEVATION_CHECKBOX) && <ElevationPlanCheckbox handleChange={onElevationCheckBoxChange} value={formState.isElevationBoxChecked}/>}
            <FormDataConsumer>
                {({formData}) => {
                    const record = {
                        clients: inviteType === 'testimonial' ?
                            [{
                                client_first_name: _.get(formData, 'client_first_name'),
                                client_email: _.get(formData, 'client_email'),
                                type: 'review',
                                testimonial: _.get(formData, 'testimonial'),
                            }] : formState.grid.map(row => ({type: inviteType, ...row})),
                        email_template: formData.email_template,
                        is_elevation_checked: hasApplicationFeature(FEATURE_ELEVATION_CHECKBOX) ? formState.isElevationBoxChecked : null,
                    };

                    return (
                        <InviteSubmitButton
                            buttonLabel="Send Invites"
                            values={record}
                            invalidForm={!formState.isFormValid || invalidForm}
                            handleError={handleError}
                            onSuccess={onSuccess}
                        />
                    );
                }}
            </FormDataConsumer>
            {!_.isEmpty(formState.invalidEmails) && (
                <div>
                    <Typography variant="body1" color="error">
                        The following invitations did NOT send due to an error with the email
                        address:
                    </Typography>
                    {formState.invalidEmails.map(email => (
                        <Typography key={email} variant="body1" color="error">
                            {email}
                        </Typography>
                    ))}
                </div>
            )}
        </>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        professionalEmail: _.get(basicInfo, 'professional_email')
    };
};

export default connect(mapStateToProps)(ReviewInviteFormContent);
