import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Form } from 'react-formio';
import { useDataProvider, useNotify } from 'react-admin';
import { SURVEY_DOWNGRADE_GKA } from '../constants';
import '../styles/formioCustomStyles.css';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
    formWrapper: {
        '& .alert.alert-success': {
            display: 'none',
        },
    },
    formWrapperNoSpinner: {
        '& .fa-spin': {
            display: 'none',
        },
        '& .alert.alert-success': {
            display: 'none',
        },
    },
}));

const DowngradeForm = props => {
    const professionalId = useSelector(({ basicInfo }) => basicInfo.id);
    const [formData, setFormData] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const dataProvider = useDataProvider();

    useEffect(() => {
        (async () => {
            const res = await dataProvider.getOne('survey', SURVEY_DOWNGRADE_GKA);
            const form = JSON.parse(res.data.form)['formData'];
            setFormData(form);
        })();
    }, [dataProvider]);

    useEffect(() => {
        setErrorMessage('');
        setSuccessMessage('');
        setLoading(false);
    }, [props]);

    useEffect(() => {
        props.changeCanClose(!loading)
    }, [loading]);

    const handleSubmit = async form => {
        setLoading(true)

        try {
            await dataProvider('CREATE', 'survey', {
                data: {
                    values: { ...form.data, professionalId: professionalId, completed: true },
                    gka: SURVEY_DOWNGRADE_GKA,
                },
            });
            let message = '';
            if (form.data.pauseYourVouchedForMembership) {
                message =
                    'Thank you. Your request has been sent to our team to action within 3 days. We’ll be in touch when your membership is due to resume and talk you through your next steps.';
            } else {
                message =
                    'Thank you. Your request has been sent to our team to action within 3 days. Thank you for being a valued VouchedFor member & we’re here if you ever need us in the future.';
            }
            setErrorMessage('');
            setSuccessMessage(message);
            setLoading(false)
        } catch (e) {
            const res = {...e};
            const errMessage = res?.body?.data?.message;
            setSuccessMessage('');
            setLoading(false)
            setErrorMessage(errMessage ? errMessage : 'Downgrade request failed');
        }
    };

    return (
        <>
            {!successMessage ? (
                <div className={!loading ? `${classes.formWrapperNoSpinner} downgrade` : `${classes.formWrapper} downgrade`}>
                    <Form form={formData} onSubmit={handleSubmit} />
                    {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : null}
                </div>
            ) : (
                <Alert severity="success">{successMessage}</Alert>
            )}
        </>
    );
};

export default DowngradeForm;
