import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from "@material-ui/core";

const VERIFIED_POINTS = [
    {
        item: `1`,
        boldText: `Start your application today for free`,
        text: `Begin building your profile and reviews.`,
    },
    {
        item: `2`,
        boldText: `Pass our checks`,
        text: `We’ll confirm that you hold the necessary regulatory permissions and qualifications to practise in your chosen areas. If you have advanced qualifications and specialisms, we’ll highlight these on your profile.`,
    },
    {
        item: `3`,
        boldText: `Receive at least 2 client reviews`,
        text: `We rigorously check reviews to ensure they’re authentic.`,
    },
];

const useStyles = makeStyles(theme => ({
    section: {
        textAlign: 'center',
        padding: `${theme.spacing(10)}px ${theme.spacing(8)}px`,
        backgroundColor: theme.palette.secondary.light,

        [theme.breakpoints.down('sm')]: {
            padding: `${theme.spacing(5)}px ${theme.spacing(2)}px`,
        },
    },
    contentWrapper: {
        maxWidth: 1200,
        width: '100%',
        margin: '0 auto',
    },
    content: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.primary.main,
    },
    verifiedOptions: {
        marginTop: theme.spacing(5),
    },
    verifiedItem: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    itemNumberWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `2.5px solid ${theme.palette.primary.main}`,
        borderRadius: '50%',
        width: '35px',
        height: '35px',
        marginTop: '3px',
        marginLeft: 1,
        '& > p': {
            color: theme.palette.primary.main,
            fontWeight: 800,
            fontSize: '19px',
        },
    },
}));

const VerifiedSection = () => {
    const classes = useStyles();

    return (
        <section className={classes.section}>
            <div className={classes.contentWrapper}>
                <div className={classes.content}>
                    <Typography component="h2" color="inherit" variant="h4">
                        How do I become a Verified member?
                    </Typography>
                    <Grid container className={classes.verifiedOptions}>
                        {
                            VERIFIED_POINTS.map(verified =>
                                <Grid item md={4} sm={12} xs={12} className={classes.verifiedItem}>
                                    <div className={classes.itemNumberWrapper}>
                                        <Typography>{verified.item}</Typography>
                                    </div>
                                    <Typography variant="body1" color="inherit">
                                        <b>{verified.boldText}</b>
                                    </Typography>
                                    <Typography variant="body1" color="inherit">
                                        {verified.text}
                                    </Typography>
                                </Grid>
                            )
                        }
                    </Grid>
                </div>
            </div>
        </section>
    );
};

export default VerifiedSection;
