import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.tertiary.contrastText,
        '&.MuiPaper-rounded': {
            padding: theme.spacing(1)
        },
        '& .MuiCardContent-root': {
            padding: theme.spacing(0.5)
        },
        '&.main': {
            backgroundColor: theme.palette.primary.contrastText,
            border: "2px solid " + theme.palette.secondary.main,
            cursor: 'pointer',
            '& .value': {
                color: theme.palette.secondary.main,
            },
            '&:hover': {
                backgroundColor: theme.palette.tertiary.contrastText,
            }
        },
        width: '250px',
        border: "2px solid " + theme.palette.tertiary.dark,
        textAlign: 'center',
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
        borderRadius: 5,
        transition: 'all 0.4s ease-in-out',
        '& .MuiCardContent-root:last-child': {
            paddingBottom: 0
        },
        [theme.breakpoints.down('md')]: {
            marginBottom: 40,
            marginLeft: 16,
            marginRight: 16,
        },
        '&.circle': {
            borderRadius: '100%',
            width: '200px',
            height: '200px',
            backgroundColor: theme.palette.tertiary.light,
            border: "3px solid " + theme.palette.tertiary.main,
            [theme.breakpoints.down('md')]: {
                marginBottom: 0,
            },
            '& .title': {
                paddingTop: theme.spacing(0.75),
                fontSize: theme.spacing(2.25)
            }
        },
        '& .value': {
            color: theme.palette.tertiary.main,
        },
    },
    title: {
        textTransform: 'uppercase',
        fontWeight: 700,
        height: 50,
        letterSpacing: '1.2px',
        fontSize: 20,
        wordWrap: 'break-word'
    },
    subtitle: {
        fontSize: 16,
    },
    active: {
        border: "3px solid " + theme.palette.tertiary.main + ' !important',
        marginTop: 16,
        marginBottom: -16,
        '&.main .value': {
            color: theme.palette.tertiary.main
        },
        [theme.breakpoints.down('md')]: {
            marginBottom: 24,
        },
    },
    disabled: {
        '&.main':{
            border: "2px solid " + theme.palette.tertiary.dark,
            '&.main .value': {
                color: theme.palette.tertiary.dark
            },
            cursor: 'default !important',
            '&:hover': {
                backgroundColor: theme.palette.primary.contrastText + " !important",
            }
        }
    }
}));

const CustomCard = (props) => {
    const classes = useStyles();
    const { title, value, subtitle, active, onClick, main, circle } = props

    return (
        <Card className={`${classes.root} ${value == 0 ? classes.disabled : (active ? classes.active : '')} ${main ? 'main' : ''} ${circle ? 'circle' : ''}`} variant="outlined" onClick={value > 0 ? onClick : null}>
            <CardContent>
                { title ? <Typography className={`${classes.title} title`} color="primary" gutterBottom>
                    { title }
                </Typography> : null }
                <Typography variant="h2" component="h2" className={`value`}>
                    { value }
                </Typography>
                { subtitle ? <Typography variant="body2" component="p" className={classes.subtitle}>
                    { subtitle }
                </Typography> : null }
            </CardContent>
        </Card>
    );
}

export default CustomCard;
