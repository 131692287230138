import React from 'react';
import ReputationToolGridLayout from './ReputationToolGridLayout';
import ProfessionalWidget from './Tools/ProfessionalWidget';
import ProfessionalCoe from './Tools/ProfessionalCoe';
import ProfessionalPdfReportWithDownloadOptionsFeature from './Tools/ProfessionalPdfReportWithDownloadOptionsFeature';
import ProfessionalEmailSignatures from './Tools/ProfessionalEmailSignatures';
import ProfessionalShareReview from './Tools/ProfessionalShareReview';
import ProfessionalTopRatedReportCard from './Tools/ProfessionalTopRatedReportCard';
import ReputationToolCategory from './Tools/Components/ReputationToolCategory';
import WidgetStars from './Tools/WidgetStars';
import { ExternalLink } from '../Components/Links';
import { SUPPORT_URL } from '../constants';
import connect from 'react-redux/es/connect/connect';
import _ from 'lodash';
import ProfessionalPdfReport from './Tools/ProfessionalPdfReport';
import {showNewRepTool, showWidget} from "../utils";

const EmailSignatureDescription = () => {
    return (
        <>
            Build trust with prospects and clients in your email signature. Simply download an image
            and{' '}
            <ExternalLink href={SUPPORT_URL.ACCOUNT.HOW_TO_USE_MARKETING_TOOLS}>
                follow the instructions
            </ExternalLink>{' '}
            to link the image to your VouchedFor profile or leave a review page.
        </>
    );
};

const ProfessionalReputationToolGrid = props => (
    <ReputationToolGridLayout>
        <ReputationToolCategory
            title="Rating Widget"
            description="Choose a dynamically updated widget to add to your website to share your ratings and reviews with all visitors. When you click the widget from your website you will be taken to your VouchedFor profile."
            titleFontSize="24px">
            { showWidget() &&
                <WidgetStars
                    type="panel"
                    resource="professional/widget/star"
                    buttonName="Copy code"
                    cardDisabled={props.cardDisabled}
                />
            }
            <WidgetStars
                type="centre"
                resource="professional/widget/star"
                buttonName="Copy code"
                cardDisabled={props.cardDisabled}
            />
            { showWidget() &&
                <WidgetStars
                    type="left"
                    resource="professional/widget/star"
                    buttonName="Copy code"
                    cardDisabled={props.cardDisabled}
                />
            }
            <ProfessionalWidget cardDisabled={props.cardDisabled} />
        </ReputationToolCategory>

        <ReputationToolCategory
            title="Email Signature"
            description={EmailSignatureDescription()}
            titleFontSize="24px">
            <ProfessionalEmailSignatures />
        </ReputationToolCategory>

        <ReputationToolCategory title="PDF Downloads" description="" titleFontSize="24px">
            <ProfessionalCoe showNewRepTool={showNewRepTool()} />
            <ProfessionalTopRatedReportCard showNewRepTool={showNewRepTool()} />
            <ProfessionalPdfReportWithDownloadOptionsFeature showNewRepTool={showNewRepTool()} />
        </ReputationToolCategory>

        <ReputationToolCategory title="Social Share" description="" titleFontSize="24px">
            <ProfessionalShareReview showNewRepTool={showNewRepTool()} />
        </ReputationToolCategory>
    </ReputationToolGridLayout>
);

const mapStateToProps = ({ basicInfo }) => {
    return {
        upgradeUrl: `/account-settings/${basicInfo.id}`,
        cardDisabled: _.get(basicInfo, ['permissions', 'reputation_tools']) === false,
        hasReviewPdfOptionsFeature: _.get(basicInfo, 'has_review_pdf_options_feature'),
    };
};

export default connect(mapStateToProps)(ProfessionalReputationToolGrid);
