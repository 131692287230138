import React from 'react';
import RegisterReputationalToolBanner from '../Components/RegisterReputationalToolBanner';
import {
    ELEVATION,
    ADVISER_PROFILE,
    CONSUMER_DUTY_REPORT,
    TOP_RATED_GUIDE
} from '../../constants';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    section: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: theme.palette.secondary.main,
        padding: `${theme.spacing(10)}px ${theme.spacing(8)}px`,
        textAlign: 'center',
        color: theme.palette.primary.contrastText,

        [theme.breakpoints.down('sm')]: {
            padding: `${theme.spacing(5)}px ${theme.spacing(2)}px`,
        },
    },
    gridWrapper: {
        marginTop: theme.spacing(4),
    },
    subheadingText: {
        maxWidth: theme.spacing(75),
        margin: '0 auto',
    },
    positionTop: {
        top: 0,

        [theme.breakpoints.down('sm')]: {
            top: 'initial',
            bottom: 0,
        },
    },
    positionBottom: {
        bottom: 0,
    },
}));

const RegisterReputationToolsSection = ({ width }) => {
    const spacing = ['xl', 'lg', 'md'].indexOf(width) === -1 ? 32 : 0;
    const classes = useStyles();

    return (
        <section className={classes.section}>
            <Grid container justify="center">
                <Grid item lg={10} md={12} sm={12} xs={12}>
                    <Typography component="h1" color="inherit" variant="h4">
                        What we offer
                    </Typography>
                    <div className={classes.gridWrapper}>
                        <Grid container spacing={spacing}>
                            <RegisterReputationalToolBanner
                                image={ELEVATION}
                                text="Elevation"
                                textWrapperClassName={classes.positionBottom}
                                widthSize={7}
                            />
                            <RegisterReputationalToolBanner
                                image={ADVISER_PROFILE}
                                text="Adviser profile and reputation tools"
                                textWrapperClassName={classes.positionTop}
                                widthSize={5}
                            />
                        </Grid>
                        <Grid container spacing={spacing}>
                            <RegisterReputationalToolBanner
                                image={TOP_RATED_GUIDE}
                                text="Ability to qualify for Top Rated Adviser Guide"
                                textWrapperClassName={classes.positionBottom}
                                widthSize={5}
                            />
                            <RegisterReputationalToolBanner
                                image={CONSUMER_DUTY_REPORT}
                                text="Consumer Duty Report"
                                textWrapperClassName={classes.positionTop}
                                widthSize={7}
                            />
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </section>
    );
};

export default (withWidth()(RegisterReputationToolsSection));
