import {
    SHOW_SIDEBAR,
    HIDE_SIDEBAR,
    ON_ROUTE_CHANGE,
    DASHBOARD_REFERRER_COUNT,
    DASHBOARD_INFLUENCED_COUNT,
} from '../types';

export const showSidebar = () => {
    return {
        type: SHOW_SIDEBAR,
    };
};

export const hideSidebar = () => {
    return {
        type: HIDE_SIDEBAR,
    };
};

export const onRouteChange = () => {
    return {
        type: ON_ROUTE_CHANGE,
    };
};

export const setDashboardReferrerCount = count => {
    return {
        type: DASHBOARD_REFERRER_COUNT,
        payload: count,
    };
};

export const setDashboardInfluencedCount = count => {
    return {
        type: DASHBOARD_INFLUENCED_COUNT,
        payload: count,
    };
};
