import React from 'react';
import _ from 'lodash';
import YesNoBooleanField from '../../Components/Inputs/YesNoBooleanField';
import ReviewAutoInviteEdit from '../ReviewAutoInviteEdit';
import {
    Datagrid,
    DateField,
    ReferenceManyField,
    TextField,
    Pagination,
    Responsive,
    SimpleList,
} from 'react-admin';
import CustomerMasterRowNextInviteDate from './CustomerMasterRowNextInviteDate';

import withStyles from '@material-ui/core/styles/withStyles';

const styles = () => ({
    email: {
        fontSize: 'smaller',
    },
});

const CustomerMasterGrid = ({ classes, ...rest }) => {
    return (
        <ReferenceManyField
            label=""
            reference="customer-master"
            target="professional_id"
            fullWidth
            perPage={5}
            {...rest}
            pagination={<Pagination />}
        >
            <Responsive
                small={
                    <SimpleList
                        style={{ width: '100%' }}
                        primaryText={record =>
                            `Name: ${_.get(record, [
                                'current_customer',
                                'name',
                            ])}`
                        }
                        secondaryText={record => (
                            <span className={classes.email}>
                                {_.get(record, 'current_customer.email')}
                            </span>
                        )}
                        tertiaryText={record =>
                            `Annual Auto Invite: ${
                                record.annual_auto_invite ? 'On' : 'Off'
                            }`
                        }
                    />
                }
                medium={
                    <Datagrid
                        rowClick="expand"
                        expand={<ReviewAutoInviteEdit />}
                        {...rest}
                    >
                        <TextField
                            label="Full Name"
                            source="current_customer.name"
                        />
                        <TextField
                            label="Email"
                            source="current_customer.email"
                        />
                        <DateField
                            label="Last reviewed date"
                            source="last_reviewed_date"
                        />
                        <CustomerMasterRowNextInviteDate
                            label="Next invite date"
                            source="calculated_next_invite_date"
                            {...rest}
                        />
                        <YesNoBooleanField
                            label="Annual auto invite"
                            source="annual_auto_invite"
                        />
                    </Datagrid>
                }
            />
        </ReferenceManyField>
    );
};

export default withStyles(styles)(CustomerMasterGrid);
