import React, { useState } from 'react';
import ContentTooltip from '../../../Components/ConsumerReactComponents/ContentTooltip';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    tooltip: {
        position: 'absolute',
        top: theme.spacing(3),
        right: theme.spacing(3),
    },
});

const DashboardCardTooltip = ({ classes, title }) => {
    const [open, setOpen] = useState(false);

    const openTooltip = () => {
        setOpen(true);
    };

    const closeTooltip = () => {
        setOpen(false);
    };

    return (
        <div className={classes.tooltip}>
            <ClickAwayListener onClickAway={closeTooltip}>
                <ContentTooltip
                    placement="bottom"
                    title={title}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    onClick={open ? closeTooltip : openTooltip}
                    onClose={closeTooltip}
                />
            </ClickAwayListener>
        </div>
    );
};

export default withStyles(styles)(DashboardCardTooltip);
