import React from "react";

import { makeStyles } from '@material-ui/core';
import {Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import SpeechBubble from "../../../Components/Typography/SpeechBubble";

const useStyles = makeStyles(theme => ({
    placeholderText: {
        fontSize: theme.spacing(1)*1.5,
    },
}));

const StatisticTopTipBubble = ({ toptipContent }) => {
    const classes = useStyles();

    return (
        <Grid item xs={12} lg={2}>
            <SpeechBubble isPlaceholder={true}>
                <Typography color="secondary" className={classes.placeholderText}>
                    <b>Top tip: </b>
                    {toptipContent}
                </Typography>
            </SpeechBubble>
        </Grid>
    )
}

export default StatisticTopTipBubble;