import React from 'react';
import _ from 'lodash';
import DialogFormSubmit from '../../Components/Dialogs/DialogFormSubmit';
import { Typography } from '@material-ui/core';

const FirmDetailsDialogModal = ({ record }) => {
    const firmAdvisersCount = _.get(record, 'firm_advisers_count', null);
    const firmFcaId = _.get(record, 'fca_number', null);
    const firmFcaIdText = firmFcaId ? `(FCA ID ${firmFcaId})` : null;
    let message = null;

    if (firmAdvisersCount === 1 || !firmAdvisersCount) {
        message = (
            <div>
                <Typography>
                    The changes you've made to your firm profile will affect any colleagues from
                    your current firm {firmFcaIdText} who sign up in future.
                </Typography>
                <Typography>Do you want to go ahead?</Typography>
            </div>
        );
    } else {
        message = (
            <div>
                <Typography>
                    The changes you've made will affect you and {firmAdvisersCount - 1} other
                    advisers who are linked to your current firm {firmFcaIdText}
                </Typography>
                <Typography>Do you want to go ahead?</Typography>
            </div>
        );
    }

    return (
        <DialogFormSubmit
            buttonLabel="Save"
            saveButtonLabel="Yes, save changes"
            message={message}
            resource="firm"
            type="UPDATE"
            values={record}
            title="Confirm Firm Changes"
        />
    );
};

export default FirmDetailsDialogModal;
