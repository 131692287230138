import React from 'react';
import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ComposedChart,
    Bar,
    Line,
    ResponsiveContainer,
} from 'recharts';

const CustomComposedChart = ({
    dataPoints,
    lineElements,
    opacity,
    visibility,
    handleMouseEnter,
    handleMouseLeave,
    handleClick,
    barElements,
    lineColors,
    barColors,
}) => {
    if (!dataPoints) return null;

    return (
        <ResponsiveContainer height={300} width="100%">
            <ComposedChart data={dataPoints}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="xaxis" />
                <YAxis
                    allowDecimals={false}
                    tickCount={6}
                />
                <Tooltip />
                <Legend
                    onClick={handleClick}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                />
                {barElements.map((name, key) => (
                    <Bar
                        barSize={20}
                        dataKey={name}
                        fill={barColors[key]}
                        opacity={opacity[name]}
                        hide={!visibility[name]}
                    />
                ))}{' '}
                {lineElements.map((name, key) => (
                    <Line
                        type="linear"
                        dataKey={name}
                        stroke={lineColors[key]}
                        strokeWidth={4}
                        opacity={opacity[name]}
                        hide={!visibility[name]}
                    />
                ))}
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default CustomComposedChart;
