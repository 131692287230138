import React, { useEffect } from 'react';
import cn from 'classnames';
import _ from 'lodash';
import { connect } from 'react-redux';
import FeesAverage5YearCost from './FeesAverage5YearCost';
import FeesChargeStatistics from './FeesChargeStatistics';
import FeesInsightsHeaderBadge from './FeesInsightsHeaderBadge';
import { FEE_BADGE, PLAN, PLAN_ID, ROOT_DOMAIN } from '../../constants';
import * as analytics from '../../analytics';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { CallToActionButton } from '../../Components/Buttons/Buttons';
import { isIE11 } from '../../utils';

const useStyles = makeStyles(theme => ({
    gridIEFix: {
        [theme.breakpoints.down('xl')]: {
            display: 'block',
        },
    },
}));

const FeesInsightsContent = props => {
    const { planId, profileUrl, onStarterPlan, width, record } = props;
    const classes = useStyles();
    const hasFeesChargeLevels = _.get(record, 'hasFeesChargeLevels', true);
    const isContentDisabled = onStarterPlan || !hasFeesChargeLevels;
    const showUnlockBanner = onStarterPlan && hasFeesChargeLevels;
    const feeReportUrl = `${ROOT_DOMAIN}/${profileUrl}/fee-report/${_.get(
        record,
        'feeReportToken',
        ''
    )}`;

    useEffect(() => {
        analytics.track('Viewed fee insights', {
            category: 'Fee Insights',
            label: planId,
        });
    });

    return (
        <React.Fragment>
            <Grid
                className={cn({ [classes.gridIEFix]: isIE11() })}
                container
                spacing={3}
                direction={width === 'xl' ? 'row' : 'column'}
            >
                <Grid item xl={6} lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item md={12} sm={12} xs={12}>
                            <FeesInsightsHeaderBadge
                                avatar={FEE_BADGE}
                                title={_.get(record, 'headerText', '')}
                                feeReportUrl={feeReportUrl}
                            />
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <FeesAverage5YearCost
                                showUnlockBanner={showUnlockBanner}
                                onStarterPlan={onStarterPlan}
                                hasFeesChargeLevels={hasFeesChargeLevels}
                                feeReportUrl={feeReportUrl}
                                isContentDisabled={isContentDisabled}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xl={6} lg={12} md={12} sm={12} xs={12}>
                    <FeesChargeStatistics
                        showUnlockBanner={showUnlockBanner}
                        onStarterPlan={onStarterPlan}
                        hasFeesChargeLevels={hasFeesChargeLevels}
                        isContentDisabled={isContentDisabled}
                    />
                </Grid>
                <Grid item xl={12} align={isWidthDown('sm', width) && 'center'}>
                    <Typography variant="body1">
                        Your fee insights & report are currently free for Verified members while in
                        ‘beta’ trial stages.
                    </Typography>
                </Grid>
                <Grid item xl={12} align={isWidthDown('sm', width) && 'center'}>
                    <CallToActionButton
                        href={feeReportUrl}
                        onClickAction={() => {
                            analytics.track('Clicked Get Fee Report', {
                                category: 'Fee Insights',
                            });
                        }}
                    >
                        Get Shareable Fee Report
                    </CallToActionButton>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        planId: PLAN_ID[basicInfo.plan],
        profileUrl: _.get(basicInfo, ['permalink', 'url'], ''),
        onStarterPlan: _.get(basicInfo, 'plan', '') === PLAN.STARTER,
    };
};

export default connect(mapStateToProps)(withWidth()(FeesInsightsContent));
