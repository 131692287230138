import {
    SHOW_SIDEBAR,
    HIDE_SIDEBAR,
    DASHBOARD_REFERRER_COUNT,
    DASHBOARD_INFLUENCED_COUNT,
} from '../types';

const INITIAL_STATE = {
    showSidebar: true,
    dashboardInfluencedCount: null,
    dashboardReferrerCount: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SHOW_SIDEBAR:
            return { ...state, showSidebar: true };
        case HIDE_SIDEBAR:
            return { ...state, showSidebar: false };
        case DASHBOARD_REFERRER_COUNT:
            return { ...state, dashboardReferrerCount: action.payload };
        case DASHBOARD_INFLUENCED_COUNT:
            return { ...state, dashboardInfluencedCount: action.payload };
        default:
            return state;
    }
};
