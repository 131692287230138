import {
    FIND_ADVISER_URL,
    SOCIAL_URL,
    TERMS_AND_CONDITIONS,
    PRIVACY_POLICY,
    COMPANY_URL,
} from './external-urls';
import {
    VERTICAL,
    FA_INVESTMENT_ADVICE,
    MA_INVESTMENT_ADVICE,
    CALCULATED_COST,
    FEE_TYPE,
    FEE_SUB_TYPE,
    ACCOUNTANT_QUALIFICATION,
} from './professional';
import {
    REP_TOOL_FIRM_BADGE_DARK,
    REP_TOOL_FIRM_BADGE_LIGHT,
    REP_TOOL_FIRM_BADGE_STARS,
    REP_TOOL_FIRM_BADGE_TOP_RATED_2023,
    REP_TOOL_FIRM_BADGE_TOP_RATED_2024,
} from './assets';

export const SECURITY_QUESTION_CHOICES = [
    {
        id: "What is your mother's maiden name?",
        name: "What is your mother's maiden name?",
    },
    {
        id: "What was your first pet's name?",
        name: "What was your first pet's name?",
    },
    {
        id: 'What was the name of your primary school?',
        name: 'What was the name of your primary school?',
    },
    {
        id: 'What is your favourite colour?',
        name: 'What is your favourite colour?',
    },
];

export const ENQUIRY_STATUS_CHOICES = [
    {
        id: 'not_yet_contacted',
        name: 'Not yet Contacted',
    },
    {
        id: 'contacted_taking_further',
        name: 'Contacted: taking further',
    },
    {
        id: 'contacted_no_taking_further',
        name: 'Contacted: not taking further',
    },
    {
        id: 'contact_attempted_no_response',
        name: 'Contact attempted: no response',
    },
    {
        id: 'meeting_held_taking_further',
        name: 'Meeting held: taking further',
    },
    {
        id: 'meeting_held_no_taking_further',
        name: 'Meeting held: not taking further',
    },
    {
        id: 'became_a_client',
        name: 'Became a client',
    },
];

export const ENQUIRY_REJECT_REASON_CHOICES = [
    {
        id: 'spam',
        name: 'Spam',
    },
    {
        id: 'existing_client',
        name: 'Duplicate, existing client or colleague',
    },
    {
        id: 'poor_contact_details',
        name: 'Unable to contact',
    },
    {
        id: 'colleague',
        name: 'Incorrect services match',
    },
];

export const LOCATION_DISTANCE_CHOICES = [
    { id: 0, name: '0 miles' },
    { id: 5, name: '5 miles' },
    { id: 10, name: '10 miles' },
    { id: 15, name: '15 miles' },
    { id: 20, name: '20 miles' },
    { id: 25, name: '25 miles' },
    { id: 30, name: '30 miles' },
    { id: 35, name: '35 miles' },
    { id: 40, name: '40 miles' },
    { id: 45, name: '45 miles' },
    { id: 50, name: '50 miles' },
    { id: 55, name: '55 miles' },
    { id: 60, name: '60 miles' },
    { id: 65, name: '65 miles' },
    { id: 70, name: '70 miles' },
    { id: 75, name: '75 miles' },
    { id: 80, name: '80 miles' },
];

export const FIND_ADVISER_URLS = [
    {
        text: 'IFAs / Financial Advisers',
        url: FIND_ADVISER_URL.FINANCIAL_ADVISER,
    },
    {
        text: 'Mortgage Advisers',
        url: FIND_ADVISER_URL.MORTGAGE_ADVISER,
    },
    {
        text: 'Legal Advisers',
        url: FIND_ADVISER_URL.LEGAL_ADVISER,
    },
    {
        text: 'Accountants',
        url: FIND_ADVISER_URL.ACCOUNTANT,
    },
];

export const SOCIAL_URLS = [
    {
        text: 'Facebook',
        url: SOCIAL_URL.FACEBOOK,
    },
    {
        text: 'Twitter',
        url: SOCIAL_URL.TWITTER,
    },
    {
        text: 'LinkedIn',
        url: SOCIAL_URL.LINKEDIN,
    },
];

export const LEGAL_URLS = [
    {
        text: 'Terms & Conditions',
        url: TERMS_AND_CONDITIONS,
    },
    {
        text: 'Privacy Policy',
        url: PRIVACY_POLICY,
    },
];

export const COMPANY_URLS = [
    {
        text: 'About Us',
        url: COMPANY_URL.ABOUT,
    },
    {
        text: 'Tips & Guides',
        url: COMPANY_URL.BLOG,
    },
    {
        text: 'Contact',
        url: COMPANY_URL.CONTACT_LINK,
    },
];

export const CHARTERED_QUALIFICATIONS = [
    {
        id: 'accountant_chartered_qualification_association_of_chartered_certified_accountants_acc',
        name: 'Association of Chartered Certified Accountants (ACCA)',
    },
    {
        id: 'accountant_chartered_qualification_chartered_accountants_ireland_cai_',
        name: 'Chartered Accountants Ireland (CAI)',
    },
    {
        id: 'accountant_chartered_qualification_chartered_institute_of_management_accountants',
        name: 'Chartered Institute of Management Accountants (CIMA)',
    },
    {
        id: 'accountant_chartered_qualification_institute_of_chartered_accountants_in_england_and_',
        name: 'Institute of Chartered Accountants in England and Wales (ICAEW)',
    },
    {
        id: 'accountant_chartered_qualification_institute_of_chartered_accountants_of_scotland_ica',
        name: 'Institute of Chartered Accountants of Scotland (ICAS)',
    },
    {
        id: 'accountant_chartered_qualification_chartered_institute_of_public_finance',
        name: 'Chartered Institute of Public Finance and Accountancy (CIPFA)',
    },
    {
        id: 'accountant_chartered_qualification_chartered_institute_of_taxation',
        name: 'Chartered Institute of Taxation (CIOT)',
    },
    {
        id: 'accountant_chartered_qualification_association_of_tax_technicians',
        name: 'Association of Tax Technicians (ATT)',
    },
    {
        id: 'accountant_chartered_qualification_institute_of_financial_accountants',
        name: 'Institute of Financial Accountants (IFA)',
    },
    {
        id: 'accountant_chartered_qualification_association_of_accounting_technicians',
        name: 'Association of Accounting Technicians (AAT)',
    },
    {
        id: 'accountant_chartered_qualification_institute_of_certified_bookkeepers',
        name: 'The Institute of Certified Bookkeepers (ICB)',
    },
    {
        id: 'accountant_chartered_qualification_international_association_of_bookkeepers',
        name: 'International Association of Bookkeepers (IAB)',
    },
    {
        id: ACCOUNTANT_QUALIFICATION.OTHER,
        name: 'Other',
    },
];

export const FINANCIAL_COACH_QUALIFICATIONS = [
    {
        id: 'financial_adviser_qualifications_ifw_financial_wellbeing_certificate',
        name: 'IFW Financial Wellbeing Certificate',
    },
    {
        id: 'financial_adviser_qualifications_wise_monkey_financial_coach_practitioner_certificate_training',
        name: 'Wise Monkey - Financial Coach Practitioner Certificate Training',
    },
    {
        id: 'financial_adviser_qualifications_the_money_panel_certified_financial_coach',
        name: 'The Money Panel - Certified Financial Coach',
    },
    {
        id: 'financial_adviser_qualifications_octopus_moneycoach_accredited_financial_coach',
        name: 'Octopus MoneyCoach - Accredited Financial Coach',
    },
    {
        id: 'financial_adviser_qualifications_quiver_financial_wellbeing_certificate',
        name: 'Quiver - Financial Wellbeing Certificate',
    },
]

export const REGISTRY_SRA_CHOICES = [
    {
        id: 'professional_registry_sra',
        name: 'I am a solicitor practicing in England or Wales',
    },
    {
        id: 'professional_registry_sra_scotland_and_northern_ireland',
        name: 'I am solicitor practicing in Scotland or Nothern Ireland',
    },
    {
        id: 'professional_registry_professional_paralegal_register',
        name: 'I am paralegal',
    },
];

export const LOGGED_OUT_AND_SIDEBAR_URLS = [
    'login',
    'register',
    'verify',
    'verify-services',
    'basic-profile',
    'first-invitation/create',
    'enquiry/view',
    'verification',
    'forgot-password',
    'onboarding/update-password',
];

export const FEES_REGISTER_ROUTE = {
    LIST: '/fees'
};

export const REGISTER_VERTICAL_CHOICES = [
    { id: VERTICAL.FINANCIAL_ADVISER, name: 'Financial and/or Mortgage Adviser' },
    { id: VERTICAL.LEGAL_ADVISER, name: 'Legal Adviser' },
    { id: VERTICAL.ACCOUNTANT, name: 'Accountant' },
    { id: VERTICAL.FINANCIAL_COACH, name: 'Financial Coach' },
];

export const REGISTER_TITLE_CHOICES = [
    { id: 'mr', name: 'Mr' },
    { id: 'mrs', name: 'Mrs' },
    { id: 'miss', name: 'Miss' },
    { id: 'ms', name: 'Ms' },
    { id: 'dr', name: 'Dr' },
    {id: 'mx', name: 'Mx'}
];

export const REGISTER_FA_CHOICES = [
    {
        id: FA_INVESTMENT_ADVICE.INDEPENDENT,
        name: 'Independent Financial Adviser',
    },
    {
        id: FA_INVESTMENT_ADVICE.RESTRICTED,
        name: 'Restricted Financial Adviser',
    },
    {
        id: FA_INVESTMENT_ADVICE.FOCUSSED,
        name: 'Focussed Independent Financial Adviser',
    },
    {
        id: FA_INVESTMENT_ADVICE.NONE,
        name: 'None',
    },
];

export const REGISTER_MA_CHOICES = [
    {
        id: MA_INVESTMENT_ADVICE.INDEPENDENT,
        name: 'Independent Mortgage Adviser',
    },
    {
        id: MA_INVESTMENT_ADVICE.LIMITED,
        name: 'Limited Mortgage Adviser',
    },
    {
        id: MA_INVESTMENT_ADVICE.NONE,
        name: 'None',
    },
];

export const FEE_MODEL_CHOICES = [
    {
        id: CALCULATED_COST.INVESTMENT_AND_PENSION_WITH_PLANNING,
        name:
            'Fixed financial planning fee + percentage of assets for implementation & ongoing service (36% of advisers)',
    },
    {
        id: CALCULATED_COST.INVESTMENT_AND_PENSION,
        name: 'Percentage of assets only (53% of advisers)',
    },
    {
        id: FEE_SUB_TYPE.FIXED_FEE_ONLY,
        name: 'Fixed fees only (6% of advisers)',
    },
];

export const FEE_TYPE_CHOICES = [
    {
        id: FEE_TYPE.INVESTMENT_AND_PENSION_WITH_PLANNING,
        name: 'Financial Planning with Implementation & Ongoing Service',
    },
    {
        id: FEE_TYPE.INVESTMENT_AND_PENSION,
        name: 'Implementation & Ongoing Service (without Financial Planning)',
    },
];

export const FEE_SUB_TYPE_CHOICES = [
    { id: FEE_SUB_TYPE.INITIAL_AND_ONGOING, name: 'Percentage of Assets only' },
    { id: FEE_SUB_TYPE.FIXED_FEE_ONLY, name: 'Fixed Fee Only' },
    { id: FEE_SUB_TYPE.HOURLY_CHARGE, name: 'Hourly Charge' },
];

export const FEE_SUB_TYPE_CHOICES_LOGGED_OUT = [
    { id: FEE_SUB_TYPE.INITIAL_AND_ONGOING, name: 'Percentage of Assets only' },
    { id: FEE_SUB_TYPE.FIXED_FEE_ONLY, name: 'Fixed Fee Only' },
];

export const FEE_LEVEL_TYPE_CHOICES = [
    { id: 'up_to', name: 'Up to' },
    { id: 'between', name: 'Between' },
    { id: 'over', name: 'Over' },
    { id: 'any_amount', name: 'For any amount' },
];

export const REVIEWERS_LIST = [
    {
        id: 1,
        name: 'all_reviewers_2018',
        description: 'All reviewers from 2018',
    },
    {
        id: 2,
        name: 'all_reviewers_all_time',
        description: 'All reviewers all time',
    },
    { id: 3, name: 'just_me', description: 'Just me (test)' },
    {
        id: 4,
        name: 'all_new_reviewers',
        description: 'Latest reviewers (since I last asked for referrals)',
    },
];

export const ASSET_LEVEL_CHOICES = [
    { id: 50000, name: '£50,000' },
    { id: 100000, name: '£100,000' },
    { id: 250000, name: '£250,000' },
    { id: 500000, name: '£500,000' },
];

export const TOP_RATED_FIRM_BADGES = [
    {
        label: 'light',
        image_url: REP_TOOL_FIRM_BADGE_LIGHT,
    },
    {
        label: 'dark',
        image_url: REP_TOOL_FIRM_BADGE_DARK,
    },
    {
        label: 'color',
        image_url: REP_TOOL_FIRM_BADGE_STARS,
    },
];

export const TOP_RATED_FIRM_BADGES_VF2023 = [
    {
        label: 'light',
        image_url: REP_TOOL_FIRM_BADGE_TOP_RATED_2023,
    },
];

export const TOP_RATED_FIRM_BADGES_VF2024 = [
    {
        label: 'light',
        image_url: REP_TOOL_FIRM_BADGE_TOP_RATED_2024,
    },
];

export const MEETING_LOCATIONS_OPTIONS = [
    { id: 'my_office', name: 'My Office' },
    { id: 'your_home', name: 'Your Home' },
    { id: 'video_call', name: 'Video Call' },
    { id: 'other', name: 'Other' },
];

export const REVIEW_REPORT_REASONS = [
    { id: 'review_abusive', name: 'This review is abusive'},
    { id: 'not_client', name: 'This reviewer is not a client of mine'},
    { id: 'other', name: 'Other'},
]

export const FIRST_IMPRESSION_REPORT_REASONS = [
    { id: 'first_impression_abusive', name: 'This review is abusive'},
    { id: 'not_prospect_spoken_to', name: "This reviewer is not a prospect I've spoken to"},
    { id: 'other', name: 'Other'},
]
