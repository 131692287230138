import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../Components/Buttons/CustomButton';
import { makeStyles } from '@material-ui/core/styles';

const propTypes = {
    label: PropTypes.string.isRequired,
};

const defaultProps = {
    label: '',
};

const useStyles = makeStyles(theme => ({
    button: {
        '.MuiSvgIcon-root': {
            fontSize: theme.spacing(3),
        },
    },
}));

const HeaderButton = React.forwardRef(({ label, ...rest }, ref) => {
    const classes = useStyles();

    return (
        <Button color="tertiary" className={classes.button} size="large" {...rest} ref={ref}>
            {label}
        </Button>
    );
});

HeaderButton.propTypes = propTypes;
HeaderButton.defaultProps = defaultProps;

export default HeaderButton;
