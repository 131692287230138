import React from 'react';
import { Typography, makeStyles, Box } from '@material-ui/core';
import CustomSlider from './Slider';
import Chip from '@material-ui/core/Chip';
import { Link } from 'react-router-dom';
import ContentTooltip from '../../../Components/ConsumerReactComponents/ContentTooltip';
import { ExternalLink } from '../../../Components/Links';
import { palette } from '../../../MyTheme';

const useStyles = makeStyles(theme => ({
    anchor: {
        textDecoration: 'none',
    },
    sliderWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: 120,
        background: "#fcfdfd",
        marginBottom: "1rem",
        padding: "1rem 3rem 1rem 1rem",
        borderRadius: '16px',
        '& .MuiChip-root': {
            backgroundColor: theme.palette.tertiary.main,
            color: theme.palette.primary.contrastText
        },
        '& .MuiChip-clickable': {
            '&:hover': {
                backgroundColor: palette.tertiary.light,
                color: theme.palette.tertiary.main
            }
        },
        [theme.breakpoints.down('md')]: {
            padding: "1rem"
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            textAlign: 'center',
            flexDirection: 'column',
            padding: "1rem"
        },
        boxShadow: "rgba(17, 17, 26, 0.05) 0px 2px 4px, rgba(17, 17, 26, 0.05) 0px 4px 8px"
    },
    driverTitle: {
        display: 'block',
        flexBasis: "20%",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
    },
    slider: {
        flexBasis: "75%",
        padding: '1rem',
        display: "flex",
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            flexBasis: "70%",
        },
        [theme.breakpoints.down('sm')]: {
            flexBasis: "100%",
            flexDirection: "column",
            width: "80%",
            marginTop: "1rem",
        },
        '& .rc-slider-step': {
            '& .rc-slider-dot-active': {
                display: 'none',
                opacity: 0,
                visibility: 'hidden',
            },
        },
        '& .rc-slider-rail': {
            height: "3px !important"
        },
        '& .rc-slider': {
            padding: "3px 0",
            height: "7px",
        },
    },
    sliderInnerWrapper: {
        flexGrow: 0.8,
        alignSelf: 'center',
        [theme.breakpoints.down('md')]: {
            flexGrow: 0.7,
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            marginBottom: "2rem"
        },
    },
    driverName: {
        color: theme.palette.secondary.main,
        marginBottom: 0,
        fontWeight: 600,
    },
    chipContainer: {
        '& .MuiButtonBase-root': {
           padding: "1.1rem 0.3rem",
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: "1rem"
        },
    },
    driverTooltipIcon: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        [theme.breakpoints.up('md')]: {
            minHeight: 100,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
}));

const getToolTipText = (description, descriptionHyperlink) => {
    return (
        <>
            {description} {descriptionHyperlink &&
            <ExternalLink href={descriptionHyperlink} target={'_blank'}>
                Learn more.
            </ExternalLink>}
        </>
    );
};

const DriverSlider = props => {
    const { averages, compareToIndustry, type, review_count, goal } = props;
    const classes = useStyles();

    const strength = type === 'strengths';

    const sliderProps = {
        avg: Math.round(averages.industry_avg),
        professionalAvg: Math.round(averages.professionalAvg),
        compareToIndustry,
        strength,
        extraFirmAvg: averages.firm_avg !== undefined ? Math.round(parseFloat(averages.firm_avg)) : false
    };

    return (
        <Box className={classes.sliderWrapper}>
            <Box className={classes.driverTooltipIcon}>
                <ContentTooltip
                    placement="top"
                    title={getToolTipText(averages.description, averages.descriptionHyperlink)}
                    disableFocusListener
                    disableTouchListener
                    interactive
                    leaveDelay={200}
                    isDriverTooltip={true}
                />
            </Box>
            <Box className={classes.driverTitle}>
                <Typography className={classes.driverName}>
                    {averages.name}
                </Typography>
            </Box>
            <Box className={classes.slider}>
                <Box className={classes.sliderInnerWrapper}>
                    <CustomSlider {...sliderProps} />
                </Box>
                <Box component="div" className={classes.chipContainer}>
                    <Link to={`/elevation-client-answers?goal=${goal}&driver=${averages.driver_id}`} className={classes.anchor}>
                        <Chip 
                        clickable={true}
                        label={`${review_count ? `See ${review_count} reviews` : "See reviews"}`} />
                    </Link>
                </Box>
            </Box>

        </Box>
    );
};

export default DriverSlider;
