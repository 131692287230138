import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {palette} from "../../MyTheme";
import PricingList from "../Components/PricingList";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    section: {
        textAlign: 'center',
        padding: `${theme.spacing(10)}px ${theme.spacing(8)}px`,
        backgroundColor: theme.palette.secondary.light,

        [theme.breakpoints.down('sm')]: {
            padding: `${theme.spacing(5)}px ${theme.spacing(2)}px`,
        },
    },
    content: {
        backgroundColor: theme.palette.secondary.light,
    },
    contentWrapper: {
        maxWidth: 1200,
        width: '100%',
        margin: '0 auto',
    },
    subheadingText: {
        maxWidth: 600,
        margin: '0 auto',
    },
    link: {
        textDecoration: 'none',
        color: palette.tertiary.main,
    },
    title: {
        marginBottom: '40px',
    }
}));

const PricingSection = () => {
    const classes = useStyles();

    return (
        <section className={classes.section}>
            <Grid container justify="center">
                <Grid item lg={10} md={12} sm={12} xs={12} className={classes.contentWrapper}>
                    <div className={classes.content}>
                        <Typography component="h1" variant="h4" className={classes.title}>
                            Verified pricing
                        </Typography>
                        <Typography variant="body1">
                            <b>Verified membership is a simple low cost fee of £54 + VAT per month* and includes:</b>
                        </Typography>
                        <PricingList />
                    </div>
                </Grid>
            </Grid>
        </section>
    );
};

export default PricingSection;
