import React from 'react';
import { COMPANY_URLS, FIND_ADVISER_URLS, LEGAL_URLS, SOCIAL_URLS } from '../constants';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

const DATE = new Date();

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: theme.palette.primary.main,
        padding: `${theme.spacing(5)}px ${theme.spacing(4)}px`,

        [theme.breakpoints.down('xs')]: {
            padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
        },
    },
    anchor: {
        color: theme.palette.secondary.contrastText,
        textDecoration: 'none',
        fontSize: '1.2rem',
        '&:hover': {
            color: theme.palette.secondary.light,
        },
    },
    list: {
        paddingLeft: theme.spacing(1),
        listStyle: 'none',
        color: theme.palette.primary.contrastText,
        [theme.breakpoints.down('xs')]: {
            borderBottom: "1px solid #fff",
            paddingBottom: theme.spacing(3),
        },
    },
    listItem: {
        margin: 0,
    },
    listItemTitle: {
        color: theme.palette.secondary.main,
        textTransform: 'uppercase',
        fontSize: theme.spacing(2) - 2,
        marginBottom: theme.spacing(1),
    },
    copyrightSectionItem: {
        marginTop: theme.spacing(5),
        color: theme.palette.primary.contrastText,
    },
    white: {
        color: theme.palette.primary.contrastText,
    },
}));

const FooterColumn = props => {
    const { title, list } = props;
    const classes = useStyles();

    return (
        <ul className={classes.list}>
            <Typography component="li" variant="body1" className={classes.listItemTitle}>
                <b>{title}</b>
            </Typography>
            {list.map(item => (
                <Typography
                    component="li"
                    variant="body1"
                    key={item.url}
                    className={classes.listItem}>
                    <a className={classes.anchor} href={item.url} rel="noopener noreferrer">
                        {item.text}
                    </a>
                </Typography>
            ))}
        </ul>
    );
};

const Footer = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Grid container justify="center">
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <FooterColumn title="Find An Adviser" list={FIND_ADVISER_URLS} />
                </Grid>
                <Grid item lg={2} md={4} sm={6} xs={12}>
                    <FooterColumn title="Company" list={COMPANY_URLS} />
                </Grid>
                <Grid item lg={2} md={4} sm={6} xs={12}>
                    <FooterColumn title="Social" list={SOCIAL_URLS} />
                </Grid>
                <Grid item lg={2} md={4} sm={6} xs={12}>
                    <ul className={classes.list}>
                        <Typography
                            component="li"
                            variant="body1"
                            className={classes.listItemTitle}>
                            <b>Advisers</b>
                        </Typography>
                        <Typography component="li" variant="body1">
                            <a className={classes.anchor} href="/register">
                                Sign up&nbsp;
                            </a>
                            <span className={classes.white}>|</span>
                            <a className={classes.anchor} href="/login">
                                &nbsp;Log In
                            </a>
                        </Typography>
                    </ul>
                </Grid>
                <Grid item lg={2} md={4} sm={6} xs={12}>
                    <FooterColumn title="Legal & Cookies" list={LEGAL_URLS} />
                </Grid>
            </Grid>
            <div className={classes.copyrightSection}>
                <Typography variant="body1" className={classes.copyrightSectionItem}>
                    &copy; VouchedFor {DATE.getFullYear()}
                </Typography>
            </div>
        </div>
    );
};

export default Footer;
