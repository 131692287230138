import React from 'react';
import { Create, TopToolbar } from 'react-admin';
import BackButton from '../Components/Buttons/BackButton';
import FeesCreateForm from './Forms/FeesCreateForm';

const FeesCreate = props => {
    const { dispatch, ...rest } = props;

    return (
        <Create
            title={null}
            actions={
                <TopToolbar>
                    <BackButton href="/fees" />
                </TopToolbar>
            }
            {...rest}
        >
            <FeesCreateForm redirect="/fees" />
        </Create>
    );
};

export default FeesCreate;
