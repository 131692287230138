import React from 'react';
import _ from 'lodash';
import { useForm } from 'react-final-form';
import { useNotify } from 'react-admin';
import ErrorButton from '../../Components/Buttons/ErrorButton';
import dataProvider from '../../dataProvider';

const ServicesRemoveIntroductoryOfferButton = () => {
    const form = useForm();
    const notify = useNotify();
    const formValues = form.getState().values;
    const introductoryOfferId = _.get(formValues, 'attributes.introductory_offer');

    const onButtonClick = async () => {
        await dataProvider('DELETE', 'services/promotion', {
            id: introductoryOfferId,
        });

        form.change('attributes.introductory_offer', null);

        notify('Deletion complete');
    };

    if (!introductoryOfferId) return null;

    return <ErrorButton onClick={onButtonClick}>Remove Selected Offer</ErrorButton>;
};

export default ServicesRemoveIntroductoryOfferButton;
