import React from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import withStyles from '@material-ui/core/styles/withStyles';
import DashboardCardTooltip from './DashboardCardTooltip';

const propTypes = {
    header: PropTypes.any.isRequired,
    body: PropTypes.any.isRequired,
    toolTip: PropTypes.any,
    footer: PropTypes.any,
};

const styles = theme => ({
    root: {
        padding: 0,
        height: '100%',
    },
    content: {
        padding: 0,
        textAlign: 'center',
    },
    header: {
        position: 'relative',
        background: theme.palette.secondary.faint,
        padding: theme.spacing(5),

        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3),
        },
    },
    body: {
        padding: theme.spacing(5),
        paddingBottom: theme.spacing(2),

        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3),
        },
    },
    footer: {},
});

const DashboardCardBase = ({ header, tooltip, body, footer, classes }) => (
    <Card className={classes.root}>
        <CardContent className={classes.content}>
            <div className={classes.header}>
                {header}
                {tooltip && <DashboardCardTooltip title={tooltip} />}
            </div>
            <div className={classes.body}>{body}</div>
            {footer && <div className={classes.footer}>{footer}</div>}
        </CardContent>
    </Card>
);

DashboardCardBase.propTypes = propTypes;

export default withStyles(styles)(DashboardCardBase);
