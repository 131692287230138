import React, { useState } from 'react';
import _ from 'lodash';
import { SimpleForm, FormDataConsumer } from 'react-admin';
import { Redirect } from 'react-router-dom';
import { FormSpy } from 'react-final-form';
import FootNote from '../../Components/Typography/FootNote';
import ReviewInviteRecipientFields from '../Content/ReviewInviteRecipientFields';
import ReviewInviteDialogModal from '../Content/ReviewInviteDialogModal';
import FirstInvitationEmailTemplate from './FirstInvitationEmailTemplate';
import { FormSectionTitle } from '../../Components/Typography/Headlines';

import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    root: {
        '& > div': {
            padding: '0 !important',
        },
    },

    center: {
        textAlign: 'center',
    },
    inviteFields: {
        width: '100%',
        flexDirection: 'column',
    },
    input: {
        '& > label': {
            zIndex: 1,
        },

        '& > .MuiInputBase-root': {
            borderRadius: 4,
            backgroundColor: theme.palette.primary.contrastText,
        },
    },
    note: {
        marginBottom: theme.spacing(5),
    },
});

const FirstInvitationForm = props => {
    const { classes } = props;
    const [sentInvite, setSentInvite] = useState(false);
    const [invalidForm, setInvalidForm] = useState(false);

    if (sentInvite) {
        return <Redirect to="/" />;
    }

    return (
        <SimpleForm toolbar={null} className={classes.root}>
            <FormSpy
                subscription={{ invalid: true }}
                onChange={({ invalid }) => {
                    setInvalidForm(invalid);
                }}
            />
            <>
                <FormSectionTitle
                    title="Invite your first client review using our invite template:"
                    className={classes.center}
                />
            </>
            <ReviewInviteRecipientFields
                className={classes.inviteFields}
                inputProps={{
                    fullWidth: true,
                    className: classes.input,
                    inline: false,
                }}
            />
            <FirstInvitationEmailTemplate />
            <FootNote
                className={classes.note}
                content={
                    <span>
                        you should have your client's permission to contact them before entering
                        their email address here. We will only use it for the purpose of sending the
                        request, unless they opt in to further communications once they visit the
                        website.
                    </span>
                }
            />
            <FormDataConsumer>
                {({ formData }) => {
                    const record = {
                        email_template: _.get(formData, 'email_template'),
                        clients: [
                            {
                                client_first_name: _.get(formData, 'client_first_name'),
                                client_email: _.get(formData, 'client_email'),
                                type: 'review',
                            },
                        ],
                    };

                    return (
                        <div className={classes.center}>
                            <ReviewInviteDialogModal
                                buttonLabel="Invite Client"
                                title="Confirm & Send Review Invitation"
                                saveButtonLabel="OK, Send Invitation"
                                values={record}
                                onSuccess={() => setSentInvite(true)}
                                invalidForm={invalidForm}
                            />
                        </div>
                    );
                }}
            </FormDataConsumer>
        </SimpleForm>
    );
};

export default withStyles(styles)(FirstInvitationForm);
