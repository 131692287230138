import React from 'react';
import { FormSectionTitle } from '../../../Components/Typography/Headlines';
import { Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    category: {
        marginBottom: theme.spacing(5),
        width: '90%',
    },
    gridWrapper: {
        marginTop: theme.spacing(5),
    },
    divider: {
        marginBottom: theme.spacing(5),
    },
}));

const ReputationToolCategory = ({ title, description, titleFontSize, children }) => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.category}>
                <FormSectionTitle title={title} style={{fontSize:`${titleFontSize}`}} />
                <Typography>{description}</Typography>
                <div className={classes.gridWrapper}>
                    <Grid container spacing={5}>
                        {children}
                    </Grid>
                </div>
            </div>
            <Divider className={classes.divider} />
        </>
    );
};

export default ReputationToolCategory;
