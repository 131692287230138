import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import dataProvider from '../../dataProvider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import { FacebookIcon, TwitterIcon, LinkedInIcon } from '../../icons/icons';

export const SOCIAL_MEDIA_TYPE = {
    FACEBOOK: 'facebook',
    TWITTER: 'twitter',
    LINKEDIN: 'linked-in',
};

const SOCIAL_BUTTONS = [
    {
        id: SOCIAL_MEDIA_TYPE.FACEBOOK,
        color: '#3C5A99',
        icon: <FacebookIcon />,
    },
    {
        id: SOCIAL_MEDIA_TYPE.TWITTER,
        color: '#1DA1F2',
        icon: <TwitterIcon />,
    },
    {
        id: SOCIAL_MEDIA_TYPE.LINKEDIN,
        color: '#0077B5',
        icon: <LinkedInIcon />,
    },
];

const propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
};

const defaultProps = {
    title: '',
    children: null,
};

const styles = theme => ({
    container: {
        margin: `${theme.spacing(4)}px 0 ${theme.spacing(3)}px`,
    },
    buttonRow: {
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        marginRight: theme.spacing(3),
    },
    buttons: {
        display: 'flex',
        margin: 0,
        padding: 0,
        listStyle: 'none',
    },
    button: {
        height: theme.spacing(4),
        width: theme.spacing(4),
        marginRight: theme.spacing(2),
    },
    badge: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& > svg': {
            width: 20,
        },
    },
    content: {},
});

class ShareButtons extends Component {
    onButtonClick = id => e => {
        this.props.onShareClick(id);

        dataProvider('CREATE', 'reputation/usage/shared-last-review', {
            data: {},
        });
    };

    render() {
        const { containerStyle, title, children, classes } = this.props;

        return (
            <div className={cn(classes.container, containerStyle)}>
                <div className={classes.buttonRow}>
                    {title.length > 0 && (
                        <Typography className={classes.title}>
                            <b>{title}</b>
                        </Typography>
                    )}
                    <ul className={classes.buttons}>
                        {SOCIAL_BUTTONS.map(button => (
                            <li key={button.id}>
                                <IconButton
                                    className={classes.button}
                                    style={{ background: button.color }}
                                    onClick={this.onButtonClick(button.id)}
                                >
                                    <div className={classes.badge}>{button.icon}</div>
                                </IconButton>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={classes.content}>{children && children}</div>
            </div>
        );
    }
}

ShareButtons.defaultProps = defaultProps;
ShareButtons.propTypes = propTypes;

export default withStyles(styles)(ShareButtons);
