import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formatPlural } from '../../../utils';

import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '../../../Components/Buttons/CustomButton';
import ContentTooltip from '../../../Components/ConsumerReactComponents/ContentTooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { ROOT_DOMAIN } from '../../../constants';

const propTypes = {
    rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    reviewAndFICount: PropTypes.number,
};

const styles = theme => ({
    ratingReviews: {
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        },
    },
    ratingWrap: {
        display: 'flex',
        alignItems: 'center',
    },
    ratingText: {
        marginTop: 0,
    },
    rating: {
        marginRight: theme.spacing(1.5),
        marginLeft: theme.spacing(1.5),
    },
    reviewText: {
        textDecoration: 'none',
        fontSize: '0.83em',

        [theme.breakpoints.down('sm')]: {
            margin: `${theme.spacing(1)}px 0 0`,
        },
    },
    toolTipWrapper: {
        '& svg': {
            left: 4,
            bottom: 0,
        },
    },
    button: {
        marginTop: theme.spacing(1),

        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
        },
    },
});

const RatingPanel = ({
    loading,
    rating,
    reviewAndFICount,
    permalink,
    firstImpressionCount,
    recentReviewAndFiCount,
    recentReviewDate,
    reviewCount,
    classes,
}) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const closeTooltip = () => setTooltipOpen(false);
    const openTooltip = () => setTooltipOpen(true);

    const reviewTextPlural = formatPlural(reviewAndFICount, 'review', 'reviews');

    return (
        <Grid item xs={12} md="auto">
            <div className={classes.ratingReviews}>
                <div className={classes.ratingWrap}>
                    {loading ? (
                        <Skeleton animation={'pulse'} height={40} width={180} />
                    ) : (
                        Number(rating) > 0 && (
                            <Typography
                                component="h3"
                                color="secondary"
                                className={classes.ratingText}
                            >
                                <b>{`Your rating: ${rating} / 5`}</b>
                            </Typography>
                        )
                    )}
                </div>
                <Typography variant="body1" color="primary" className={classes.reviewText}>
                    {loading ? (
                        <Skeleton animation="pulse" width={220} height={25} />
                    ) : (
                        <>
                            <b>
                                {reviewAndFICount}&nbsp;{reviewTextPlural}
                            </b>
                            <span>
                                {` (${reviewCount} from clients and ${firstImpressionCount} from prospects)`}
                            </span>
                        </>
                    )}
                </Typography>
                <Typography variant="body1" color="primary" className={classes.reviewText}>
                    {loading ? (
                        <Skeleton animation="pulse" width={220} height={25} />
                    ) : (
                        <>
                            {`${recentReviewAndFiCount} recent reviews`}
                            <span className={classes.toolTipWrapper}>
                                <ClickAwayListener onClickAway={closeTooltip}>
                                    <ContentTooltip
                                        placement="right"
                                        title="This is the number of reviews the adviser has received in the last 12 months"
                                        open={tooltipOpen}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        onClick={openTooltip}
                                        onClose={closeTooltip}
                                    />
                                </ClickAwayListener>
                            </span>
                        </>
                    )}
                </Typography>
                {loading ? (
                    <Skeleton animation="pulse" width={220} height={25} />
                ) : (
                    Number(rating) > 0 && (
                        <Typography
                            variant="body1"
                            color="primary"
                            className={classes.reviewText}
                        >
                            {`Last review: ${recentReviewDate} days ago`}
                        </Typography>
                    )
                )}
                {!loading && (
                    <Button
                        variant="outlined"
                        color="tertiary"
                        href={`${ROOT_DOMAIN}/${permalink}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.button}
                    >
                        View Your Profile
                    </Button>
                )}
            </div>
        </Grid>
    );
};

RatingPanel.propTypes = propTypes;

export default withStyles(styles)(RatingPanel);
