import React from 'react';
import DialogFormSubmit from '../../Components/Dialogs/DialogFormSubmit';
import { showNotification } from 'react-admin';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import * as analytics from '../../analytics';
import {
    REVIEW_INVITE_TYPE_CLIENT,
    REVIEW_INVITE_TYPE_PROSPECT,
    REVIEW_INVITE_TYPE_REVIEWS,
} from '../Invite/ReviewInviteContent';

const ReviewInviteDialogModal = props => {
    const {
        type,
        buttonLabel,
        title,
        values,
        invalidForm = true,
        saveButtonLabel = '',
        onSuccess = () => {},
        handleError = () => {},
        resource = 'review-invitations',
    } = props;
    const dispatch = useDispatch();

    return (
        <>
            <DialogFormSubmit
                buttonLabel={buttonLabel}
                title={title}
                disabled={invalidForm}
                resource={resource}
                type="CREATE"
                saveButtonLabel={saveButtonLabel}
                values={values}
                handleSuccess={() => {
                    const labelMap = {
                        [REVIEW_INVITE_TYPE_PROSPECT]: 'invited 1',
                        [REVIEW_INVITE_TYPE_CLIENT]: 'invited multiple',
                        [REVIEW_INVITE_TYPE_REVIEWS]: 'imported'
                    };

                    analytics.track('complete', {
                        category: 'InviteClient',
                        label: labelMap[type],
                    });

                    dispatch(showNotification('ra.notification.invitation_sent'));

                    onSuccess();
                }}
                handleError={() => {
                    dispatch(showNotification('ra.notification.bad_item', 'warning'));

                    handleError();
                }}
                message={
                    <Typography>
                        Please note we have a rigorous approach to checking reviews. This includes spot
                        checks where we may ask you to provide evidence that the reviewer is a client or
                        prospect of yours
                    </Typography>
                }
            />
        </>
    );
};

export default ReviewInviteDialogModal;
