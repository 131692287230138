import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import CheckboxInput from '../Inputs/CheckboxInput';

const propTypes = {
    component: PropTypes.func,
};

const defaultProps = {
    component: CheckboxInput,
    disabled: false,
};

const TrueFalseInput = ({
    label,
    source,
    component,
    defaultValue,
    disabled,
}) => (
    <Field
        name={source}
        component={component}
        label={label}
        defaultValue={defaultValue}
        disabled={disabled}
    />
);

TrueFalseInput.propTypes = propTypes;
TrueFalseInput.defaultProps = defaultProps;

export default TrueFalseInput;
