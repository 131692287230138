import React from 'react';
import {
    ReferenceManyField,
    Datagrid,
    TextField,
    EditButton,
    DeleteButton,
} from 'react-admin';
import { useNotify } from 'react-admin';
import { MORTGAGE_SERVICES_ATTRIBUTE_ID, WEALTH_CRITERIA_ASSOCIATED_SERVICES } from "../constants";

export const CustomDeleteButton = ({record}) => {
    const notify = useNotify();

    const OnFailure = () => {
        notify('This service cannot be deleted', 'warning' );
    };

    if (!record || record.id == null) {
        return null;
    }

    if (record.attribute_id === MORTGAGE_SERVICES_ATTRIBUTE_ID) {
        return (
            <DeleteButton
                record={record}
                redirect={false}
                icon={null}
                undoable={false}
                onFailure={OnFailure}
                confirmContent="Deleting mortgage services will also delete mortgage criteria, as one cannot exist without the other. Are you sure you want to delete this attribute?"
            />
        );
    }

    if (WEALTH_CRITERIA_ASSOCIATED_SERVICES.includes(record.attribute_id)) {
        return (
            <DeleteButton
                record={record}
                redirect={false}
                icon={null}
                undoable={false}
                onFailure={OnFailure}
                confirmContent="Deleting this service will also delete wealth criteria if you offer no other wealth criteria associated service. Are you sure you want to delete this attribute?"
            />
        );
    }

    return (
        <DeleteButton
            record={record}
            redirect={false}
            icon={null}
            undoable={false}
            onFailure={OnFailure}
        />
    );
};

const ServicesGrid = props => {
    return (
        <ReferenceManyField
            {...props}
            label={null}
            reference="services"
            target="professional_id"
        >
            <Datagrid>
                <TextField label="Service" source="attribute_name" />
                <TextField
                    label="Status"
                    source="service_state_name"
                    defaultValue=""
                />
                <EditButton color="secondary" icon={null} />
                <CustomDeleteButton />
            </Datagrid>
        </ReferenceManyField>
    );
};

export default ServicesGrid;
