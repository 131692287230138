import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { FormSectionTitle } from '../../Components/Typography/Headlines';
import AddFeeLevelButton from '../Forms/AddFeeLevelButton';
import ActionButton from '../../Components/Buttons/ActionButton';

import Typography from '@material-ui/core/Typography';
import MortgageFeesDataGrid from './MortgageFeesDataGrid';

const MortgageFeesLevels = ({ isLoggedIn, ...props }) => {
    const mortgageChargeLevels = _.get(
        props,
        ['record', 'content', 'mortgage', 'chargeLevels'],
        []
    );
    const mortgageFeeId = _.get(props, ['record', 'content', 'mortgage', 'id'], null);

    return (
        <React.Fragment>
            <br />
            <FormSectionTitle title="Mortgage Fees" />
            {_.isEmpty(mortgageChargeLevels) ? (
                <Typography variant="body1">You haven't added any mortgage fee levels</Typography>
            ) : (
                <MortgageFeesDataGrid feeId={mortgageFeeId} />
            )}
            <AddFeeLevelButton
                to={{
                    pathname: '/mortgage-fee-levels/create',
                    state: {
                        feeId: mortgageFeeId,
                    },
                }}
            />
            {isLoggedIn && (
                <ActionButton
                    record={_.get(props, ['record', 'content', 'mortgage'])}
                    label="Remove this fee type"
                    resource="fees"
                    type="DELETE"
                />
            )}
        </React.Fragment>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        isLoggedIn: _.get(basicInfo, 'is_logged_in', false),
    };
};

export default connect(mapStateToProps)(MortgageFeesLevels);
