import React, { Component } from 'react';
import _ from 'lodash';
import {
    Responsive,
    Datagrid,
    TextField,
    DateField,
    EditButton,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { YesNoTextField } from '../Components/ResourcePageComponents';
import SimpleList from '../Components/Lists/SimpleList';
import FirmPageWrapper from './FirmPageWrapper';

class ReviewListSmall extends Component {
    render() {
        const { dispatch, record, ...props } = this.props;

        return (
            <>
                <Typography variant="h5">
                    Reviews for all advisers linked to your firm
                </Typography>
                <SimpleList
                    primaryText={record =>
                        `Reviewer Name: ${_.get(record, 'client_fullname', '')}`
                    }
                    secondaryText={record =>
                        `Reviewer Email: ${_.get(record, 'client_email', '')}`
                    }
                    tertiaryText={record =>
                        `Adviser Name: ${_.get(
                            record,
                            'professional.full_name',
                            ''
                        )}`
                    }
                    {...props}
                />
            </>
        );
    }
}

class ReviewListContent extends Component {
    render() {
        const { ...props } = this.props;

        return (
            <>
                <Typography variant="h5">
                    Reviews for all advisers linked to your firm
                </Typography>
                <Typography variant="body1">
                    To select featured reviews to use in your firm reviews
                    widget, click ‘view/edit’ and select the option on the
                    next page
                </Typography>
                <Datagrid {...props}>
                    <DateField
                        label="Date"
                        source="submitted_at"
                        sortable={false}
                    />
                    <TextField
                        label="Reviewer Name"
                        source="customer.name"
                        sortable={false}
                    />
                    <TextField
                        label="Reviewer email"
                        source="customer.email"
                        sortable={false}
                    />
                    <TextField
                        label="Adviser Name"
                        source="professional.full_name"
                        sortable={false}
                    />
                    <YesNoTextField
                        label="In widget?"
                        source="featured"
                        sortable={false}
                    />
                    <EditButton color="secondary" />
                </Datagrid>
            </>
        );
    }
}

const FirmReviews = ({ classes, ...props }) => {
    return (
        <FirmPageWrapper
            variant="list"
            filter={{ type: 'review' }}
            {...props}
        >
            <Responsive
                small={<ReviewListSmall />}
                medium={<ReviewListContent />}
            />
        </FirmPageWrapper>
    );
};

export default FirmReviews;
