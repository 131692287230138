import React from 'react';
import {
    REPUTATION_STARS,
    REPUTATION_SHARE,
    REPUTATION_PEOPLE,
} from '../../constants';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {palette} from "../../MyTheme";
import {CallToActionButton} from "../../Components/Buttons/Buttons";
import {makeStyles} from "@material-ui/core";
import CustomIcon from '../../icons/CustomIcon';

const REPUTATION_POINTS = [
    {
        icon: <CustomIcon color="blue"  icon="share" />,
        boldTitle: 'Gain more referrals',
        title: ' as existing clients have greater faith in your service',
        text: '83% of clients are more likely to refer their adviser to friends, having read their VouchedFor reviews',
    },
    {
        icon: <CustomIcon color="mint"  icon="star" />,
        boldTitle: 'Increase conversion',
        title: ' of prospective clients by showcasing the service you offer',
        text: '1 in 3 new clients find and read an adviser’s VouchedFor reviews, before making contact',
    },
    {
        icon: <CustomIcon color="green"  icon="people" />,
        boldTitle: 'Improve retention',
        title: ' of clients (and staff) who see your great feedback',
        text: '',
    },
];

const useStyles = makeStyles(theme => ({
    container: {
        padding: `${theme.spacing(10)}px ${theme.spacing(8)}px`,
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
            padding: `${theme.spacing(5)}px ${theme.spacing(2)}px`,
        },
    },
    subHeading: {
        maxWidth: theme.spacing(105),
        margin: '0 auto',
    },
    list: {
        marginTop: theme.spacing(4),
        listStyle: 'none',
        padding: 0,
    },
    listItem: {
        textAlign: 'center',

        [theme.breakpoints.only('sm')]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },

        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(3),
        },

        [theme.breakpoints.down('xs')]: {
            margin: -theme.spacing(1),
        }
    },
    itemTitle: {
        marginBottom: theme.spacing(2),

        [theme.breakpoints.down('sm')]: {
            marginBottom: '5px',
        },

        [theme.breakpoints.up('md')]: {
            minHeight: '64px',
        },
    },
    itemContent: {
        [theme.breakpoints.only('sm')]: {
            textAlign: 'left',
            width: '88%',
        },
    },
    icon: {
        height: theme.spacing(6),
        marginBottom: theme.spacing(2),

        [theme.breakpoints.only('sm')]: {
            marginRight: theme.spacing(2),
            marginBottom: 0,
        },

        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1),
        }
    },
    link: {
        textDecoration: 'none',
    },
    heading: {
        paddingBottom: theme.spacing(3),
    },
    findOutMoreButton: {
        paddingTop: theme.spacing(3),
    },
    lastSubHeading: {
        maxWidth: theme.spacing(105),
        margin: '0 auto',
        paddingTop: '20px',
    }
}));

const RegisterReputationPowerSection = ({ handleClick }) => {
    const classes = useStyles();

    return (
        <section className={classes.container}>
            <Grid container justify="center">
                <Grid item lg={10} md={12} sm={12} xs={12} id="verified-anchor">
                    <Typography color="inherit" component="h1" variant="h4" className={classes.heading}>
                        Unlock the full potential of VouchedFor, with Verified membership
                    </Typography>
                    <Typography variant="body1" className={classes.subHeading}>
                        Verified membership gives you a public profile on <a className={classes.link} target="_blank" href="https://www.vouchedfor.co.uk/">VouchedFor.co.uk</a>, the UK’s leading review site for financial advisers.                         Being a VouchedFor adviser gives clients the confidence to choose you as their adviser - it’s a sign that you’re committed to delivering the best possible client experience.
                    </Typography>
                    <Typography variant="body1" className={classes.lastSubHeading}>
                        VouchedFor advisers:
                    </Typography>
                    <Grid container spacing={2} className={classes.list} component="ul">
                        {
                            REPUTATION_POINTS.map(({ icon, boldTitle, title, text }) =>
                                <Grid item xs={12} md={4} component="li" className={classes.listItem} key={title}>
                                    { icon }
                                    {/* <img className={classes.icon} src={image} alt={title} /> */}
                                    <div className={classes.itemContent}>
                                        <Typography className={classes.itemTitle} component="h3" variant="h5" color="textPrimary">
                                            <b>{ boldTitle }</b> { title }
                                        </Typography>
                                        <Typography variant="body1">
                                            { text }
                                        </Typography>
                                    </div>
                                </Grid>
                            )
                        }
                    </Grid>
                    <Grid item className={classes.findOutMoreButton}>
                        <CallToActionButton onClickAction={handleClick("register-form")}>
                            Find out more
                        </CallToActionButton>
                    </Grid>
                </Grid>
            </Grid>
        </section>
    );
};

export default RegisterReputationPowerSection;
