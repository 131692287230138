import React from 'react';
import {
    Edit,
    SimpleForm,
    required,
    FormDataConsumer,
} from 'react-admin';

import withStyles from '@material-ui/core/styles/withStyles';
import { futureDateValidation } from '../validation';

import TrueFalseInput from '../Components/Forms/TrueFalseInput';
import FormDateInput from '../Components/Inputs/Form/FormDateInput';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
    form: {
        '& .MuiToolbar-root': {
            padding: 0,
            marginTop: 0,
        },
    },
    formContent: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(2),
        
        '& .MuiFormControl-root': {
            margin: `${theme.spacing(2)}px 0 0 !important`,
            width: 'auto !important',
        },
    },
    inputWrap: {
        height: '100%',
    },
});

const ReviewAutoInviteEdit = props => {
    const redirectUrl = `/review-setting`;
    const { classes } = props;

    if (props.record?.is_transferred_client_review)
    return (
        <Typography variant="h5" color="error">
            Annual auto invite cannot be set for reviews from Transferred Clients.
        </Typography>
    );

    return (
        <Edit {...props}>
            <SimpleForm
                redirect={redirectUrl}
                className={classes.form}
            >
                <FormDataConsumer>
                    {({ formData }) => {
                        return (
                            <div className={classes.formContent}>
                                <TrueFalseInput
                                    source="annual_auto_invite"
                                    label="Annual auto invite"
                                />
                                <FormDateInput
                                    label="Next invite date"
                                    source="calculated_next_invite_date"
                                    validate={[
                                        futureDateValidation,
                                        required(),
                                    ]}
                                    style={{
                                        display: formData.annual_auto_invite
                                            ? 'block'
                                            : 'none',
                                        margin: '0 16px',
                                    }}
                                />
                            </div>
                        );
                    }}
                </FormDataConsumer>
            </SimpleForm>
        </Edit>
    );
};

export default withStyles(styles)(ReviewAutoInviteEdit);
