import AccountSettings from './AccountSettings';
import BillingShow from './Billing/BillingShow';
import BillingList from './Billing/BillingList';
import DirectDebit from './DirectDebit';

export default {
    directDebit: DirectDebit,
    accountSettings: AccountSettings,
    billing: {
        show: BillingShow,
        list: BillingList,
    },
};
