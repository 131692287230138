import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'react-admin';

import Card from '@material-ui/core/Card';
import withStyles from '@material-ui/core/styles/withStyles';
import CardContent from '@material-ui/core/CardContent';

const propTypes = {
    /**
     * The header component. In almost all cases this should be a usage of PageActions.js
     */
    header: PropTypes.any,
    /**
     * Pagination for List pages. By default pagination will always show.
     */
    pagination: PropTypes.any,
};

const defaultProps = {
    header: null,
};

const styles = () => ({
    root: {
        boxShadow: 'none',

        '& .MuiPaper-root': {
            padding: 0,
            boxShadow: 'none',
        },
    },
});

const AppLayoutList = ({ classes, children, header, pagination, ...rest }) => {
    return (
        <Card>
            <CardContent className={classes.root}>
                {header}
                <List
                    title={null}
                    pagination={pagination}
                    bulkActionButtons={false}
                    actions={null}
                    {...rest}
                >
                    {children}
                </List>
            </CardContent>
        </Card>
    );
};

AppLayoutList.propTypes = propTypes;
AppLayoutList.defaultProps = defaultProps;

export default withStyles(styles)(AppLayoutList);
