import React, { useEffect } from 'react';
import _ from 'lodash';
import { history } from '../../App';
import { FormSectionTitle } from '../../Components/Typography/Headlines';
import FinancialPlanningFeesLevels from './FinancialPlanningFeesLevels';
import InvestmentPensionsFeesLevels from './InvestmentPensionsFeesLevels';
import MortgageFeesLevels from './MortgageFeesLevels';
import FeesListActions from '../FeesListActions';
import { ExternalLink } from '../../Components/Links';

import Typography from '@material-ui/core/Typography/index';

import { SUPPORT_URL } from '../../constants/external-urls';

const defaultProps = {
    isLoggedIn: true,
    setRegisterData: () => {},
};

const FeesListContent = props => {
    const { isLoggedIn, setRegisterData, ...rest } = props;
    const hasMortgageFees = _.has(rest, ['record', 'content', 'mortgage']);
    const hasInvestmentFees = _.has(rest, ['record', 'content', 'investment']);
    const hasFinancialPlanningFees = _.has(rest, [
        'record',
        'content',
        'financialPlanning',
    ]);
    const feesAddedCount = Object.keys(_.get(rest, ['record', 'content'], {}))
        .length;

    useEffect(() => {
        if (isLoggedIn) {
            history.replace(`/fees`, { parentSidebarItem: 'fees-insights' });
        }

        setRegisterData({
            hasFinancialPlanningFees,
            hasInvestmentFees,
            record: rest.record,
        });
        /** @todo hook needs cleaning up so we don't have to add the eslint-disable-next-line comment */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rest.record]);

    const generateFeesText = isLoggedIn => {
        if (isLoggedIn) {
            return (
                <>
                    Add your fees to your profile and we'll make it easy for
                    prospective clients to view examples of what you would
                    charge at different wealth levels. Please note, your fees
                    should exclude any platform charges. For help on how to add
                    fees,{' '}
                    <ExternalLink href={SUPPORT_URL.FEES.ADD_YOUR_FEES}>
                        read our article here
                    </ExternalLink>
                </>
            );
        } else {
            return 'Next, add fee levels to let us know what your fees are at different asset levels';
        }
    };

    return (
        <React.Fragment>
            <FormSectionTitle title="Your Fee Data" />
            <Typography variant="body1">
                {generateFeesText(isLoggedIn)}
            </Typography>
            {isLoggedIn && hasMortgageFees && <MortgageFeesLevels {...rest} />}
            {hasFinancialPlanningFees && (
                <FinancialPlanningFeesLevels {...rest} />
            )}
            {hasInvestmentFees && (
                <InvestmentPensionsFeesLevels
                    hasFinancialPlanningFees={hasFinancialPlanningFees}
                    {...rest}
                />
            )}
            <br />
            <FeesListActions
                feesAddedCount={feesAddedCount}
                hasFeesAdded={feesAddedCount > 0}
            />
            {rest.children}
        </React.Fragment>
    );
};

FeesListContent.defaultProps = defaultProps;

export default FeesListContent;
