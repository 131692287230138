export const forgottenPasswordStyles = theme => ({
    forgotContainer: {
        padding: '40px 0',
        maxWidth: '500px',
        margin: '0 auto',
        textAlign: 'center',
        minHeight: '40vh',
        alignItems: 'center',
        flexDirection: 'column',
        '& p': {
            marginBottom: '0',
        },
    },
    forgotSimpleForm: {
        marginTop: 40,

        '& > div': {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: '0px !important',
        },
        '& span': {
            width: '100%',
        },
        '& > div > div': {
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 384,
            width: '100%',
            margin: '0 auto',
        },
    },
    forgotSimpleFormInput: {
        width: '100%',
        margin: 0,
    },
    skeletonTop: {
        marginBottom: 16,
    },
});
