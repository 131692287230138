import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Button from '../../../Components/Buttons/CustomButton';
import * as analytics from '../../../analytics';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles(theme => ({
    greenBackground: {
        backgroundColor: theme.palette.secondary.light,
        boxShadow:
            'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset',
    },
    root: {
        padding: theme.spacing(2),
        width: '100%',
    },
    margin: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
    },
    tipAndTextWrapper: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        },
    },
    text: {
        fontSize: '1.2rem',
        // lineHeight: '1.2rem',
        fontWeight: 700,
        alignSelf: 'center',
        flex: 1,
        margin: 0
    },
    tipWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(2),
        marginRight: theme.spacing(1),
        color: theme.palette.tertiary.main,
        '& .MuiSvgIcon-root': {
            fontSize: theme.spacing(4)
        }
    },
    buttonWrapper: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(2),
        justifyContent: 'center',
    },
    buttons: {
        width: 500,
        maxWidth: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        '& .MuiButtonBase-root': {
            [theme.breakpoints.down('sm')]: {
                marginBottom: theme.spacing(2),
            },
        },
    },
    singleButton: {
        alignSelf: 'center !important',
    },
}));

const Tip = props => {
    const classes = useStyles();
    const {
        text,
        showInviteButton,
        showRepButton,
        whiteBackground,
        inviteOnClick,
        repOnClick,
        margin = true,
        inviteButtonLabel = 'Invite Reviews',
        singleButtonNewLine = false
    } = props;
    const showBothButtons = showInviteButton && showRepButton ? true : false;

    const renderButtons = () => {
        if (showInviteButton && showRepButton) {
            return (
                <div className={classes.buttonWrapper}>
                    <div className={classes.buttons}>
                        {renderInviteButton()}
                        {renderRepButton()}
                    </div>
                </div>
            );
        } else if (showInviteButton && !showRepButton) {
            if (singleButtonNewLine) {
                return (<div className={classes.buttonWrapper}>
                    <div className={classes.buttons}>
                        {renderInviteButton()}
                    </div>
                </div>)
            }
            return <div className={classes.singleButton}>{renderInviteButton()}</div>;
        } else if (showRepButton && !showInviteButton) {
            if (singleButtonNewLine) {
                return (<div className={classes.buttonWrapper}>
                    <div className={classes.buttons}>
                        {renderRepButton()}
                    </div>
                </div>)
            }
            return <div className={classes.singleButton}>{renderRepButton()}</div>;
        }
        return null;
    };

    const renderInviteButton = () => (
        <Button
            onClick={() => {
                if (inviteOnClick) inviteOnClick();
                handleButtonClick(inviteButtonLabel)
            }}
            variant="contained"
            color="tertiary"
            href="/invite-reviews">
            {inviteButtonLabel}
        </Button>
    );
    const renderRepButton = () => (
        <Button
            onClick={() => {
                if (repOnClick) repOnClick();
                handleButtonClick('Use Rep Tools')
            }}
            variant="contained"
            color="tertiary"
            href="/reputation">
            Use Rep Tools
        </Button>
    );

    const handleButtonClick = (label) => analytics.track(label, { category: 'tip_button_clicked' });

    return (
        <>
            <div className={`${classes.root} ${margin ? classes.margin : ''} ${whiteBackground ? '' : classes.greenBackground}`}>
                <div className={classes.tipAndTextWrapper}>
                    <div className={classes.tipWrapper}><InfoIcon size="large" /></div>
                    <Typography className={classes.text}>{text}</Typography>
                    {(!showBothButtons && !singleButtonNewLine) && renderButtons()}
                </div>
                {(showBothButtons || singleButtonNewLine) && renderButtons()}
            </div>
        </>
    );
};

export default Tip;
