import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import InvestmentFeesDataGrid from './InvestmentFeesDataGrid';
import AddFeeLevelButton from '../Forms/AddFeeLevelButton';
import FeesDeleteButton from '../Forms/FeesDeleteButton';
import InputPrefix from '../../Components/Inputs/InputPrefix';
import ActionButton from '../../Components/Buttons/ActionButton';
import { FormSectionTitle } from '../../Components/Typography/Headlines';
import { FEE_SUB_TYPE } from '../../constants';

import Typography from '@material-ui/core/Typography';
import FormNumberInput from '../../Components/Inputs/Form/FormNumberInput';

const { HOURLY_CHARGE } = FEE_SUB_TYPE;

const InvestmentPensionsFeesLevels = ({ hasFinancialPlanningFees, isLoggedIn, ...props }) => {
    const feeType = hasFinancialPlanningFees
        ? 'Implementation & Ongoing Service'
        : 'Implementation & Ongoing Service (without Financial Planning)';
    const investmentChargeLevels = _.get(
        props,
        ['record', 'content', 'investment', 'chargeLevels'],
        []
    );
    const investmentFeeSubType = _.get(props, ['record', 'content', 'investment', 'subType'], '');
    const investmentFeeId = _.get(props, ['record', 'content', 'investment', 'id'], null);

    return (
        <React.Fragment>
            <br />
            <FormSectionTitle title={`${feeType} Fees`} />
            {investmentFeeSubType === HOURLY_CHARGE ? (
                <FormNumberInput
                    disabled
                    source="content.investment.amount"
                    label="Your average hourly fee amount"
                    InputProps={{ startAdornment: <InputPrefix label="£" /> }}
                />
            ) : (
                <React.Fragment>
                    {_.isEmpty(investmentChargeLevels) ? (
                        <Typography variant="body1">
                            {`You haven't added any ${
                                hasFinancialPlanningFees
                                    ? 'implementation & ongoing service'
                                    : 'investment'
                            } fee levels`}
                        </Typography>
                    ) : (
                        <InvestmentFeesDataGrid
                            feeId={investmentFeeId}
                            feeType={feeType}
                            subType={investmentFeeSubType}
                            isLoggedIn={isLoggedIn}
                        />
                    )}
                    <AddFeeLevelButton
                        variant={
                            !_.isEmpty(investmentChargeLevels) && !isLoggedIn ? 'text' : 'contained'
                        }
                        to={{
                            pathname: isLoggedIn
                                ? '/investment-fee-levels/create'
                                : '/register',
                            state: {
                                feeType,
                                investmentFeeSubType,
                                feeId: investmentFeeId,
                            },
                        }}
                    />
                </React.Fragment>
            )}
            {isLoggedIn && (
                <React.Fragment>
                    {hasFinancialPlanningFees ? (
                        <FeesDeleteButton
                            record={props.record}
                            feeTypeToDelete="Financial Planning"
                        />
                    ) : (
                        <ActionButton
                            record={_.get(props, ['record', 'content', 'investment'])}
                            label="Remove this fee type"
                            resource="fees"
                            type="DELETE"
                        />
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        isLoggedIn: _.get(basicInfo, 'is_logged_in', false),
    };
};

export default connect(mapStateToProps)(InvestmentPensionsFeesLevels);
