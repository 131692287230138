import React from 'react';
import _ from 'lodash';
import { ShareViewButton } from '../../Components/Buttons/Buttons';
import { showViewEditButton, STATE } from '../../constants';

const { DISPUTED_CLIENT } = STATE;

const ShareReviewButton = props => {
    const record = _.get(props, 'record', null);

    if (record && record.current_review_state === DISPUTED_CLIENT) return null;

    if (record && record.review_disabled === true) {
        return <ShareViewButton {...props} disabled={true} label="Share review" />;
    }
    if (record && record.c_visible && showViewEditButton(record.current_review_state)) {
        return <ShareViewButton {...props} label="Share review"  />;
    }

    return null;
};

export default ShareReviewButton;
