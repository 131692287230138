import React, { useState, useEffect } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import {
    Box, Button, DialogContent, DialogActions,
    Dialog
} from '@material-ui/core';
import { useDataProvider, useNotify } from 'react-admin';
import ElevationStatsContent from './Elevation/ElevationStatsContent';
import DriverStatistics from './Elevation/DriverStatistics';
import { makeStyles } from '@material-ui/core/styles';
import Spinner from '../Components/Spinner';
import DateRangeCustom from './Elevation/Components/DateRange';
import { formatRange } from '../utils';
import Grid from '@material-ui/core/Grid';
import StarRatings from 'react-star-ratings';
import {
    SERVICE_TYPE_FILTER_LEGAL,
    SERVICE_TYPE_FILTERS,
    SERVICE_TYPE_FILTERS_EXCLUDING_LEGAL,
} from '../constants/elevation';
import {getServiceTypeAsString, highestStatistics, isLegalAdviser} from '../Helpers/elevation';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PageActions from '../AppLayout/PageActions';
import { connect } from 'react-redux';
import _ from 'lodash';
import { LOCAL_STORAGE } from '../constants';
import { palette } from '../MyTheme';

const useStyles = makeStyles(theme => ({
    containerRoot: {
        display: 'flex',
        justifyContent: 'center'
    },
    driverStatistics: {
        flexGrow: 0.9
    },
    header: {
        display: "flex",
        justifyContent: 'space-between',
        [theme.breakpoints.down("md")]: {
            flexDirection: 'column',
            alignItems: 'flex-start !important'
        },
    },
    headerTitle: {
        flexBasis: "100%",
    },
    starWrapper: {
        display: "flex",
        alignItems: "center",
        marginBottom: "1.5rem",
        '& svg': {
            '& path': {
                transform: 'translate(-199.939px, 0px)'
            }
        }
    },
    starsTitle: {
        alignSelf: "center",
        marginTop: 0,
        marginBottom: 0,
        marginRight: '1rem'
    },
    button: {
        marginBottom: "1rem",
        padding: "9px 18.5px",
        width: "100%",
        fontWeight: 400,
        maxWidth: "250px",
        '& .MuiButton-label': {
            justifyContent: "flex-start",
            textTransform: "none",
            fontSize: "1rem",
            color: theme.palette.primary.main
        }
    },
    dialog: {
        '& .MuiDialog-paperWidthSm': {
            width: "auto"
        }
    },
    actions: {
        display: "flex",
        justifyContent: "space-between"
    },
    dropdown: {
        maxWidth: "250px",
    },
    ratingText: {
        marginLeft: '1rem',
        color: theme.palette.secondary.main
    },
    title: {
        fontSize: '35px'
    }
}));

const ElevationDashboard = ({...props }) => {
    const dataProvider = useDataProvider();
    const classes = useStyles()

    const [stats, setStats] = useState(null);
    const [statsFetched, setStatsFetched] = useState(false);
    const [initialFetch, setInitialFetch] = useState(false);
    const [goalId, setGoalId] = useState(1);
    const [range, setRange] = useState({ from: null, to: null });
    const [driverStatistics, setDriverStatistics] = useState(null);
    const [driverStatisticsFetched, setDriverStatisticsFetched] = useState(false);
    const [rating, setRating] = useState(0)
    const [showDialog, setShowDialog] = useState(false)
    const basicInfoString = JSON.parse(localStorage.getItem(LOCAL_STORAGE.BASIC_INFO));
    const vertical = basicInfoString.data?.permissions?.vertical

    const [serviceType, setServiceType] = useState(isLegalAdviser(vertical)
        ? SERVICE_TYPE_FILTER_LEGAL
        : null);
    const [showServiceTypeFilters] = useState(!isLegalAdviser(vertical));
    const notify = useNotify();

    const handleCloseClick = () => setShowDialog(false)
    const handleClearClick = () => {
        setRange({ to: null, from: null })
        setShowDialog(false)
    }

    useEffect(()=>{
        setInitialFetch(true);
        fetchStatistics()
    },[])

    useEffect(() => {
        if(initialFetch){
            setDriverStatisticsFetched(false)
            fetchStatistics()
        }
    }, [goalId]);

    useEffect(() => {
        if (range.to && range.from) {
            setDriverStatisticsFetched(false)
        }
        if(initialFetch) {
            fetchStatistics()
        }
    }, [range]);

    useEffect(() => {
        setDriverStatisticsFetched(false)
        if(initialFetch){
            fetchStatistics()
        }
    }, [serviceType]);

    const fetchStatistics = () => {
        let params = formatRange(range);
        if (!serviceType) {
            dataProvider('GET_LIST', 'elevation/dashboard/highest-statistics', {filter: {...params}}).then(res => {
                const type = SERVICE_TYPE_FILTERS.find(x => x.type === res.data['serviceType']);
                setServiceType(type);
                params['service_type'] = getServiceTypeAsString(type);

                dataProvider('GET_LIST', 'elevation/dashboard/elevation-statistics', {filter: {...params}})
                    .then(res => {
                        setStats(res.data)
                        setStatsFetched(true)
                    }).catch((e) => {
                    notify(e.message, 'warning')
                    setStatsFetched(true);
                })
            }).catch(e => notify(e.message, 'warning'));
        } else {
            params['service_type'] = getServiceTypeAsString(serviceType);
            dataProvider('GET_LIST', 'elevation/dashboard/elevation-statistics', {filter: {...params}})
                .then(res => {
                    setStats(res.data)
                    setStatsFetched(true)
                }).catch(() => setStatsFetched(true));
        }
        dataProvider('GET_ONE', 'dashboard').then(res => setRating(res.data.overall_rating));

        dataProvider('GET_MANY', `elevation/dashboard/driver-statistics/${goalId}`, {
            filter: {
                industry: true,
                ...(props.showFirmAvg && {show_firm_avg_on_adviser_elevation_dashboard: props.showFirmAvg}),
                ...params
            },
        }).then(data => {
            setDriverStatistics(data.data);
            setDriverStatisticsFetched(true)
        }).catch(e => notify(e.message, 'warning'));
    }

    return (
        <Card>
            <CardContent>
                <PageActions
                    title="Client Experience"
                    className={classes.title}
                    color={'textPrimary'}
                />
                <Box className={classes.header}>
                    <Box className={classes.headerTitle}>
                        {/* <CompareSelect
                            compareToIndustry={compareToIndustry}
                            setCompareToIndustry={setCompareToIndustry}
                        /> */}
                        <Box className={classes.starWrapper}>
                            <Typography className={classes.starsTitle} variant="h5">Your rating: </Typography>
                            <StarRatings
                                rating={parseFloat(rating)}
                                numberOfStars={5}
                                starDimension="25px"
                                starRatedColor={palette.secondary.main}
                                starEmptyColor={palette.secondary.light}
                                starSpacing="2px"
                                svgIconPath="M225.52,19.761l7.182-5.982a1.459,1.459,0,0,0-.841-2.584l-9.249-.567a1.452,1.452,0,0,1-1.261-.92l-3.4-8.779a1.444,1.444,0,0,0-2.7,0l-3.4,8.744a1.453,1.453,0,0,1-1.261.92l-9.284.567a1.459,1.459,0,0,0-.841,2.584l7.182,5.947a1.5,1.5,0,0,1,.491,1.487l-2.348,9.1a1.447,1.447,0,0,0,2.172,1.593l7.813-5.062a1.362,1.362,0,0,1,1.541,0l7.848,5.062a1.447,1.447,0,0,0,2.172-1.593l-2.348-9.062A1.462,1.462,0,0,1,225.52,19.761Z"
                                svgIconViewBox="0 0 33.296 32.099"
                            />
                            <Typography className={classes.ratingText} variant="span">{rating}/5</Typography>
                        </Box>
                        <Button
                            className={classes.button}
                            variant="outlined"
                            onClick={() => setShowDialog(true)}
                        >
                            {range.to && range.from ?
                                formatRange(range).from + " - " + formatRange(range).to
                                :
                                "Filter dashboard by date"
                            }

                        </Button>
                        <Box>
                            {
                                showServiceTypeFilters &&
                                <Select
                                    className={classes.dropdown}
                                    variant="outlined"
                                    option={SERVICE_TYPE_FILTERS}
                                    value={serviceType ? serviceType.type : 0}
                                    onChange={e => setServiceType(SERVICE_TYPE_FILTERS.find(item => item.type === e.target.value))}
                                >
                                    {SERVICE_TYPE_FILTERS_EXCLUDING_LEGAL.map(serviceType => (
                                        <MenuItem
                                            key={serviceType.type}
                                            value={serviceType.type}
                                        >
                                            {serviceType.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            }
                        </Box>
                    </Box>
                    <Dialog
                        className={classes.dialog}
                        open={showDialog}
                        onBackdropClick={handleCloseClick}
                    >

                        <DialogContent>
                            <DateRangeCustom range={range} setRange={setRange} />
                        </DialogContent>
                        <DialogActions className={classes.actions}>
                            <Button
                                label="ra.action.cancel"
                                onClick={handleClearClick}
                            >
                                Clear interval
                            </Button>
                            <Button
                                color="secondary"
                                label="ra.action.cancel"
                                onClick={handleCloseClick}
                            >
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>

                </Box>
                {!statsFetched ? <Spinner /> : stats && (
                    <>
                        <Typography variant="h5">Choose Elevation Goal</Typography>
                        <ElevationStatsContent
                            data={stats}
                            goalId={goalId}
                            setGoalId={setGoalId}
                            compareToIndustry={true}
                            serviceType={serviceType}
                        />
                    </>
                )}
                {!driverStatisticsFetched ? <Spinner /> : driverStatistics && (
                    <Grid spacing={3}>
                        <Grid
                            item
                            xs={12}>
                            <Box className={classes.containerRoot}>
                                <DriverStatistics
                                    classes={classes.driverStatistics}
                                    driverStatistics={driverStatistics}
                                    compareToIndustry={true}
                                    goal={goalId}
                                    serviceType={getServiceTypeAsString(serviceType)}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </CardContent>
        </Card>
    );
};



const mapStateToProps = ({ basicInfo }) => {
    return {
        showFirmAvg: _.get(basicInfo, 'show_firm_avg_on_adviser_elevation_dashboard', false)
    };
};

export default connect(mapStateToProps)(ElevationDashboard);
