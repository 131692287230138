import React, { useState } from 'react';
import _ from 'lodash';
import {
    SimpleForm,
    usePermissions,
    showNotification as showNotificationAction,
    refreshView as refreshViewAction,
} from 'react-admin';
import dataProvider from '../dataProvider';
import { connect } from 'react-redux';
import { EditPageTitle, FormSectionTitle } from '../Components/Typography/Headlines';
import ReviewShare from './Content/ReviewShare';
import ReviewQuestions from './Content/ReviewQuestions';
import PreventUndoEdit from '../Components/PreventUndoEdit';

import { PLAN } from '../constants';
import { STATE } from '../constants/review';
import ReviewEditActions, { ReportButton } from './Content/ReviewEditActions';
import ReviewReport from './Report/ReviewReport';
import { formatSimpleListDate } from '../utils';
import Divider from '@material-ui/core/Divider';
import SmsIcon from '@material-ui/icons/Sms';
import { makeStyles } from '@material-ui/core/styles';
import ReviewReply from './Content/ReviewReply';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: '15px',
        verticalAlign: 'top',
        marginTop: '4px',
    },
    reviewWrapper: {
        display: 'flex',
    },
    replyButton: {
        marginBottom: '1rem',
        marginLeft: '1rem',
    }
}));


export const ReviewsNote = ({title}) => {
    return (
        <>
            <FormSectionTitle title={title} color={"textPrimary"} variant={"h4"}/>
            <p><b>Please note:</b> Your reply will be publicly visible if you’re on our Verified membership, and the reviewer is notified that you have replied. Please avoid using personal information (i.e. the name of your client) in your response. </p>
        </>
    );
};

export const ReviewTitle = ({ record }) => {
    const classes = useStyles();

    return <EditPageTitle
        titleText="Rating & Summary"
        color={'textPrimary'}
        variant={'h4'}
        icon={<SmsIcon className={classes.icon} />}
    />;
};

export const ReviewerDetails = ({ record }) => {
    const { client_first_name, client_last_name, client_email, submitted_at } = record;
    const name = client_last_name
        ? `${client_first_name} ${client_last_name}`
        : `${client_first_name}`;
    const date = formatSimpleListDate(submitted_at);
    const details = `${name} (${client_email}) on ${date}`
    return (
        <>
            <EditPageTitle
                titleText={"Review by " + `${details}`}
                color={'textPrimary'}
                variant={'h4'}
            />
            <br/>
        </>

    );
};

export const ReviewDescription = () => {
    return <EditPageTitle
        titleText="Feedback that will appear publicly if you're on our Verified membership"
        color={'textPrimary'}
        variant={"body2"}
    />;
};

const ReviewEdit = (props) => {
    const [reported, setReported] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [showReply, setShowReply] = useState(false);
    const classes = useStyles();

    const {
        showNotification,
        refreshView,
        onStarterPlan,
        ...rest
    } = props;

    const onPrivateMode = _.get(props.permissions, 'elevation_private_mode');

    const reportReview = (reviewId, formData) => {
        const { showNotification } = props;
        dataProvider(
            'UPDATE',
            `reviews/${reviewId}/change-state/${STATE.CONTENT_ABUSE}`,
            { data: formData }
        ).then(response => {
            showNotification(
                'An issue has been reported. Our team will be in touch!'
            );

            setReported(true);
        });
    };

    const deleteReply = (reviewId) => {
        const { showNotification, refreshView } = props;

        dataProvider('DELETE', `reviews/${reviewId}/respond`, {
            data: {},
        }).then(response => {
            showNotification('Reply deleted');
            setShowReply(false)
            refreshView();
        });
    };

    const handleDialogClose = () => {
        setShowDialog(false)
    };

    const handleDialogOpen = () => {
        setShowDialog(true)
    };


    const handleReplyDialogClose = () => {
        setShowReply(false)
    };

    const handleReplyDialogOpen = () => {
        setShowReply(true)
    };


    const ReplyButton = ({record}) => {
        return <Button data-test="reply-dialog-open-button" className={classes.replyButton} color="primary" variant="contained" onClick={handleReplyDialogOpen}>{record?.professional_response ? 'Edit/Delete Reply' : 'Reply'}</Button>
    }

    const ActionButtons = (props) => {
        return (
            <div className={classes.buttonWrapper}>                
                <ReportButton
                    reportHandler={handleDialogOpen}
                    reported={reported}
                    {...props}
                    href="/reviews"/>

                <ReplyButton {...props} handleReplyDialogOpen={handleReplyDialogOpen} />
            </div>    
        )
    }

    return (
        <PreventUndoEdit
            {...rest}
            title={null}
            actions={
                <ReviewEditActions
                    reported={reported}
                    href="/reviews"
                />
            }
        >
            <SimpleForm toolbar={null} redirect={false}>
                <ReviewerDetails />
                <ReviewTitle/>
                <ReviewDescription />
                <ReviewQuestions
                    onStarterPlan={onStarterPlan}
                    publicQuestions={true}
                    privateQuestions={false}
                />
                {!onPrivateMode && !onStarterPlan && <ReviewShare/>}
                <ActionButtons />
                <Divider style={{width:"auto"}}/>

                <ReviewQuestions
                    onStarterPlan={onStarterPlan}
                    publicQuestions={false}
                    privateQuestions={true}
                />
                <br/>
                { showReply && <ReviewReply 
                        showDialog={showReply}
                        handleClose={handleReplyDialogClose}
                        deleteReply={deleteReply}
                        source="professional_response"
                        title="Reply to review"
                    />
                }
                {showDialog && <ReviewReport
                    showDialog={showDialog}
                    handleClose={handleDialogClose}
                    handleSend={reportReview}
                    reportType="review"
                    reportedSuccess={reported}
                />}
            </SimpleForm>
        </PreventUndoEdit>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        onStarterPlan: basicInfo.plan === PLAN.STARTER,
    };
};

const actions = {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
};

export default connect(
    mapStateToProps,
    actions
)(ReviewEdit);
