import React from 'react';
import Button from '../../../Components/Buttons/CustomButton';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    root: {
        marginTop: theme.spacing(1),
    },
});

const StatisticButton = ({ classes, label, ...rest }) => (
    <Button color="tertiary" className={classes.root} {...rest}>
        { label ? label : 'See who said this' }
    </Button>
);

export default withStyles(styles)(StatisticButton);
