import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, withStyles } from '@material-ui/core';

const styles = theme => ({
    anchor: {
        display: 'block',
        marginTop: theme.spacing.unit * 2,
        color: theme.palette.secondary.main,
        textDecoration: 'none',
    },
});

const ForgottenPasswordEmailSent = ({ classes }) => {
    return (
        <div>
            <Typography>
                <b>Thank You</b>
            </Typography>
            <Typography>
                Please check your email inbox. You should receive an email shortly with a link to
                reset your password. You may need to check your spam/junk folder.
            </Typography>
            <Link to="/login" className={classes.anchor}>
                Go back to login
            </Link>
        </div>
    );
};

export default withStyles(styles)(ForgottenPasswordEmailSent);
