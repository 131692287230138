import React from 'react';
import authProvider from '../../authProvider';
import { AUTH_CHECK, AUTH_LOGOUT } from 'react-admin';
import { connect } from 'react-redux';
import { featureSwitch } from '../../utils';
import { history } from '../../App';

export const withFeatureToggleCheck = (WrappedComponent, featureProps) => {
    return (props) => {
        if (featureSwitch(...featureProps)) {
            return (
                <WrappedComponent {...props} />
            );
        }
        return null
    }
};


const withAuthCheck = (CustomComponent, permissionToCheck) => {
    return class extends React.Component {
        componentDidMount() {
            authProvider(AUTH_CHECK).catch(this.handleAuthCheckFail);
        }

        handleAuthCheckFail = () => {
            authProvider(AUTH_LOGOUT).then(() => history.push('/login'));
        };

        render() {
            return !permissionToCheck || (this.props.permissions && this.props.permissions[permissionToCheck]) ? <CustomComponent {...this.props} /> : null;
        }
    };
};

const withPermissionsAndAuthCheck = (Component, permissionToCheck = '') => {
    const mapStateToProps = state => ({
        permissions: state.basicInfo.permissions,
    });

    return connect(mapStateToProps)(withAuthCheck(Component, permissionToCheck));
};

export default withPermissionsAndAuthCheck;
