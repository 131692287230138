import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import { showNotification } from 'react-admin';
import { connect } from 'react-redux';
import { FormSectionTitle } from '../../Components/Typography/Headlines';
import { copyToClipboard } from '../../utils';
import { API_KEYS } from '../../constants';
import { ExternalLink } from '../../Components/Links';
import * as analytics from '../../analytics';

import Typography from '@material-ui/core/Typography';
import Button from '../../Components/Buttons/CustomButton';
import withStyles from '@material-ui/core/styles/withStyles';

const SECONDS_TO_REDIRECT = 5;

const styles = theme => ({
    root: {
        textAlign: 'center',
        wordBreak: 'break-word',
    },
    urlWrapper: {
        display: 'block',
        clear: 'both',
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
    },
    redirectText: {
        marginBottom: theme.spacing(5),
    },
});

const FirstInvitationUrl = props => {
    const { writeReviewUrl, showNotification, classes } = props;
    const [buttonClicked, setButtonClicked] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [count, setCount] = useState(SECONDS_TO_REDIRECT);

    useEffect(() => {
        let countdown;

        if (count === 0) {
            setRedirect(true);

            return;
        }

        if (buttonClicked) {
            countdown = setInterval(() => {
                setCount(count > 0 ? count - 1 : 0);
            }, 1000);
        }

        return () => clearInterval(countdown);
    }, [buttonClicked, count]);

    const handleClick = () => {
        setButtonClicked(true);

        analytics.track('Clicked Copy Invite Link ', {
            category: 'First Invitation Step',
        });

        copyToClipboard(writeReviewUrl);

        showNotification('ra.notification.text_copied');
    };

    if (redirect) {
        return <Redirect to="/" />;
    }

    return (
        <div className={classes.root}>
            <FormSectionTitle title="Copy your review invitation link and share it yourself:" />
            <div className={classes.urlWrapper}>
                <ExternalLink href={writeReviewUrl}>
                    <b>{writeReviewUrl}</b>
                </ExternalLink>
            </div>
            {buttonClicked && (
                <Typography className={classes.redirectText}>
                    Redirecting to your dashboard in {count}s
                </Typography>
            )}
            <Button
                onClick={handleClick}
                color="tertiary"
                variant="contained"
                disabled={buttonClicked}
            >
                Copy link
            </Button>
        </div>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    const permalink = _.get(basicInfo, 'permalink.url');

    return {
        writeReviewUrl: `${API_KEYS.consumer_url}/${permalink}/write-review`,
    };
};

const mapDispatchToProps = {
    showNotification,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(FirstInvitationUrl));
