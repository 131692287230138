import React, { useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Create, SimpleForm, Toolbar, SaveButton, TopToolbar } from 'react-admin';
import BackButton from '../Components/Buttons/BackButton';
import { PERMISSIONS, SERVICE } from '../constants';
import ServicesCreateFormContent from './Components/ServicesCreateFormContent';
import dataProvider from '../dataProvider';

const { BASIC_PROFILE_COMPLETE } = PERMISSIONS;

const ServicesCreate = props => {
    const { permissions, canUpgrade, servicesUrl, ...rest } = props;
    const [pensionServiceErrorMessage, setPensionServiceErrorMessage] = useState(null);
    const [requestFinished, setRequestFinished] = useState(false);

    const handleSave = async values => {
        const payload = {
            data: values,
        };

        try {
            await dataProvider('CREATE', 'services', payload);

            setRequestFinished(true);
        } catch (e) {
            if (values.attribute === SERVICE.PENSION_TRANSFERS_SPECIALIST && !canUpgrade) {
                setPensionServiceErrorMessage(_.get(e, 'body.message.attribute.0'));
            }
        }
    };

    if (requestFinished) {
        return <Redirect to={servicesUrl} />;
    }

    return (
        <Create
            actions={
                <TopToolbar>
                    <BackButton href={servicesUrl} />
                </TopToolbar>
            }
            title={null}
            {...rest}
        >
            <SimpleForm
                toolbar={
                    <Toolbar {...props}>
                        <SaveButton label="Create" submitOnEnter={false} onSave={handleSave} />
                    </Toolbar>
                }
            >
                <ServicesCreateFormContent
                    pensionServiceErrorMessage={pensionServiceErrorMessage}
                    style={{ margin: '24px 0' }}
                />
            </SimpleForm>
        </Create>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    const professionalId = _.get(basicInfo, 'id');

    return {
        canUpgrade: _.get(basicInfo, 'permissions.can_upgrade'),
        servicesUrl: _.get(basicInfo, ['permissions', BASIC_PROFILE_COMPLETE], false)
            ? `/services-list/${professionalId}`
            : `/basic-profile/${professionalId}`,
    };
};

export default connect(mapStateToProps)(ServicesCreate);
