import React, { Component } from 'react';
import _ from 'lodash';
import {
    List,
    Responsive,
    Datagrid,
    TextField,
    DateField,
    SimpleList,
} from 'react-admin';
import Button from '../Components/Buttons/CustomButton';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import withStyles from '@material-ui/core/styles/withStyles';
import { LOCAL_STORAGE } from '../constants';
import { referralGeneratorStyles } from './referralGeneratorStyles';

const { REFERRAL_EXCLUDED_IDS, REFERRAL_FORM_VALUES } = LOCAL_STORAGE;

class DeleteAddButtons extends Component {
    state = {
        showAddButton: false,
    };

    componentDidMount() {
        const excludedIds = sessionStorage.getItem(REFERRAL_EXCLUDED_IDS);
        const recordId = _.get(this.props, ['record', 'id'], null);

        if (!recordId || !excludedIds) return;

        if (JSON.parse(excludedIds).includes(recordId)) {
            this.setState({ showAddButton: true });
        }
    }

    onDeleteClick = () => {
        this.toggleShow();
        this.props.onDeleteClick(this.props.record.id);
    };

    onAddClick = () => {
        this.toggleShow();
        this.props.onAddClick(this.props.record.id);
    };

    toggleShow = () => {
        this.setState({ showAddButton: !this.state.showAddButton });
    };

    render() {
        if (this.state.showAddButton) {
            return (
                <Button color="tertiary" onClick={this.onAddClick}>
                    Add to invite list
                </Button>
            );
        }

        return (
            <Button color="primary" onClick={this.onDeleteClick}>
                Remove from invite list
            </Button>
        );
    }
}

class CustomerList extends Component {
    state = {
        removedIds: [],
        customerGroupFromStorage: null,
        mostRecentCustomerReferralInvitationDate: null,
    };

    componentDidMount() {
        const referralFormValues = JSON.parse(
            sessionStorage.getItem(REFERRAL_FORM_VALUES)
        );
        const removedIdsFromStorage = JSON.parse(
            sessionStorage.getItem(REFERRAL_EXCLUDED_IDS)
        );

        this.setState({
            customerGroupFromStorage: referralFormValues.customerGroup,
            mostRecentCustomerReferralInvitationDate:
                referralFormValues.customerGroup !== 'all_new_reviewers'
                    ? null
                    : referralFormValues.recentReferralInvitationDate,
        });

        if (removedIdsFromStorage) {
            this.setState({ removedIds: removedIdsFromStorage });
        }
    }

    onDeleteClick = id => {
        this.setState({ removedIds: [...this.state.removedIds, id] });
        sessionStorage.setItem(
            REFERRAL_EXCLUDED_IDS,
            JSON.stringify([...this.state.removedIds, id])
        );
    };

    onAddClick = id => {
        const removedIds = this.state.removedIds.filter(el => el !== id);
        this.setState({
            removedIds: removedIds,
        });
        sessionStorage.setItem(
            REFERRAL_EXCLUDED_IDS,
            JSON.stringify(removedIds )
        );
    };

    handleSave = () => {
        window.location.href = '/referral-generator/create';
    };

    render() {
        const { classes, ...props } = this.props;
        const {
            removedIds,
            customerGroupFromStorage,
            mostRecentCustomerReferralInvitationDate,
        } = this.state;

        return (
            <>
                {customerGroupFromStorage && (
                    <CardContent className={classes.customersListWrapper}>
                        <List
                            hasList={true}
                            title={null}
                            bulkActionButtons={false}
                            actions={null}
                            {...props}
                            filter={
                                mostRecentCustomerReferralInvitationDate
                                    ? {
                                          customerGroup: customerGroupFromStorage,
                                          startDate: mostRecentCustomerReferralInvitationDate,
                                      }
                                    : {
                                          customerGroup: customerGroupFromStorage,
                                      }
                            }
                            className={classes.customersList}
                            sort={{ field: 'created_at', order: 'DESC' }}
                        >
                            <Responsive
                                xsmall={
                                    <div>
                                        <Typography>
                                            <b>As this tool is in trial stages,
                                            we're still working on the mobile
                                            version! To edit this list, please
                                            use a desktop device</b>
                                        </Typography>
                                        <SimpleList
                                            primaryText={record =>
                                                record.created_at
                                            }
                                            secondaryText={record =>
                                                record.name
                                            }
                                            tertiaryText={record =>
                                                record.email
                                            }
                                        />
                                    </div>
                                }
                                large={
                                    <Datagrid>
                                        <DateField
                                            label="Customer created at"
                                            source="created_at"
                                        />
                                        <TextField
                                            label="Reviewer name"
                                            source="name"
                                        />
                                        <TextField
                                            label="Reviewer email"
                                            source="email"
                                        />
                                        <DeleteAddButtons
                                            removedIds={removedIds}
                                            onDeleteClick={this.onDeleteClick}
                                            onAddClick={this.onAddClick}
                                        />
                                    </Datagrid>
                                }
                            />
                        </List>
                        <Typography variant="caption">
                            Please note this will only show customers which
                            haven't got your invitation yet
                        </Typography>
                        <Button
                            onClick={this.handleSave}
                            color="tertiary"
                            variant="contained"
                        >
                            Save & Go back to form
                        </Button>
                    </CardContent>
                )}
            </>
        );
    }
}

export default withStyles(referralGeneratorStyles)(CustomerList);
