import React from 'react';
import PropTypes from 'prop-types';
import { FormSectionTitle } from '../../Components/Typography/Headlines';
import FeesInsightsUnlockBanner from './FeesInsightsUnlockBanner';

import Card from '@material-ui/core/Card';
import withStyles from '@material-ui/core/styles/withStyles';

const propTypes = {
    title: PropTypes.string.isRequired,
    showUnlockBanner: PropTypes.bool,
    children: PropTypes.any,
};

const styles = theme => ({
    root: {
        position: 'relative',
        marginBottom: theme.spacing(3),
    },
    title: {
        position: 'relative',
        margin: `0 0 ${theme.spacing(2)}px`,
    },
});

const FeesInsightsCard = ({ classes, children, showUnlockBanner = false, title = '' }) => {
    return (
        <Card className={classes.root}>
            <FormSectionTitle className={classes.title} title={title} />
            {showUnlockBanner && <FeesInsightsUnlockBanner />}
            {children}
        </Card>
    );
};

FeesInsightsCard.propTypes = propTypes;

export default withStyles(styles)(FeesInsightsCard);
