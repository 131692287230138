import React from 'react';
import _ from 'lodash';
import { Datagrid, TextField, DateField } from 'react-admin';
import VerifyAndRejectReviewButtons from './VerifyAndRejectReviewButtons';
import ReviewDownloadPdfButton from './ReviewDownloadPdfButton';
import { usePermissions } from 'react-admin';
import CardContent from '@material-ui/core/CardContent';
import ShareReviewButton from './ShareReviewButton';

const ReviewListMedium = ({ dispatch, ...rest }) => {
    const { permissions } = usePermissions();
    return (
        <div>
            <CardContent>
                <Datagrid {...rest} actions={<div />}>
                    <DateField label="Date" source="submitted_at" />
                    <TextField label="Reviewer name" source="customer.name" />
                    <TextField label="Reviewer email" source="customer.email" />
                    <Rating label="Rating" sortable={false}/>
                    <TextField label="Review Status" source="state_description" sortable={false} />
                    <VerifyAndRejectReviewButtons reviewType="review"/>
                    {_.get(permissions, 'elevation_private_mode') ? null : (
                        <ShareReviewButton />
                    )}
                </Datagrid>
                <ReviewDownloadPdfButton />
            </CardContent>
        </div>
    );
};

const Rating = (props) => {
    const isTransferredClientReview = _.get(props?.record, 'is_transferred_client_review', false);
    const averageRating = _.get(props?.record, 'average_rating', 0);

    return <>
        {isTransferredClientReview ? <>
                n/a
            </> :
            <>{averageRating}</>
        }
    </>;
};

export default ReviewListMedium;
