import React from 'react';
import { Create, TopToolbar } from 'react-admin';
import BackButton from '../Components/Buttons/BackButton';
import PreventUndoEdit from '../Components/PreventUndoEdit';
import MortgageFeeLevelForm from './Forms/MortgageFeeLevelForm';

export const MortgageFeeLevelsCreate = ({ dispatch, recordValues, ...rest }) => {
    return (
        <Create
            actions={
                <TopToolbar>
                    <BackButton href="/fees" />
                </TopToolbar>
            }
            title={null}
            {...rest}
        >
            <MortgageFeeLevelForm {...rest} />
        </Create>
    );
};

export const MortgageFeeLevelsEdit = ({ dispatch, recordValues, ...rest }) => {
    return (
        <PreventUndoEdit
            actions={
                <TopToolbar>
                    <BackButton href="/fees" />
                </TopToolbar>
            }
            {...rest}
        >
            <MortgageFeeLevelForm {...rest} />
        </PreventUndoEdit>
    );
};
