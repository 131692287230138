import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux'; 
import {
    Query,
    required,
} from 'react-admin';
import EditButton from '../../Components/Buttons/EditButton';
import FootNote from '../../Components/Typography/FootNote';
import TrueFalseInput from '../../Components/Forms/TrueFalseInput';
import { Link } from '../../Components/Links';
import { FormSectionTitle } from '../../Components/Typography/Headlines';
import FirmLogo from './FirmLogo';
import { CONTACT } from '../../constants';
import { Grid, Typography } from '@material-ui/core';
import FormTextInput from '../../Components/Inputs/Form/FormTextInput';
import FormSelectInput from '../../Components/Inputs/Form/FormSelectInput';

const FirmDetail = ({ label, value = null }) => {
    if (!value) return null;

    return (
        <Grid container alignItems="center">
            <Grid item>
                <Typography><b>{label}&nbsp;</b></Typography>
            </Grid>
            <Grid item>
                <Typography>{value}</Typography>
            </Grid>
        </Grid>
    );
};

const FirmDetailsFormContent = ({ professionalId, isFinancialAdviser, record }) => {
    const firm = _.get(record, 'firm', {});
    const isCurrentFirmAdmin = _.get(firm, 'admin.id') === professionalId;
    const firmHasAdmin = Boolean(_.get(firm, 'admin'));

    if (!isFinancialAdviser) {
        return (
            <div>
                <FormTextInput source="firm_name" />
                <FormTextInput multiline source="about_firm" />
            </div>
        );
    }

    return (
        <div>
            <FirmDetail label="Firm FCA ID:" value={_.get(firm, 'fca_number')} />
            <FirmDetail label="Name:" value={_.get(firm, 'name')} />
            <FirmDetail label="About:" value={_.get(firm, 'about')} />
            <Query type="getOne" resource="firm" payload={{ id: _.get(firm, 'id', null) }}>
                {({ data }) => (
                    <FirmLogo enableEdit={false} src={_.get(data, 'logo.urls.standard')} />
                )}
            </Query>
            {isCurrentFirmAdmin ? (
                <EditButton color="secondary" href={`/firm/${_.get(firm, 'id')}`} label="Edit Firm Details" />
            ) : (
                <>
                    <br />
                    <Typography color="error">
                        You must be the firm administrator to edit these details.
                    </Typography>
                </>
            )}
            <br />
            <FormSectionTitle title="Firm Settings:" />
            <>
                <Query type="getList" resource="firm/firm-fca">
                    {({ data }) => {
                        const firmOptions = data && data.length ? data : [];

                        return (
                            <FormSelectInput
                                validate={[required()]}
                                label="My Firm"
                                source="firm.id"
                                disabled={isCurrentFirmAdmin}
                                defaultValue={_.get(firm, 'id', '')}
                                choices={firmOptions}
                            />
                        );
                    }}
                </Query>
                <TrueFalseInput
                    source="firm_admin"
                    label="I am my firm's administrator"
                    disabled={!isCurrentFirmAdmin && firmHasAdmin}
                />
            </>
            <br />
            <Typography variant="body1" gutterBottom>
                <b>MOVED FIRMS?</b>&nbsp;Please&nbsp;
                <Link href={`mailto:${CONTACT.EMAIL}`}>email us</Link> to let us know if you have
                moved firms, with your new firm FCA ID.
            </Typography>
            <FootNote
                content={
                    <>
                        <span>
                            you must be the firm administrator to edit details, or access 'linked
                            advisers' or 'firm reputation tools.{' '}
                        </span>
                        {!isCurrentFirmAdmin && firmHasAdmin && (
                            <span>
                                Currently your firm administrator is set to
                                <b>{` ${_.get(firm, 'admin.full_name', 'someone else')}`}</b>.
                            </span>
                        )}
                    </>
                }
            />
        </div>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        professionalId: _.get(basicInfo, 'id'),
    };
};

export default connect(mapStateToProps)(FirmDetailsFormContent);
