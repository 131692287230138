import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import MyTheme from '../../MyTheme';

export const FormSectionTitle = ({ title, color, variant, icon, ...props }) => {
    const { basePath, ...rest } = props;
    const { palette } = MyTheme;

    switch (color) {
        case 'white':
            color = palette.primary.contrastText;
            break;
        case 'textPrimary':
            color = palette.primary.main;
            break;
        default:
            color = palette.secondary.main;
    }
    if (typeof variant === 'undefined') {
        variant = "h5";
    }

    return (
        <div>
            {
                //in some places there is data passed to the Typography element with dangerouslySetInnerHtml that doesn't support child components on the component as well
                icon ?
                    <Typography style={{ color }} variant={variant} {...rest}>
                        { icon }
                        {title}
                    </Typography>
                :
                    <Typography style={{ color }} variant={variant} {...rest}>
                        {title}
                    </Typography>
            }
        </div>
    );
};

FormSectionTitle.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    color: PropTypes.string,
    variant: PropTypes.string,
};

export const EditPageTitle = ({ titleText, color, variant, icon }) => (
    <FormSectionTitle title={titleText} color={color} variant={variant} icon={icon} />
);
