import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { useQueryWithStore, usePermissions } from 'react-admin';
import WelcomePanel from './Components/WelcomePanel';
import { showSidebar, fetchBasicInfo } from '../../state/actions';
import { PLAN } from '../../constants';
import * as analytics from '../../analytics';
import GuideActionsCard from '../GuideActionsCard';
import DashboardStatistics from './Components/DashboardStatistics';
import OnboardingBanner from '../OnboardingBanner/OnBoardingBanner';
import Grid from '@material-ui/core/Grid';
import PrivateDashboard from '../Elevation/PrivateDashboard';
import { makeStyles } from '@material-ui/core';
import DashboardData from './Components/DashboardData';
import { useDataProvider } from 'react-admin';
import { CONTACT } from '../../constants/various';
import Typography from '@material-ui/core/Typography';
import { Link } from '../../Components/Links';

const useStyles = makeStyles(theme => ({
    container: {
        justifyContent: 'center',
    },
    contentWrapper: {
        maxWidth: '1100px',
        alignSelf: 'center',
        width: '100%',
    },
    getInTouch: {
        marginBottom: theme.spacing(6),
        marginTop: theme.spacing(12),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
    },
    titleWrapper: {
        width: '100%',
        fontSize: '1.2rem',
        fontWeight: 700,
    },
}));
const Dashboard = props => {
    const [cards, setCards] = useState([
        {
            title: 'Reviews',
            number: 0,
            subtitle: 'See who left you Client and First Impression Reviews',
            active: false,
            gka: 'reviews',
            key: 'reviews_and_fi_365_days',
            hideElements: [],
            gaAction: 'Reviews',
        },
        {
            title: 'Potential clients',
            number: 0,
            subtitle: 'Those engaging with your profile',
            active: false,
            gka: 'visits',
            key: 'potential_clients_365_days',
            hideElements: [],
            gaAction: 'Potential_clients',
        },
        {
            title: 'Influenced',
            number: 0,
            subtitle: 'See who read your reviews before becoming a client',
            active: false,
            gka: 'influences',
            key: 'influenced_365_days',
            hideElements: [],
            gaAction: 'Influenced_by',
        },
        {
            title: 'Recommenders',
            number: 0,
            subtitle: 'See who is more likely to recommend you after seeing your reviews',
            active: false,
            gka: 'referrers',
            key: 'referrers_365_days',
            hideElements: [],
            gaAction: 'Recommenders',
        },
    ]);
    const [reviewType, setReviewType] = useState('reviews');
    const [graphData, setGraphData] = useState(null);
    const [visitsGraphData, setVisitsGraphData] = useState(null);
    const [visitsGraphVisible, setVisitsGraphVisible] = useState('potential');
    const [visitsGraphPotentialKeys] = useState(['Potential Enquirers', 'Direct Enquirers']);
    const { showGuideActionsCard, showSidebar, fetchBasicInfo } = props;
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const { permissions } = usePermissions();

    const { data: { statistics } = {} } = useQueryWithStore({
        type: 'getOne',
        resource: 'dashboard/statistics',
        payload: { id: null },
    });

    const { data } = useQueryWithStore({
        type: 'getOne',
        resource: 'dashboard',
        payload: { id: null },
    });

    useEffect(() => {
        setGraphData(null);
        fetchGraphData();
    }, [cards, reviewType]);

    useEffect(() => {
        showSidebar();
        fetchBasicInfo();
        analytics.track('Viewed dashboard', { category: 'Dashboard card' });
    }, [showSidebar, fetchBasicInfo]);

    useEffect(() => {
        if (statistics) {
            const newCards = [...cards];
            cards.map((card, i) => {
                if (card.key in statistics) {
                    if (card.key === 'potential_clients_365_days') {
                        const { value, title, hideElements } = getPotentialClientData(statistics);
                        newCards[i].number = value;
                        newCards[i].title = title;
                        newCards[i].hideElements = [...newCards[i].hideElements, ...hideElements];
                    } else {
                        newCards[i].number = statistics[card.key];
                    }
                }
                return true;
            });

            let hasActive = false;
            newCards.map(card => {
                if (hasActive === false && card.number > 0) {
                    card.active = true;
                    hasActive = true;
                }
                return true;
            });
            setCards([...newCards]);
        }
    }, [statistics]);

    useEffect(() => {
        if (visitsGraphData) {
            const newVisitData = { ...visitsGraphData };
            newVisitData.lines = visitsGraphData.lines.filter(line => {
                return visitsGraphVisible === 'potential'
                    ? visitsGraphPotentialKeys.includes(line)
                    : !visitsGraphPotentialKeys.includes(line);
            });
            newVisitData.bars = visitsGraphData.bars.filter(bar =>
                visitsGraphVisible === 'potential'
                    ? visitsGraphPotentialKeys.includes(bar)
                    : !visitsGraphPotentialKeys.includes(bar)
            );
            setGraphData(newVisitData);
        }
    }, [visitsGraphData, visitsGraphVisible]);

    const getPotentialClientData = statistics => {
        let value = statistics.potential_clients_365_days;
        let title = 'Potential clients';
        let hideElements = [];
        if (value === 0) {
            value =
                (statistics.googlers.value ? statistics.googlers.value : 0) +
                (statistics.clickers.value ? statistics.clickers.value : 0) +
                statistics.vf_directory_profile_visits_365_days;
            title = 'Profile visits';
            hideElements = [...hideElements, 'potentialClients'];
            setVisitsGraphVisible('found')
        }

        return { value, title, hideElements };
    };

    const fetchGraphData = () => {
        dataProvider('GET_LIST', 'dashboard/graphs-data', {
            filter: { type: getFilter() },
        }).then(res => {
            if (res.data) {
                if (getFilter() === 'visits') {
                    setVisitsGraphData(res.data);
                } else {
                    setGraphData(res.data);
                }
            }
        });
    };

    const handleCardClick = gka => {
        const newCards = cards.map(card => {
            if (card.gka !== gka) {
                return { ...card, active: false };
            } else {
                return { ...card, active: true };
            }
        });
        setCards([...newCards]);

        const clickedCard = cards.find(card => card.gka === gka);
        if (clickedCard) {
            analytics.track(clickedCard.gaAction, { category: 'Main cards' });
        }
    };

    const handleReviewTypeChange = type => {
        setReviewType(type);
        analytics.track('Reviews', { category: 'Main cards', label: type });
    };

    const handleVisitsGraphVisible = visible => {
        setVisitsGraphVisible(visible);
        analytics.track('Potemtial clients', { category: 'Main cards', label: visible });
    };

    const getActiveGka = () => {
        const active = cards.find(card => card.active === true);
        return active?.gka;
    };

    const getHiddenElements = () => {
        const active = cards.find(card => card.active === true);
        return active?.hideElements;
    };

    const getFilter = () => {
        const active = getActiveGka();
        return active === 'reviews' && reviewType === 'first_impressions'
            ? 'fi_and_prospect_intention'
            : active;
    };

    return (
        <>
            {_.get(permissions, 'elevation_private_mode') ? (
                <PrivateDashboard />
            ) : (
                <>
                    <div>
                        <OnboardingBanner dashboardData={data} />
                    </div>
                    <div>
                        <WelcomePanel data={data} />
                        <Grid container className={classes.container}>
                            {showGuideActionsCard && <GuideActionsCard />}
                            <div className={classes.contentWrapper}>
                                <DashboardStatistics
                                    cards={cards}
                                    handleCardClick={handleCardClick}
                                />
                                <DashboardData
                                    statistics={statistics}
                                    reviewType={reviewType}
                                    setReviewType={handleReviewTypeChange}
                                    setVisitsGraphVisible={handleVisitsGraphVisible}
                                    visitsGraphVisible={visitsGraphVisible}
                                    {...graphData}
                                    filter={getFilter()}
                                    gka={getActiveGka()}
                                    hiddenElements={getHiddenElements()}
                                />
                                <Grid item md={12} className={classes.getInTouch}>
                                    <div className={classes.titleWrapper}>Get in touch!</div>
                                    <Typography variation="body1">
                                        We're continually working to bring you useful information on
                                        your VouchedFor account to help you get the most of your
                                        membership. <br />
                                        Please note this information is only visible to you. We'd
                                        love your feedback - just email{' '}
                                        <Link href={`mailto:${CONTACT.EMAIL}`}>
                                            {CONTACT.EMAIL}
                                        </Link>{' '}
                                        or call us on{' '}
                                        <Link href={`tel:${CONTACT.PHONE_NUMBER}`}>
                                            {CONTACT.PHONE_NUMBER}
                                        </Link>
                                    </Typography>{' '}
                                </Grid>
                            </div>
                        </Grid>
                    </div>
                </>
            )}
        </>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        onStarterPlan: basicInfo.plan === PLAN.STARTER,
        showGuideActionsCard: false,
    };
};

const actions = {
    showSidebar,
    fetchBasicInfo,
};

export default connect(
    mapStateToProps,
    actions
)(Dashboard);
