import React from 'react';
import DialogBase from '../../../Components/Dialogs/DialogBase';
import { makeStyles, Typography } from '@material-ui/core';
import * as analytics from '../../../analytics';
import EmailSignatureGroup from './EmailSignatureGroup';
import {TOP_RATED_FIRM_BADGES, TOP_RATED_FIRM_BADGES_VF2024} from '../../../constants';

const useStyles = makeStyles(theme => ({
    groups: {
        marginTop: theme.spacing(3),
    },
}));

const FirmBadgesModal = props => {
    const classes = useStyles();

    const handleBadgeClick = item => () => {
        analytics.track('Firm badge download', {
            category: 'Firm reputation tools',
            label: item.label,
        });
    };

    return (
        <DialogBase
            modalWidth={800}
            open={props.open}
            title={props.showNewRepTool ? "Firm Badge" : "Firm Badges" }
            onClose={props.handleModalClose}
        >
            <div>
                {props.showNewRepTool ?
                    <Typography>
                        Simply click the signature below to download, or <b>right click</b> on
                        the image and click "<b>Copy Image</b>":
                    </Typography>
                    :
                    <Typography>
                        Simply click any of the signatures below to download, or <b>right click</b> on
                        the image you’d like and click "<b>Copy Image</b>":
                    </Typography>
                }
                <div className={classes.groups}>
                    <EmailSignatureGroup
                        list={props.showNewRepTool ? TOP_RATED_FIRM_BADGES_VF2024 : TOP_RATED_FIRM_BADGES}
                        onSignatureClick={handleBadgeClick}
                    />
                </div>
            </div>
        </DialogBase>
    );
};

export default FirmBadgesModal;
