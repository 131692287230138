import React from 'react';
import { useNotify } from 'react-admin';
import {API_KEYS, REP_TOOL_TOP_RATED_CERTIFICATE_VF_2023} from '../../constants';
import { getLexikToken } from '../../Helpers/provider';
import * as analytics from '../../analytics';
import { REP_TOOL_TOP_RATED_CERTIFICATE } from '../../constants';
import Button from '../../Components/Buttons/CustomButton';
import ReputationToolItem from './Components/ReputationToolItem';
import { FormSectionTitle } from '../../Components/Typography/Headlines';

const FirmTopRatedReportCard = props => {
    const notify = useNotify();

    const handleClick = e => {
        e.preventDefault();

        analytics.track(`Downloaded top rated Firm certificate`, {
            category: 'Profile promotion',
        });

        window.location.href = `${
            API_KEYS.api_url
        }/firm/top-rated-certificate?lexiktoken=${getLexikToken()}`;

        notify('Top rated certificate downloaded');
    };

    return (
        <ReputationToolItem
            img={props.showNewRepTool ? REP_TOOL_TOP_RATED_CERTIFICATE_VF_2023 : REP_TOOL_TOP_RATED_CERTIFICATE}
            content={<FormSectionTitle title="Top Rated Firm Certificate" />}
            actions={
                <Button variant="contained" color="tertiary" onClick={handleClick}>
                    Download Pdf
                </Button>
            }
        />
    );
};

export default FirmTopRatedReportCard;
