import React from 'react';
import { Field } from "react-final-form";
import DataSheetInput from "../../Components/Inputs/DataSheetInput";
import Typography from "@material-ui/core/Typography";

const ReviewInviteMultipleFields = ({ onRowChange, resetGrid, allEmailsValid, grid }) => {

    return (
        <>
            <Field
                input={{
                    onChange: e => onRowChange(e),
                }}
                component={DataSheetInput}
                name="clients"
                resetGrid={resetGrid}
            />
            <>
                {!allEmailsValid && grid.length > 0 && (
                    <Typography color="error">
                        One or more email addresses you have entered are invalid
                    </Typography>
                )}
            </>
        </>
    );
};

export default ReviewInviteMultipleFields;
