import React from 'react';
import OnboardingBannerBase from './OnboardingBannerBase';
import { Typography } from '@material-ui/core';
import {ICONS} from '../../constants/emoji-constants';

const AVERAGE_RATING_STATISTIC_NOTE = 'Worried about inviting reviews at the moment? Don’t be! Good advisers are more valued than ever. The average rating received from April to November 2020 is 4.89 stars.';


const OnboardingBannerSignUpInviteReviews = () => {
    return (
        <OnboardingBannerBase
            title={ICONS[':party_popper:'] + " Thanks, you're all set!" }
            content={
                <div>
                    <Typography color="inherit">
                        We're thrilled to have you join VouchedFor, home of the UK's most trusted
                        advisers.
                    </Typography>
                    <Typography color="inherit">
                        To start, let's get those client reviews rolling in!
                    </Typography>
                </div>
            }
            note={AVERAGE_RATING_STATISTIC_NOTE}
        />
    );
};

export default OnboardingBannerSignUpInviteReviews;
