import React from 'react';

const PrivateDashboardListItem = ({ primary, secondary }) => {
    return (
            <li>
                <b>{primary}</b>&nbsp;{secondary}
            </li>
    );
};

export default PrivateDashboardListItem;
