import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import SkeletonWrapper from '../../../Components/SkeletonWrapper';
import ProfileAvatar from './ProfileAvatar';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '../../../Components/Buttons/CustomButton';
import { ROOT_DOMAIN, TOP_RATED_ADVISER_VF_2024 } from '../../../constants';
import { makeStyles } from '@material-ui/core';
import { usePermissions } from 'react-admin';
import * as analytics from '../../../analytics';
import { formatPlural } from '../../../utils';

const useStyles = makeStyles(theme => ({
    container: {
        borderRadius: 5,
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        '& .MuiTypography-root': {
            color: theme.palette.primary.contrastText,
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
    },
    avatarWrap: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    personName: {
        marginTop: 0,
        color: theme.palette.secondary.main + ' !important',
        marginBottom: 0,
        fontSize: 32
    },
    ratingDataWrap: {
        display: 'flex',
        flex: 1,
        padding: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    ratingWrap: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: `${theme.spacing(0)}px ${theme.spacing(2)}px`,
        borderLeft: `2px solid ${theme.palette.secondary.main}`,
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
            borderLeft: 'none',
            textAlign: 'center'
        },
    },
    ratingText: {
        marginTop: 0,
        marginBottom: 0,
        fontSize: 24,
    },
    button: {
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
        },
    },
    secondaryText: {
        color: theme.palette.secondary.main,
    },
    topRatedImage: {
        maxHeight: 150,
        maxWidth: 150,
        marginRight: 20,
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'center',
            marginRight: 0,
        },
    },
    rating: {
        marginRight: theme.spacing(1.5),
        marginLeft: theme.spacing(1.5),
    },
    reviewText: {
        textDecoration: 'none',
        marginTop: 0,
        marginBottom: 0,
        [theme.breakpoints.down('sm')]: {
            margin: `${theme.spacing(1)}px 0 0`,
        },
    },
    allTime: {
        color: theme.palette.secondary.main,
        fontSize: 26,
    },
}));

const WelcomePanel = props => {
    const { firstName, permalink, data } = props;
    let loading = _.isNull(firstName) || !data;
    const rating = _.get(data, 'overall_rating', 0);
    const classes = useStyles();
    const { permissions } = usePermissions();
    const isTopRated = permissions?.is_top_rated;
    const reviewTextPlural = formatPlural(_.get(data, 'review_and_fi_count', 0), 'review', 'reviews');

    return (
        <div className={classes.container}>
            <div className={classes.avatarWrap}>
                {loading ? (
                    <Skeleton
                        variant="circle"
                        height={160}
                        width={160}
                        style={{ marginRight: 24 }}
                    />
                ) : (
                    <>
                        <ProfileAvatar loading={loading} />
                        <Button
                            variant="contained"
                            color="tertiary"
                            href={`${ROOT_DOMAIN}/${permalink}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() =>
                                analytics.track('view_profile', {
                                    category: 'home_profile_banner',
                                })
                            }
                            className={classes.button}>
                            View Profile
                        </Button>
                    </>
                )}
            </div>
            <div className={classes.ratingDataWrap}>
                <SkeletonWrapper loading={loading} height={45} width={235}>
                    <div className={classes.ratingWrap}>
                        <Typography className={`${classes.personName} welcome`} component="h2" variant="h3">
                            {`Hi, ${firstName}`}
                        </Typography>

                        {loading ? (
                            <Skeleton animation={'pulse'} height={40} width={180} />
                        ) : (
                            Number(rating) > 0 && (
                                <Typography
                                    component="h3"
                                    color="secondary"
                                    className={classes.ratingText}>
                                    <b>
                                        Your rating:{' '}
                                        <span className={classes.secondaryText}>{rating} / 5</span>
                                    </b>
                                </Typography>
                            )
                        )}

                        <Typography variant="body1" color="primary" className={classes.reviewText}>
                            {loading ? (
                                <Skeleton animation="pulse" width={220} height={25} />
                            ) : (
                                <>
                                    <b className={classes.allTime}>
                                        {_.get(data, 'review_and_fi_count', 0)}&nbsp;all time&nbsp;
                                        {reviewTextPlural}
                                    </b>
                                    <span>
                                        {` (${_.get(
                                            data,
                                            'review_count',
                                            0
                                        )} from clients and ${_.get(
                                            data,
                                            'first_impression_count',
                                            0
                                        )} from prospects)`}
                                    </span>
                                </>
                            )}
                        </Typography>

                        {loading ? (
                            <Skeleton animation="pulse" width={220} height={25} />
                        ) : (
                            Number(rating) > 0 && (
                                <Typography
                                    variant="body1"
                                    color="primary"
                                    className={classes.reviewText}>
                                    {`Last review: ${_.get(
                                        data,
                                        'days_since_last_review',
                                        0
                                    )} days ago`}
                                </Typography>
                            )
                        )}
                    </div>
                </SkeletonWrapper>
            </div>
            {isTopRated && (
                <img src={TOP_RATED_ADVISER_VF_2024} className={classes.topRatedImage} />
            )}
        </div>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        firstName: basicInfo.first_name,
        permalink: _.get(basicInfo, ['permalink', 'url'], null),
    };
};

export default connect(mapStateToProps)(WelcomePanel);
