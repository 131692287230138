import React, { useState } from 'react';
import dataProvider from '../dataProvider';
import { LOCAL_STORAGE, CONTACT } from '../constants';

import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

const { AUTH_ERROR_MESSAGE } = LOCAL_STORAGE;
const { PHONE_NUMBER, EMAIL } = CONTACT;

const styles = theme => ({
    bold: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
});

const LoginResetEmailMessage = ({ classes, email }) => {
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRequestFailed, setIsRequestFailed] = useState(false);

    const resendEmailVerification = e => {
        if (isLoading) return;

        sessionStorage.removeItem(AUTH_ERROR_MESSAGE);

        const params = { data: { email } };

        dataProvider(
            'CREATE',
            'professionals/resend-verification-email',
            params
        )
            .then(() => setIsEmailSent(true))
            .catch(() => setIsRequestFailed(true))
            .finally(() => setIsLoading(false));
    };

    if (!email) return null;

    if (isRequestFailed) {
        return (
            <Typography variant="body1">
                Sorry, there was an error processing this request. Please
                contact our team on <b>{PHONE_NUMBER}</b> or <b>{EMAIL}</b> to
                unlock your account
            </Typography>
        );
    }

    return (
        <Typography variant="body1" color={isEmailSent ? 'default' : 'error'}>
            {isEmailSent ? (
                `A new verification link has been sent to your email address. Please check your inbox`
            ) : (
                <>
                    Your account has been locked as you have not yet verified
                    your email. To unlock your account, please{' '}
                    <b
                        className={classes.bold}
                        onClick={resendEmailVerification}
                    >
                        click here
                    </b>{' '}
                    to send a new verification link to your email
                </>
            )}
        </Typography>
    );
};

export default withStyles(styles)(LoginResetEmailMessage);
