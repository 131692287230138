import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormSectionTitle } from '../../Components/Typography/Headlines';
import RenderQuestions from '../Content/RenderQuestions';
import { usePermissions, useDataProvider } from 'react-admin';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ReviewRating } from '../Content/ReviewQuestions';
import DescriptionIcon from '@material-ui/icons/DescriptionRounded';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import RisksAndOpportunities from '../Content/RisksAndOpportunities'
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    link: {
        textDecoration: 'none',
    },
    descriptionIcon: {
        marginRight: '15px',
        verticalAlign: 'top'
    },
    responsesList: {
        padding: '8px 0px',
        '& .MuiTypography-h6': {
            paddingTop: 0,
            lineHeight: 1
        },
    },
    gridLayout: {
        display: 'flex',
        gap: '4rem',
        flexWrap: 'wrap'
    },
    ratingsLayout: {
        display: 'flex',
        gap: '1.5rem',
    },
    overAllColor: {
        color: theme.palette.primary.main,
    },
}));

const propTypes = {
    questions: PropTypes.array.isRequired,
};

const defaultProps = {
    questions: [],
};

const initialiseData = props => {
    let publicItems = [];
    let privateItems = [];
    let rating = 0;
    props.questions.forEach(item => {
        if (item.visible_in_review) {
            switch (item.gka) {
                case 'star_rating':
                    rating = item.answer;
                    break;
                default:
                    publicItems.push(item);
            }
        } else {
            privateItems.push(item);
        }
    });
    return { publicItems, privateItems, rating };
};

const FirstImpressionsQuestions = props => {
    const [state, setState] = useState({
        publicItems: [],
        privateItems: [],
        rating: 0,
    });
    const elevationPermission = useSelector(({ basicInfo }) => basicInfo?.permissions?.elevation);

    const classes = useStyles();
    const { showPrivate, showPublic } = props;

    useEffect(() => {
        const data = initialiseData(props);
        setState({ ...data });
    }, [props]);

    const { permissions } = usePermissions();
    const isFirmOnElevation = permissions?.is_firm_on_elevation;

    const dataProvider = useDataProvider()

    useEffect(() => {
        if (elevationPermission) {
            dataProvider.update(`first-impressions/seen`, { id: props.record.id});
        }
    }, [])

    return (
        <>
            {showPublic && (
                <Box className={classes.gridLayout}>
                    <Box className={classes.ratingsLayout}>
                        <ReviewRating value={state.rating} />
                        <Typography className={classes.overAllColor} variant={'body1'}>
                            <b>Overall Rating</b>
                        </Typography>
                    </Box>

                    {state.publicItems.map(item => (
                        <div key={item.question}><RenderQuestions item={item} publicQuestions={true} /></div>
                    ))}
                </Box>
            )}

            <>
                <RisksAndOpportunities
                    isOpportunity={false}
                    visible={elevationPermission && showPrivate}
                    {...props?.record?.potential_risks}
                />
                <RisksAndOpportunities
                    isOpportunity={true}
                    visible={elevationPermission && showPrivate}
                    {...props?.record?.opportunities}
                />
            </>
            {showPrivate && (
                <>
                    <FormSectionTitle
                        title="All Private Responses"
                        color={'textPrimary'}
                        variant={'h4'}
                        icon={<DescriptionIcon className={classes.descriptionIcon} />}
                    />
                    {!isFirmOnElevation && (
                        <>
                            <Typography variant="body1">
                                To learn more, read our{' '}
                                <a
                                    className={classes.link}
                                    target="_blank"
                                    href=" https://support.vouchedfor.co.uk/article/434-new-client-experience-questions">
                                    help article{' '}
                                </a>
                                on our 'New Client Experience Questions'
                            </Typography>
                            <span hidden>\n\n</span>
                        </>
                    )}
                    <div className={classes.responsesList}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {
                                    [...state.privateItems].reverse().map(item => (
                                        <div key={item.question}><RenderQuestions item={item} /></div>
                                    ))
                                }
                            </Grid>
                        </Grid>
                    </div>
                </>
            )}
        </>
    );
};

FirstImpressionsQuestions.propTypes = propTypes;
FirstImpressionsQuestions.defaultProps = defaultProps;

export default FirstImpressionsQuestions;
