import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ElevationList from "../Components/ElevationList";
import {CallToActionButton} from "../../Components/Buttons/Buttons";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    section: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: `${theme.spacing(10)}px ${theme.spacing(8)}px`,
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
            padding: `${theme.spacing(5)}px ${theme.spacing(2)}px`,
        },
    },
    contentWrapper: {
        maxWidth: 1200,
    },
    headingText: {
        paddingBottom: theme.spacing(3),
    },
}));

const ElevationSection = () => {
    const classes = useStyles();

    return (
        <section className={classes.section}>
            <Grid container justify="center">
                <Grid item lg={10} md={12} sm={12} xs={12} className={classes.contentWrapper} id="elevation-anchor">
                    <Typography className={classes.headingText} component="h2" variant="h4" color="inherit">
                        Power your client experience and increase revenue, with Elevation
                    </Typography>
                    <ElevationList />
                    <CallToActionButton href="https://professionals.vouchedfor.co.uk/elevation?utm_source=homepage">
                        Find out more
                    </CallToActionButton>
                </Grid>
            </Grid>
        </section>
    );
};

export default ElevationSection;
