import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomChart from '../Charts/CustomChart';
import { palette } from '../../../../MyTheme';
import CustomCard from '../CustomCard';
import { Typography } from '@material-ui/core';
import Tip from '../Tip';
import Button from '../../../../Components/Buttons/CustomButton';
import * as analytics from '../../../../analytics';

const useStyles = makeStyles(theme => ({
    root: {},
    potentialClientsSection: {
        backgroundColor: theme.palette.secondary.light,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: `${theme.spacing(14)}px !important`,
            paddingRight: `${theme.spacing(14)}px !important`,
        },
    },
    wrapper: {
        display: 'flex',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
    },
    toggleWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginBottom: theme.spacing(-5),
        marginTop: theme.spacing(-2.5),
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    tipWrapper: {
        border: '2px solid ' + theme.palette.tertiary.dark,
        padding: theme.spacing(3),
        margin: `${theme.spacing(6)}px ${theme.spacing(5)}px ${theme.spacing(0)}px ${theme.spacing(
            5
        )}px`,
        '& .MuiButtonBase-root': {
            width: 146,
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
        },
    },
    analyticsVisitsSection: {
        backgroundColor: theme.palette.primary.contrastText,
    },
    section: {
        marginBottom: theme.spacing(6),
        border: '2px solid ' + theme.palette.tertiary.dark,
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(3),
        },
        padding: theme.spacing(1),
    },
    title: {
        fontSize: '1.2rem',
        textTransform: 'uppercase',
        letterSpacing: '1.2px',
        fontWeight: 700,
        textAlign: 'center',
        marginBottom: theme.spacing(1),
        marginTop: 0,
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(3),
        },
    },
    circleText: {
        maxWidth: '350px',
        textAlign: 'center',
        marginTop: '1rem',
        [theme.breakpoints.down('md')]: {
            marginBottom: '1rem',
        },
    },
    element: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    button: {
        marginBottom: '1rem',
        marginTop: '1rem',
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center',
        borderRadius: 5,
        '& .MuiButton-containedPrimary': {
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        '& .MuiButton-containedSecondary': {
            backgroundColor: theme.palette.secondary.main,
            '&:hover': {
                backgroundColor: theme.palette.secondary.main,
            },
        },
        backgroundColor: theme.palette.tertiary.contrastText,
        padding: theme.spacing(4),
        '& .MuiButton-root': {
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
            borderRadius: 5,
            '&.disabled': {
                backgroundColor: theme.palette.primary.dark,
            },
        },
        '& .MuiButton-label': {
            fontWeight: 400,
        },
    },
    text: {
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(0),
        fontWeight: 700,
    },
}));

const VisitStats = props => {
    const classes = useStyles();
    const {
        data,
        lines,
        bars,
        statistics,
        hiddenElements,
        setVisitsGraphVisible,
        visitsGraphVisible,
    } = props;
    const [colors] = useState([
        palette.primary.main,
        palette.secondary.main,
        palette.tertiary.main,
        palette.error.main,
    ]);
    const [barColors] = useState([palette.secondary.light]);
    const [cardsCirlce, setCardsCircle] = useState([
        {
            title: 'Potential Enquirers',
            number: 0,
            key: 'indirect_enquirers',
            text:
                'Spent 1+ minutes on your profile and then left for another site - often your own website',
        },
        {
            title: 'Direct Enquirers',
            number: 0,
            text: 'Found you and contacted you on VouchedFor',
            key: 'direct_enquirers',
        },
    ]);

    const [cards, setCards] = useState([
        {
            title: 'VouchedFor',
            number: 0,
            key: 'vf_directory_profile_visits_365_days',
            text: 'People who found you via the VouchedFor Directory',
        },
        {
            title: 'Googlers',
            number: 0,
            text: 'Googled your name and clicked onto your profile',
            key: 'googlers',
        },
        {
            title: 'Clickers',
            number: 0,
            key: 'clickers',
            text:
                'Clicked a link to your profile (i.e. from an email signature, rep tool or from your own website)',
        },
    ]);

    useEffect(() => {
        updateCards(cardsCirlce, setCardsCircle);
        updateCards(cards, setCards);
    }, [statistics]);

    const updateCards = (cardsToUpdate, setCardToUpdate) => {
        if (statistics) {
            const newCards = [...cardsToUpdate];
            newCards.map((card, i) => {
                if (statistics[card.key]) {
                    newCards[i].number = statistics[card.key].value
                        ? statistics[card.key].value
                        : typeof statistics[card.key] !== 'object'
                        ? statistics[card.key]
                        : 0;
                }
                return true;
            });
            setCardToUpdate([...newCards]);
        }
    };

    const inviteOnClick = () =>
        analytics.track('invite_reviews', { category: 'How_they_found_you' });
    const repOnClick = () => analytics.track('use_rep_tools', { category: 'How_they_found_you' });

    return (
        <>
            {!hiddenElements.includes('potentialClients') ? (
                <div className={`${classes.potentialClientsSection} ${classes.section}`}>
                    <Typography className={classes.title}>
                        These people visited your profile and showed high intent
                    </Typography>
                    <div className={classes.wrapper}>
                        {cardsCirlce.map(d => (
                            <div className={classes.element} key={d.key}>
                                <CustomCard title={d.title} value={d.number} circle={true} />
                                <div className={classes.circleText}>{d.text}</div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : null}
            <div className={`${classes.analyticsVisitsSection} ${classes.section}`}>
                <Typography className={classes.title}>How they found your profile</Typography>
                <div className={classes.wrapper}>
                    {cards.map(d => (
                        <CustomCard
                            key={d.key}
                            title={d.title}
                            value={d.number}
                            subtitle={d.text}
                        />
                    ))}
                </div>

                <div className={`${classes.wrapper} ${classes.tipWrapper}`}>
                    <Tip
                        whiteBackground
                        text="Inviting more reviews will drive more people to your profile via the VouchedFor directory and Google!"
                        showInviteButton
                        inviteOnClick={inviteOnClick}
                        margin={false}
                    />
                    <Tip
                        whiteBackground
                        text="Utilising your rep tools will drive more 'Clickers' to your profile!"
                        showRepButton
                        repOnClick={repOnClick}
                        margin={false}
                    />
                </div>
            </div>
            {!hiddenElements.includes('potentialClients') ? (
                <div className={classes.toggleWrapper}>
                    <Typography variant="h4" className={classes.text}>
                        Toggle between Potential Clients and How They Found You view
                    </Typography>
                    <div className={classes.buttonWrapper}>
                        <Button
                            className={`${visitsGraphVisible === 'potential' ? '' : 'disabled'} `}
                            onClick={() => setVisitsGraphVisible('potential')}
                            color="primary"
                            variant="contained">
                            Potential clients
                        </Button>
                        <Button
                            className={`${visitsGraphVisible === 'found' ? '' : 'disabled'} `}
                            onClick={() => setVisitsGraphVisible('found')}
                            color="secondary"
                            variant="contained">
                            How they found you
                        </Button>
                    </div>
                </div>
            ) : null}
            <CustomChart
                dataPoints={data}
                bars={bars}
                lines={lines}
                lineColors={colors}
                barColors={barColors}
            />
        </>
    );
};

export default VisitStats;
