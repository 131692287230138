import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Button from '../../Components/Buttons/CustomButton';
import AddIcon from '@material-ui/icons/Add';

const propTypes = {
    to: PropTypes.object.isRequired,
};

const AddFeeLevelButton = ({ to, variant = 'contained' }) => {
    return (
        <Button
            component={Link}
            color="tertiary"
            label="Add a fee level"
            variant={variant}
            style={{ marginRight: 8 }}
            to={to}
        >
            {variant === 'text' && <AddIcon style={{ fontSize: 20 }} />}
            Add a fee level
        </Button>
    );
};

AddFeeLevelButton.propTypes = propTypes;

export default AddFeeLevelButton;
