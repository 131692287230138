import React from 'react';
import cn from 'classnames';
import { required } from 'react-admin';
import { validateEmail } from '../../validation';
import FormTextInput from '../../Components/Inputs/Form/FormTextInput';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
}));

const ReviewInviteRecipientFields = ({ inputProps = {}, className = '' }) => {
    const classes = useStyles();

    return (
        <div
            className={cn({
                [classes.container]: true,
                [className]: Boolean(className),
            })}
        >
            <FormTextInput
                inline
                validate={[required()]}
                label="Client first name"
                source="client_first_name"
                {...inputProps}
            />
            <FormTextInput
                inline
                validate={validateEmail}
                label="Client email"
                source="client_email"
                {...inputProps}
            />
        </div>
    );
};

export default ReviewInviteRecipientFields;
