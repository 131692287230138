import React, { useEffect } from 'react';
import _ from 'lodash';
import { SimpleForm } from 'react-admin';
import { useSelector } from 'react-redux';
import { LOCAL_STORAGE, PROFESSIONAL } from '../constants';
import PreventUndoEdit from '../Components/PreventUndoEdit';
import BasicProfileEditFormContent from './Components/BasicProfileEditFormContent';
import BasicProfileEditToolbar from './Components/BasicProfileEditToolbar';

const { BASIC_PROFILE_FORM_VALUES } = LOCAL_STORAGE;
const { FINANCIAL_ADVISER, ACCOUNTANT, LEGAL_ADVISER } = PROFESSIONAL.VERTICAL;

const getBasicInfoFormInitialValues = vertical => {
    const persistedValues = JSON.parse(sessionStorage.getItem(BASIC_PROFILE_FORM_VALUES));

    if (!persistedValues) {
        return {
            auto_invite: true,
        };
    }

    const initialValues = {
        auto_invite: true,
        phone_direct: _.get(persistedValues, 'phone_direct', ''),
        address: {
            address_line1: _.get(persistedValues, 'address.address_line1', ''),
            address_line2: _.get(persistedValues, 'address.address_line2', ''),
            town: _.get(persistedValues, 'address.town', ''),
            postcode: _.get(persistedValues, 'address.postcode', ''),
        },
    };

    if (vertical === LEGAL_ADVISER) {
        return {
            ...initialValues,
            firm_name: _.get(persistedValues, 'firm_name', ''),
            legal_adviser_option: _.get(persistedValues, 'legal_adviser_option', ''),
            attributes: {
                professional_registry_sra: {
                    value: _.get(
                        persistedValues,
                        'attributes.professional_registry_sra.value',
                        null
                    ),
                },
                professional_registry_sra_scotland_and_northern_ireland: {
                    value: _.get(
                        persistedValues,
                        'attributes.professional_registry_sra_scotland_and_northern_ireland.value',
                        null
                    ),
                },
                professional_registry_professional_paralegal_register: {
                    value: _.get(
                        persistedValues,
                        'attributes.professional_registry_professional_paralegal_register.value',
                        null
                    ),
                },
            },
        };
    }

    if (vertical === ACCOUNTANT) {
        return {
            ...initialValues,
            firm_name: _.get(persistedValues, 'firm_name', ''),
            chartered_qualification: _.get(persistedValues, 'chartered_qualification', ''),
            chartered_qualification_description: _.get(
                persistedValues,
                'chartered_qualification_description',
                ''
            ),
        };
    }

    if (vertical === FINANCIAL_ADVISER) {
        const minimumMortgageCriteria = _.get(
            persistedValues,
            'attributes.attributes.minimum_mortgage_criteria'
        );
        const minimumInvestmentCriteria = _.get(
            persistedValues,
            'attributes.attributes.minimum_investment_criteria'
        );

        const values = {
            ...initialValues,
            no_personal_fca_id: _.get(persistedValues, 'no_personal_fca_id'),
            firm: {
                id: _.get(persistedValues, 'firm.id', ''),
                name: _.get(persistedValues, 'firm.name', ''),
            },
            attributes: {
                financial_adviser_personal_fca: {
                    value: _.get(
                        persistedValues,
                        'attributes.financial_adviser_personal_fca.value',
                        ''
                    ),
                },
                financial_adviser_firm_fca: {
                    value: _.get(
                        persistedValues,
                        'attributes.financial_adviser_firm_fca.value',
                        ''
                    ),
                },
            },
        };

        if (Boolean(minimumMortgageCriteria)) {
            values.attributes.minimum_mortgage_criteria = minimumMortgageCriteria;
        }

        if (Boolean(minimumInvestmentCriteria)) {
            values.attributes.minimum_investment_criteria = minimumInvestmentCriteria;
        }

        return values;
    }
};

const BasicProfileEdit = props => {
    const vertical = useSelector(state => state.basicInfo?.permissions?.vertical);

    useEffect(() => {
        localStorage.setItem(LOCAL_STORAGE.NEW_ADVISER, '1');
    }, []);

    return (
        <PreventUndoEdit {...props}>
            <SimpleForm
                initialValues={getBasicInfoFormInitialValues(vertical)}
                submitOnEnter={false}
                redirect={false}
                toolbar={<BasicProfileEditToolbar />}
            >
                <BasicProfileEditFormContent />
            </SimpleForm>
        </PreventUndoEdit>
    );
};

export default BasicProfileEdit;
