import React from 'react';
import _ from 'lodash';
import { SimpleForm } from 'react-admin';
import { connect } from 'react-redux';
import FormToolbar from '../Components/Toolbars/FormToolbar';
import EnquiriesPageWrapper from './Components/EnquiriesPageWrapper';
import EnquiriesListContent from './Components/EnquiriesListContent';
import EnquirySettings from './Components/EnquirySettings';
import EnquiryPauseFields from './Components/EnquiryPauseFields';

const EnquiriesList = props => {
    return (
        <EnquiriesPageWrapper variant="edit" {...props}>
            <SimpleForm
                submitOnEnter={false}
                redirect={false}
                onSubmit={e => e.preventDefault()}
                toolbar={<FormToolbar label="save" submitOnEnter={false} />}
            >
                <EnquiriesListContent {...props} />
                <EnquirySettings permissions={_.get(props, 'permissions', {})} />
                <EnquiryPauseFields />
            </SimpleForm>
        </EnquiriesPageWrapper>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        professionalId: basicInfo.id,
    };
};

export default connect(mapStateToProps)(EnquiriesList);
