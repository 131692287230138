import FirmReputationTools from './FirmReputationTools';
import FirmLinkedAdvisers from './FirmLinkedAdvisers';
import FirmDetails from './FirmDetails';
import FirmReviews from './FirmReviews';
import FirmReviewEdit from './FirmReviewEdit';
import FirmEditDetails from './FirmEditDetails';

export default {
    reviewEdit: FirmReviewEdit,
    reviews: FirmReviews,
    tools: FirmReputationTools,
    details: FirmDetails,
    editDetails: FirmEditDetails,
    linkedAdvisers: FirmLinkedAdvisers,
};
