import React from 'react';
import { CardContent, Typography, makeStyles, Box, Card } from '@material-ui/core';
import Button from '../../Components/Buttons/CustomButton';
import PrivateDashboardListItem from './Components/PrivateDashboardListItem';
import { PUBLIC_PROFILE_BENEFITS } from '../../constants';
import imacImage from '../../assets/images/imac-private-mode-dashboard.png';
import iphoneImage from '../../assets/images/iphone-x-private-mode-dashbaord.png';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: '2fr 1fr',
    },
    blockContainer: {
        display: 'block',
    },
    upgradeContainer: {
        paddingLeft: '2rem',
        paddingRight: '2rem',
    },
    images: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        width: '100%',
    },
    imgImac: {
        width: '100%',
    },
    imgIphone: {
        width: '100%',
    },
    buttonStyle: {
        maxWidth: '220px',
    }
}));

const PublicProfileUpgrade = () => {
    const classes = useStyles();
    const theme = useTheme();
    const onMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Card >
            <CardContent className={onMobile ? classes.blockContainer :classes.gridContainer}>
                <div>
                <Typography variant="h5" color={'error'}>
                    <b>YOUR PROFILE IS SET TO PRIVATE</b>
                </Typography>
                <Typography variant="h5">
                    If you'd like to showcase your reviews publicly, you can upgrade to our Verified
                    membership.
                </Typography>
                <Box marginBottom={'2rem'}>
                    <ul>
                        {PUBLIC_PROFILE_BENEFITS.map(benefit => {
                            return (
                                <PrivateDashboardListItem
                                    primary={benefit.primary}
                                    secondary={benefit.secondary}
                                />
                            );
                        })}
                    </ul>
                </Box>
                </div>
                <Box className={classes.images}>
                    <img
                        className={classes.imgImac}
                        src={imacImage}
                        alt={"vouchedFor image imac"}
                    />
                    <img
                        className={classes.imgIphone}
                        src={iphoneImage}
                        alt={"vouchedFor image iphone"}
                    />
                </Box>
                <Button className={classes.buttonStyle} variant="contained" color="tertiary" href={`/payment`}>
                    Make my profile public
                </Button>
            </CardContent>
        </Card>
    );
};

export default PublicProfileUpgrade;
