import React from 'react';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import withStyles from '@material-ui/core/styles/withStyles';
import Hidden from '@material-ui/core/Hidden';


const propTypes = {
    /**
     * Data received from the SimpleForm
     */
    record: PropTypes.array.isRequired,
    /**
     * A render function to call in the parent which maps over each row.
     * Material-ui table components should be used in almost every case (e.g. TableRow + TableCell)
     */
    renderRow: PropTypes.func.isRequired,
    /**
     *  An array of grid column labels
     */
    columns: PropTypes.array.isRequired,
    /**
     * Inherited from Material-ui withStyles() HOC
     */
    classes: PropTypes.object.isRequired,
};

const styles = theme => ({
    headCell: {
        [theme.breakpoints.down('sm')]: {
            minWidth: theme.spacing(25),
        },
    },
    body: {
        '& > tr': {
            // Changes grid to vertical layout on mobile
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                padding: `${theme.spacing(2)}px 0`,
                borderBottom: `1px solid rgba(224, 224, 224, 1)`
            },
        },
        '& > tr > td': {
            // Fix styling for vertical mobile layout
            [theme.breakpoints.down('sm')]: {
                borderBottom: 0,
                padding: `4px 0`,
                fontSize: 'inherit',
            },
        },
        // Removes margin for React Admin input components
        '& > tr > td > div': {
            margin: 0,
        },

        // Removes margin from React Admin select dropdown
        '& > tr > td > div > div': {
            margin: 0,
        }
    },
});

const FormGrid = props => {
    const { record, renderRow, columns, classes } = props;

    if (!record) return null;

    return (
        <Table>
            <Hidden mdDown>
                <TableHead>
                    <TableRow>
                        {
                            columns.map(column => (
                                <TableCell
                                    className={classes.headCell}
                                    key={column}
                                    sortDirection={false}
                                >
                                    {column}
                                </TableCell>
                            ))
                        }
                    </TableRow>
                </TableHead>
            </Hidden>
            <TableBody className={classes.body}>
                {record.map(renderRow)}
            </TableBody>
        </Table>
    );
};

FormGrid.propTypes = propTypes;

export default withStyles(styles)(FormGrid);
