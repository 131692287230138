export const referralGeneratorStyles = theme => ({
    formWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    logoCard: {
        textAlign: 'center',
        padding: `${theme.spacing(4)}px ${theme.spacing(8)}px ${theme.spacing(8)}px`,
        backgroundColor: theme.palette.tertiary.light,
        border: `1px solid ${theme.palette.primary.dark}`,
        borderRadius: 4,
        marginTop: theme.spacing(3),

        '& > img': {
            height: theme.spacing(7),
            marginBottom: theme.spacing(4),
        },
        '& > div': {
            backgroundColor: theme.palette.primary.contrastText,
        },
        [theme.breakpoints.down('sm')]: {
            backgroundColor: theme.palette.primary.contrastText,
        },
        [theme.breakpoints.down('xs')]: {
            padding: 0,
            border: 0,
            '& > img': {
                marginBottom: theme.spacing(1),
            },
        },
    },
    formElementsWrap: {
        border: `1px solid ${theme.palette.primary.dark}`,
        textAlign: 'left',
        padding: theme.spacing(5),
        [theme.breakpoints.down('xs')]: {
            padding: 0,
            border: 'none',
        },
    },
    referralDropdowns: {
        width: 'auto !important',
        margin: 0,

        '& .MuiSelect-root': {
            color: theme.palette.secondary.main,
        },
        '& .MuiFormHelperText-root': {
            display: 'none',
        }
    },
    dropdownInput: {
        margin: '0 12px',
        background: 'unset',
        '& > div': {
            padding: '8px 32px 8px 8px',
        }
    },
    editCustomersButton: {
        position: 'relative',
        marginLeft: theme.spacing(2),
    },
    noReward: {
        opacity: 0.3,
    },
    referralNoteMessage: {
        marginTop: -theme.spacing(1),
    },
    customersListWrapper: {
        paddingTop: theme.spacing(3),
        boxShadow:
            '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
    },
    customersList: {
        marginBottom: theme.spacing(3),
        '& > div > div': {
            boxShadow: 'none',

            '& > .MuiToolbar-root': {
                display: 'none',
            }
        },
    },
    referralsPerformCard: {
        margin: `${theme.spacing(3)}px 0`,
        width: 'auto',
        padding: 0,
    },
    titleWrapper: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(2),
        backgroundColor: theme.palette.secondary.faint,
        marginBottom: theme.spacing(3),
        textAlign: 'center',
    },
    performCardItemsWrapper: {
        padding: `0 12px`,
    },
    helperText: {
        margin: `0 0 ${theme.spacing(2)}px`,
    },
    acceptedTermsAndConditionsInput: {
        textAlign: 'left',
        marginTop: theme.spacing(2),
    },
    noRewardMessage: {
        marginLeft: theme.spacing(1),
    },
});
