import React, { useState } from 'react';
import { useRefresh, showNotification } from 'react-admin';
import { useDispatch } from 'react-redux';
import Button from '../../Components/Buttons/CustomButton';
import dataProvider from '../../dataProvider';
import {
    REVIEW_INVITE_TYPE_CLIENT,
    REVIEW_INVITE_TYPE_PROSPECT,
    REVIEW_INVITE_TYPE_REVIEWS
} from "../Invite/ReviewInviteContent";
import * as analytics from "../../analytics";

const InviteSubmitButton = ({
        buttonLabel, 
        values, 
        type = 'CREATE', 
        invalidForm = true, 
        resource = 'review-invitations', 
        handleError = () => {},
        onSuccess = () => {},
        ...rest}) => {
    const [sending, setSending] = useState(false);
    const dispatch = useDispatch();
    const refresh = useRefresh();


    const handleSuccess = () => {
        const labelMap = {
            [REVIEW_INVITE_TYPE_PROSPECT]: 'invited prospect',
            [REVIEW_INVITE_TYPE_CLIENT]: 'invited client',
            [REVIEW_INVITE_TYPE_REVIEWS]: 'imported'
        };

        analytics.track('complete', {
            category: 'InviteClient',
            label: labelMap[type],
        });

        dispatch(showNotification('ra.notification.invitation_sent'));

        onSuccess();
    };

    const handleSave = async () => {
        setSending(true)
        const payload = {
            data: values,
        };

        if (values.id) {
            payload.id = values.id;
        }

        try {
            await dataProvider(type, resource, payload);

            refresh();

            handleSuccess();
        } catch {
            dispatch(showNotification('ra.notification.bad_item', 'warning'));

            handleError();
        }
        setSending(false);
    };

    return (
        <>
            <Button
                onClick={handleSave}
                variant="contained"
                color="tertiary"
                disabled={sending ? true : invalidForm}
                {...rest}
            >
                {sending ? 'Sending...' : buttonLabel}
            </Button>

        </>
    );
};

export default InviteSubmitButton;
