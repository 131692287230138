import React from 'react';
import _ from 'lodash';
import { FormDataConsumer, required } from 'react-admin';
import { CHARTERED_QUALIFICATIONS, ACCOUNTANT_QUALIFICATION } from '../../constants';
import FormTextInput from '../../Components/Inputs/Form/FormTextInput';
import FormSelectInput from '../../Components/Inputs/Form/FormSelectInput';

const BasicProfileFormAccountantFields = () => {
    return (
        <>
            <FormSelectInput
                validate={[required()]}
                label="Accreditation Body"
                source="chartered_qualification"
                defaultValue=""
                choices={CHARTERED_QUALIFICATIONS}
            />
            <FormDataConsumer>
                {({ formData }) => {
                    const charteredQualification = _.get(formData, 'chartered_qualification', null);

                    if (!charteredQualification) return null;

                    if (charteredQualification === ACCOUNTANT_QUALIFICATION.OTHER) {
                        return (
                            <FormTextInput
                                label="What is your Accreditation Body?"
                                validate={[required()]}
                                key="chartered_qualification_description"
                                source="chartered_qualification_description"
                                defaultValue=""
                            />
                        );
                    }
                }}
            </FormDataConsumer>
        </>
    );
};

export default BasicProfileFormAccountantFields;
