import React, { Component } from 'react';
import _ from 'lodash';
import dataProvider from '../../dataProvider';
import CopyTextButton from '../../Components/Buttons/CopyTextButton';
import {
    REP_TOOL_FIRM_REVIEWS_DARK,
    REP_TOOL_FIRM_REVIEWS_DARK_VF_2023,
    REP_TOOL_FIRM_REVIEWS_LIGHT,
    REP_TOOL_FIRM_REVIEWS_LIGHT_VF_2023,
} from '../../constants';
import * as analytics from '../../analytics';

import Button from '../../Components/Buttons/CustomButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import withStyles from '@material-ui/core/styles/withStyles';
import ReputationToolItem from './Components/ReputationToolItem';

const styles = theme => ({
    dialog: {
        [theme.breakpoints.down('sm')]: {
            '& > div:nth-child(2)': {
                width: '100%',
            },
        },
    },
});

class FirmWidgetCarousel extends Component {
    state = {
        showModal: false,
        widgetCode: null,
    };

    componentDidMount() {
        this.fetchWidgetCode();
    }

    fetchWidgetCode = async () => {
        const { type } = this.props;
        const params = type === 'light' ? { filter: { light_theme: 1 } } : {};

        try {
            const { data } = await dataProvider('GET_ONE', 'firm/reviews-widget', params);

            if (!data) return;

            this.setState({ widgetCode: _.get(data, 'widgetCode') });
        } catch {
            return null;
        }
    };

    handleCopy = () => {
        this.sendAnalytics('Copied firm reviews widget code');
    };

    handlePreview = () => {
        if (!this.state.widgetCode) return;

        this.setState({ showModal: true });

        this.sendAnalytics('Previewed firm reviews widget code');
    };

    closePreview = () => {
        this.setState({ showModal: false });
    };

    sendAnalytics = action => {
        analytics.track(action, {
            category: 'Firm reputation tools',
        });
    };

    render() {
        const { width, type } = this.props;
        const { showModal, widgetCode } = this.state;

        const image = widgetCode ?
            (
                type === 'dark' ?
                widgetCode.includes('vf2023') ? REP_TOOL_FIRM_REVIEWS_DARK_VF_2023 : REP_TOOL_FIRM_REVIEWS_DARK
                :
                widgetCode.includes('vf2023') ? REP_TOOL_FIRM_REVIEWS_LIGHT_VF_2023 : REP_TOOL_FIRM_REVIEWS_LIGHT
            )
            :
            '';

        return (
            <>
                <ReputationToolItem
                    img={image}
                    actions={
                        <>
                            <CopyTextButton
                                clipboardContent={widgetCode}
                                onClick={this.handleCopy}
                                style={{ marginRight: 8 }}
                                variant="contained"
                            >
                                Copy widget code
                            </CopyTextButton>
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={this.handlePreview}
                            >
                                Preview
                            </Button>
                        </>
                    }
                />
                {Boolean(widgetCode) && (
                    <Dialog
                        open={showModal}
                        maxWidth={false}
                        onClose={this.closePreview}
                        className={this.props.classes.dialog}
                    >
                        <DialogContent>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: isWidthDown('md', width)
                                        ? widgetCode.replace('width="900"', 'width=100%')
                                        : widgetCode,
                                }}
                            />
                        </DialogContent>
                    </Dialog>
                )}
            </>
        );
    }
}

export default withStyles(styles)(withWidth()(FirmWidgetCarousel));
