import { formatSimpleListDate } from '../../utils';

const CustomerMasterRowNextInviteDate = ({ record = {} }) => {
    if (record?.annual_auto_invite) {
        return formatSimpleListDate(record?.calculated_next_invite_date);
    }

    return null;
};

export default CustomerMasterRowNextInviteDate;
