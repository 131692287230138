import React from 'react';
import AppPageWrapper from '../../AppLayout/AppPageWrapper';
import PageActions from '../../AppLayout/PageActions';

const EnquiriesPageWrapper = ({ children, ...props }) => {
    return (
        <AppPageWrapper header={<PageActions title="Your Enquiries" />} {...props}>
            {children}
        </AppPageWrapper>
    );
};

export default EnquiriesPageWrapper;
