import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { minLength } from 'react-admin';
import { InputAdornment, IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import FormTextInput from './Form/FormTextInput';

const propTypes = {
    inputLabel: PropTypes.string,
    onChange: PropTypes.func,
};

const defaultProps = {
    inputLabel: 'New password',
    onChange: () => {},
};

class ShowablePasswordInput extends Component {
    state = {
        textVisible: false,
    };

    showPassword = () => {
        this.setState({
            textVisible: !this.state.textVisible,
        });
    };

    renderEndAdornment = textVisible => (
        <InputAdornment position="end">
            <IconButton color="secondary" onClick={this.showPassword}>
                {textVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
        </InputAdornment>
    );

    render() {
        const { textVisible } = this.state;
        const { inputLabel, ...props } = this.props;

        return (
            <FormTextInput
                label={inputLabel}
                source={_.get(this.props, 'source', null)}
                validate={minLength(8)}
                type={textVisible ? 'text' : 'password'}
                onChange={this.props.onChange}
                {...props}
                InputProps={{
                    endAdornment: this.renderEndAdornment(textVisible),
                }}
            />
        );
    }
}

ShowablePasswordInput.defaultProps = defaultProps;
ShowablePasswordInput.propTypes = propTypes;

export default ShowablePasswordInput;
