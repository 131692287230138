import React, { Component, Fragment } from 'react';
import { SimpleForm } from 'react-admin';
import FormToolbar from '../Components/Toolbars/FormToolbar';
import dataProvider from '../dataProvider';
import ForgottenPasswordEmailSent from './Content/ForgottenPasswordEmailSent';
import { forgottenPasswordStyles } from './forgottenPasswordStyles';
import { validateEmail } from '../validation';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import FormTextInput from '../Components/Inputs/Form/FormTextInput';

class ForgottenPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitted: false,
        };
    }

    componentDidMount() {
        // Ensure the user is logged out
        localStorage.clear();
    }

    handleSubmit = formData => {
        dataProvider('CREATE', 'resetting/send-email', { data: formData })
            .then(res => {
                this.setState({
                    submitted: res.data.success,
                });
            })
            .catch(error => console.log(error));
    };

    renderContent = submitted => {
        const { classes } = this.props;

        if (submitted) {
            return <ForgottenPasswordEmailSent />;
        }

        return (
            <Fragment>
                <Typography>
                    <b>Login to your VouchedFor account</b>
                </Typography>
                <Typography>
                    Please enter your email address below, and we will send you a link to reset your
                    password
                </Typography>
                <SimpleForm
                    className={classes.forgotSimpleForm}
                    save={this.handleSubmit}
                    toolbar={<FormToolbar label="Send reset link" />}
                >
                    <FormTextInput
                        className={classes.forgotSimpleFormInput}
                        source="username"
                        label="Email"
                        validate={validateEmail}
                    />
                </SimpleForm>
            </Fragment>
        );
    };

    render() {
        const { classes } = this.props;

        return (
            <Grid container justify="center" className={classes.forgotContainer}>
                {this.renderContent(this.state.submitted)}
            </Grid>
        );
    }
}

export default withStyles(forgottenPasswordStyles)(ForgottenPassword);
