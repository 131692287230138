import React from 'react';
import { connect } from 'react-redux';
import PageTabs from '../../../AppLayout/PageTabs';

const SettingBillingTabs = props => {
    return (
        <PageTabs
            routes={[
                {
                    label: 'Account Settings',
                    link: `/account-settings/${props.professionalId}`,
                },
                {
                    label: 'Billing',
                    link: '/billing',
                },
            ]}
        />
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        professionalId: basicInfo.id,
    };
};

export default connect(mapStateToProps)(SettingBillingTabs);
