import React from 'react';
import {
    SelectField,
    EditButton,
    DeleteButton,
    ReferenceManyField,
    Responsive,
    SimpleList,
    Datagrid,
    TextField,
} from 'react-admin';

import { MEETING_LOCATIONS_OPTIONS } from '../../constants/lists';

const MeetingLocationsGrid = props => (
    <ReferenceManyField
        {...props}
        label={null}
        reference="meeting-locations"
        target="professional_id"
    >
        <Responsive
            small={
                <SimpleList
                    primaryText={record => record.type}
                    secondaryText={record => record.description}
                />
            }
            medium={
                <Datagrid>
                    <SelectField
                        source="type"
                        label="Location"
                        choices={MEETING_LOCATIONS_OPTIONS}
                    />
                    <TextField label="Description" source="description" />
                    <EditButton color="secondary" />
                    <DeleteButton redirect={false} />
                </Datagrid>
            }
        />
    </ReferenceManyField>
);

export default MeetingLocationsGrid;
