import React from 'react';
import { required } from 'react-admin';
import { Typography } from '@material-ui/core';
import { ExternalLink } from '../../Components/Links';
import FormTextInput from '../../Components/Inputs/Form/FormTextInput';
import FormSelectInput from '../../Components/Inputs/Form/FormSelectInput';

const BasicProfileFormLegalAdviserPoints = props => {
    const { legalAdviserOption } = props;

    switch (legalAdviserOption) {
        case 'professional_registry_sra':
            return (
                <div>
                    <Typography variant="body1">
                        Please enter your personal SRA ID, VouchedFor will use this to periodically
                        check your SRA regulation status
                    </Typography>
                    <FormTextInput
                        validate={[required()]}
                        label="My personal SRA ID"
                        source="attributes.professional_registry_sra.value"
                        defaultValue={null}
                    />
                </div>
            );
        case 'professional_registry_sra_scotland_and_northern_ireland':
            return (
                <FormSelectInput
                    validate={[required()]}
                    label="Please select"
                    source="attributes.professional_registry_sra_scotland_and_northern_ireland.value"
                    defaultValue={null}
                    choices={[
                        {
                            id: 'Scotland',
                            name: 'I am authorised to practice in Scotland',
                        },
                        {
                            id: 'Northern Ireland',
                            name: 'I am authorised to practice in Northern Ireland',
                        },
                    ]}
                />
            );
        case 'professional_registry_professional_paralegal_register':
            return (
                <div>
                    <Typography variant="body1">
                        VouchedFor accepts paralegals that have:
                    </Typography>
                    <ul>
                        <li>
                            <Typography variant="body1">
                                A Paralegal Practicing Certificate from the Professional Paralegal
                                Register (PPR) AND
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                Are at least a Tier 2 Paralegal (see{' '}
                                <ExternalLink href="http://ppr.org.uk/paralegals/the-tiers-explained">
                                    here
                                </ExternalLink>
                                )
                            </Typography>
                        </li>
                    </ul>
                    <FormTextInput
                        validate={[required()]}
                        label="PPR Registration number"
                        source="attributes.professional_registry_professional_paralegal_register.value"
                        defaultValue={null}
                    />
                </div>
            );
        default:
            return null;
    }
};

export default BasicProfileFormLegalAdviserPoints;
