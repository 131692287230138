import React from 'react';
import { required } from 'react-admin';
import FormTextInput from './Form/FormTextInput';
import { validateEmail } from '../../validation';

export const LoginEmailField = props => {
    return (
        <FormTextInput
            {...props}
            source="email"
            label="Email"
            validate={validateEmail}
            fullWidth
        />
    );
};

export const LoginPasswordField = props => {
    return (
        <FormTextInput
            {...props}
            source="password"
            label="Password"
            type="password"
            validate={[required()]}
            fullWidth
        />
    );
};
