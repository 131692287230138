import { useState, useEffect } from 'react';
import _ from 'lodash';
import dataProvider from '../dataProvider';
import authProvider from '../authProvider';
import { useForm } from 'react-final-form';
import { AUTH_LOGIN, useDataProvider, useNotify } from 'react-admin';
import { FA_INVESTMENT_ADVICE, LOCAL_STORAGE, PASSWORD_ERROR, VERTICAL } from '../constants';
import * as analytics from '../analytics';

const {
    HAS_COMPLETED_FEES_SIGN_UP,
    BASIC_INFO,
    UNAUTHENTICATED_LEXIKTOKEN,
    UNAUTHENTICATED_PROFESSIONAL_ID,
} = LOCAL_STORAGE;

export const useRegisterUser = (resource = 'register') => {
    const notify = useNotify();
    const [payload, setPayload] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const isFeesRegister = resource === 'register/fees';

    useEffect(() => {
        if (!payload) return;

        setLoading(true);

        const handleError = errors => {
            const emailMessage = _.get(errors, 'email.0', null);
            const passwordMessage = _.get(errors, 'plainPassword.0', null);
            const attributeMessage = _.get(errors, 'attributes.0', null)

            let message = '';
            switch (true) {
                case emailMessage !== null :
                    message = emailMessage
                    break;
                case passwordMessage !== null :
                    message = passwordMessage
                    break;
                case attributeMessage !== null :
                    message = attributeMessage
                    break;
                default :
                    message = PASSWORD_ERROR.NOTIFICATION
                    break;
            }

            setErrors(errors);
            setLoading(false);
            setPayload(null);

            notify(message, 'warning');
        };

        if (payload.vertical === VERTICAL.FINANCIAL_ADVISER) {
            payload.attributes = [];

            if (payload.financial_advice === FA_INVESTMENT_ADVICE.FOCUSSED) {
                payload.attributes.push({
                    gka: FA_INVESTMENT_ADVICE.INDEPENDENT,
                    state: 5,
                });
            } else {
                payload.attributes.push({ gka: payload.financial_advice });
            }

            payload.attributes.push({ gka: payload.mortgage_advice });
        }

        dataProvider('CREATE', resource, payload)
            .then(res => {
                const errors = _.get(res, 'errors');

                if (errors) {
                    handleError(errors);
                } else {
                    if (isFeesRegister) {
                        sessionStorage.setItem(HAS_COMPLETED_FEES_SIGN_UP, 'true');

                        analytics.track('Signed up', {
                            category: 'Fee sign up page',
                        });
                    }

                    const credentials = {
                        username: payload.email,
                        password: payload.plain_password,
                    };

                    authProvider(AUTH_LOGIN, credentials).then(({ data }) => {
                        setLoading(false);

                        window.location.href = `/basic-profile/${data.id}`;
                    });
                }
            })
            .catch(() => handleError());
    }, [payload, resource, isFeesRegister, notify]);

    return [errors, loading, setPayload];
};


export const useScrollTo = (x = 0, y = 0) => {
    useEffect(() => {
        window.scrollTo(x, y);
    }, [x, y]);
};

export const useSetReviewInvitationTemplateCopy = type => {
    const dataProvider = useDataProvider();
    const form = useForm();

    useEffect(() => {
        if (Boolean(type)) {
            dataProvider
                .getOne('review-invitations/template', { type })
                .then(({ data }) => form.change('email_template', _.get(data, 'template', '')));
        }
    }, [type, dataProvider, form]);
};
