import React, { Component } from 'react';
import _ from 'lodash';
import {
    SimpleForm,
    FormDataConsumer,
    TopToolbar,
} from 'react-admin';
import FeesTieredButtonsField from './Forms/FeesTieredButtonsField';
import PreventUndoEdit from '../Components/PreventUndoEdit';
import BackButton from '../Components/Buttons/BackButton';
import InputPrefix from '../Components/Inputs/InputPrefix';
import { FormSectionTitle } from '../Components/Typography/Headlines';
import { FEE_SUB_TYPE } from '../constants';
import TrueFalseInput from '../Components/Forms/TrueFalseInput';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import FormTextInput from '../Components/Inputs/Form/FormTextInput';
import FormNumberInput from '../Components/Inputs/Form/FormNumberInput';

const { INITIAL_AND_ONGOING, HOURLY_CHARGE } = FEE_SUB_TYPE;

const styles = theme => ({
    longTextInput: {
        '& textarea': {
            minHeight: theme.spacing(15),
        },
    },
});

class FeesEdit extends Component {
    getInvestmentFormLabels = hasFinancialPlanningFees => ({
        title: hasFinancialPlanningFees
            ? 'Implementation & Ongoing Service Fees'
            : 'Implementation & Ongoing Service (without Financial Planning) Fees',
        isWealthBased:
            'How do your tier your implementation & ongoing service charges?',
        minimumFee: 'Minimum implementation & ongoing service fee',
    });

    render() {
        const { professionalId, dispatch, classes, ...rest } = this.props;

        return (
            <PreventUndoEdit
                actions={
                    <TopToolbar>
                        <BackButton href="/fees" />
                    </TopToolbar>
                }
                id="no-id"
                resource="fees-edit"
                basePath="/fees-edit"
                {...rest}
            >
                <SimpleForm redirect="/fees">
                    <FormDataConsumer>
                        {({ formData }) => {
                            return (
                                _.has(formData, ['content', 'mortgage']) && (
                                    <div>
                                        <FormSectionTitle title="Mortgage Fees" />
                                        <FormTextInput
                                            style={{ display: 'none' }}
                                            source="mortgage.id"
                                        />
                                        <FormTextInput
                                            style={{ display: 'none' }}
                                            source="content.mortgage.chargeLevels"
                                        />
                                        <FormTextInput
                                            multiline
                                            label="Mortgage fee notes"
                                            className={classes.longTextInput}
                                            source="content.mortgage.notes"
                                        />
                                        <FormNumberInput
                                            label="Minimum mortgage fee"
                                            source="content.mortgage.minimumFee"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputPrefix label="£" />
                                                ),
                                            }}
                                        />
                                    </div>
                                )
                            );
                        }}
                    </FormDataConsumer>
                    <br />
                    <FormDataConsumer>
                        {({ formData }) => {
                            if (
                                !_.has(formData, [
                                    'content',
                                    'financialPlanning',
                                ])
                            ) {
                                return null;
                            }

                            return (
                                <div>
                                    <FormSectionTitle title="Financial Planning Fees" />
                                    <FormTextInput
                                        style={{ display: 'none' }}
                                        source="financialPlanning.id"
                                    />
                                    <FormTextInput
                                        style={{ display: 'none' }}
                                        source="content.financialPlanning.subType"
                                    />
                                    {_.get(formData, [
                                        'content',
                                        'financialPlanning',
                                        'subType',
                                    ]) === INITIAL_AND_ONGOING && (
                                        <FeesTieredButtonsField
                                            source="content.financialPlanning.isWealthBased"
                                            label="Please choose which way you tier your financial planning charges"
                                        />
                                    )}
                                    <FormTextInput
                                        multiline
                                        label="Notes"
                                        source="content.financialPlanning.notes"
                                        className={classes.longTextInput}
                                    />
                                    <FormNumberInput
                                        label="Minimum financial planning fee"
                                        source="content.financialPlanning.minimumFee"
                                        InputProps={{
                                            startAdornment: (
                                                <InputPrefix label="£" />
                                            ),
                                        }}
                                    />
                                    {_.get(formData, [
                                        'content',
                                        'financialPlanning',
                                        'subType',
                                    ]) === HOURLY_CHARGE && (
                                        <FormNumberInput
                                            source="content.financialPlanning.amount"
                                            label="Your average hourly fee amount"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputPrefix label="£" />
                                                ),
                                            }}
                                        />
                                    )}
                                    <div style={{ marginTop: '24px' }}>
                                        <TrueFalseInput
                                            source="content.financialPlanning.offsetAgainstInitialFee"
                                            label="Financial planning fee can be offset against initial fee for implementation"
                                        />
                                    </div>
                                    {_.get(
                                        formData,
                                        [
                                            'content',
                                            'financialPlanning',
                                            'offsetAgainstInitialFee',
                                        ],
                                        false
                                    ) && (
                                        <Typography
                                            variant="body1"
                                            color="error"
                                        >
                                            Please note that as your fees can be
                                            offset, your fee report and insights
                                            won’t quite work yet. You can still
                                            add your fees to your profile and
                                            display them to prospective clients!
                                        </Typography>
                                    )}
                                </div>
                            );
                        }}
                    </FormDataConsumer>
                    <br />
                    <FormDataConsumer>
                        {({ formData }) => {
                            const hasInvestmentFees = _.has(formData, [
                                'content',
                                'investment',
                            ]);
                            const hasFinancialPlanningFees = _.has(formData, [
                                'content',
                                'financialPlanning',
                            ]);
                            const isInitialAndOngoing =
                                _.get(formData, [
                                    'content',
                                    'investment',
                                    'subType',
                                ]) === INITIAL_AND_ONGOING;
                            const isHourlyCharge =
                                _.get(formData, [
                                    'content',
                                    'investment',
                                    'subType',
                                ]) === HOURLY_CHARGE;
                            const formLabel = this.getInvestmentFormLabels(
                                hasFinancialPlanningFees
                            );

                            if (!hasInvestmentFees) {
                                return null;
                            }

                            return (
                                <div>
                                    <FormSectionTitle title={formLabel.title} />
                                    <FormTextInput
                                        style={{ display: 'none' }}
                                        source="investment.id"
                                    />
                                    <FormTextInput
                                        style={{ display: 'none' }}
                                        source="content.investment.subType"
                                    />
                                    {isInitialAndOngoing && (
                                        <FeesTieredButtonsField
                                            source="content.investment.isWealthBased"
                                            label={formLabel.isWealthBased}
                                        />
                                    )}
                                    <div>
                                        {!hasFinancialPlanningFees && [
                                            <FormTextInput
                                                multiline
                                                className={
                                                    classes.longTextInput
                                                }
                                                label="Notes"
                                                source="content.investment.notes"
                                                key="content.investment.notes"
                                            />,
                                            <FormNumberInput
                                                label={formLabel.minimumFee}
                                                source="content.investment.minimumFee"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputPrefix label="£" />
                                                    ),
                                                }}
                                                key="content.investment.minimumFee"
                                            />,
                                        ]}
                                    </div>
                                    <div>
                                        {isHourlyCharge && (
                                            <FormNumberInput
                                                source="content.investment.amount"
                                                label="Your average hourly fee amount"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputPrefix label="£" />
                                                    ),
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            );
                        }}
                    </FormDataConsumer>
                </SimpleForm>
            </PreventUndoEdit>
        );
    }
}

export default withStyles(styles)(FeesEdit);
