import React from 'react';
import { Toolbar, SaveButton } from 'react-admin';

const EnquiriesEditToolbar = ({ resource, ...props }) => {
    return (
        <Toolbar {...props}>
            <SaveButton
                label={resource === 'enquiry/report' ? 'Report' : 'Save'}
                submitOnEnter={true}
            />
        </Toolbar>
    );
};

export default EnquiriesEditToolbar;
