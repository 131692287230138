import React from 'react';
import {
    SimpleForm,
    FormDataConsumer,
} from 'react-admin';
import FirmLogoImage from './Components/FirmLogoImage';
import FirmDetailsDialogModal from './Components/FirmDetailsDialogModal';
import FirmPageWrapper from './FirmPageWrapper';
import TrueFalseInput from '../Components/Forms/TrueFalseInput';
import FormLongTextInput from '../Components/Inputs/Form/FormLongTextInput';
import FormTextInput from '../Components/Inputs/Form/FormTextInput';
import FormDateInput from "../Components/Inputs/Form/FormDateInput";

const ApprovedOnDate = ({ record }) => {
    if (!record.firm_config.should_show_approved_field) return null;

    return (
        <FormDateInput label="SJP Approved" source="firm_config.approved_on_date" />
    );
}

const FirmEditDetails = props => {
    const { recordValues, dispatch, ...rest } = props;
    const aboutSectionHelperText = "In order for your firm profile to appear in google search results, your about section must have at least 50 words";

    return (
        <FirmPageWrapper {...rest} variant="edit">
            <SimpleForm redirect={false} toolbar={null}>
                <br />
                <FormTextInput source="name" label="Firm name" />
                <FormLongTextInput source="about" label="Firm about" helperText={aboutSectionHelperText} />
                <ApprovedOnDate />
                    <FirmLogoImage
                        source="file"
                        record={recordValues}
                        isAdmin={true}
                    />
                <br />
                <FormDataConsumer>
                    {({ formData }) => (
                        <FirmDetailsDialogModal record={formData} />
                    )}
                </FormDataConsumer>
            </SimpleForm>
        </FirmPageWrapper>
    );
};

export default FirmEditDetails;
