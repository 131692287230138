import React from 'react';
import _ from 'lodash';
import ResetPasswordContent from './Content/ResetPasswordContent';

const ResetPassword = props => {
    const token = _.get(props, 'match.params.token');

    return (
        <ResetPasswordContent
            token={token}
            title="Reset Password"
            helperText="Please enter your new password below."
            buttonLabel="Reset Password"
        />
    );
};

export default ResetPassword;
