import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import ReviewTabs from './ReviewTabs';
import PageActions from '../../AppLayout/PageActions';
import * as analytics from '../../analytics';

import Button from '../../Components/Buttons/CustomButton';
import withStyles from '@material-ui/core/styles/withStyles';
import FirstImpressionsProspectPercentage from "../FirstImpressions/FirstImpressionsProspectPercentage";

const SHOW_BUTTON_PATHNAME_LIST = [
    '/review/previous-invites',
    '/reviews',
    '/first-impressions',
    '/review-setting',
];

const styles = theme => ({
    root: {
        paddingTop: theme.spacing(2),
    },
});

const ReviewPageHeader = ({ classes, location }) => {
    const { pathname } = location;

    const handleClick = () => {
        const lookup = {
            '/review/previous-invites': 'see who you’ve invited tab',
            '/reviews': 'my reviews tab',
            '/first-impressions': 'first impressions tab',
            '/review-setting': 'annual feedback tab',
        };

        analytics.track('Clicked invite more reviews button', {
            category: lookup[pathname],
        });
    };

    return (
        <>
            <div className={classes.header}>
                <PageActions title="Reviews">
                    {SHOW_BUTTON_PATHNAME_LIST.includes(pathname) && (
                        <Button
                            variant="contained"
                            color="tertiary"
                            component={Link}
                            to="/invite-reviews"
                            onClick={handleClick}
                        >
                            Keep Getting Reviews
                        </Button>
                    )}
                </PageActions>
            </div>
            <ReviewTabs />
            { pathname === '/first-impressions' && <FirstImpressionsProspectPercentage />}
        </>
    );
};

export default withRouter(withStyles(styles)(ReviewPageHeader));
