import React from 'react';
import {
    Datagrid,
    TextField,
    Responsive,
    SimpleList,
    DateField,
    downloadCSV,
} from 'react-admin';
import _ from 'lodash';
import { FormSectionTitle } from '../Components/Typography/Headlines';
import ReviewPageWrapper from './ReviewPageWrapper';
import ReviewFAQs from './Content/ReviewFAQs';
import { formatSimpleListDate } from '../utils';
import { SURVEY_TYPE } from '../constants/review';

import Button from '../Components/Buttons/CustomButton';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { DisabledEditViewButtons } from '../Components/Buttons/Buttons';

import { API_KEYS } from '../constants';
import { getLexikToken } from '../Helpers/provider';

import dataProvider from '../dataProvider';
import jsonExport from 'jsonexport/dist';
import moment from 'moment';

const ReviewButton = props => {
    const surveyId = _.get(props, 'record.verified_survey_id');
    const surveyType = _.get(props, 'record.survey_type');
    const surveyDisabled = _.get(props, 'record.review_disabled');

    if (surveyDisabled) {
        return <DisabledEditViewButtons {...props} />;
    }

    if (!surveyId) return null;

    return (
        <Button
            href={
                surveyType === SURVEY_TYPE.REVIEW
                    ? `/reviews/${surveyId}`
                    : `/first-impressions/${surveyId}`
            }
            color="secondary"
        >
            Read Review
        </Button>
    );
};

const exporter = async () => {
    const params = {
        pagination: { perPage: 1000 },
    };
    const { data } = await dataProvider(
        'GET_LIST',
        'review-invitations',
        params
    );

    const advisersForExport = data.map((adviser) => {

        const adviserForExport = {};

        adviserForExport.client_email = adviser.client_email;
        adviserForExport.client_first_name = adviser.client_first_name;
        adviserForExport.created_at = moment(adviser.created_at).format('DD/MM/YYYY');
        adviserForExport.reviewState = adviser.verified_survey_id ? 'Yes' : 'No';

        return adviserForExport;
    });
    jsonExport(
        advisersForExport,
        {
            headers: [
                'client_first_name',
                'client_email',
                'created_at',
                'reviewState',
            ], // order fields in the export
            rename: [
                'Client Name',
                'Client Email',
                'Date of invitation',
                'Left review from invite',
            ], 
        },
        (err, csv) => {
            downloadCSV(csv, 'invitation-export'); // download as 'adviser-export.csv` file
        }
    );
};
const ReviewInvitationPreviousContent = props => {
    return (
        <CardContent>
                <FormSectionTitle title="See Who You've Invited To Review You Before" />

            <Button
                variant="contained"
                color="tertiary"
                onClick={exporter}
            >
                Download list of invited clients
            </Button>
            

            <Responsive
                small={
                    <SimpleList
                        {...props}
                        primaryText={record => record.client_first_name}
                        secondaryText={record => record.client_email}
                        tertiaryText={record =>
                            formatSimpleListDate(record.created_at)
                        }
                    />
                }
                medium={
                    <Datagrid {...props}>
                        <TextField
                            label="First name"
                            source="client_first_name"
                            sortable={false}
                        />
                        <TextField
                            label="Email"
                            source="client_email"
                            sortable={false}
                        />
                        <DateField
                            label="Date"
                            source="created_at"
                            sortable={false}
                        />
                        <ReviewButton />
                    </Datagrid>
                }
            />
        </CardContent>
    );
};

const ReviewInvitationPrevious = props => (
    <>
        <ReviewPageWrapper variant="list" pagination={null} {...props}>
            <ReviewInvitationPreviousContent />
        </ReviewPageWrapper>
        <ReviewFAQs />
    </>
);

export default ReviewInvitationPrevious;
