import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import dataProvider from '../dataProvider';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import AnswersTable from './components/AnswersTable';
import PageActions from '../AppLayout/PageActions';
import {useHistory, useLocation} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    container: {
        margin: 0,
        padding: 0,
    },
    dropdown: {
        marginBottom: "1rem"
    },
    title: {
        fontSize: '35px',
    },
}));

const getSelectedGoalOrDriverFromUrl = (param, type) => {
    if (type === 'goal' && param.has('goal')) {
        return param.get('goal');
    }
    if (type === 'driver' && param.has('driver')) {
        return param.get('driver');
    }
    return 0;
}

const ElevationClientAnswers = () => {

    const classes = useStyles()
    const history = useHistory();
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)

    const [goals, setGoals] = useState([])
    const [selectedGoal, setSelectedGoal] = useState(getSelectedGoalOrDriverFromUrl(queryParams, 'goal'))
    const [selectedDriver, setSelectedDriver] = useState(getSelectedGoalOrDriverFromUrl(queryParams, 'driver'));
    const [drivers, setDrivers] = useState([])

    useEffect(() => {
        dataProvider('GET_LIST', 'elevation/goals').
            then(res => {
                setGoals(res.data)
                if (queryParams.has('goal') && res.data.find(goal => goal.id == queryParams.get('goal'))) {
                    setSelectedGoal(queryParams.get('goal'))
                }
            });
    }, [])

    useEffect(() => {
        if (selectedGoal) {
            dataProvider('GET_LIST', 'elevation/drivers', { filter: { goal: selectedGoal } }).
                then(res => {
                    setDrivers(res.data)
                    if (queryParams.has('driver') && !selectedDriver && res.data.find(driver => driver.id == queryParams.get('driver'))) {
                        setSelectedDriver(queryParams.get('driver'))
                    }
                });
        }
    }, [selectedGoal])


    const handleGoalChange = e => {
        setSelectedDriver(0)
        setDrivers([])
        setSelectedGoal(e.target.value)
        queryParams.set('goal', e.target.value);
        queryParams.delete('driver');
        history.push({ search: queryParams.toString() });
    }
    const handleDriverChange = e => {
        setSelectedDriver(e.target.value)
        queryParams.set('driver', e.target.value);
        history.push({ search: queryParams.toString() });
    }

    return (
        <Card>
            <CardContent>
                <PageActions
                    title="Client Answers"
                    className={classes.title}
                    color={'textPrimary'}
                />
                <Box>
                    <Box>
                        <Select
                            className={classes.dropdown}
                            variant="outlined"
                            value={selectedGoal}
                            onChange={handleGoalChange}
                        >
                            <MenuItem
                                key={0}
                                value={0}
                            >
                                Select a goal
                            </MenuItem>
                            {goals.map(goal => (
                                <MenuItem
                                    key={goal.id}
                                    value={goal.id}
                                >
                                    {goal.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <Box>
                        <Select
                            className={classes.dropdown}
                            variant="outlined"
                            value={selectedDriver}
                            onChange={handleDriverChange}
                        >
                            <MenuItem
                                key={0}
                                value={0}
                            >
                                Choose question to look at
                            </MenuItem>
                            {drivers.map(driver => (
                                <MenuItem
                                    key={driver.id}
                                    value={driver.id}
                                >
                                    {driver.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Box>
                {selectedGoal || selectedDriver ?
                    <Grid spacing={3}>
                        <Grid item xs={12}>
                            <AnswersTable
                                goal={selectedGoal}
                                driver={selectedDriver}
                            />
                        </Grid>
                    </Grid>
                    : null}
            </CardContent>
        </Card>
    )
}


export default ElevationClientAnswers
