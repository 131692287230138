import React from 'react';
import Helmet from 'react-helmet';
import API_KEYS from './constants/api-keys';

const AppHelmet = () => {
    const getMicrosoftClarityCode = () => {
        return `
        (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);
            t.setAttribute('type', 'text/plain');t.setAttribute('data-cookiescript', 'accepted');
            t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "${API_KEYS.microsoft_clarity}");`

    }

    const getGtagCode = () => {
        return `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag("consent", "default", {
                ad_storage: "denied",
                analytics_storage: "denied",
                wait_for_update: 600000
            });
            gtag('js', new Date());
            gtag('config', "${API_KEYS.gtag_key}", {
                page_path: window.location.pathname,
            });`
    }

    return (
        <>
            <Helmet
                script={[{
                    type: 'text/javascript',
                    innerHTML: getMicrosoftClarityCode()
                }]}
            />
            <Helmet>
                <script async src={`https://www.googletagmanager.com/gtag/js?id=${API_KEYS.gtag_key}`}></script>
            </Helmet>
            <Helmet
                script={[{
                    type: 'text/javascript',
                    innerHTML: getGtagCode()
                }]}
            />
        </>
    )
}


export default AppHelmet;