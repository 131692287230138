import React from 'react';
import AppPageWrapper from '../AppLayout/AppPageWrapper';
import FirmPageHeader from './FirmPageHeader';

const FirmPageWrapper = props => {
    return (
        <AppPageWrapper
            header={<FirmPageHeader />}
            {...props}
        />
    );
};

export default FirmPageWrapper;
