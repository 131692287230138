import React from 'react';
import _ from 'lodash';
import ResetPasswordContent from './Content/ResetPasswordContent';

const OnboardingUpdatePassword = props => {
    const token = _.get(props, 'match.params.token');

    return (
        <ResetPasswordContent
            token={token}
            title="To access your account, please create a secure password below"
            buttonLabel="Set password"
        />
    );
};

export default OnboardingUpdatePassword;
