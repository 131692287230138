import React, { useState } from 'react';
import _ from 'lodash';
import { Query } from 'react-admin';

import DashboardCardBase from '../CardComponents/DashboardCardBase';
import DashboardCardTitle from '../CardComponents/DashboardCardTitle';
import DashboardCardSubTitle from '../CardComponents/DashboardCardSubTitle';
import CustomersList from '../CardComponents/CustomersList';
import ReputationActionLoading from '../CardComponents/ReputationActionLoading';
import SkeletonWrapper from '../../../Components/SkeletonWrapper';

import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/es/styles/withStyles';

const styles = theme => ({
    root: {
        height: '100%',
        '& > div': {
            height: '100%',
        },
    },
});

const DashboardListCard = ({
    resource,
    title,
    button,
    classes,
    id,
    onFinishLoading,
    renderSubTitle,
}) => {
    const [limit, setLimit] = useState(5);

    return (
        <Query type="getOne" resource={resource} payload={{ limit: limit }}>
            {({ data, loading }) => {
                const customers = _.get(data, 'customers', []);
                const total = _.get(data, 'total');
                const totalReviewsSince = _.get(data, 'totalReviewsSince');
                const percentage = Math.round((total / totalReviewsSince) * 100);

                if (Boolean(data) && !loading) {
                    onFinishLoading(total);

                    if (total === 0) return null;
                }

                return (
                    <div className={classes.root} id={id}>
                        <DashboardCardBase
                            header={
                                <>
                                    <DashboardCardTitle>{title}</DashboardCardTitle>
                                    <SkeletonWrapper loading={loading} height={45} width={'100%'}>
                                        <DashboardCardSubTitle>
                                            {renderSubTitle(percentage)}
                                        </DashboardCardSubTitle>
                                    </SkeletonWrapper>
                                </>
                            }
                            body={
                                <Grid container>
                                    <Grid item xs={12} sm={12}>
                                        {loading ? (
                                            <ReputationActionLoading />
                                        ) : (
                                            <CustomersList
                                                customers={customers}
                                                total={total}
                                                handleButtonClick={setLimit}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            }
                            footer={button}
                        />
                    </div>
                );
            }}
        </Query>
    );
};

export default withStyles(styles)(DashboardListCard);
