import React, { Fragment } from 'react';
import { SimpleForm } from 'react-admin';

import { FormSectionTitle } from '../../Components/Typography/Headlines';
import CustomerMasterGrid from './CustomerMasterGrid';
import EditButton from '../../Components/Buttons/EditButton';
import TrueFalseInput from '../../Components/Forms/TrueFalseInput';
import ReviewAnnualFeedbackSwitchInput from './ReviewAnnualFeedbackSwitchInput';
import ReviewPageWrapper from '../ReviewPageWrapper';

import Typography from '@material-ui/core/Typography';

export const ReviewSettingEdit = (props) => {
    return (
        <ReviewPageWrapper variant="edit" {...props} id="no-id">
            <SimpleForm toolbar={null} basePath="/review-setting">
                <AnnualAutoInvitationSettings />
                <>
                    <FormSectionTitle title="Your previous reviewers:" />
                    <Typography color="error">
                        Click on a row to edit the next invite date, or if you wish to turn off the invite simply untick the box called 'Annual auto invite'.
                    </Typography>
                    <Typography color="error">
                        Please note: changing the email address against the invite isn't possible at the moment. Please invite your client again, using their new email address through the 'Invite Reviews' page.
                    </Typography>
                </>
                <CustomerMasterGrid />
                <EditButton
                    color="secondary"
                    label="PREVIEW/EDIT INVITATION MESSAGE"
                    href="/review-setting/template"
                />
            </SimpleForm>
        </ReviewPageWrapper>
    );
};

const AnnualAutoInvitationSettings = () => {
    return (
        <Fragment>
            <FormSectionTitle title="Annual auto invitation settings:" />
            <TrueFalseInput
                source="auto_invite"
                label="Turn on annual feedback invitations for all previous and future reviewers (you can exclude clients individually below!)"
                component={ReviewAnnualFeedbackSwitchInput}
            />
        </Fragment>
    );
};
