import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import ReputationToolCard from '../Card/ReputationToolCard';
import { connect } from 'react-redux';
import { getLexikToken } from '../../Helpers/provider';
import { getMonth } from '../../utils';
import {API_KEYS, REP_TOOL_REVIEW_PDF, REP_TOOL_REVIEW_PDF_VF_2023} from '../../constants';
import * as analytics from '../../analytics';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useQuery } from 'react-admin';

import Button from '../../Components/Buttons/CustomButton';

const ProfessionalPdfReportWithDownloadOptionsFeature = ({ isTopRated, showNewRepTool }) => {
    const { data, loading } = useQuery({
        type: 'getOne',
        resource: 'reputation/card/pdf-report',
    });

    const menuItems = useQuery({
        type: 'getOne',
        resource: 'reputation/card/pdf-report-dropdown-options',
    });
    
    const [selectedRT, setSelectedRT] = useState('all');

    useEffect(() => {
        if (menuItems?.data) {
            let availableOptions = []
            Object.values(menuItems.data).map(menuItem => {
                if (typeof menuItem === 'object' && 'value' in menuItem) {
                    availableOptions = [...availableOptions, menuItem.value]
                }
            }) 
            if (availableOptions.length && !availableOptions.includes('all')) setSelectedRT(availableOptions[0])     
        }
    }, [menuItems])

    const onClick = () => {
        window.location.href = `${
            API_KEYS.api_url
        }/reviews/export/pdf?reviewtype=${selectedRT}&lexiktoken=${getLexikToken()}`;

        let action;
        switch (selectedRT) {
            case 'all':
                action = 'Review and First Impressions';
                break;
            default:
                action = 'Reviews only';
        }

        analytics.track(action, {
            category: isTopRated ? 'Download Top Rated Report' : 'Download Reviews',
            label: getMonth(),
        });
    };

    const onSelectChange = ({ target }) => {
        setSelectedRT(target.value);
    };

    if (!menuItems.data) return false;

    return (
        <ReputationToolCard
            disabled={loading}
            title={_.get(data, 'title', '')}
            subTitle={_.get(data, 'subtitle', '')}
            image={showNewRepTool ? REP_TOOL_REVIEW_PDF_VF_2023 : REP_TOOL_REVIEW_PDF}
            actions={
                <Button variant="contained" color="tertiary" onClick={onClick}>
                    Download
                </Button>
            }
        >
            <FormControl variant="outlined" >
                <InputLabel htmlFor="certificateExcellencePFD" >
                    Choose version of certificate
                </InputLabel>
                <Select
                    variant="outlined"
                    label="Choose version of certificate"
                    value={selectedRT}
                    onChange={onSelectChange}
                    inputProps={{
                        name: 'certificateExcellencePDF',
                        id: 'certificateExcellencePDF',
                    }}
                >
                    {
                        Object.values(menuItems.data).map(menuItem => (
                            <MenuItem
                                key={menuItem.value}
                                value={menuItem.value}
                            >
                                {menuItem.text}
                            </MenuItem>
                        )) 
                    }
                </Select>
            </FormControl>
        </ReputationToolCard>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        isTopRated: _.get(basicInfo, ['permissions', 'is_top_rated'], false),
    };
};

export default connect(mapStateToProps)(ProfessionalPdfReportWithDownloadOptionsFeature);
