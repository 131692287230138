import React from 'react';
import _ from 'lodash';
import { EditButton } from 'react-admin';
import ErrorButton from './ErrorButton';
import Button from '../Buttons/CustomButton';
import withStyles from '@material-ui/core/styles/withStyles';
import { SaveButton } from 'react-admin';
import ContentTooltip from '../ConsumerReactComponents/ContentTooltip';
import { ExternalLink } from '../Links';
import { CONTACT } from '../../constants';

const styles = theme => ({
    callToActionBtn: {
        height: theme.spacing(6),
        width: theme.spacing(40),
        fontSize: theme.spacing(2),
    },
});
const tooltipStyles = theme => ({
    toolTip: {
        background: 'white',
        border: `1px solid ${theme.palette.tertiary.dark}`,
        borderRadius: '1%',
        width: 190,
    },
    questionIcon: {
        position: 'relative',
        bottom: theme.spacing(-1),
        left: theme.spacing(2),
        width: 20,
        height: 20,
        color: `${theme.palette.tertiary.dark}`,
        cursor: 'pointer',
    },
});

export const DeleteReplyButton = ({ record, ...props }) => (
    <div>
        {record && record[props.recordProperty] && (
            <ErrorButton data-test="delete-reply-button" onClick={() => props.clickHandler(record.id)}>Delete reply</ErrorButton>
        )}
    </div>
);

export const DisabledEditViewButtons = withStyles(tooltipStyles)(props => {
    const { classes } = props;
    const calendlyBookingUrl = _.get(props, 'record.calendly_induction_call_booking_url');

    return (
        <>
            <EditButton disabled label="View / Reply" />
            <ContentTooltip
                title={
                    calendlyBookingUrl ? (
                        <div>
                            Your reviews will be published once your account has been
                            activated.&nbsp;
                            <span>
                                <ExternalLink href={calendlyBookingUrl}>
                                    Book in your activation call
                                </ExternalLink>
                            </span>
                            &nbsp;with our team to activate your profile.
                        </div>
                    ) : (
                        <div>
                            Your reviews will be published once your account has been activated. To
                            activate your account, book a call on {CONTACT.PHONE_NUMBER}.
                        </div>
                    )
                }
                placement="top"
                arrow
                interactive
                classes={{
                    questionIcon: classes.questionIcon,
                    toolTip: classes.toolTip,
                }}
            />
        </>
    );
});

export const EditViewButtons = props => {
    const { ...rest } = props;
    return <EditButton color="secondary" {...rest} label="View / Reply" />;
};

export const ShareViewButton = props => {
    const { label, ...rest } = props;
    return <EditButton color="secondary" {...rest} label={label} />;
};

export const CallToActionButton = withStyles(styles)(props => {
    const {
        classes,
        href,
        onClickAction,
        disabled,
        variant = 'contained',
        type = 'button',
        color = 'tertiary',
        children,
        ...rest
    } = props;

    if (type === 'save') {
        return <SaveButton {...rest} label={children} className={classes.callToActionBtn} />;
    } else {
        return (
            <Button
                variant={variant}
                color={color}
                onClick={onClickAction}
                disabled={disabled}
                className={classes.callToActionBtn}
                href={href}
                component="a"
                target={href && '_blank'}
            >
                {children}
            </Button>
        );
    }
});
