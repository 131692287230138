import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const DashboardIcon = props => (
    <SvgIcon {...props}>
        <g id="Header_x2F_BG" display="none">
            <rect
                x="-314"
                y="-446"
                display="inline"
                fill="#F1F1F2"
                width="520"
                height="520"
            />
        </g>
        <g id="Bounding_Boxes">
            <g id="ui_x5F_spec_x5F_header_copy_3"></g>
            <path fill="none" d="M0,0h24v24H0V0z" />
        </g>
        <g id="Rounded" display="none">
            <g id="ui_x5F_spec_x5F_header_copy_5" display="inline"></g>
            <path
                display="inline"
                d="M10,19v-5h4v5c0,0.55,0.45,1,1,1h3c0.55,0,1-0.45,1-1v-7h1.7c0.46,0,0.68-0.57,0.33-0.87L12.67,3.6 c-0.38-0.34-0.96-0.34-1.34,0l-8.36,7.53C2.63,11.43,2.84,12,3.3,12H5v7c0,0.55,0.45,1,1,1h3C9.55,20,10,19.55,10,19z"
            />
        </g>
        <g id="Sharp" display="none">
            <g id="ui_x5F_spec_x5F_header_copy_4" display="inline"></g>
            <path display="inline" d="M10,20v-6h4v6h5v-8h3L12,3L2,12h3v8H10z" />
        </g>
        <g id="Outline">
            <g id="ui_x5F_spec_x5F_header"></g>
            <path
                id="XMLID_1_"
                d="M12,5.69l5,4.5V12v6h-2v-4v-2h-2h-2H9v2v4H7v-6v-1.81L12,5.69 M12,3L2,12h3v8h6v-6h2v6h6v-8h3L12,3L12,3z"
            />
        </g>
        <g id="Duotone" display="none">
            <g id="ui_x5F_spec_x5F_header_copy_2" display="inline"></g>
            <g display="inline">
                <path d="M12,3L2,12h3v8h6v-6h2v6h6v-8h3L12,3z M17,18h-2v-6H9v6H7v-7.81l5-4.5l5,4.5V18z" />
                <polygon
                    opacity="0.3"
                    points="7,10.19 7,18 9,18 9,12 15,12 15,18 17,18 17,10.19 12,5.69 		"
                />
            </g>
        </g>
        <g id="Fill" display="none">
            <g id="ui_x5F_spec_x5F_header_copy" display="inline"></g>
            <path display="inline" d="M10,20v-6h4v6h5v-8h3L12,3L2,12h3v8H10z" />
        </g>
        <g id="nyt_x5F_exporter_x5F_info" display="none"></g>
    </SvgIcon>
);

export const ProfileIcon = props => (
    <SvgIcon {...props}>
        <g id="Bounding_Boxes">
            <g id="ui_x5F_spec_x5F_header_copy_3" display="none"></g>
            <path fill="none" d="M0,0h24v24H0V0z" />
        </g>
        <g id="Rounded" display="none">
            <g id="ui_x5F_spec_x5F_header_copy_5"></g>
            <g display="inline">
                <path
                    d="M12,12c2.21,0,4-1.79,4-4c0-2.21-1.79-4-4-4C9.79,4,8,5.79,8,8C8,10.21,9.79,12,12,12z M12,6c1.1,0,2,0.9,2,2s-0.9,2-2,2
          c-1.1,0-2-0.9-2-2S10.9,6,12,6z"
                />
                <path
                    d="M12,13c-2.67,0-8,1.34-8,4v2c0,0.55,0.45,1,1,1h14c0.55,0,1-0.45,1-1v-2C20,14.34,14.67,13,12,13z M18,18H6l0-0.99
          C6.2,16.29,9.3,15,12,15s5.8,1.29,6,2V18z"
                />
            </g>
        </g>
        <g id="Sharp" display="none">
            <g id="ui_x5F_spec_x5F_header_copy_4"></g>
            <g display="inline">
                <path
                    d="M12,12c2.21,0,4-1.79,4-4c0-2.21-1.79-4-4-4C9.79,4,8,5.79,8,8C8,10.21,9.79,12,12,12z M12,6c1.1,0,2,0.9,2,2s-0.9,2-2,2
          c-1.1,0-2-0.9-2-2S10.9,6,12,6z"
                />
                <path d="M12,13c-2.67,0-8,1.34-8,4v3h16v-3C20,14.34,14.67,13,12,13z M18,18H6l0-0.99C6.2,16.29,9.3,15,12,15s5.8,1.29,6,2V18z" />
            </g>
        </g>
        <g id="Outline">
            <g id="ui_x5F_spec_x5F_header" display="none"></g>
            <path
                d="M12,6c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S10.9,6,12,6 M12,15c2.7,0,5.8,1.29,6,2v1H6l0-0.99C6.2,16.29,9.3,15,12,15
        M12,4C9.79,4,8,5.79,8,8s1.79,4,4,4s4-1.79,4-4S14.21,4,12,4L12,4z M12,13c-2.67,0-8,1.34-8,4v3h16v-3C20,14.34,14.67,13,12,13
        L12,13z"
            />
        </g>
        <g id="Duotone" display="none">
            <g id="ui_x5F_spec_x5F_header_copy_2"></g>
            <g display="inline">
                <circle opacity="0.3" cx="12" cy="8" r="2" />
                <path
                    opacity="0.3"
                    d="M12,15c-2.7,0-5.8,1.29-6,2.01L6,18h12v-1C17.8,16.29,14.7,15,12,15z"
                />
                <path
                    d="M12,12c2.21,0,4-1.79,4-4c0-2.21-1.79-4-4-4C9.79,4,8,5.79,8,8C8,10.21,9.79,12,12,12z M12,6c1.1,0,2,0.9,2,2s-0.9,2-2,2
          c-1.1,0-2-0.9-2-2S10.9,6,12,6z"
                />
                <path d="M12,13c-2.67,0-8,1.34-8,4v3h16v-3C20,14.34,14.67,13,12,13z M18,18H6l0-0.99C6.2,16.29,9.3,15,12,15s5.8,1.29,6,2V18z" />
            </g>
        </g>
        <g id="Material" display="none">
            <g id="ui_x5F_spec_x5F_header_copy"></g>
            <g display="inline">
                <path
                    d="M12,12c2.21,0,4-1.79,4-4c0-2.21-1.79-4-4-4C9.79,4,8,5.79,8,8C8,10.21,9.79,12,12,12z M12,6c1.1,0,2,0.9,2,2s-0.9,2-2,2
          c-1.1,0-2-0.9-2-2S10.9,6,12,6z"
                />
                <path d="M12,13c-2.67,0-8,1.34-8,4v3h16v-3C20,14.34,14.67,13,12,13z M18,18H6l0-0.99C6.2,16.29,9.3,15,12,15s5.8,1.29,6,2V18z" />
            </g>
        </g>
    </SvgIcon>
);

export const FirmIcon = props => (
    <SvgIcon {...props}>
        <g id="Bounding_Boxes">
            <g id="ui_x5F_spec_x5F_header_copy_3"></g>
            <path fill="none" d="M0,0h24v24H0V0z" />
        </g>
        <g id="Outline">
            <g id="ui_x5F_spec_x5F_header"></g>
            <path
                d="M14,6V4h-4v2H14z M4,8v11h16V8H4z M20,6c1.11,0,2,0.89,2,2v11c0,1.11-0.89,2-2,2H4c-1.11,0-2-0.89-2-2L2.01,8
        c0-1.11,0.88-2,1.99-2h4V4c0-1.11,0.89-2,2-2h4c1.11,0,2,0.89,2,2v2H20z"
            />
        </g>
    </SvgIcon>
);

export const ReputationToolsIcon = props => (
    <SvgIcon {...props}>
        <g id="Header_x2F_BG" display="none">
            <rect
                x="-50"
                y="-446"
                display="inline"
                fill="#F1F1F2"
                width="520"
                height="520"
            />
        </g>
        <g id="Bounding_Boxes">
            <g id="ui_x5F_spec_x5F_header_copy_3"></g>
            <path fill="none" d="M0,0h24v24H0V0z" />
        </g>
        <g id="Rounded" display="none">
            <g id="ui_x5F_spec_x5F_header_copy_5" display="inline"></g>
            <path
                display="inline"
                d="M12,17.27l5.17,3.12c0.38,0.23,0.85-0.11,0.75-0.54l-1.37-5.88l4.56-3.95c0.33-0.29,0.16-0.84-0.29-0.88
        l-6.01-0.51l-2.35-5.54c-0.17-0.41-0.75-0.41-0.92,0L9.19,8.63L3.18,9.14C2.74,9.18,2.56,9.73,2.9,10.02l4.56,3.95l-1.37,5.88
        c-0.1,0.43,0.37,0.77,0.75,0.54L12,17.27z"
            />
        </g>
        <g id="Sharp" display="none">
            <g id="ui_x5F_spec_x5F_header_copy_4" display="inline"></g>
            <path
                display="inline"
                d="M12,17.27L18.18,21l-1.64-7.03L22,9.24l-7.19-0.61L12,2L9.19,8.63L2,9.24l5.46,4.73L5.82,21L12,17.27z"
            />
        </g>
        <g id="Outline">
            <g id="ui_x5F_spec_x5F_header"></g>
            <path
                id="XMLID_583_"
                d="M12,7.13l0.97,2.29l0.47,1.11l1.2,0.1l2.47,0.21l-1.88,1.63l-0.91,0.79l0.27,1.18l0.56,2.41l-2.12-1.28
        L12,14.93l-1.03,0.62l-2.12,1.28l0.56-2.41l0.27-1.18l-0.91-0.79l-1.88-1.63l2.47-0.21l1.2-0.1l0.47-1.11L12,7.13 M12,2L9.19,8.63
        L2,9.24l5.46,4.73L5.82,21L12,17.27L18.18,21l-1.64-7.03L22,9.24l-7.19-0.61L12,2L12,2z"
            />
        </g>
        <g id="Duotone" display="none">
            <g id="ui_x5F_spec_x5F_header_copy_2" display="inline"></g>
            <g display="inline">
                <polygon
                    opacity="0.3"
                    points="17.11,10.83 14.64,10.62 13.44,10.52 12.97,9.41 12,7.13 11.03,9.41 10.56,10.52 9.36,10.62
          6.89,10.83 8.77,12.46 9.68,13.25 9.41,14.42 8.84,16.84 10.97,15.56 12,14.93 13.03,15.56 15.16,16.84 14.59,14.42 14.32,13.25
          15.23,12.46 		"
                />
                <path
                    d="M22,9.24l-7.19-0.61L12,2L9.19,8.63L2,9.24l5.46,4.73L5.82,21L12,17.27L18.18,21l-1.64-7.03L22,9.24z M14.59,14.42
          l0.56,2.41l-2.12-1.28L12,14.93l-1.03,0.62l-2.12,1.28l0.56-2.41l0.27-1.18l-0.91-0.79l-1.88-1.63l2.47-0.21l1.2-0.1l0.47-1.11
          L12,7.13l0.97,2.29l0.47,1.11l1.2,0.1l2.47,0.21l-1.88,1.63l-0.91,0.79L14.59,14.42z"
                />
            </g>
        </g>
        <g id="Fill" display="none">
            <g id="ui_x5F_spec_x5F_header_copy" display="inline"></g>
            <path
                display="inline"
                d="M12,17.27L18.18,21l-1.64-7.03L22,9.24l-7.19-0.61L12,2L9.19,8.63L2,9.24l5.46,4.73L5.82,21L12,17.27z"
            />
        </g>
        <g id="nyt_x5F_exporter_x5F_info" display="none"></g>
    </SvgIcon>
);

export const ReviewsIcon = props => (
    <SvgIcon {...props}>
        <g id="Bounding_Boxes">
            <path fill="none" d="M0,0h24v24H0V0z" />
        </g>
        <g id="Outline">
            <g id="ui_x5F_spec_x5F_header"></g>
            <g>
                <path
                    d="M20,2H4C2.9,2,2.01,2.9,2.01,4L2,22l4-4h14c1.1,0,2-0.9,2-2V4C22,2.9,21.1,2,20,2z M20,16H6H5.17l-0.59,0.59L4,17.17V4h16
          V16z"
                />
                <polygon points="10.5,14 18,14 18,12 12.5,12 		" />
                <path d="M14.36,8.13c0.2-0.2,0.2-0.51,0-0.71l-1.77-1.77c-0.2-0.2-0.51-0.2-0.71,0L6,11.53V14h2.47L14.36,8.13z" />
            </g>
        </g>
    </SvgIcon>
);

export const EnquiriesIcon = props => (
    <SvgIcon {...props}>
        <g id="Bounding_Boxes">
            <g id="ui_x5F_spec_x5F_header_copy_3" display="none"></g>
            <path fill="none" d="M0,0h24v24H0V0z" />
            <path opacity="0.87" fill="none" d="M0,0h24v24H0V0z" />
        </g>
        <g id="Rounded" display="none">
            <g id="ui_x5F_spec_x5F_header_copy_5"></g>
            <path
                display="inline"
                d="M4,13L4,13c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1h0c-0.55,0-1,0.45-1,1v0C3,12.55,3.45,13,4,13z M4,17
        L4,17c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1h0c-0.55,0-1,0.45-1,1v0C3,16.55,3.45,17,4,17z M4,9L4,9c0.55,0,1-0.45,1-1v0
        c0-0.55-0.45-1-1-1h0C3.45,7,3,7.45,3,8v0C3,8.55,3.45,9,4,9z M8,13h12c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H8
        c-0.55,0-1,0.45-1,1v0C7,12.55,7.45,13,8,13z M8,17h12c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H8c-0.55,0-1,0.45-1,1v0
        C7,16.55,7.45,17,8,17z M7,8L7,8c0,0.55,0.45,1,1,1h12c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H8C7.45,7,7,7.45,7,8z"
            />
            <path
                display="inline"
                d="M4,13L4,13c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1h0c-0.55,0-1,0.45-1,1v0C3,12.55,3.45,13,4,13z M4,17
        L4,17c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1h0c-0.55,0-1,0.45-1,1v0C3,16.55,3.45,17,4,17z M4,9L4,9c0.55,0,1-0.45,1-1v0
        c0-0.55-0.45-1-1-1h0C3.45,7,3,7.45,3,8v0C3,8.55,3.45,9,4,9z M8,13h12c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H8
        c-0.55,0-1,0.45-1,1v0C7,12.55,7.45,13,8,13z M8,17h12c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H8c-0.55,0-1,0.45-1,1v0
        C7,16.55,7.45,17,8,17z M7,8L7,8c0,0.55,0.45,1,1,1h12c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H8C7.45,7,7,7.45,7,8z"
            />
        </g>
        <g id="Sharp" display="none">
            <g id="ui_x5F_spec_x5F_header_copy_4"></g>
            <path
                display="inline"
                d="M3,13h2v-2H3V13z M3,17h2v-2H3V17z M3,9h2V7H3V9z M7,13h14v-2H7V13z M7,17h14v-2H7V17z M7,7v2h14V7H7z"
            />
            <path
                display="inline"
                d="M3,13h2v-2H3V13z M3,17h2v-2H3V17z M3,9h2V7H3V9z M7,13h14v-2H7V13z M7,17h14v-2H7V17z M7,7v2h14V7H7z"
            />
        </g>
        <g id="Outline">
            <g id="ui_x5F_spec_x5F_header" display="none"></g>
            <path d="M3,13h2v-2H3V13z M3,17h2v-2H3V17z M3,9h2V7H3V9z M7,13h14v-2H7V13z M7,17h14v-2H7V17z M7,7v2h14V7H7z" />
            <path d="M3,13h2v-2H3V13z M3,17h2v-2H3V17z M3,9h2V7H3V9z M7,13h14v-2H7V13z M7,17h14v-2H7V17z M7,7v2h14V7H7z" />
        </g>
        <g id="Duotone" display="none">
            <g id="ui_x5F_spec_x5F_header_copy_2"></g>
            <path
                display="inline"
                d="M3,13h2v-2H3V13z M3,17h2v-2H3V17z M3,9h2V7H3V9z M7,13h14v-2H7V13z M7,17h14v-2H7V17z M7,7v2h14V7H7z"
            />
            <path
                display="inline"
                d="M3,13h2v-2H3V13z M3,17h2v-2H3V17z M3,9h2V7H3V9z M7,13h14v-2H7V13z M7,17h14v-2H7V17z M7,7v2h14V7H7z"
            />
        </g>
        <g id="Material" display="none">
            <g id="ui_x5F_spec_x5F_header_copy"></g>
            <path
                display="inline"
                d="M3,13h2v-2H3V13z M3,17h2v-2H3V17z M3,9h2V7H3V9z M7,13h14v-2H7V13z M7,17h14v-2H7V17z M7,7v2h14V7H7z"
            />
        </g>
    </SvgIcon>
);

export const PensionAdviceVouchersIcon = props => (
    <SvgIcon {...props}>
        <g id="Bounding_Boxes">
            <g id="ui_x5F_spec_x5F_header_copy_3" display="none"></g>
            <path fill="none" d="M0,0h24v24H0V0z" />
        </g>
        <g id="Rounded" display="none">
            <g id="ui_x5F_spec_x5F_header_copy_5"></g>
            <path
                display="inline"
                d="M17.63,5.84C17.27,5.33,16.67,5,16,5L5,5.01C3.9,5.01,3,5.9,3,7v10c0,1.1,0.9,1.99,2,1.99L16,19
        c0.67,0,1.27-0.33,1.63-0.84l3.96-5.58c0.25-0.35,0.25-0.81,0-1.16L17.63,5.84z"
            />
        </g>
        <g id="Sharp" display="none">
            <g id="ui_x5F_spec_x5F_header_copy_4"></g>
            <path
                display="inline"
                d="M17.03,5L3,5.01v13.98L17.03,19L22,12L17.03,5z"
            />
        </g>
        <g id="Outline">
            <g id="ui_x5F_spec_x5F_header" display="none"></g>
            <path
                d="M17.63,5.84C17.27,5.33,16.67,5,16,5L5,5.01C3.9,5.01,3,5.9,3,7v10c0,1.1,0.9,1.99,2,1.99L16,19
        c0.67,0,1.27-0.33,1.63-0.84L22,12L17.63,5.84z M16,17H5V7h11l3.55,5L16,17z"
            />
        </g>
        <g id="Duotone" display="none">
            <g id="ui_x5F_spec_x5F_header_copy_2"></g>
            <g display="inline">
                <polygon opacity="0.3" points="16,7 5,7 5,17 16,17 19.55,12 		" />
                <path
                    d="M17.63,5.84C17.27,5.33,16.67,5,16,5L5,5.01C3.9,5.01,3,5.9,3,7v10c0,1.1,0.9,1.99,2,1.99L16,19
          c0.67,0,1.27-0.33,1.63-0.84L22,12L17.63,5.84z M16,17H5V7h11l3.55,5L16,17z"
                />
            </g>
        </g>
        <g id="Material" display="none">
            <g id="ui_x5F_spec_x5F_header_copy"></g>
            <path
                display="inline"
                d="M17.63,5.84C17.27,5.33,16.67,5,16,5L5,5.01C3.9,5.01,3,5.9,3,7v10c0,1.1,0.9,1.99,2,1.99L16,19
        c0.67,0,1.27-0.33,1.63-0.84L22,12L17.63,5.84z"
            />
        </g>
    </SvgIcon>
);

export const SettingsBillingIcon = props => (
    <SvgIcon {...props}>
        <g id="Bounding_Boxes">
            <g id="ui_x5F_spec_x5F_header_copy_3" display="none"></g>
            <path fill="none" d="M0,0h24v24H0V0z" />
        </g>
        <g id="Rounded" display="none">
            <g id="ui_x5F_spec_x5F_header_copy_5"></g>
            <path
                display="inline"
                d="M19.43,12.98c0.04-0.32,0.07-0.64,0.07-0.98s-0.03-0.66-0.07-0.98l2.11-1.65
        c0.19-0.15,0.24-0.42,0.12-0.64l-2-3.46c-0.12-0.22-0.39-0.3-0.61-0.22l-2.49,1c-0.52-0.4-1.08-0.73-1.69-0.98l-0.38-2.65
        C14.46,2.18,14.25,2,14,2h-4C9.75,2,9.54,2.18,9.51,2.42L9.13,5.07C8.52,5.32,7.96,5.66,7.44,6.05l-2.49-1
        c-0.23-0.09-0.49,0-0.61,0.22l-2,3.46C2.21,8.95,2.27,9.22,2.46,9.37l2.11,1.65C4.53,11.34,4.5,11.67,4.5,12s0.03,0.66,0.07,0.98
        l-2.11,1.65c-0.19,0.15-0.24,0.42-0.12,0.64l2,3.46c0.12,0.22,0.39,0.3,0.61,0.22l2.49-1c0.52,0.4,1.08,0.73,1.69,0.98l0.38,2.65
        C9.54,21.82,9.75,22,10,22h4c0.25,0,0.46-0.18,0.49-0.42l0.38-2.65c0.61-0.25,1.17-0.59,1.69-0.98l2.49,1
        c0.23,0.09,0.49,0,0.61-0.22l2-3.46c0.12-0.22,0.07-0.49-0.12-0.64L19.43,12.98z M12,15.5c-1.93,0-3.5-1.57-3.5-3.5
        s1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5S13.93,15.5,12,15.5z"
            />
        </g>
        <g id="Sharp" display="none">
            <g id="ui_x5F_spec_x5F_header_copy_4"></g>
            <path
                display="inline"
                d="M19.44,12.99l-0.01,0.02c0.04-0.33,0.08-0.67,0.08-1.01c0-0.34-0.03-0.66-0.07-0.99l0.01,0.02l2.44-1.92
        l-2.43-4.22l-2.87,1.16l0.01,0.01c-0.52-0.4-1.09-0.74-1.71-1h0.01L14.44,2H9.57L9.13,5.07h0.01c-0.62,0.26-1.19,0.6-1.71,1
        l0.01-0.01L4.56,4.89L2.12,9.11l2.44,1.92l0.01-0.02C4.53,11.34,4.5,11.66,4.5,12c0,0.34,0.03,0.68,0.08,1.01l-0.01-0.02l-2.1,1.65
        c-0.19,0.15-0.33,0.26-0.33,0.26l2.43,4.2l2.88-1.15l-0.02-0.04c0.53,0.41,1.1,0.75,1.73,1.01H9.13L9.58,22h4.85
        c0,0,0.03-0.18,0.06-0.42l0.38-2.65h-0.01c0.62-0.26,1.2-0.6,1.73-1.01l-0.02,0.04l2.88,1.15l2.43-4.2c0,0-0.14-0.12-0.33-0.26
        L19.44,12.99z M12,15.5c-1.93,0-3.5-1.57-3.5-3.5s1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5S13.93,15.5,12,15.5z"
            />
        </g>
        <g id="Outline">
            <g id="ui_x5F_spec_x5F_header" display="none"></g>
            <g>
                <path
                    d="M19.43,12.98c0.04-0.32,0.07-0.64,0.07-0.98c0-0.34-0.03-0.66-0.07-0.98l2.11-1.65c0.19-0.15,0.24-0.42,0.12-0.64l-2-3.46
          c-0.09-0.16-0.26-0.25-0.44-0.25c-0.06,0-0.12,0.01-0.17,0.03l-2.49,1c-0.52-0.4-1.08-0.73-1.69-0.98l-0.38-2.65
          C14.46,2.18,14.25,2,14,2h-4C9.75,2,9.54,2.18,9.51,2.42L9.13,5.07C8.52,5.32,7.96,5.66,7.44,6.05l-2.49-1
          C4.89,5.03,4.83,5.02,4.77,5.02c-0.17,0-0.34,0.09-0.43,0.25l-2,3.46C2.21,8.95,2.27,9.22,2.46,9.37l2.11,1.65
          C4.53,11.34,4.5,11.67,4.5,12c0,0.33,0.03,0.66,0.07,0.98l-2.11,1.65c-0.19,0.15-0.24,0.42-0.12,0.64l2,3.46
          c0.09,0.16,0.26,0.25,0.44,0.25c0.06,0,0.12-0.01,0.17-0.03l2.49-1c0.52,0.4,1.08,0.73,1.69,0.98l0.38,2.65
          C9.54,21.82,9.75,22,10,22h4c0.25,0,0.46-0.18,0.49-0.42l0.38-2.65c0.61-0.25,1.17-0.59,1.69-0.98l2.49,1
          c0.06,0.02,0.12,0.03,0.18,0.03c0.17,0,0.34-0.09,0.43-0.25l2-3.46c0.12-0.22,0.07-0.49-0.12-0.64L19.43,12.98z M17.45,11.27
          c0.04,0.31,0.05,0.52,0.05,0.73c0,0.21-0.02,0.43-0.05,0.73l-0.14,1.13l0.89,0.7l1.08,0.84l-0.7,1.21l-1.27-0.51l-1.04-0.42
          l-0.9,0.68c-0.43,0.32-0.84,0.56-1.25,0.73l-1.06,0.43l-0.16,1.13L12.7,20H11.3l-0.19-1.35l-0.16-1.13l-1.06-0.43
          c-0.43-0.18-0.83-0.41-1.23-0.71l-0.91-0.7l-1.06,0.43l-1.27,0.51l-0.7-1.21l1.08-0.84l0.89-0.7l-0.14-1.13
          C6.52,12.43,6.5,12.2,6.5,12s0.02-0.43,0.05-0.73l0.14-1.13L5.8,9.44L4.72,8.6l0.7-1.21l1.27,0.51l1.04,0.42l0.9-0.68
          c0.43-0.32,0.84-0.56,1.25-0.73l1.06-0.43l0.16-1.13L11.3,4h1.39l0.19,1.35l0.16,1.13l1.06,0.43c0.43,0.18,0.83,0.41,1.23,0.71
          l0.91,0.7l1.06-0.43l1.27-0.51l0.7,1.21L18.2,9.44l-0.89,0.7L17.45,11.27z"
                />
                <path
                    d="M12,8c-2.21,0-4,1.79-4,4s1.79,4,4,4s4-1.79,4-4S14.21,8,12,8z M12,14c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2
          S13.1,14,12,14z"
                />
            </g>
        </g>
        <g id="Duotone" display="none">
            <g id="ui_x5F_spec_x5F_header_copy_2"></g>
            <g display="inline">
                <path
                    opacity="0.3"
                    d="M19.28,8.6l-0.7-1.21l-1.27,0.51l-1.06,0.43l-0.91-0.7c-0.39-0.3-0.8-0.54-1.23-0.71l-1.06-0.43l-0.16-1.13
          L12.7,4H11.3l-0.19,1.35l-0.16,1.13L9.89,6.92C9.48,7.09,9.07,7.33,8.64,7.65l-0.9,0.68L6.69,7.91L5.42,7.39L4.72,8.6L5.8,9.44
          l0.89,0.7l-0.14,1.13C6.52,11.57,6.5,11.8,6.5,12s0.02,0.43,0.05,0.73l0.14,1.13l-0.89,0.7L4.72,15.4l0.7,1.21l1.27-0.51
          l1.06-0.43l0.91,0.7c0.39,0.3,0.8,0.54,1.23,0.71l1.06,0.43l0.16,1.13L11.3,20h1.39l0.19-1.35l0.16-1.13l1.06-0.43
          c0.41-0.17,0.82-0.41,1.25-0.73l0.9-0.68l1.04,0.42l1.27,0.51l0.7-1.21l-1.08-0.84l-0.89-0.7l0.14-1.13
          c0.04-0.31,0.05-0.52,0.05-0.73c0-0.21-0.02-0.43-0.05-0.73l-0.14-1.13l0.89-0.7L19.28,8.6z M12,16c-2.21,0-4-1.79-4-4s1.79-4,4-4
          s4,1.79,4,4S14.21,16,12,16z"
                />
                <path
                    d="M19.43,12.98c0.04-0.32,0.07-0.64,0.07-0.98c0-0.34-0.03-0.66-0.07-0.98l2.11-1.65c0.19-0.15,0.24-0.42,0.12-0.64l-2-3.46
          c-0.09-0.16-0.26-0.25-0.44-0.25c-0.06,0-0.12,0.01-0.17,0.03l-2.49,1c-0.52-0.4-1.08-0.73-1.69-0.98l-0.38-2.65
          C14.46,2.18,14.25,2,14,2h-4C9.75,2,9.54,2.18,9.51,2.42L9.13,5.07C8.52,5.32,7.96,5.66,7.44,6.05l-2.49-1
          C4.89,5.03,4.83,5.02,4.77,5.02c-0.17,0-0.34,0.09-0.43,0.25l-2,3.46C2.21,8.95,2.27,9.22,2.46,9.37l2.11,1.65
          C4.53,11.34,4.5,11.67,4.5,12s0.03,0.66,0.07,0.98l-2.11,1.65c-0.19,0.15-0.24,0.42-0.12,0.64l2,3.46
          c0.09,0.16,0.26,0.25,0.44,0.25c0.06,0,0.12-0.01,0.17-0.03l2.49-1c0.52,0.4,1.08,0.73,1.69,0.98l0.38,2.65
          C9.54,21.82,9.75,22,10,22h4c0.25,0,0.46-0.18,0.49-0.42l0.38-2.65c0.61-0.25,1.17-0.59,1.69-0.98l2.49,1
          c0.06,0.02,0.12,0.03,0.18,0.03c0.17,0,0.34-0.09,0.43-0.25l2-3.46c0.12-0.22,0.07-0.49-0.12-0.64L19.43,12.98z M17.45,11.27
          c0.04,0.31,0.05,0.52,0.05,0.73c0,0.21-0.02,0.43-0.05,0.73l-0.14,1.13l0.89,0.7l1.08,0.84l-0.7,1.21l-1.27-0.51l-1.04-0.42
          l-0.9,0.68c-0.43,0.32-0.84,0.56-1.25,0.73l-1.06,0.43l-0.16,1.13L12.7,20H11.3l-0.19-1.35l-0.16-1.13l-1.06-0.43
          c-0.43-0.18-0.83-0.41-1.23-0.71l-0.91-0.7l-1.06,0.43l-1.27,0.51l-0.7-1.21l1.08-0.84l0.89-0.7l-0.14-1.13
          C6.52,12.43,6.5,12.2,6.5,12s0.02-0.43,0.05-0.73l0.14-1.13L5.8,9.44L4.72,8.6l0.7-1.21l1.27,0.51l1.04,0.42l0.9-0.68
          c0.43-0.32,0.84-0.56,1.25-0.73l1.06-0.43l0.16-1.13L11.3,4h1.39l0.19,1.35l0.16,1.13l1.06,0.43c0.43,0.18,0.83,0.41,1.23,0.71
          l0.91,0.7l1.06-0.43l1.27-0.51l0.7,1.21L18.2,9.44l-0.89,0.7L17.45,11.27z"
                />
                <path
                    d="M12,8c-2.21,0-4,1.79-4,4s1.79,4,4,4s4-1.79,4-4S14.21,8,12,8z M12,14c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2
          S13.1,14,12,14z"
                />
            </g>
        </g>
        <g id="Material" display="none">
            <g id="ui_x5F_spec_x5F_header_copy"></g>
            <path
                display="inline"
                d="M19.43,12.98c0.04-0.32,0.07-0.64,0.07-0.98s-0.03-0.66-0.07-0.98l2.11-1.65
        c0.19-0.15,0.24-0.42,0.12-0.64l-2-3.46c-0.12-0.22-0.39-0.3-0.61-0.22l-2.49,1c-0.52-0.4-1.08-0.73-1.69-0.98l-0.38-2.65
        C14.46,2.18,14.25,2,14,2h-4C9.75,2,9.54,2.18,9.51,2.42L9.13,5.07C8.52,5.32,7.96,5.66,7.44,6.05l-2.49-1
        c-0.23-0.09-0.49,0-0.61,0.22l-2,3.46C2.21,8.95,2.27,9.22,2.46,9.37l2.11,1.65C4.53,11.34,4.5,11.67,4.5,12s0.03,0.66,0.07,0.98
        l-2.11,1.65c-0.19,0.15-0.24,0.42-0.12,0.64l2,3.46c0.12,0.22,0.39,0.3,0.61,0.22l2.49-1c0.52,0.4,1.08,0.73,1.69,0.98l0.38,2.65
        C9.54,21.82,9.75,22,10,22h4c0.25,0,0.46-0.18,0.49-0.42l0.38-2.65c0.61-0.25,1.17-0.59,1.69-0.98l2.49,1
        c0.23,0.09,0.49,0,0.61-0.22l2-3.46c0.12-0.22,0.07-0.49-0.12-0.64L19.43,12.98z M12,15.5c-1.93,0-3.5-1.57-3.5-3.5
        s1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5S13.93,15.5,12,15.5z"
            />
        </g>
    </SvgIcon>
);

export const LogoutIcon = props => (
    <SvgIcon {...props}>
        <g id="Bounding_Boxes">
            <g id="ui_x5F_spec_x5F_header_copy_3" display="none"></g>
            <path fill="none" d="M0,0h24v24H0V0z" />
        </g>
        <g id="Rounded" display="none">
            <g id="ui_x5F_spec_x5F_header_copy_5"></g>
            <path
                display="inline"
                d="M12,3L12,3c-0.55,0-1,0.45-1,1v8c0,0.55,0.45,1,1,1h0c0.55,0,1-0.45,1-1V4C13,3.45,12.55,3,12,3z
        M17.14,5.86L17.14,5.86c-0.39,0.39-0.38,1-0.01,1.39c1.13,1.2,1.83,2.8,1.87,4.57c0.09,3.83-3.08,7.13-6.91,7.17
        C8.18,19.05,5,15.9,5,12c0-1.84,0.71-3.51,1.87-4.76c0.37-0.39,0.37-1-0.01-1.38l0,0c-0.4-0.4-1.05-0.39-1.43,0.02
        C3.98,7.42,3.07,9.47,3,11.74c-0.14,4.88,3.83,9.1,8.71,9.25c5.1,0.16,9.29-3.93,9.29-9c0-2.37-0.92-4.51-2.42-6.11
        C18.2,5.47,17.54,5.46,17.14,5.86z"
            />
        </g>
        <g id="Sharp" display="none">
            <g id="ui_x5F_spec_x5F_header_copy_4"></g>
            <path
                display="inline"
                d="M13,3h-2v10h2V3z M17.83,5.17l-1.42,1.42C17.99,7.86,19,9.81,19,12c0,3.87-3.13,7-7,7s-7-3.13-7-7
        c0-2.19,1.01-4.14,2.58-5.42L6.17,5.17C4.23,6.82,3,9.26,3,12c0,4.97,4.03,9,9,9s9-4.03,9-9C21,9.26,19.77,6.82,17.83,5.17z"
            />
        </g>
        <g id="Outline">
            <g id="ui_x5F_spec_x5F_header" display="none"></g>
            <path
                d="M13,3h-2v10h2V3z M17.83,5.17l-1.42,1.42C17.99,7.86,19,9.81,19,12c0,3.87-3.13,7-7,7s-7-3.13-7-7
        c0-2.19,1.01-4.14,2.58-5.42L6.17,5.17C4.23,6.82,3,9.26,3,12c0,4.97,4.03,9,9,9s9-4.03,9-9C21,9.26,19.77,6.82,17.83,5.17z"
            />
        </g>
        <g id="Duotone" display="none">
            <g id="ui_x5F_spec_x5F_header_copy_2"></g>
            <path
                display="inline"
                d="M13,3h-2v10h2V3z M17.83,5.17l-1.42,1.42C17.99,7.86,19,9.81,19,12c0,3.87-3.13,7-7,7s-7-3.13-7-7
        c0-2.19,1.01-4.14,2.58-5.42L6.17,5.17C4.23,6.82,3,9.26,3,12c0,4.97,4.03,9,9,9s9-4.03,9-9C21,9.26,19.77,6.82,17.83,5.17z"
            />
        </g>
        <g id="Material" display="none">
            <g id="ui_x5F_spec_x5F_header_copy"></g>
            <path
                display="inline"
                d="M13,3h-2v10h2V3z M17.83,5.17l-1.42,1.42C17.99,7.86,19,9.81,19,12c0,3.87-3.13,7-7,7s-7-3.13-7-7
        c0-2.19,1.01-4.14,2.58-5.42L6.17,5.17C4.23,6.82,3,9.26,3,12c0,4.97,4.03,9,9,9s9-4.03,9-9C21,9.26,19.77,6.82,17.83,5.17z"
            />
        </g>
    </SvgIcon>
);

export const ContactIcon = props => (
    <SvgIcon {...props}>
        <g id="Header_x2F_BG" display="none">
            <rect
                x="-50"
                y="-270"
                display="inline"
                fill="#F1F1F2"
                width="520"
                height="520"
            />
        </g>
        <g id="Bounding_Boxes">
            <g id="ui_x5F_spec_x5F_header_copy_3"></g>
            <path fill="none" d="M0,0h24v24H0V0z" />
        </g>
        <g id="Rounded" display="none">
            <g id="ui_x5F_spec_x5F_header_copy_5" display="inline"></g>
            <path
                display="inline"
                d="M11.5,2C6.81,2,3,5.81,3,10.5S6.81,19,11.5,19H12v3c4.86-2.34,8-7,8-11.5C20,5.81,16.19,2,11.5,2z
        M12.5,16.5h-2v-2h2V16.5z M12.9,11.72c-0.01,0.01-0.02,0.03-0.03,0.05c-0.05,0.08-0.1,0.16-0.14,0.24
        c-0.02,0.03-0.03,0.07-0.04,0.11c-0.03,0.07-0.06,0.14-0.08,0.21c-0.07,0.21-0.1,0.43-0.1,0.68h-0.87h0H10.5
        c0-0.51,0.08-0.94,0.2-1.3c0-0.01,0-0.02,0.01-0.03c0.01-0.04,0.04-0.06,0.05-0.1c0.06-0.16,0.13-0.3,0.22-0.44
        c0.03-0.05,0.07-0.1,0.1-0.15c0.03-0.04,0.05-0.09,0.08-0.12l0.01,0.01c0.84-1.1,2.21-1.44,2.32-2.68
        c0.09-0.98-0.61-1.93-1.57-2.13c-1.04-0.22-1.98,0.39-2.3,1.28C9.48,7.71,9.15,8,8.74,8h-0.2C7.94,8,7.5,7.41,7.67,6.83
        c0.55-1.82,2.37-3.09,4.43-2.79c1.69,0.25,3.04,1.64,3.33,3.33C15.87,9.81,13.8,10.4,12.9,11.72z"
            />
        </g>
        <g id="Sharp" display="none">
            <g id="ui_x5F_spec_x5F_header_copy_4" display="inline"></g>
            <path
                display="inline"
                d="M11.5,2C6.81,2,3,5.81,3,10.5S6.81,19,11.5,19H12v3c4.86-2.34,8-7,8-11.5C20,5.81,16.19,2,11.5,2z
        M12.5,16.5h-2v-2h2V16.5z M12.5,13h-2c0-3.25,3-3,3-5c0-1.1-0.9-2-2-2s-2,0.9-2,2h-2c0-2.21,1.79-4,4-4s4,1.79,4,4
        C15.5,10.5,12.5,10.75,12.5,13z"
            />
        </g>
        <g id="Outline">
            <g id="ui_x5F_spec_x5F_header"></g>
            <g>
                <path
                    d="M11,23.59v-3.6c-5.01-0.26-9-4.42-9-9.49C2,5.26,6.26,1,11.5,1S21,5.26,21,10.5c0,4.95-3.44,9.93-8.57,12.4L11,23.59z
          M11.5,3C7.36,3,4,6.36,4,10.5S7.36,18,11.5,18H13v2.3c3.64-2.3,6-6.08,6-9.8C19,6.36,15.64,3,11.5,3z"
                />
                <rect x="10.5" y="14.5" width="2" height="2" />
                <path d="M12.5,13h-2c0-3.25,3-3,3-5c0-1.1-0.9-2-2-2s-2,0.9-2,2h-2c0-2.21,1.79-4,4-4s4,1.79,4,4C15.5,10.5,12.5,10.75,12.5,13z" />
            </g>
        </g>
        <g id="Duotone" display="none">
            <g id="ui_x5F_spec_x5F_header_copy_2" display="inline"></g>
            <g display="inline">
                <path
                    opacity="0.3"
                    d="M11.5,3C7.36,3,4,6.36,4,10.5S7.36,18,11.5,18H13v2.3c3.64-2.3,6-6.08,6-9.8C19,6.36,15.64,3,11.5,3z
          M12.5,16.5h-2v-2h2V16.5z M12.5,13h-2c0-3.25,3-3,3-5c0-1.1-0.9-2-2-2s-2,0.9-2,2h-2c0-2.21,1.79-4,4-4s4,1.79,4,4
          C15.5,10.5,12.5,10.75,12.5,13z"
                />
                <g>
                    <path
                        d="M11.5,1C6.26,1,2,5.26,2,10.5c0,5.07,3.99,9.23,9,9.49v3.6l1.43-0.69C17.56,20.43,21,15.45,21,10.5
            C21,5.26,16.74,1,11.5,1z M13,20.3V18h-1.5C7.36,18,4,14.64,4,10.5S7.36,3,11.5,3S19,6.36,19,10.5C19,14.23,16.64,18.01,13,20.3z
            "
                    />
                    <rect x="10.5" y="14.5" width="2" height="2" />
                    <path d="M11.5,4c-2.21,0-4,1.79-4,4h2c0-1.1,0.9-2,2-2s2,0.9,2,2c0,2-3,1.75-3,5h2c0-2.25,3-2.5,3-5C15.5,5.79,13.71,4,11.5,4z" />
                </g>
            </g>
        </g>
        <g id="Fill" display="none">
            <g id="ui_x5F_spec_x5F_header_copy" display="inline"></g>
            <path
                display="inline"
                d="M11.5,2C6.81,2,3,5.81,3,10.5S6.81,19,11.5,19H12v3c4.86-2.34,8-7,8-11.5C20,5.81,16.19,2,11.5,2z
        M12.5,16.5h-2v-2h2V16.5z M12.5,13h-2c0-3.25,3-3,3-5c0-1.1-0.9-2-2-2s-2,0.9-2,2h-2c0-2.21,1.79-4,4-4s4,1.79,4,4
        C15.5,10.5,12.5,10.75,12.5,13z"
            />
        </g>
        <g id="nyt_x5F_exporter_x5F_info" display="none"></g>
    </SvgIcon>
);

export const FacebookIcon = () => (
    <SvgIcon>
        <path
            fill="#FFFFFF"
            d="M17,2V2H17V6H15C14.31,6 14,6.81 14,7.5V10H14L17,10V14H14V22H10V14H7V10H10V6A4,4 0 0,1 14,2H17Z"
        />
    </SvgIcon>
);

export const TwitterIcon = () => (
    <SvgIcon>
        <path
            fill="#FFFFFF"
            d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,
            4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,
            10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,
            14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,
            21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z"
        />
    </SvgIcon>
);

export const LinkedInIcon = () => (
    <SvgIcon>
        <path
            fill="#FFFFFF"
            d="M21,21H17V14.25C17,13.19 15.81,12.31 14.75,12.31C13.69,12.31 13,13.19 13,14.25V21H9V9H13V11C13.66,
            9.93 15.36,9.24 16.5,9.24C19,9.24 21,11.28 21,13.75V21M7,21H3V9H7V21M5,3A2,2 0 0,1 7,5A2,2 0 0,1 5,7A2,2 0 0,1 3,5A2,2 0 0,1 5,3Z"
        />
    </SvgIcon>
);

export const FeeIcon = props => (
    <SvgIcon {...props}>
        <path d="M6,21V19C10,17 9.5,13 9.5,13H7V11H9.5C8.5,6.5 10,3 14,3C16,3 17,3.5 17,3.5V5.5C11,3.5 11,8 11.5,11H16V13H11.5C11.5,13 12,17 9.5,19H18V21H6Z" />
    </SvgIcon>
);

export const ShareIcon = () => (
    <SvgIcon>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path
                d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34
                3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12
                4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"
            />
        </svg>
    </SvgIcon>
);

export const SealBullet = ({
    width = 24,
    height = 24,
    viewBox = '0 0 24 24',
    ...rest
}) => (
    <SvgIcon {...rest}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={viewBox}
        >
            <g fill="none" fillRule="nonzero">
                <path
                    fill="#4BC1CA"
                    d="M15.915 9.281c-2.566.448-4.98 3.214-5.811 4.258L8.29 12.254a.5.5 0 0 0-.578.817l2.206 1.563a.498.498 0 0 0 .694-.115c.028-.038 2.769-3.78 5.475-4.253a.5.5 0 0 0-.172-.985z"
                />
                <path
                    fill="#4BC1CA"
                    d="M23 9.725l-.9-.835c-.22-.21-.39-.455-.505-.735l-.095-.22a2.195 2.195 0 0 1-.155-.88l.04-1.225a3.092 3.092 0 0 0-.86-2.28 3.081 3.081 0 0 0-2.24-.935l-1.34.04-.075.005a2.12 2.12 0 0 1-.805-.16l-.22-.09c-.28-.12-.525-.29-.735-.51l-.835-.9A3.081 3.081 0 0 0 9.73 1l-.84.9c-.205.22-.455.39-.735.51l-.215.09c-.26.105-.53.16-.81.16l-1.415-.045c-.855 0-1.65.33-2.235.935a3.096 3.096 0 0 0-.865 2.28l.045 1.225c.01.305-.045.6-.16.88l-.09.22c-.115.28-.29.525-.51.735l-.9.835c-.645.605-1 1.41-1 2.275s.355 1.67 1 2.27l.9.84c.22.205.395.46.51.735l.09.215c.115.285.17.58.16.88l-.045 1.23c-.03.86.28 1.67.865 2.28.585.605 1.38.935 2.24.935l1.34-.045h.07c.28 0 .55.055.81.16l.215.09c.28.12.525.29.735.51l.84.9a3.08 3.08 0 0 0 4.545 0l.835-.9c.21-.22.455-.39.735-.51l.22-.09c.255-.105.525-.16.805-.16l1.415.045c.86 0 1.655-.33 2.24-.94.585-.605.89-1.415.86-2.275l-.04-1.23c-.01-.295.045-.6.155-.875l.095-.22c.11-.275.29-.53.505-.735l.9-.84c.645-.6 1-1.405 1-2.27s-.355-1.67-1-2.275zm-11 10.22c-4.38 0-7.945-3.565-7.945-7.945 0-4.385 3.565-7.95 7.945-7.95 4.385 0 7.95 3.565 7.95 7.95 0 4.38-3.565 7.945-7.95 7.945z"
                />
            </g>
        </svg>
    </SvgIcon>
);

export const BenefitIcon = () => (
    <SealBullet style={{ fontSize: 64 }} width={64} height={64} viewBox="" />
);

export const SealBulletListComponent = ({
   width = 24,
   height = 24,
   viewBox = '0 0 24 24',
   ...rest
}) => (
    <SvgIcon {...rest}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={viewBox}
        >
            <g fill="none" fillRule="nonzero">
                <path
                    fill="#022D4F"
                    d="M15.915 9.281c-2.566.448-4.98 3.214-5.811 4.258L8.29 12.254a.5.5 0 0 0-.578.817l2.206 1.563a.498.498 0 0 0 .694-.115c.028-.038 2.769-3.78 5.475-4.253a.5.5 0 0 0-.172-.985z"
                />
                <path
                    fill="#022D4F"
                    d="M23 9.725l-.9-.835c-.22-.21-.39-.455-.505-.735l-.095-.22a2.195 2.195 0 0 1-.155-.88l.04-1.225a3.092 3.092 0 0 0-.86-2.28 3.081 3.081 0 0 0-2.24-.935l-1.34.04-.075.005a2.12 2.12 0 0 1-.805-.16l-.22-.09c-.28-.12-.525-.29-.735-.51l-.835-.9A3.081 3.081 0 0 0 9.73 1l-.84.9c-.205.22-.455.39-.735.51l-.215.09c-.26.105-.53.16-.81.16l-1.415-.045c-.855 0-1.65.33-2.235.935a3.096 3.096 0 0 0-.865 2.28l.045 1.225c.01.305-.045.6-.16.88l-.09.22c-.115.28-.29.525-.51.735l-.9.835c-.645.605-1 1.41-1 2.275s.355 1.67 1 2.27l.9.84c.22.205.395.46.51.735l.09.215c.115.285.17.58.16.88l-.045 1.23c-.03.86.28 1.67.865 2.28.585.605 1.38.935 2.24.935l1.34-.045h.07c.28 0 .55.055.81.16l.215.09c.28.12.525.29.735.51l.84.9a3.08 3.08 0 0 0 4.545 0l.835-.9c.21-.22.455-.39.735-.51l.22-.09c.255-.105.525-.16.805-.16l1.415.045c.86 0 1.655-.33 2.24-.94.585-.605.89-1.415.86-2.275l-.04-1.23c-.01-.295.045-.6.155-.875l.095-.22c.11-.275.29-.53.505-.735l.9-.84c.645-.6 1-1.405 1-2.27s-.355-1.67-1-2.275zm-11 10.22c-4.38 0-7.945-3.565-7.945-7.945 0-4.385 3.565-7.95 7.945-7.95 4.385 0 7.95 3.565 7.95 7.95 0 4.38-3.565 7.945-7.95 7.945z"
                />
            </g>
        </svg>
    </SvgIcon>
);

export const VfStar = ({ fill, width, height, className}) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 33.296 32.099"
        className={`vfStar ${className}`}
      >
        <path
          d="M225.52,19.761l7.182-5.982a1.459,1.459,0,0,0-.841-2.584l-9.249-.567a1.452,1.452,0,0,1-1.261-.92l-3.4-8.779a1.444,1.444,0,0,0-2.7,0l-3.4,8.744a1.453,1.453,0,0,1-1.261.92l-9.284.567a1.459,1.459,0,0,0-.841,2.584l7.182,5.947a1.5,1.5,0,0,1,.491,1.487l-2.348,9.1a1.447,1.447,0,0,0,2.172,1.593l7.813-5.062a1.362,1.362,0,0,1,1.541,0l7.848,5.062a1.447,1.447,0,0,0,2.172-1.593l-2.348-9.062A1.462,1.462,0,0,1,225.52,19.761Z"
          transform="translate(-199.939 0)"
          fill={fill}
        />
      </svg>
    );
};

export const VfTick = ({className, fill}) => {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"  className={`vfTick ${className}`}>
            <path className="st0" d="M12.2,0.4C9.1,3.7,7.3,7.7,6.3,10.6C5.5,9,4.4,7,3.6,5.7C3.2,5,2.3,4.9,1.6,5.3C1,5.8,0.8,6.6,1.2,7.3
                c1.6,2.5,4.2,7.8,4.2,7.9C5.7,15.7,6.2,16,6.7,16c0.1,0,0.1,0,0.2,0c0.6-0.1,1.1-0.5,1.2-1.1c0-0.1,1.4-7.5,6.2-12.4
                c0.5-0.6,0.5-1.5,0-2C14,0.1,13.6,0,13.3,0C12.9,0,12.5,0.1,12.2,0.4" fill={fill}/>
        </svg>
    );
}
