import React from 'react';
import _ from 'lodash';
import { useQuery } from 'react-admin';
import ReputationToolCard from '../Card/ReputationToolCard';
import { connect } from 'react-redux';
import { getLexikToken } from '../../Helpers/provider';
import { getMonth } from '../../utils';
import { API_KEYS, REP_TOOL_REVIEW_PDF } from '../../constants';
import * as analytics from '../../analytics';

import Button from '../../Components/Buttons/CustomButton';

const ProfessionalPdfReport = ({ isTopRated }) => {
    const { data, loading } = useQuery({
        type: 'getOne',
        resource: 'reputation/card/pdf-report',
    });

    const onClick = () => {
        window.location.href = `${
            API_KEYS.api_url
        }/reviews/export/pdf?lexiktoken=${getLexikToken()}`;

        analytics.track('Download', {
            category: isTopRated ? 'Download Top Rated Report' : 'Download Reviews',
            label: getMonth(),
        });
    };

    return (
        <ReputationToolCard
            disabled={loading}
            title={_.get(data, 'title', '')}
            subTitle={_.get(data, 'subtitle', '')}
            image={REP_TOOL_REVIEW_PDF}
            actions={
                <Button variant="contained" color="tertiary" onClick={onClick}>
                    Download
                </Button>
            }
        />
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        isTopRated: _.get(basicInfo, ['permissions', 'is_top_rated'], false),
    };
};

export default connect(mapStateToProps)(ProfessionalPdfReport);
