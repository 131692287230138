import React from 'react';
import { withRouter } from 'react-router-dom';
import { LOGO_WHITE, ROOT_DOMAIN } from '../constants';

import Button from '../Components/Buttons/CustomButton';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

import { CONTACT } from '../constants/various';

const styles = theme => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.primary.main,
        padding: `0 ${theme.spacing(3)}px`,
        height: theme.spacing(11) - 2,

        [theme.breakpoints.down('sm')]: {
            height: theme.spacing(10),
        },
    },
    logoWrapper: {
        width: theme.spacing(22),
        transition: 'all 0.2s ease',

        '&:hover': {
            opacity: 0.8,
        },
    },
    logo: {
        width: '100%',
    },
    loginWrapper: {
        display: 'flex',
        flexDirection: 'row',

        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    supportText: {
        color: theme.palette.primary.contrastText,
        marginRight: theme.spacing(2),

        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    button: {
        maxWidth: theme.spacing(19),
    },
});

const AppHeaderLoggedOut = ({ classes, ...props }) => {
    const urlPathString = props.location.pathname;

    return (
        <header className={classes.header}>
            <a className={classes.logoWrapper} href={ROOT_DOMAIN}>
                <img className={classes.logo} src={LOGO_WHITE} alt="VouchedFor" />
            </a>
            <div className={classes.loginWrapper}>
                {urlPathString.includes('/register') && (
                    <Typography className={classes.supportText}>
                        Questions? Call us on {CONTACT.PHONE_NUMBER}
                    </Typography>
                )}
                <Button
                    variant="contained"
                    color="tertiary"
                    href="/login"
                    className={classes.button}
                >
                    Log In
                </Button>
            </div>
        </header>
    );
};

export default withRouter(withStyles(styles)(AppHeaderLoggedOut));
