import React from 'react';
import _ from 'lodash';
import { Datagrid, EditButton, ListContextProvider, TextField, useQuery } from 'react-admin';
import {
    PrefixTextField,
    UnderscoreConvertTextField,
} from '../../Components/ResourcePageComponents';
import FeesChargeLevelDeleteButton from '../Components/FeesChargeLevelDeleteButton';

const MortgageFeesDataGrid = ({ feeId }) => {
    const { data, loading, error, total } = useQuery({
        type: 'getList',
        resource: 'fee-charges',
        payload: {
            filter: {
                fee: feeId,
            },
        },
    });

    if (loading || error) {
        return null;
    }

    return (
        <ListContextProvider
            value={{
                data: _.keyBy(data, 'id'),
                ids: data.map(({ id }) => id),
                total,
                page: 1,
                perPage: 50,
                setPage: () => {},
                currentSort: { field: 'id', order: 'DESC' },
                basePath: '/mortgage-fee-levels',
                resource: 'mortgage-fee-levels',
                selectedIds: [],
            }}
        >
            <Datagrid>
                <UnderscoreConvertTextField label="For levels" source="levelType" />
                <PrefixTextField label="Minimum value" source="minimumAmount" prefix="£" />
                <PrefixTextField label="Maximum value" source="maximumAmount" prefix="£" />
                <PrefixTextField label="Fee" source="paymentFee" prefix="£" />
                <TextField label="On" source="paymentTime" />
                <EditButton color="secondary" />
                <FeesChargeLevelDeleteButton />
            </Datagrid>
        </ListContextProvider>
    );
};

export default MortgageFeesDataGrid;
