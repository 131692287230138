import React from 'react';
import PropTypes from 'prop-types';
import { TopToolbar } from 'react-admin';
import { FormSectionTitle } from '../Components/Typography/Headlines';

import Grid from '@material-ui/core/Grid';

const propTypes = {
    title: PropTypes.string.isRequired,
};

const PageActions = props => {
    return (
        <TopToolbar style={{ width: '100%' }}>
            <Grid
                container
                spacing={0}
                alignItems="center"
                justify="space-between"
            >
                <FormSectionTitle {...props} />
                {props.children}
            </Grid>
        </TopToolbar>
    );
};

PageActions.propTypes = propTypes;

export default PageActions;
