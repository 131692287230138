import _ from 'lodash';
import decode from 'jwt-decode';
import dataProvider from './dataProvider';
import { parse } from 'query-string';
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import { LOCAL_STORAGE, LOGGED_OUT_AND_SIDEBAR_URLS, API_KEYS } from './constants';
import { checkCookiesAndInitMixpanel, createBasicInfo, isImpersonator } from './utils';
import mixpanel from 'mixpanel-browser/src/loaders/loader-module';
import { history } from './App';

const {
    LEXIKTOKEN,
    BASIC_INFO,
    REDIRECT_URL,
    AUTH_ERROR_MESSAGE,
    UNAUTHENTICATED_LEXIKTOKEN,
    UNAUTHENTICATED_PROFESSIONAL_ID,
} = LOCAL_STORAGE;

const hasValidLexiktoken = (lexiktoken) => {
    const lsUnauthenticatedLexiktoken = localStorage.getItem(UNAUTHENTICATED_LEXIKTOKEN);
    const currentLexiktoken = lexiktoken || lsUnauthenticatedLexiktoken;

    if (!currentLexiktoken) {
        return false;
    }

    // Dummy lexiktokens used on localhost should be valid
    if (window.location.origin.includes('localhost') && currentLexiktoken.startsWith('{')) {
        return true;
    }

    try {
        const nowUnixTimestamp = Math.round(+new Date() / 1000);
        const decodedLexiktoken = decode(currentLexiktoken);
        const { exp, expiry_date } = decodedLexiktoken;
        const expiryDate = exp || expiry_date;

        if (!expiryDate) {
            return false;
        }

        return nowUnixTimestamp < expiryDate;
    } catch {
        return false;
    }
};

const showAuthLoginErrorMessage = error => {
    const type = _.get(error, 'type');
    const email = _.get(error, 'email');

    sessionStorage.setItem(AUTH_ERROR_MESSAGE, JSON.stringify({ type, email }));
};

const logoutUser = () => {
    console.log("in logout user", "clearing local storage")
    localStorage.clear();

    try {
        //disable tracking first
        mixpanel.disable();
        //reset user
        mixpanel.reset();
    } catch (e) {
        console.log('error ', e);
    }
};

export default (type, params) => {
    const lsLexiktoken = localStorage.getItem(LEXIKTOKEN);

    // called when the user attempts to log in
    if (type === AUTH_LOGIN) {
        console.log("in auth provider", AUTH_LOGIN)
        const { username, password } = params;
        const request = new Request(API_KEYS.login_url, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });

        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }

                return response.json();
            })
            .then(async ({ token, error }) => {
                if (error) {
                    return showAuthLoginErrorMessage(error);
                }

                localStorage.setItem(LEXIKTOKEN, token);
                localStorage.removeItem(UNAUTHENTICATED_LEXIKTOKEN);
                localStorage.removeItem(UNAUTHENTICATED_PROFESSIONAL_ID);

                const basicInfo = await dataProvider('GET_ONE', 'professional/basic-info');


                const formattedBasicInfo = createBasicInfo(basicInfo);

                localStorage.setItem(BASIC_INFO, JSON.stringify(formattedBasicInfo));

                checkCookiesAndInitMixpanel();

                return formattedBasicInfo;
            });
    }

    // called when the user clicks on the logout button
    if (type === AUTH_LOGOUT) {
        console.log("in auth provider", AUTH_LOGOUT)
        logoutUser();
        return Promise.resolve();
    }

    // called when the API returns an error
    if (type === AUTH_ERROR) {
        console.log("in auth provider", AUTH_ERROR)
        const { status } = params;

        if (status === 401 || status === 403) {
            return Promise.reject("Accounts request to server: authentication failed");
        }

        return Promise.resolve();
    }

    // called when the user navigates to a new location
    if (type === AUTH_CHECK) {
        console.log("in auth provider", AUTH_CHECK)
        return hasValidLexiktoken(lsLexiktoken) ? Promise.resolve() : Promise.reject();
    }

    if (type === AUTH_GET_PERMISSIONS) {
        console.log("in auth provider", AUTH_GET_PERMISSIONS)
        if (!lsLexiktoken) {
            var q = parse(window.location.search);

            let lexiktoken = q.lexiktoken;

            if (lexiktoken) {
                localStorage.setItem(LEXIKTOKEN, lexiktoken);

                dataProvider('GET_ONE', 'professional/basic-info').then(response => {
                    localStorage.setItem(BASIC_INFO, JSON.stringify(createBasicInfo(response)));
                });

                const basicInfo = localStorage.getItem(BASIC_INFO);
                const localBasicInfo = basicInfo ? JSON.parse(basicInfo).data : null;
                const role = localBasicInfo ? localBasicInfo.permissions : {};

                return Promise.resolve(role);
            }
        }

        const basicInfo = localStorage.getItem(BASIC_INFO);
        const localBasicInfo = basicInfo ? JSON.parse(basicInfo).data : null;
        const role = localBasicInfo ? localBasicInfo.permissions : {};
        const { pathname, search } = window.location;
        const matchFound = new RegExp('(' + LOGGED_OUT_AND_SIDEBAR_URLS.join('|') + ')');

        if (_.isEmpty(role) && !matchFound.test(pathname)) {
            sessionStorage.setItem(REDIRECT_URL, pathname + search);
            if (!isImpersonator()) {
                history.push('/login')
                console.log("in auth provider", "Rejecting because of empty token/permissions")
                return Promise.reject('Rejecting because of empty token/permissions');
            }
            return Promise.resolve();
        }

        return Promise.resolve(role);
    }

    return Promise.reject();
};
