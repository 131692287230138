
import React from 'react';
import { makeStyles } from "@material-ui/core";
import { VfStar, VfTick } from "./icons";
import PeopleIcon from '@material-ui/icons/People';
import ShareIcon from '@material-ui/icons/Share';

const useStyles = makeStyles((theme) => ({
  red: {
    color: theme.palette.error.main
  },
  green: {
    color: theme.palette.secondary.dark
  },
  blue: {
    color: theme.palette.tertiary.main
  },
  mint: {
    color: theme.palette.secondary.main
  },
  navy: {
    color: theme.palette.primary.main
  },
  icon: {
    margin: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    borderRadius: '100%',
    border: '3px solid',
    padding: theme.spacing(1) /2,
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(2),
      width: theme.spacing(2),   
      margin: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(0)}px ${theme.spacing(0)}px`, 
      '& .MuiSvgIcon-root': {
        fontSize: "1rem",
        '&.vfStar': {
          fontSize: "0.8rem !important",
        }
      },
      '& .vfStar': {
        fontSize: "0.8rem !important",
      },
      '& .vfTick': {
        fontSize: "0.8rem !important",
      }
    },
  },
  marginRightOnly: {
    margin: `${theme.spacing(0)}px ${theme.spacing(1)}px ${theme.spacing(0)}px ${theme.spacing(0)}px`,
  },
  noMargin: {
    margin: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(0)}px`,
  },
  iconDefault: {
    height: theme.spacing(8),
    width: theme.spacing(8),
    '& .MuiSvgIcon-root': {
      fontSize: "2rem",
      color: 'inherit',
    },
    '& .vfStar': {
      fontSize: "1.5rem",
    },
    '& .vfTick': {
      fontSize: "1.5rem",
    },
  },
  iconSmall: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    '& .MuiSvgIcon-root': {
      fontSize: "2rem",
      color: 'inherit',
    },
    '& .vfStar': {
      fontSize: "1.5rem",
    },
    '& .vfTick': {
      fontSize: "0.8rem",
    },
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  flexTop: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: "column"
  }
}));

const icons = {
  star: <VfStar />,
  tick: <VfTick />,
  share: <ShareIcon />,
  people: <PeopleIcon />,
};

const CustomIcon = ({ icon, color, small, marginRightOnly, noMargin}) => {
  const classes = useStyles();

  return (
    <div className={classes.flexTop}>
    <div className={`${classes.icon} 
    ${classes[color]} 
    ${classes.flexCenter} 
    ${marginRightOnly ? classes.marginRightOnly : ''} 
    ${noMargin ? classes.noMargin : ''} 
    ${ small ? classes.iconSmall : classes.iconDefault} vfIcon`}>
      {icons[icon]}
      </div>
      </div>
  );
};

export default CustomIcon;