import ServicesCreate from './ServicesCreate';
import ServicesEdit from './ServicesEdit';
import ServicesList from './ServicesList';
import ServicesVerify from './ServicesVerify';

export default {
    create: ServicesCreate,
    edit: ServicesEdit,
    list: ServicesList,
    verify: ServicesVerify,
};
