import React, { Component } from 'react';
import _ from 'lodash';
import dataProvider from '../dataProvider';
import { connect } from 'react-redux';
import { SimpleForm, email, showNotification } from 'react-admin';
import { FormSectionTitle } from '../Components/Typography/Headlines';
import AccountSettingsContent from './AccountSettings/AccountSettingsContent';
import PasswordInput from '../Components/Inputs/PasswordInput';
import { PASSWORD_ERROR, SECURITY_QUESTION_CHOICES } from '../constants';
import FormToolbar from '../Components/Toolbars/FormToolbar';
import { history } from '../App';

import FormTextInput from '../Components/Inputs/Form/FormTextInput';
import FormSelectInput from '../Components/Inputs/Form/FormSelectInput';
import SettingsBillingPageWrapper from './Billing/Components/SettingsBillingPageWrapper';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    securityQuestion: {
        marginTop: theme.spacing(2),
    },
});

class AccountSettings extends Component {
    state = {
        record: null,
        passwordError: false,
    };

    componentDidMount() {
        dataProvider('GET_ONE', `account-settings/${this.props.professionalId}`).then(res =>
            this.setState({ record: res.data })
        );
    }

    handleSubmit = formValues => {
        const { showNotification } = this.props;
        const payload = {
            id: this.props.professionalId,
            data: formValues,
        };

        dataProvider('UPDATE', `account-settings`, payload).then(res => {
            if (res.errors) {
                this.setState({ passwordError: true });

                showNotification(
                    _.get(res, 'errors.email.0', PASSWORD_ERROR.NOTIFICATION),
                    'warning'
                );
            } else {
                history.push('/');

                showNotification('Changes saved!');
            }
        });
    };

    render() {
        const { showNotification, classes, ...props } = this.props;
        const { record } = this.state;

        if (record) {
            return (
                <SettingsBillingPageWrapper variant="edit" {...props}>
                    <SimpleForm
                        save={this.handleSubmit}
                        redirect={false}
                        toolbar={<FormToolbar label="Update Details" />}
                    >
                        <AccountSettingsContent data={record} {...props} />
                        <>
                            <FormSectionTitle title="Account Details" />
                        </>
                        <FormTextInput
                            label="Your email address"
                            source="email"
                            type="email"
                            validate={email()}
                            defaultValue={_.get(this.state, ['record', 'email'], '')}
                        />
                        <FormTextInput
                            label="Email address to CC:"
                            source="email_cc"
                            type="email"
                            validate={email()}
                            defaultValue={_.get(this.state, ['record', 'email_cc'], '')}
                        />
                        <FormTextInput
                            label="Phone number"
                            source="phone_direct"
                            defaultValue={_.get(this.state, ['record', 'phone_direct'], '')}
                        />
                        <PasswordInput
                            hasError={this.state.passwordError}
                            showableInput
                            source="plainPassword"
                        />
                        <FormSelectInput
                            label="Security question"
                            source="security_question"
                            className={classes.securityQuestion}
                            defaultValue={_.get(this.state, ['record', 'security_question'], null)}
                            choices={SECURITY_QUESTION_CHOICES}
                        />
                        <FormTextInput
                            label="Security answer"
                            source="security_answer"
                            placeholder="******"
                        />
                    </SimpleForm>
                </SettingsBillingPageWrapper>
            );
        }

        return null;
    }
}

const mapStateToProps = ({ basicInfo }) => {
    return {
        professionalId: basicInfo.id,
    };
};

const actions = {
    showNotification,
};

export default connect(
    mapStateToProps,
    actions
)(withStyles(styles)(AccountSettings));
