import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ReviewDownloadPdfButton from './ReviewDownloadPdfButton';
import SimpleList from '../../Components/Lists/SimpleList';
import VerifyAndRejectButton from './VerifyAndRejectReviewButtons';
import { onWorkflowStart } from '../../constants';

import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import withStyles from '@material-ui/core/styles/withStyles';

const propTypes = {
    type: PropTypes.oneOf(['review', 'firstImpression', null]),
};

const styles = () => ({
    email: {
        fontSize: 'smaller',
    },
});

class ReviewListSmall extends Component {
    shouldShowVerifyAndRejectButtons = record => {
        if (this.props.type === 'review' || this.props.type === 'first_impression') {
            return onWorkflowStart(record);
        }

        return false;
    };

    renderPrimaryText = record => {
        const { primaryTextKey } = this.props;
        const name = _.get(record, primaryTextKey, 'Anon');

        return `Reviewer: ${name}`;
    };

    renderSecondaryText = record => {
        const { secondaryTextKey, classes } = this.props;
        const email = _.get(record, secondaryTextKey, 'Email not specified');

        return (
            <div>
                <Typography className={classes.email}>{email}</Typography>
                {this.renderVerifyAndRejectButton(record)}
            </div>
        );
    };

    renderVerifyAndRejectButton = record => {
        if (!this.shouldShowVerifyAndRejectButtons(record)) return null;

        return (
            <>
                <br />
                <VerifyAndRejectButton
                    record={record}
                    verifyProps={{
                        variant: 'contained',
                        style: { marginRight: 8 },
                    }}
                    reviewType={this.props.type}
                />
            </>
        );
    };

    renderTertiaryText = record => {
        if (this.shouldShowVerifyAndRejectButtons(record)) {
            return <ErrorIcon color="error" />;
        }

        return record.state_description;
    };

    render() {
        const {
            dispatch,
            classes,
            record,
            primaryTextKey,
            secondaryTextKey,
            title,
            subTitle,
            ...props
        } = this.props;

        return (
            <div>
                <SimpleList
                    primaryText={this.renderPrimaryText}
                    secondaryText={this.renderSecondaryText}
                    tertiaryText={this.renderTertiaryText}
                    {...props}
                />
                <ReviewDownloadPdfButton />
            </div>
        );
    }
}

ReviewListSmall.propTypes = propTypes;

export default withStyles(styles)(ReviewListSmall);
