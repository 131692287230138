import * as React from 'react';
import { ListBase, ListToolbar } from 'react-admin';
import Card from '@material-ui/core/Card';
import { cloneElement } from 'react';
import ListPagination from './ListPagination';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        '& .MuiToolbar-gutters': {
            paddingTop: 0,
            paddingBottom: 0,
        }
    }
}))

const ListWrapper = ({ children, actions, bulkActionButtons, filters, title, onClickForward, onClickBackwards, hidePagination, ...props }) => {

    const classes = useStyles()
    return (
        <ListBase {...props}>
            <Card>
                {filters || actions ? 
                <Box className={classes.toolbar}>
                    <ListToolbar  filters={filters} actions={actions} />
                </Box> : null }
                {cloneElement(children, {
                    hasBulkActions: bulkActionButtons !== false,
                })}
                { hidePagination ? null : <ListPagination onClickForward={onClickForward} onClickBackwards={onClickBackwards} /> }
            </Card>
        </ListBase>
    );
};

export default ListWrapper;
