import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    showMore: {
        color: theme.palette.tertiary.main
    }
}));


const ExtraCustomers = ({ total, currentSize }) => {
    total = total - currentSize;
    const classes = useStyles()
    return total > 0 ? (
        <div>
            <Typography variant="body1" className={classes.showMore}>
                + {total} more
            </Typography>
        </div>
    ) : (
        ''
    );
};

export default ExtraCustomers;
