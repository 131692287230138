import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';
import _ from 'lodash';
import { InView } from 'react-intersection-observer';
import { randomNumber, isIE11 } from '../../utils';

import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

const propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    color: PropTypes.oneOf(['blue', 'green', 'red', 'yellow']),
};

const styles = ({ palette }) => ({
    blue: {
        color: palette.tertiary.main,
    },
    green: {
        color: palette.secondary.main,
    },
    red: {
        color: palette.error.main,
    },
    yellow: {
        color: palette.error.light,
    },
    default: {
        color: palette.primary.textPrimary,
    },
});

class CountUpNumber extends Component {
    state = {
        hasFinishedCounting: false,
    };

    setFinishedCounting = () => {
        this.setState({ hasFinishedCounting: true });
    };

    getClassName = () => {
        const { color, classes } = this.props;

        const lookup = {
            blue: classes.blue,
            green: classes.green,
            red: classes.red,
            yellow: classes.yellow,
        };

        return _.has(lookup, color) ? lookup[color] : classes.default;
    };

    getValue = () => {
        const value = _.get(this.props, 'value', null);

        return value === null ? '???' : value;
    };

    renderContent = inView => {
        const value = this.getValue();
        const typographyProps = {
            className: this.getClassName(),
            variant: 'h3',
        };

        if (!inView) {
            return <Typography {...typographyProps}>0</Typography>;
        }

        if (typeof value === 'number') {
            return (
                <Typography {...typographyProps}>
                    <CountUp end={value} formattingFn={_.get(this.props, 'formatValue', null)} />
                </Typography>
            );
        }

        if (typeof value === 'string') {
            return (
                <Typography {...typographyProps}>
                    {this.state.hasFinishedCounting ? (
                        value
                    ) : (
                        <CountUp end={randomNumber(1000, 5000)} onEnd={this.setFinishedCounting} />
                    )}
                </Typography>
            );
        }

        return value;
    };

    render() {
        const { width } = this.props;

        if (isIE11() || isWidthDown('sm', width)) {
            return <div>{this.renderContent(true)}</div>;
        }

        return (
            <InView>
                {({ inView, ref }) => <div ref={ref}>{this.renderContent(inView)}</div>}
            </InView>
        );
    }
}

CountUpNumber.propTypes = propTypes;

export default withStyles(styles)(withWidth()(CountUpNumber));
