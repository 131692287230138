import _ from 'lodash';
import {
    START_IMPERSONATE,
    END_IMPERSONATE,
    PLAN_UPGRADE_SUCCESS,
    BASIC_INFO_FETCH_SUCCESS,
    GET_SERVICES_COUNT,
    GET_SERVICES_LIST,
} from '../types';
import { LOCAL_STORAGE } from '../../constants';

const localBasicInfo = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE.BASIC_INFO) || null
);
const localBasicInfoData = _.get(localBasicInfo, 'data', {});

const INITIAL_STATE = {
    isImpersonating: localStorage.getItem(LOCAL_STORAGE.IS_IMPERSONATING),
    loaded: false,
    upgradeSuccess: false,
    servicesCount: 0,
    servicesList: [],
    ...localBasicInfoData,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case BASIC_INFO_FETCH_SUCCESS:
            return { ...state, ...action.basicInfo, loaded: true };
        case START_IMPERSONATE:
            return { ...state, ...action.basicInfo, isImpersonating: true };
        case END_IMPERSONATE:
            return { ...state, ...action.basicInfo, isImpersonating: false };
        case PLAN_UPGRADE_SUCCESS:
            return { ...state, ...action.basicInfo, upgradeSuccess: true };
        case GET_SERVICES_COUNT:
            return { ...state, servicesCount: action.payload };
        case GET_SERVICES_LIST:
            return { ...state, servicesList: action.payload };
        default:
            return state;
    }
};
