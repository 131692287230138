import React from 'react'
import DialogBase from '../../Components/Dialogs/DialogBase';
import withStyles from '@material-ui/core/styles/withStyles';
import { FIRST_IMPRESSION_REPORT_REASONS, REVIEW_REPORT_REASONS } from '../../constants/lists'
import ReviewReportForm from './ReviewReportForm'
import ReviewReportSent from './ReviewReportSent'

export const texts = {
    review: {
        title: 'Report review from',
        formTitle: 'What is the reason for reporting this review?',
        selectList: REVIEW_REPORT_REASONS
    },
    firstImpression: {
        title: 'First impression from',
        formTitle: 'What is the reason for reporting this first impression?',
        selectList: FIRST_IMPRESSION_REPORT_REASONS
    }
}

const styles = theme => ({
    dialog: {
        width: "450px",
        maxWidth: "95%",
        '& .MuiDialogTitle-root': {
            fontSize: "14px",
            wordBreak: "break-all"
        },
        '& .MuiDialogActions-root': {
            display: "none"
        },
    }
});

const ReviewReport = ({
    showDialog,
    handleClose = () => { },
    handleSend = () => { },
    reportType,
    classes,
    record,
    reportedSuccess
}) => {

    const send = (formData) => {
        const toSend = {
            reason: formData.report_reason,
            comment: formData.report_other_comment ? formData.report_other_comment : formData.report_comment ? formData.report_comment : ''
        }

        handleSend(record.id, toSend)
    }
    
    return (
        <DialogBase
            className={classes.dialog}
            open={showDialog}
            onClose={handleClose}
            title={`${texts[reportType].title} ${record.customer.email}`}
        >
            {reportedSuccess ? <ReviewReportSent save={handleClose} /> : <ReviewReportForm reportType={reportType} save={send} /> }
        </DialogBase>
    );
};


export default withStyles(styles)(ReviewReport);

