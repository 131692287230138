import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import DashboardRow from '../DashboardComponents/DashboardRow';
import DashboardRowTitle from '../DashboardComponents/DashboardRowTitle';
import DashboardReferrerCard from './DashboardReferrerCard';
import DashboardInfluencedCard from './DashboardInfluencedCard';

import Grid from '@material-ui/core/Grid';

const DashboardReferrerInfluencedRow = ({ visible }) => {
    if (!visible) return null;

    return (
        <DashboardRow
            title={<DashboardRowTitle>Latest referrers and latest influenced:</DashboardRowTitle>}
            content={
                <Grid container spacing={3}>
                    <Grid item md={6} sm={12}>
                        <DashboardReferrerCard />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <DashboardInfluencedCard />
                    </Grid>
                </Grid>
            }
        />
    );
};

const mapStateToProps = state => {
    let visible = true;
    const referrerCount = _.get(state, 'layout.dashboardReferrerCount');
    const influencedCount = _.get(state, 'layout.dashboardInfluencedCount');

    if (referrerCount !== null && influencedCount !== null) {
        visible = 0 < referrerCount + influencedCount;
    }

    return {
        visible,
    };
};

export default connect(mapStateToProps)(DashboardReferrerInfluencedRow);
