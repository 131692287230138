import React from 'react';
import PropTypes from 'prop-types';
import {HERO_IMAGE_WITH_ELEVATION_REGISTER} from '../constants';

import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core";

const propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    children: PropTypes.any,
};

const useStyles = makeStyles(theme => ({
    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        width: '100%',
        minHeight: 436,
        backgroundImage: `url(${HERO_IMAGE_WITH_ELEVATION_REGISTER})`,
        backgroundPosition: '50% 35%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',

        [theme.breakpoints.down('xs')]: {
            backgroundPosition: 'initial',
        },
    },
    overlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.7)',

        [theme.breakpoints.down('sm')]: {
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
        },
    },
    contentWrapper: {
        textAlign: 'left',
        maxWidth: 850,
        padding: `0 ${theme.spacing(2)}px`,
        zIndex: 1,
    },
    subtitle: {
        color: theme.palette.primary.main,
    },
}));

const RegisterHero = ({ title, subtitle, children }) => {
    const classes = useStyles();

    return (
        <section className={classes.container}>
            <div className={classes.overlay} />
            <div className={classes.contentWrapper}>
                <Typography component="h1" variant="h1">
                    {title}                
                </Typography>
                {subtitle && (
                    <Typography component="h4" className={classes.subtitle}>
                        {subtitle}
                    </Typography>
                )}
                {children}
            </div>
        </section>
    );
};

RegisterHero.propTypes = propTypes;

export default RegisterHero;
