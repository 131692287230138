import React from 'react';
import Button from "../../Components/Buttons/CustomButton";

const OnboardingBannerButton = (props) => {
    const { children, className, ...rest } = props;

    return (
        <Button
            {...rest}
            color="tertiary"
            variant="contained"
            className={className}
        >
            { children }
        </Button>
    );
};

export default OnboardingBannerButton;
