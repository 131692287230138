import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Card from '@material-ui/core/Card';
import withStyles from '@material-ui/core/styles/withStyles';

const ANIMATE_IN_DELAY = 1500;

const propTypes = {
    action: PropTypes.any,
    variant: PropTypes.oneOf(['error', 'default']),
};

const defaultProps = {
    variant: 'default',
    action: null,
};

const styles = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: `4px ${theme.spacing(3)}px`,
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        '& p': {
            color: theme.palette.primary.contrastText,
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            textAlign: 'center',
        },
    },
    variantError: {
        backgroundColor: theme.palette.error.main,
    },
    actionWrapper: {
        color: theme.palette.primary.contrastText,
    },
});

class AlertBarBase extends Component {
    state = {
        isVisible: false,
    };

    componentDidMount() {
        setTimeout(() => this.setState({ isVisible: true }), ANIMATE_IN_DELAY);
    }

    getClassName = () => {
        const { classes, variant } = this.props;

        if (variant === 'error') {
            return cn(classes.variantError, classes.container);
        }

        return classes.container;
    };

    render() {
        const { title, action, classes } = this.props;
        const containerClass = this.getClassName();

        return (
            <Collapse in={this.state.isVisible}>
                <Card className={containerClass}>
                    <Typography variant="body1">
                        <b>Alert: </b>
                        {title}
                    </Typography>
                    {action && (
                        <div className={classes.actionWrapper}>{action}</div>
                    )}
                </Card>
            </Collapse>
        );
    }
}

AlertBarBase.propTypes = propTypes;
AlertBarBase.defaultProps = defaultProps;

export default withStyles(styles)(AlertBarBase);
