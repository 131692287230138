import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    DeleteButton,
    ReferenceManyField,
    Responsive,
    SimpleList,
    Datagrid,
    TextField,
    EditButton,
    DateField,
} from 'react-admin';
import _ from 'lodash';
import { formatSimpleListDate } from '../../utils';

class AwardsGrid extends Component {
    render() {
        const { isFirmAdmin, dispatch, professionalId, ...rest } = this.props;
        const awardsUrl = `/professional/experience/${professionalId}`;

        return (
            <ReferenceManyField label={null} reference="awards" target="professional_id" {...rest}>
                <Responsive
                    small={
                        <SimpleList
                            primaryText={record => record.title}
                            secondaryText={record =>
                                `Date received: ${formatSimpleListDate(record.date)}`
                            }
                        />
                    }
                    medium={
                        <Datagrid>
                            <TextField label="Award" source="title" />
                            <DateField label="Date received" source="date" />
                            <EditButton color="secondary" />
                            <DeleteButton redirect={awardsUrl} />
                        </Datagrid>
                    }
                />
            </ReferenceManyField>
        );
    }
}

const mapStateToProps = ({ basicInfo }) => {
    return {
        professionalId: basicInfo.id,
        isFirmAdmin: _.get(basicInfo, ['permissions', 'is_firm_admin'], false),
    };
};

export default connect(mapStateToProps)(AwardsGrid);
