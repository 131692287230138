import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import cn from 'classnames';
import ContentTooltip from '../../Components/ConsumerReactComponents/ContentTooltip.js';
import { ExternalLink } from '../../Components/Links';
import { palette } from '../../MyTheme.js';

const useStylesCustom = makeStyles(theme => ({
    sameHeigh: {
        minHeight: '2.1em',
        lineHeight: 1,
        overflow: 'auto',
    },
    cardContentRoot: {
        padding: '1rem 1.5rem !important',
        '&:last-child': {
            paddingBottom: 0,
        },
        [theme.breakpoints.up('m')]: {
            paddingLeft: '6rem',
            paddingRight: '6rem',
        },
        '& > div': {
            alignItems: 'flex-start',
        },
        '& .MuiTypography-root': {
            color: theme.palette.primary.main,
        },
    },
    cardRoot: {
        borderRadius: '16px',
        height: '100%',
        boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px',
        [theme.breakpoints.up('xl')]: {
            marginBottom: 0,
        },
        padding: '10px',
        width: '100%',
    },
    active: {
        backgroundColor: theme.palette.primary.main,
        '& .MuiTypography-root': {
            color: theme.palette.primary.contrastText,
        },
        '& .MuiBox-root': {
            color: theme.palette.primary.contrastText,
        },
    },
    subText: {
        textTransform: 'none !important',
    },
    notEnoughData: {
        color: theme.palette.error.main + ' !important',
        fontSize: theme.spacing(1.5)
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const getToolTipText = (description, description_hyperlink) => {
    return (
        <>
            {description}{' '}
            {description_hyperlink && (
                <ExternalLink href={description_hyperlink} target={'_blank'}>
                    Learn more.
                </ExternalLink>
            )}
        </>
    );
};

function CardStats({
    subtitle,
    title,
    diff,
    contentData,
    active,
    compareToIndustry,
    reviewCount,
    description,
    hyperlink,
}) {
    const classes = useStylesCustom();

    return (
        <>
            <Card className={cn(classes.cardRoot, active && classes.active)}>
                <CardContent className={cn(classes.cardContentRoot, active && classes.active)}>
                    <Box
                        component={Box}
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-evenly"
                        key={new Date().toDateString() + Math.random()}>
                        <Box
                            component={Typography}
                            variant="h5"
                            marginBottom="0!important"
                            marginTop="0!important"
                            textAlign="left"
                            className={`${classes.sameHeigh}`}>
                            {subtitle}
                            <ContentTooltip
                                placement="right"
                                title={getToolTipText(description, hyperlink)}
                                disableFocusListener
                                disableTouchListener
                                interactive
                                leaveDelay={200}
                                isGoalTooltip={true}></ContentTooltip>
                        </Box>
                        <Box
                            component={Typography}
                            variant="h2"
                            fontSize="40px!important"
                            textAlign="left"
                            fontWeight="900!important"
                            className={classes.subText}>
                            {title}
                        </Box>
                    </Box>
                    <Box
                        component="div"
                        fontSize=".875rem"
                        marginTop="0"
                        marginBottom="0"
                        className={classes.subText}>
                        <Box width={'100%'}>
                            <Box
                                textAlign="left"
                                className={`${classes.flex} ${classes.sameHeigh}`}>
                                {contentData.text}
                            </Box>
                            <Box
                                textAlign="left"
                                className={`${classes.flex} ${classes.sameHeigh}`}
                                marginTop="0.5rem">
                                {reviewCount >= 5 ? (
                                    <>
                                    <span style={{ color: (diff > 0) ? contentData.color : contentData.reverseColor }}>
                                        {diff >= 0 ? <ArrowUpward/> : <ArrowDownward/>}
                                    </span>
                                        <Box component="span">
                                            {' '}
                                            <span>
                                                {Math.round(Math.abs(diff))}% vs{' '}
                                                {compareToIndustry ? 'industry' : 'firm'}
                                            </span>
                                        </Box>
                                    </>
                                ) : (
                                    <Box component="span" className={classes.notEnoughData}>
                                        <span>
                                            You need at least 5 reviews for this goal to see data
                                        </span>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    );
}

export default CardStats;
