import React from 'react';
import { RadioButtonGroupInput, FormDataConsumer, required } from 'react-admin';
import { REGISTRY_SRA_CHOICES } from '../../constants';
import BasicProfileFormLegalAdviserPoints from './BasicProfileFormLegalAdviserPoints';

const BasicProfileFormLegalAdviserFields = props => {
    return (
        <>
            <RadioButtonGroupInput
                label="Select the option that applies to you:"
                source="legal_adviser_option"
                validate={[required()]}
                defaultValue=""
                choices={REGISTRY_SRA_CHOICES}
            />
            <FormDataConsumer>
                {({ formData }) => (
                    <BasicProfileFormLegalAdviserPoints
                        legalAdviserOption={formData.legal_adviser_option}
                    />
                )}
            </FormDataConsumer>
        </>
    );
};

export default BasicProfileFormLegalAdviserFields;
