import FeesList from './FeesList';
import FeesCreate from './FeesCreate';
import FeesEdit from './FeesEdit';
import FeesInsights from './FeesInsights';
import FeesInsightsRedirect from './FeesInsightsRedirect';
import {
    InvestmentFeeLevelsCreate,
    InvestmentFeeLevelsEdit,
} from './InvestmentFeeLevels';
import {
    MortgageFeeLevelsCreate,
    MortgageFeeLevelsEdit,
} from './MortgageFeeLevels';

export default {
    list: FeesList,
    create: FeesCreate,
    edit: FeesEdit,
    mortgageLevels: {
        create: MortgageFeeLevelsCreate,
        edit: MortgageFeeLevelsEdit,
    },
    investmentLevels: {
        create: InvestmentFeeLevelsCreate,
        edit: InvestmentFeeLevelsEdit,
    },
    insights: FeesInsights,
    insightsRedirect: FeesInsightsRedirect,
};
