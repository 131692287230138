import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'proxy-polyfill';
import 'core-js/stable';
import 'isomorphic-fetch';
import 'date-input-polyfill';
import 'intersection-observer';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './styles/highlight-share.css';
import './styles/bootstrap.min.css';
import './styles/formio.full.min.css';
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));
