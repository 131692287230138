import React from 'react';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';

import StatisticTopTipBubble from './StatisticToptipBubble';
import StatisticColumnGooglers from './StatisticColumnGooglers';
import StatisticColumnClickers from './StatisticColumnClickers';
import StatisticColumnInfluenced from './StatisticColumnInfluenced';
import StatisticColumnReferrers from './StatisticColumnReferrers';
import StatisticColumnDirectEnquirers from './StatisticColumnDirectEnquirers';
import StatisticColumnIndirectEnquirers from './StatisticColumnIndirectEnquirers';
import { isIE11 } from '../../../utils';
import StatisticsColumnProspectIntention from './StatisticsColumnProspectIntention';

const getData = (data, key) => {
    const defaultData = {
        value: 0,
        content: '',
        visible: true,
    };

    return _.get(data, ['statistics', key], defaultData);
};

const StatisticsWrapperMobile = ({ data, loading }) => {
    const scrollTo = elementId => e => {
        const influencedCard = document.getElementById(elementId);

        if (influencedCard) {
            if (isIE11()) {
                window.scrollTo(0, influencedCard.offsetTop);
            } else {
                window.scrollTo({
                    top: influencedCard.offsetTop,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        }
    };

    return (
        <>
            <Grid container spacing={1}>
                 {Boolean(_.get(data, 'statistics.prospect_intention')) && (
                    <Grid item xs={12} sm={6} md={4}>
                        {Boolean(_.get(data, 'statistics.prospect_intention.toptip_content')) && (
                            <StatisticTopTipBubble
                                toptipContent={_.get(data, 'statistics.prospect_intention.toptip_content')}
                            />
                        )}

                        <StatisticsColumnProspectIntention
                            loading={loading}
                            data={getData(data, 'prospect_intention')}
                        />
                    </Grid>
                )}
                {Boolean(_.get(data, 'statistics.googlers')) && (
                    <Grid item xs={12} sm={6} md={4}>
                        {Boolean(_.get(data, 'statistics.googlers.toptip_content')) && (
                            <StatisticTopTipBubble
                                toptipContent={_.get(data, 'statistics.googlers.toptip_content')}
                            />
                        )}

                        <StatisticColumnGooglers
                            loading={loading}
                            data={getData(data, 'googlers')}
                        />
                    </Grid>
                )}
                {Boolean(_.get(data, 'statistics.clickers')) && (
                    <Grid item xs={12} sm={6} md={4}>
                        {Boolean(_.get(data, 'statistics.clickers.toptip_content')) && (
                            <StatisticTopTipBubble
                                toptipContent={_.get(data, 'statistics.clickers.toptip_content')}
                            />
                        )}

                        <StatisticColumnClickers
                            loading={loading}
                            data={getData(data, 'clickers')}
                        />
                    </Grid>
                )}
                {Boolean(_.get(data, 'statistics.influenced')) && (
                    <Grid item xs={12} sm={6} md={4}>
                        {Boolean(_.get(data, 'statistics.influenced.toptip_content')) && (
                            <StatisticTopTipBubble
                                toptipContent={_.get(data, 'statistics.influenced.toptip_content')}
                            />
                        )}

                        <StatisticColumnInfluenced
                            loading={loading}
                            data={getData(data, 'influenced')}
                            handleClick={scrollTo('latest-influenced-card')}
                        />
                    </Grid>
                )}
                {Boolean(_.get(data, 'statistics.referrers')) && (
                    <Grid item xs={12} sm={6} md={4}>
                        {Boolean(_.get(data, 'statistics.referrers.toptip_content')) && (
                            <StatisticTopTipBubble
                                toptipContent={_.get(data, 'statistics.referrers.toptip_content')}
                            />
                        )}

                        <StatisticColumnReferrers
                            loading={loading}
                            data={getData(data, 'referrers')}
                            handleClick={scrollTo('latest-referrers-card')}
                        />
                    </Grid>
                )}
                {Boolean(_.get(data, 'statistics.direct_enquirers')) && (
                    <Grid item xs={12} sm={6} md={4}>
                        {Boolean(_.get(data, 'statistics.direct_enquirers.toptip_content')) && (
                            <StatisticTopTipBubble
                                toptipContent={_.get(
                                    data,
                                    'statistics.direct_enquirers.toptip_content'
                                )}
                            />
                        )}

                        <StatisticColumnDirectEnquirers
                            loading={loading}
                            data={getData(data, 'direct_enquirers')}
                        />
                    </Grid>
                )}
                {Boolean(_.get(data, 'statistics.indirect_enquirers')) && (
                    <Grid item xs={12} sm={6} md={4}>
                        {Boolean(_.get(data, 'statistics.indirect_enquirers.toptip_content')) && (
                            <StatisticTopTipBubble
                                toptipContent={_.get(
                                    data,
                                    'statistics.indirect_enquirers.toptip_content'
                                )}
                            />
                        )}

                        <StatisticColumnIndirectEnquirers
                            loading={loading}
                            data={getData(data, 'indirect_enquirers')}
                        />
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default StatisticsWrapperMobile;
