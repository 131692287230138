import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppLayoutShow from './AppLayoutShow';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import withStyles from '@material-ui/core/styles/withStyles';
import AppLayoutEdit from './AppLayoutEdit';
import AppLayoutList from './AppLayoutList';

const VARIANT = {
    EDIT: 'edit',
    LIST: 'list',
    SIMPLE_FORM: 'simpleForm',
};

const propTypes = {
    /**
     * The variant should match the content of the page
     * e.g. a List page should use the "list" variant
     */
    variant: PropTypes.oneOf([
        VARIANT.EDIT,
        VARIANT.LIST,
        VARIANT.SIMPLE_FORM,
    ]).isRequired,
    /**
     * Pagination for List pages. By default pagination will always show.
     */
    pagination: PropTypes.any,
    /**
     * The header component. In almost all cases this should be a usage of PageActions.js
     */
    header: PropTypes.any,
};

const styles = () => ({
    formContent: {
        '& > form > div': {
            padding: 0,
        },
    },
    listContent: {
        boxShadow: 'none',

        '& > div > div': {
            padding: 0,
            boxShadow: 'none',
        },
    },
});

class AppPageWrapper extends Component {
    render() {
        const { variant, children, classes, pagination, header, ...rest } = this.props;

        if (variant === VARIANT.SIMPLE_FORM) {
            return (
                <Card>
                    <CardContent className={classes.formContent}>
                        {header}
                        {children}
                    </CardContent>
                </Card>
            );
        }

        if (variant === VARIANT.LIST) {
            return (
                <AppLayoutList header={header} pagination={pagination} {...rest}>
                    {children}
                </AppLayoutList>
            );
        }

        if (variant === VARIANT.EDIT) {
            return (
                <AppLayoutEdit header={header} {...rest}>
                    {children}
                </AppLayoutEdit>
            );
        }

        if (variant === 'show') {
            return (
                <AppLayoutShow header={header}>
                    {children}
                </AppLayoutShow>
            );
        }

        return null;
    }
}

AppPageWrapper.propTypes = propTypes;

export default withStyles(styles)(AppPageWrapper);
