import * as React from "react";
import {
    Button,
    useUpdateMany,
    useRefresh,
    useNotify,
    useUnselectAll,
} from 'react-admin';

const ActionManyButton = (props) => {
    const { selectedIds, filterValues } = props
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const [updateMany, { loading }] = useUpdateMany(
        'action',
        selectedIds,
        { value: !filterValues.actioned },
        {
            onSuccess: () => {
                refresh();
                notify(`${filterValues.actioned ? 'Actions marked as not completed' : 'Actions marked as completed'}`);
                unselectAll('action-list');
            },
            onFailure: error => notify('Error: could not action', { type: 'warning' }),
        }
    );

    if (filterValues.actioned === undefined) {
        return false;
    }
    return (
        <Button
            color="secondary"
            label={`${filterValues.actioned ? "Mark selected as not actioned" : "Mark selected as actioned"}`}
            disabled={loading}
            onClick={updateMany}
        />
    );
};

export default ActionManyButton;