import React from 'react';
import { Filter } from 'react-admin';
import FormTextInput from '../Components/Inputs/Form/FormTextInput';
import FirstImpressionsProspectFilter from './FirstImpressions/FirstImpressionsProspectFilter';
import { FUTURE_USE_CHOICES } from '../constants/review';

const ReviewsFilter = ({ ...props }) => {
    return(
    <div>
        <Filter {...props}>
            <FormTextInput
                label="Search by First Name"
                source="customer|first_name"
                alwaysOn
            />
            { props.resource === 'first-impressions' &&
                <FirstImpressionsProspectFilter
                    choices={FUTURE_USE_CHOICES}
                    label="Prospect Intention"
                    source="answer|choice"
                    alwaysOn
                />
            }
        </Filter>
    </div>
);}

export default ReviewsFilter;
