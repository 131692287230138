import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';


const styles = theme => ({
    repTool: {
        position: 'relative',
        height: 384,

        '& > div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.73)',
            position: 'absolute',
            left: 0,
            width: '100%',
            height: theme.spacing(6),
        },

        [theme.breakpoints.only('sm')]: {
            marginBottom: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
        },
    },
    repToolImage: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
        },
    },
    contentLabel: {
        color: theme.palette.primary.main,
    },
});


const RegisterReputationalToolBanner = props => {
    const { image, text, classes, widthSize, textWrapperClassName } = props;

    return (
        <Grid item md={widthSize} sm={6} xs={12} >
            <div className={classes.repTool}>
                <img className={classes.repToolImage} src={image} alt="Reputation Tool" />
                <div className={textWrapperClassName}>
                    <Typography component="h3" variant="body1" className={classes.contentLabel}>
                        <b>{ text }</b>
                    </Typography>
                </div>
            </div>
        </Grid>
    );
};

export default withStyles(styles)(RegisterReputationalToolBanner);
