import React from 'react';
import Button from '../../../Components/Buttons/CustomButton';
import * as analytics from '../../../analytics';

const DashboardButton = ({
    content,
    buttonHref,
    analyticsAction,
    analyticsCategory,
    analyticsLabel,
    classes,
    ...rest
}) => {
    return (
        <Button
            {...rest}
            variant="outlined"
            color="tertiary"
            href={buttonHref}
            analyticsAction={analyticsAction}
            analyticsCategory={analyticsCategory}
            onClick={() => {
                analytics.track(analyticsAction, {
                    category: analyticsCategory,
                    label: analyticsLabel,
                });
            }}
        >
            {content}
        </Button>
    );
};

export default DashboardButton;
