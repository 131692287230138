import React from 'react';
import { Filter } from 'react-admin';
import FormSelectInput from '../../../Components/Inputs/Form/FormSelectInput';

const BillingListFilter = ({ selectableMonths, ...rest }) => {
    return (
        <Filter {...rest}>
            <FormSelectInput
                label="Choose Month"
                source="monthyear"
                choices={selectableMonths}
                alwaysOn
            />
        </Filter>
    );
};

export default BillingListFilter;
