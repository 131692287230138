import React from 'react';
import _ from 'lodash';
import StatisticColumnBase from './StatisticColumnBase';
import StatisticContent from './StatisticContent';
import SpeechBubble from '../../../Components/Typography/SpeechBubble';
import Grid from '@material-ui/core/Grid';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(5),
    },
    statistics: {
        position: 'relative',
        marginTop: theme.spacing(4),
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 2,
        background: 'rgba(255, 255, 255, 0.6)',
    },
    message: {
        textAlign: 'center',
    },
}));

const getToolTipMessage = (reviewAndFiCount = 0) => {
    if (reviewAndFiCount > 0) {
        return "As you build up more recent reviews and use the reputation tools, you'll see some key numbers here on your dashboard, including:";
    }

    return 'As you start to build reviews, you’ll see some key numbers here on your dashboard, including:';
};

const DashboardStatisticsPlaceholder = ({ dashboardData }) => {
    const classes = useStyles();
    const reviewAndFiCount = _.get(dashboardData, 'review_and_fi_count');

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12} sm={12} md={10}>
                    <SpeechBubble>
                        <Typography color="secondary" className={classes.message}>
                            <b>Top tip: </b>
                            {getToolTipMessage(reviewAndFiCount)}
                        </Typography>
                    </SpeechBubble>
                </Grid>
            </Grid>
            <div className={classes.statistics}>
                <div className={classes.overlay} />
                <Grid container spacing={8}>
                    <StatisticColumnBase loading={false} name="Prospect Intention">
                        <StatisticContent>
                            Did you know First Impressions include private feedback on whether someone is going to become a client?
                        </StatisticContent>
                    </StatisticColumnBase>
                    <StatisticColumnBase loading={false} name="Googlers">
                        <StatisticContent>
                            How many people are googling you and finding your profile?
                        </StatisticContent>
                    </StatisticColumnBase>
                    <StatisticColumnBase loading={false} name="Clickers">
                        <StatisticContent>
                            How many clients and prospects are clicking a link to your profile?
                        </StatisticContent>
                    </StatisticColumnBase>
                    <StatisticColumnBase loading={false} name="Referrers">
                        <StatisticContent>
                            How many clients are saying they’re more likely to refer you?
                        </StatisticContent>
                    </StatisticColumnBase>
                    <StatisticColumnBase loading={false} name="Direct Enquiries">
                        <StatisticContent>
                            How many direct enquiries have you received?
                        </StatisticContent>
                    </StatisticColumnBase>
                    <StatisticColumnBase loading={false} name="Potential Enquiries">
                        <StatisticContent>
                            How many ‘potential enquiries’ (where someone found you on VouchedFor
                            but didn’t contact you via VouchedFor)?
                        </StatisticContent>
                    </StatisticColumnBase>
                </Grid>
            </div>
        </div>
    );
};

export default DashboardStatisticsPlaceholder;
