import React from 'react';
import { Typography, Divider, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    divider: {
        marginTop: '1rem',
        marginBottom: '1rem',
    },
    text: {
        marginLeft: theme.spacing(2) + 'px !important',
        color: theme.palette.tertiary.dark,
        marginBottom: '1rem !important',
        paddingTop: '0.25rem',
        paddingBottom: '0.25rem !important',
        fontSize: '0.9rem !important',
        textTransform: 'uppercase',
        letterSpacing: '0.1em',
    },
}));

const ElevationSidebar = () => {
    const classes = useStyles();

    return (
        <>
            <Divider className={classes.divider} variant="middle" />
            <Typography className={classes.text} variant="h2">
                Elevation
            </Typography>
        </>
    );
};
export default ElevationSidebar;
