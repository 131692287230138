import React from 'react';
import { Responsive } from 'react-admin';
import ReviewListMedium from './Content/ReviewListMedium';
import ReviewListSmall from './Content/ReviewListSmall';
import ReviewPageWrapper from './ReviewPageWrapper';
import ReviewsFilter from './ReviewsFilter';

const ReviewList = props => {
    return (
        <ReviewPageWrapper
            variant="list"
            {...props}
            filters={<ReviewsFilter />}
            actions={<div />}
            sort={{ field: 'submitted_at', order: 'DESC' }}
        >
            <Responsive
                medium={<ReviewListMedium />}
                small={
                    <ReviewListSmall
                        type="review"
                        primaryTextKey="client_first_name"
                        secondaryTextKey="client_email"
                    />
                }
            />
        </ReviewPageWrapper>
    );
};

export default ReviewList;
