import React from 'react';
import _ from 'lodash';
import ViewEnquiryDetail from './ViewEnquiryDetail';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}));

const ViewEnquiryQualifyingDetails = ({ data }) => {
    const classes = useStyles();

    if (_.isArray(data)) {
        return data.map((item, i) =>
            <div key={i} className={classes.root}>
                <ViewEnquiryDetail enquiry={item} />
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <ViewEnquiryDetail enquiry={data} />
        </div>
    );
};

export default ViewEnquiryQualifyingDetails;
