import React from 'react';
import { connect } from 'react-redux';
import DashboardFooterButton from '../CardComponents/DashboardFooterButton';
import DashboardListCard from './DashboardListCard';
import { setDashboardReferrerCount } from '../../../state/actions';

const DashboardReferrerCard = ({ setDashboardReferrerCount }) => (
    <DashboardListCard
        resource="customers/referrals"
        title="Latest referrers"
        subTitle="% of your clients say they’re more likely to refer you after seeing your reviews"
        onFinishLoading={count => {
            setDashboardReferrerCount(count);
        }}
        renderSubTitle={percentage => {
            return `${percentage}% of your clients say they’re more likely to refer you after seeing your reviews`;
        }}
        id="latest-referrers-card"
        button={
            <DashboardFooterButton
                content="Ask for referrals"
                buttonHref="/referral-generator/create"
                analyticsAction="Your reviews are driving more referrals"
                analyticsCategory="Dashboard card"
                analyticsLabel="Clicked Invite More Reviews button"
            />
        }
    />
);

export default connect(
    null,
    { setDashboardReferrerCount }
)(DashboardReferrerCard);
