import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';


const DisclaimerCheckbox = props => {

    const { handleChange, value} = props;

    return (
            <FormControlLabel
                control={
                        <Checkbox
                            checked={value}
                            onChange={handleChange}
                            name="disclaimerCheckbox"
                            color="secondary"
                        />
                    }
                label={"I understand that I should have my clients permission to contact them and that I may be asked to provide evidence of our relationship, in line with VouchedFor’s review verification policy."}
            />
    );
};

export default DisclaimerCheckbox;
