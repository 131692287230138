import React from 'react';
import PageActions from '../AppLayout/PageActions';
import FirmTabs from './FirmTabs';

const FirmPageHeader = () => {
    return (
        <>
            <PageActions title="Firm" />
            <FirmTabs />
        </>
    );
};

export default FirmPageHeader;
