import React, { Component } from 'react';
import _ from 'lodash';
import {
    SimpleForm,
    showNotification as showNotificationAction,
    TopToolbar,
    refreshView as refreshViewAction,
} from 'react-admin';
import { connect } from 'react-redux';
import { EditPageTitle } from '../Components/Typography/Headlines';
import ReviewQuestions from '../Review/Content/ReviewQuestions';
import PreventUndoEdit from '../Components/PreventUndoEdit';
import BackButton from '../Components/Buttons/BackButton';
import TrueFalseInput from '../Components/Forms/TrueFalseInput';

import { PLAN } from '../constants';
import { ReviewDescription, ReviewTitle } from '../Review/ReviewEdit';
import { formatSimpleListDate } from '../utils';

const EditActions = ({ basePath, data, resource, href, ...props }) => (
    <TopToolbar>
        <BackButton href={href} />
    </TopToolbar>
);

const ReviewerDetails = ({ record }) => {
    const name = _.get(record.customer, 'name', '');
    const email = _.get(record.customer, 'email', '');
    const date = formatSimpleListDate(record.submitted_at);
    const emailString = email ? `(${email})` : '';

    return (
        <>
            <EditPageTitle
                titleText={'Review by ' + `${name} ${emailString} on ${date}`}
                color={'textPrimary'}
                variant={'h4'}
            />
            <br/>
        </>
    );
};

class FirmReviewEdit extends Component {
    render() {
        const {
            showNotification,
            refreshView,
            onStarterPlan,
            ...rest
        } = this.props;

        return (
            <PreventUndoEdit
                {...rest}
                title={null}
                actions={<EditActions href="/firm-reviews" />}
            >
                <SimpleForm>
                    <ReviewerDetails />
                    <ReviewTitle/>
                    <ReviewDescription />
                    <ReviewQuestions
                        onStarterPlan={onStarterPlan}
                        publicQuestions={true}
                        privateQuestions={false}
                    />
                    <TrueFalseInput
                        label="Use this as a featured review"
                        source="featured"
                    />
                </SimpleForm>
            </PreventUndoEdit>
        );
    }
}

const mapStateToProps = ({ basicInfo }) => {
    return {
        onStarterPlan: basicInfo.plan === PLAN.STARTER,
    };
};

export default connect(
    mapStateToProps,
    {
        showNotification: showNotificationAction,
        refreshView: refreshViewAction,
    }
)(FirmReviewEdit);
