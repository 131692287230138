import React, { useState } from 'react';
import _ from 'lodash';
import { ImageInput } from 'react-admin';
import { Typography, makeStyles } from '@material-ui/core';
import ImagePreviewCrop from '../../Components/Inputs/Components/ImagePreviewCrop';

import { MAX_FILE_UPLOAD_SIZE_FIVE_MB } from '../../constants/various';
import DialogButton from '../../Components/Dialogs/DialogButton';

const useStyles = makeStyles(theme => ({
    imageWrapper: {
        height: '188px',
        width: '188px',
        position: 'relative',
        marginLeft: '10px',
        marginBottom: '20px',
    },
    profileImage: {
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: '50%',
    },
    imageLabel: {
        margin: `${theme.spacing(2)}px 0`,
    },
    imageInput: {
        maxWidth: 800,
    },
}));

export const ImageUploadHeading = () => {
    return (
            < Typography variant='body2'>
                <ul>
                    <li> Our profile photos appear as a circle. Please use the drag and drop tool below to crop your
                        photo into a circle.
                    </li>
                    <li> The max image size should not exceed 5MB. </li>
                </ul>
            </Typography>
    );
};
const ProfileImage = props => {
    const classes = useStyles();
    const professionalPhoto = _.get(props, 'record.professional_photo.urls.standard', null);
    const [imageCropComplete, setImageCropComplete] = useState(false);

    return (
        <div>
            {professionalPhoto && (
                <div>
                    <div className={classes.imageLabel}>
                        <Typography><b>Photo:</b></Typography>
                    </div>
                    <div className={classes.imageWrapper}>
                        <img
                            className={classes.profileImage}
                            src={professionalPhoto}
                            alt="Profile"
                        />
                    </div>
                </div>
            )}
            <DialogButton
                record={props.record}
                type="upload-graphic"
                name="Upload Photo"
                resource="professional"
                form={
                    <>
                        <ImageUploadHeading/>
                        <ImageInput
                            source="file"
                            label={null}
                            accept="image/*"
                            maxSize={MAX_FILE_UPLOAD_SIZE_FIVE_MB}
                            className={classes.imageInput}
                        >
                            <ImagePreviewCrop source="src" setImageCropComplete={setImageCropComplete}/>
                        </ImageInput>
                    </>
                }
                disableSave={!imageCropComplete}
            />
        </div>
    );
};

export default ProfileImage;
