import React from 'react';
import { Query } from 'react-admin';
import ShareButtons, {
    SOCIAL_MEDIA_TYPE,
} from '../../Components/Buttons/ShareButtons';
import Typography from '@material-ui/core/Typography';
import { shareToTwitter, shareToLinkedIn, shareToFacebook } from '../../utils';

const { FACEBOOK, TWITTER, LINKEDIN } = SOCIAL_MEDIA_TYPE;

const ReviewShare = ({ record }) => {
    return (
        <Query type="getOne" resource="reviews/share-content" payload={{id: record.id}}>
            {({data, loading, error}) => {
                if (loading || error) return null;

                const handleShare = (type) => {
                    const { content } = data;

                    switch (type) {
                        case FACEBOOK:
                            return shareToFacebook(content, record.id);
                        case TWITTER:
                            return shareToTwitter(content, record.id);
                        case LINKEDIN:
                            return shareToLinkedIn(record.id);
                        default:
                            return null;
                    }
                };

                return (
                    <ShareButtons
                        title="Share this review:"
                        onShareClick={handleShare}
                    >
                    </ShareButtons>
                );
            }}
        </Query>
    );
};

export default ReviewShare;
