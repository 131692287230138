import React, { useState } from 'react';
import FirmBadgesModal from './Components/FirmBadgesModal';
import {
    REP_TOOL_FIRM_BADGE,
    REP_TOOL_FIRM_BADGE_PLACEHOLDER_VF_2023,
} from '../../constants';
import Button from '../../Components/Buttons/CustomButton';
import ReputationToolItem from './Components/ReputationToolItem';
import { FormSectionTitle } from '../../Components/Typography/Headlines';

const FirmTopRatedBadges = ({showNewRepTool}) => {
    const [showBadges, setShowBadges] = useState(false);

    const openModal = () => {
        setShowBadges(true);
    };

    return (
        <>
            <ReputationToolItem
                img={showNewRepTool ? REP_TOOL_FIRM_BADGE_PLACEHOLDER_VF_2023 : REP_TOOL_FIRM_BADGE}
                content={<FormSectionTitle title={showNewRepTool ? "Top Rated Firm Badge" : "Top Rated Firm Badges"} />}
                actions={
                    <Button variant="contained" color="tertiary" onClick={openModal}>
                        {showBadges ?
                            showNewRepTool ? 'Hide badge' : 'Hide badges'
                                :
                            showNewRepTool ? 'Download badge' : 'Choose badges'
                        }
                    </Button>
                }
            />
            <FirmBadgesModal open={showBadges} handleModalClose={() => setShowBadges(false)} showNewRepTool={showNewRepTool} />
        </>
    );
};

export default FirmTopRatedBadges;
