import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { SimpleForm, FormDataConsumer, TopToolbar } from 'react-admin';
import LinkedSelect from '../Components/Inputs/LinkedSelect';
import PreventUndoEdit from '../Components/PreventUndoEdit';
import BackButton from '../Components/Buttons/BackButton';
import { FormSectionTitle } from '../Components/Typography/Headlines';
import { getServiceEditRedirectUrl } from './servicesUtils';
import { VERTICAL } from '../constants';
import FormTextInput from '../Components/Inputs/Form/FormTextInput';

const { FINANCIAL_ADVISER } = VERTICAL;

const ServicesEdit = props => {
    const {
        servicesCount,
        dispatch,
        professionalId,
        permissions,
        isFinancialAdviser,
        ...rest
    } = props;
    const servicesUrl = `${getServiceEditRedirectUrl(
        isFinancialAdviser,
        permissions
    )}/${professionalId}`;

    return (
        <PreventUndoEdit
            actions={
                <TopToolbar>
                    <BackButton href={servicesUrl} />
                </TopToolbar>
            }
            {...rest}
        >
            <SimpleForm redirect={servicesUrl}>
                <FormSectionTitle title="Edit Service" />
                <FormTextInput label="Service" source="attribute_name" disabled={true} />
                <FormDataConsumer {...rest}>
                    {({ formData }) => {
                        const serviceOptions = _.get(permissions, 'service_options');
                        const attributeId = _.get(formData, 'attribute_id');

                        if (!serviceOptions || !attributeId) return null;

                        return (
                            <LinkedSelect
                                source="service_state"
                                resourceName="services-choices"
                                resourceId={attributeId}
                            />
                        );
                    }}
                </FormDataConsumer>
            </SimpleForm>
        </PreventUndoEdit>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        servicesCount: _.get(basicInfo, 'servicesCount'),
        isFinancialAdviser: _.get(basicInfo, 'vertical') === FINANCIAL_ADVISER,
        professionalId: _.get(basicInfo, 'id'),
    };
};

export default connect(mapStateToProps)(ServicesEdit);
