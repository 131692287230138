import React, { Component } from 'react';
import _ from 'lodash';
import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';
import { FormSectionTitle } from '../Typography/Headlines';

import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    note: {
        margin: `${theme.spacing(1)}px auto`,
    },
    title: {
        marginBottom: theme.spacing(5),
    },
    dataSheet: {
        width: '100%',
    },
    buttonRow: {
        width: '100%',
        textAlign: 'center',
        marginTop: theme.spacing(2),
    },
});

const GRID_ROW_EMPTY = [{}, {}];

const GRID_INITIAL_STATE = [
    [
        { value: 'First name', readOnly: true },
        { value: 'Email', readOnly: true },
    ],
    GRID_ROW_EMPTY,
];

class DataSheetInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            grid: GRID_INITIAL_STATE,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps && this.props.resetGrid) {
            this.setState({
                grid: GRID_INITIAL_STATE,
            });
        }
    }

    handleDataSheetChange(grid) {
        this.setState({ grid });
        let clientsValue = [];
        for (let client of grid) {
            if (!client[0].readOnly) {
                if (client[0].value && client[1].value) {
                    clientsValue.push({
                        client_first_name: client[0].value,
                        client_email: client[1].value,
                    });
                }
            }
        }

        const value = clientsValue.length > 0 ? clientsValue : null;

        this.props.input.onChange(value);
    }

    handleAddRow = () => {
        const clonedGrid = _.cloneDeep(this.state.grid);

        clonedGrid[clonedGrid.length] = GRID_ROW_EMPTY;

        this.setState({ grid: clonedGrid });
    };

    cellRenderer = props => {
        let style;
        const {
            row,
            col,
            cell,
            children,
            updated,
            editing,
            attributesRenderer,
            ...rest
        } = props;

        return (
            <td {...rest} style={style}>
                {props.children}
            </td>
        );
    };

    render() {
        const { classes, label } = this.props;

        return (
            <div>
                <FormSectionTitle title={label} className={classes.title} />
                <ReactDataSheet
                    className={classes.dataSheet}
                    data={this.state.grid}
                    valueRenderer={cell => cell.value}
                    cellRenderer={this.cellRenderer}
                    onContextMenu={(e, cell, i, j) =>
                        cell.readOnly ? e.preventDefault() : null
                    }
                    onCellsChanged={(changes, additionalData) => {
                        let grid = this.state.grid.map(row => [...row]);
                        changes.forEach(({ cell, row, col, value }) => {
                            grid[row][col] = { ...grid[row][col], value };
                        });
                        if (additionalData) {
                            additionalData.forEach(({ row, col, value }) => {
                                if (!grid[row]) {
                                    grid[row] = [{}, {}];
                                }
                                if (grid[row][col]) {
                                    grid[row][col] = {
                                        ...grid[row][col],
                                        value,
                                    };
                                }
                            });
                        }
                        this.handleDataSheetChange(grid);
                    }}
                />
                <div className={classes.buttonRow}>
                    <Button
                        onClick={this.handleAddRow}
                        variant="outlined"
                        color="secondary"
                    >
                        <AddIcon className={classes.icon} />
                        &nbsp;Add Row
                    </Button>
                </div>
                <br />
            </div>
        );
    }
}

export default withStyles(styles)(DataSheetInput);
