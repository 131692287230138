import React from 'react';
import {FormDataConsumer, BooleanInput, required} from 'react-admin';
import {Typography} from '@material-ui/core';
import {ExternalLink} from '../../Components/Links';
import {SUPPORT_URL} from "../../constants";


const EnquiriesReportCriteriaList = ({criteria, ...props}) => {
    const listItems = {
        poor_contact_details: [
            'You attempted to contact the enquirer by phone, and email if provided, at least 3 times over a 24 hour period after receiving the enquiry.',
            'You left your contact details, either by voicemail or email where possible.',
            'You have been unable to have an initial conversation with the enquirer.',
            <>
                <strong>Please note:&nbsp;</strong>
                You must report enquiries within 3 days of receiving them, in line with our&nbsp;
                <ExternalLink href={SUPPORT_URL.ENQUIRY.REFUND_POLICY}>
                    refund policy.
                </ExternalLink>
            </>
        ],
        colleague: [
            'You contacted the consumer within 24 hours.',
            'You spoke to the enquirer to discuss their needs.',
            'You are unable to provide any service they need.',
            'They have not become a client and you have not referred them to another professional.',
            <>
                <strong>Please note:&nbsp;</strong>
                You must report enquiries within 3 days of receiving them, in line with our&nbsp;
                <ExternalLink href={SUPPORT_URL.ENQUIRY.REFUND_POLICY}>
                    refund policy.
                </ExternalLink>
            </>
        ],
    };

    return (
        <FormDataConsumer {...props}>
            {({formData}) =>
                formData.enquiry_reject_reason.gka === criteria && (
                    <div>
                        <Typography variant="body1">Please confirm that:</Typography>
                        <ol>
                            {listItems[criteria].map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ol>
                        < BooleanInput
                            label="I confirm I have met these criteria"
                            source="confirm_reject_criteria_met"
                            validate={[required()]}
                        />
                    </div>
                )
            }
        </FormDataConsumer>
    );
};

export default EnquiriesReportCriteriaList;
