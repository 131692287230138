export const LOCAL_STORAGE = {
    BASIC_INFO: 'basicInfo',
    IS_IMPERSONATING: 'isImpersonating',
    HISTORY_LIST: 'historyList',
    CALLBACK_REQUESTED: 'callbackRequested',
    BASIC_PROFILE_FORM_VALUES: 'basicProfileFormValues',
    ADDRESS_FORM_VALUES: 'addressFormValues',
    LEXIKTOKEN: 'lexiktoken',
    INITIAL_TOKEN: 'initialToken',
    INITIAL_BASIC_INFO: 'initialBasicInfo',
    REDIRECT_URL: 'redirectUrl',
    AUTH_ERROR_MESSAGE: 'authErrorMessage',
    REFERRAL_EXCLUDED_IDS: 'referralExcludedIds',
    REFERRAL_FORM_VALUES: 'referralFormValues',
    UNAUTHENTICATED_PROFESSIONAL_ID: 'unauthenticatedProfessionalId',
    UNAUTHENTICATED_LEXIKTOKEN: 'unauthenticatedLexiktoken',
    HAS_COMPLETED_FEES_SIGN_UP: 'hasCompletedFeesSignUp',
    NEW_ADVISER: 'newAdviser',
};
