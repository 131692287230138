import React from 'react';
import { connect } from 'react-redux';
import { Create, SimpleForm, required, TopToolbar } from 'react-admin';
import _ from 'lodash';
import { pastDateValidation } from '../validation';
import { FormSectionTitle } from '../Components/Typography/Headlines';
import BackButton from '../Components/Buttons/BackButton';
import FormTextInput from '../Components/Inputs/Form/FormTextInput';
import FormDateInput from '../Components/Inputs/Form/FormDateInput';

const AwardsCreate = props => {
    const { isFirmAdmin, dispatch, professionalId, ...rest } = props;
    const awardsUrl = `/professional/experience/${professionalId}`;

    return (
        <Create
            actions={
                <TopToolbar>
                    <BackButton href={awardsUrl} />
                </TopToolbar>
            }
            {...rest}
        >
            <SimpleForm redirect={awardsUrl}>
                <FormSectionTitle title="Add award" />
                <FormTextInput label="Award" source="title" validate={[required()]} />
                <FormDateInput
                    label="Date Received"
                    source="date"
                    validate={[required(), pastDateValidation]}
                />
            </SimpleForm>
        </Create>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        professionalId: basicInfo.id,
        isFirmAdmin: _.get(basicInfo, ['permissions', 'is_firm_admin'], false),
    };
};

export default connect(mapStateToProps)(AwardsCreate);
