import React from 'react';
import { palette } from '../../MyTheme';

const AxisTick = ({ x, y, payload, disabled, textProps }) => {
    return (
        <g transform={`translate(${x},${y})`}>
            <text
                y={8}
                textAnchor="middle"
                fill={
                    disabled
                        ? palette.tertiary.dark
                        : palette.primary.textPrimary
                }
                fontSize={12}
                {...textProps}
            >
                {disabled ? '???' : payload.value}
            </text>
        </g>
    );
};

export const XAxisTick = props => {
    return <AxisTick textProps={{ y: 8 }} {...props} />;
};

export const YAxisTick = props => {
    return <AxisTick textProps={{ y: 4, x: -8 }} {...props} />;
};
