import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showNotification, userLogin } from 'react-admin';
import ResetPasswordExpiredTokenMessage from '../Content/ResetPasswordExpiredTokenMessage';
import ResetPasswordForm from '../Content/ResetPasswordForm';
import SkeletonWrapper from '../../Components/SkeletonWrapper';
import { API_KEYS } from '../../constants';
import { forgottenPasswordStyles } from '../forgottenPasswordStyles';

import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const propTypes = {
    token: PropTypes.string.isRequired,
    title: PropTypes.string,
    buttonLabel: PropTypes.string,
};

const defaultProps = {
    title: 'Reset Password',
    buttonLabel: 'Reset Password',
};

const ResetPasswordContent = (props) => {
    const [state, setState] = useState('loading');
    const { classes, token } = props;

    useEffect(() => {
        const validateToken = async () => {
            try {
                const res = await fetch(
                    `${API_KEYS.public_api_url}/register/resetting/validate?token=${token}`
                );

                return res.status === 200 ? setState(null) : handleError();
            } catch {
                handleError();
            }
        };

        // Ensure the user is logged out
        localStorage.clear();

        validateToken();
    }, [token]);

    const handleError = () => {
        setState('error');
    };

    return (
        <Grid container justify="center" direction="column" className={classes.forgotContainer}>
            {state === 'error' ? (
                <ResetPasswordExpiredTokenMessage />
            ) : (
                <>
                    <SkeletonWrapper
                        loading={state === 'loading'}
                        height={24}
                        width={300}
                        variant="rect"
                        className={classes.skeletonTop}
                    >
                        <Typography>
                            <b>{props.title}</b>
                        </Typography>
                        <Typography>{props.helperText}</Typography>
                    </SkeletonWrapper>
                    <SkeletonWrapper
                        loading={state === 'loading'}
                        height={80}
                        width={300}
                        variant="rect"
                    >
                        <ResetPasswordForm token={token} buttonLabel={props.buttonLabel} />
                    </SkeletonWrapper>
                </>
            )}
        </Grid>
    );
};

ResetPasswordContent.propTypes = propTypes;
ResetPasswordContent.defaultProps = defaultProps;

const actions = {
    showNotification,
    userLogin,
};

export default connect(
    null,
    actions
)(withStyles(forgottenPasswordStyles)(ResetPasswordContent));
