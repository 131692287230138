import React from 'react';
import Tip from '../Tip';
import * as analytics from '../../../../analytics';

const EmptyData = () => {
    const inviteOnClick = () => analytics.track('invite_reviews', { category: 'How_they_found_you' });
    const repOnClick = () => analytics.track('use_rep_tools', { category: 'How_they_found_you' });
    return (
        <>
            <Tip
                text="As you begin to invite reviews and make use of our rep tools, you'll start to see data coming through on your dashboard!"
                showInviteButton
                showRepButton
                inviteOnClick={inviteOnClick}
                repOnClick={repOnClick}
            />
        </>
    );
};

export default EmptyData;
