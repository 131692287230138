import React, { useState, useEffect } from 'react';
import CustomLineChart from './CustomLineChart';
import CustomComposedChart from './CustomComposedChart';
import CustomBarChart from './CustomBarChart';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.primary.contrastText,
        padding: '1.5rem',
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        height: 400,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: "2px solid " + theme.palette.tertiary.dark,
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
        '& .recharts-legend-wrapper': {
            bottom: `${- theme.spacing(1)}px !important` 
        },
        '& .recharts-legend-item': {
            cursor: 'pointer',
            fontSize: '1.1rem',
            fontWeight: 700,
        },
        '& .recharts-cartesian-axis-tick-value': {
            fontSize: '1.1rem',
            fontWeight: 700,
            fill: theme.palette.primary.main
        },
        '& .recharts-wrapper': {
            [theme.breakpoints.down('sm')]: {
                left: - theme.spacing(2),
            },
        }
    },
}));

const CustomChart = ({ dataPoints, lines = [], bars = [], colors, barColors, lineColors }) => {
    const classes = useStyles();

    const [chartType, setChartType] = useState('');
    const [opacity, setOpacity] = useState({});
    const [visibility, setVisibility] = useState({});

    const elements = [...lines, ...bars] 

    useEffect(() => {
        const type = lines.length > 0 && bars.length > 0 ? 'composed' : 
        lines.length > 0 ? 'line' :
        bars.length > 0 ? 'bar' :
        ''
        setChartType(type)

        if (type) {
            let initialOpacity = {};
            let initialVisibility = {};
            elements.map(el => {
                initialOpacity = { ...initialOpacity, [el]: 1 };
                initialVisibility = { ...initialVisibility, [el]: 1 };
                return true
            });
            setOpacity(initialOpacity);
            setVisibility(initialVisibility);
        }
    }, [lines, bars]);

    const handleMouseEnter = o => {
        const { dataKey } = o;
        setOpacity({ ...opacity, [dataKey]: 0.5 });
    };

    const handleMouseLeave = o => {
        const { dataKey } = o;
        setOpacity({ ...opacity, [dataKey]: 1 });
    };

    const handleClick = o => {
        const { dataKey } = o;
        setVisibility({ ...visibility, [dataKey]: !visibility[dataKey] });
    };

    const renderChart = () => {
        switch (chartType) {
            case 'line':
                return (
                    <CustomLineChart
                        dataPoints={dataPoints}
                        elements={lines}
                        opacity={opacity}
                        visibility={visibility}
                        handleMouseEnter={handleMouseEnter}
                        handleMouseLeave={handleMouseLeave}
                        handleClick={handleClick}
                        colors={lineColors}
                    />
                );
            case 'composed':
                return (
                    <CustomComposedChart
                        dataPoints={dataPoints}
                        lineElements={lines}
                        barElements={bars}
                        opacity={opacity}
                        visibility={visibility}
                        handleMouseEnter={handleMouseEnter}
                        handleMouseLeave={handleMouseLeave}
                        handleClick={handleClick}
                        barColors={barColors}
                        lineColors={lineColors}

                    />
                );
            case 'bar':
                return (
                    <CustomBarChart
                        dataPoints={dataPoints}
                        elements={bars}
                        opacity={opacity}
                        visibility={visibility}
                        handleMouseEnter={handleMouseEnter}
                        handleMouseLeave={handleMouseLeave}
                        handleClick={handleClick}
                        colors={barColors}

                    />
                );
            default: 
                return null
        }
    };

    return <div className={classes.root}>{renderChart()}</div>;
};

export default CustomChart;
