import React from 'react';
import _ from 'lodash';
import { Query } from 'react-admin';
import Hidden from '@material-ui/core/Hidden';

import DashboardRow from '../DashboardComponents/DashboardRow';
import DashboardRowTitle from '../DashboardComponents/DashboardRowTitle';
import DashboardStatisticsPlaceholder from './DashboardStatisticsPlaceholder';
import StatisticsWrapperDesktop from './StatisticsWrapperDesktop';
import StatisticsWrapperMobile from './StatisticsWrapperMobile';

const DashboardStatistics = ({ dashboardData }) => (
    <Query type="getOne" resource="dashboard/statistics">
        {({ data, loading }) => {
            if (!loading && data) {
                if (Boolean(_.get(data, 'show_placeholder'))) {
                    return <DashboardStatisticsPlaceholder dashboardData={dashboardData} />;
                }

                if (_.get(data, 'statistics', []).length === 0) {
                    return null;
                }
            }

            return (
                <DashboardRow
                    title={
                        <DashboardRowTitle>
                            See the impact your reviews are having (last 12 months):
                        </DashboardRowTitle>
                    }
                    content={
                        <>
                            <Hidden lgUp>
                                <StatisticsWrapperMobile data={data} loading={loading} />
                            </Hidden>
                            <Hidden mdDown>
                                <StatisticsWrapperDesktop data={data} loading={loading} />
                            </Hidden>
                        </>
                    }
                />
            );
        }}
    </Query>
);

export default DashboardStatistics;
