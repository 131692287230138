import React, { Component } from 'react';
import _ from 'lodash';
import dataProvider from '../../dataProvider';
import FeesInsightsCard from './FeesInsightsCard';
import * as analytics from '../../analytics';
import FeesComparisonTable from './FeesComparisonTable';

import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import { CallToActionButton } from '../../Components/Buttons/Buttons';
import { ASSET_LEVEL_CHOICES } from '../../constants';

const styles = theme => ({
    buttonWrapper: {
        marginTop: theme.spacing(5),
    },
});

class FeesAverage5YearCost extends Component {
    state = {
        loading: false,
        insights: null,
    };

    componentDidMount() {
        this.fetchStatistics(ASSET_LEVEL_CHOICES[0].id);
    }

    fetchStatistics = assetLevel => {
        this.setState({ loading: true }, () => {
            dataProvider('GET_ONE', `fees-insights/statistics/${assetLevel}`).then(res => {
                this.setState({
                    insights: _.get(res, ['data'], null),
                    loading: false,
                });
            });
        });
    };

    render() {
        const { loading, insights } = this.state;
        const { feeReportUrl, classes, showUnlockBanner, isContentDisabled } = this.props;

        return (
            <FeesInsightsCard showUnlockBanner={showUnlockBanner} title="Average 5 year cost:">
                <FeesComparisonTable
                    loading={loading}
                    insights={insights}
                    onDropdownChange={this.fetchStatistics}
                    isContentDisabled={isContentDisabled}
                >
                    <Grid container justify="center" className={classes.buttonWrapper}>
                        <CallToActionButton
                            href={feeReportUrl}
                            disabled={loading}
                            onClick={() => {
                                analytics.track('Clicked Get Fee Report', {
                                    category: 'Fee Insights',
                                });
                            }}
                        >
                            Get Shareable Fee Report
                        </CallToActionButton>
                    </Grid>
                </FeesComparisonTable>
            </FeesInsightsCard>
        );
    }
}

export default withStyles(styles)(FeesAverage5YearCost);
