import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import ErrorButton from '../../Components/Buttons/ErrorButton';
import EditButton from '../../Components/Buttons/EditButton';

const useStyles = makeStyles(theme => ({
    row: {
        display: 'flex',
        alignItems: 'center',

        '& .MuiButtonBase-root': {
            margin: 0,
        }
    },
}));

const EnquiryListButtons = ({ record }) => {
    const { id, enquiry_reject_reason } = record;
    const classes = useStyles();

    return (
        <div className={classes.row}>
            <EditButton color="secondary" href={`/enquiries/${id}`} label="View" icon={null} />
            {enquiry_reject_reason ? (
                enquiry_reject_reason.gka === 'genuine' ? (
                    <ErrorButton href={`/enquiry/report/${id}`}>
                        Report
                    </ErrorButton>
                ) : (
                    <Button disabled>
                        Reported
                    </Button>
                )
            ) : null}
        </div>
    );
};

export default EnquiryListButtons;
