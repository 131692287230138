import React from 'react';
import PropTypes from 'prop-types';
import ContentTooltip from '../Components/ConsumerReactComponents/ContentTooltip';

import Tab from '@material-ui/core/Tab';

const propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

const PageTabWithTooltip = ({ title, ...rest }) => {
    return (
        <ContentTooltip placement="top" title={title}>
            <Tab {...rest} />
        </ContentTooltip>
    );
};

PageTabWithTooltip.propTypes = propTypes;

export default PageTabWithTooltip;
