import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { Query } from 'react-admin';

import DashboardCard from './DashboardCard';
import { Link } from '../Components/Links';

import { FA_GUIDE_LOGO_2020 } from '../constants';

const styles = theme => ({
    guideLogo: {
        width: theme.spacing(25),
        height: theme.spacing(15),
    },
    titleText: {
        color: theme.palette.primary.contrastText,
    },
});

class GuideActionsCard extends Component {
    state = {
        error: false,
    };

    generateHeaderTitle = () => {
        const { classes } = this.props;

        return (
            <Typography className={classes.titleText}>
                Congratulations! You currently meet the qualification criteria. Why not{' '}
                <Link href={`/reputation`} dashboardLink>
                    order your guide copies or trophy
                </Link>{' '}
                now?
            </Typography>
        );
    };

    render() {
        const { error } = this.state;
        const { basicInfo, classes } = this.props;
        if (error) return null;

        return (
            <Query type="getOne" resource="dashboard/guide">
                {({ data, loading }) => {
                    if (loading) return null;

                    const isQualifiedForTheGuide = _.get(data, 'qualified', false);

                    if (!isQualifiedForTheGuide) return null;

                    return (
                        <DashboardCard
                            hidden={_.get(basicInfo, 'is_logged_in') === undefined}
                            headerTitle={this.generateHeaderTitle()}
                            gridProps={{
                                sm: 12,
                            }}
                            headerLeft={
                                <img
                                    src={FA_GUIDE_LOGO_2020}
                                    className={classes.guideLogo}
                                    alt=""
                                />
                            }
                            isGuideBanner
                            hideChildrenContent={isQualifiedForTheGuide}
                        />
                    );
                }}
            </Query>
        );
    }
}

const mapStateToProps = ({ basicInfo }) => {
    return {
        basicInfo,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(GuideActionsCard));
