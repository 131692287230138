import React from 'react';
import { LOGO } from '../../constants/assets';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    logo: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            margin: '0 auto',
        },
    },
    logoImg: {
        height: 40,

        [theme.breakpoints.down('sm')]: {
            height: 30,
            marginBottom: theme.spacing(2) + 3,
            marginLeft: -(theme.spacing(6)),
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: 0,
            marginLeft: 0,
        },
    },
}));


const HeaderLogo = () => {
    const classes = useStyles();

    return (
        <a className={classes.logo} href="/">
            <img className={classes.logoImg} src={LOGO} alt="VouchedFor" />
        </a>
    );
};

export default HeaderLogo;
