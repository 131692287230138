import { useEffect, useState } from 'react';
import { onMatchingRoute } from '../../utils';
import { VERIFY_SERVICE_ROUTES, LOCAL_STORAGE } from '../../constants';

const SHOW_POPUP_DELAY = 5000;
const SHOW_POPUP_INTERVAL_CHECK = 1000;

// List of routes the popup will NOT display on
const getDisabledRoutes = () => {
    const verifyServiceRoutes = Object.values(VERIFY_SERVICE_ROUTES);
    return verifyServiceRoutes;
};

const renderDialog = (professionalId, popup, setVisible) => {
    // If logged out
    if (!Boolean(professionalId)) {
        return;
    }

    // If no popup is meant to be rendered
    if (!Boolean(popup)) {
        return;
    }

    // If just signed up for the first time
    if (localStorage.getItem(LOCAL_STORAGE.NEW_ADVISER)) {
        return;
    }

    const disabledRoutes = getDisabledRoutes();

    // Show the popup after delay
    setTimeout(() => {
        // Keep checking if we're on a new route
        const interval = setInterval(() => {
            if (!onMatchingRoute(disabledRoutes)) {
                setVisible(true);
            }
            clearInterval(interval);
        }, SHOW_POPUP_INTERVAL_CHECK);
    }, SHOW_POPUP_DELAY);
};

export function useRenderDialogDelay(professionalId, popup) {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (!visible) {
            renderDialog(professionalId, popup, setVisible);
        }
    }, [professionalId, popup, visible]);

    return { visible };
}
