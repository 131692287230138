import React from 'react';

import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    root: {
        position: 'absolute',
        top: '10px',
        left: '-30px',
        background: theme.palette.secondary.dark,
        color: theme.palette.common.white,
        transform: `rotateZ(-30deg)`,
        padding: `2px ${theme.spacing(9)}px`,
    },
});

const Ribbon = props => {
    const { classes, label } = props;

    if (!label) return null;

    return (
        <Typography className={classes.root}>
            <b>{label}</b>
        </Typography>
    );
};

export default withStyles(styles)(Ribbon);
