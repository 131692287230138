import React from 'react';
import _ from 'lodash';
import { Datagrid, ListContextProvider, useQuery } from 'react-admin';
import { InvestmentFeeLevelsGridEditButton } from '../InvestmentFeeLevels';
import {
    PostfixTextField,
    PrefixTextField,
    UnderscoreConvertTextField,
} from '../../Components/ResourcePageComponents';
import { FEE_SUB_TYPE } from '../../constants';
import FeesChargeLevelDeleteButton from '../Components/FeesChargeLevelDeleteButton';

const InvestmentFeesDataGrid = ({ feeId, isLoggedIn, feeType, subType }) => {
    const { data, loading, error, total } = useQuery({
        type: 'getList',
        resource: 'fee-charges',
        payload: {
            filter: {
                fee: feeId,
            },
        },
    });

    if (loading || error) {
        return null;
    }

    return (
        <ListContextProvider
            value={{
                data: _.keyBy(data, 'id'),
                ids: data.map(({ id }) => id),
                total,
                page: 1,
                perPage: 50,
                setPage: () => {},
                currentSort: { field: 'id', order: 'DESC' },
                basePath: '/fees',
                resource: 'fees',
                selectedIds: [],
            }}
        >
            <Datagrid>
                <UnderscoreConvertTextField label="For levels" source="levelType" />
                <PrefixTextField label="Minimum value" source="minimumAmount" prefix="£" />
                <PrefixTextField label="Maximum value" source="maximumAmount" prefix="£" />
                {subType === FEE_SUB_TYPE.FIXED_FEE_ONLY
                    ? [
                          <PrefixTextField
                              key="initialCharge"
                              label="Initial"
                              source="initialCharge"
                              prefix="£"
                          />,
                          <PrefixTextField
                              key="ongoingCharge"
                              label="Ongoing"
                              source="ongoingCharge"
                              prefix="£"
                          />,
                      ]
                    : [
                          <PostfixTextField
                              key="initialCharge"
                              label="Initial"
                              source="initialCharge"
                              postfix="%"
                          />,
                          <PostfixTextField
                              key="ongoingCharge"
                              label="Ongoing"
                              source="ongoingCharge"
                              postfix="%"
                          />,
                      ]}
                {isLoggedIn
                    ? [
                          <InvestmentFeeLevelsGridEditButton
                              key="editButton"
                              feeType={feeType}
                              subType={subType}
                          />,
                          <FeesChargeLevelDeleteButton />,
                      ]
                    : null}
            </Datagrid>
        </ListContextProvider>
    );
};

export default InvestmentFeesDataGrid;
