import React from 'react';
import PropTypes from 'prop-types';
import { SimpleShowLayout } from 'react-admin';

import Card from '@material-ui/core/Card';
import withStyles from '@material-ui/core/styles/withStyles';

const propTypes = {
    /**
     * The header component. In almost all cases this should be a usage of PageActions.js
     */
    header: PropTypes.any,
};

const defaultProps = {
    header: null,
};

const styles = () => ({
    root: {
        marginTop: -16,
    },
});

const AppLayoutShow = ({ classes, children, header = null }) => {
    return (
        <Card>
            <SimpleShowLayout className={classes.root}>
                {header}
                {children}
            </SimpleShowLayout>
        </Card>
    );
};

AppLayoutShow.propTypes = propTypes;
AppLayoutShow.defaultProps = defaultProps;

export default withStyles(styles)(AppLayoutShow);
