import React from 'react';
import _ from 'lodash';
import { useQuery } from 'react-admin';
import ReputationToolCard from '../../Card/ReputationToolCard';
import Button from '../../../Components/Buttons/CustomButton';

const TopRatedReportCard = props => {
    const { data, loading } = useQuery({
        type: 'getOne',
        resource: 'reputation/card/top-rated-certificate',
    });

    return (
        <ReputationToolCard
            title={_.get(data, 'title', '')}
            subTitle={_.get(data, 'subtitle', '')}
            disabled={loading || _.get(data, 'showOverlay')}
            image={props.image}
            actions={
                <Button variant="contained" color="tertiary" onClick={props.handleClick}>
                    Download
                </Button>
            }
        />
    );
};

export default TopRatedReportCard;
