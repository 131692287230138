import React from 'react';
import cn from 'classnames';
import { Toolbar } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    toolbar: {
        [theme.breakpoints.only('xs')]: {
            padding: 0,
            position: 'relative',
        },
    },
});

const FormToolbar = ({ classes, children, className, ...rest }) => {
    return (
        <Toolbar className={cn(classes.toolbar, className)} {...rest}>
            {children}
        </Toolbar>
    );
};

export default withStyles(styles)(FormToolbar);
