import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SimpleForm } from 'react-admin';
import PageActions from '../../AppLayout/PageActions';
import PageTabs from '../../AppLayout/PageTabs';
import { getFeesTabs } from '../../Professional/professionalUtils';
import AppPageWrapper from '../../AppLayout/AppPageWrapper';

const propTypes = {
    children: PropTypes.any.isRequired,
};

const FeesPageWrapper = props => {
    const { children, dispatch, isLoggedIn, showFeeInsightsTab, ...rest } = props;

    return (
        <AppPageWrapper
            {...rest}
            variant="edit"
            header={
                <>
                    <PageActions title="Fees" />
                    <PageTabs routes={getFeesTabs(showFeeInsightsTab)} />
                </>
            }
        >
            <SimpleForm redirect={false} toolbar={null}>
                {children}
            </SimpleForm>
        </AppPageWrapper>
    );
};

FeesPageWrapper.propTypes = propTypes;

const mapStateToProps = ({ basicInfo }) => {
    return {
        showFeeInsightsTab: _.get(basicInfo, ['permissions', 'show_fees_insights_tab'], false),
    };
};

export default connect(mapStateToProps)(FeesPageWrapper);
