import { required, email } from 'react-admin';

export const validateRequired = required();

export const validateEmail = [required(), email()];

export const pastDateValidation = (value, allValues) => {
    if (new Date(value) > Date.now()) {
        return 'Date should be in the past';
    }
};

export const futureDateValidation = (value, allValues) => {
    if (!value) return undefined;

    let currentDate = new Date(value);

    if (typeof value === 'string') {
        if (value.includes('/') && value.length === 10) {
            const valueArr = value.split('/').reverse();

            currentDate = new Date(...valueArr);
        }
    }

    if (currentDate < Date.now()) {
        return 'Date should be in the future';
    }
};

export const validateEndDate = (value, allValues) => {
    if (allValues.startDate >= value) {
        return 'End Date can not be before the start date.';
    }
    return;
};

export const emailInputValiation = string => {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(string);
};

export const fileSizeValidation = (maxSize, message = 'Please select a smaller file') => value => {
    return value && value.rawFile.size > maxSize ? message : undefined;
};

export const numberValueValidation = maxValue => value => {
    return value > maxValue ? `Please select a value smaller than ${maxValue}` : undefined;
};

export const validateRequiredCheckboxInputGroup = value => {
    return value.length === 0 ? 'Required' : undefined;
};

export const trueInputValidation = value => {
    return value !== 'TRUE' ? 'Input value must be equal to "TRUE"' : undefined;
};

export const validateMinMaxFields = values => {
    const errors = {};

    if (!values) return {};

    const { minimumAmount, maximumAmount } = values;

    if (maximumAmount === 0) {
        errors.maximumAmount = [`Max value cannot be 0`];
    }

    if (minimumAmount && maximumAmount) {
        if (minimumAmount >= maximumAmount) {
            errors.maximumAmount = [`Must be greater than ${minimumAmount}`];
        }
    }

    return errors;
};
