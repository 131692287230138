import React from 'react';
import PageTabs from '../../AppLayout/PageTabs';

const ReferralsTabs = () => {
    return (
        <PageTabs
            routes={[
                {
                    label: 'SEND REFERRAL REQUESTS',
                    link: '/referral-generator/create',
                },
                {
                    label: 'SEE WHO YOU’VE SENT TO',
                    link: '/customer-referral',
                },
            ]}
        />
    );
};

export default ReferralsTabs;
