import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Datagrid, TextField, DateField } from 'react-admin';
import { FormSectionTitle } from '../../../Components/Typography/Headlines';
import LabelAdornment from '../../../Components/Typography/LabelAdornment';
import { getLexikToken } from '../../../Helpers/provider';
import { showDownloadBillingPdfButton } from '../../settingsUtils';
import { API_KEYS, CONTACT } from '../../../constants';
import { Typography } from '@material-ui/core';
import Button from '../../../Components/Buttons/CustomButton';

const ViewButton = ({ record }) => {
    if (!record.amount) return null;

    return (
        <Button
            color="secondary"
            component={Link}
            to={{
                pathname: `/billing/${record.id}/show`,
                state: { billingItem: record },
            }}
        >
            View
        </Button>
    );
};

const CustomAmountField = ({ source, record }) => {
    const amount = _.get(record, source, '');

    return <Typography variant="body1">{amount.replace(')', '').replace('(', '')}</Typography>;
};

const BillingListContent = props => {
    const {
        selectableMonths,
        filterValues,
        billingDetails,
        showBillingMessage,
        permissions,
        ...rest
    } = props;
    const currentMonthId = _.get(filterValues, 'monthyear');
    const showDownloadButton = showDownloadBillingPdfButton(selectableMonths, currentMonthId);

    const downloadPdfLink = e => {
        const url = `${API_KEYS.api_url}/billing/${currentMonthId}?lexiktoken=${getLexikToken()}`;

        window.open(url, '_blank');
    };

    return (
        <div>
            {showBillingMessage && (
                <>
                    <Typography variant="body1">
                        <b>You have successfully upgraded your account!</b>
                    </Typography>
                    <Typography variant="body1">
                        You will receive an email confirming this within three working days.
                        VouchedFor will appear on your bank statement.
                    </Typography>
                </>
            )}
            <>
                <FormSectionTitle title="Previous Bills" />
            </>
            <Datagrid {...rest}>
                <TextField label="Description" source="description" />
                <DateField label="Date" source="date" />
                <CustomAmountField label="Amount (incl. VAT)" source="amount" />
                <ViewButton />
            </Datagrid>
            {showDownloadButton && (
                <Button color="tertiary" variant="contained" onClick={downloadPdfLink}>
                    Download as pdf
                </Button>
            )}
            {!_.isEmpty(billingDetails) && (
                <div>
                    <FormSectionTitle title="Billing Details" />
                    <LabelAdornment
                        label="Account Holder:"
                        value={billingDetails.account_holder_name}
                    />
                    <LabelAdornment
                        label="Account Number:"
                        value={`XXXXXX${billingDetails.account_number_ending}`}
                    />
                </div>
            )}
            <Typography variant="body1">
                <br />
                Any issues with your billing details? Please call us on {CONTACT.PHONE_NUMBER}
            </Typography>
        </div>
    );
};

export default BillingListContent;
