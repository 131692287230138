import React from 'react';
import { Mutation } from 'react-admin';
import ErrorButton from '../../Components/Buttons/ErrorButton';

import Button from '../../Components/Buttons/CustomButton';

const defaultProps = {
    label: '',
    resource: '',
    payload: {},
    options: {},
    variant: 'text',
    verifyProps: {},
};

const ReviewMutationButton = props => {
    const {
        label,
        resource,
        payload,
        options,
        variant,
        verifyProps,
    } = props;
    const generateButtonProps = (approve, record) => ({
        variant,
        color: 'tertiary',
        style: { minWidth: 101 },
        disabled: record.loading,
        onClick: approve,
    });

    return (
        <Mutation
            type="update"
            resource={resource}
            payload={payload}
            options={{ ...options }}
        >
            {label === 'Verify'
                ? (approve, record) => (
                      <Button
                          {...generateButtonProps(approve, record)}
                          {...verifyProps}
                      >
                          {label}
                      </Button>
                  )
                : (approve, record) => (
                      <ErrorButton {...generateButtonProps(approve, record)}>
                          {label}
                      </ErrorButton>
                  )}
        </Mutation>
    );
};

ReviewMutationButton.defaultProps = defaultProps;

export default ReviewMutationButton;
