import React from 'react';
import {
    TextField,
    DateField,
    EditButton,
    DeleteButton,
    ReferenceManyField,
    Datagrid,
    Responsive,
    SimpleList,
} from 'react-admin';
import { formatSimpleListDate } from '../../utils';

const ConditionalEndDate = ({ record, ...rest }) =>
    record && record.current ? (
        <span>Current</span>
    ) : (
        <DateField source="endDate" record={record} />
    );

const JobsGrid = props => (
    <ReferenceManyField {...props} label={null} reference="jobs" target="professional_id">
        <Responsive
            small={
                <SimpleList
                    primaryText={record => record.position}
                    secondaryText={record => record.company}
                    tertiaryText={record => `Start date: ${formatSimpleListDate(record.startDate)}`}
                />
            }
            medium={
                <Datagrid>
                    <TextField label="Position" source="position" />
                    <TextField label="Company" source="company" />
                    <DateField label="Start date" source="startDate" />
                    <ConditionalEndDate label="End date" />
                    <EditButton color="secondary" />
                    <DeleteButton redirect={false} />
                </Datagrid>
            }
        />
    </ReferenceManyField>
);

export default JobsGrid;
