import React from 'react';
import PropTypes from 'prop-types';
import ScoreAvatar from '../../Components/ConsumerReactComponents/ScoreAvatar';

import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { CallToActionButton } from '../../Components/Buttons/Buttons';
import * as analytics from '../../analytics';

const propTypes = {
    avatar: PropTypes.string.isRequired,
    title: PropTypes.string,
};

const styles = theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            textAlign: 'center',
        },
    },
    avatarWrapper: {
        paddingRight: theme.spacing(2),

        '& > div:first-child': {
            margin: 0,
        },

        [theme.breakpoints.down('sm')]: {
            paddingRight: 0,
        },
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
        },
    },
});

const FeesInsightsHeaderBadge = ({ classes, feeReportUrl, ...props }) => {
    return (
        <Card className={classes.container}>
            <div className={classes.avatarWrapper}>
                <ScoreAvatar xs inlineBlock ratingValue={95} ratingMax={100} image={props.avatar} />
            </div>
            <div className={classes.contentWrapper}>
                {props.title && (
                    <>
                        <Typography variant="body1" color="primary" component="b">
                            {props.title}
                        </Typography>
                        <CallToActionButton
                            href={feeReportUrl}
                            onClickAction={() => {
                                analytics.track('Clicked Get Fee Report', {
                                    category: 'Fee Insights',
                                });
                            }}
                        >
                            Get Shareable Fee Report
                        </CallToActionButton>
                    </>
                )}
            </div>
        </Card>
    );
};

FeesInsightsHeaderBadge.propTypes = propTypes;

export default withStyles(styles)(FeesInsightsHeaderBadge);
