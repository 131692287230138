import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Datagrid,
    DateField,
    TextField,
    Button,
    Responsive,
    SimpleList,
    FunctionField,
} from 'react-admin';
import { Box, MenuItem, Select } from '@material-ui/core';
import ListWrapper from '../../Dashboard/Elevation/ListWrapper';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import dataProvider from '../../dataProvider';

const useStyles = makeStyles(theme => ({
    list: {
        marginTop: '1rem',
        marginBottom: '10rem',
    },
}));

const AnswersTable = props => {
    const { driver, goal } = props;
    const classes = useStyles();

    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);

    const [driverId, setDriverId] = useState(0);
    const [selectedFilter, setSelectedFilters] = useState(0);
    const [filterExtra, setFilterExtra] = useState({
        source: 'choice_id',
        defaultValue: 0,
        name: 'Filter by answer',
    });
    const [filters, setFilters] = useState([]);

    useEffect(() => {
        if (driverId !== driver || (!driverId && goal)) {
            setDriverId(driver);
            resetPage();

            dataProvider('GET_LIST', 'elevation/answers/answer-choices', {
                filter: {
                    driver_id: driver,
                    goal_id: goal,
                },
            }).then(res => {
                if (res.data) {
                    setFilters(res.data.choices);
                    setFilterExtra(res.data.extra);
                    setSelectedFilters(res.data.extra.defaultValue);
                    if (queryParams.get('answer')) {
                        res.data.choices.map((choice) =>{
                            if (choice.id == queryParams.get('answer')) {
                                setSelectedFilters(queryParams.get('answer'))
                            }
                        })
                    }
                }
            });
        }
    }, [driver, goal]);

    const resetPage = () => {
        if (queryParams.has('page')) {
            queryParams.set('page', 1);
            history.replace({
                search: queryParams.toString(),
            });
        }
    };

    const CustomButton = ({ record }) => {
        if (record) {
            const { review_type, review_id } = record;
            const type = review_type === 'first_impression' ? '/first-impressions/' : '/reviews/';
            const pageType = review_type === 'first_impression' ? '/show' : '';
            const label =
                review_type === 'first_impression' ? 'SEE FIRST IMPRESSION' : 'SEE FULL REVIEW';
            const buttonHref = type + review_id + pageType;
            return <Button color="secondary" label={label} href={buttonHref} />;
        }
        return null;
    };

    const selectChangeHandler = e => {
        resetPage();
        setSelectedFilters(e.target.value);
        queryParams.set('answer', e.target.value);
        history.push({ search: queryParams.toString() });
    };

    const CustomFilters = () => {
        return (
            <Box>
                <Select
                    variant="outlined"
                    value={selectedFilter}
                    onChange={selectChangeHandler}>
                    <MenuItem key={filterExtra.defaultValue} value={filterExtra.defaultValue}>
                        {filterExtra.name}
                    </MenuItem>
                    {filters.map(choice => (
                        <MenuItem key={choice.id} value={choice.id}>
                            {choice.text}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
        );
    };

    const getFilters = () => {
        const filters = {
            driver_id: driverId,
            goal_id: goal,
            ...(filterExtra.source == 'choice_id' && { choice_id: selectedFilter }),
            ...(filterExtra.source == 'risk' && selectedFilter !== 2 && { risk: selectedFilter }),
        };
        return { ...filters };
    };

    return (
        <Box className={classes.list}>
            <ListWrapper
                exporter={false}
                basePath="elevation/answers"
                resource="elevation/answers"
                filter={getFilters()}
                actions={<CustomFilters />}
                sort={{field:'review_date', order: 'DESC'}}
            >
                <Box className={classes.cardRoot}>
                    <Responsive
                        small={
                            <SimpleList
                                primaryText={record => record.customer_name}
                                secondaryText={record => record.answer}
                            />
                        }
                        medium={
                            <Datagrid>
                                <DateField label="Review date" source="review_date" />
                                <TextField label="Client" source="customer_name" />
                                {filterExtra.source == 'choice_id' && <TextField label="Question" source="label_template" /> }
                                {filterExtra.source == 'choice_id' && <TextField label="Answer(s)" source="answer" />}
                                {filterExtra.source == 'risk' && (
                                    <FunctionField
                                        label="Does this review show markers of risk?"
                                        render={record => (parseInt(record.risk) ? 'No' : 'Yes')}
                                    />
                                )}
                                <CustomButton />
                            </Datagrid>
                        }
                    />
                </Box>
            </ListWrapper>
        </Box>
    );
};

export default AnswersTable;
