import React from 'react';
import _ from 'lodash';
import { Query } from 'react-admin';
import { InView } from 'react-intersection-observer';

import Skeleton from '@material-ui/lab/Skeleton';
import withStyles from '@material-ui/core/styles/withStyles';
import CountUpNumber from '../../../Components/Typography/CountUpNumber';

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        height: 32,
    },
    barWrapper: {
        flex: 1,
        marginRight: theme.spacing(3),
        borderRadius: 20,
        overflow: 'hidden',
        background: theme.palette.tertiary.light,
        height: '100%',
    },
    bar: {
        height: '100%',
        background: `linear-gradient(to right, #022D4F, #4BC1CA)`,
        transition: 'ease all 1.5s',
    },
    text: {
        padding: 0,
        margin: 0,
    },
    loadingWrapper: {
        flex: 1,
    },
    loading: {},
});

const ReputationProgressBar = ({ classes }) => {
    return (
        <Query type="getOne" resource="dashboard/reputation-score">
            {({ data, loading }) => {
                const score = _.get(data, 'reputation_score', 0);

                return (
                    <div className={classes.root}>
                        {loading ? (
                            <div className={classes.loadingWrapper}>
                                <Skeleton
                                    animation="pulse"
                                    className={classes.loading}
                                    height={64}
                                />
                            </div>
                        ) : (
                            <>
                                <div className={classes.barWrapper}>
                                    <InView>
                                        {({ inView, ref }) => (
                                            <div
                                                ref={ref}
                                                className={classes.bar}
                                                style={{
                                                    width: inView ? `${score}%` : 0,
                                                }}
                                            />
                                        )}
                                    </InView>
                                </div>
                                <CountUpNumber
                                    value={score}
                                    formatValue={value => `${value} %`}
                                    color="green"
                                />
                            </>
                        )}
                    </div>
                );
            }}
        </Query>
    );
};

export default withStyles(styles)(ReputationProgressBar);
