import React, { useState } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { showNotification, useRefresh } from 'react-admin';
import { useFormState } from 'react-final-form';
import DialogBase from '../../Components/Dialogs/DialogBase';
import ErrorButton from '../../Components/Buttons/ErrorButton';
import { Typography } from '@material-ui/core';
import dataProvider from '../../dataProvider';

const DialogFormSubmit = ({ feeTypeToDelete }) => {
    const [showDialog, setShowDialog] = useState(false);
    const dispatch = useDispatch();
    const refresh = useRefresh();
    const { values } = useFormState();

    const handleSave = async () => {
        const content = _.get(values, 'content');

        if (!content) return;

        try {
            if (content.investment) {
                await dataProvider('DELETE', 'fees', { data: { id: content.investment.id } });
            }

            if (content.financialPlanning) {
                await dataProvider('DELETE', 'fees', {
                    data: { id: content.financialPlanning.id },
                });
            }

            refresh();
        } catch {
            dispatch(showNotification('ra.notification.bad_item', 'warning'));
        }

        handleClose();
    };

    const handleClose = () => {
        setShowDialog(false);
    };

    return (
        <>
            <ErrorButton
                onClick={() => setShowDialog(true)}
                color="secondary"
                disabled={!values.content}
            >
                Remove this fee type
            </ErrorButton>
            <DialogBase
                open={showDialog}
                onClose={handleClose}
                ErrorButtonProps={{
                    label: 'Cancel',
                    onClick: handleClose,
                }}
                SaveButtonProps={{
                    label: 'OK, remove these fees',
                    onClick: handleSave,
                }}
                title="Confirm"
                message={
                    <Typography>
                        As you added your <b>Implementation & Ongoing Service Fees</b> fees in
                        combination with <b>Financial Planning</b> fees, your {feeTypeToDelete} fees
                        will also be removed.
                    </Typography>
                }
            />
        </>
    );
};

export default DialogFormSubmit;
