import React from 'react';
import SkeletonWrapper from '../../../Components/SkeletonWrapper';

import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    root: {
        minHeight: 200,
        marginBottom: theme.spacing(1),
    },
    rankWrapper: {
        textAlign: 'center',

        '& > span': {
            margin: '0 auto',
        },
    },
    rank: {
        fontSize: theme.spacing(8),
        color: theme.palette.error.main,
    },
});

const DashboardCardRank = ({ rank, content, loading, classes }) => {
    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} className={classes.rankWrapper}>
                <SkeletonWrapper loading={loading} height={80} width={80}>
                    <Typography className={classes.rank}>
                        <b>{rank}</b>
                    </Typography>
                </SkeletonWrapper>
            </Grid>
            <Grid item xs={12}>
                {loading ? (
                    ['', ''].map((_, i) => (
                        <Skeleton width={'100%'} height={30} key={i} />
                    ))
                ) : (
                    <Typography
                        dangerouslySetInnerHTML={{ __html: content }}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(DashboardCardRank);
