import React from 'react';
import { SimpleForm, required } from 'react-admin';
import { connect } from 'react-redux';
import EnquiryTitle from './Components/EnquiryTitle';
import FormSelectInput from '../Components/Inputs/Form/FormSelectInput';
import PreventUndoEdit from '../Components/PreventUndoEdit';
import EnquiriesEditToolbar from './Components/EnquiriesEditToolbar';
import EnquiriesReportCriteriaList from './Components/EnquiriesReportCriteriaList';
import EnquiriesReportActions from './Components/EnquiriesReportActions';
import FormLongTextInput from '../Components/Inputs/Form/FormLongTextInput';
import { ENQUIRY_REJECT_REASON_CHOICES } from '../constants';

const EnquiriesReport = ({ professionalId, ...props }) => {
    const enquiriesUrl = `/enquiries-list/${professionalId}`;

    return (
        <PreventUndoEdit
            id={props.match.params.id}
            resource="enquiry/report"
            {...props}
            actions={<EnquiriesReportActions id={props.match.params.id} />}
        >
            <SimpleForm
                {...props}
                redirect={enquiriesUrl}
                toolbar={<EnquiriesEditToolbar {...props} />}
            >
                <EnquiryTitle report />
                <FormSelectInput
                    validate={[required()]}
                    label="Select reason for refund"
                    source="enquiry_reject_reason.gka"
                    choices={ENQUIRY_REJECT_REASON_CHOICES}
                />
                <FormLongTextInput
                    label="Further information"
                    source="report_text"
                    validate={[required()]}
                />
                <EnquiriesReportCriteriaList criteria="colleague" />
                <EnquiriesReportCriteriaList criteria="poor_contact_details" />
            </SimpleForm>
        </PreventUndoEdit>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        professionalId: basicInfo.id,
    };
};

export default connect(mapStateToProps)(EnquiriesReport);
