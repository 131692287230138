import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PageTabs from '../AppLayout/PageTabs';
import { VERTICAL } from '../constants';

const { FINANCIAL_ADVISER } = VERTICAL;

export function getFirmTabs(vertical, id, firmId, isFirmAdmin = false) {

    const tabs = {
        details: {
            label: 'Firm Details',
            link: `/professional/firm/${id}`,
        },
        edit: {
            label: 'Edit Firm',
            link: `/firm/${firmId}`,
        },
        advisers: {
            label: 'Linked Advisers',
            link: '/firm/linked-advisers',
        },
        tools: {
            label: 'Firm Reputation Tools',
            link: `/firm/reputation`,
        },
        reviews: {
            label: 'Reviews For All Advisers',
            link: `/firm-reviews`,
        },
    };

    if (vertical === FINANCIAL_ADVISER) {
        if (isFirmAdmin) {
            return [
                tabs.details,
                tabs.edit,
                tabs.advisers,
                tabs.tools,
                tabs.reviews,
            ];
        } else {
            return [tabs.details];
        }
    }

    return [];
}

const FirmTabs = props => {
    const { vertical, isFirmAdmin, professionalId, firmId } = props;
    const routes = getFirmTabs(vertical, professionalId, firmId, isFirmAdmin);

    if (!routes.length) return null;

    return <PageTabs routes={routes} />;
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        professionalId: _.get(basicInfo, 'id'),
        firmId: _.get(basicInfo, 'firm_id'),
        vertical: _.get(basicInfo, ['permissions', 'vertical'], ''),
        isFirmAdmin: _.get(basicInfo, ['permissions', 'is_firm_admin'], false),
    };
};

export default connect(mapStateToProps)(FirmTabs);
