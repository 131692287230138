import React from 'react';
import {FormSectionTitle} from "../../Components/Typography/Headlines";

const StepsTitle = ({ title }) => {
    return (
        <div>
            <FormSectionTitle title={title} style={{fontSize:`24px`}} />
        </div>
    );
};

export default StepsTitle;