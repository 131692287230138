import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import POPUP from '../constants/popup';
import { useRenderDialogDelay } from '../Components/Dialogs/DialogDecorators';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import TAndCDialogModal from '../TAndC/TAndCDialogModal'
import {isImpersonator} from "../utils";

const defaultProps = {
    professionalId: null,
    popup: null,
};

// Include all dialog components in here to ensure only one appears at any given time
const AppDialog = ({ professionalId, popup, width, isAutoBuilt }) => {
    const { visible } = useRenderDialogDelay(professionalId, popup);

    if (!visible) {
        return null;
    }

    if (isWidthDown('xs', width)) {
        switch (popup) {
            case POPUP.T_AND_C:
                if (!isImpersonator()) {
                    return <TAndCDialogModal isAutoBuilt={isAutoBuilt} professionalId={professionalId}/>;
                }
                return null;
            default:
                return null;    
        }
    }

    switch (popup) {
        case POPUP.T_AND_C:
            if (!isImpersonator()) {
                return <TAndCDialogModal isAutoBuilt={isAutoBuilt} professionalId={professionalId} />;
            }
            return null;
        default:
            return null;
    }
};

AppDialog.defaultProps = defaultProps;

const mapStateToProps = ({ basicInfo }) => {
    return {
        professionalId: _.get(basicInfo, 'id'),
        popup: _.get(basicInfo, 'permissions.popup_name'),
        isAutoBuilt: _.get(basicInfo, 'is_auto_built'),
    };
};

export default connect(mapStateToProps)(withWidth()(AppDialog));
