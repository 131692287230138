import React from 'react'
import { SimpleForm } from 'react-admin';
import { Typography } from '@material-ui/core';
import FormToolbar from '../../Components/Toolbars/FormToolbar';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    wrapper: {
        '& .MuiTypography-body1': {
            fontSize: "14px",
        }
    },
    form: {
        '& > div': {
            padding: 0
        }
    },
    formToolbar: {
        marginTop: 0,
        display: 'flex',
        justifyContent: 'center',
        '& .MuiButton-contained': {
            width: "50%",
        },
        spacer: {
            display: "none"
        }


    },
    text: {
        marginBottom: "20px",
    },
});

const ReviewReportSent = ({
    classes,
    save = () => { }
}) => {

    return (
        <div className={classes.wrapper}>
            <SimpleForm
                className={classes.form}
                save={save}
                toolbar={<FormToolbar label="OK, back to reviews" className={classes.formToolbar} />}
            >
                <Typography className={classes.text} ><strong>Thanks, this review has been reported!</strong></Typography>
                <Typography className={classes.text}>Our team will now review your request.</Typography>
                <Typography className={classes.text}>The review will not be visible on your profile while we complete this.</Typography>
                <Typography className={classes.text}>We will come back to you within 5 business days with an outcome. If you have any questions on this, please contact us on customer_service@vouchedfor.co.uk.</Typography>

            </SimpleForm>
        </div>

    );
};


export default withStyles(styles)(ReviewReportSent);

