import React from 'react';

import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = ({ spacing }) => ({
    container: {
        maxWidth: spacing.unit * 225,
        alignItems: 'stretch',
        marginTop: spacing.unit * 2,
        marginLeft: 2,
    },
});

const ReputationToolGridLayout = ({ classes, children }) => {
    return (
        <Grid container spacing={3} className={classes.container}>
            {children}
        </Grid>
    );
};

export default withStyles(styles)(ReputationToolGridLayout);
