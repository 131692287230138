import React, { useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { useQuery, usePermissions } from 'react-admin';
import { showSidebar } from '../../state/actions';
import * as analytics from '../../analytics';

import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import PrivateDashboardStats from './PrivateDashboardStats';
import PublicProfileUpgrade from './PublicProfileUpgrade';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    personName: {
        marginTop: 0,
        color: theme.palette.secondary.main,

        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        },
    },
}));

const PrivateDashboard = props => {
    const { showSidebar } = props;

    const { data } = useQuery({
        type: 'getOne',
        resource: 'dashboard/private',
    });
    const { permissions } = usePermissions();
    const classes = useStyles();
    useEffect(() => {
        showSidebar();

        analytics.track('Viewed dashboard', { category: 'Dashboard card' });
    }, [showSidebar]);

    return (
        <>
            {data && (
                <Card>
                    <CardContent>
                        <Typography className={classes.personName} component="h2" variant="h4">
                            <b>{`Welcome, ${data.first_name}`}</b>
                        </Typography>
                        <PrivateDashboardStats data={data} />
                        {_.includes(permissions?.firm_features, 'firm_opted_out_of_accounts_upsell') ? null : <PublicProfileUpgrade /> }
                    </CardContent>
                </Card>
            )}
        </>
    );
};

const mapStateToProps = () => {
    return {
        showGuideActionsCard: false,
    };
};

const actions = {
    showSidebar,
};

export default connect(
    mapStateToProps,
    actions
)(PrivateDashboard);
