import React from 'react';
import cn from 'classnames';
import ReviewInviteEmailTemplate from '../Content/ReviewInviteEmailTemplate';
import { makeStyles } from '@material-ui/core/styles';
import { useSetReviewInvitationTemplateCopy } from '../../state/hooks';

const useStyles = makeStyles(theme => ({
    message: {
        '& > div > div': {
            marginRight: theme.spacing(3),

            [theme.breakpoints.only('xs')]: {
                marginRight: 0,
            },
        },
    },
    textarea: {
        '& .MuiInputBase-root': {
            borderRadius: 4,
            backgroundColor: theme.palette.primary.contrastText,
        },
    },
}));

const FirstInvitationEmailTemplate = () => {
    const classes = useStyles();

    useSetReviewInvitationTemplateCopy('review');

    return (
        <ReviewInviteEmailTemplate
            inputProps={{
                className: cn(classes.message, classes.textarea),
            }}
        />
    );
};

export default FirstInvitationEmailTemplate;
