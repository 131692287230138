import React from 'react';
import ReviewPageHeader from './Content/ReviewPageHeader';
import AppPageWrapper from '../AppLayout/AppPageWrapper';


const ReviewPageWrapper = props => {
    return (
        <AppPageWrapper
            header={<ReviewPageHeader />}
            {...props}
        />
    );
};

export default ReviewPageWrapper;
