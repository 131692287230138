import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DialogBase from './DialogBase';
import { useRefresh, showNotification } from 'react-admin';
import { useDispatch } from 'react-redux';
import Button from '../Buttons/CustomButton';
import dataProvider from '../../dataProvider';

const propTypes = {
    /**
     * Show or hide the dialog
     */
    open: PropTypes.bool,
    /**
     * The content of the dialog modal
     */
    message: PropTypes.node,
    /**
     * The label for the button which opens the dialog
     */
    buttonLabel: PropTypes.string,
    /**
     * The state of the button which opens the dialog
     */
    disabled: PropTypes.bool,
    /**
     * Form values (can be supplied via useFormState)
     */
    values: PropTypes.object,
    /**
     * dataProvider resource
     */
    resource: PropTypes.string,
    /**
     * dataProvider type
     */
    type: PropTypes.string,
    /**
     * Called after a successful dataProvider request
     */
    handleSuccess: PropTypes.func,
    /**
     * Called after a failed dataProvider request
     */
    handleError: PropTypes.func,
    /**
     * The label for the button in the dialog
     */
    saveButtonLabel: PropTypes.string,
};

const DialogFormSubmit = ({
    values = {},
    type = '',
    resource = '',
    handleSuccess = () => {},
    handleError = () => {},
    saveButtonLabel = 'Confirm',
    buttonLabel = 'Submit',
    disabled = false,
    open = false,
    SaveButtonProps = {},
    ...rest
}) => {
    const [showDialog, setShowDialog] = useState(open);
    const dispatch = useDispatch();
    const refresh = useRefresh();

    useEffect(() => {
        setShowDialog(open);
    }, [open]);

    const handleSave = async () => {
        const payload = {
            data: values,
        };

        if (values.id) {
            payload.id = values.id;
        }

        try {
            await dataProvider(type, resource, payload);

            refresh();

            handleSuccess();
        } catch {
            dispatch(showNotification('ra.notification.bad_item', 'warning'));

            handleError();
        }

        handleClose();
    };

    const handleClose = () => {
        setShowDialog(false);
    };

    return (
        <>
            <Button
                onClick={() => setShowDialog(true)}
                variant="contained"
                color="tertiary"
                disabled={disabled}
            >
                {buttonLabel}
            </Button>
            <DialogBase
                open={showDialog}
                onClose={handleClose}
                ErrorButtonProps={{
                    label: 'Cancel',
                    onClick: handleClose,
                }}
                SaveButtonProps={{
                    label: saveButtonLabel,
                    onClick: handleSave,
                    ...SaveButtonProps,
                }}
                {...rest}
            />
        </>
    );
};

DialogFormSubmit.propTypes = propTypes;

export default DialogFormSubmit;
