import React from 'react';
import AppLayoutShow from '../AppLayout/AppLayoutShow';
import PageActions from '../AppLayout/PageActions';
import { CONTACT } from '../constants';

import Button from '../Components/Buttons/CustomButton';
import Typography from '@material-ui/core/Typography';

const ThankYou = () => {
    return (
        <AppLayoutShow header={<PageActions title="Thank You" />}>
            <Typography>
                We've successfully received your request and will be in touch shortly.
            </Typography>
            <Typography>
                If you have any questions in the meantime, please call us on {CONTACT.PHONE_NUMBER}.
            </Typography>
            <br />
            <Button variant="contained" color="tertiary" href="/">
                Go to Dashboard
            </Button>
        </AppLayoutShow>
    );
};

export default ThankYou;

