import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { FormDataConsumer, BooleanInput, required, minLength, maxLength, number } from 'react-admin';
import { FormControlLabel, Typography, Checkbox, makeStyles } from '@material-ui/core';
import BasicProfileFormDivider from './BasicProfileFormDivider';
import FormTextInput from '../../Components/Inputs/Form/FormTextInput';
import { useForm } from 'react-final-form';

const useStyles = makeStyles((theme) => ({
    fcaIdsRow: {
        display: 'flex',

        [theme.breakpoints.only('xs')]: {
            flexDirection: 'column',
        },
    },
    noFcaIdFormControl: {
        marginBottom: theme.spacing(2),
    },
    fcaIdErrorStyle: {
        display: 'block',
        marginBottom: 20,
    },
}));

const BasicProfileFormFinancialAdviserFields = (props) => {
    const {
        fcaIdError,
        checkedFirmFallsUnderSMCR,
        handleFirmFallsUnderSMCRCheckboxChange,
        lookupFirmName,
        isFinancialCoach,
    } = props;
    const classes = useStyles();
    const form = useForm();

    const [firmFcaIdChecked, setFirmFcaIdChecked] = useState(false);

    useEffect(() => {
        const localStorageFirmFcaIdIsChecked = JSON.parse(
            sessionStorage.getItem('firm_fca_id_checked')
        );
        if(localStorageFirmFcaIdIsChecked) {
            setFirmFcaIdChecked(Boolean(localStorageFirmFcaIdIsChecked));
        }
    }, []);

    const getValidation = () => {
        if (checkedFirmFallsUnderSMCR) {
            return null;
        }

        return [required()];
    };

    const handleFirmFcaIdChange = () =>{
        const nextValue = !firmFcaIdChecked;

        setFirmFcaIdChecked(nextValue);

        form.change('no_firm_fca_id', nextValue);
        form.change('attributes.financial_adviser_firm_fca.value', null);

        sessionStorage.setItem('firm_fca_id_checked', nextValue);
    };

    const getValidationForFirm = () => {
        if (firmFcaIdChecked && isFinancialCoach) {
            return null;
        }

        return [required(), minLength(6, "This field must be at least 6 characters long"), maxLength(7, "This field must be 7 characters long"), number("This field must only contain numbers") ];
    };

    return (
        <>
            <BasicProfileFormDivider style={{ marginBottom: 24 }} />
                <div className={classes.noFcaIdFormControl}>
                    <FormControlLabel
                        label="I don't have a personal FCA ID"
                        control={
                            <Checkbox
                                checked={checkedFirmFallsUnderSMCR}
                                onChange={handleFirmFallsUnderSMCRCheckboxChange}
                                name="firmFallsUnderSMCR"
                                color="secondary"
                            />
                        }
                    />
                    <BooleanInput
                        source="no_personal_fca_id"
                        style={{ display: 'none' }}
                        defaultValue={false}
                    />
                </div>
            {isFinancialCoach && (
            <div className={classes.noFcaIdFormControl}>
                <FormControlLabel
                    label="I don't have a Firm FCA ID"
                    control={
                        <Checkbox
                            checked={firmFcaIdChecked}
                            onChange={handleFirmFcaIdChange}
                            name="firmFallsUnderSMCR"
                            color="secondary"
                        />
                    }
                />
                <BooleanInput
                    source="no_firm_fca_id"
                    style={{ display: 'none' }}
                    defaultValue={false}
                />
            </div>
            )}
            <div className={classes.fcaIdsRow}>
                    <div className={classes.fcaIdErrorStyle}>
                        <FormDataConsumer>
                            {({ formData }) => {
                                return (
                                    <FormTextInput
                                        inline
                                        disabled={_.get(formData, 'no_personal_fca_id')}
                                        validate={getValidation()}
                                        key="personal_fca"
                                        label="Personal FCA ID"
                                        source="attributes.financial_adviser_personal_fca.value"
                                        defaultValue=""
                                    />
                                );
                            }}
                        </FormDataConsumer>
                        {fcaIdError && (
                            <Typography variant="body2" color="error">
                                {fcaIdError}
                            </Typography>
                        )}
                    </div>
                <FormTextInput
                    validate={getValidationForFirm()}
                    key="firm_fca"
                    label="Firm FCA ID"
                    onBlur={lookupFirmName}
                    source="attributes.financial_adviser_firm_fca.value"
                    defaultValue=""
                />
            </div>
        </>
    );
};

const mapStateToProps = ({ basicInfo, form }) => {
    return {
        fcaIdError: _.get(form, 'error.fca_id_error'),
        isFinancialCoach: _.get(basicInfo, 'permissions.coach_mode'),
    };
};

export default connect(mapStateToProps)(BasicProfileFormFinancialAdviserFields);
