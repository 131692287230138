import React from 'react';
import { connect } from 'react-redux';
import * as analytics from '../../../analytics';

import Button from '@material-ui/core/Button';
import CheckBoxOutline from '@material-ui/icons/CheckBoxOutlineBlank';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

function getContent(label, professionalId) {
    const map = {
        'Keep getting reviews': {
            url: '/invite-reviews',
            analyticsAction: 'Get reviews',
        },
        'Use an updated reputation tool': {
            url: '/reputation',
            analyticsAction: 'Use rep tool',
        },
        'Upgrade to use reputation tools': {
            url: `/account-settings/${professionalId}`,
            analyticsAction: null,
        },
        'Complete your profile': {
            url: `/professional/about/${professionalId}`,
            analyticsAction: 'Complete profile',
        },
    };

    return map[label];
}

const styles = theme => ({
    root: {
        display: 'flex',
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        color: theme.palette.error.main,

        '&:hover': {
            backgroundColor: 'rgba(211, 91, 131, 0.08)',
        },

        [theme.breakpoints.down('sm')]: {
            marginLeft: -12,
        },
    },
    text: {
        marginLeft: theme.spacing(1),
        textAlign: 'left',
    },
});

const ReputationToDoAction = ({ label, classes, professionalId }) => {
    const { url, analyticsAction } = getContent(label, professionalId);

    const handleClick = () => {
        analytics.track(analyticsAction, {
            category: 'dashboard rep score',
        });
    };

    return (
        <Button href={url} onClick={handleClick} className={classes.button} key={label}>
            <CheckBoxOutline />
            <Typography className={classes.text} color="inherit">
                {label}
            </Typography>
        </Button>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        professionalId: basicInfo.id,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(ReputationToDoAction));
