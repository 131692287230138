import React from 'react';
import _ from 'lodash';
import { Query } from 'react-admin';
import DashboardCardBase from '../CardComponents/DashboardCardBase';
import DashboardCardTitle from '../CardComponents/DashboardCardTitle';
import DashboardCardSubTitle from '../CardComponents/DashboardCardSubTitle';
import ReputationToDoAction from '../CardComponents/ReputationToDoAction';
import ReputationDoneAction from '../CardComponents/ReputationDoneAction';
import ReputationProgressBar from '../CardComponents/ReputationProgressBar';
import ReputationActionLoading from '../CardComponents/ReputationActionLoading';

import Grid from '@material-ui/core/Grid';
import { ExternalLink } from '../../../Components/Links';
import { SUPPORT_URL } from '../../../constants';

const DashboardReputationProgressCard = () => {
    return (
        <Query type="getOne" resource="dashboard/key-actions">
            {({ data, loading }) => (
                <DashboardCardBase
                    header={
                        <>
                            <DashboardCardTitle>Your Reputation Score</DashboardCardTitle>
                            <DashboardCardSubTitle>
                                Ways to improve your profile (only you can see this score!)
                            </DashboardCardSubTitle>
                        </>
                    }
                    tooltip={
                        <>
                            Your reputation score helps you see where you could get more value from
                            VouchedFor. It includes profile completeness, review number and recency,
                            as well as use and recency of reputation tools. This score is visible
                            only to you and does not affect your VouchedFor search ranking.&nbsp;
                            <ExternalLink href={SUPPORT_URL.ACCOUNT.REP_SCORE}>
                                Learn more
                            </ExternalLink>
                        </>
                    }
                    body={
                        <>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <ReputationProgressBar />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {loading ? (
                                        <ReputationActionLoading />
                                    ) : (
                                        _.get(data, 'done_actions', []).map(label => (
                                            <ReputationDoneAction label={label} key={label} />
                                        ))
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {loading ? (
                                        <ReputationActionLoading />
                                    ) : (
                                        _.get(data, 'todo_actions', []).map(label => (
                                            <ReputationToDoAction label={label} key={label} />
                                        ))
                                    )}
                                </Grid>
                            </Grid>
                        </>
                    }
                />
            )}
        </Query>
    );
};

export default DashboardReputationProgressCard;
