import React, { useState } from 'react';
import { connect } from 'react-redux';
import { CONTACT, API_KEYS } from '../../constants';
import * as analytics from '../../analytics';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import DowngradeDialogModal from '../../Survey/DowngradeDialogModal';

const useStyles = makeStyles(theme => ({
    link: {
        cursor: "pointer",
        color: theme.palette.tertiary.main
    },
}));

const DowngradeText = ({ planGka }) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles()
    const triggerAnalytics = () => {
        analytics.track('Clicked robo-steve link', {
            category: 'Settings & Billing',
            label: planGka,
        });
    };

    const handleClick = () => {
        triggerAnalytics();
        setOpen(true);
    };

    if (planGka === 'unlimited') {
        return (
            <>
                <Typography variant="body1">
                    To change or cancel your membership, please
                    <span className={classes.link} onClick={handleClick}>{' click here '}</span>
                    or call our team on {`${CONTACT.PHONE_NUMBER}`}
                </Typography>
                <DowngradeDialogModal open={open} setOpen={setOpen} />
            </>
        );
    }

    if (planGka === 'verified') {
        return (
            <>
                <Typography variant="body1">
                    To change or cancel your membership,
                    <span className={classes.link} onClick={handleClick}>{' click here '}</span>
                    or call our team on {`${CONTACT.PHONE_NUMBER}`}. To discuss applying to change
                    your plan to the Unlimited plan, please contact our team on{' '}
                    {`${CONTACT.PHONE_NUMBER}`}
                </Typography>
                <DowngradeDialogModal open={open} setOpen={setOpen} />
            </>
        );
    }

    return null;
};

const mapStateToProps = ({ basicInfo }) => {
    const firstName = basicInfo.first_name;
    const plan = basicInfo.plan;
    const professionalId = basicInfo.id;

    return {
        downgradeUrl: `${API_KEYS.downgrade_form_url}?name=${firstName}&plan=${plan}&id=${professionalId}`,
    };
};

export default connect(mapStateToProps)(DowngradeText);
