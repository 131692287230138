import React from 'react';
import { connect } from 'react-redux';
import { RadioButtonGroupInput, SimpleForm, TopToolbar } from 'react-admin';
import { FormSectionTitle } from '../Components/Typography/Headlines';
import PreventUndoEdit from '../Components/PreventUndoEdit';
import BackButton from '../Components/Buttons/BackButton';

import { MEETING_LOCATIONS_OPTIONS } from '../constants/lists';
import FormLongTextInput from '../Components/Inputs/Form/FormLongTextInput';

const MeetingLocationsEdit = props => {
    const { dispatch, locationUrl, ...rest } = props;

    return (
        <PreventUndoEdit
            {...rest}
            actions={
                <TopToolbar>
                    <BackButton href={locationUrl} />
                </TopToolbar>
            }
        >
            <SimpleForm redirect={locationUrl}>
                <FormSectionTitle title="Edit Meeting Location" />
                <RadioButtonGroupInput
                    fullWidth
                    source="type"
                    choices={MEETING_LOCATIONS_OPTIONS}
                />
                <FormLongTextInput label="Description" source="description" />
            </SimpleForm>
        </PreventUndoEdit>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        locationUrl: `/professional/location/${basicInfo.id}`,
    };
};

export default connect(mapStateToProps)(MeetingLocationsEdit);
