import React from 'react';
import _ from 'lodash';
import { SimpleForm } from 'react-admin';
import { connect } from 'react-redux';
import FirmPageWrapper from './FirmPageWrapper';
import { FormSectionTitle } from '../Components/Typography/Headlines';
import FirmDetailsFormContent from './Components/FirmDetailsFormContent';
import { VERTICAL } from '../constants';

const FirmDetails = props => {
    const { dispatch, isFinancialAdviser, ...rest } = props;

    return (
        <FirmPageWrapper {...rest} variant="edit">
            <SimpleForm redirect={false}>
                {isFinancialAdviser && <FormSectionTitle title="Firm Details" />}
                <FirmDetailsFormContent isFinancialAdviser={isFinancialAdviser} />
            </SimpleForm>
        </FirmPageWrapper>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        isFinancialAdviser: _.get(basicInfo, 'permissions.vertical') === VERTICAL.FINANCIAL_ADVISER,
    };
};

export default connect(mapStateToProps)(FirmDetails);
