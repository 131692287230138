import React from 'react';
import _ from 'lodash';
import { useQuery } from 'react-admin';
import ViewEnquiryDetail from './Components/ViewEnquiryDetail';
import ViewEnquiryQualifyingDetails from './Components/ViewEnquiryQualifyingDetails';
import { connect } from 'react-redux';
import { Typography, Card } from '@material-ui/core';
import Button from '../Components/Buttons/CustomButton'
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '780px',
        margin: `${theme.spacing(3)}px auto`,
        textAlign: 'center',

        [theme.breakpoints.only('xs')]: {
            width: '100%',
        },
    },
    enquiryData: {
        margin: theme.spacing(3),
        textAlign: 'left',
    },
    sorryText: {
        margin: `${theme.spacing(2)}px 0`,
    },
    loadingText: {
        width: 420,
        height: 56,

        [theme.breakpoints.only('xs')]: {
            width: '100%',
        },
    },
    link: {
        textDecoration: 'none',
    },
}));

const getEnquirerFirstName = enquiry => {
    const fullName = _.get(enquiry, 'redundant_customer_name', '');

    return fullName.split(' ')[0];
};

const ViewEnquiry = props => {
    const token = _.get(props, 'match.params.token');
    const classes = useStyles();
    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: `enquiry/view/${token}`,
    });
    const qualifyingDetails = _.get(data, 'qualifying_details', {});

    if (error) {
        return null;
    }

    if (!loading && data && data.id === 'no-id') {
        return (
            <div className={classes.root}>
                <Typography variant="h4">Oh no! This link has expired</Typography>
                <Typography className={classes.sorryText}>
                    Sorry, this link has expired due to security reasons. <br />
                    {props.isLoggedIn
                        ? 'Click the button below to view your latest enquiries.'
                        : 'To view the enquiry, please log in.'}
                </Typography>
                <Button
                    color="tertiary"
                    variant="contained"
                    href={props.redirectUrl}
                    disabled={loading}
                >
                    {props.isLoggedIn ? 'View my enquiries' : 'Login to your account'}
                </Button>
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <Typography variant="h4">Details of this enquiry</Typography>
            <Card className={classes.enquiryData}>
                {loading ? (
                    <div>
                        {['', '', '', ''].map((row, i) => (
                            <Skeleton key={i} className={classes.loadingText} />
                        ))}
                    </div>
                ) : (
                    <div>
                        <ViewEnquiryDetail enquiry={data} />
                        {!_.isEmpty(qualifyingDetails) && (
                            <ViewEnquiryQualifyingDetails data={qualifyingDetails} />
                        )}
                        <Typography>
                            <b>{`Please contact ${getEnquirerFirstName(
                                data
                            )} as soon as possible. We'll ask them for their feedback in a couple of weeks.`}</b>
                        </Typography>
                        <Typography>
                            You have 3 working days to report any issues to us that meet our <a className={classes.link} target="_blank" href="https://support.vouchedfor.co.uk/article/102-enquiry-refund-policy">
                                refund criteria.
                            </a> For example, if you're unable to contact the enquirer after 3 attempts or can't offer the service they need.
                            Please attempt to contact the enquirer within 1 working day to establish if you can help them.
                        </Typography>
                        <Typography>
                            Please note, the information provided above is all that the enquirer has disclosed to us.
                            Please ensure you cover all other key information in your first contact with the customer.
                        </Typography>
                    </div>
                )}
            </Card>
            <Button color="tertiary" variant="contained" href={props.redirectUrl} disabled={loading}>
                Go to your account
            </Button>
        </div>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    const professionalId = _.get(basicInfo, 'id');
    const isLoggedIn = _.get(basicInfo, 'is_logged_in');

    return {
        isLoggedIn,
        redirectUrl: isLoggedIn ? `/enquiries-list/${professionalId}` : '/login',
    };
};

export default connect(mapStateToProps)(ViewEnquiry);
