import React from 'react';
import PropTypes from 'prop-types';
import CountUpNumber from '../Typography/CountUpNumber';

import { Card, Hidden, Typography, makeStyles } from '@material-ui/core';

const STATISTICS_LIMIT = 5;

const propTypes = {
    statistics: PropTypes.array,
    withCard: PropTypes.bool,
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        minHeight: theme.spacing(121),
        maxWidth: theme.spacing(180),
        margin: `${theme.spacing(5)}px auto`,
        width: `calc(100% - ${theme.spacing(10)}px)`,

        '& > div:last-child': {
            flex: 1,
        },

        [theme.breakpoints.down('xs')]: {
            paddingLeft: 0,
            paddingRight: 0,
            margin: theme.spacing(2),
            width: 'auto',
            minHeight: 'auto',
        },
    },
    sidebar: {
        backgroundColor: theme.palette.secondary.dark,
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        listStyle: 'none',
        minWidth: theme.spacing(30),
        padding: '98px 0 0',
        margin: 0,
    },
    item: {
        margin: `${theme.spacing(2)}px auto`,
        textAlign: 'center',
        maxWidth: theme.spacing(25),
    },
    value: {
        backgroundColor: theme.palette.primary.contrastText,
        margin: '0 auto',
        maxWidth: theme.spacing(15),
    },
    label: {
        color: theme.palette.primary.contrastText,
    },
    card: {
        width: '100%',
    },
}));

function getNumberColor(i) {
    const lookup = {
        0: 'blue',
        1: 'red',
        2: 'red',
        3: 'yellow',
        4: 'blue',
    };

    return lookup[i];
}

const FormWithSidebarStatistics = ({ children, withCard, statistics = [] }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Hidden smDown>
                <ul className={classes.sidebar}>
                    {statistics.slice(0, STATISTICS_LIMIT).map(({ label, value }, i) => (
                        <li className={classes.item} key={`${value} ${label}`}>
                            <div className={classes.value}>
                                <CountUpNumber color={getNumberColor(i)} value={value} />
                            </div>
                            <Typography className={classes.label}>{label}</Typography>
                        </li>
                    ))}
                </ul>
            </Hidden>
            {withCard ? <Card className={classes.card}>{children}</Card> : children}
        </div>
    );
};

FormWithSidebarStatistics.propTypes = propTypes;

export default FormWithSidebarStatistics;
