import About from './aboutMe';
import Qualifications from './qualifications';
import Experience from './experience';
import Services from './services';
import Location from './location';
import Disclaimer from './disclaimer';

export default {
    about: About,
    qualifications: Qualifications,
    experience: Experience,
    services: Services,
    location: Location,
    disclaimer: Disclaimer,
};
