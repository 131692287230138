import React from 'react';
import { connect } from 'react-redux';
import { RadioButtonGroupInput, Create, required, SimpleForm, TopToolbar } from 'react-admin';
import { FormSectionTitle } from '../Components/Typography/Headlines';
import BackButton from '../Components/Buttons/BackButton';

import { MEETING_LOCATIONS_OPTIONS } from '../constants/lists';
import FormLongTextInput from '../Components/Inputs/Form/FormLongTextInput';

const MeetingLocationsCreate = props => {
    const { dispatch, professionalId, exactActivePath, locationUrl, ...rest } = props;

    return (
        <Create
            title={null}
            {...rest}
            actions={
                <TopToolbar>
                    <BackButton href={locationUrl} />
                </TopToolbar>
            }
        >
            <SimpleForm redirect={locationUrl}>
                <FormSectionTitle title="Create Meeting location" />
                <RadioButtonGroupInput
                    fullWidth
                    source="type"
                    validate={[required()]}
                    choices={MEETING_LOCATIONS_OPTIONS}
                />
                <FormLongTextInput
                    label="Write a short description about this location"
                    source="description"
                    validate={[required()]}
                />
            </SimpleForm>
        </Create>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        locationUrl: `/professional/location/${basicInfo.id}`,
    };
};

export default connect(mapStateToProps)(MeetingLocationsCreate);
