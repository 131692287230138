import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import DriverSlider from './DriverSlider';
import { useTheme } from '@material-ui/core/styles';
import _ from 'lodash';
import ContentTooltip from '../../../Components/ConsumerReactComponents/ContentTooltip';

const useStyles = makeStyles(theme => ({
    wrapper: {
        position: 'relative',
    },
    driverStatistics: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    text: {
        color: theme.palette.secondary.dark,
        fontWeight: 'bold',
        cursor: "pointer",
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    reviews: {
        height: 200,
        width: 350,
        textAlign: 'center',
        borderRadius: '1rem',
        backgroundColor: theme.palette.secondary.main,
        display: 'flex',
        alignItems: 'center',
    },
    reviewsText: {
        color: theme.palette.tertiary.light,
        fontWeight: 'bold',
    },
    overlayWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: 2,
        pointerEvents: "none"
    },
    disabled: {
        filter: 'grayscale(1)',
        opacity: 0.4,
    }
}));

const DisplayDriverAverages = props => {
    const { title, data, type, compareToIndustry, disabled, tooltip, tooltipText, goal } = props;

    const classes = useStyles();

    const renderTitle = () => {
        return (
            <Typography
                className={`${classes.text} ${disabled && classes.disabled}`}
                variant="h4">
                {title}
            </Typography>
        )
    }
    return (
        <Box className={classes.wrapper}>
            { tooltip ? 
            <ContentTooltip title={tooltipText} placement="top-start" >
                {renderTitle()}
            </ContentTooltip> : renderTitle() }
            <Box className={`${classes.driverStatistics} ${disabled && classes.disabled}`}>
                {data.map(driver => {
                    return (
                        <DriverSlider
                            key={parseInt(driver.driver_id) + Math.random()}
                            averages={driver}
                            compareToIndustry={compareToIndustry}
                            type={type}
                            review_count={driver.review_count}
                            goal={goal}
                        />
                    );
                })}
            </Box>
        </Box>
    );
};

export default DisplayDriverAverages;
