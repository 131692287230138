import React from 'react';
import { SimpleForm } from 'react-admin';
import ReviewPageWrapper from './ReviewPageWrapper';
import ReviewInviteContent from './Invite/ReviewInviteContent';
import ReviewFAQs from './Content/ReviewFAQs';

const ReviewInvite = (props) => {
    return (
        <>
            <ReviewPageWrapper variant="simpleForm" {...props}>
                <SimpleForm redirect={false} toolbar={null}>
                    <ReviewInviteContent />
                </SimpleForm>
            </ReviewPageWrapper>
            <ReviewFAQs />
        </>
    );
};

export default ReviewInvite;
