import Card from '@material-ui/core/Card';
import * as React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStylesCustom = makeStyles(() => ({
    cardRoot: {
        padding: '1rem',
    },
}));

const EmptyList = ({ children, actions, bulkActionButtons, filters, title, ...props }) => {
    const classes = useStylesCustom();
    return (
        <Box className={classes.cardRoot}>
            <Card className={classes.cardRoot}>
                <Typography color="secondary" variant="body1">
                    No data was found
                </Typography>
            </Card>
        </Box>
    );
};

export default EmptyList;
