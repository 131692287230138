import React from 'react';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    publicQuestions: {
        maxWidth: 600,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            marginTop: "16px !important",
            marginBottom: "16px !important",
        }
    },
    privateQuestions: {
        marginTop: 0,
        marginBottom: 0,
        [theme.breakpoints.down('xs')]: {
            marginTop: "16px",
            marginBottom: "16px",
            '& .MuiTypography-root': {
                marginTop: "0px",
                marginBottom: "0px",
            }
        }
    },
}));

const RenderQuestions = ({item, publicQuestions = false}) => {
    const classes = useStyles();

    return (
        <div className={publicQuestions ? classes.publicQuestions : classes.privateQuestions}>
            <b>{item.first_impression ? item.question : item.label_template}</b>
            {Array.isArray(item.answer) ? (
                <ul>
                    {item.answer.map((answer, index) => (
                        <li key={index}><Typography>{answer.value}</Typography></li>
                    ))}
                </ul>
            ) : (
                <Typography>{item.answer}</Typography>
            )}
            <br />
        </div>
    )
};

export default RenderQuestions;