import React from 'react';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';

import StatisticTopTipBubble from './StatisticToptipBubble';
import StatisticColumnGooglers from './StatisticColumnGooglers';
import StatisticColumnClickers from './StatisticColumnClickers';
import StatisticColumnInfluenced from './StatisticColumnInfluenced';
import StatisticColumnReferrers from './StatisticColumnReferrers';
import StatisticColumnDirectEnquirers from './StatisticColumnDirectEnquirers';
import StatisticColumnIndirectEnquirers from './StatisticColumnIndirectEnquirers';
import { isIE11 } from '../../../utils';
import StatisticsColumnProspectIntention from './StatisticsColumnProspectIntention';

const getData = (data, key) => {
    const defaultData = {
        value: 0,
        content: '',
        visible: true,
    };

    return _.get(data, ['statistics', key], defaultData);
};

const StatisticsWrapperDesktop = ({ data, loading }) => {
    const scrollTo = elementId => e => {
        const influencedCard = document.getElementById(elementId);

        if (influencedCard) {
            if (isIE11()) {
                window.scrollTo(0, influencedCard.offsetTop);
            } else {
                window.scrollTo({
                    top: influencedCard.offsetTop,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        }
    };

    return (
        <>
            <Grid container spacing={1} alignItems="flex-end" justify="space-between">
                {Boolean(_.get(data, ['statistics', 'prospect_intention', 'toptip_content'], null)) ? (
                    <StatisticTopTipBubble
                        toptipContent={_.get(data, 'statistics.prospect_intention.toptip_content')}
                    />
                ) : (
                    <Grid item xs={12} sm={6} md={2} />
                )}
                {Boolean(_.get(data, ['statistics', 'googlers', 'toptip_content'], null)) ? (
                    <StatisticTopTipBubble
                        toptipContent={_.get(data, 'statistics.googlers.toptip_content')}
                    />
                ) : (
                    <Grid item xs={12} sm={6} md={2} />
                )}
                {Boolean(_.get(data, ['statistics', 'clickers', 'toptip_content'], null)) ? (
                    <StatisticTopTipBubble
                        toptipContent={_.get(data, 'statistics.clickers.toptip_content')}
                    />
                ) : (
                    <Grid item xs={12} sm={6} md={2} />
                )}
                {/*We don't have influence card*/}
                {/*{*/}
                {/*    Boolean(_.get(data, ['statistics', 'influenced', 'toptip_content'], null)) ?*/}
                {/*    <StatisticTopTipBubble toptipContent={_.get(data, 'statistics.influenced.toptip_content')}/>*/}
                {/*        :*/}
                {/*        <Grid item xs={12} sm={6} md={2}/>*/}
                {/*}*/}
                {/*We don't have influence card*/}
                {Boolean(_.get(data, ['statistics', 'referrers', 'toptip_content'], null)) ? (
                    <StatisticTopTipBubble
                        toptipContent={_.get(data, 'statistics.referrers.toptip_content')}
                    />
                ) : (
                    <Grid item xs={12} sm={6} md={2} />
                )}
                {Boolean(
                    _.get(data, ['statistics', 'direct_enquirers', 'toptip_content'], null)
                ) ? (
                    <StatisticTopTipBubble
                        toptipContent={_.get(data, 'statistics.direct_enquirers.toptip_content')}
                    />
                ) : (
                    <Grid item xs={12} sm={6} md={2} />
                )}
                {Boolean(
                    _.get(data, ['statistics', 'indirect_enquirers', 'toptip_content'], null)
                ) ? (
                    <StatisticTopTipBubble
                        toptipContent={_.get(data, 'statistics.indirect_enquirers.toptip_content')}
                    />
                ) : (
                    <Grid item xs={12} sm={6} md={2} />
                )}
            </Grid>
            <Grid container spacing={1} justify="space-between">
                {Boolean(_.get(data, 'statistics.prospect_intention')) && (
                    <StatisticsColumnProspectIntention loading={loading} data={getData(data, 'prospect_intention')} />
                )}
                {Boolean(_.get(data, 'statistics.googlers')) && (
                    <StatisticColumnGooglers loading={loading} data={getData(data, 'googlers')} />
                )}
                {Boolean(_.get(data, 'statistics.clickers')) && (
                    <StatisticColumnClickers loading={loading} data={getData(data, 'clickers')} />
                )}
                {Boolean(_.get(data, 'statistics.influenced')) && (
                    <StatisticColumnInfluenced
                        loading={loading}
                        data={getData(data, 'influenced')}
                        handleClick={scrollTo('latest-influenced-card')}
                    />
                )}
                {Boolean(_.get(data, 'statistics.referrers')) && (
                    <StatisticColumnReferrers
                        loading={loading}
                        data={getData(data, 'referrers')}
                        handleClick={scrollTo('latest-referrers-card')}
                    />
                )}
                {Boolean(_.get(data, 'statistics.direct_enquirers')) && (
                    <StatisticColumnDirectEnquirers
                        loading={loading}
                        data={getData(data, 'direct_enquirers')}
                    />
                )}
                {Boolean(_.get(data, 'statistics.indirect_enquirers')) && (
                    <StatisticColumnIndirectEnquirers
                        loading={loading}
                        data={getData(data, 'indirect_enquirers')}
                    />
                )}
            </Grid>
        </>
    );
};

export default StatisticsWrapperDesktop;
