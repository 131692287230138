import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import DisplayDriverAverages from './Components/DisplayDriverAverages';
import {
    DRIVER_AREAS_FOR_IMPROVEMENT,
    DRIVER_STRENGTHS,
    insufficientDriverData,
} from '../../utils';
import _ from 'lodash';
import { useTheme } from '@material-ui/core/styles';
import { GOALS } from '../../constants/elevation';

const DriverStatistics = ({ driverStatistics, compareToIndustry, classes, goal, serviceType }) => {
    const [statistics, setStatistics] = useState(null);
    const [disabled, setDisabled] = useState(null);
    const [missingNumbers, setMissingNumbers] = useState(null);

    const theme = useTheme();

    useEffect(() => {
        if (driverStatistics.extra_info) {
            setMissingNumbers(driverStatistics.extra_info);
        }

        setStatistics({
                strengths: _.isEmpty(driverStatistics.strengths) || driverStatistics.strengths.length === 0
                    ? DRIVER_STRENGTHS
                    : driverStatistics.strengths,
                areas_for_improvement: _.isEmpty(driverStatistics.areas_for_improvement) || driverStatistics.areas_for_improvement.length === 0
                    ? DRIVER_AREAS_FOR_IMPROVEMENT
                    : driverStatistics.areas_for_improvement,
            }
        );
        setDisabled({
            strengths: _.isEmpty(driverStatistics.strengths),
            areas_for_improvement: _.isEmpty(driverStatistics.areas_for_improvement),
        });
    }, [driverStatistics]);

    return (
        <Box className={classes}>
            {(missingNumbers?.nr_of_missing_drivers > 0 || missingNumbers?.min_reviews_to_show_data > 0) && (
                <Typography style={{ color: theme.palette.error.main, fontWeight: 'bold' }}>
                    {insufficientDriverData(missingNumbers)}
                </Typography>
            )}
            {statistics && (
                <>
                    <DisplayDriverAverages
                        title="Things you’re best at"
                        type="strengths"
                        data={statistics.strengths}
                        disabled={disabled.strengths}
                        compareToIndustry={compareToIndustry}
                        goal={goal}
                        serviceType={serviceType}
                    />
                    <DisplayDriverAverages
                        title={`Opportunities to improve ${GOALS[goal].toLowerCase()}`}
                        data={statistics.areas_for_improvement}
                        compareToIndustry={compareToIndustry}
                        disabled={disabled.areas_for_improvement}
                        goal={goal}
                        serviceType={serviceType}
                    />
                </>
            )}
        </Box>
    );
};

export default DriverStatistics;
