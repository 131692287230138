import React from 'react';
import { makeStyles } from '@material-ui/core';
import cn from 'classnames';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        margin: theme.spacing(2),
        zIndex: 10,
    },
    bubble: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        background: theme.palette.primary.contrastText,
        border: `2px solid ${theme.palette.secondary.main}`,
        padding: theme.spacing(2),
        borderRadius: 4,

        '&::after': {
            content: '""',
            position: 'absolute',
            bottom: -20,
            width: theme.spacing(3),
            height: theme.spacing(3),
            borderRadius: '0 0 0px 0',
            transform: 'rotate(45deg) translate(-50%)',
            transformOrigin: 'bottom',
            background: 'inherit',
            borderBottom: 'inherit',
            borderRight: 'inherit',
            boxShadow: 'inherit'
        },
    },
    placeholderBubble: {
        padding: theme.spacing(1) / 2,
    },
}));

const SpeechBubble = ({ children, isPlaceholder }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={cn(classes.bubble, { [classes.placeholderBubble]: isPlaceholder })}>
                { children }
            </div>
        </div>
    );
};

export default SpeechBubble;
