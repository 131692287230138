import React from 'react';
import _ from 'lodash';
import { connect, useDispatch } from 'react-redux';
import { Toolbar, SaveButton, showNotification } from 'react-admin';
import { PROFESSIONAL } from '../../constants';
import { setFormError, basicProfileUpdateSuccess } from '../../state/actions';
import dataProvider from '../../dataProvider';
import * as analytics from '../../analytics';
import { makeStyles } from '@material-ui/core';

const { FINANCIAL_ADVISER } = PROFESSIONAL.VERTICAL;

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
    },
}));

const BasicProfileEditToolbar = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { renderToolbar, professionalId, ...rest } = props;

    if (!renderToolbar) return null;

    const save = async (values) => {
        const payload = {
            id: professionalId,
            data: values,
        };

        try {
            await dataProvider('UPDATE', 'basic-profile', payload);

            analytics.track('complete', {
                category: 'BasicProfile',
            });

            dispatch(basicProfileUpdateSuccess());
        } catch (e) {
            const message = _.get(e, 'body.message');

            if (message) {
                const error = _.mapValues(message, (value) => _.get(value, '0'));

                dispatch(setFormError(error));
            }
            dispatch(showNotification('ra.notification.http_error', 'warning'));
        }
    };

    return (
        <Toolbar {...rest} className={classes.root}>
            <SaveButton submitOnEnter={false} onSave={save} />
        </Toolbar>
    );
};

const mapStateToProps = ({ basicInfo, form }) => {
    const servicesCount = _.get(basicInfo, 'servicesCount');
    const isFinancialAdviser = _.get(basicInfo, 'permissions.vertical') === FINANCIAL_ADVISER;

    return {
        professionalId: _.get(basicInfo, 'id'),
        renderToolbar: isFinancialAdviser && servicesCount === 0 ? false : true,
    };
};

export default connect(mapStateToProps)(BasicProfileEditToolbar);
