import React, { useState } from 'react';
import _ from 'lodash';
import { showNotification, SimpleForm, userLogin } from 'react-admin';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { forgottenPasswordStyles } from '../forgottenPasswordStyles';
import FormToolbar from '../../Components/Toolbars/FormToolbar';
import PasswordInput from '../../Components/Inputs/PasswordInput';
import { API_KEYS } from '../../constants';

const ResetPasswordForm = props => {
    const [loading, setLoading] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const { token, classes } = props;

    const handleSubmit = async ({ plainPassword }) => {
        if (loading) return;

        if (!plainPassword) {
            handleError('Required');

            return;
        }

        setLoading(true);

        try {
            const res = await fetch(`${API_KEYS.public_api_url}/register/resetting/reset`, {
                method: 'PUT',
                body: JSON.stringify({ token, plainPassword }),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });

            const json = await res.json();
            const email = _.get(json, 'data.email');

            if (res.status === 200 && Boolean(email)) {
                props.userLogin({
                    username: email,
                    password: plainPassword,
                }, "/");
            } else {
                handleError(_.get(json, 'data.message.plainPassword.0'));
            }
        } catch {
            handleError();
        }
    };

    const handleError = message => {
        const error = message
            ? message
            : 'Sorry, we had an error changing that. Please try again, or contact us';

        props.showNotification(error, 'warning');

        setPasswordError(true);
        setLoading(false);
    };

    return (
        <SimpleForm
            saving={loading}
            className={classes.forgotSimpleForm}
            save={handleSubmit}
            toolbar={<FormToolbar label={props.buttonLabel} />}
        >
            <PasswordInput source="plainPassword" showableInput hasError={passwordError} />
        </SimpleForm>
    );
};

const actions = {
    showNotification,
    userLogin,
};

export default connect(
    null,
    actions
)(withStyles(forgottenPasswordStyles)(ResetPasswordForm));
