import React from 'react';
import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    BarChart,
    Bar,
    ResponsiveContainer,
} from 'recharts';

const CustomBarChart = ({
    dataPoints,
    elements,
    opacity,
    visibility,
    handleMouseEnter,
    handleMouseLeave,
    handleClick,
    colors,
}) => {
    if (!dataPoints) return null;

    return (
        <ResponsiveContainer height={300} width="100%">
            <BarChart data={dataPoints}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="xaxis" />
                <YAxis
                    allowDecimals={false}
                    tickCount={6}
                />
                <Tooltip />
                <Legend
                    onClick={handleClick}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                />
                {elements.map((name, key) => (
                    <Bar
                        barSize={20}
                        dataKey={name}
                        fill={colors[key]}
                        opacity={opacity[name]}
                        hide={!visibility[name]}
                    />
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default CustomBarChart;
