import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../../Components/Buttons/CustomButton';
import withStyles from '@material-ui/core/styles/withStyles';
import * as analytics from '../../analytics';

const styles = theme => ({
    container: {
        minHeight: theme.spacing(10),
        width: '100%',
        marginBottom: theme.spacing(3),
    },
    content: {
        marginBottom: theme.spacing(2),
        color: theme.palette.primary.contrastText,
        textTransform: 'uppercase',
    },
});

const FeesInsightsUnlockBanner = ({ classes, ...props }) => {
    return (
        <Button
            variant="contained"
            color="tertiary"
            component={Link}
            to="/payment"
            className={classes.container}
            onClick={() => analytics.track('Clicked Upgrade', { category: 'Fee Insights' })}
            {...props}
        >
            Upgrade to access the full set of data
        </Button>
    );
};

export default withStyles(styles)(FeesInsightsUnlockBanner);
