import React from 'react';
import { Typography } from "@material-ui/core";
import { useQuery } from "react-admin";
import _ from 'lodash';

const FirstImpressionsProspectPercentage = () => {

    const { data, loading } = useQuery({
        type: 'getOne',
        resource: 'dashboard/statistics',
    });

    const value = _.get(data, 'statistics.prospect_intention.value', null);

    return(
        <>
            { loading ? null :
                (
                    <div>
                        { value ? (
                            <>
                                <Typography variant="h5"><b>Did you know?</b></Typography>
                                <Typography variant="h5">
                                    <b><span>{value}%</span></b> of your prospects said they're going to use your services in the future.
                                </Typography>
                            </>

                        ) : (
                            <Typography variant="h5">
                                Did you know that first impression reviews give you an indication
                                whether a prospect is likely to use your services or not?
                            </Typography>
                        )}
                    </div>
                )}
        </>
    );}

export default FirstImpressionsProspectPercentage;