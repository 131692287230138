import React from 'react';
import _ from 'lodash';
import { SimpleForm, DateField, TextField, required } from 'react-admin';
import { connect } from 'react-redux';
import EnquiryTitle from './Components/EnquiryTitle';
import FormSelectInput from '../Components/Inputs/Form/FormSelectInput';
import { ENQUIRY_STATUS_CHOICES } from '../constants';
import { Typography } from '@material-ui/core';
import PreventUndoEdit from '../Components/PreventUndoEdit';
import EnquiriesEditToolbar from './Components/EnquiriesEditToolbar';
import EnquiriesReportActions from './Components/EnquiriesReportActions';

const EnquiryMessage = ({ record }) => {
    const message = _.get(record, ['enquiry_message', 'message'], null);

    if (!message) return null;

    return <Typography style={{ whiteSpace: 'pre-line' }}>{message}</Typography>;
};

const EnquiriesEdit = props => {
    const { professionalId, ...rest } = props;
    const enquiriesUrl = `/enquiries-list/${professionalId}`;
    return (
        <PreventUndoEdit {...rest} actions={<EnquiriesReportActions {...rest} />}>
            <SimpleForm toolbar={<EnquiriesEditToolbar {...props} />} redirect={enquiriesUrl}>
                <EnquiryTitle />
                <DateField label="Date" source="created_at" />
                <TextField
                    label="Seeking Advice Primarily On"
                    source="enquiry_message.attribute.name"
                />
                <EnquiryMessage />
                <TextField label="Email" source="redundant_customer_email" />
                <TextField label="Phone Number" source="redundant_customer_phone" />
                <TextField label="Best Time To Call" source="enquiry_message.best_time_to_call" />
                <br />
                <FormSelectInput
                    validate={[required()]}
                    label="How did it go?"
                    source="enquiry_status.gka"
                    choices={ENQUIRY_STATUS_CHOICES}
                />
                <p style={{width: 'auto'}}><b>Please note:</b> This is for your own record, VouchedFor will not be notified of changes to the status. </p>
            </SimpleForm>
        </PreventUndoEdit>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        professionalId: basicInfo.id,
    };
};

export default connect(mapStateToProps)(EnquiriesEdit);
