import React, { useState } from 'react';
import _ from 'lodash';
import { Box } from '@material-ui/core';
import Button from '../../Components/Buttons/CustomButton';
import { Toolbar, useDataProvider, useNotify } from 'react-admin';
import { useFormState } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { SERVICE } from '../../constants';
import MissingPensionQualificationErrorMessage from './MissingPensionQualificationErrorMessage';
import { history } from '../../App';

const useStyles = makeStyles(theme => ({
    toolbar: {
        padding: `0 ${theme.spacing(2)}px`,
    },
}));

const VerifyServicesToolbar = props => {
    const [state, setState] = useState({ loading: false, pensionServiceErrorMessage: null });
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const classes = useStyles();
    const { values } = useFormState();

    const submitForm = async () => {
        setState({
            loading: true,
            pensionServiceErrorMessage: null,
        });

        try {
            await dataProvider.update('verify-services', { id: values.id, data: values });

            history.push('/');
        } catch (e) {
            const attributeErrorMessage = _.get(e, 'body.message.attribute.0');
            const pensionTransferSpecialistService = values.services.find(
                service => service.attribute_id === SERVICE.PENSION_TRANSFERS_SPECIALIST
            );

            if (pensionTransferSpecialistService && attributeErrorMessage) {
                setState({
                    loading: false,
                    pensionServiceErrorMessage: attributeErrorMessage,
                });
            } else {
                setState({
                    loading: false,
                    pensionServiceErrorMessage: null,
                });

                notify('Sorry, we had a problem verifying your services', 'error');
            }
        }
    };

    return (
        <>
            <Toolbar className={classes.toolbar} {...props}>
                <Box flexDirection="column">
                    <Button
                        onClick={submitForm}
                        disabled={props.invalid || state.loading}
                        variant="contained"
                        color="tertiary"
                    >
                        Save
                    </Button>
                    <MissingPensionQualificationErrorMessage
                        message={state.pensionServiceErrorMessage}
                    />
                </Box>
            </Toolbar>
        </>
    );
};

export default VerifyServicesToolbar;
