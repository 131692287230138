import React from 'react';
import FormGrid from './FormGrid';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import FormSelectInput from '../../Components/Inputs/Form/FormSelectInput';

function getLabel(attributeId) {
    switch (attributeId) {
        case 549:
            return 'Overall type of Financial Adviser';
        case 553:
            return 'Overall type of Mortgage Adviser';
        default:
            return [];
    }
}

const AdviceAreaGrid = ({ record }) => {
    return (
        <FormGrid
            record={record.advice_areas}
            columns={['Advice Area', 'Status']}
            renderRow={(item, i) =>
                <TableRow key={item.id}>
                    <TableCell>
                        { getLabel(item.attribute_id) }
                    </TableCell>
                    <TableCell>
                        <FormSelectInput
                            label=""
                            source={`advice_areas[${i}].option.id`}
                            choices={item.verification_options}
                        />
                    </TableCell>
                </TableRow>
            }
        />
    );
};

export default AdviceAreaGrid;
