import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextInput, required, minLength } from 'react-admin';
import ShowablePasswordInput from './ShowablePasswordInput';
import MyTheme from '../../MyTheme';
import { PASSWORD_ERROR } from '../../constants';

import Typography from '@material-ui/core/Typography';

const { error, primary } = MyTheme.palette;

const propTypes = {
    inputLabel: PropTypes.string,
    source: PropTypes.string,
    inputValidation: PropTypes.array,
    whiteMessageStyle: PropTypes.bool,
    className: PropTypes.any,
    onInputChange: PropTypes.func,
    showableInput: PropTypes.bool,
    hasError: PropTypes.bool,
    fullWidth: PropTypes.bool,
};

const defaultProps = {
    inputValidation: [required(), minLength(8)],
    inputLabel: 'Choose password',
    showableInput: false,
    hasError: false,
    fullWidth: false,
    source: 'plain_password',
};

class PasswordInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messageColor: null,
        };

        this.errorColor = error.main;
    }

    componentDidMount() {
        this.setDefaultColor();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props && this.props.hasError) {
            this.setState({ messageColor: this.errorColor });
        }
    }

    getMessageStyle = () => {
        return {
            textAlign: 'left',
            margin: 0,
            marginBottom: '1rem',
            color: this.state.messageColor,
            fontSize: '11px',
        };
    };

    setDefaultColor = () => {
        const { messageColor } = this.state;
        const { whiteMessageStyle } = this.props;

        if (!messageColor || messageColor === this.errorColor) {
            this.setState({
                messageColor: whiteMessageStyle
                    ? primary.contrastText
                    : primary.textPrimary,
            });
        }
    };

    renderPasswordInput = () => {
        if (this.props.showableInput) {
            return (
                <ShowablePasswordInput
                    source={this.props.source}
                    inputLabel="New password"
                    onChange={this.setDefaultColor}
                />
            );
        }

        return (
            <TextInput
                type="password"
                source={this.props.source}
                className={this.props.className}
                validate={this.props.inputValidation}
                label={this.props.inputLabel}
                onChange={this.setDefaultColor}
                fullWidth={this.props.fullWidth}
            />
        );
    };

    render() {
        return (
            <React.Fragment>
                {this.renderPasswordInput()}
                <Typography align="center" style={this.getMessageStyle()}>
                    {PASSWORD_ERROR.INPUT_MESSAGE}
                </Typography>
            </React.Fragment>
        );
    }
}

PasswordInput.defaultProps = defaultProps;
PasswordInput.propTypes = propTypes;

export default PasswordInput;
