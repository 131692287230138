import React from 'react';
import { TopToolbar } from 'react-admin';
import Button from '@material-ui/core/Button';
import BackButton from '../../Components/Buttons/BackButton';
import ErrorButton from '../../Components/Buttons/ErrorButton';
import { STATE } from '../../constants/review';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    errorButton: {
        backgroundColor: theme.palette.tertiary.main,
        paddingRight: '1rem',
        paddingLeft: '1rem',
        marginBottom: '1rem',
        color: "#fff",
        '&:hover': {
            backgroundColor: theme.palette.tertiary.dark,
        },
        [theme.breakpoints.down('xs')]: {
            float: 'none'
        },

    },
}))

const ReviewEditActions = ({ basePath, data, resource, href, ...props }) => {
    return(
    <TopToolbar>
        <BackButton href={href} />
        {(data && data.current_review_state === STATE.CONTENT_ABUSE) || props.reported ? (
            <Button>Reported</Button>
        ) : null}
    </TopToolbar>
    )
};

export const ReportButton = ({ basePath, record, resource, href, label, ...props }) => {
    const classes = useStyles()
    return (
        <>
            {!(
                (record && record.current_review_state === STATE.CONTENT_ABUSE) || 
                props.reported
            ) ?
                <ErrorButton className={classes.errorButton} onClick={() => props.reportHandler()}>{label ? label : "Report issue"}</ErrorButton> : null
            }
        </>
    )
}

export default ReviewEditActions;
