import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { required } from 'react-admin';
import dataProvider from '../../dataProvider';
import FormSelectInput from './Form/FormSelectInput';

const LinkedSelect = props => {
    const [choices, setChoices] = useState([]);
    const { source, resourceName, label, resourceId } = props;

    useEffect(() => {
        const url = props.resourceId ? `${resourceName}/${props.resourceId}` : resourceName;
        dataProvider('GET_LIST', url).then(res => {
            const services_choices = res.data?.services_choices;

            if (typeof services_choices == 'undefined') {
                setChoices(_.get(res, 'data', []));
            } else if (services_choices == null) {
                setChoices([])
            } else {
                setChoices(services_choices);
            }
        });
    }, [resourceId]);

    return (
        <>
            {choices.length > 0 &&
                <FormSelectInput
                    validate={props.required ? [required()] : null}
                    label={label}
                    source={source}
                    defaultValue={props.defaultValue}
                    choices={choices}
                />
            }
        </>
    );
};

export default LinkedSelect;
