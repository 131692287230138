import {LOCAL_STORAGE, LOGGED_OUT_AND_SIDEBAR_URLS} from "../../constants";

const shouldShowSidebar = (pathname, basicProfileComplete = true) => {
    let showSidebar = true;
    const basicInfo = localStorage.getItem(LOCAL_STORAGE.BASIC_INFO);

    if (!basicInfo) {
        showSidebar = false;
    }

    if (!basicProfileComplete) {
        showSidebar = false;
    }

    LOGGED_OUT_AND_SIDEBAR_URLS.forEach(url => {
        if (pathname.includes(url)) {
            showSidebar = false;
        }
    });

    return showSidebar;
};

export default shouldShowSidebar;
