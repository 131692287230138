import React, { useState } from 'react';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import { FormDataConsumer, required, SimpleForm } from 'react-admin';
import { validateMinMaxFields } from '../../validation';
import InputPrefix from '../../Components/Inputs/InputPrefix';
import { FormSectionTitle } from '../../Components/Typography/Headlines';
import FormTextInput from '../../Components/Inputs/Form/FormTextInput';
import FormSelectInput from '../../Components/Inputs/Form/FormSelectInput';
import FormNumberInput from '../../Components/Inputs/Form/FormNumberInput';

const MortgageFeeLevelForm = props => {
    const [redirect, setRedirect] = useState(false);
    const { save, record } = props;
    const mortgageFeeId = _.get(props, ['location', 'state', 'feeId'], null);
    const isCreateForm = window.location.href.includes('create');

    if (redirect) {
        return <Redirect to="/fees" />;
    }

    return (
        <SimpleForm
            redirect={() => setRedirect(true)}
            save={save}
            record={record}
            validate={validateMinMaxFields}
        >
            <FormSectionTitle title={`${isCreateForm ? 'Add' : 'Edit'} fee level: Mortgage`} />
            <FormSelectInput
                label="For mortgage value"
                source="levelType"
                validate={[required()]}
                choices={[
                    { id: 'up_to', name: 'Up to' },
                    { id: 'between', name: 'Between' },
                    { id: 'over', name: 'Over' },
                ]}
            />
            <FormTextInput
                style={{ display: 'none' }}
                source="feeId"
                defaultValue={mortgageFeeId}
            />
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    return (
                        <FormNumberInput
                            source={
                                formData.levelType === 'up_to' ? 'maximumAmount' : 'minimumAmount'
                            }
                            {...rest}
                            label="Value"
                            InputProps={{
                                startAdornment: <InputPrefix label="£" />,
                            }}
                        />
                    );
                }}
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    formData.levelType === 'between' && (
                        <FormNumberInput
                            source="maximumAmount"
                            label="Max Value"
                            {...rest}
                            InputProps={{
                                startAdornment: <InputPrefix label="£" />,
                            }}
                        />
                    )
                }
            </FormDataConsumer>
            <FormNumberInput
                source="paymentFee"
                label="Fee"
                validate={[required()]}
                InputProps={{
                    startAdornment: <InputPrefix label="£" />,
                }}
            />
            <FormSelectInput
                label="On"
                source="paymentTime"
                defaultValue={'completion'}
                validate={[required()]}
                choices={[
                    { id: 'offer', name: 'Offer' },
                    { id: 'completion', name: 'Completion' },
                    { id: 'application', name: 'Application' },
                ]}
            />
        </SimpleForm>
    );
};

export default MortgageFeeLevelForm;
