import React, { Component } from 'react';

class ReviewsCounter extends Component {
    renderDigit(digit, index) {
        if (!/[0-9]/.test(digit)) return digit;

        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="43" viewBox="0 0 36 43" key={index}>
                <defs>
                    <linearGradient id="c" x1="50%" x2="50%" y1="0%" y2="100%">
                        <stop offset="0%" stopColor="#FFF" stopOpacity="0"/>
                        <stop offset="100%" stopColor="#FFF"/>
                    </linearGradient>
                    <path id="b" d="M1 18h28v3H1z"/>
                    <filter id="a" width="150%" height="566.7%" x="-25%" y="-166.7%" filterUnits="objectBoundingBox">
                        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
                        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2"/>
                        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
                    </filter>
                    <filter id="d" width="132.1%" height="400%" x="-16.1%" y="-83.3%" filterUnits="objectBoundingBox">
                        <feOffset dy="-1" in="SourceAlpha" result="shadowOffsetInner1"/>
                        <feComposite in="shadowOffsetInner1" in2="SourceAlpha" k2="-1" k3="1" operator="arithmetic" result="shadowInnerInner1"/>
                        <feColorMatrix in="shadowInnerInner1" values="0 0 0 0 0.624732617 0 0 0 0 0.629339177 0 0 0 0 0.638552296 0 0 0 1 0"/>
                    </filter>
                </defs>
                <g fill="none" fillRule="evenodd" transform="translate(3)">
                    <rect width="27" height="40" x="1.5" y="2.5" fill="#FBFBFB" stroke="#B0B0B0" rx="2"/>
                    <rect width="29" height="40" x=".5" y=".5" fill="#FBFBFB" stroke="#B0B0B0" rx="2"/>
                    <path stroke="#979797" strokeLinecap="square" d="M3.25 18.5v4M27.25 18.5v4"/>
                    <g opacity=".6">
                        <use fill="#000" filter="url(#a)"/>
                        <use fill="url(#c)"/>
                        <use fill="#000" filter="url(#d)"/>
                    </g>
                    <text fill="#4a4a4a" fontFamily="Nunito" fontSize="22" letterSpacing="-.55">
                        <tspan x="8.485" y="28">{digit}</tspan>
                    </text>
                </g>
            </svg>
        );
    }
    render() {
        return (
            <span>
                { [...this.props.children].map(this.renderDigit) }
            </span>
        );
    }
}

export default ReviewsCounter;
