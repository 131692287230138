import React from 'react';
import { Create, SimpleForm, TopToolbar } from 'react-admin';
import { getHistory } from '../Helpers/history';
import { FormSectionTitle } from '../Components/Typography/Headlines';
import { makeStyles } from '@material-ui/core/styles';
import BackButton from '../Components/Buttons/BackButton';
import LinkedSelect from '../Components/Inputs/LinkedSelect';

const useStyles = makeStyles(() => ({
    autocompleteDropdownInputCard: {
        '& > div': {
            overflow: 'visible',
        },
    },
}));

const LanguagesCreate = props => {
    const classes = useStyles();

    return (
        <Create
            className={classes.autocompleteDropdownInputCard}
            title={null}
            actions={
                <TopToolbar>
                    <BackButton />
                </TopToolbar>
            }
            {...props}
        >
            <SimpleForm redirect={getHistory(2)}>
                <FormSectionTitle title="Add language" />
                <LinkedSelect
                    label="Languages"
                    source="attribute"
                    resourceName="languages-choices"
                />
            </SimpleForm>
        </Create>
    );
};

export default LanguagesCreate;
