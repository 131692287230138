import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import Button from '../Buttons/CustomButton';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link } from 'react-router-dom';

const styles = theme => ({
    editButton: {
        display: 'flex',
        clear: 'both',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1),
        width: 'fit-content',
    },
});

const EditButton = ({ label, href, classes }) => {
    return (
        <Button
            color="secondary"
            component={Link}
            to={href}
            className={classes.editButton}
        >
            <EditIcon style={{ fontSize: 20 }} />
            {label}
        </Button>
    );
};

export default withStyles(styles)(EditButton);
