import React from 'react';
import FirmPageWrapper from './FirmPageWrapper';
import ReputationToolCategory from '../ReputationTools/Tools/Components/ReputationToolCategory';
import WidgetStars from '../ReputationTools/Tools/WidgetStars';
import FirmWidgetCarousel from '../ReputationTools/Tools/FirmWidgetCarousel';
import FirmWidgetRating from '../ReputationTools/Tools/FirmWidgetRating';
import FirmTopRatedReportCard from '../ReputationTools/Tools/FirmTopRatedReportCard';
import FirmTopRatedBadges from '../ReputationTools/Tools/FirmTopRatedBadges';
import { useSelector } from 'react-redux';
import ReputationToolGridLayout from '../ReputationTools/ReputationToolGridLayout';
import {showNewRepTool, showWidget} from "../utils";

const FirmMarketingTools = () => {
    const isTopRatedFirm = useSelector(({ basicInfo }) => basicInfo.permissions?.is_top_rated_firm);

    return (
        <FirmPageWrapper variant="show" style={{ width: '60%' }}>
            <ReputationToolGridLayout>
                <ReputationToolCategory
                    title="Rating Widget"
                    description="The Star Rating Widget shows the average review rating for all advisers at your firm. When you click the widget from your website you will be taken to your VouchedFor firm profile."
                >
                    {showWidget() &&
                        <WidgetStars type="panel" resource="firm/widget/star" buttonName="Copy widget code"/>
                    }
                    <WidgetStars type="centre" resource="firm/widget/star" buttonName="Copy widget code"/>
                    { showWidget() &&
                        <WidgetStars type="left" resource="firm/widget/star" buttonName="Copy widget code"/>
                    }
                </ReputationToolCategory>
                <ReputationToolCategory
                    title="Latest Reviews Widget"
                    description="Display the latest 5 reviews across all the verified advisers linked to your firm profile, or select ‘featured’ reviews on your ‘reviews for all advisers' tab."
                >
                    <FirmWidgetCarousel type="dark"/>
                    <FirmWidgetCarousel type="light"/>
                </ReputationToolCategory>
                <ReputationToolCategory
                    title="Firm Marketing Tools"
                    description={
                        <div>
                        <span>
                            Firm Rating Widget dynamically show your firm's VouchedFor rating (based
                            on the average of all linked advisers) on your company website.
                        </span>
                            <br/>
                            <span>
                            When visitors to your site click on the widget, they will be able to
                            visit your firm profile on VouchedFor.
                        </span>
                        </div>
                    }
                >
                    <FirmWidgetRating/>
                </ReputationToolCategory>
                {isTopRatedFirm && (
                    <>
                        <ReputationToolCategory
                            title="Top Rated Certificate"
                            description="Put it up on your wall, tweet about it, take it to meetings... the choice is yours."
                        >
                            <FirmTopRatedReportCard showNewRepTool={showNewRepTool()} />
                        </ReputationToolCategory>
                        <ReputationToolCategory
                            title={ showNewRepTool() ? "Top Rated Firm Badge" : "Top Rated Firm Badges"}
                            description={
                                showNewRepTool() ?
                                    "Click to download the image; you can share it on your website, emails, or client packs!"
                                    :
                                    "Click to download the images that work best for your branding; you can share them on your website, emails, or client packs!"
                                }
                        >
                            <FirmTopRatedBadges showNewRepTool={showNewRepTool()}/>
                        </ReputationToolCategory>
                    </>
                )}
            </ReputationToolGridLayout>
        </FirmPageWrapper>
    );
};

export default FirmMarketingTools;
