import React, { useState } from 'react';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import { FormDataConsumer, required, SimpleForm } from 'react-admin';
import InputPrefix from '../../Components/Inputs/InputPrefix';
import { FormSectionTitle } from '../../Components/Typography/Headlines';
import { validateMinMaxFields } from '../../validation';
import { FEE_LEVEL_TYPE_CHOICES, FEE_SUB_TYPE } from '../../constants';
import { getFeeLevelFormTitle } from '../feesUtils';
import FootNote from '../../Components/Typography/FootNote';
import FormTextInput from '../../Components/Inputs/Form/FormTextInput';
import FormSelectInput from '../../Components/Inputs/Form/FormSelectInput';
import FormNumberInput from '../../Components/Inputs/Form/FormNumberInput';

const { FIXED_FEE_ONLY } = FEE_SUB_TYPE;

function getFeeTypeFromLocationProps(props) {
    return {
        type: _.get(props, ['location', 'state', 'feeType'], ''),
        subType: _.get(props, ['location', 'state', 'investmentFeeSubType'], ''),
    };
}

const InvestmentFeeLevelForm = props => {
    const [redirect, setRedirect] = useState(false);
    const locationProps = getFeeTypeFromLocationProps(props);
    const isCreateForm = window.location.href.includes('create');
    const feeType = locationProps.type;
    const investmentFeeSubType = locationProps.subType;
    const inputPrefix = investmentFeeSubType === FIXED_FEE_ONLY ? '£' : '%';

    if (redirect) {
        return <Redirect to="/fees" />;
    }

    return (
        <SimpleForm
            redirect={() => setRedirect(true)}
            save={props.save}
            record={props.record}
            validate={validateMinMaxFields}
            toolbar={props.toolbar}
        >
            <FormSectionTitle
                title={getFeeLevelFormTitle(feeType, investmentFeeSubType, isCreateForm)}
            />
            <FormSelectInput
                label="For amounts"
                validate={[required()]}
                source="levelType"
                choices={FEE_LEVEL_TYPE_CHOICES}
            />
            <FormTextInput
                style={{ display: 'none' }}
                source="feeId"
                defaultValue={_.get(props, ['location', 'state', 'feeId'], null)}
            />
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    const { levelType } = formData;

                    if (levelType === 'any_amount') {
                        return null;
                    }

                    return (
                        <FormNumberInput
                            source={levelType === 'up_to' ? 'maximumAmount' : 'minimumAmount'}
                            {...rest}
                            label="Value"
                            InputProps={{
                                startAdornment: <InputPrefix label="£" />,
                            }}
                        />
                    );
                }}
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    formData.levelType === 'between' && (
                        <FormNumberInput
                            source="maximumAmount"
                            label="Max Value"
                            {...rest}
                            InputProps={{
                                startAdornment: <InputPrefix label="£" />,
                            }}
                        />
                    )
                }
            </FormDataConsumer>
            <FormNumberInput
                label="Initial charge"
                source="initialCharge"
                validate={[required()]}
                InputProps={{
                    startAdornment: <InputPrefix label={inputPrefix} />,
                }}
            />
            <FormNumberInput
                label="Ongoing charge"
                source="ongoingCharge"
                validate={[required()]}
                InputProps={{
                    startAdornment: <InputPrefix label={inputPrefix} />,
                }}
            />
            {feeType === 'Financial Planning' && (
                <FootNote
                    content={
                        <span>
                            if you only charge an initial fixed fee for financial planning, just
                            enter £0 in the <b>Ongoing Charge</b> field.
                        </span>
                    }
                />
            )}
        </SimpleForm>
    );
};

export default InvestmentFeeLevelForm;
