import React from 'react';
import _ from 'lodash';
import { TextField, Datagrid, BooleanField } from 'react-admin';
import { FormSectionTitle } from '../Components/Typography/Headlines';
import { connect } from 'react-redux';
import { CONTACT } from '../constants';
import ImpersonateButtonComponent from './ImpersonateButtonComponent';
import FirmPageWrapper from './FirmPageWrapper';

import Typography from '@material-ui/core/Typography';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

const FirmLinkedAdvisersContent = ({ canImpersonate, width, ...props }) => {
    return (
        <>
            <FormSectionTitle title="Linked Advisers" />
            <Typography variant="body1">
                If you would like to unlink any advisers, please contact us on{' '}
                {CONTACT.PHONE_NUMBER} or email {CONTACT.EMAIL}
            </Typography>
            {
                isWidthDown('sm', width)
                ? <Datagrid {...props}>
                    <TextField
                        label="Adviser Name"
                        source="full_name"
                        sortable={false}
                    />
                    {canImpersonate && (
                        <ImpersonateButtonComponent />
                    )}
                </Datagrid>
                : <Datagrid {...props}>
                    <TextField
                        label="Adviser Name"
                        source="full_name"
                        sortable={false}
                    />
                    <TextField
                        label="Overall Rating"
                        source="review_analytics.overall_rating"
                        sortable={false}
                    />
                    <BooleanField
                        label="Is Contactable?"
                        source="c_is_contactable"
                        sortable={false}
                    />
                    {canImpersonate && (
                        <ImpersonateButtonComponent />
                    )}
                </Datagrid>
            }
        </>
    );
};

const FirmLinkedAdvisers = props => {
    const { canImpersonate, width, ...rest } = props;

    return (
        <FirmPageWrapper variant="list" {...rest} >
            <FirmLinkedAdvisersContent canImpersonate={canImpersonate} width={width} />
        </FirmPageWrapper>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        canImpersonate: _.get(basicInfo, 'can_impersonate'),
    };
};

export default connect(mapStateToProps)(withWidth()(FirmLinkedAdvisers));
