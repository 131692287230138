import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { SimpleForm } from 'react-admin';
import FormToolbar from '../../Components/Toolbars/FormToolbar';
import { PROFESSIONAL } from '../../constants';

import AppPageWrapper from '../../AppLayout/AppPageWrapper';
import ProfessionalPageHeader from './ProfessionalPageHeader';

const { ACCOUNTANT } = PROFESSIONAL.VERTICAL;

const propTypes = {
    children: PropTypes.element.isRequired,
};

const SaveButton = ({ vertical, ...props }) => {
    if (props.resource === 'professional/experience') {
        return null;
    }

    if (props.resource === 'services-list' && vertical === ACCOUNTANT) {
        return null;
    }

    return <FormToolbar label="Save" {...props} />;
};

// const ProfessionalPageWrapper = (props) => {
//     const {
//         permalink,
//         data,
//         vertical,
//         children,
//         dispatch,
//         ...rest
//     } = props;

//     return (
//         <PreventUndoEdit
//             actions={
//                 <PageActions title="Profile">
//                     <Button
//                         variant="contained"
//                         color="primary"
//                         href={permalink}
//                         target="_blank"
//                         rel="noopener noreferrer"
//                     >
//                         View Profile
//                     </Button>
//                 </PageActions>
//             }
//             {...rest}
//         >
//             <SimpleForm redirect={false} toolbar={<SaveButton vertical={vertical} resource={rest.resource}/>}>
//                 <ProfessionalTabs />
//                 {children}
//             </SimpleForm>
//         </PreventUndoEdit>
//     );
// }

const ProfessionalPageWrapper = props => {
    const { data, vertical, children, dispatch, ...rest } = props;

    return (
        <AppPageWrapper
            {...rest}
            variant="edit"
            header={<ProfessionalPageHeader />}
        >
            <SimpleForm
                redirect={false}
                toolbar={<SaveButton vertical={vertical} resource={rest.resource} />}
            >
                {children}
            </SimpleForm>
        </AppPageWrapper>
    );
};

PropTypes.propTypes = propTypes;

const mapStateToProps = ({ basicInfo }) => {
    return {
        vertical: _.get(basicInfo, ['permissions', 'vertical']),
    };
};

export default connect(mapStateToProps)(ProfessionalPageWrapper);
