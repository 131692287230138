import React from 'react';
import cn from 'classnames';
import AppPageWrapper from '../../AppLayout/AppPageWrapper';
import { FormSectionTitle } from '../../Components/Typography/Headlines';
import SkipFirstInvitationStep from './SkipFirstInvitationStep';
import FirstInvitationForm from './FirstInvitationForm';
import FirstInvitationUrl from './FirstInvitationUrl';

import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import OnboardingBannerSignUpInviteReviews
    from '../../Dashboard/OnboardingBanner/OboardingBannerSignUpInviteReviews';

const styles = theme => ({
    root: {
        maxWidth: 1920,
        margin: '0 auto',
    },
    grid: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
    },
    column: {
        background: theme.palette.secondary.faint,
        borderRadius: 4,
    },
    center: {
        textAlign: 'center',
    },
    skipStep: {
        margin: `${theme.spacing(10)}px 0 ${theme.spacing(5)}px`,
    },
});

const FirstInvitationCreate = props => {
    const { classes, ...rest } = props;

    return (
        <>
            <OnboardingBannerSignUpInviteReviews />
            <AppPageWrapper variant="simpleForm" {...rest}>
                <div className={classes.root}>
                    <Grid container alignItems="flex-start" spacing={5} className={classes.grid}>
                        <Grid item md={5} xs={12} className={classes.column}>
                            <FirstInvitationForm />
                        </Grid>
                        <Grid item md={2} xs={12}>
                            <div className={classes.center}>
                                <FormSectionTitle title="OR" />
                            </div>
                        </Grid>
                        <Grid item md={5} xs={12} className={cn(classes.column)}>
                            <FirstInvitationUrl />
                        </Grid>
                    </Grid>
                    <div className={classes.skipStep}>
                        <SkipFirstInvitationStep />
                    </div>
                </div>
            </AppPageWrapper>
        </>
    );
};

export default withStyles(styles)(FirstInvitationCreate);
