import React from 'react';
import { FormSectionTitle } from '../Components/Typography/Headlines';
import JobsGrid from '../Jobs/Components/JobsGrid';
import AwardsGrid from '../Awards/Components/AwardsGrid';
import ProfessionalPageWrapper from './Components/ProfessionalPageWrapper';import AddButton from '../Components/Buttons/AddButton';

const ExperienceChild = props => {
    return (
        <div>
            <FormSectionTitle title="Job Experience" />
            <JobsGrid {...props} />
            <AddButton
                resource="jobs"
                label="Add job experience"
                to={{ pathname: '/jobs/create' }}
            />
            <br />
            <FormSectionTitle title="Awards" />
            <AwardsGrid {...props} />
            <AddButton
                resource="awards"
                label="Add award"
                to={{ pathname: '/awards/create' }}
            />
        </div>
    );
};

const Experience = props => {
    return (
        <ProfessionalPageWrapper {...props}>
            <ExperienceChild {...props} />
        </ProfessionalPageWrapper>
    );
};

export default Experience;
