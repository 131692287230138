import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import AlertBarBase from './AlertBarBase';

import Button from '@material-ui/core/Button';


class AlertBar extends Component {
    render() {
        const { verifyServicesUrl, showAlertBar } = this.props;

        if (!showAlertBar) return null;

        return (
            <AlertBarBase
                variant="error"
                title="Please verify your services as restricted or independent"
                action={
                    <Button color="inherit" href={verifyServicesUrl}>
                        Verify Now
                    </Button>
                }
            />
        );
    }
}

const mapStateToProps = ({ basicInfo, router }) => {
    const location = _.get(router, 'location.pathname');
    const servicesVerified = _.get(basicInfo, 'permissions.services_verified');
    const plan = _.get(basicInfo, 'plan');
    const professionalId = _.get(basicInfo, 'id');
    const verifyServicesUrl = `/verify-services/${professionalId}`;

    return {
        verifyServicesUrl,
        showAlertBar: !servicesVerified && plan !== "Starter" && location !== verifyServicesUrl,
    };
};

export default connect(mapStateToProps)(AlertBar);
