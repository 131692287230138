import {
    BASIC_INFO_UPDATE_SUCCESS,
    BASIC_INFO_FETCH_SUCCESS,
    FETCH_BASIC_INFO,
    PLAN_UPGRADE_SUCCESS,
    GET_SERVICES_COUNT,
    GET_SERVICES_LIST,
} from '../types';

export const basicProfileUpdateSuccess = () => ({
    type: BASIC_INFO_UPDATE_SUCCESS,
});

export const basicProfileFetched = basicInfo => ({
    type: BASIC_INFO_FETCH_SUCCESS,
    basicInfo,
});

export const planUpgradeSuccess = () => {
    return {
        type: PLAN_UPGRADE_SUCCESS,
    };
};

export const getServicesCount = count => {
    return {
        type: GET_SERVICES_COUNT,
        payload: count,
    };
};

export const getServicesList = services => {
    return {
        type: GET_SERVICES_LIST,
        payload: services,
    };
};

export const fetchBasicInfo = payload => {
    return {
        type: FETCH_BASIC_INFO,
        payload,
    };
};
