import React from 'react';
import { useDispatch } from 'react-redux';
import { useDelete, refreshView, useNotify } from 'react-admin';
import ErrorButton from '../../Components/Buttons/ErrorButton';

const FeesChargeLevelDeleteButton = ({ record }) => {
    const dispatch = useDispatch();
    const notify = useNotify();
    const [deleteOne, { loading }] = useDelete('fee-charges', record.id, null, {
        onSuccess: () => {
            dispatch(refreshView());
            dispatch(notify('ra.action.fee_charge_level_deleted'));
        },
    });

    return (
        <ErrorButton onClick={deleteOne} disabled={loading}>
            Delete
        </ErrorButton>
    );
};

export default FeesChargeLevelDeleteButton;
