import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LoginResetEmailMessage from './LoginResetEmailMessage';
import { LOCAL_STORAGE } from '../constants';

import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

const { AUTH_ERROR_MESSAGE } = LOCAL_STORAGE;

const ERROR_TYPE = {
    IS_BLOCKED: 'isBlocked',
};

const styles = theme => ({
    container: {
        textAlign: 'center',
    },
    loadingWrapper: {
        marginTop: theme.spacing(2),
    },
    anchor: {
        textDecoration: 'none',
    },
});

class LoginMessage extends Component {
    state = {
        messageType: null,
        email: null,
    };

    componentDidMount() {
        const error = sessionStorage.getItem(AUTH_ERROR_MESSAGE);

        if (error) {
            const { type, email } = JSON.parse(error);

            this.setState({ email, messageType: type });
        }
    }

    renderMessage = () => {
        const { messageType, email } = this.state;
        const { classes } = this.props;

        if (messageType === ERROR_TYPE.IS_BLOCKED) {
            return <LoginResetEmailMessage email={email} />;
        }

        return (
            <>
                <Typography variant="body1">
                    <Link to="/forgot-password">
                        Forgotten your password or yet to set one?
                    </Link>
                </Typography>
                <Typography variant="body1" className={classes.anchor}>
                    Don't have a VouchedFor profile?{' '}
                    <a
                        href="/register"
                    >
                        Sign up for free
                    </a>
                </Typography>
            </>
        );
    };

    render() {
        const { classes } = this.props;

        return <div className={classes.container}>{this.renderMessage()}</div>;
    }
}

export default withStyles(styles)(LoginMessage);
