import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import ProfilePanel from './ProfilePanel';
import RatingPanel from './RatingPanel';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import SkeletonWrapper from '../../../Components/SkeletonWrapper';

const styles = theme => ({
    container: {
        marginBottom: theme.spacing(3),
    },
    personName: {
        marginTop: 0,
        color: theme.palette.secondary.main,

        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        },
    },
    ratingPanelWrapper: {
        marginTop: `-${theme.spacing(1)}px`,
    },
});

const WelcomePanel = props => {
    const { firstName, classes, permalink, data } = props;
    let loading = _.isNull(firstName) || !data;

    return (
        <div className={classes.container}>
            <SkeletonWrapper loading={loading} height={45} width={235}>
                <Typography className={classes.personName} component="h2" variant="h4">
                    <b>{`Welcome, ${firstName}`}</b>
                </Typography>
            </SkeletonWrapper>
            <ProfilePanel loading={loading}>
                <div className={classes.ratingPanelWrapper}>
                    <RatingPanel
                        loading={loading}
                        rating={_.get(data, 'overall_rating', 0)}
                        reviewAndFICount={_.get(data, 'review_and_fi_count', 0)}
                        permalink={permalink}
                        firstImpressionCount={_.get(data, 'first_impression_count', 0)}
                        recentReviewAndFiCount={_.get(data, 'recent_review_count', 0)}
                        recentReviewDate={_.get(data, 'days_since_last_review', 0)}
                        reviewCount={_.get(data, 'review_count', 0)}
                    />
                </div>
            </ProfilePanel>
        </div>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        firstName: basicInfo.first_name,
        permalink: _.get(basicInfo, ['permalink', 'url'], null),
    };
};

export default connect(mapStateToProps)(withStyles(styles)(WelcomePanel));
