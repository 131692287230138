import React from 'react';
import { SimpleForm, required } from 'react-admin';
import { FormSectionTitle } from '../Components/Typography/Headlines';
import { trueInputValidation } from '../validation';
import PreventUndoEdit from '../Components/PreventUndoEdit';
import VerifyServicesGrid from './Components/VerifyServicesGrid';
import AdviceAreaGrid from './Components/AdviceAreaGrid';
import { Link } from '../Components/Links';
import { CONTACT } from '../constants';

import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import FormTextInput from '../Components/Inputs/Form/FormTextInput';
import VerifyServicesToolbar from './Components/VerifyServicesToolbar';

const styles = theme => ({
    simpleForm: {
        maxWidth: theme.spacing(200),
    },
});

const ServicesVerify = ({ classes, ...props }) => {
    return (
        <PreventUndoEdit {...props}>
            <SimpleForm toolbar={<VerifyServicesToolbar />} className={classes.simpleForm}>
                <>
                    <FormSectionTitle title="Please verify your overall status: " />
                    <Typography>
                        Please confirm whether you are Independent / Whole of Market overall, or
                        Restricted / Limited
                    </Typography>
                </>
                <AdviceAreaGrid />
                <>
                    <Typography>
                        If you have any questions about your professional type, please call us on{' '}
                        <Link href={`tel:${CONTACT.PHONE_NUMBER}`}>{CONTACT.PHONE_NUMBER}</Link> or
                        email us at <Link href={`mailto:${CONTACT.EMAIL}`}>{CONTACT.EMAIL}</Link>
                    </Typography>
                    <br />
                    <FormSectionTitle title="Please confirm your services: " />
                    <Typography>
                        Confirm you still offer the services below and your independent/restricted
                        status for each, by typing ‘TRUE’ in the box below. Click ‘edit’ or ‘delete’
                        to make any changes to your services.
                    </Typography>
                </>
                <VerifyServicesGrid />
                <FormTextInput
                    label="Type TRUE to confirm"
                    validate={[required(), trueInputValidation]}
                    source="confirm"
                />
            </SimpleForm>
        </PreventUndoEdit>
    );
};

export default withStyles(styles)(ServicesVerify);
