import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import AddButton from '../Components/Buttons/AddButton';
import ServicesGrid from './ServicesGrid';
import { FormSectionTitle } from '../Components/Typography/Headlines';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(5),
    },
}));

const ServicesList = props => {
    const { servicesCount, ...rest } = props;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <FormSectionTitle title="Services you offer" />
            <br />
            <Typography variant="body1">
                It is your responsibility to ensure the description of your
                services complies with the appropriate governing body's guidance
                (for example, the FCA).
            </Typography>
            <ServicesGrid {...rest} />
            <AddButton
                resource="services"
                label={
                    servicesCount > 0 ? 'Add another service' : 'Add a service'
                }
                to={{ pathname: '/services/create' }}
            />
        </div>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        servicesCount: _.get(basicInfo, 'servicesCount'),
        professionalId: _.get(basicInfo, 'id'),
    };
};

export default connect(mapStateToProps)(ServicesList);
