export const VERTICAL = {
    ACCOUNTANT: 'accountant',
    FINANCIAL_ADVISER: 'financial_adviser',
    LEGAL_ADVISER: 'legal_adviser',
    FINANCIAL_COACH: 'financial_coach'
};

export const PROFESSIONAL = {
    VERTICAL,
};

export const VERTICAL_ID = {
    [VERTICAL.ACCOUNTANT]: 4,
    [VERTICAL.FINANCIAL_ADVISER]: 5,
    [VERTICAL.LEGAL_ADVISER]: 3,
    [VERTICAL.FINANCIAL_COACH]: 5,
};

export const PLAN = {
    STARTER: 'Starter',
    UNLIMITED: 'Unlimited',
    VERIFIED: 'Verified',
};

export const PLAN_ID = {
    [PLAN.STARTER]: 1,
    [PLAN.UNLIMITED]: 2,
    [PLAN.VERIFIED]: 3,
};

export const PROMOTION_PARENT_ATTRIBUTE = {
    [VERTICAL.LEGAL_ADVISER]: 81,
    [VERTICAL.FINANCIAL_ADVISER]: 413,
};

export const FEE_TYPE = {
    INVESTMENT_AND_PENSION: 'investment_and_pension',
    INVESTMENT_AND_PENSION_WITH_PLANNING:
        'investment_and_pension_with_financial_planning',
    MORTGAGE: 'mortgage',
};

export const FEE_SUB_TYPE = {
    FIXED_FEE_ONLY: 'fixed-fees-only',
    INITIAL_AND_ONGOING: 'initial-and-ongoing',
    HOURLY_CHARGE: 'hourly-charge',
};

export const CALCULATED_COST = {
    INVESTMENT_AND_PENSION: 'investment_without_planning',
    INVESTMENT_AND_PENSION_WITH_PLANNING: 'investment_with_planning',
};

export const REP_TOOL_ACTION = {
    COPY_WIDGET_CODE: 'copy-widget-code',
    SEND_TO_DEVELOPER: 'send-to-developer',
    DOWNLOADED_SELECTED_CERTIFICATE: 'download-selected-certificate',
    GET_CERTIFICATE_SHARE_LINK: 'get-certificate-share-link',
    DOWNLOAD_REPORT: 'download-report',
    DOWNLOAD_REVIEWS: 'download-reviews',
    CHOOSE_SIGNATURE: 'choose-signature',
    CHOOSE_SPECIFIC_SIGNATURE: 'choose-specific-signature',
    GET_HELP: 'get-help',
};

export const FA_INVESTMENT_ADVICE = {
    INDEPENDENT: 'financial_adviser_investment_advice_independent',
    RESTRICTED: 'financial_adviser_investment_advice_restricted',
    FOCUSSED: 'financial_adviser_investment_advice_focussed',
    NONE: 'financial_adviser_investment_advice_none',
    FINANCIAL_COACH: 'financial_adviser_investment_advice_financial_coaching',
};

export const MA_INVESTMENT_ADVICE = {
    INDEPENDENT: 'financial_adviser_mortgage_advice_independent',
    LIMITED: 'financial_adviser_mortgage_advice_limited',
    NONE: 'financial_adviser_mortgage_advice_none',
};

export const AVERAGE_FIVE_YEAR_COST = {
    TOTAL: 'total_five_year_cost',
    UK_WIDE: 'uk_five_year_average_cost',
    HIGHER_PERCENT: 'higher_fees_percent',
    LOWER_PERCENT: 'lower_fees_percent',
};

export const ACCOUNTANT_QUALIFICATION = {
    OTHER: 'accountant_chartered_qualification_other',
};

export const PERMISSIONS = {
    BASIC_PROFILE_COMPLETE: 'basic_profile_complete',
    SERVICES_VERIFIED: 'services_verified',
    SERVICE_OPTIONS: 'service_options',
    VERTICAL: 'vertical',
};

export const FA_BENEFITS = [
    {
        title: 'Fully Verified Profile',
        content:
            'Give prospective clients peace of mind with a Verified profile that passes our unique system of checks, reviews and monitoring.',
    },
    {
        title: 'Access to Reputational Tools',
        content:
            'Show off your VouchedFor rating with a range of online and print tools designed to sit alongside your own marketing materials.',
    },
    {
        title: 'Access to Fee Insights',
        content:
            'Gain market intelligence on how other advisers charge as well as a shareable Fee Report.',
    },
];

export const ACCOUNTANTS_BENEFITS = [
    {
        title: 'Fully Verified Profile',
        content:
            'Give prospective clients peace of mind with a Verified profile that passes our unique system of checks, reviews and monitoring.',
    },
    {
        title: 'Access to Reputational Tools',
        content:
            'Show off your VouchedFor rating with a range of online and print tools designed to sit alongside your own marketing materials.',
    },
];

export const FIRM_NETWORK_ID_OPENWORK = 1;
