import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ScoreAvatar from './ScoreAvatar';
import * as analytics from '../../../analytics';

import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CircularProgressGradient from '../../../Components/ConsumerReactComponents/CircularProgressGradient';

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: '50%',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        position: 'relative',
        width: 140,
        height: 140,
        margin: '0 auto',
        textDecoration: 'none',

        '& > div': {
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
        },
    },
    score: {
        color: theme.palette.secondary.dark,
        fontSize: theme.spacing(4),
        marginTop: 0,
        marginBottom: 2,
    },
    label: {
        textAlign: 'center',
        fontSize: theme.spacing(2),
        color: theme.palette.primary.main,
        margin: 0,
        textTransform: 'uppercase',
        maxWidth: 140,
    },
}));

const ProfilePanel = props => {
    const { avatar, firstName, addAvatarHref, loading } = props;
    const classes = useStyles()
    const handleClick = () => {
        analytics.track('Clicked add profile photo', {
            category: 'Dashboard',
        });
    };

    if (loading) return null;

    if (avatar) {
        return (
            <ScoreAvatar
                image={avatar}
                ratingValue={5}
                ratingMax={5}
                professionalName={firstName}
            />
        );
    }

    return (
        <ButtonBase focusRipple className={classes.root}>
            <Link to={addAvatarHref} className={classes.container} onClick={handleClick}>
                <Typography className={classes.label}>
                    <b>
                        Add a<br />
                        profile
                        <br />
                        picture
                    </b>
                </Typography>
                <CircularProgressGradient rating={1} maxRating={1} />
            </Link>
        </ButtonBase>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        addAvatarHref: `/professional/about/${basicInfo.id}`,
        firstName: basicInfo.first_name,
        avatar: basicInfo.avatar,
    };
};

export default connect(mapStateToProps)(ProfilePanel);
