import _ from 'lodash';
import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    fetchUtils,
} from 'react-admin';
import { stringify } from 'query-string';
import { getAddServicePayload, getAccountSettingsPayload } from './utils';
import {
    getAPIUrl,
    getLexikToken,
    convertFileToBase64,
    createFileParamsForUpload,
} from './Helpers/provider';
import { API_KEYS } from './constants';

/**
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataProviderRequestToHTTP = (type, resource, params) => {
    let API_URL = getAPIUrl(resource);
    let PUBLIC_API_URL = API_KEYS.public_api_url;
    const SURVEY_API_URL = API_KEYS.survey_api_url;
    const SURVEY_RESULT_API_URL = API_KEYS.api_url;

    let queryString = `?`;
    let page, perPage, field, order;
    let query = {};
    let filterBrackets = true;

    if (params) {
        perPage = params.pagination ? params.pagination.perPage : null;
        page = params.pagination ? params.pagination.page : null;
        field = params.sort ? params.sort.field : null;
        order = params.sort ? params.sort.order : null;

        if (perPage) {
            query.limit = perPage;
            if (page) {
                query.offset = (page - 1) * perPage;
            }
        } else if (params.limit) {
            query.limit = params.limit;
        }

        if (params.target && params.id) {
            query.filter = JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            });
        }

        queryString = queryString + '&' + stringify(query);

        if (resource == 'customer-master' && field) {
            if (field === 'calculated_next_invite_date') {
                field = 'next_invite_date';
            }
            if (field === 'last_reviewed_date') {
                field = 'created_at';
            }
        }

        if (field && order) {
            queryString = queryString + encodeURI(`&order_by[${field}]=${order}`);
        }

        if (params.filter) {
            let filters = [];
            if (resource === 'billing' && type === 'GET_LIST') {
                if (params.filter && params.filter.monthyear) {
                    const month = params.filter.monthyear.substr(4, 2);
                    const year = params.filter.monthyear.substr(0, 4);
                    filters.push('month=' + month);
                    filters.push('year=' + year);
                }
            } else {
                for (let prop in params.filter) {
                    if (filterBrackets) {
                        filters.push(
                            'filters%5B' + prop.replace('|', '.') + '%5D=' + params.filter[prop]
                        );
                    } else {
                        filters.push(prop.replace('|', '.') + '=' + params.filter[prop]);
                    }
                }
            }

            filters = filters.join('&');

            queryString = queryString + '&' + filters;
        }
    }

    switch (type) {
        case GET_LIST: {
            if (resource === 'reviews') {
                return {
                    url: `${API_URL}/${resource}${queryString}`,
                };
            } else if (resource.includes('previous-invites')) {
                return {
                    url: `${API_URL}/review-invitations/history${queryString}`,
                };
            } else if (resource === 'register') {
                return { url: `${PUBLIC_API_URL}/${resource}` };
            } else if (resource === 'firm/firm-fca') {
                return { url: `${API_URL}/${resource}${queryString}` };
            } else if (resource === 'firm/linked-advisers') {
                return {
                    url: `${API_URL}/firm/active-professionals${queryString}`,
                };
            } else {
                return { url: `${API_URL}/${resource}${queryString}` };
            }
        }
        case GET_ONE:
            if (resource === 'fees' || resource.includes('fees-edit')) {
                return { url: `${API_URL}/fees${queryString}` };
            } else if (resource.includes('professional')) {
                return { url: `${API_URL}/${resource}${queryString}` };
            } else if (resource.includes('basic-profile')) {
                return { url: `${API_URL}/professional${queryString}` };
            } else if (resource === 'professional/firm') {
                return { url: `${API_URL}/${resource}${queryString}` };
            } else if (resource === 'review-invitations/template') {
                return { url: `${API_URL}/${resource}${queryString}&type=${params.type}` };
            } else if (resource === 'survey' && params) {
                return { url: `${SURVEY_API_URL}/${params}${queryString}` };
            } else if (resource === 'survey-result') {
                return { url: `${SURVEY_RESULT_API_URL}/${resource}${queryString}` };
            }

            if (params) {
                if (resource === 'mortgage-fee-levels' || resource === 'investment-fee-levels') {
                    return {
                        url: `${API_URL}/fee-charges/${params.id}${queryString}`,
                    };
                } else if (resource === 'adviser-dashboard') {
                    return {
                        url: `${API_URL}/${resource}/${params.id}${queryString}`,
                    };
                } else {
                    if (!params.id || params.id === 'no-id' || params.id === 'fees-insights') {
                        return { url: `${API_URL}/${resource}${queryString}` };
                    } else {
                        return {
                            url: `${API_URL}/${resource}/${params.id}${queryString}`,
                        };
                    }
                }
            } else {
                return { url: `${API_URL}/${resource}${queryString}` };
            }
        case GET_MANY: {
            let ids = null;
            if (params.ids) {
                ids = {
                    filter: JSON.stringify({ id: params.ids }),
                };
            }
            return { url: `${API_URL}/${resource}${queryString}${ids ? stringify(ids) : ''}` };
        }
        case GET_MANY_REFERENCE:
            let ids = null;
            if (params.ids) {
                ids = {
                    filter: JSON.stringify({ id: params.ids }),
                };
            }
            return { url: `${API_URL}/${resource}${queryString}${ids ? stringify(ids) : ''}` };
        case UPDATE:
            if (resource === 'professional/about') {
                delete params.data.permalink;
            }
            if (resource.includes('fees-edit')) {
                const payload = Object.values(params.data.content).map(fee => fee);

                return {
                    url: `${API_URL}/fees${queryString}`,
                    options: { method: 'PUT', body: JSON.stringify(payload) },
                };
            }
            if (resource.includes('professional') || params.id === 'no-id') {
                delete params.data.id;
                delete params.data.professional_photo;
                return {
                    url: `${API_URL}/${resource}${queryString}`,
                    options: {
                        method: 'PUT',
                        body: JSON.stringify(params.data),
                    },
                };
            }
            if (resource.includes('basic-profile')) {
                let updateBasicProfileData = {};
                let hasMinimumCriterias = false;
                if (params.data.firm) {
                    updateBasicProfileData.firm = params.data.firm;
                }
                if (params.data.firm_name) {
                    updateBasicProfileData.firm_name = params.data.firm_name;
                }
                if (params.data.accepted_terms_and_conditions) {
                    updateBasicProfileData.accepted_terms_and_conditions =
                        params.data.accepted_terms_and_conditions;
                }
                if (params.data.attributes) {
                    if (params.data.attributes.financial_adviser_firm_fca) {
                        updateBasicProfileData.firm.fcaNumber =
                            params.data.attributes.financial_adviser_firm_fca.value;
                    }
                    let fa_attributes = [];
                    if (params.data.attributes.minimum_investment_criteria) {
                        hasMinimumCriterias = true;
                        fa_attributes.push(params.data.attributes.minimum_investment_criteria);
                        delete params.data.attributes.minimum_investment_criteria;
                    }
                    if (params.data.attributes.minimum_mortgage_criteria) {
                        hasMinimumCriterias = true;
                        fa_attributes.push(params.data.attributes.minimum_mortgage_criteria);
                        delete params.data.attributes.minimum_mortgage_criteria;
                    }
                    if (hasMinimumCriterias) {
                        updateBasicProfileData.fa_attributes = fa_attributes;
                    }
                }
                updateBasicProfileData.address = params.data.address;
                updateBasicProfileData.phone_direct = params.data.phone_direct;
                updateBasicProfileData.attributes = params.data.attributes
                    ? params.data.attributes
                    : {};
                updateBasicProfileData.auto_invite = params.data.auto_invite;
                if (params.data.chartered_qualification) {
                    updateBasicProfileData.attributes[params.data.chartered_qualification] = {};
                }

                if (params.data.accredited_by) {
                    updateBasicProfileData.attributes[params.data.accredited_by] = {};
                }

                if (params.data.no_personal_fca_id) {
                    updateBasicProfileData.no_personal_fca_id = params.data.no_personal_fca_id;
                }

                for (const attributeGka in updateBasicProfileData.attributes) {
                    updateBasicProfileData.attributes[attributeGka].flag = 1;
                }

                const qualificationDesc = _.get(
                    params,
                    ['data', 'chartered_qualification_description'],
                    null
                );

                if (qualificationDesc) {
                    updateBasicProfileData.attributes.accountant_chartered_qualification_other.value = qualificationDesc;
                }

                return {
                    url: `${API_URL}/professional/basic-info${queryString}`,
                    options: {
                        method: 'PUT',
                        body: JSON.stringify(updateBasicProfileData),
                    },
                };
            }
            if (resource === 'account-settings') {
                const payload = getAccountSettingsPayload(params);

                return {
                    url: `${API_URL}/${resource}/${params.id}${queryString}`,
                    options: { method: 'PUT', body: JSON.stringify(payload) },
                };
            }
            if (
                (resource.includes('reviews') && resource.includes('change-state')) ||
                (resource.includes('first-impressions') && resource.includes('verify')) ||
                (resource.includes('first-impressions') && resource.includes('report'))
            ) {
                return {
                    url: `${API_URL}/${resource}${queryString}`,
                    options: {
                        method: 'PUT',
                        body: JSON.stringify(params.data),
                    },
                };
            }
            if (resource === 'verify-services') {
                delete params.data.confirm;
                delete params.data.id;
            }
            if (resource === 'mortgage-fee-levels' || resource === 'investment-fee-levels') {
                if (params.data.levelType === 'up_to') {
                    params.data.minimumAmount = null;
                } else if (params.data.levelType === 'over') {
                    params.data.maximumAmount = null;
                }
                delete params.data.levelType;
                return {
                    url: `${API_URL}/fee-charges/${params.id}${queryString}`,
                    options: {
                        method: 'PUT',
                        body: JSON.stringify(params.data),
                    },
                };
            }
            if (resource === 'enquiries-list') {
                delete params.data.id;
            }
            return {
                url: `${API_URL}/${resource}/${params.id}${queryString}`,
                options: { method: 'PUT', body: JSON.stringify(params.data) },
            };
        case UPDATE_MANY:
            const query = {
                filter: JSON.stringify({ id: params.ids }),
            };
            return {
                url: `${API_URL}/${resource}${queryString}&${stringify(query)}`,
                options: { method: 'PATCH', body: JSON.stringify(params.data) },
            };
        case CREATE:
            if (resource.includes('review/')) {
                return {
                    url: `${API_URL}/review-invitations${queryString}`,
                    options: {
                        method: 'POST',
                        body: JSON.stringify(params.data),
                    },
                };
            } else if (resource.includes('register')) {
                return {
                    url: `${PUBLIC_API_URL}/${resource}`,
                    options: { method: 'POST', body: JSON.stringify(params) },
                };
            } else if (resource === 'mortgage-fee-levels' || resource === 'investment-fee-levels') {
                if (params.data.levelType === 'any_amount') {
                    params.data.levelType = 'over';
                    params.data.minimumAmount = 1;
                }

                return {
                    url: `${API_URL}/fee-charges/${params.data.feeId}${queryString}`,
                    options: {
                        method: 'POST',
                        body: JSON.stringify(params.data),
                    },
                };
            } else if (resource === 'firm/impersonate') {
                return {
                    url: `${API_URL}/${resource}/${params.id}${queryString}`,
                    options: { method: 'POST', body: JSON.stringify({}) },
                };
            } else if (resource === 'customer-referral/invitation') {
                return {
                    url: `${API_URL}/${resource}${queryString}`,
                    options: {
                        method: 'POST',
                        body: JSON.stringify(params.data),
                    },
                };
            } else if (resource === 'services') {
                const requestData = getAddServicePayload(params.data);

                return {
                    url: `${API_URL}/${resource}${queryString}`,
                    options: {
                        method: 'POST',
                        body: JSON.stringify(requestData),
                    },
                };
            } else if (resource === 'survey') {
                return {
                    url: `${SURVEY_API_URL}${queryString}`,
                    options: {
                        method: 'POST',
                        body: JSON.stringify(params.data),
                    },
                };
            } else if (resource === 'professional/request-callback') {
                return {
                    url: `${API_URL}/${resource}${queryString}`,
                    options: {
                        method: 'POST',
                        body: JSON.stringify(params),
                    },
                };
            } else {
                return {
                    url: `${API_URL}/${resource}${queryString}`,
                    options: {
                        method: 'POST',
                        body: JSON.stringify(params.data),
                    },
                };
            }
        case DELETE:
            if (
                (resource.includes('reviews') && resource.includes('respond')) ||
                (resource.includes('first-impressions') && resource.includes('respond'))
            ) {
                return {
                    url: `${API_URL}/${resource}${queryString}`,
                    options: { method: 'DELETE' },
                };
            } else if (resource === 'mortgage-fee-levels' || resource === 'investment-fee-levels') {
                return {
                    url: `${API_URL}/fee-charges/${params.id}${queryString}`,
                    options: { method: 'DELETE' },
                };
            } else if (resource === 'fees') {
                return {
                    url: `${API_URL}/${resource}/${params.data.id}${queryString}`,
                    options: { method: 'DELETE' },
                };
            } else {
                return {
                    url: `${API_URL}/${resource}/${params.id}${queryString}`,
                    options: { method: 'DELETE' },
                };
            }
        case 'upload-graphic':
            if (resource === 'professional') {
                return {
                    url: `${API_URL}/${resource}${queryString}`,
                    options: { method: 'PUT', body: JSON.stringify(params.data) },
                };
            } else if (resource === 'firm') {
                return {
                    url: `${API_URL}/${resource}/${params.id}${queryString}`,
                    options: { method: 'PUT', body: JSON.stringify(params.data) },
                };
            }
            break;
        default:
            throw new Error(`Unsupported fetch action type ${type}`);
    }
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} Data Provider response
 */
const convertHTTPResponseToDataProvider = (response, type, resource, params) => {
    let { json } = response;
    let total = 100;

    if (json && json.aggregate_data && json.aggregate_data.total !== null) {
        total = json.aggregate_data.total;
    } else if (json && json.aggregate_data && json.aggregate_data.count !== null) {
        total = json.aggregate_data.count;
    }

    if (resource === 'resetting/send-email') {
        return {
            data: { success: true },
        };
    }

    if (resource === 'review/single-invitation' || resource === 'review/multiple-invitation') {
        return {
            data: { id: 1, success: true },
        };
    }

    switch (type) {
        case GET_LIST:
            return {
                data: json.data ? json.data : [],
                total: total ? total : json.data.length,
            };
        case GET_ONE:
            if (!_.get(json, ['data', 'id']) && params && params.id) {
                json.data.id = params.id;
            }
            if (resource === 'fees' || resource === 'fees-edit') {
                let contentData = {};
                json.data.forEach(data => {
                    if (data.subType === 'mortgage') {
                        contentData.mortgage = data;
                    } else if (data.type === 'investment_and_pension_with_financial_planning') {
                        contentData.financialPlanning = data;
                    } else {
                        contentData.investment = data;
                    }
                });
                var feeData = {
                    id: params.id,
                    content: contentData,
                };
                json.data.forEach(function(fee) {
                    if (fee.subType === 'mortgage') {
                        feeData.mortgage = {
                            id: fee.id,
                        };
                    } else if (fee.type === 'investment_and_pension_with_financial_planning') {
                        feeData.financialPlanning = {
                            id: fee.id,
                        };
                    } else {
                        feeData.investment = {
                            id: fee.id,
                        };
                    }
                });
                return {
                    data: feeData,
                };
            } else {
                return {
                    data: { ...json.data, id: json.data?.id || 'no-id' },
                };
            }
        case UPDATE:
            if (resource === 'account-settings') {
                if (_.get(json, 'errors', false)) {
                    return { errors: json.errors };
                }

                return json;
            }
            if (!_.get(json, 'data.id') && params && params.id) {
                json.data.id = params.id;
            }
            return { data: json.data };
        case CREATE:
            if (resource.includes('review/first-invitation')) {
                json.data.id = 1;
            } else if (resource === 'register') {
                return json;
            }
            return { data: json.data, status: response.status };
        case DELETE:
            return {
                data: {
                    id: null,
                },
            };
        case GET_MANY_REFERENCE: {
            return {
                data: json.data,
                total: total,
            };
        }
        case UPDATE_MANY: {
            return {
                data: json.data,
            };
        }
        case GET_MANY: {
            if (
                resource === 'services-choices' ||
                resource === 'certificates' ||
                resource === 'fees/choices' ||
                resource.includes('driver-statistics') ||
                resource.includes('action-list')
            ) {
                return { data: json.data };
            }

            return { data: json };
        }
        default:
            return { data: json };
    }
};

const doRequest = (type, resource, params) => {
    // This first IF statement is very old and needs to be cleaned up.
    // What we're doing here is shortcutting the dataProvider on
    // these resources - it doesn't actually do an api call.

    // e.g. there isn't an endpoint for professional/experience, so we trick
    // the dataProvider into thinking the request has returned an id
    // via Promise.resolve({ data: ... });
    if (['professional/experience', 'billing'].indexOf(resource) !== -1 && type === 'GET_ONE') {
        return Promise.resolve({
            data: {
                id: params.id,
            },
        });
    } else {
        const { url, options = {} } = convertDataProviderRequestToHTTP(type, resource, params);
        let endpoint = url;
        if (endpoint.includes('elevation')) {
            endpoint = endpoint.replace('elevation/', '');
        }
        options.headers = new Headers({ Accept: 'application/json' });
        options.headers.set('vf-token', getLexikToken());

        return fetchUtils
            .fetchJson(endpoint, options)
            .then(response => convertHTTPResponseToDataProvider(response, type, resource, params));
    }
};

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} params Request parameters. Depends on the request type
 * @returns {Promise} the Promise for response
 */
export default (type, resource, params) => {
    if (_.has(params, 'data.file')) {
        if (!['CREATE', 'UPDATE'].includes(type)) {
            return doRequest(type, resource, params);
        }

        // if file is already base64 encoded
        if (_.has(params, 'data.file.dataURL')) {
            const newParams = createFileParamsForUpload(params, resource);

            return doRequest(type, resource, newParams);
        }

        return convertFileToBase64(params.data.file, resource).then(file => {
            params.data.file.dataURL = file;

            const newParams = createFileParamsForUpload(params, resource);

            return doRequest(type, resource, newParams);
        });
    }

    return doRequest(type, resource, params);
};
