import React, { Component } from 'react';
import _ from 'lodash';
import dataProvider from '../../dataProvider';
import CopyTextButton from '../../Components/Buttons/CopyTextButton';
import ReputationToolItem from './Components/ReputationToolItem';

import Button from '../../Components/Buttons/CustomButton';

class FirmWidgetRating extends Component {
    state = {
        widgetCode: null,
        widgetUrl: null,
    };

    async componentDidMount() {
        try {
            const { data } = await dataProvider('GET_ONE', 'firm/widget');

            if (!data) return;

            this.setState({
                widgetCode: _.get(data, 'widgetCode'),
                widgetUrl: _.get(data, 'widgetUrl'),
            });
        } catch {
            return null;
        }
    }

    sendToDeveloperAction = () => {
        const { widgetCode } = this.state;

        if (!widgetCode) return;

        window.location.href = `mailto:?subject=Please add this code to my website&body=Hi, please add this code to my website: ${widgetCode}`;
    };

    render() {
        const { widgetCode, widgetUrl } = this.state;

        return (
            <ReputationToolItem
                img={widgetUrl}
                actions={
                    <>
                        <CopyTextButton
                            clipboardContent={widgetCode}
                            style={{ margin: 4 }}
                            variant="contained"
                        >
                            Copy widget code
                        </CopyTextButton>
                        <Button
                            variant="contained"
                            color="secondary"
                            style={{ margin: 4 }}
                            onClick={this.sendToDeveloperAction}
                        >
                            Send to developer
                        </Button>
                    </>
                }
            />
        );
    }
}

export default FirmWidgetRating;
