import React, { useState, useEffect } from 'react';
import CustomChart from '../Charts/CustomChart';
import { palette } from '../../../../MyTheme';
import DashboardTable from '../Tables';
import Tip from '../Tip';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Button from '../../../../Components/Buttons/CustomButton';
import * as analytics from '../../../../analytics';

const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        padding: theme.spacing(2),
        marginBottom: theme.spacing(-5),
        marginTop: theme.spacing(-5),
        '& .MuiButton-containedPrimary': {
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        '& .MuiButton-containedSecondary': {
            backgroundColor: theme.palette.secondary.main,
            '&:hover': {
                backgroundColor: theme.palette.secondary.main,
            },
        },
        '& .MuiTypography-root': {
            marginTop: 0,
            marginBottom: 0,
        },
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.tertiary.contrastText,
        padding: theme.spacing(4),
        borderRadius: 5,
        '& .MuiButton-root': {
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
            borderRadius: 5,
            '&.disabled': {
                backgroundColor: theme.palette.primary.dark,
            },
        },
        '& .MuiButton-label': {
            fontWeight: 400,
        },
    },
    text: {
        marginBottom: `${theme.spacing(2)}px !important`,
        fontWeight: 700,
    },
}));

const ReviewStats = props => {
    const classes = useStyles();
    const { data, lines, bars, setReviewType, reviewType, filter, statistics } = props;
    const [barColors, setBarColors] = useState([palette.secondary.main]);
    const [lineColors] = useState([palette.error.main]);
    const [firstImpressionsExist, setFirstImpressionsExist] = useState(true);
    const [reviewsExist, setReviewsExist] = useState(true);

    useEffect(() => {
        if (reviewType === 'first_impressions') {
            setBarColors([palette.primary.main]);
        }

        if (reviewType === 'reviews') {
            setBarColors([palette.secondary.main]);
        }
    }, [reviewType]);

    useEffect(() => {
        if (statistics && statistics.reviews_365_days == 0) {
            setReviewsExist(false);
            setReviewType('first_impressions');
        }
        if (statistics && statistics.fi_365_days == 0) {
            setFirstImpressionsExist(false);
            setReviewType('reviews');
        }
    }, [statistics]);

    const handleTableForwardNav = () => analytics.track('next', { category: 'reviews_table' });
    const handleTableBackwardNav = () =>
        analytics.track('previous', { category: 'reviews_table' });

    return (
        <>
            {reviewType === 'reviews' && reviewsExist && !firstImpressionsExist ? (
                <Tip
                    text="Invite prospects to find out who intends to become a client"
                    showInviteButton
                    inviteButtonLabel="Invite Prospects"
                    singleButtonNewLine
                />
            ) : null}
            {reviewType === 'first_impressions' && !reviewsExist && firstImpressionsExist ? (
                <Tip
                    text="Invite reviews from your clients to drive your dashboard data and see feedback"
                    showInviteButton
                    singleButtonNewLine
                />
            ) : null}
            {reviewsExist && firstImpressionsExist ? (
                <div className={classes.wrapper}>
                    <Typography variant="h4">
                        Toggle between Reviews and First Impressions view
                    </Typography>
                    <Typography variant="body2" className={classes.text}>
                        (For both chart and table)
                    </Typography>
                    <div className={classes.buttonWrapper}>
                        <Button
                            className={`${reviewType === 'reviews' ? '' : 'disabled'} `}
                            onClick={() => setReviewType('reviews')}
                            color="secondary"
                            variant="contained">
                            Reviews
                        </Button>
                        <Button
                            className={`${reviewType === 'first_impressions' ? '' : 'disabled'} `}
                            onClick={() => setReviewType('first_impressions')}
                            color="primary"
                            variant="contained">
                            First Impressions
                        </Button>
                    </div>
                </div>
            ) : null}
            <CustomChart
                dataPoints={data}
                bars={bars}
                lines={lines}
                lineColors={lineColors}
                barColors={barColors}
            />
            {filter === 'fi_and_prospect_intention' ? (
                <Tip text="‘Prospect Intention’: Those who left you a First Impression, that said they would use your services in the future" />
            ) : null}

            <DashboardTable
                key={filter}
                filter={filter}
                onClickForward={handleTableForwardNav}
                onClickBackwards={handleTableBackwardNav}
                showCtaButton={true}
            />
        </>
    );
};

export default ReviewStats;
