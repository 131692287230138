import React, { useState } from 'react';
import { Redirect } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import Button from '../../Components/Buttons/CustomButton';

const SkipFirstInvitationStep = () => {
    const [clicked, setClicked] = useState(false);

    if (clicked) {
        window.scrollTo(0, 0);

        return <Redirect to="/" />;
    }

    return (
        <Grid container justify="center">
            <Grid item>
                <Button 
                    color="tertiary"
                    variant="outlined" 
                    onClick={() => setClicked(true)}
                >
                    or skip this step for now
                </Button>
            </Grid>
        </Grid>
    );
};

export default SkipFirstInvitationStep;
