import React from 'react';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core";
import CustomIcon from '../../icons/CustomIcon';
const ELEVATION_POINTS = [
    {
        title: `understand and improve their clients’ experience`,
    },
    {
        title: `prioritise needs for training and development`,
    },
    {
        title: `identify opportunities to increase referrals and conversion`,
    },
    {
        title: `embrace the Consumer Duty and gain competitive advantage`,
    },
];

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        paddingBottom: theme.spacing(3),
        margin: `0 ${theme.spacing(2)}px`,

        [theme.breakpoints.down('xs')]: {
            margin: 0,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    para: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        },
    },
    elevationList: {
        padding: 0,
        listStyleType: 'none',
        marginTop: 0,
    },
    elevation: {
        display: 'flex',
    },
    elevationText: {
        marginLeft: theme.spacing(1),
    },
}));

const ElevationList = () => {
    const classes = useStyles();

    return (
        <section className={classes.container}>
            <Typography variant="body1" className={classes.para}>
                <b>For firm bosses and advisers who want to:</b>
            </Typography>
            <ul className={classes.elevationList}>
                {
                    ELEVATION_POINTS.map(elevation =>
                        <Typography
                            variant="body1"
                            component="li"
                            key={elevation.title}
                            className={classes.elevation}
                        >
                            <CustomIcon icon="tick" color="mint" small marginRightOnly />
                            <div className={classes.elevationText}>
                                <span>{ elevation.title }</span>
                            </div>
                        </Typography>
                    )
                }
            </ul>
            <Typography variant="body1" className={classes.para}>
                Elevation is our turbo-charged client survey.  Powered by client feedback and cross-industry analysis, it shows advice firms the specific things they can do to improve client experience, meet the Consumer Duty and drive revenue.            </Typography>
        </section>
    );
}

export default ElevationList;
