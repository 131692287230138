import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import { FormSpy } from 'react-final-form';
import FeesChargeLevelBanner from './FeesChargeLevelBanner';
import CountUpNumber from '../../Components/Typography/CountUpNumber';
import LoadingSpinner from '../../Components/ConsumerReactComponents/LoadingSpinner';
import { ASSET_LEVEL_CHOICES, AVERAGE_FIVE_YEAR_COST } from '../../constants';
import { getFeesStatisticFormattedValue, getFeesStatisticsRenderProps } from '../feesUtils';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FormSelectInput from '../../Components/Inputs/Form/FormSelectInput';

const { TOTAL, UK_WIDE, HIGHER_PERCENT, LOWER_PERCENT } = AVERAGE_FIVE_YEAR_COST;

const propTypes = {
    insights: PropTypes.object,
};

const useStyles = makeStyles(theme => ({
    selectDropdown: {
        marginTop: 0,
        marginBottom: theme.spacing(5),
        display: 'flex',
        alignItems: 'center',
    },
    selectInput: {
        maxWidth: '100%',
    },
    loadingWrapper: {
        minHeight: theme.spacing(19.5),
    },
    statCol: {
        textAlign: 'center',
        padding: `0 ${theme.spacing(2)}px`,
    },
    statTwo: {
        borderLeft: `1px solid ${theme.palette.primary.dark}`,
        borderRight: `1px solid ${theme.palette.primary.dark}`,
        [theme.breakpoints.down('md')]: {
            borderLeft: 0,
            borderRight: 0,
        },
    },
    statThree: {
        borderRight: `1px solid ${theme.palette.primary.dark}`,
        [theme.breakpoints.down('md')]: {
            borderRight: 0,
        },
    },
    statColDisabled: {
        '& h1, & p': {
            color: theme.palette.tertiary.dark,
        },
    },
    summaryWrapper: {
        backgroundColor: theme.palette.secondary.faint,
        marginBottom: theme.spacing(5),
        padding: theme.spacing(1),
        textAlign: 'center',
    },
}));

const Stat = props => {
    const classes = useStyles();
    const { isContentDisabled, stat, label, color, value } = props;

    const className =
        isContentDisabled || !value
            ? cn(classes.statCol, classes.statColDisabled)
            : classes.statCol;

    return (
        <div className={className}>
            <CountUpNumber
                color={color}
                value={value}
                formatValue={value => getFeesStatisticFormattedValue(value, stat)}
            />
            <Typography variant="body1">{label}</Typography>
        </div>
    );
};

const FeesComparisonTable = props => {
    const classes = useStyles();
    const { loading, children, isLoggedOut, insights, onDropdownChange, isContentDisabled } = props;

    const statistic = getFeesStatisticsRenderProps(isLoggedOut, _.get(insights, 'asset_level'));
    const insightsSummary = _.get(insights, 'summary', null);
    const statistics = _.get(insights, 'statistics', {});
    const gridItemColSizes = { lg: 3, md: 3, sm: 12, xs: 12 };

    return (
        <React.Fragment>
            <FormSpy
                subscription={{ values: true }}
                onChange={({ values }) => {
                    const assetLevel = _.get(values, 'params.assetLevel', '');

                    if (assetLevel) {
                        onDropdownChange(assetLevel);
                    }
                }}
            />
            <Grid container justify="center">
                {insightsSummary && (
                    <Grid className={classes.summaryWrapper} item md={12} sm={12} xs={12}>
                        <FeesChargeLevelBanner summary={insightsSummary} />
                    </Grid>
                )}
                {onDropdownChange && (
                    <Grid item lg={3} md={6} sm={6} xs={12}>
                        <FormSelectInput
                            label="Asset level:"
                            source="params.assetLevel"
                            choices={ASSET_LEVEL_CHOICES}
                            defaultValue={ASSET_LEVEL_CHOICES[0].id}
                            disabled={loading}
                            className={classes.selectDropdown}
                            InputProps={{
                                className: classes.selectInput,
                            }}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid container justify="center">
                <Grid item xl={12} lg={10} md={10} sm={12} xs={12}>
                    <Grid
                        container
                        justify="center"
                        className={loading ? null : classes.statisticsCols}
                    >
                        {loading ? (
                            <Grid className={classes.loadingWrapper} item md={12}>
                                <LoadingSpinner />
                            </Grid>
                        ) : (
                            <React.Fragment>
                                <Grid item {...gridItemColSizes}>
                                    <Stat
                                        {...statistic[TOTAL]}
                                        value={isContentDisabled ? null : statistics[TOTAL]}
                                        isContentDisabled={isContentDisabled}
                                    />
                                </Grid>
                                <Grid item className={classes.statTwo} {...gridItemColSizes}>
                                    <Stat
                                        {...statistic[UK_WIDE]}
                                        value={isContentDisabled ? null : statistics[UK_WIDE]}
                                        isContentDisabled={isContentDisabled}
                                    />
                                </Grid>
                                <Grid item className={classes.statThree} {...gridItemColSizes}>
                                    <Stat
                                        {...statistic[HIGHER_PERCENT]}
                                        value={
                                            isContentDisabled ? null : statistics[HIGHER_PERCENT]
                                        }
                                        isContentDisabled={isContentDisabled}
                                    />
                                </Grid>
                                <Grid item {...gridItemColSizes}>
                                    <Stat
                                        {...statistic[LOWER_PERCENT]}
                                        value={isContentDisabled ? null : statistics[LOWER_PERCENT]}
                                        isContentDisabled={isContentDisabled}
                                    />
                                </Grid>
                            </React.Fragment>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            {children}
        </React.Fragment>
    );
};

FeesComparisonTable.propTypes = propTypes;

export default FeesComparisonTable;
