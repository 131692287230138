import React from 'react';
import PropTypes from 'prop-types';

import Skeleton from '@material-ui/lab/Skeleton';

const propTypes = {
    loading: PropTypes.bool.isRequired,
    children: PropTypes.any.isRequired,
};

const SkeletonWrapper = ({ loading, children, ...rest }) => {
    if (loading) {
        return <Skeleton {...rest} animate="pulse" />;
    }

    return children;
};

SkeletonWrapper.propTypes = propTypes;

export default SkeletonWrapper;
