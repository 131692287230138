import React, { useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { useForm, FormSpy } from 'react-final-form';
import { FormDataConsumer, required, ArrayInput } from 'react-admin';
import SimpleFormIterator from '../../../Components/SimpleFormIterator';
import FootNote from '../../../Components/Typography/FootNote';
import FeesTieredButtonsField from '../FeesTieredButtonsField';
import TrueFalseInput from '../../../Components/Forms/TrueFalseInput';
import { getFeesCreateFormLabels } from '../../feesUtils';
import {
    FEE_SUB_TYPE,
    FEE_TYPE,
    FEE_TYPE_CHOICES,
    FEE_SUB_TYPE_CHOICES,
    FEE_SUB_TYPE_CHOICES_LOGGED_OUT,
} from '../../../constants';

import { Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import FormTextInput from '../../../Components/Inputs/Form/FormTextInput';
import FormReferenceInput from '../../../Components/Inputs/Form/FormReferenceField';
import FormSelectInput from '../../../Components/Inputs/Form/FormSelectInput';

const { INVESTMENT_AND_PENSION, INVESTMENT_AND_PENSION_WITH_PLANNING, MORTGAGE } = FEE_TYPE;
const { INITIAL_AND_ONGOING } = FEE_SUB_TYPE;

const useStyles = makeStyles(theme => ({
    feeTypeInput: {
        maxWidth: 656,

        [theme.breakpoints.down('xs')]: {
            '& label': { fontSize: '0.8em' },
        },
    },
}));

const FeesCreateFormContent = props => {
    const { isLoggedIn } = props;
    const [values, setValues] = useState({});
    const { change } = useForm();
    const classes = useStyles();
    const theme = useTheme();
    const isWidthSmDown = useMediaQuery(theme.breakpoints.down('sm'));

    const feeType = _.get(values, 'type');
    const isMortgageFeeType = feeType === MORTGAGE;
    const renderFees = Boolean(feeType) && !isMortgageFeeType;

    const getFeesCreateInitialValues = type => {
        const investmentWithoutPlanningOption = {
            type: INVESTMENT_AND_PENSION,
            subType: '',
        };

        if (type === INVESTMENT_AND_PENSION_WITH_PLANNING) {
            return [
                { type: INVESTMENT_AND_PENSION_WITH_PLANNING, subType: '' },
                { ...investmentWithoutPlanningOption },
            ];
        }

        return [investmentWithoutPlanningOption];
    };

    return (
        <>
            <FormSpy
                subscription={{ values: true }}
                onChange={({ values }) => {
                    setValues(values);
                }}
            />
            {isLoggedIn ? (
                <FormReferenceInput
                    label="Choose type of fee"
                    source="type"
                    className={classes.feeTypeInput}
                    validate={[required()]}
                    reference="fees/choices"
                    resource="fees"
                    onChange={e => {
                        change('fees', getFeesCreateInitialValues(e.target.value));
                    }}
                >
                    <FormSelectInput optionText="name" />
                </FormReferenceInput>
            ) : (
                <FormSelectInput
                    label="Choose type of fee"
                    validate={[required()]}
                    className={classes.feeTypeInput}
                    source="type"
                    fullWidth
                    choices={FEE_TYPE_CHOICES}
                    onChange={e => {
                        change('fees', getFeesCreateInitialValues(e.target.value));
                    }}
                />
            )}
            {renderFees && (
                <ArrayInput source="fees" label="" style={{ margin: 0 }}>
                    <SimpleFormIterator disableAdd disableRemove>
                        <FormDataConsumer>
                            {({ formData, getSource, scopedFormData, ...rest }) => {
                                const type = _.get(scopedFormData, 'type', null);
                                const subType = _.get(scopedFormData, 'subType', null);
                                const withFinancialPlanning =
                                    formData.type === INVESTMENT_AND_PENSION_WITH_PLANNING;
                                const inputLabel = getFeesCreateFormLabels(
                                    type,
                                    withFinancialPlanning,
                                    isWidthSmDown
                                );

                                return (
                                    <>
                                        <FormTextInput
                                            key="type"
                                            style={{ display: 'none' }}
                                            source={getSource('type')}
                                        />
                                        <FormSelectInput
                                            key="subType"
                                            {...rest}
                                            validate={[required()]}
                                            label={inputLabel.subType}
                                            source={getSource('subType')}
                                            fullWidth
                                            choices={
                                                isLoggedIn
                                                    ? FEE_SUB_TYPE_CHOICES
                                                    : FEE_SUB_TYPE_CHOICES_LOGGED_OUT
                                            }
                                        />
                                        {subType === INITIAL_AND_ONGOING && (
                                            <FeesTieredButtonsField
                                                source={getSource('isWealthBased')}
                                                label={inputLabel.isWealthBased}
                                            />
                                        )}
                                    </>
                                );
                            }}
                        </FormDataConsumer>
                    </SimpleFormIterator>
                </ArrayInput>
            )}
            <FormDataConsumer>
                {({ formData }) => {
                    if (!formData) return;

                    if (_.get(formData, 'type', '') !== INVESTMENT_AND_PENSION_WITH_PLANNING)
                        return;

                    return (
                        <React.Fragment>
                            <TrueFalseInput
                                label="Financial planning fee can be offset against initial fee for implementation"
                                source="fees[0].offsetAgainstInitialFee"
                                defaultValue={false}
                            />
                            {_.get(formData, ['fees', '0', 'offsetAgainstInitialFee'], false) && (
                                <Typography variant="body1" color="error">
                                    As your fees can be offset, your fee report and insights won’t
                                    quite work yet. You can still add your fees to your profile and
                                    display them to prospective clients!
                                </Typography>
                            )}
                        </React.Fragment>
                    );
                }}
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData }) => {
                    const type = _.get(formData, 'type', null);

                    if (!type) return;

                    if (type === INVESTMENT_AND_PENSION_WITH_PLANNING) {
                        return (
                            <FootNote
                                content={
                                    <span>
                                        on the next step, you’ll be asked to add <b>fee levels</b>{' '}
                                        to detail your fees at different asset levels.
                                        <br />
                                        The Financial Planning fees you add will <b>added</b> to
                                        your Implementation & Ongoing fees to get your total cost.
                                    </span>
                                }
                            />
                        );
                    }

                    return (
                        <FootNote
                            content={
                                <span>
                                    on the next step, you’ll be asked to add <b>fee levels</b> to
                                    detail your fees at different asset levels.
                                </span>
                            }
                        />
                    );
                }}
            </FormDataConsumer>
        </>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        isLoggedIn: _.get(basicInfo, 'is_logged_in', false),
    };
};

export default connect(mapStateToProps)(FeesCreateFormContent);
