import React from 'react';
import { useQuery } from 'react-admin';
import * as analytics from '../../analytics';
import Button from '../../Components/Buttons/CustomButton';
import { Typography } from '@material-ui/core';
import { ExternalLink } from '../../Components/Links';
import dataProvider from '../../dataProvider';
import EmailSignatureCard from '../Card/EmailSignatureCard';

const ANALYTICS_LABEL = {
    read_my_reviews: 'no-logo-reviews-rating',
    view_my_profile: 'simple',
    review_my_reviews_with_logo: 'logo-reviews-rating',
    leave_me_a_review: 'leave-me-a-review',
    top_rated_2019: 'top-rated-2019',
    top_rated_2020_dark: 'Top Rated 2020 dark',
    top_rated_2020_stars: 'Top Rated 2020 coloured stars',
    top_rated_2020_light: 'Top Rated 2020 light',
    top_rated_2021_dark: 'Top Rated 2021 dark',
    top_rated_2021_stars: 'Top Rated 2021 coloured stars',
    top_rated_2021_light: 'Top Rated 2021 light',
};

const ProfessionalEmailSignatures = () => {
    const { data } = useQuery({
        type: 'getOne',
        resource: 'reputation/email-signatures',
    });

    const handleClick = ({ id, type_gka }) => async () => {
        await dataProvider('CREATE', `reputation/usage/email-signature/${id}`, {
            data: {},
        });

        analytics.track('Email signature downloaded', {
            category: 'Profile promotion',
            label: ANALYTICS_LABEL[type_gka],
        });
    };

    return (
        <>
            {data ? (
                Object.entries(data.list).map(([groupName, signatures]) => (

                    signatures.map((item, i) => (
                        <EmailSignatureCard
                            key={i}
                            image={item.image_url}
                            actions={
                                <ExternalLink
                                    href={item.image_url}
                                    onClick={handleClick(item)}
                                >
                                    <Button variant="contained"
                                            color="tertiary"
                                            style={{ margin: 'auto' }}>
                                        Download
                                    </Button>
                                </ExternalLink>
                            }
                        />
                    ))
                ))
            ) : (
                <Typography>Failed to fetch email signatures</Typography>
            )}
        </>
    );
};

export default ProfessionalEmailSignatures;
