import React from 'react';
import DialogBase from '../../Components/Dialogs/DialogBase';
import { makeStyles } from '@material-ui/core';
import FormLongTextInput from '../../Components/Inputs/Form/FormLongTextInput';
import { DeleteReplyButton } from '../../Components/Buttons/Buttons';
import { required, Button } from 'react-admin';
import { useForm } from 'react-final-form';
import { ReviewsNote } from '../ReviewEdit';

const useStyles = makeStyles(theme => ({
    dialog: {
        width: '450px',
        maxWidth: '95%',
        '& .MuiDialogTitle-root': {
            fontSize: '14px',
            wordBreak: 'break-all',
        },
        '& .MuiDialogActions-root': {
            display: 'none',
        },
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    justifySingle: {
        justifyContent: 'flex-end',
    },
    justifyMore: {
        justifyContent: 'space-between',
    },
}));

const ReviewReply = ({ showDialog, handleClose, record, deleteReply, source, title, ...rest }) => {
    const classes = useStyles();
    const form = useForm();

    const handleSave = () => {
        form.submit();
        handleClose();
    };

    return (
        <DialogBase className={classes.dialog} open={showDialog} onClose={handleClose}>
            <ReviewsNote title={title} />
            <FormLongTextInput
                label="Add reply"
                source={source}
                validate={[required()]}
                onChange={e => form.change(source, e.target.value)}
            />
            <div className={`${classes.buttonWrapper} ${record.professional_response ? classes.justifyMore : classes.justifySingle}`}>
                {record.professional_response && (
                    <DeleteReplyButton
                        record={record}
                        {...rest}
                        clickHandler={deleteReply}
                        recordProperty={source}
                    />
                )}
                <Button color="primary" variant="contained" label="Save" onClick={handleSave} />
            </div>
        </DialogBase>
    );
};

export default ReviewReply;
