import React from 'react';
import PropTypes from 'prop-types';
import ProfileAvatar from './ProfileAvatar';

import Skeleton from '@material-ui/lab/Skeleton';
import withStyles from '@material-ui/core/styles/withStyles';

const propTypes = {
    loading: PropTypes.bool.isRequired,
};

const defaultProps = {
    loading: true,
};

const styles = theme => ({
    container: {
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',

        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    avatarWrap: {
        [theme.breakpoints.up('md')]: {
            marginRight: theme.spacing(1),
        },

        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
});

const ProfilePanel = props => {
    const { classes, loading, children } = props;

    return (
        <div className={classes.container}>
            <div className={classes.avatarWrap}>
                {loading ? (
                    <Skeleton
                        variant="circle"
                        height={160}
                        width={160}
                        style={{ marginRight: 24 }}
                    />
                ) : (
                    <ProfileAvatar loading={loading} />
                )}
            </div>
            <div>{children}</div>
        </div>
    );
};

ProfilePanel.defaultProps = defaultProps;
ProfilePanel.propTypes = propTypes;

export default withStyles(styles)(ProfilePanel);
