import React from 'react';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core";
import CustomIcon from '../../icons/CustomIcon';
const PRICING_POINTS = [
    {
        title: `Adviser and firm profile, featuring specialisms`,
    },
    {
        title: `Improved SEO`,
    },
    {
        title: `Unlimited client and prospect reviews`,
    },
    {
        title: `Dedicated Success Manager`,
    },
    {
        title: `Digital reputation tools for website, social, email, Zoom`,
    },
    {
        title: `Printed reputation tools, including Certificate of Excellence`,
    },
    {
        title: `Opportunity to qualify for VouchedFor Top Rated Adviser and Top Rated Firm, distributed in the Times`,
    },
];

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        paddingBottom: theme.spacing(3),
        margin: `0 ${theme.spacing(2)}px`,

        [theme.breakpoints.down('xs')]: {
            margin: 0,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    para: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        },
        fontSize: '0.8rem',
    },
    pricingList: {
        padding: 0,
        listStyleType: 'none',
    },
    pricing: {
        display: 'flex',
        marginBottom: theme.spacing(2),
    },
    pricingText: {
        marginLeft: theme.spacing(1),
    },
}));

const PricingList = () => {
    const classes = useStyles();

    return (
        <section className={classes.container}>
            <ul className={classes.pricingList}>
                {
                    PRICING_POINTS.map(pricing =>
                        <Typography
                            variant="body1"
                            component="li"
                            key={pricing.title}
                            className={classes.pricing}
                        >
                            <CustomIcon icon="tick" color="navy" small marginRightOnly />
                            <div className={classes.pricingText}>
                                <span>{ pricing.title }</span>
                            </div>
                        </Typography>
                    )
                }
            </ul>
            <Typography variant="body1" className={classes.para}>
                *£36 + VAT for accountants; discount available for newly qualified financial advisers and protection only advisers.
            </Typography>
        </section>
    );
}

export default PricingList;
