import React, { useState } from 'react';
import _ from 'lodash';
import { useForm } from 'react-final-form';
import FormGrid from './FormGrid';
import ErrorButton from '../../Components/Buttons/ErrorButton';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles'; 
import FormSelectInput from '../../Components/Inputs/Form/FormSelectInput';

const useStyles = makeStyles((theme) => ({
    buttonMobile: {
        marginLeft: theme.spacing(1),
    },
    cellMobile: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
        },
    },
}));

const VerifyServicesGrid = ({ record }) => {
    const [hiddenServices, setHiddenServices] = useState([]);
    const classes = useStyles();
    const form = useForm();

    const handleDelete = (idx, serviceId) => e => {
        form.change(`services[${idx}].service_state`, 0);

        setHiddenServices([...hiddenServices, serviceId]);
    };

    return (
        <FormGrid
            record={record.services}
            columns={['Service', 'Status', ' ']}
            renderRow={(item, i) => {
                if (hiddenServices.includes(item.id)) {
                    return null;
                }

                return (
                    <TableRow key={item.id}>
                        <TableCell>
                            { item.attribute_name }
                        </TableCell>
                        <Hidden mdDown>
                            <TableCell>
                            {item.service_state &&
                                <FormSelectInput
                                    label=""
                                    source={`services[${i}].service_state`}
                                    choices={_.get(item,'attribute.services_choices', [])  || [] }
                                    helperText={false}
                                />
                            }
                            </TableCell>
                            <TableCell>
                                <ErrorButton onClick={handleDelete(i, item.id)}>
                                    Delete
                                </ErrorButton>
                            </TableCell>
                        </Hidden>
                        <Hidden mdUp>
                            <TableCell className={classes.cellMobile}>
                                <FormSelectInput
                                    label=""
                                    source={`services[${i}].service_state`}
                                    choices={_.get(item,'attribute.services_choices', [])  || [] }
                                />
                                <ErrorButton className={classes.buttonMobile} onClick={handleDelete(i, item.id)}>
                                    Delete
                                </ErrorButton>
                            </TableCell>
                        </Hidden>
                    </TableRow>
                );
            }}
        />
    );
};


export default VerifyServicesGrid;
