import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import defaultTheme from './MyTheme';
import Layout from './AppLayout/Layout';
import { initSocialShare } from './utils';
import { onRouteChange } from './state/actions/layoutActions';

import { ThemeProvider as MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
const propTypes = {
    theme: PropTypes.object,
};

const defaultProps = {
    theme: defaultTheme,
};

class MyLayout extends Component {
    constructor(props) {
        super(props);
        this.theme = createMuiTheme(props.theme);
    }

    componentDidMount() {
        initSocialShare();

        this.props.onRouteChange();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setBodyStyles();
        }
    }

    componentWillReceiveProps(nextProps) {
        const { theme, location, onRouteChange } = this.props;

        if (nextProps.theme !== theme) {
            this.theme = createMuiTheme(nextProps.theme);
        }

        if (nextProps.location.pathname !== location.pathname) {
            onRouteChange();
        }
    }

    setBodyStyles = () => {
        if (window.location.pathname === '/qualifications/create') {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    };

    render() {
        const { theme, onRouteChange, ...rest } = this.props;

        return (
            <MuiThemeProvider theme={this.theme}>
                <Layout {...rest} />
            </MuiThemeProvider>
        );
    }
}

MyLayout.propTypes = propTypes;
MyLayout.defaultProps = defaultProps;

export default withRouter(
    connect(
        null,
        { onRouteChange }
    )(MyLayout)
);
