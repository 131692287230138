import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import dataProvider from '../../dataProvider';
import { FormSpy } from 'react-final-form';
import { RadioButtonGroupInput, FormDataConsumer } from 'react-admin';
import FeesInsightsCard from './FeesInsightsCard';
import FeesChargeGraph from './FeesChargeGraph';
import ContentTooltip from '../../Components/ConsumerReactComponents/ContentTooltip';
import {
    ASSET_LEVEL_CHOICES,
    CALCULATED_COST,
    FEE_MODEL_CHOICES,
    FEE_SUB_TYPE,
} from '../../constants';
import { getFeeInsightsGraphDummyData } from '../../utils';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import FormSelectInput from '../../Components/Inputs/Form/FormSelectInput';

const DUMMY_GRAPH_DATA = getFeeInsightsGraphDummyData();

const useStyles = makeStyles(theme => ({
    radioGroup: {
        maxWidth: '100%',
        '& .MuiIconButton-label div': {
            color: 'inherit'
        },

        '& > div': {
            flexDirection: 'column',
        },
    },
}));

const FeesChargeStatistics = props => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(props.isContentDisabled ? DUMMY_GRAPH_DATA : null);
    const { isContentDisabled, showUnlockBanner, onStarterPlan, hasFeesChargeLevels } = props;

    const fetchChartData = async ({ assetLevel, model }) => {
        const params = {
            filter: { model, assetLevel },
        };

        const res = await dataProvider('GET_ONE', `fees-insights/charges`, params);

        setLoading(false);
        setData(_.get(res, 'data', null));
    };

    useEffect(() => {
        fetchChartData({
            assetLevel: ASSET_LEVEL_CHOICES[0].id,
            model: FEE_MODEL_CHOICES[0].id,
        });
    }, []);

    return (
        <FeesInsightsCard
            showUnlockBanner={showUnlockBanner}
            title="What are other advisers in the UK charging?"
        >
            <FormSpy
                subscription={{ values: true }}
                onChange={({ values }) => {
                    if (values.params) {
                        fetchChartData(values.params);
                    }
                }}
            />
            <Grid container spacing={3}>
                <Grid item md={12} sm={12} xs={12}>
                    <Grid container spacing={3}>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <FormSelectInput
                                label="Asset level:"
                                source="params.assetLevel"
                                choices={ASSET_LEVEL_CHOICES}
                                defaultValue={ASSET_LEVEL_CHOICES[0].id}
                                disabled={loading || isContentDisabled}
                                fullWidth
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <ContentTooltip placement="top" title="Location filter coming soon">
                                <FormSelectInput
                                    label="Location:"
                                    source="params.location"
                                    disabled
                                    choices={[
                                        {
                                            id: '50',
                                            name: 'Within 50 miles of me',
                                        },
                                    ]}
                                    defaultValue={'50'}
                                    fullWidth
                                />
                            </ContentTooltip>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <RadioButtonGroupInput
                        label="Fee model:"
                        choices={FEE_MODEL_CHOICES}
                        source="params.model"
                        defaultValue={FEE_MODEL_CHOICES[0].id}
                        className={classes.radioGroup}
                        disabled={loading || isContentDisabled}
                    />
                </Grid>
                <FormDataConsumer>
                    {({ formData }) => {
                        const model = _.get(formData, ['params', 'model'], null);
                        const prefix = model === FEE_SUB_TYPE.FIXED_FEE_ONLY ? '£' : '%';

                        return (
                            <React.Fragment>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <FeesChargeGraph
                                        disabled={isContentDisabled}
                                        data={_.get(data, 'initial_charge_graph', null)}
                                        title={`Number of advisers, split by initial ${prefix} fee`}
                                        xAxis={{
                                            label: 'Initial fee',
                                            dataKey: 'group',
                                        }}
                                        onStarterPlan={onStarterPlan}
                                        hasFeesChargeLevels={hasFeesChargeLevels}
                                    />
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <FeesChargeGraph
                                        disabled={isContentDisabled}
                                        data={_.get(data, 'ongoing_charge_graph', null)}
                                        title={`Number of advisers, split by ongoing ${prefix} fee`}
                                        xAxis={{
                                            label: 'Ongoing fee',
                                            dataKey: 'group',
                                        }}
                                        onStarterPlan={onStarterPlan}
                                        hasFeesChargeLevels={hasFeesChargeLevels}
                                    />
                                </Grid>
                                {model === CALCULATED_COST.INVESTMENT_AND_PENSION_WITH_PLANNING && (
                                    <Grid item md={12} sm={12} xs={12}>
                                        <FeesChargeGraph
                                            disabled={isContentDisabled}
                                            data={_.get(data, 'fixed_fees_graph', null)}
                                            title="Number of advisers, split by financial planning charge"
                                            xAxis={{
                                                label: 'Financial planning fee',
                                                dataKey: 'group',
                                            }}
                                            onStarterPlan={onStarterPlan}
                                            hasFeesChargeLevels={hasFeesChargeLevels}
                                        />
                                    </Grid>
                                )}
                            </React.Fragment>
                        );
                    }}
                </FormDataConsumer>
            </Grid>
        </FeesInsightsCard>
    );
};

export default FeesChargeStatistics;
