import React from 'react';
import _ from 'lodash';
import { Grid, Typography } from '@material-ui/core';
import BooleanSwitchInput from '../../Components/Inputs/BooleanSwitchInput';
import { ExternalLink } from '../../Components/Links';
import { SUPPORT_URL } from '../../constants';
import { connect } from 'react-redux';
import { FormSectionTitle } from '../../Components/Typography/Headlines';

const EnquirySettings = props => {
    const permissions = _.get(props, 'permissions', {});
    const match_enquiries = _.get(permissions, 'match_enquiries', null);

    return (
        <div>
            {match_enquiries && (
                <>
                    <FormSectionTitle title="Enquiry Settings" />
                    <BooleanSwitchInput
                        switchLabel="Opt into Matched enquiries"
                        source="attributes.enquiry_preferences_matched"
                        href="#!"
                    />
                </>
            )}
        </div>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        permissions: basicInfo.permissions,
    };
}

export default connect(mapStateToProps)(EnquirySettings);
