import React, {useEffect} from 'react';
import _ from 'lodash';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import ActionTable from './components/ActionTable';
import PageActions from '../AppLayout/PageActions';
import {showSidebar} from "../state/actions";
import {connect, useDispatch} from "react-redux";
import shouldShowSidebar from "../AppLayout/Sidebar/shouldShowSidebar";
import {useLocation} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        padding: '8px',
    },
    pageActionTitle: {
        fontSize: '35px',
    },
    flexContainer: {
        padding: '10px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        gap: '10px',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    centerAlign: {
        textAlign: 'center',
    },
    title: {
        color: `${theme.palette.primary.main} !important`,
    },
    icon: {
        marginRight: '15px',
        verticalAlign: 'top',
        fontSize: '28px',
    },
    trendingUpIcon: {
        color: `${theme.palette.secondary.dark}`,
    },
    errorIcon: {
        color: `${theme.palette.error.main}`,
    },
}));

const ActionListComponent = (props) => {
    const classes = useStyles();
    const location = useLocation();
    const dispatch = useDispatch();

    const { basicProfileComplete } = props;
    const sidebarVisible = shouldShowSidebar(location.pathname, basicProfileComplete);

    useEffect(() => {
        if (sidebarVisible) {
            dispatch(showSidebar());
        }
    }, []);


    return (
        <Box className={classes.root}>
            <PageActions
                title={`Elevation Action List`}
                className={classes.pageActionTitle}
                color={'textPrimary'}
            />
                <Box>
                    <Box className={classes.container}>
                        <ActionTable {...props} />
                    </Box>
                </Box>
        </Box>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        basicProfileComplete: _.get(basicInfo, 'permissions.basic_profile_complete', true),
    };
};

const actions = {
    showSidebar,
};

export default connect(
    mapStateToProps,
    actions
)(ActionListComponent);
