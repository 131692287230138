import ReviewList from './ReviewList';
import ReviewEdit from './ReviewEdit';
import ReviewInvitationPrevious from './ReviewInvitationPrevious';
import ReviewAutoInviteEdit from './ReviewAutoInviteEdit';
import ReviewInvite from './ReviewInvite';

export default {
    list: ReviewList,
    edit: ReviewEdit,
    listPreviousReviews: ReviewInvitationPrevious,
    createAutoInvite: ReviewAutoInviteEdit,
    invite: ReviewInvite,
};
