import React from 'react';
import { FormSectionTitle } from '../../Components/Typography/Headlines';
import { useForm } from 'react-final-form';
import { Button, Typography } from '@material-ui/core/';
import { futureDateValidation } from '../../validation';
import FormDateInput from '../../Components/Inputs/Form/FormDateInput';

const StartInput = () => {
    return (
        <FormDateInput
            label="Start date:"
            source="on_holiday_start"
        />
    );
};

const EndInput = () => {
    return (
        <FormDateInput
            validate={[futureDateValidation]}
            label="End date:"
            source="on_holiday_end"
            style={{ margin: '16px 0' }}
        />
    );
};

const EnquiryPauseFields = () => {
    const form = useForm();

    const handleEndHolidayClick = () => {
        form.batch(() => {
            form.change('on_holiday_start', '');
            form.change('on_holiday_end', '');
        });
    };

    return (
        <div>
            <FormSectionTitle title="Pause Enquiries" />
            <Typography>
                To pause direct enquiries through VouchedFor for a time period (such as a holiday),
                simply enter a start date for the break and end date for when you’d like to be
                contactable again, and then click save
            </Typography>
            <StartInput />
            <EndInput />
            <Button variant="outlined" color="primary" onClick={handleEndHolidayClick}>
                Cancel / End Holiday
            </Button>
        </div>
    );
};

export default EnquiryPauseFields;
