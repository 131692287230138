import React from 'react';
import {
    Filter,
    List,
    ReferenceManyField,
    Responsive,
    SimpleList,
    Datagrid,
    TextField,
    DateField,
} from 'react-admin';
import { formatSimpleListDate } from '../../utils';
import EnquiriesListButtons from './EnquiriesListButtons';
import FormTextInput from '../../Components/Inputs/Form/FormTextInput';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    datagrid: {
        '@media screen and (max-width: 1440px)': {
            '& thead > tr > th:nth-of-type(3)': {
                maxWidth: 200,
                overflow: 'hidden',
            },
            '& tbody > tr > td:nth-of-type(3)': {
                maxWidth: 200,
                overflow: 'hidden',
            },
        },
    },
}));

const EnquiriesListContent = props => {
    const classes = useStyles();

    return (
        <ReferenceManyField label="" reference="enquiries" target="professional_id" {...props}>
            <List
                hasList={true}
                title={null}
                filters={
                    <Filter {...props}>
                        <FormTextInput
                            label="Search by Client Name"
                            source="enquiry_message|redundant_customer_name"
                            alwaysOn
                        />
                    </Filter>
                }
                sort={{ field: 'created_at', order: 'DESC' }}
                bulkActionButtons={false}
                actions={null}
                {...props}
            >
                <Responsive
                    medium={
                        <SimpleList
                            primaryText={record => record.enquiry_message.redundant_customer_name}
                            secondaryText={record => formatSimpleListDate(record.created_at)}
                            tertiaryText={record => record.redundant_customer_phone}
                        />
                    }
                    large={
                        <Datagrid className={classes.datagrid}>
                            <TextField label="Enquiry Reference" source="id" />
                            <TextField
                                label="First name"
                                source="enquiry_message.redundant_customer_name"
                            />
                            <TextField
                                label="Email"
                                source="enquiry_message.redundant_customer_email"
                            />
                            <TextField
                                label="Phone"
                                source="enquiry_message.redundant_customer_phone"
                            />
                            <DateField label="Date" source="created_at" />
                            <EnquiriesListButtons />
                        </Datagrid>
                    }
                />
            </List>
        </ReferenceManyField>
    );
};

export default EnquiriesListContent;
