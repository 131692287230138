import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import { getHistory } from '../../Helpers/history';

const propTypes = {
    label: PropTypes.string,
};

const defaultProps = {
    label: 'Go Back',
};

class BackButton extends Component {
    getHref = () => {
        const { href, professionalId } = this.props;

        if (href) {
            return href.includes(':professionalId')
                ? href.replace(':professionalId', professionalId)
                : href;
        }

        return getHistory(2);
    };

    render() {
        return (
            <Button color="secondary" href={this.getHref()}>
                <ListIcon />
                {this.props.label}
            </Button>
        );
    }
}

BackButton.propTypes = propTypes;
BackButton.defaultProps = defaultProps;

const mapStateToProps = ({ basicInfo }) => {
    return {
        professionalId: basicInfo.id,
    };
};

export default connect(mapStateToProps)(BackButton);
