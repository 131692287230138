import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Datagrid,
    DateField,
    TextField,
    Responsive,
    SimpleList,
    Button,
    Labeled,
    FunctionField,
    ArrayField,
    SingleFieldList,
} from 'react-admin';
import { Box, Tooltip, Typography } from '@material-ui/core';
import ListWrapper from '../../../Elevation/ListWrapper';
import _ from 'lodash';
import CustomButton from '../../../../Components/Buttons/CustomButton';
import * as analytics from '../../../../analytics';
import ListPagination from '../../../Elevation/ListPagination';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';

const useStyles = makeStyles(theme => ({
    list: {
        border: '2px solid ' + theme.palette.tertiary.dark,
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
        '& .MuiToolbar-root': {
            float: 'right',
            '& .MuiButtonBase-root': {
                color: theme.palette.tertiary.main,
                border: '1px solid',
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1),
                '&.prev': {
                    paddingLeft: theme.spacing(1),
                    paddingRight: theme.spacing(2),
                },
                '&.next': {
                    paddingLeft: theme.spacing(2),
                    paddingRight: theme.spacing(1),
                },
            },
        },
        '& .MuiTableCell-root': {
            color: theme.palette.primary.main,
            padding: theme.spacing(1),
        },
        '& .MuiPaper-rounded': {
            padding: `${theme.spacing(1)}px ${theme.spacing(0)}px ${theme.spacing(
                1
            )}px ${theme.spacing(0)}px`,
        },
        '& .MuiInputLabel-shrink': {
            paddingLeft: 0,
        },
        '& .MuiTableCell-head': {
            fontSize: '0.9rem',
            fontWeight: 700,
            textTransform: 'uppercase',
            [theme.breakpoints.up('lg')]: {
                whiteSpace: 'nowrap'
            }
        }
    },
    cardRoot: {
        '& .MuiTable-root': {
            margin: 0,
        },
        [theme.breakpoints.down('sm')]: {
            '& .MuiList-root': {
                padding: theme.spacing(2),
            }
        },
    },
    review: {
        color: theme.palette.secondary.main,
    },
    firstImpression: {
        color: theme.palette.primary.main,
    },
    reviewAndFiText: {
        fontWeight: 700,
        fontStyle: 'italic',
        textTransform: 'uppercase',
    },
    buttonWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    button: {
        '& .MuiButton-label span': {
            whiteSpace: 'nowrap',
        },
        margin: theme.spacing(1),
        '&.MuiButton-outlined:hover': {
            backgroundColor: theme.palette.tertiary.dark,
            borderColor: theme.palette.tertiary.dark,
        },
        '&.MuiButton-outlined': {
            padding: '3px 10px',
        },
    },
    question: {
        '& .MuiFormLabel-root': {
            color: theme.palette.secondary.main,
            fontSize: theme.spacing(2.5),
            fontWeight: 700,
        },
    },
    shareButtonWrapper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        position: 'relative',
        justifyContent: 'space-around',
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'flex-start',
        },
        minHeight: 40,
    },
    red: {
        color: theme.palette.error.main,
    },
    green: {
        color: theme.palette.secondary.dark,
    },
    cta: {
        [theme.breakpoints.up('lg')]: {
            order: 1,
            left: '50%',
            transform: 'translateX(-50%)',
        },
    },
    pagination: {
        [theme.breakpoints.up('lg')]: {
            order: 2,
            right: '1.1rem',
            position: 'absolute',
        },
        marginLeft: 0,
        marginRight: 0,
        '& .MuiToolbar-gutters': {
            padding: 0,
        },
    },
    label: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: 'center',
    },
    icon: {
        fontSize: theme.spacing(2),
        color: theme.palette.tertiary.main,
        marginLeft: theme.spacing(0.5)
    },
    tooltip: {
        color: theme.palette.tertiary.main,
    }
}));

const CustomViewEditButton = ({ record, filter }) => {
    const classes = useStyles();

    if (record) {
        const { reviewer_type, id } = record;
        const type = reviewer_type === 'first_impression' ? '/first-impressions/' : '/reviews/';
        const label = 'View / Reply';
        const buttonHref = `${type}${id}${reviewer_type === 'first_impression' ? '/show' : ''}`;
        return (
            <div className={classes.buttonWrapper}>
                <Button
                    variant="contained"
                    className={classes.button}
                    label={label}
                    href={buttonHref}
                    onClick={() => analytics.track('view_reply', { category: filter })}
                />
                <CustomButton
                    variant="outlined"
                    className={classes.button}
                    href={buttonHref}
                    color="tertiary"
                    onClick={() => analytics.track('share', { category: filter })}>
                    Share
                </CustomButton>
            </div>
        );
    }
    return null;
};

const DashboardTable = props => {
    const {
        filter,
        onClickForward,
        onClickBackwards,
        ctaLabel = 'Invite reviews',
        ctaUrl = '/invite-reviews',
        showCtaButton,
    } = props;

    const classes = useStyles();
    const [conditionalFields] = useState({
        fi_and_prospect_intention: [
            {
                type: 'customTextColorField',
                label: 'Prospect intention',
                source: 'prospect_intention',
                tooltip:
                    'Those who left you a First Impression, that said they would use your services in the future',
            },
            {
                type: 'customYesNoField',
                label: 'Replied?',
                source: 'has_professional_response',
                tooltip: 'Have you replied to this Review / First Impression',
            },
        ],
        reviews: [
            {
                type: 'customYesNoField',
                label: 'Replied?',
                source: 'has_professional_response',
                tooltip: 'Have you replied to this Review / First Impression',
            },
        ],
        influences: [
        ],
        referrers: [
            {
                type: 'customText',
                label: 'Referral requested',
                source: 'customer.referral_requested',
                tooltip:
                    'Have you sent a referral request to this client via the referral generator',
            },
        ],
    });

    const CustomField = ({ record, data }) => {
        switch (data.type) {
            case 'customTextColorField':
                return (
                    <FunctionField
                        source={data.source}
                        record={record}
                        render={record => (
                            <span
                                className={
                                    record[data.source] === 'Yes'
                                        ? classes.green
                                        : record[data.source] === 'No'
                                        ? classes.red
                                        : ''
                                }>
                                {record[data.source]}
                            </span>
                        )}
                    />
                );
            case 'customYesNoField':
                return (
                    <FunctionField
                        source={data.source}
                        record={record}
                        render={record => (
                            <span className={record[data.source] ? classes.green : classes.red}>
                                {record[data.source] ? 'Yes' : 'No'}
                            </span>
                        )}
                    />
                );
            case 'customDate':
                return <DateField source={data.source} record={record} />;
            default:
                return <TextField source={data.source} record={record} />;
        }
    };

    const CustomReviewType = ({ record }) => {
        const classes = useStyles();
        if (record) {
            const { reviewer_type } = record;
            return (
                <span
                    record={record}
                    className={`${classes.reviewAndFiText}  ${
                        reviewer_type == 'first_impression'
                            ? classes.firstImpression
                            : classes.review
                    }`}>
                    {reviewer_type == 'first_impression' ? 'First impression' : 'Review'}
                </span>
            );
        }
        return null;
    };

    const CustomLabel = ({tooltip, label}) => {
        if (tooltip) {
            return (
                <Tooltip arrow title={<Typography className={classes.tooltip}>{tooltip}</Typography>} placement="top">
                    <div className={classes.label}>{label} <ContactSupportIcon className={classes.icon} fontSize="small" /></div>
                </Tooltip>
            );
        } 
        return label
    };

    return (
        <Box className={classes.list}>
            <ListWrapper
                hidePagination
                sort={{ field: 'submitted_at', order: 'DESC' }}
                perPage={5}
                key={filter}
                exporter={false}
                basePath="dashboard/table-data"
                resource="dashboard/table-data"
                onClickForward={onClickForward}
                onClickBackwards={onClickBackwards}
                filter={{ type: filter }}>
                <Box className={classes.cardRoot}>
                    <Responsive
                        small={
                            <SimpleList
                                primaryText={record => record?.customer.name}
                                secondaryText={record => 'Rating: ' + record?.average_rating}
                                tertiaryText={record =>
                                    new Date(record?.submitted_at).toLocaleDateString()
                                }
                                linkType={false}
                            />
                        }
                        medium={
                            <Datagrid optimized={true}>
                                <CustomReviewType label="Type" sortable={false} />
                                <TextField
                                    sortable={false}
                                    label="Rating"
                                    source="average_rating"
                                />
                                <TextField sortable={false} label="Name" source="customer.name" />
                                <DateField label="Date" source="submitted_at" />
                                {conditionalFields[filter] &&
                                    conditionalFields[filter].map(data => (
                                        <CustomField
                                            sortable={false}
                                            data={data}
                                            label={<CustomLabel {...data} />}
                                        />
                                    ))}
                                <ArrayField
                                    sortable={false}
                                    label="Key question / answer"
                                    source="dashboard_questions">
                                    <SingleFieldList linkType={false}>
                                        <FunctionField
                                            className={classes.question}
                                            render={record => (
                                                <Labeled label={record.question}>
                                                    <TextField record={record} source="answer" />
                                                </Labeled>
                                            )}
                                        />
                                    </SingleFieldList>
                                </ArrayField>
                                <CustomViewEditButton filter={filter} />
                            </Datagrid>
                        }
                    />
                    <div className={classes.shareButtonWrapper}>
                        {showCtaButton &&
                            <CustomButton
                                className={classes.cta}
                                onClick={() => analytics.track('invite_reviews', {category: filter})}
                                variant="contained"
                                color="tertiary"
                                href={ctaUrl}>
                                {ctaLabel}
                            </CustomButton>
                        }
                        <ListPagination
                            className={classes.pagination}
                            onClickForward={onClickForward}
                            onClickBackwards={onClickBackwards}
                        />
                    </div>
                </Box>
            </ListWrapper>
        </Box>
    );
};

export default DashboardTable;
