import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { ELEVATION_PRICING_URL } from '../../constants';
import { ExternalLink } from '../../Components/Links';
import dataProvider from '../../dataProvider';


const onClick = () => {
    dataProvider('CREATE', 'review-invitations/clicked-elevation-pricing', {data:{}});
}

const ElevationPlanLabel = () => {
    return <>
        Please make sure my account is set up on the Elevation Plan, so that I can learn about opportunities to improve
        and extend my relationship with this client. I have read and understood the
        {<ExternalLink href={ELEVATION_PRICING_URL} onClick={onClick}> Elevation pricing</ExternalLink>}.
    </>;
};
const ElevationPlanCheckbox = props => {
    const { handleChange, value } = props;

    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={value}
                    onChange={handleChange}
                    name="elevationPlanCheckbox"
                    color="secondary"
                />
            }
            label={<ElevationPlanLabel/>}
        />
    );
};

export default ElevationPlanCheckbox;
