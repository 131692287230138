import React from 'react';
import _ from 'lodash';
import ViewEnquiryDetailItem from './ViewEnquiryDetailItem';

const getEnquiryLabel = value => {
    const labels = {
        created_at: 'Received',
        redundant_customer_name: 'Name',
        redundant_customer_email: 'Email',
        redundant_customer_phone: 'Phone',
        message: 'Message',
        financial_type: 'Type of asset',
        financial_value: 'Asset value',
        financial_action: 'Action',
        financial_action_time: 'When',
        customer_employment_status: 'Employment status',
        customer_annual_income: 'Annual income',
        customer_partner_employment_status: `Partner's employment status`,
        mortgage_type: 'Mortgage type',
        property_value: 'Value of the property want to purchase',
        property_deposit: 'Deposit value',
        when_mortgage_need: 'When need the mortgage',
    };

    return labels[value];
};

const toEnquiryDate = date => {
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sept',
        'Oct',
        'Nov',
        'Dec',
    ];
    const ymd = date.split('T');
    const ymdArray = ymd[0].split('-');
    const year = ymdArray[0];
    const month = months[ymdArray[1] - 1];
    const day = ymdArray[2];

    return `${month} ${day}, ${year}`;
};

const toCurrency = value => {
    return `£${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

const formatEnquiryValue = (key, value) => {
    switch (true) {
        case key === 'created_at':
            return toEnquiryDate(value);
        case typeof value === 'number':
            return toCurrency(value);
        default:
            return value;
    }
};

const ViewEnquiryDetail = ({ enquiry = {} }) => {
    if (_.isEmpty(enquiry)) return null;

    return Object.entries(enquiry).map(([key, value]) => {
        if (['qualifying_details', 'sole_or_joint', 'id'].includes(key)) {
            return null;
        }

        return (
            <ViewEnquiryDetailItem
                key={key}
                label={getEnquiryLabel(key)}
                value={formatEnquiryValue(key, value)}
            />
        );
    })
};

export default ViewEnquiryDetail;
