import React from 'react';
import { Create, EditButton, TopToolbar } from 'react-admin';
import BackButton from '../Components/Buttons/BackButton';
import PreventUndoEdit from '../Components/PreventUndoEdit';
import InvestmentFeeLevelsForm from './Forms/InvestmentFeeLevelForm';

export const InvestmentFeeLevelsCreate = ({
    dispatch,
    recordValues,
    ...rest
}) => {
    return (
        <Create 
            title={null} 
            actions={
                <TopToolbar>
                    <BackButton href="/fees" />
                </TopToolbar>
            } 
            {...rest}>
            <InvestmentFeeLevelsForm {...rest} />
        </Create>
    );
};

export const InvestmentFeeLevelsEdit = ({
    dispatch,
    recordValues,
    ...rest
}) => {
    return (
        <PreventUndoEdit 
            actions={
                <TopToolbar>
                    <BackButton href="/fees" />
                </TopToolbar>
            } 
            {...rest}
        >
            <InvestmentFeeLevelsForm {...rest} />
        </PreventUndoEdit>
    );
};

export const InvestmentFeeLevelsGridEditButton = ({
    feeType,
    record,
    subType,
}) => {
    return (
        <EditButton
            color="secondary"
            to={{
                pathname: `/investment-fee-levels/${record.id}`,
                state: {
                    feeType,
                    investmentFeeSubType: subType,
                },
            }}
        />
    );
};
