import React, { Component } from 'react';
import _ from 'lodash';
import authProvider from '../authProvider';
import PropTypes from 'prop-types';
import cn from 'classnames';
import compose from 'recompose/compose';
import { Responsive } from 'react-admin';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AUTH_LOGOUT, toggleSidebar as toggleSidebarAction } from 'react-admin';
import AppHeaderLoggedOut from './AppHeaderLoggedOut';
import HeaderLogo from './Header/HeaderLogo';
import HeaderButton from './Header/HeaderButton';
import HeaderProfessionalDetails from './Header/HeaderProfessionalDetails';
import HeaderHelpDropdown from './Header/HeaderHelpDropdown';
import AlertBar from '../Components/Toolbars/AlertBar';
import { LogoutIcon } from '../icons/icons';
import { endImpersonate } from '../state/impersonateActions';
import { LOCAL_STORAGE } from '../constants';

import Card from '@material-ui/core/Card';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiAppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { history } from '../App';

const { LEXIKTOKEN } = LOCAL_STORAGE;

const propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    logout: PropTypes.element,
    open: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    toggleSidebar: PropTypes.func.isRequired,
};

const styles = theme => ({
    root: {
        zIndex: 999,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    card: {
        padding: `0 ${theme.spacing(3)}px`,
    },
    toolbar: {
        padding: `${theme.spacing(2)}px 0`,
        minHeight: 'initial',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        '& ul': {
            display: 'block',
            textAlign: 'right',
            margin: '0',
            padding: '0',
        },

        '& li': {
            display: 'inline-flex',
            alignItems: 'center',
            listStyleType: 'none',

            '& svg': {
                marginRight: theme.spacing(0.5),
            },
        },

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
            alignItems: 'flex-start',
            flexWrap: 'wrap',

            '& ul': {
                textAlign: 'center',
            },
        },
    },
    menuButtonIconClosed: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create(['transform'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    menuButtonIconOpen: {
        transform: 'rotate(180deg)',
        transition: theme.transitions.create(['transform'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    logout: {
        color: theme.palette.primary.contrastText,
    },
    headerContent: {
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'center',
            flexBasis: '100%',
        },
    },
    headerButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
    },
    mobileLogoWrapper: {
        display: 'flex',
        justifyContent: 'space-between',

        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start',
            flexBasis: '100%',
        },
    },
    hamburgerMenu: {
        marginRight: '2.5em',

        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
        },
    },
});

class AppHeader extends Component {
    onReturnProfileClick = () => {
        const originalToken = localStorage.getItem(LEXIKTOKEN);

        this.props.endImpersonate(originalToken);

        this.setState({ isImpersonating: false });
    };

    onClickLogout = () => {
        authProvider(AUTH_LOGOUT).then(() => history.push('/login'));
    };

    render() {
        const {
            isImpersonating,
            classes,
            isLoggedIn,
            className,
            toggleSidebar,
            open,
            location,
        } = this.props;

        if (!isLoggedIn) {
            return <AppHeaderLoggedOut />;
        }

        if (
            ['/login', '/register', '/forgot-password', '/forgot-password/reset'].includes(
                location.pathname
            )
        ) {
            return <AppHeaderLoggedOut />;
        }

        return (
            <MuiAppBar className={cn(classes.root, className)} color="inherit" position="static">
                <Card className={classes.card}>
                    <Toolbar disableGutters className={classes.toolbar}>
                        <Responsive
                            xsmall={
                                <div className={classes.mobileLogoWrapper}>
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={toggleSidebar}
                                        className={classes.hamburgerMenu}
                                    >
                                        <MenuIcon
                                            classes={{
                                                root: open
                                                    ? classes.menuButtonIconOpen
                                                    : classes.menuButtonIconClosed,
                                            }}
                                        />
                                    </IconButton>
                                    <HeaderLogo />
                                    <HeaderHelpDropdown />
                                </div>
                            }
                            small={
                                <div className={classes.mobileLogoWrapper}>
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={toggleSidebar}
                                        className={classes.hamburgerMenu}
                                    >
                                        <MenuIcon
                                            classes={{
                                                root: open
                                                    ? classes.menuButtonIconOpen
                                                    : classes.menuButtonIconClosed,
                                            }}
                                        />
                                    </IconButton>
                                    <HeaderLogo />
                                </div>
                            }
                            medium={<HeaderLogo />}
                        />
                        <div className={classes.headerContent}>
                            <Responsive
                                xsmall={null}
                                small={
                                    <div className={classes.headerButtons}>
                                        <ul>
                                            {isImpersonating && (
                                                <HeaderButton
                                                    onClick={this.onReturnProfileClick}
                                                    startIcon={<ArrowBack />}
                                                    label="Return to my profile"
                                                />
                                            )}
                                            <HeaderHelpDropdown />
                                            <HeaderButton
                                                onClick={this.onClickLogout}
                                                startIcon={<LogoutIcon />}
                                                label="Logout"
                                            />
                                        </ul>
                                    </div>
                                }
                            />
                            <HeaderProfessionalDetails />
                        </div>
                    </Toolbar>
                </Card>
                <AlertBar />
            </MuiAppBar>
        );
    }
}

AppHeader.propTypes = propTypes;

const mapStateToProps = ({ basicInfo }) => {
    return {
        isImpersonating: _.get(basicInfo, 'isImpersonating'),
        isLoggedIn: _.get(basicInfo, 'is_logged_in', false),
    };
};

const actions = {
    toggleSidebar: toggleSidebarAction,
    endImpersonate,
};

const enhance = compose(
    connect(
        mapStateToProps,
        actions
    ),
    withStyles(styles)
);

export default enhance(withRouter(AppHeader));
