import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import myTheme from '../../MyTheme';
import CardStats from './CardStats';

const useStylesCustom = makeStyles(theme => ({

    button: {
        width: '100%',
    },
    containerRoot: {
        padding: 0,
        margin: 0,
        marginBottom: '1.5rem'
    },
}));

const contentData = {
    prospect_intentions: {
        title: 'Prospect Conversion',
        text: 'of prospects intend to become a client',
        color: myTheme.palette.secondary.dark,
        reverseColor: myTheme.palette.error.main,
        goal: 1,
    },
    client_advocacy: {
        title: 'Client Advocacy',
        text: 'of clients are passionate advocates',
        color: myTheme.palette.secondary.dark,
        reverseColor: myTheme.palette.error.main,
        goal: 2,
    },
    safeguarding_clients: {
        title: 'Mitigating Risk',
        text: 'of reviews don’t exceed our risk threshold',
        color: myTheme.palette.secondary.dark,
        reverseColor: myTheme.palette.error.main,
        goal: 3,
    },
};

const ElevationStatsContent = props => {
    const classes = useStylesCustom();

    const { data, goalId, setGoalId, compareToIndustry } = props;

    return (
        <Box className={classes.containerRoot}>
            {goalId && (
                <Grid container spacing={3}>
                    {Object.keys(contentData).map((key, i) => {
                        const firmOrIndustryAvg = compareToIndustry
                            ? data[key].overallAvg
                            : data[key].firmAvg;
                        const diff = data[key].professionalAvg - firmOrIndustryAvg;
                        return (
                            <Grid
                                key={key}
                                item
                                lg={4}
                                md={6}
                                xs={12}
                                >
                                <Button
                                    onClick={e => setGoalId(e.currentTarget.value)}
                                    value={i + 1}
                                    className={classes.button}>
                                    <CardStats
                                        active={
                                            parseInt(goalId) === parseInt(contentData[key].goal)
                                        }
                                        subtitle={contentData[key].title}
                                        titleSameHeigh={true}
                                        title={
                                            data[key].professionalReviewCount >=5 ?
                                            <span>
                                                {data[key].professionalAvg
                                                    ? `${Math.round(
                                                          data[key].professionalAvg
                                                      )}%`
                                                    : '0%'}
                                            </span>
                                            : '-'
                                        }
                                        description={data[key].description}
                                        hyperlink = {data[key].descriptionHyperlink}
                                        color="bgSuccess"
                                        diff={diff}
                                        goalId={goalId}
                                        reviewCount={data[key].professionalReviewCount}
                                        contentData={contentData[key]}
                                        compareToIndustry={compareToIndustry}
                                    />
                                </Button>
                            </Grid>
                        );
                    })}
                </Grid>
            )}
        </Box>
    );
};

export default ElevationStatsContent;
