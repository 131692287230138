import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { SimpleForm, showNotification, FormDataConsumer, SelectInput } from 'react-admin';
import dataProvider from '../dataProvider';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Button from '../Components/Buttons/CustomButton';
import CardContent from '@material-ui/core/CardContent';
import ReferralPageWrapper from './Components/ReferralPageWrapper';
import FormToolbar from '../Components/Toolbars/FormToolbar';
import { FormSectionTitle } from '../Components/Typography/Headlines';
import TrueFalseInput from '../Components/Forms/TrueFalseInput';
import {
    LOCAL_STORAGE,
    NO_REWARDS_ID,
    ALL_REVIEWERS_ALL_TIME,
} from '../constants';
import * as analytics from '../analytics';

import { referralGeneratorStyles } from './referralGeneratorStyles';
import { LOGO } from '../constants/assets';

const DEFAULT_FORM_VALUES = {
    CUSTOMER_REFERRAL_REWARD: 1,
    ACCEPTED_TERMS_AND_CONDITIONS: false,
};

class ReferralGenerator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rewards: [],
            goals: [],
            reviewersList: [],
            excludedIds: [],
            defaultFormValues: [],
            error: null,
            buttonEnabled: true,
            mostRecentCustomerReferralInvitationDate: null,
            referralAchievedNotSelectedMessage: false,
        };
    }

    componentDidMount() {
        dataProvider('GET_LIST', 'customer-referral/rewards')
            .then(result => {
                const rewards = _.get(result, ['data'], [])
                rewards.sort((a,b) => (a.name.charAt(0) > b.name.charAt(0)) ? 1 : ((b.name.charAt(0) > a.name.charAt(0)) ? -1 : 0))
                this.setState({ rewards });
            })
            .catch(error => {
                this.setState({
                    error,
                });
            });

        dataProvider('GET_LIST', 'customer-referral/achievements')
            .then(result => {
                const achievements = _.get(result, ['data'], []);

                this.setState({
                    goals: achievements,
                    buttonEnabled: _.isEmpty(achievements) ? false : true,
                });
            })
            .catch(error => {
                this.setState({
                    error,
                });
            });

        dataProvider('GET_LIST', 'customer-referral/options')
            .then(result => {
                const allReviewersOption = _.find(result.data, option => {
                    if (option.id === 'all_new_reviewers') {
                        return option;
                    }
                });

                this.setState({
                    reviewersList: _.get(result, ['data'], []),
                    mostRecentCustomerReferralInvitationDate: allReviewersOption
                        ? allReviewersOption.date
                        : null,
                });
            })
            .catch(error => {
                this.setState({
                    error,
                });
            });

        this.setState({
            excludedIds: JSON.parse(
                sessionStorage.getItem(LOCAL_STORAGE.REFERRAL_EXCLUDED_IDS)
            ),
            defaultFormValues: JSON.parse(
                sessionStorage.getItem(LOCAL_STORAGE.REFERRAL_FORM_VALUES)
            ),
        });
    }

    handleSubmit = formValues => {
        const {
            acceptedTermsAndConditions,
            customerReferralReward,
            customerReferralAchievement
        } = formValues;

        let invalidForm =  (!acceptedTermsAndConditions && customerReferralReward !== NO_REWARDS_ID);

        if (invalidForm && customerReferralAchievement === undefined ) {
            this.setState({referralAchievedNotSelectedMessage : true})

            this.props.showNotification(
                'Please check you have completed all fields, and confirm ' +
                'you\'re happy with the cost',
                'warning'
            );

            return;
        }

        if (customerReferralAchievement === undefined) {
            this.setState({referralAchievedNotSelectedMessage : true})

            this.props.showNotification(
                'Please check you have completed all fields ', 'warning'
            );

            return;
        }

        this.setState({referralAchievedNotSelectedMessage : false})

         if (invalidForm) {
            this.props.showNotification(
                'Please confirm you\'re happy with the cost', 'warning'
            );

            return;
        }

        formValues.excludedIds = this.state.excludedIds
            ? this.state.excludedIds
            : [];

        const payload = {
            id: this.props.professionalId,
            data: formValues,
        };

        analytics.track('Removed client from list', {
            category: 'Referral generator',
            label: formValues.excludedIds.length,
        });

        dataProvider('CREATE', `customer-referral/invitation`, payload)
            .then(res => {
                this.props.showNotification('Referrals sent!');

                this.setState({
                    buttonEnabled: false,
                });
            })
            .catch(error => {
                this.setState({
                    error,
                });
            });
        sessionStorage.removeItem(LOCAL_STORAGE.REFERRAL_EXCLUDED_IDS);
        sessionStorage.removeItem(LOCAL_STORAGE.REFERRAL_FORM_VALUES);
    };

    handleSaveFormValues = (formData) => () => {
        const { mostRecentCustomerReferralInvitationDate } = this.state;

        analytics.track('Edit client list', { category: 'Referral generator' });

        sessionStorage.setItem(
            LOCAL_STORAGE.REFERRAL_FORM_VALUES,
            JSON.stringify({
                customerGroup: _.get(formData, 'customerGroup', null),
                customerReferralAchievement: _.get(formData, 'customerReferralAchievement', null),
                customerReferralReward: _.get(formData, 'customerReferralReward', null),
                recentReferralInvitationDate: mostRecentCustomerReferralInvitationDate,
            })
        );
    };

    render() {
        const { classes, firstName, lastName, pronoun } = this.props;
        const {
            rewards,
            goals,
            reviewersList,
            defaultFormValues,
            error,
            buttonEnabled,
        } = this.state;

        if (error)
            return (
                <Card>
                    <Typography>
                        <b>Sorry, it looks like there was an error. Please try
                        again, or contact us</b>
                    </Typography>
                </Card>
            );

        return (
            <ReferralPageWrapper variant="simpleForm" {...this.props}>
                <SimpleForm
                    save={this.handleSubmit}
                    redirect={false}
                    toolbar={
                        <FormToolbar
                            validForm={buttonEnabled}
                            label={
                                buttonEnabled ? 'SEND REFERRAL REQUESTS' : 'REFERRAL REQUEST SENT'
                            }
                            onClick={() => {
                                analytics.track('Clicked send', {
                                    category: 'Referral generator',
                                });
                            }}
                        />
                    }
                >
                    <>
                        <FormSectionTitle
                            title="Generate More Referrals From Your Clients"
                            style={{ marginTop: 0 }}
                        />
                        <Typography color="error">
                            You will not be charged for any enquiries received through this referral
                            generator.
                        </Typography>
                    </>
                    <Card className={classes.referralsPerformCard}>
                        <div className={classes.titleWrapper}>
                            <Typography color="secondary">
                                <b>How do referrals through VouchedFor perform?</b>
                            </Typography>
                        </div>
                        <CardContent>
                            <Grid container className={classes.performCardItemsWrapper}>
                                <Grid item lg={4} md={12} sm={12} xs={12}>
                                    <Typography variant="body1" align="center">
                                        <b>63%</b> of clients open the email
                                    </Typography>
                                </Grid>
                                <Grid item lg={4} md={12} sm={12} xs={12}>
                                    <Typography variant="body1" align="center">
                                        <b>34%</b> forward it to a friend
                                    </Typography>
                                </Grid>
                                <Grid item lg={4} md={12} sm={12} xs={12}>
                                    <Typography variant="body1" align="center">
                                        <b>7%</b> of those emailed visit your profile page
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <div className={classes.formWrapper}>
                        <Grid className={classes.logoCard}>
                            <img src={LOGO} alt="VouchedFor" />
                            <Grid className={classes.formElementsWrap}>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="baseline"
                                    className={classes.toRow}
                                >
                                    <Typography>
                                        <b>To</b>
                                    </Typography>
                                    <SelectInput
                                        className={classes.referralDropdowns}
                                        source="customerGroup"
                                        choices={reviewersList}
                                        label=""
                                        variant="standard"
                                        InputProps={{
                                            className: classes.dropdownInput,
                                        }}
                                        defaultValue={
                                            defaultFormValues
                                                ? defaultFormValues.customerGroup
                                                : ALL_REVIEWERS_ALL_TIME
                                        }
                                        onChange={(event, key, payload) => {
                                            analytics.track('Chose who to invite', {
                                                category: 'Referral generator',
                                                label: key,
                                            });
                                        }}
                                    />
                                    <FormDataConsumer>
                                        {({ formData }) => {
                                            const customerGroup = _.get(formData, 'customerGroup');

                                            if (customerGroup === 'just_me') return null;

                                            return (
                                                <Button
                                                    href="/customers/referral-customers"
                                                    onClick={this.handleSaveFormValues(formData)}
                                                    color="tertiary"
                                                    className={classes.editCustomersButton}
                                                >
                                                    VIEW / EDIT LIST OF REVIEWERS THIS WILL SEND TO
                                                </Button>
                                            );
                                        }}
                                    </FormDataConsumer>
                                </Grid>
                                <Typography variant="caption" className={classes.helperText}>
                                    Please note this will only send requests to those you have not
                                    already sent it to
                                </Typography>
                                <Typography>
                                    <b>Hi [CLIENT NAME],</b>
                                </Typography>
                                <Grid container>
                                    <Grid>
                                        <Typography>
                                            <b>
                                                Thank you for leaving a review for your adviser,{' '}
                                                {firstName} {lastName}, and helping {pronoun}
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid>
                                        <SelectInput
                                            style={
                                                isWidthDown('sm', this.props.width)
                                                    ? { maxWidth: '328px' }
                                                    : { maxWidth: '656px' }
                                            }
                                            className={classes.referralDropdowns}
                                            InputProps={{
                                                className: classes.dropdownInput,
                                            }}
                                            source="customerReferralAchievement"
                                            choices={goals}
                                            optionText="description"
                                            label=""
                                            onChange={(event, key, payload) => {
                                                analytics.track('Chose what to thank for', {
                                                    category: 'Referral generator',
                                                    label: key,
                                                });
                                            }}
                                        />
                                        {
                                        this.state.referralAchievedNotSelectedMessage &&
                                        <Typography variant="body2" color="error" > Please select a dropdown option for milestone/achievement</Typography>
                                    }
                                    </Grid>
                                </Grid>
                                <Typography>
                                    <b>
                                        VouchedFor’s mission is to help people access great advice.
                                        To that end, we’d be very grateful if you would introduce{' '}
                                        {firstName} to anyone you know who may need help with their
                                        finances, by sharing the unique link below:
                                    </b>
                                </Typography>
                                <Typography>
                                    <b>
                                        [Unique link to your profile will be generated in the email
                                        for your clients]
                                    </b>
                                </Typography>
                                <Grid container>
                                    <FormDataConsumer>
                                        {({ formData }) => {
                                            const customerReferralReward = _.get(
                                                formData,
                                                'customerReferralReward'
                                            );
                                            const className =
                                                customerReferralReward === NO_REWARDS_ID
                                                    ? classes.noReward
                                                    : '';

                                            return (
                                                <Grid>
                                                    <Typography className={className}>
                                                        <b>
                                                            As a thank you, if anyone you refer
                                                            becomes a client of {firstName}, you and
                                                            your friend will both receive a
                                                        </b>
                                                    </Typography>
                                                </Grid>
                                            );
                                        }}
                                    </FormDataConsumer>
                                    <Grid>
                                        <SelectInput
                                            className={classes.referralDropdowns}
                                            InputProps={{
                                                className: classes.dropdownInput,
                                            }}
                                            source="customerReferralReward"
                                            choices={rewards}
                                            defaultValue={_.get(
                                                defaultFormValues,
                                                'customerReferralReward',
                                                DEFAULT_FORM_VALUES.CUSTOMER_REFERRAL_REWARD
                                            )}
                                            label=""
                                            onChange={(event, key) => {
                                                analytics.track('Chose reward', {
                                                    category: 'Referral generator',
                                                    label: key,
                                                });
                                            }}
                                        />
                                        <FormDataConsumer>
                                            {({ formData }) => {
                                                const customerReferralReward = _.get(
                                                    formData,
                                                    'customerReferralReward'
                                                );

                                                if (customerReferralReward === NO_REWARDS_ID) {
                                                    return (
                                                        <Typography variant="caption" className={classes.noRewardMessage}>
                                                            (This sentence will not display)
                                                        </Typography>
                                                    );
                                                }
                                            }}
                                        </FormDataConsumer>
                                    </Grid>
                                </Grid>

                                <Typography>
                                    <b>
                                        Best wishes,
                                        <br />
                                        <br />
                                        The VouchedFor team
                                        <br />
                                        (0)20 3111 0580
                                    </b>
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                    {_.isEmpty(goals) && (
                        <Typography>
                            Sorry, you must wait until you have reached key milestones to use the
                            referral generator
                        </Typography>
                    )}
                    <FormDataConsumer>
                        {({ formData }) => {
                            const customerReferralReward = _.get(
                                formData,
                                'customerReferralReward'
                            );

                            if (customerReferralReward === NO_REWARDS_ID) return null;

                            return (
                                <div className={classes.acceptedTermsAndConditionsInput}>
                                    <TrueFalseInput
                                        label="I am happy to cover the cost of this referral reward (VouchedFor will arrange the reward for qualifying referrers and charge the cost of it back to you).
                                                    Please note the reward is for both the client and their friend;
                                                    for example, a £25 voucher will be a total cost of £50 for you.*"
                                        source="acceptedTermsAndConditions"
                                        defaultValue={
                                            defaultFormValues
                                                ? defaultFormValues.acceptedTermsAndConditions
                                                : DEFAULT_FORM_VALUES.ACCEPTED_TERMS_AND_CONDITIONS
                                        }
                                    />
                                </div>
                            );
                        }}
                    </FormDataConsumer>
                </SimpleForm>
            </ReferralPageWrapper>
        );
    }
}

const mapStateToProps = state => {
    return {
        firstName: state.basicInfo.first_name,
        lastName: state.basicInfo.last_name,
        pronoun: state.basicInfo.pronoun,
        professionalId: state.basicInfo.id,
    };
};

export default connect(
    mapStateToProps,
    { showNotification }
)(withStyles(referralGeneratorStyles)(withWidth()(ReferralGenerator)));
