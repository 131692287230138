import _ from 'lodash';
import { allServiceDescriptions, PERMISSIONS, SERVICE } from '../constants';

const { BASIC_PROFILE_COMPLETE, SERVICES_VERIFIED } = PERMISSIONS;

const {
    PENSION_TRANSFERS_SPECIALIST,
    PENSION_TRANSFERS_NON_SPECIALIST,
} = SERVICE;

export function getServiceEditRedirectUrl(
    isFinancialAdviser,
    permissions = {}
) {
    if (isFinancialAdviser) {
        if (!_.get(permissions, BASIC_PROFILE_COMPLETE, false)) {
            return `/basic-profile`;
        }

        if (!_.get(permissions, SERVICES_VERIFIED, false)) {
            return `/verify-services`;
        }
    }

    return `/services-list`;
}

export function getServicesCreateDescriptionHTML(attributeId) {
    if (!attributeId) return null;

    if (attributeId === PENSION_TRANSFERS_NON_SPECIALIST) {
        return (
            '"<b>Pension Transfers - Non Specialist</b>" refers to transfers where specialist qualifications (G60, AF3, AF7, AwPetr or the CISI’s pension transfer certificate) ' +
            'are <b>NOT</b> required to advise on the pension transfer. For example, pension switches or pension transfers where there are no safeguarded benefits.' +
            '<br /><br />' +
            'Please add "<b>Pension Transfers - Specialist</b>" as another service if you have the relevant qualifications and wish to offer this service as well.'
        );
    } else if (attributeId === PENSION_TRANSFERS_SPECIALIST) {
        return (
            '"<b>Pension Transfers - Specialist</b>" refers to any any pension transfers that require a specialist qualification: G60, AF3, AF7, AwPetr or the CISI’s pension ' +
            'transfer certificate. For example, defined benefit pension transfers, or pension transfers involving other safeguarded benefits.' +
            '<br /><br />' +
            'Please note: if you hold CII Level 4 Diploma you also need to hold a specialist qualification such as CII unit AF7 / CII Level 6 Award in Regulated Pension ' +
            'Transfer Advice in order to offer the <b>Pension Transfers - Specialist</b> service. Please ensure all qualification are uploaded before trying to add the service.'+
            '<br /><br />' +
            'Please select "<b>Pension Transfers - Non Specialist</b>" for services that do not require specialist qualifications, such as pension switches or transfers where there ' +
            'are no safeguarded benefits.'
        );
    } else {
        const service = allServiceDescriptions.find( serv => serv.id === attributeId)
        if (service) {
            return service.description
        }
    }

    return null;
}
