import React from 'react';
import { connect } from 'react-redux';
import { ExternalLink } from '../Components/Links';
import { CONTACT, PLAN, SUPPORT_URL } from '../constants';

import Typography from '@material-ui/core/Typography';

const ReputationToolContent = props => {
    const { onStarterPlan, professionalId } = props;

    if (onStarterPlan) {
        return (
            <Typography variant="body1">
                Our marketing tools are available only for members on our
                Verified or Unlimited plans.
                <br />
                To upgrade and get access to these tools, call us on{' '}
                {CONTACT.PHONE_NUMBER} or
                <ExternalLink href={`/account-settings/${professionalId}`}>
                    {' click here '}
                </ExternalLink>
                .
            </Typography>
        );
    }

    return (
        <Typography variant="body1">
            <ExternalLink
                href={SUPPORT_URL.ACCOUNT.HOW_TO_USE_MARKETING_TOOLS_V2}
            >
                Read more&nbsp;
            </ExternalLink>
            about how to make the most of these reputation tools.
        </Typography>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        professionalId: basicInfo.id,
        onStarterPlan: basicInfo.plan === PLAN.STARTER,
    };
};

export default connect(mapStateToProps)(ReputationToolContent);
