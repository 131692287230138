import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { showNotification } from 'react-admin';
import dataProvider from '../../dataProvider';
import ReputationToolCard from '../Card/ReputationToolCard';
import {
    API_KEYS,
    SUPPORT_URL,
    REP_TOOL_CERTIFICATE_OF_EXCELLENCE, REP_TOOL_CERTIFICATE_OF_EXCELLENCE_VF_2023,
} from '../../constants';
import { getSelectedCoELabel } from '../reputationUtils';
import { getLexikToken } from '../../Helpers/provider';
import * as analytics from '../../analytics';

import Button from '../../Components/Buttons/CustomButton';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

class ProfessionalCertificate extends Component {
    state = {
        certificates: [],
        selectedCoE: '',
    };

    componentDidMount() {
        if (!this.props.hasCoE) return;

        this.getCertificates();
    }

    getCertificates = async () => {
        const payload = {
            limit: 1,
        };

        const { data } = await dataProvider(
            'GET_LIST',
            'reputation/certificates',
            payload
        );
        const selectedCoE = _.get(data, ['0', 'id'], '');

        this.setState({
            selectedCoE,
            certificates: data,
        });
    };

    downloadCoE = () => {
        const { selectedCoE } = this.state;

        window.location.href = `${
            API_KEYS.api_url
        }/reputation/certificates/${selectedCoE}?lexiktoken=${getLexikToken()}`;

        this.props.showNotification('Certificate downloaded');

        this.sendAnalytics('Download');
    };

    copyCoEUrl = async () => {
        await dataProvider('CREATE', 'reputation/usage/coe', { data: {} });

        this.sendAnalytics('Copied link');
    };

    sendAnalytics = action => {
        const { certificates, selectedCoE } = this.state;

        analytics.track(action, {
            category: 'Certificate of Excellence',
            label: getSelectedCoELabel(certificates, selectedCoE),
        });
    };

    onSelectChange = ({ target }) => {
        this.setState({ selectedCoE: target.value });
    };

    getUpgradeProps = () => {
        if (_.isEmpty(this.state.certificates)) {
            return {
                message: 'Learn how to qualify',
                href: SUPPORT_URL.ACCOUNT.HOW_TO_USE_COE,
                target: '_blank',
            };
        }

        return undefined;
    };

    render() {
        const { certificates, selectedCoE } = this.state;

        return (
            <ReputationToolCard
                title="Certificate of Excellence"
                subTitle="Send it to prospects, post it on Linkedin, include it in your welcome pack... the choice is yours"
                image={this.props.showNewRepTool ?  REP_TOOL_CERTIFICATE_OF_EXCELLENCE_VF_2023 : REP_TOOL_CERTIFICATE_OF_EXCELLENCE}
                disabled={_.isEmpty(certificates)}
                upgradeProps={this.getUpgradeProps()}
                actions={
                    <>
                        <Button
                            variant="contained"
                            color="tertiary"
                            onClick={this.downloadCoE}
                        >
                            Download
                        </Button>
                    </>
                }
            >
                    <FormControl variant="outlined" >
                        <InputLabel htmlFor="certificateExcellence" >
                            Choose version of certificate
                        </InputLabel>
                        <Select
                            variant="outlined"
                            label="Choose version of certificate"
                            value={selectedCoE}
                            onChange={this.onSelectChange}
                            inputProps={{
                                name: 'certificateExcellence',
                                id: 'certificateExcellence',
                            }}
                        >
                            {certificates.map(certificate => (
                                <MenuItem
                                    key={certificate.id}
                                    value={certificate.id}
                                >
                                    {certificate.display_date}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
            </ReputationToolCard>
        );
    }
}

const mapStateToProps = ({ basicInfo }) => {
    return {
        hasCoE: _.get(
            basicInfo,
            ['permissions', 'reputation_certificate_of_excellence'],
            false
        ),
    };
};

export default connect(
    mapStateToProps,
    { showNotification }
)(ProfessionalCertificate);
