import React, { useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { PROFESSIONAL, PERMISSIONS } from '../../constants';
import { required, FormDataConsumer } from 'react-admin';
import { useForm } from 'react-final-form';
import LinkedSelect from '../../Components/Inputs/LinkedSelect';
import { FormSectionTitle } from '../../Components/Typography/Headlines';
import { getServicesCreateDescriptionHTML } from '../servicesUtils';
import { Typography } from '@material-ui/core';
import dataProvider from '../../dataProvider';
import FormReferenceInput from '../../Components/Inputs/Form/FormReferenceField';
import FormSelectInput from '../../Components/Inputs/Form/FormSelectInput';
import MissingPensionQualificationErrorMessage from './MissingPensionQualificationErrorMessage';

const { SERVICE_OPTIONS } = PERMISSIONS;
const { FINANCIAL_ADVISER } = PROFESSIONAL.VERTICAL;

const ServicesCreateFormContent = props => {
    const { isFinancialAdviser, serviceOptions, pensionServiceErrorMessage } = props;
    const [childAttributes, setChildAttributes] = useState(null);
    const [serviceDescription, setServiceDescription] = useState(null);
    const form = useForm();

    const getChildAttributes = newAttribute => {
        if (isFinancialAdviser || !newAttribute) return;

        dataProvider('GET_LIST', `services-settings-choices/${newAttribute}`).then(res => {
            setChildAttributes(res.data);

            form.change('selectedChild', _.get(res, 'data.0.id'));
        });
    };

    const handleAttributeChange = attributeId => {
        getChildAttributes(attributeId);

        setServiceDescription(getServicesCreateDescriptionHTML(attributeId));
    };

    return (
        <>
            <FormSectionTitle title="Add Service" />
            <FormReferenceInput
                label={isFinancialAdviser ? 'Service' : 'Category'}
                resource="attribute"
                source="attribute"
                validate={[required()]}
                reference="services-choices"
                onChange={e => handleAttributeChange(e.target.value)}
            >
                <FormSelectInput optionText="name" error={Boolean(pensionServiceErrorMessage)} />
            </FormReferenceInput>
            <MissingPensionQualificationErrorMessage
                message={pensionServiceErrorMessage}
                style={{ margin: '0 0 40px' }}
            />
            {isFinancialAdviser ? (
                <FormDataConsumer {...props}>
                    {({ formData }) => {
                        const attribute = _.get(formData, 'attribute');

                        if (!serviceOptions || !attribute) return null;

                        return (
                            <LinkedSelect
                                required
                                source="service_state"
                                resourceName="services-choices"
                                resourceId={attribute}
                            />
                        );
                    }}
                </FormDataConsumer>
            ) : (
                <>
                    {childAttributes && (
                        <FormSelectInput
                            label="Service"
                            source="selectedChild"
                            choices={childAttributes}
                            validate={[required()]}
                        />
                    )}
                </>
            )}
            <>
                <Typography
                    variant="body1"
                    dangerouslySetInnerHTML={{ __html: serviceDescription }}
                />
            </>
        </>
    );
};

const mapStateToProps = ({ basicInfo, ...state }) => {
    const permissions = _.get(basicInfo, 'permissions', {});

    return {
        serviceOptions: _.get(permissions, SERVICE_OPTIONS, false),
        isFinancialAdviser: _.get(permissions, 'vertical') === FINANCIAL_ADVISER,
    };
};

export default connect(mapStateToProps)(ServicesCreateFormContent);
