import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

const propTypes = {
    label: PropTypes.any,
    value: PropTypes.any,
};

const LabelAdornment = ({ label = '', value = '' }) => {
    if (!Boolean(value)) return null;

    return (
        <Typography variant="body1">
            <b>{label} </b> {value}
        </Typography>
    );
};

LabelAdornment.propTypes = propTypes;

export default LabelAdornment;
