import React from 'react';
import ReviewsCounter from './ReviewsCounter';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core";
import CustomIcon from '../../icons/CustomIcon';
const NUMBER_OF_REVIEWS = '280,380';

const FEATURE_POINTS = [
    {
        title: `Discover actionable insights to drive client experience and revenue`,
    },
    {
        title: `Deliver a robust, ongoing response to the Consumer Duty`,
    },
    {
        title: `Create social proof to build trust and grow your business`,
    },
];

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        paddingBottom: theme.spacing(3),
        margin: `0 ${theme.spacing(2)}px`,

        [theme.breakpoints.down('xs')]: {
            margin: 0,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    header: {
        textAlign: 'left',
        margin: `${theme.spacing(3)}px 0`,
        fontSize: theme.spacing(4),

        '& span': {
            color: theme.palette.secondary.main,
        },

        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        },
    },
    para: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        },
    },
    featuresList: {
        padding: 0,
        listStyleType: 'none',
        margin: 0,
    },
    feature: {
        display: 'flex',
    },
    featureText: {
        marginLeft: theme.spacing(1),
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    reviewCount: {
        display: 'flex',
        textAlign: 'left',

        [theme.breakpoints.down('xs')]: {
            alignItems: 'center',
            flexDirection: 'column',
        },
    },
    reviewText: {
        marginLeft: theme.spacing(1),
    },
    link: {
        fontWeight: 'bold',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    lastPara: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            paddingBottom: '0px',
        },
        paddingBottom: '0px',
    }
}));

const OurStandardsList = ({ handleClick }) => {
    const classes = useStyles();

    return (
        <section className={classes.container}>
            <Typography variant="h5" color="inherit" className={classes.header}>
                <b>
                    We’re <span>VouchedFor</span>, the client experience experts.
                </b>
            </Typography>
            <Typography variant="body1" className={classes.para}>
                We believe that the right advice has the power to transform lives.
            </Typography>
            <Typography variant="body1" className={classes.para}>
                We’re on a mission to help the adviser community deliver an even greater impact for millions of clients.
            </Typography>
            <Typography variant="body1" className={classes.lastPara}>
                We use client feedback to help you:
            </Typography>
            <ul className={classes.featuresList}>
                {
                    FEATURE_POINTS.map(feature =>
                        <Typography
                            variant="body1"
                            component="li"
                            key={feature.title}
                            className={classes.feature}
                        >
                            <CustomIcon icon="tick" color="mint" small marginRightOnly />
                            <div className={classes.featureText}>
                                <span>{ feature.title }</span>
                            </div>
                        </Typography>
                    )
                }
            </ul>
            <Typography variant="body1" className={classes.para}>
                We help you improve client experience and rise to the Consumer Duty through <a className={classes.link} onClick={handleClick("elevation-anchor")}>Elevation</a>
            </Typography>
            <Typography variant="body1" className={classes.para}>
                We help you build trust and attract more clients organically through <a className={classes.link} onClick={handleClick("verified-anchor")}>Verified</a>
            </Typography>
            <Divider className={classes.divider} />
            <div className={classes.reviewCount}>
                <ReviewsCounter>{NUMBER_OF_REVIEWS}</ReviewsCounter>
                <Typography variant="body1" className={classes.reviewText}>
                    verified reviews
                </Typography>
            </div>
        </section>
    );
};

export default OurStandardsList;
