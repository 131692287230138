import React from 'react';
import { Edit } from 'react-admin';

const defaultProps = {
    title: null,
    actions: null,
};

const PreventUndoEdit = ({
    children,
    title,
    actions,
    staticContext,
    ...props
}) => {
    return (
        <Edit title={title} actions={actions} undoable={false} {...props}>
            {children}
        </Edit>
    );
};

PreventUndoEdit.defaultProps = defaultProps;

export default PreventUndoEdit;
