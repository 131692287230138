import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, withStyles } from '@material-ui/core';

const styles = theme => ({
    anchor: {
        textDecoration: 'none',
        color: theme.palette.secondary.main,
    },
});

const ResetPasswordExpiredTokenMessage = ({ classes }) => {
    return (
        <div>
            <Typography>
                Sorry, it looks like that link has expired due to security precautions!
            </Typography>
            <Typography>
                Please request a{' '}
                <Link to="/forgot-password" className={classes.anchor}>
                    <b>new link</b>
                </Link>
            </Typography>
        </div>
    );
};

export default withStyles(styles)(ResetPasswordExpiredTokenMessage);
