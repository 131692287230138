import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Ribbon from '../Components/Ribbon';
import ContentTooltip from '../Components/ConsumerReactComponents/ContentTooltip';

import Card from '@material-ui/core/Card';
import withStyles from '@material-ui/core/styles/withStyles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const propTypes = {
    headerContent: PropTypes.any,
    tooltipTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    children: PropTypes.any,
    loading: PropTypes.bool,
};

const defaultProps = {
    title: '',
    loading: false,
};

const styles = theme => ({
    container: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        position: 'relative',
        height: '100%',
        boxSizing: 'border-box',
        padding: 0,
        marginBottom: theme.spacing(3),
        maxWidth: 800,

        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },

        [theme.breakpoints.up('lg')]: {
            marginBottom: 0,
        },
    },
    headerContentWrapper: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.secondary.faint,
        padding: theme.spacing(3),
        minHeight: theme.spacing(23),

        [theme.breakpoints.down('sm')]: {
            position: 'inherit',
        },
    },
    smallCardHeaderContentWrapper: {
        minHeight: theme.spacing(12),
        padding: 0,
        borderBottom: `1px solid ${theme.palette.primary.dark}`,
    },
    guideBannerHeader: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
        minHeight: 'unset',
    },
    headerContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tooltipWrapper: {
        position: 'absolute',
        top: theme.spacing(3),
        right: theme.spacing(3),
    },
    contentWrapper: {
        padding: theme.spacing(3),
    },
    smallCardContentWrapper: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    headerTitleWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(2),

        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            marginLeft: 0,
        },
    },
    smallCardHeaderTitleWrapper: {
        flexDirection: 'unset',
        justifyContent: 'center',
        margin: 0,
        '@media(max-width: 760px)': {
            padding: `0 ${theme.spacing(3)}px`,
        },
    },
    headerTitle: {
        color: theme.palette.secondary.main,
    },
    guideBannerHeaderTitle: {
        color: theme.palette.primary.contrastText,
    },
    smallCardHeaderTitle: {
        padding: `0 ${theme.spacing(2)}px`,
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    scoreWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    actionWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        minHeight: 58,
        '& svg': {
            marginRight: theme.spacing(1),
        },
        '@media(min-width: 960px) and (max-width: 1060px)': {
            width: 222,
        },
        '@media(max-width: 706px)': {
            width: theme.spacing(26),
        },
    },
    doneAction: {
        color: theme.palette.primary.dark,
    },
    todoAction: {
        padding: `1px ${theme.spacing(2)}px`,
        justifyContent: 'flex-start',
        textDecoration: 'none',
        textTransform: 'uppercase',
        color: theme.palette.error.main,

        '& p': {
            paddingLeft: 0,
        },

        [theme.breakpoints.down('sm')]: {
            marginLeft: `-${theme.spacing(1)}px`,
            padding: `1px ${theme.spacing(1)}px`,

            '& p': {
                paddingLeft: theme.spacing(1),
            },
        },
        '@media(max-width: 1060px)': {
            width: 'unset',
        },
    },
    errorButton: {
        '&:hover': {
            backgroundColor: 'rgba(211, 91, 131, 0.08)',
        },
    },
});

class DashboardCard extends Component {
    state = {
        tooltipOpen: false,
    };

    openToolTip = () => {
        this.setState({ tooltipOpen: true });
    };

    closeToolTip = () => {
        this.setState({ tooltipOpen: false });
    };

    render() {
        const { tooltipOpen } = this.state;
        const {
            children,
            ribbonText,
            headerLeft,
            headerTitle,
            headerSummary,
            tooltipTitle,
            smallCard,
            classes,
            hidden,
            gridProps = { md: 6, sm: 12, xs: 12 },
            caption,
            isGuideBanner,
            hideChildrenContent,
        } = this.props;

        if (hidden) return null;

        return (
            <Grid item {...gridProps}>
                <Card className={classes.container}>
                    <div
                        className={cn(
                            classes.headerContentWrapper,
                            smallCard && classes.smallCardHeaderContentWrapper,
                            isGuideBanner && classes.guideBannerHeader
                        )}
                    >
                        {tooltipTitle && (
                            <div className={classes.tooltipWrapper}>
                                <ClickAwayListener onClickAway={this.closeToolTip}>
                                    <ContentTooltip
                                        placement="right"
                                        title={tooltipTitle}
                                        open={tooltipOpen}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        onClick={this.openToolTip}
                                        onClose={this.closeToolTip}
                                    />
                                </ClickAwayListener>
                            </div>
                        )}

                        <Grid container className={classes.headerContent}>
                            <Ribbon label={ribbonText} />

                            {headerLeft && (
                                <Grid
                                    item
                                    md={isGuideBanner ? 5 : 6}
                                    sm={12}
                                    xs={12}
                                    className={classes.scoreWrapper}
                                >
                                    {headerLeft}
                                </Grid>
                            )}

                            <Grid item md={smallCard ? 12 : isGuideBanner ? 7 : 6} sm={12} xs={12}>
                                <div
                                    className={cn(
                                        classes.headerTitleWrapper,
                                        smallCard && classes.smallCardHeaderTitleWrapper
                                    )}
                                >
                                    <Typography
                                        component={smallCard ? 'h3' : 'h2'}
                                        className={cn(
                                            classes.headerTitle,
                                            smallCard && classes.smallCardHeaderTitle,
                                            isGuideBanner && classes.guideBannerHeaderTitle
                                        )}
                                        align={smallCard && 'center'}
                                    >
                                        {headerTitle}
                                    </Typography>
                                    <Typography variant="body1">{headerSummary}</Typography>
                                    {caption && (
                                        <Typography variant="caption">{caption}</Typography>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    {hideChildrenContent ? null : (
                        <div
                            className={cn(
                                classes.contentWrapper,
                                smallCard && classes.smallCardContentWrapper
                            )}
                        >
                            {children}
                        </div>
                    )}
                </Card>
            </Grid>
        );
    }
}

DashboardCard.defaultProps = defaultProps;
DashboardCard.propTypes = propTypes;

export default withStyles(styles)(DashboardCard);
