import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import FeesPageWrapper from './Components/FeesPageWrapper';
import FeesListContent from './Lists/FeesListContent';
import { Redirect } from 'react-router-dom';

const Fees = props => {
    if (!props.isLoggedIn) {
        return <Redirect to="/register" />;
    }

    return (
        <FeesPageWrapper id="no-id" resource="fees" basePath="/fees" {...props}>
            <FeesListContent {...props} />
        </FeesPageWrapper>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        isLoggedIn: _.get(basicInfo, 'is_logged_in', false),
    };
};

export default connect(mapStateToProps)(Fees);
