import React from 'react';
import PropTypes from 'prop-types';
import { palette } from '../../MyTheme';
import TrueFalseInput from '../../Components/Forms/TrueFalseInput';

const styles = {
    container: {
        display: 'block',
    },
    link: {
        textDecoration: 'none',
        display: 'inline',
    },
    boolean: {
        display: 'inline',
    },
};

const BooleanSwitchInput = ({ switchLabel, source, href }) => (
    <div style={styles.container}>
        <TrueFalseInput style={styles.boolean} label={switchLabel} source={source} href={href} />
    </div>
);

BooleanSwitchInput.propTypes = {
    switchLabel: PropTypes.string.isRequired,
    source: PropTypes.string,
    href: PropTypes.string,
};

export default BooleanSwitchInput;