import React from 'react';
import StatisticColumnBase from './StatisticColumnBase';
import StatisticContent from './StatisticContent';
import StatisticButton from './StatisticButton';
import * as analytics from '../../../analytics';

const StatisticsColumnProspectIntention = ({ data, loading }) => {
    if (!data) return null;

    const clickHandler = () => {
        analytics.track('Clicked Prospect Intention Stat', {
            category: 'Dashboard card',
            label: data.value ? 'Viewed Prospects' : 'Invite Prospects'
        });
        window.location.href = data.value ? '/first-impressions' : '/invite-reviews';
    }

    return (
        <StatisticColumnBase
            score={data.value ? data.value + '%' : '?'}
            name='Prospect Intention'
            loading={loading}
            badge="new"
        >
            <StatisticContent dangerouslySetInnerHTML={{ __html: data.content }} />
            <StatisticButton
                onClick={clickHandler}
                {...(!data.value && { label: "Invite First Impressions" })}
            />

        </StatisticColumnBase>
    );
};

export default StatisticsColumnProspectIntention;
