import React, { Component } from 'react';
import { connect } from 'react-redux';
import LinkedSelect from './LinkedSelect';
import { FINANCIAL_ADVISER_SERVICES } from '../../constants/attributes'

class MinimumInvestmentFields extends Component {
    state = {
        showInvestmentDropdown: false,
    };

    componentDidUpdate(prevProps) {
        let showInvestmentDropdown = false;

        if (prevProps !== this.props) {
            const { servicesList } = this.props;

            if (servicesList.length === 1 && servicesList.includes('Mortgages')) {
                showInvestmentDropdown = false;
            }
            const FAServices = servicesList.filter(value => FINANCIAL_ADVISER_SERVICES.includes(value));

            if ( FAServices.length > 0 ) {
                showInvestmentDropdown = true;
            }

            this.setState({ showInvestmentDropdown });
        }
    }

    render() {
        const { servicesList } = this.props;

        if (servicesList.length === 0) {
            return null;
        }

        return (
            <div>
                {servicesList.includes('Mortgages') && (
                    <LinkedSelect
                        label="Minimum Mortgage Criteria"
                        required={true}
                        source="attributes.minimum_mortgage_criteria"
                        resourceName="services-settings-choices"
                        resourceId="407"
                    />
                )}
                {this.state.showInvestmentDropdown && (
                    <LinkedSelect
                        label="Minimum Investment Criteria"
                        required={true}
                        source="attributes.minimum_investment_criteria"
                        resourceName="services-settings-choices"
                        resourceId="401"
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        servicesList: state.basicInfo.servicesList,
    };
};

export default connect(mapStateToProps)(MinimumInvestmentFields);
