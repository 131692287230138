import React from 'react';
import _ from 'lodash';
import { FormSectionTitle } from '../../Components/Typography/Headlines';

const EnquiryTitle = ({ record, report }) => {
    const customerName = _.get(record, 'redundant_customer_name', '');
    const title = report ? `Report Enquiry from ${customerName}` : `Enquiry from ${customerName}`;

    return <FormSectionTitle title={title} />;
};

export default EnquiryTitle;
