import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showNotification as showNotificationAction } from 'react-admin';
import { copyToClipboard } from '../../utils';

import Button from '../Buttons/CustomButton';

const propTypes = {
    onClick: PropTypes.func,
    clipboardContent: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
    ]),
};

const defaultProps = {
    clipboardContent: null,
    onClick: () => {},
    notificationMessage: 'ra.notification.code_copied',
};

const CopyTextButton = props => {
    const {
        clipboardContent,
        onClick,
        showNotification,
        children,
        notificationMessage,
        ...rest
    } = props;

    const handleClick = e => {
        if (!clipboardContent) return;

        onClick(e);

        copyToClipboard(clipboardContent);

        showNotification(notificationMessage);
    };

    return (
        <Button onClick={handleClick} color="tertiary" variant="outlined" {...rest}>
            {children}
        </Button>
    );
};

CopyTextButton.propTypes = propTypes;
CopyTextButton.defaultProps = defaultProps;

export default connect(
    null,
    { showNotification: showNotificationAction }
)(CopyTextButton);
