const getActiveSidebarItemGka = (pathname, professionalId, firmId) => {
    const map = {
        // Dashboard
        [`/`]: 'dashboard',

        // Profile
        [`/professional/about/${professionalId}`]: 'profile',
        [`/professional/qualifications/${professionalId}`]: 'profile',
        [`professional/experience/${professionalId}`]: 'profile',
        [`/services-list/${professionalId}`]: 'profile',
        [`/professional/location/${professionalId}`]: 'profile',
        [`/professional/disclaimer/${professionalId}`]: 'profile',
        [`/qualifications/create`]: 'profile',
        [`/jobs/create`]: 'profile',
        [`/awards/create`]: 'profile',
        [`/services/create`]: 'profile',
        [`/meeting-locations/create`]: 'profile',

        // Fees
        [`/fees-insights`]: 'fees',
        [`/fees`]: 'fees',
        [`/fees-edit`]: 'fees',
        [`/investment-fee-levels/create`]: 'fees',

        // Firm
        [`/professional/firm/${professionalId}`]: 'firm',
        [`/firm/${firmId}`]: 'firm',
        [`/firm/linked-advisers`]: 'firm',
        [`/firm/reputation`]: 'firm',
        [`/firm-reviews`]: 'firm',

        // Reputation Tools
        [`/reputation`]: 'reputation',

        // Reviews
        [`/invite-reviews`]: 'reviews',
        [`/review/previous-invites`]: 'reviews',
        [`/reviews`]: 'reviews',
        [`/first-impressions`]: 'reviews',
        [`/review-setting`]: 'reviews',
        [`/review-setting/template`]: 'reviews',

        // Referral Generator
        [`/referral-generator/create`]: 'referral',
        [`/customer-referral`]: 'referral',

        // Enquiries
        [`/enquiries-list/${professionalId}`]: 'enquiries',

        // Settings & Billing
        [`/account-settings/${professionalId}`]: 'settings',
        [`/billing`]: 'settings',

        //Elevation
        [`/elevation-dashboard`] : 'elevation-dashboard',
        ['/elevation-client-answers'] : 'elevation-client-answers',
        ['/action-list'] : 'action-list',
    };

    return map[pathname] || null;
};

export default getActiveSidebarItemGka;
