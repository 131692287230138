import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { useNotify } from 'react-admin';
import {API_KEYS, REP_TOOL_TOP_RATED_CERTIFICATE_VF_2023} from '../../constants';
import { getLexikToken } from '../../Helpers/provider';
import * as analytics from '../../analytics';
import TopRatedReportCard from './Components/TopRatedReportCard';
import { REP_TOOL_TOP_RATED_CERTIFICATE } from '../../constants';

const ProfessionalTopRatedReportCard = props => {
    const notify = useNotify();

    if (!props.isTopRated) return null;

    const handleClick = e => {
        e.preventDefault();

        analytics.track(`Downloaded top rated certificate`, {
            category: 'Profile promotion',
        });

        window.location.href = `${API_KEYS.api_url}/reputation/top-rated-certificate/${
            props.professionalId
        }?lexiktoken=${getLexikToken()}`;

        notify('Top rated certificate downloaded');
    };

    return (
        <TopRatedReportCard
            title="Top Rated Certificate"
            handleClick={handleClick}
            image={props.showNewRepTool ? REP_TOOL_TOP_RATED_CERTIFICATE_VF_2023 : REP_TOOL_TOP_RATED_CERTIFICATE}
        />
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        professionalId: _.get(basicInfo, 'id'),
        isTopRated: _.get(basicInfo, ['permissions', 'is_top_rated'], false),
    };
};

export default connect(mapStateToProps)(ProfessionalTopRatedReportCard);
