import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Query } from 'react-admin';
import SkeletonWrapper from '../../../Components/SkeletonWrapper';
import DashboardCardBase from '../CardComponents/DashboardCardBase';
import DashboardCardTitle from '../CardComponents/DashboardCardTitle';
import DashboardFooterButton from '../CardComponents/DashboardFooterButton';
import DashboardCardRank from '../CardComponents/DashboardCardRank';
import { PLAN } from '../../../constants';

const DashboardReputationToolRankCard = ({ buttonText, buttonHref }) => {
    return (
        <Query type="getOne" resource="dashboard/reputation-rank">
            {({ loading, data }) => (
                <DashboardCardBase
                    header={
                        <SkeletonWrapper loading={loading} height={56}>
                            <DashboardCardTitle>{_.get(data, 'title', '')}</DashboardCardTitle>
                        </SkeletonWrapper>
                    }
                    body={
                        <DashboardCardRank
                            rank={_.get(data, 'rank')}
                            content={_.get(data, 'content')}
                            loading={loading}
                        />
                    }
                    footer={
                        <DashboardFooterButton
                            content={buttonText}
                            buttonHref={buttonHref}
                            analyticsAction="You could promote your profile more effectively"
                            analyticsCategory="Dashboard card"
                            analyticsLabel="Clicked View Your Reputation Tools button"
                        />
                    }
                />
            )}
        </Query>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    const onStarterPlan = basicInfo.plan === PLAN.STARTER;

    return {
        buttonText: onStarterPlan ? 'Upgrade to use reputation Tools' : 'View reputation tools',
        buttonHref: onStarterPlan ? `/account-settings/${basicInfo.id}` : '/reputation',
    };
};

export default connect(mapStateToProps)(DashboardReputationToolRankCard);
