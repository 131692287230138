import React from 'react';
import PropTypes from 'prop-types';
import { TopToolbar } from 'react-admin';

import Button from '../../Components/Buttons/CustomButton';
import withStyles from '@material-ui/core/styles/withStyles';

const propTypes = {
    message: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    actions: PropTypes.any,
    href: PropTypes.string,
};

const defaultProps = {
    disabled: true,
    href: undefined,
    message: 'Please upgrade your plan to access this feature',
};

const styles = () => ({
    root: {
        padding: 0,
    },
    button: {
        minWidth: '100%',
        textAlign: 'center',
        textTransform: 'uppercase',
    },
});

const Actions = props => {
    const { classes, message, actions, disabled, href, buttonProps } = props;

    return (
        <TopToolbar className={classes.root}>
            {disabled ? (
                <Button
                    color="tertiary"
                    className={classes.button}
                    href={href}
                    {...buttonProps}
                >
                    {message}
                </Button>
            ) : (
                actions
            )}
        </TopToolbar>
    );
};

Actions.propTypes = propTypes;
Actions.defaultProps = defaultProps;

export default withStyles(styles)(Actions);
