import React from 'react';
import StatisticColumnBase from './StatisticColumnBase';
import StatisticContent from './StatisticContent';
import { formatPlural } from '../../../utils';

const StatisticColumnDirectEnquirers = ({ data, loading }) => {
    if (!data) return null;

    return (
        <StatisticColumnBase
            score={data.value ? data.value : '?'}
            name={formatPlural(data.value, 'Direct Enquirer', 'Direct Enquirers')}
            loading={loading}
        >
            <StatisticContent>{data.content}</StatisticContent>
        </StatisticColumnBase>
    );
};

export default StatisticColumnDirectEnquirers;
