import React from 'react';
import cn from 'classnames';
import {
    PROFESSIONAL_PHOTO_MADELEINE_SUNLEY,
    PROFESSIONAL_PHOTO_WAYNE_GRIFFITHS,
} from '../../constants';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core";

const TESTIMONIALS = [
    {
        image: PROFESSIONAL_PHOTO_MADELEINE_SUNLEY,
        content: "VouchedFor has been amazing for my business. VouchedFor is the perfect place to showcase what you do, by regularly asking clients to review your services to show that you are great. They come up at the top of Google searches when people want to find an adviser and therefore you need to be on there. Once a year you can qualify to be a Top Rated Adviser and last year I took on some wonderful clients who found me in The Times Top Rated Adviser Guide with VouchedFor.VouchedFor is a great place for clients to find me and I love it.",
        person: 'Madeleine Sunley, IFA and Director, Blueberry Financial',
    },
    {
        image: PROFESSIONAL_PHOTO_WAYNE_GRIFFITHS,
        content: "Before VouchedFor, 25% of prospects became clients… now it’s more like 75%.  Either the team’s got 3 times better at their job overnight or it’s a powerful platform!",
        person: 'Wayne Griffiths, IFA and Managing Director, One Financial Solutions',
    },
];

const useStyles = makeStyles(theme => ({
    section: {
        padding: `${theme.spacing(10)}px ${theme.spacing(8)}px`,

        [theme.breakpoints.down('sm')]: {
            padding: `${theme.spacing(5)}px ${theme.spacing(2)}px`,
        },
    },
    line: {
        margin: `${theme.spacing(8)}px auto`,
        width: theme.spacing(42),

        [theme.breakpoints.down('sm')]: {
            margin: `${theme.spacing(4)}px auto`,
        },
    },
    testimonial: {
        textAlign: 'right',

        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    personImgWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    personImg: {
        height: theme.spacing(24),
        width: theme.spacing(24),
        padding: theme.spacing(1) / 2,
        border: `${theme.spacing(1) / 2}px solid ${
            theme.palette.secondary.main
        }`,
        borderRadius: '50%',
        float: 'left',

        [theme.breakpoints.down('sm')]: {
            float: 'none',
        },
    },
    testimonialLeft: {
        textAlign: 'left',

        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },

        '& > div:first-child': {
            order: 2,

            [theme.breakpoints.down('sm')]: {
                order: 0,
            },
        },
        '& img': {
            float: 'right',

            [theme.breakpoints.down('sm')]: {
                float: 'none',
            },
        },
    },
    content: {
        fontSize: theme.spacing(3),
        color: theme.palette.primary.main,
        fontFamily: theme.fonts.reviewFont,
        marginBottom: theme.spacing(2),
    },
}));

const RegisterTestimonialsSection = () => {
    const classes = useStyles();

    return (
        <section className={classes.section}>
            <Grid container justify="center">
                <Grid item lg={10} md={12} sm={12} xs={12}>
                    {TESTIMONIALS.map((testimonial, i) => {
                        const testimonialClassName =
                            i === 1 ? classes.testimonialLeft : null;

                        return (
                            <React.Fragment key={testimonial.person}>
                                <Grid
                                    container
                                    spacing={0}
                                    className={cn(
                                        classes.testimonial,
                                        testimonialClassName
                                    )}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        md={3}
                                        className={classes.personImgWrapper}
                                    >
                                        <img
                                            className={classes.personImg}
                                            src={testimonial.image}
                                            alt={`Head shot of ${testimonial.person}`}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <Typography
                                            className={classes.content}
                                            variant="body1"
                                            color="inherit"
                                        >
                                            {`"${testimonial.content}"`}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            color="inherit"
                                        >
                                            {testimonial.person}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {i === 0 && <hr className={classes.line} />}
                            </React.Fragment>
                        );
                    })}
                </Grid>
            </Grid>
        </section>
    );
};

export default RegisterTestimonialsSection;
