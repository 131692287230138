import React, { Component } from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import QuestionIcon from '@material-ui/icons/Help';
import OutlinedQuestionIcon from '@material-ui/icons/HelpOutlineOutlined';

const styles = theme => ({
    toolTip: {
        background: theme.palette.primary.contrastText,
        border: `1px solid ${theme.palette.secondary.main}`,
        color: theme.palette.primary.dark,
    },
    toolTipPopper: {
        opacity: 1,
    },
    questionIcon: {
        position: 'relative',
        bottom: theme.spacing(2),
        left: theme.spacing(2),
        width: 18,
        height: 18,
        color: theme.palette.secondary.main,
        cursor: 'pointer',
    },
    questionIconGoal: {
        bottom: theme.spacing(2),
        left: theme.spacing(2),
        width: 18,
        height: 18,
        color: theme.palette.secondary.main,
        cursor: 'pointer',
        marginLeft: '6px'
    },
    questionIconDriver: {
        width: 18,
        height: 18,
        color: theme.palette.secondary.main,
        cursor: 'pointer',
    },
    title: {
        fontSize: 14,
        margin: 0,
    },
});

class ContentTooltip extends Component {
    renderToolTipTitle = title => {
        const { classes } = this.props;

        return (
            <Typography variant="body1" className={classes.title}>
                {title}
            </Typography>
        );
    };

    renderLabel = () => {
        if (this.props.children) {
            return this.props.children;
        }

        return (
            this.props.isDriverTooltip ?
                <OutlinedQuestionIcon
                    onClick={this.props.onClick}
                    className={this.props.classes.questionIconDriver}
                />
                :
                <QuestionIcon
                    onClick={this.props.onClick}
                    className={this.props.isGoalTooltip ? this.props.classes.questionIconGoal : this.props.classes.questionIcon}
                />
        );
    };

    render() {
        const {
            classes,
            title,
            onClick,
            fullWidth,
            indicator,
            selected,
            onChange,
            textColor,
            value,
            ...rest
        } = this.props;

        return (
            <React.Fragment>
                <Tooltip
                    title={this.renderToolTipTitle(title)}
                    classes={{
                        tooltip: classes.toolTip,
                        popper: classes.toolTipPopper,
                    }}
                    {...rest}
                >
                    {this.renderLabel()}
                </Tooltip>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(ContentTooltip);
