export const BASIC_INFO_UPDATE_SUCCESS = 'BASIC_INFO_UPDATE_SUCCESS';
export const BASIC_INFO_FETCH_SUCCESS = 'BASIC_INFO_FETCH_SUCCESS';
export const FETCH_BASIC_INFO = 'FETCH_BASIC_INFO';
export const BASIC_PROFILE_FETCH_FAILED = 'BASIC_PROFILE_FETCH_FAILED';
export const START_IMPERSONATE = 'START_IMPERSONATE';
export const END_IMPERSONATE = 'END_IMPERSONATE';
export const PLAN_UPGRADE_SUCCESS = 'PLAN_UPGRADE_SUCCESS';
export const GET_SERVICES_COUNT = 'GET_SERVICES_COUNT';
export const GET_SERVICES_LIST = 'GET_SERVICES_LIST';
export const HIDE_SIDEBAR = 'HIDE_SIDEBAR';
export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
export const ON_ROUTE_CHANGE = 'ON_ROUTE_CHANGE';
export const DASHBOARD_REFERRER_COUNT = 'DASHBOARD_REFERRER_COUNT';
export const DASHBOARD_INFLUENCED_COUNT = 'DASHBOARD_INFLUENCED_COUNT';
export const SET_FORM_ERROR = 'SET_FORM_ERROR';
