import React, { useState } from 'react';
import CustomChart from '../Charts/CustomChart';
import { palette } from '../../../../MyTheme';
import DashboardTable from '../Tables';
import * as analytics from '../../../../analytics';
import Tip from '../Tip';
import _ from 'lodash';
import {usePermissions} from "react-admin";

const ReferralStats = props => {
    const { data, lines, bars, filter } = props;
    const [colors] = useState([palette.error.main]);
    const [barColors] = useState([palette.secondary.main]);
    const { permissions } = usePermissions();
    const showCtaButton = _.get(permissions, 'can_use_referral_generator');

    const handleTableForwardNav = () => analytics.track('next', { category: 'recommenders_table' });
    const handleTableBackwardNav = () => analytics.track('previous', { category: 'recommenders_table' });
    return (
        <>
            <Tip text='"Recommenders" refers to those who answered positively to whether your reviews on VouchedFor mean they would be more confident recommending you to a friend' />
            <CustomChart
                dataPoints={data}
                bars={bars}
                lines={lines}
                lineColors={colors}
                barColors={barColors}
            />
            <DashboardTable
                ctaLabel="Referral generator"
                ctaUrl="/referral-generator/create"
                key={filter}
                filter={filter}
                onClickForward={handleTableForwardNav}
                onClickBackwards={handleTableBackwardNav}
                showCtaButton={showCtaButton}
            />
        </>
    );
};

export default ReferralStats;
