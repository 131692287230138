import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
    showNotification as showNotificationAction,
    refreshView as refreshViewAction,
    UPDATE,
} from 'react-admin';
import { connect } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import dataProvider from '../../dataProvider';

const ReviewAnnualFeedbackSwitchInput = props => {
    const inputValue = _.get(props, ['input', 'value'], '');

    const [checked, setChecked] = useState(inputValue);

    /**
     * @todo this causes the checkbox to flicker, need to find a better solution
     */
    useEffect(() => {
        setChecked(inputValue)
    }, [inputValue]);

    const handleChange = name => event => {
        const payload = {
            id: 'no-id',
            data: {
                auto_invite: event.target.checked,
            },
        };

        dataProvider(UPDATE, `review-setting`, payload).then(res => {
            props.showNotification('Annual feedback settings changed');
            props.refreshView();
        });

        props.input.onChange(event.target.checked);
        setChecked(event.target.checked);
    };

    return (
        <div>
            <FormControlLabel
                control={
                    <Switch
                        checked={checked}
                        onChange={handleChange('checked')}
                        value="checked"
                    />
                }
                label={props.label}
            />
        </div>
    );
};

const actions = {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
};

export default connect(
    null,
    actions
)(ReviewAnnualFeedbackSwitchInput);
