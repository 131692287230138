import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { SimpleForm } from 'react-admin';
import { FormSpy } from 'react-final-form';
import { FormSectionTitle } from '../../Components/Typography/Headlines';
import { FEES_REGISTER_ROUTE } from '../../constants';
import FeesCreateFormContent from './Components/FeesCreateFormContent';
import FormWithSidebarStatistics from '../../Components/Forms/FormWithSidebarStatistics';
import FeesCreateRegisterNextButton from './Components/FeesCreateRegisterNextButton';
import { getFeesCreateStatistics } from '../feesUtils';

const defaultProps = {
    formTitle: 'Create New Fee',
};

const FeesCreateForm = ({ redirect, formTitle, withSidebarStatistics = false, ...rest }) => {
    const [requestFinished, setRequestFinished] = useState(false);
    const [statistics, setStatistics] = useState([]);

    const simpleFormProps = {
        ...rest,
    };

    if (withSidebarStatistics) {
        simpleFormProps.toolbar = null;
    }

    if (requestFinished) {
        return <Redirect to={FEES_REGISTER_ROUTE.LIST} />;
    }

    return (
        <SimpleForm redirect={redirect} {...simpleFormProps}>
            {withSidebarStatistics ? (
                <>
                    <FormSpy
                        subscription={{ values: true }}
                        onChange={({ values }) => {
                            setStatistics(getFeesCreateStatistics(values));
                        }}
                    />
                    <FormWithSidebarStatistics withCard statistics={statistics}>
                        <FormSectionTitle title={formTitle} />
                        <FeesCreateFormContent />
                        <div>
                            <FeesCreateRegisterNextButton
                                onSuccess={() => setRequestFinished(true)}
                            />
                        </div>
                    </FormWithSidebarStatistics>
                </>
            ) : (
                <>
                    <FormSectionTitle title={formTitle} />
                    <FeesCreateFormContent />
                </>
            )}
        </SimpleForm>
    );
};

FeesCreateForm.defaultProps = defaultProps;

export default FeesCreateForm;
