import dataProvider from '../dataProvider';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
    USER_LOGIN_SUCCESS,
    CRUD_CREATE_SUCCESS,
    CRUD_UPDATE_SUCCESS,
    CRUD_GET_MANY_REFERENCE_SUCCESS,
    CRUD_GET_ONE_LOADING,
} from 'react-admin';
import {
    BASIC_PROFILE_FETCH_FAILED,
    END_IMPERSONATE,
    PLAN_UPGRADE_SUCCESS,
    ON_ROUTE_CHANGE,
    FETCH_BASIC_INFO,
    SET_FORM_ERROR,
    BASIC_INFO_UPDATE_SUCCESS,
} from './types';
import { basicProfileFetched, getServicesCount, getServicesList } from './actions';
import { history } from '../App';
import { createBasicInfo, onMatchingRoute } from '../utils';
import { LOCAL_STORAGE, VERIFY_SERVICE_ROUTES } from '../constants';
import * as analytics from '../analytics';

const {
    BASIC_INFO,
    LEXIKTOKEN,
    INITIAL_TOKEN,
    IS_IMPERSONATING,
    REDIRECT_URL,
    BASIC_PROFILE_FORM_VALUES,
    HAS_COMPLETED_FEES_SIGN_UP,
} = LOCAL_STORAGE;

function* fetchBasicInfo(action) {
    try {
        const basicInfo = yield call(dataProvider, 'GET_ONE', 'professional/basic-info');

        const formattedBasicInfo = createBasicInfo(basicInfo);

        yield put(basicProfileFetched(formattedBasicInfo.data));

        localStorage.setItem(BASIC_INFO, JSON.stringify(formattedBasicInfo));
    } catch (e) {
        yield put({ type: BASIC_PROFILE_FETCH_FAILED, ...e.message });
    }
}

function* handleEndImpersonate(action) {
    if (action.type === END_IMPERSONATE) {
        const initialToken = localStorage.getItem(INITIAL_TOKEN);

        localStorage.setItem(LEXIKTOKEN, initialToken);
        localStorage.removeItem(IS_IMPERSONATING);
        localStorage.removeItem(INITIAL_TOKEN);

        yield fetchBasicInfo(action);
        history.push('/');
        window.location.reload();
    }
}

function* handleGetOneLoading(action) {
    const { meta } = action;

    if (!meta) return;

    switch (meta.resource) {
        case 'professional/firm':
            yield fetchBasicInfo(action);
            break;
        default:
            return;
    }
}

function* handleCreateSuccess(action) {
    const { meta } = action;

    if (meta) {
        switch (meta.resource) {
            case 'services':
                yield fetchBasicInfo(action);
                break;
            default:
            // Do nothing.
        }
    }

    yield put({ type: SET_FORM_ERROR, payload: null });
}

function* handleUpdateSuccess(action) {
    const { meta } = action;
    if (meta) {
        switch (meta.resource) {
            case 'account-settings':
                history.push('/');
                break;
            case 'verify-services':
                yield fetchBasicInfo(action);
                history.push('/');
                window.location.reload();
                break;
            case 'fees':
            case 'professional/about':
                yield fetchBasicInfo(action);
                window.location.reload();
                break;
            case 'firm':
            case 'professional/firm':
                yield fetchBasicInfo(action);
                break;
            default:
            // Do nothing.
        }
    }

    yield put({ type: SET_FORM_ERROR, payload: null });
}

function* handleUpgradeSuccess(action) {
    if (action.type === PLAN_UPGRADE_SUCCESS) {
        analytics.track('complete - upgrade', { category: 'upgrade' });

        history.push('/billing');

        yield fetchBasicInfo(action);
    }
}

function* handleGetManySuccess(action) {
    if (action.type === CRUD_GET_MANY_REFERENCE_SUCCESS) {
        let services = [];
        if (action.meta.resource === 'services') {
            yield put(getServicesCount(action.payload.data.length));

            action.payload.data.map(service => services.push(service.attribute_name));

            yield put(getServicesList(services));
        }
    }
}

function* handleBasicProfileUpdateSuccess(action) {
    yield fetchBasicInfo(action);

    sessionStorage.removeItem(BASIC_PROFILE_FORM_VALUES);

    if (sessionStorage.getItem(HAS_COMPLETED_FEES_SIGN_UP)) {
        sessionStorage.removeItem(HAS_COMPLETED_FEES_SIGN_UP);

        window.location.href = '/fees-insights';
    } else {
        window.location.href = '/review/first-invitation/create';
    }
}

function onRouteChange(action) {
    if (action.type === ON_ROUTE_CHANGE) {
        handleUserRedirect();
    }
}

function handleUserRedirect() {
    const basicInfo = localStorage.getItem(BASIC_INFO);
    const redirectUrl = sessionStorage.getItem(REDIRECT_URL);

    if (!basicInfo) return;

    const { id, permissions, is_logged_in } = JSON.parse(basicInfo).data;

    if (!is_logged_in || !permissions || !id) return;

    let nextUrl = null;

    if (!permissions.basic_profile_complete) {
        if (!onMatchingRoute(Object.values(VERIFY_SERVICE_ROUTES))) {
            nextUrl = `/basic-profile/${id}`;
        }
    } else if (redirectUrl) {
        sessionStorage.removeItem(REDIRECT_URL);

        nextUrl = redirectUrl.replace(/pro-id/g, id);
    }

    if (nextUrl && nextUrl !== window.location.pathname) {
        history.push(nextUrl)
    } else {
        analytics.pageView(window.location.pathname);
    }
}

function* mySaga() {
    yield takeLatest(USER_LOGIN_SUCCESS, fetchBasicInfo);
    yield takeLatest(CRUD_GET_ONE_LOADING, handleGetOneLoading);
    yield takeLatest(CRUD_CREATE_SUCCESS, handleCreateSuccess);
    yield takeLatest(CRUD_UPDATE_SUCCESS, handleUpdateSuccess);
    yield takeLatest(CRUD_UPDATE_SUCCESS, handleUpdateSuccess);
    yield takeLatest(FETCH_BASIC_INFO, fetchBasicInfo);
    yield takeLatest(END_IMPERSONATE, handleEndImpersonate);
    yield takeLatest(PLAN_UPGRADE_SUCCESS, handleUpgradeSuccess);
    yield takeLatest(CRUD_GET_MANY_REFERENCE_SUCCESS, handleGetManySuccess);
    yield takeLatest(ON_ROUTE_CHANGE, onRouteChange);
    yield takeLatest(BASIC_INFO_UPDATE_SUCCESS, handleBasicProfileUpdateSuccess);
}

export default mySaga;
