import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormSectionTitle } from '../../Components/Typography/Headlines';

import Button from '../../Components/Buttons/CustomButton';
import withStyles from '@material-ui/core/styles/withStyles';

const propTypes = {
    summary: PropTypes.shape({
        level: PropTypes.string.isRequired,
        level_description: PropTypes.string.isRequired,
    }),
};

const styles = theme => ({
    title: {
        margin: `${theme.spacing(1)}px 0`,
    },
    button: {
        margin: `${theme.spacing(1)}px 0`,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
});

const FeesChargeLevelBanner = ({ professionalId, summary, classes }) => {
    const textProps = {
        className: classes.title,
        color: 'textPrimary',
        dangerouslySetInnerHTML: { __html: summary.level_description },
    };

    if (summary.button_label) {
        return (
            <div>
                <FormSectionTitle {...textProps} />
                <Button
                    className={classes.button}
                    variant="contained"
                    color="tertiary"
                    to="/fees"
                    component={Link}
                >
                    {summary.button_label}
                </Button>
            </div>
        );
    }

    return <FormSectionTitle {...textProps} />;
};

FeesChargeLevelBanner.propTypes = propTypes;

export default withStyles(styles)(FeesChargeLevelBanner);
