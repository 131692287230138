import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';

function TAndC() {
    return (
        <Fragment>
            <Typography variant="h6" color="primary" paragraph>
                General Terms and Conditions
            </Typography>
            <ol>
                <li>
                    <Typography variant="subtitle1" color="primary" paragraph>
                        <b>Acceptance of terms</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        VouchedFor provides a service to enable members of the public to search for, check the credentials and reviews of a Professional in their local area, contact those Professionals and provide their own feedback on their experience with Professionals. In using this Service or any other service provided by VouchedFor (whether you are a Client, a Professional or otherwise), you will be deemed to have read and accepted to be bound by the terms and conditions set out below ("Terms") which constitute a binding contract between you and VouchedFor. If you do not wish to be bound by the Terms you should not use the service.
                    </Typography>
                </li>
                <li>
                    <Typography variant="subtitle1" color="primary" paragraph>
                        <b>Key Definitions</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        In these Terms the following definitions shall apply:
                    </Typography>
                    <Typography variant="body1">
                        <b>"Accountants"</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                          means Chartered Accountants, Chartered Certified Accountants, Chartered Management Accountants, Accountants or Bookkeepers who register on the Website in order to promote their services and credentials to Clients.
                    </Typography>
                    <Typography variant="body1">
                        <b>"Clients"</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        means persons who use the Service for the purposes of either searching for and identifying a Professional which meets their needs, contacting a Professional and/or providing feedback on their Professional as their client or as a prospective client.
                    </Typography>
                    <Typography variant="body1">
                        <b>"Financial Advisers"</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        means Independent Financial Advisers (IFAs) or other Financial Advisers who register on the Website in order to promote their services and credentials to Clients.
                    </Typography>
                    <Typography variant="body1">
                      <b>“Financial Coaches”</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        means financial coaches trained by WiseMonkey, MoneyPanel, Quiver, Initiative for Financial Wellbeing or accredited by Octopus Moneycoach who register on the website in order to promote their services and credentials to Clients.
                    </Typography>
                    <Typography variant="body1">
                        <b>"Firm"</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        a firm of Professionals who VouchedFor permits to register to use the Services including, without limitation, a partnership, corporate or unincorporated body.
                    </Typography>
                    <Typography variant="body1">
                        <b>"Legal Advisers"</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        means any Solicitors or Paralegals who register on the Website in order to promote their services and credentials to Clients.
                    </Typography>
                    <Typography variant="body1">
                        <b>"Mortgage Advisers"</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        means Mortgage Advisers who register on the Website in order to promote their services and credentials to Clients.
                    </Typography>
                    <Typography variant="body1">
                        <b>"Protection Advisers"</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        means Protection Advisers who register on the Website in order to promote their services and credentials to Clients.
                    </Typography>
                    <Typography variant="body1">
                        <b>"Professionals"</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        means any Financial Adviser, Accountant, Mortgage Adviser, Legal Adviser, Financial Coach, Protection Adviser or any other professional adviser who VouchedFor permits to register on the Website in order to promote their services and credentials to Clients. For the avoidance of doubt, for the purpose of the Terms, a Firm shall be a Professional. Where the Firm is a Professional, any individual who VouchedFor has confirmed can benefit from the Services on behalf of the Firm shall also be a Professional for the purpose of the Terms.
                    </Typography>
                    <Typography variant="body1">
                        <b>"Service"</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        means the online and telephony service operated through the Website whereby users can search for, select and check the credentials of Professionals, together with any other service provided by VouchedFor to Users from time to time.
                    </Typography>
                    <Typography variant="body1">
                        <b>"User"</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        means anyone using the Website and/or making use of the Service.
                    </Typography>
                    <Typography variant="body1">
                        <b>"VouchedFor", "us", "we" and "our"</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        means VouchedFor Limited, a Company registered in England (No. 07485300) whose address is 8 Waldegrave Road, Teddington, TW11 8HT
                    </Typography>
                    <Typography variant="body1">
                        <b>"Website"</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        means the website <a href="https://www.vouchedfor.co.uk" target="_blank">www.VouchedFor.co.uk</a> and all the pages, content and sub-domains contained in it. It also means similar websites that we develop with third parties under their own brands.
                    </Typography>
                    <Typography variant="body1">
                        <b>"You" and "Your"</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        means the person using our Services (whether you are a Client, Professional or otherwise).
                    </Typography>
                </li>
                <li>
                    <Typography variant="subtitle1" color="primary" paragraph>
                        <b>Nature of the Service and the role of VouchedFor</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You acknowledge and accept that the Services involves the provision of information about Professionals together with features which enables Clients to perform any of the actions described in clause 1 above. The provision or availability of such information through the Services does not constitute recommendation of any Professional, and the fact that a Professional is listed on the Website or as part of the Service does not mean that they are approved or endorsed by VouchedFor in any way.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        It is solely the responsibility of the Client to identify and select an appropriate Professional, to agree the fee arrangements and other terms of engagement with the Professional and to assess the suitability, if applicable, of any recommendations the Professional provides, including any recommendation of financial products.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Prior to the initial registration of a Professional on the Website, and on occasion after that (at a frequency determined by VouchedFor at its discretion), VouchedFor endeavours to carry out reasonable checks with respect to the Professional’s regulatory status with either their employer and/or the relevant regulatory or professional body (e.g. Financial Conduct Authority for Financial Advisers, Solicitors Regulation Authority for Solicitors, Institute of Chartered Accountants in England and Wales, Association of Chartered Certified Accountants, Institute of Chartered Accountants Scotland, Chartered Institute of Management Accountants, Institute Chartered Accountants of Ireland etc. for Accountants). Where VouchedFor is unable to conduct these checks, this will be clearly marked on the Professional’s profile page on the Website. VouchedFor also requests of each Professional that the description of their services and qualifications on the Website is accurate, but VouchedFor does not take any other steps to verify the information provided by the Professional and the Client accepts it is solely responsible for verifying such information. VouchedFor shall not be responsible for conducting any other checks on Professionals.
                    </Typography>
                    <Typography variant="body1" paragraph>
                    VouchedFor also accommodates Financial Coaches, in-line with our <a href="https://about.vouchedfor.co.uk/financial-coaching/financial-coaching" target="_blank">Financial Coach Policy</a>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        VouchedFor is not authorised to give, and does not give, any financial, legal advice or any other regulated advice through the Service.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        VouchedFor provides a list of "not fully verified" individuals, where we have reason to believe they may be a Professional, but may not have verified that to be true. The list is based on publicly available data and information, as well as data that has been uploaded by the individuals. These individuals cannot be contacted through the Service.
                    </Typography>
                    <Typography variant="body1" paragraph>
                    A further purpose of the VouchedFor website is to provide data to help firms and the professionals that are employed or affiliated with them to improve client outcomes through VouchedFor’s Elevation service. The Professional’s use of VouchedFor’s Elevation service shall be subject to the following terms: <a href="https://vouchedfor.co.uk/elevation-terms-and-conditions" target="_blank">Elevation terms</a> (“Elevation Terms”). Where there is any conflict between the Elevation Terms and these Terms, in so far as it relates to the supply of VouchedFor’s Elevation service, the Elevation Terms shall take precedence.
                    </Typography>
                </li>
                <li>
                    <Typography variant="subtitle1" color="primary" paragraph>
                        <b>Information</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Although we make reasonable attempts to ensure that all information available through the Service is correct, VouchedFor cannot guarantee the accuracy of the information on the Website or elsewhere in the Service, and we are not liable for any problems or losses arising from errors in such information or any decisions you make on the basis of such information.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Information contained in this Website or elsewhere in the Service is provided for general guidance only and shall not be construed as professional advice unless explicitly stated. VouchedFor does not accept any responsibility for any loss which may arise from the reliance on information contained in the Website.
                    </Typography>
                </li>
                <li>
                    <Typography variant="subtitle1" color="primary" paragraph>
                        <b>Use of the Website and Content</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You agree to use the Website only for lawful purposes and in a way that does not infringe the rights of or restrict or inhibit anyone else's use and enjoyment of the Website.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You understand that all postings, messages, text, files, images, photos, video, sounds, or other materials ("Content") posted on, transmitted through, or linked from the Website are the sole responsibility of the person from whom such content originated, and VouchedFor does not control, and is not responsible for Content provided by any other person (including, without limitation, any advertiser on the Website).
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You are entirely responsible for all Content that you upload, post, email or otherwise make available via the Service.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You agree not to upload, post, email, or otherwise make available any Content:
                    </Typography>
                    <ul>
                        <li>
                            <Typography variant="body1">
                                that is false, inaccurate or misleading;
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                that infringes any intellectual property rights of, or a duty of confidentiality to, or any other rights of, any other person;
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                that advertises products or services the sale or supply of which is prohibited or restricted by applicable law;
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment; or
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                that is libellous, abusive, threatening, vulgar, obscene, or otherwise objectionable.
                            </Typography>
                        </li>
                    </ul>
                    <Typography variant="body1" paragraph>
                        We have the right to disclose your identity to any third party who is claiming that any Content posted or uploaded by you to our Services constitutes a violation of their rights, including, without limitation, their intellectual property rights, right to privacy or confidentiality or is otherwise in breach of these Terms.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You acknowledge that VouchedFor does not pre-screen or approve Content, but that VouchedFor shall have the right (but not the obligation) at its sole discretion to refuse, delete, edit or move any Content that is available on the Website for violating the letter or spirit of the terms or for any other reason.
                    </Typography>
                    <Typography variant="body1" paragraph>
                      We reserve the right to request evidence from Professionals of their relationship with Clients to prevent fraudulent reviews. We also reserve the right to contact reviewers to verify Content they have written. If we deactivate a Professional’s account while we verify reviews, we will continue to charge a monthly subscription until our investigation is complete.
                    </Typography>
                </li>
                <li>
                    <Typography variant="subtitle1" color="primary" paragraph>
                        <b>VouchedFor Offers</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        VouchedFor offers are all subject to availability and we reserve the right to withdraw any of our offers at any point. This includes all free or discounted products or services and anything else that we describe as an offer. We may also work with third parties to provide you with offers from time to time.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Certain offers are subject to additional terms and conditions and any minimum criteria as set out on our website or any third party websites where the offers are presented.
                    </Typography>
                    <Typography variant="body1" paragraph>
                    Free plans: Our ‘Free Financial Plans’, ‘Free Inheritance Plans’ and ‘Free Pension Plans’ are only available to those that exceed a minimum wealth criteria. VouchedFor takes no responsibility for the content of all free plans and any decisions you take having spoken with the adviser or coach writing your plan must be made by you on the basis of your own investigations.
                    </Typography>
                    <Typography variant="body1" paragraph>
                    Monthly prize draw for reviewers: VouchedFor, at its sole discretion, will run a prize draw for Users who have left a review using the Service. This will include every User who has left a review using the Service in the previous month. The prize draw will not include Users who have opted out or who have been opted out by the Professional they are reviewing. The winner will be picked at random and the advertised prize will be distributed by VouchedFor. Our 'Get a £25 off Financial Advice voucher' offer is designed to make advice slightly more accessible. VouchedFor will pay £25 to any Clients  who contacts a Financial Adviser through VouchedFor, and can prove that they went on to take paid-for advice from the adviser they contacted. Only one voucher can be claimed per household. In order to redeem this voucher, the Client must follow the required steps as set out on the Website from time to time. This promotion is provided and paid for entirely by VouchedFor and is not endorsed in any way by any of the advisers listed on VouchedFor.
                    </Typography>
                  </li>
                <li>
                    <Typography variant="subtitle1" color="primary" paragraph>
                        <b>Feedback</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        All Clients are able to complete feedback evaluations on Professionals (or "not fully verified" individuals listed on the Website) from whom they received professional advice or have contacted. Users who have made contact with a Professional may leave a ‘First Impression Review’ of their experience, even if they do not go on to become a Client. Users must ensure that any feedback posted by them conforms to the rules regarding Content in the section entitled “Use of the Website and Content” above. VouchedFor is not responsible for the content of feedback posted by Users, or any harm done by such content.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        VouchedFor shall take reasonable measures to verify that the feedback is genuine, and may at its sole discretion remove Client feedback where authenticity is in doubt. VouchedFor does not accept any responsibility for damage caused by false or bogus feedback, or its failure or refusal to remove it.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Where a Client, Professional or other User believes feedback (about either Professionals or "not fully verified" individuals) may be fraudulent, defamatory or abusive, they should immediately contact VouchedFor through the Contact page or by emailing consumer_support@vouchedfor.co.uk.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        VouchedFor reserves the right to determine (in accordance with our data protection policy), which parties have access to the feedback we collect.
                    </Typography>
                </li>
                <li>
                    <Typography variant="subtitle1" color="primary" paragraph>
                        <b>Third Party Links</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        The Website and Content may contain links to other websites which are independent of VouchedFor. VouchedFor makes no representation or warranty as to the accuracy, completeness or authenticity of the information contained in any other site, and if you follow a link to another website you do so at your own risk.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You acknowledge and agree that VouchedFor is not liable for any loss or damage which may be incurred by you as a result of the availability of those external sites or resources, or as a result of any reliance placed by you on the completeness, accuracy or existence of any advertising, products or other materials on, or available from, such web sites or resources.
                    </Typography>
                </li>
                <li>
                    <Typography variant="subtitle1" color="primary" paragraph>
                        <b>Intellectual Property</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        VouchedFor owns all the intellectual property rights relating to the Website and the Services, including the designs, text, database, graphics and layouts, and you agree not to use or copy any part of them without our express permission in writing.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You agree not to download, reproduce, copy, resell or exploit for any commercial purposes, any aspect of the Service, or to use automated means to download data from the Service (including without limitation, spiders, robots, crawlers or data mining tools, but excepting standard internet search engines).
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Users are not permitted to decompile or disassemble, reverse engineer or otherwise attempt to discover any source code contained in the Website.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        The intellectual property rights in Content uploaded by Users to the Website are retained by the copyright owner, and Content is added to the Website by Users at their own risk. In doing so you are giving irrevocable permission for us to store, display, use and otherwise exploit the Content as we see fit.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Except for your Content which you have submitted to us using the Services, you accept and acknowledge that all Content on the Services is either owned by VouchedFor or licensed to VouchedFor by third parties, and is subject to the intellectual property rights of VouchedFor or VouchedFor’s licensors. Any third-party trade or service marks present on such Content are trade or service marks of their respective owners. Such Content may not be downloaded, copied, reproduced, distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any other purpose whatsoever without the prior written consent of VouchedFor or, where applicable, VouchedFor’s licensors. VouchedFor and its licensors reserve all rights not expressly granted in and to their Content.
                    </Typography>
                </li>
                <li>
                    <Typography variant="subtitle1" color="primary" paragraph>
                        <b>Dealings between Users</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Your interactions with organisations and/or individuals found on, or through, the Website, including responsibility for delivery of and payment for products or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such organisations and/or individuals. You agree that VouchedFor shall not be responsible, or liable, for any loss or damage of any sort arising from any such dealings.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        In the event that you have a dispute with one or more other Users, you hereby release VouchedFor, its officers, employees, agents and successors in rights from claims, demands and damages (actual, consequential, special or otherwise) of every kind or nature, known or unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way related to such disputes and/or the Service. You shall also indemnify VouchedFor, its officers, employees, agents and successors in rights (together, “Indemnified Parties”) against any losses, damages (actual, consequential, special or otherwise), expenses, costs, liabilities or claims the Indemnified Parties may suffer or incur arising out of or in connection with any such disputes.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Whilst VouchedFor may, at the request of a Client or a Professional, investigate a complaint, you acknowledge and agree that VouchedFor is under no obligation to become involved in any dispute between Users.
                    </Typography>
                </li>
                <li>
                    <Typography variant="subtitle1" color="primary" paragraph>
                        <b>Preventing Abuse</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We strive to make sure that your experience of using the Service is positive and rewarding, and as a result we may contact you from time to time during your use of the Service to check that you are satisfied with how things are going and to verify that Professionals and other Users are complying with their obligations to you and us. You understand and agree that we may contact you from time to time for these purposes and that, in the interests of maintaining the quality and integrity of the Service, you will respond promptly and truthfully to any reasonable requests for information.
                    </Typography>
                </li>
                <li>
                    <Typography variant="subtitle1" color="primary" paragraph>
                        <b>Disclaimer of Liability</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        VouchedFor does not recommend, endorse or approve any of the Professionals listed on the Website or any advice they may provide.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        It is the sole responsibility of the Client to select an appropriate Professional, to agree the terms of engagement with the Professional and to assess the suitability of any products or services offered or recommended to them. VouchedFor does not give any warranty as to the suitability, competence or qualifications of any Professional and shall not be liable for any loss or damage resulting from any advice given by a Professional or the purchase of (or failure to purchase) any products or services as a result of or in connection with such advice.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Save for the checks on Professionals referred to in section 3 above, VouchedFor is not responsible for vetting or conducting any checks on Professionals.
                    </Typography>
                    <Typography variant="body1" paragraph>
                      The Services have not been developed to meet your individual requirements. Please check that the features and functions of the Services (as set out on the Website or as otherwise notified to you from time to time) meet your requirements.
                    </Typography>
                    <Typography variant="body1" paragraph>
                      VouchedFor takes reasonable care to ensure that the Website and its electronic communications are virus-free. However, VouchedFor disclaims any warranty that the Website is free of viruses or other harmful components.
                    </Typography>
                    <Typography variant="body1" paragraph>
                    VouchedFor shall not be liable for, whether you are a Client or a Professional or other User:
                    </Typography>
                    <ul>
                        <li>
                            <Typography variant="body1">
                                (a)	any loss or damage caused by other Users including any loss in connection with another Users’ conduct or User Content;
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                (b)	any loss or damage that was not directly caused by VouchedFor’s breach of the Terms;
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                (c)	any loss or damage caused by you including without limitation your failure to provide VouchedFor with accurate information and/or your failure to keep your password or account details secure and confidential; or
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                (d)	any loss or damage that was not a reasonably foreseeable consequence of VouchedFor breaching the Terms.
                            </Typography>
                        </li>
                      </ul>
                      <Typography variant="body1" paragraph>
                      If you are a Professional, or any other User other than a Client:
                      </Typography>
                      <ul>
                          <li>
                              <Typography variant="body1">
                                  (a)	VouchedFor shall not be liable for loss of profits, loss of sales or business, loss of agreements or contracts, loss of anticipated savings, loss of use or corruption of software, data or information, loss of or damage to goodwill or indirect of consequential losses (in all cases whether such losses were foreseeable or not).
                              </Typography>
                          </li>
                          <li>
                              <Typography variant="body1">
                                  (b)	You agree that the Website and the Service are provided on an "as is" or "as available" basis, and accordingly use of the Website and the Service is entirely at your own risk.
                              </Typography>
                          </li>
                          <li>
                              <Typography variant="body1">
                                  (c)	Any and all warranties relating to the Website and the Service, including, without limitation, any implied warranties as to satisfactory quality, fitness for a particular purpose and non-infringement of proprietary rights are expressly disclaimed to the fullest extent permitted by law.
                              </Typography>
                          </li>
                      </ul>
                      <Typography variant="body1" paragraph>
                      Whether you are a Client or a Professional or other User, VouchedFor’s aggregate liability under the Terms shall not exceed the higher of:
                      </Typography>
                      <ul>
                          <li>
                              <Typography variant="body1">
                              (a)	the total amount paid by you for any of the Services; or
                               </Typography>
                          </li>
                          <li>
                              <Typography variant="body1">
                              (b)	(£1,000) one thousand GBP.
                               </Typography>
                          </li>
                      </ul>
                </li>
                <li>
                    <Typography variant="subtitle1" color="primary" paragraph>
                        <b>Indemnity</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You agree to indemnify and hold harmless VouchedFor, its officers, employees, agents and service providers from any claim or demand, including reasonable legal fees and court costs, made by any third party due to or arising out of Content you submit, post or make available through the Service, your use of the Service, your violation of the Terms, or your violation of any rights of any person or organisation.
                    </Typography>
                </li>
                <li>
                    <Typography variant="subtitle1" color="primary" paragraph>
                        <b>Limitations on Service</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You acknowledge that VouchedFor may establish limits concerning use of the Website, including but not limited to the maximum number of days that Content will be retained by the Website, the maximum number and size of postings, email messages, or other Content that may be transmitted or stored by the Website, and the frequency with which you may access the Website.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You agree that VouchedFor has no responsibility or liability for the deletion or failure to store any Content maintained or transmitted by the Website. You also acknowledge that VouchedFor reserves the right at any time to modify or discontinue the Website (or any part of it, including the review form) with or without notice.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        VouchedFor shall not be liable to you or to any third party for any modification, suspension or discontinuance of the Service or any part of it, including for loss of profit or consequential loss or damage.
                    </Typography>
                </li>
                <li>
                    <Typography variant="subtitle1" color="primary" paragraph>
                        <b>Termination or restriction of Service</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You agree that VouchedFor, in its sole discretion, has the right to delete, suspend or deactivate your user account, block your email address, or otherwise terminate or restrict your access to or use of the Website immediately and without notice and remove and discard any content within the Website for any reason, including, without limitation, if VouchedFor believes that you have acted inconsistently with the letter or spirit of the Terms.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You agree that neither VouchedFor nor its officers or employees shall be liable to you or any third-party for any termination or restriction of your access to the Website.
                    </Typography>
                </li>
                <li>
                    <Typography variant="subtitle1" color="primary" paragraph>
                        <b>Privacy Policy</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        VouchedFor has established a Privacy Policy which governs how Users’ information is collected and used, and this policy is located in the Legal section of the website. Your use of the Website and/or the Service signifies that you have read and understood our Privacy Policy. Our Privacy Policy has been updated in line with the requirements of applicable data protection law.
                    </Typography>
                </li>
                <li>
                    <Typography variant="subtitle1" color="primary" paragraph>
                        <b>Changes to Terms</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        VouchedFor reserves the right to change or update these Terms from time to time. As a User of the Website, you are responsible for informing yourself of any updates of these Terms each time you visit our Website or use our Services. You will be deemed to have accepted the Terms when you next visit the Website and/or use the Services. Any significant changes in the Terms will be notified by an announcement on the Website and/or an email to active Users.
                    </Typography>
                </li>
                <li>
                  <Typography variant="subtitle1" color="primary" paragraph>
                    <b>Waiver</b>
                  </Typography>
                  <Typography variant="body1" paragraph>
                      A waiver of any right or remedy under these Terms or by law is only effective if given in writing and shall not be deemed a waiver of any subsequent right or remedy.
                  </Typography>
                  <Typography variant="body1" paragraph>
                      A failure or delay by a party to exercise any right or remedy provided under these Terms or by law shall not constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict any further exercise of that or any other right or remedy. No single or partial exercise of any right or remedy provided under these Terms or by law shall prevent or restrict the further exercise of that or any other right or remedy.
                  </Typography>
                </li>
                <li>
                  <Typography variant="subtitle1" color="primary" paragraph>
                    <b>No partnership or agency</b>
                  </Typography>
                  <Typography variant="body1" paragraph>
                      Nothing in these Terms is intended to, or shall be deemed to, establish any partnership or joint venture between any of the parties, constitute any party the agent of another party, or authorise any party to make or enter into any commitments for or on behalf of any other party. Each party confirms it is acting on its own behalf and not for the benefit of any other person.
                  </Typography>
                </li>
                <li>
                  <Typography variant="subtitle1" color="primary" paragraph>
                    <b>Third Party Rights</b>
                  </Typography>
                  <Typography variant="body1" paragraph>
                      These Terms does not give rise to any rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of this agreement.
                  </Typography>
                </li>
                <li>
                  <Typography variant="subtitle1" color="primary" paragraph>
                    <b>Governing law & jurisdiction</b>
                  </Typography>
                  <Typography variant="body1" paragraph>
                      These Terms shall be governed by and interpreted in accordance with English law. Disputes arising in connection with these Terms shall be subject to the exclusive jurisdiction of the English courts.
                  </Typography>
                  <Typography variant="body1" paragraph>
                      If any provision of these Terms is held to be illegal or unenforceable such provision shall be severed and the remainder of these Terms shall remain in full force and effect, unless the business purpose of the contract between us is substantially frustrated as a result.
                  </Typography>
                </li>
            </ol>
            <Typography variant="h6" color="primary" paragraph>
                Professional Specific terms
            </Typography>
            <ol>
                <li>
                    <Typography variant="subtitle1" color="primary" paragraph>
                        <b>Acceptance of Terms</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        In submitting an online registration form (“Registration Form”) or submitting a registration request - in writing or verbally to a VouchedFor representative to participate in the Service you agree to comply with and be bound by (i) our general terms and conditions above (“Terms”), and (ii) the terms and conditions set out below (“Professional Terms”) (which shall supplement and form part of the Terms), which together with the Registration Form or registration request constitute a binding contract between you and VouchedFor. Definitions used in the General Terms also apply in these Professional Terms.
                    </Typography>
                </li>
                <li>
                    <Typography variant="subtitle1" color="primary" paragraph>
                        <b>Term</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Unless otherwise agreed in writing, your initial Subscription Period shall be one calendar month from the date upon which you have provided billing details, any trial period has been used or expired, and VouchedFor has activated your profile on the Website. Subject to receipt of your subscription payment for each new Subscription Period, your subscription shall automatically renew for successive periods of one calendar month until terminated by you or VouchedFor. Each calendar month for which you have subscribed with VouchedFor shall be a “Subscription Period”.
                    </Typography>
                </li>
                <li>
                    <Typography variant="subtitle1" color="primary" paragraph>
                        <b>Completion of Profile</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        At the time of registration for the Service you will be asked to complete your online profile, which you must do as fully and accurately as possible. You must not link to your own website, include your contact details or encourage people to contact you through a channel other than VouchedFor on your VouchedFor profile. You are responsible for keeping your profile up to date at all times and you must promptly notify VouchedFor of any material changes in relation to your details or status which may affect your eligibility to use the Service (including without limitation any change in your FCA or SRA registration, membership of the Law Society for Scotland or Northern Ireland, or membership of the accountancy body you registered with).
                    </Typography>
                </li>
                <li>
                    <Typography variant="subtitle1" color="primary" paragraph>
                        <b>Fees and Payment</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Any significant changes to our charging structure, subscription fees, enquiry fees or addition or changes to other charges will be communicated in advance via an e-mail to active Professionals.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <b>Subscription Fees</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Professionals shall pay to VouchedFor subscription fees and other fees in respect of the Service on the basis and at the levels described on the following page of our Website: <a href="https://support.vouchedfor.co.uk/article/rhe30miunp-pricing-summary" target="_blank">pricing summary</a> (“Fee Page”) (all rates and fees set out in the Fees Page are together and individually referred to as the “Fees”).
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <b>Enquiry Fees</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        If you are required to pay enquiry fees as part of the Fees (“Enquiry Fees"), your obligation to pay Enquiry Fees shall survive, and shall not be affected by, expiry or cancellation of your subscription.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You will be required to pay the appropriate Enquiry Fee at the rates specified in the Fee Page each time a Client contacts a Professional through the Services. There may be instances where you are entitled to a refund of an Enquiry Fee which you have paid to us. Please refer to our refund policy for more information about this: <a href="https://support.vouchedfor.co.uk/article/102-enquiry-refund-policy" target="_blank"> enquiry refund policy</a>.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You agree that, promptly (and in any event within one Business Day) following receipt of an enquiry from a potential Client through the Service, you will make contact with the potential Client via the Service or directly by telephone or email, observing any contact preference. A “Business Day” means any day other than a Saturday or Sunday when banks are generally open for business in London.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        In certain situations, VouchedFor may agree exceptional pricing structures with certain Professionals or Firms  - these will always be communicated in writing with the Professionals in question.
                    </Typography>
                        <b>VAT</b>
                    <Typography variant="body1" paragraph>
                        All Subscription Fees and Enquiry Fees quoted above are exclusive of VAT which will be charged as an additional item on Subscription Fees and Enquiry Fees as applicable.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <b>Billing & Invoicing</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        VouchedFor shall bill your bank account by way of direct debit using the payment details provided directly or through our  third party payment provider. You accept that where we use a third party payment provider to charge and/or collect payment of the Fee from you, you will be required to accept their terms and conditions.
                    </Typography>
                    <Typography variant="body1" paragraph>
                      Unless otherwise agreed in writing by VouchedFor, the Fees shall be become due and payable as set out below:
                    </Typography>
                    <ul>
                        <li>
                            <Typography variant="body1">
                            (a)	In respect of the Subscription Fee on a monthly basis in advance of the upcoming Subscription Period; and
                             </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                            (b)	In respect of the Enquiry Fee on a weekly basis for all enquiries made in the previous week.
                             </Typography>
                        </li>
                    </ul>
                    <Typography variant="body1" paragraph>
                      Where that arrangement is not in place and an alternative has been agreed in writing with VouchedFor, VouchedFor will supply you with invoices upon any Subscription Fee or Enquiry Fee becoming due (as set out above) (or on a weekly, monthly or other periodic basis at the discretion of VouchedFor). All invoices shall be payable without deduction, set-off, counterclaim or withholding within 7 days of receipt.
                    </Typography>
                    <Typography variant="body1" paragraph>
                      VouchedFor reserves the right to charge interest on any overdue amounts at the rate of 4% above the base lending rate of HSBC Bank plc from time to time, such interest accruing daily. VouchedFor may also suspend the provision of the Services to you without liability where you fail to make payments when they fall due.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <b>Suspension of the Service</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Your obligation to pay Subscription Fees and Enquiry fees shall not be affected by any disruption, discontinuance, downtime or suspension of the Service for any reason.
                    </Typography>
                </li>
                <li>
                    <Typography variant="subtitle1" color="primary" paragraph>
                        <b>Cancellation</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <b>Your right to cancel</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You may cancel your subscription to the Service at any time by giving VouchedFor at least one calendar month's notice in writing before your next monthly or annual payment is due. Any Subscription Fees already paid by you in respect of periods following the cancellation date shall be forfeited and shall belong to VouchedFor.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <b>Cancellation by VouchedFor by notice</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        VouchedFor may cancel your subscription to the Service by notice in writing given at any time prior to the end of the current Subscription Period, to take effect at the end of the current Subscription Period.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Upon cancellation by VouchedFor by notice, VouchedFor shall refund any Subscription Fees already paid by you in respect of periods following the cancellation date.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <b>Cancellation for an Event of Default</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        VouchedFor may cancel your subscription and/or delete, suspend or deactivate your user account or otherwise terminate or restrict your access to the Service immediately and without notice and remove and discard any content within the Website if you commit an “Event of Default”. Each of the following shall be an Event of Default:
                    </Typography>
                    <ul>
                        <li>
                            <Typography variant="body1">
                                (i)	You cease providing the services of a Professional;
                             </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                (ii)	you fail to comply fully with any VouchedFor investigations into the validity of information you have provided or feedback you have received from Users, or those investigations raise significant suspicions, as determined by VouchedFor at its sole discretion;
                             </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                (iii)	your registration with FCA, SRA or accountancy chartered body or any other applicable professional or regulatory body lapses or is cancelled;
                             </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                (iv)	you become subject to any inspection, investigation or enquiry by the FCA, SRA or any other professional statutory or regulatory body (save for routine inspections in the ordinary course) or any consumer body or reputable news service;
                             </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                (v)	your firm has a legally-binding contract in place with you that requires you to deactivate your VouchedFor profile upon you leaving the firm;
                             </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                (vi)	we receive a complaint about you from a Client or other User or you become involved in a dispute with a Client or other User, in circumstances where VouchedFor considers, in its absolute discretion, that your continued participation in the Service or presence on the Website may cause harm to the business or reputation of (a) the Service, (b) VouchedFor, or (c) VouchedFor’s officers or employees;
                             </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                (vii)	you commit a material breach of the Terms or these Professional Terms (including, without limitation, failure to make any payment of Subscription Fees or Enquiry Fees when due);
                             </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                (viii)	you become unable to pay your debts within the meaning of the Insolvency Act 1986, or
                             </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                (ix)	a receiver, liquidator, administrator or trustee in bankruptcy is appointed in respect of all or any part of your undertaking or assets, or
                             </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                (x)	you enter into a creditors’ voluntary arrangement or an individual voluntary arrangement, or
                             </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                (xi)	any order is made or resolution is passed in connection with the liquidation, administration, bankruptcy or winding up of you or your business.
                             </Typography>
                        </li>
                    </ul>
                    <Typography variant="body1" paragraph>
                        You are obliged to notify VouchedFor immediately in writing upon becoming aware that an Event of Default has occurred.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        If VouchedFor cancels your subscription as a result of an Event of Default, any Subscription Fees already paid by you in respect of periods following the cancellation date shall be forfeited and shall belong to VouchedFor.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        If VouchedFor cancels or suspends your subscription as a result of an Event of Default, then VouchedFor reserves the right to inform the Client, any regulatory body, any court or other third party the reason of such cancellation or suspension.
                    </Typography>
                </li>
                <li>
                    <Typography variant="subtitle1" color="primary" paragraph>
                        <b>Client Management</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We reserve the right to contact reviewers to verify Content (including feedback and reviews about Professionals) they have written.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        If we are unable to verify your review Content, we may suspend or deactivate your account and you will not be eligible for any refunds of your Subscription Fees. If we deactivate your account while we verify your reviews, we will continue to charge you your monthly Subscription Fees until our investigation is complete.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        VouchedFor reserves the right to verify your compliance with the Terms or these Professional Terms generally by any lawful means including (without limitation) by contacting Clients, mystery shopping and through monitoring on-site behaviour.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You understand and agree that VouchedFor will remain in contact with Clients during their use of the Service and that upon request by a Client, VouchedFor shall be entitled (but not obliged) to investigate and become involved in any Client complaint or grievance or any dispute between you and a Client.
                    </Typography>
                </li>
                <li>
                    <Typography variant="subtitle1" color="primary" paragraph>
                        <b>Accuracy of Information</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        By completing a Registration Form and using the Service you unconditionally confirm that:
                    </Typography>
                    <ul>
                        <li>
                          <Typography variant="body1">
                              You acknowledge and agree that, without the need for any further consent, VouchedFor shall be entitled to contact directly the FCA, SRA (and/or any other applicable professional or certifying body), financial institutions, networks and any other body or organization which VouchedFor may in its discretion decide, in order to verify your FCA, SRA of accountancy chartered body authorization and any information posted on the Website or otherwise provided by you in connection with the Service, or to verify the validity of any information provided by a User posting feedback about your service. In the case of an individual representative of a financial advice firm we also reserve the right to contact their employer or any previous employer of such representative for the same purpose. You also acknowledge and agree that, without the need for any further consent, VouchedFor shall be entitled to inform such bodies and organisations of any suspicions it may have concerning the authenticity of any information provided by you in connection with the Service.
                          </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                The details provided by you in your Registration Form, profile template and in your online firm profile is true, fair and accurate in all material respects and complies with all applicable regulatory or statutory requirements;
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                You are registered with the appropriate regulatory and/or professional body, including, without limitation:
                            </Typography>
                            <ul>
                                <li>
                                    <Typography variant="body1">
                                        with the FCA as a Financial Adviser, under the registration number you have provided;
                                    </Typography>
                                    <Typography variant="body1">
                                        OR
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body1">
                                        with the SRA, Law Society of Scotland or Law Society of Northern Ireland as a solicitor;
                                    </Typography>
                                    <Typography variant="body1">
                                        OR
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body1">
                                        with the Professional Paralegal Register (PPR) as a Tier 2 paralegal (or higher) and, if you are a self-employed practitioner, you hold a Paralegal Practicing Certificate;
                                    </Typography>
                                    <Typography variant="body1">
                                        OR
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body1">
                                        with the ICAEW, ACCA, ICAS, CIMA, CAI, CIPFA, CIOT, ATT, IFA, AAT, ICB or IAB as a Chartered Accountant, Chartered Certified Accountant, Chartered Management Accountant, Accountant or Bookkeeper.
                                    </Typography>
                                </li>
                                <li>
                                    Or, if you are a Financial Adviser, Mortgage Adviser, Protection Adviser or Financial Coach who is not personally registered with the FCA, your firm is registered with the FCA under the registration number you have provided and a Senior Manager at your firm is able to confirm your authorisation under the Senior Manager & Certification Regime.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Typography variant="body1">
                                And if you registered as an Independent Financial Adviser, you also confirm that:
                            </Typography>
                            <ul>
                                <li>
                                    <Typography variant="body1">
                                        you are an independent adviser (per the latest FCA definition) and you are able to provide fee-based advice to Clients;
                                    </Typography>
                                    <Typography variant="body1">
                                        and
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body1">
                                        you have accurately stated whether you offer either Independent or Restricted advice in each product area (including but not limited to Investments, Pensions and Mortgages)
                                    </Typography>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Typography variant="body1">
                                And if you registered as a Restricted Financial Adviser, you also confirm that:
                            </Typography>
                            <ul>
                                <li>
                                    <Typography variant="body1">
                                        you are a restricted adviser (per the latest FCA definition) and you are able to provide fee-based advice to Clients;
                                    </Typography>
                                    <Typography variant="body1">
                                        and
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body1">
                                        you have accurately stated whether you offer either Independent or Restricted advice in each product area (including but not limited to Investments, Pensions and Mortgages)
                                    </Typography>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Typography variant="body1">
                                And if you registered as a Focussed Independent Financial Adviser you also confirm that;
                            </Typography>
                            <ul>
                                <li>
                                    <Typography variant="body1">
                                        you are a Focussed Independent Adviser (per the latest FCA definition) and you are able to provide fee-based advice to Clients;
                                    </Typography>
                                    <Typography variant="body1">
                                        and
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body1">
                                        you have accurately stated in which product areas you offer Focussed Independent Advice (including but not limited to Investments, Pensions and Mortgages)
                                    </Typography>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    <Typography variant="subtitle1" color="primary" paragraph>
                        <b>Compliance with law</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You acknowledge and agree that in completing a Registration Form, participating in the Service and providing services to Clients you are required to inform yourself of and comply with all applicable laws, regulations and guidelines (whether mandatory or not) and VouchedFor shall have no responsibility for reviewing or verifying your compliance.
                    </Typography>
                </li>
                <li>
                    <Typography variant="subtitle1" color="primary" paragraph>
                        <b>Feedback</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You acknowledge and agree that feedback from Clients to whom you have provided advice, or met with, may be published on the Website and in such other places and/or forms as VouchedFor shall reasonably decide, and that VouchedFor shall not have any responsibility for screening or monitoring such feedback.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        In the event that you suspect that any feedback made about you on the Website is not authentic feedback from a Client advised by you, or you consider any feedback to be abusive, libellous, inappropriate or in breach of the Terms you should bring this to the attention of VouchedFor who shall take reasonable steps to investigate the matter. We may take such action, in our absolute discretion, in relation to such a matter, which may involve the editing or removal of such feedback.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Your obligation to pay Subscription Fees and Enquiry Fees shall not be affected by any action we take (or refrain from taking) in relation to feedback.
                    </Typography>
                </li>
                <li>
                    <Typography variant="subtitle1" color="primary" paragraph>
                        <b>Use of Information in Promotional Material</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        By completing the Registration Form, you give VouchedFor permission to reference you, your profile, your photo and your client feedback in any material which promotes the VouchedFor Website or the Services.
                    </Typography>
                </li>
                <li>
                    <Typography variant="subtitle1" color="primary" paragraph>
                        <b>“Top Rated” marketing benefits</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                         “Top Rated” means a Professional who has generated over 10 positive client reviews, with an average rating of 4.5 or more out of 5 within the specified time period as communicated by VouchedFor to you.
                    </Typography>
                    <Typography variant="body1" paragraph>
                         To qualify as “Top Rated”, Professionals must be VouchedFor Verified or Unlimited members. This ensures that they are subject to all of our checks and ongoing monitoring.
                    </Typography>
                    <Typography variant="body1" paragraph>
                         “Top Rated” status is awarded each year or as and when otherwise determined by the VouchedFor at its discretion e.g. Professionals can be “Top Rated 2019”.
                    </Typography>
                    <Typography variant="body1" paragraph>
                         “Top Rated” Professionals may feature in print or digital guides from time to time. “Top Rated” Professionals can also use this status on promotional material and get access to several tools e.g. the Top Rated Report and Top Rated Email Signature.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Guide merchandise: As a Top Rated adviser, you have the opportunity to purchase merchandise connected with the 2021 Top Rated Adviser Guide, including trophies and copies of the Guide. The prices we charge for these products are listed on the website. We reserve the right to change our prices for products displayed at any time, and to correct pricing errors that may inadvertently occur. Additional information about pricing and sales tax is available on the payments page. We may, without prior notice, change the merchandise services; stop providing the services or any features of the services we offer; or create limits for the services. We may permanently or temporarily terminate or suspend access to the services without notice and liability for any reason, or for no reason. You have the right to claim a refund, replacement, repair and/or compensation where the goods are faulty or misdescribed.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        VouchedFor reserves the right to introduce new policies governing the use by the Professional of “Top Rated” logos, badges, tools or any other terms, names, tags or other marketing materials ascribed to an awards or marketing scheme VouchedFor runs from time to time.
                    </Typography>
                    <Typography variant="body1" paragraph>
                      <b>Duration of Marketing Materials</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Professionals can continue to use the logos, badges and tools or any other terms, names, tags  or other marketing materials (together “Marketing Materials”) ascribed to an awards or the “Top Rated” scheme VouchedFor runs from time to time (“Top Rated Scheme”) for marketing purposes during and after the year they obtained the required status under the applicable Top Rated Scheme (“Top Rated Status”) as long as they do not delete or amend the logo, badges or tools, especially the year they denote.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        If a Professional ceases to be a Verified or Unlimited VouchedFor member, either of their own accord or at VouchedFor’s request, the use of the Marketing Materials or any right to refer to themselves as being part of the Top Rated Scheme or achieving the Top Rated Status is revoked. The Professional must remove all VouchedFor Marketing Materials and any references to being part of the Top Rated Scheme or achieving the Top Rated Status from all their marketing materials including websites, social media and printed materials.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Where we cease to run a Top Rated Scheme we shall notify you in writing (including by e-mail). After receipt of such notification, the Professional must remove all VouchedFor Marketing Materials and any references to being part of the Top Rated Scheme or achieving the Top Rated Status from all their marketing materials including websites, social media and printed materials.
                    </Typography>
                    <Typography variant="body1" paragraph>
                      <b>VouchedFor's Obligations</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We reserve the right to change the content, promotional schedule and date of all Top Rated Guides and other marketing campaigns. VouchedFor shall not be liable for any loss suffered by you or your firm as a result of not being listed. If you wish to opt out of being featured in the national press, please email customer_service@vouchedfor.co.uk.
                    </Typography>
                    <Typography variant="body1" paragraph>
                      <b>Your Obligations</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You shall provide VouchedFor with all information required by VouchedFor to facilitate the delivery of a timely and proper marketing campaign.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You warrant and represent that all information provided to VouchedFor is accurate and complete.
                    </Typography>
                    <Typography variant="body1" paragraph>
                      <b>Intellectual Property Rights</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        All copyrights, design rights and other intellectual or proprietary rights in any assets or materials created (or the subject-matter of which is created) whether by VouchedFor or by you for the purposes of or in connection with any Top Rated Scheme, including  the Marketing Materials  shall belong to VouchedFor and you hereby assign to VouchedFor absolutely with full title guarantee all intellectual property and other proprietary rights in such assets or materials, which might vest in you in by operation of law, on their creation. For the avoidance of any doubt, VouchedFor shall be entitled to amend, edit, publish and re-publish all Marketing Materials, and retains the right to use all such material for its future professional use.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You may not use the “VouchedFor” name, trade mark or logo without our prior express consent.
                    </Typography>
                    <Typography variant="body1" paragraph>
                      <b>Opting-out</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                    If after registering and qualifying to receive Top Rated Status you decide that you would prefer not to receive the marketing benefits, please email customer_service@vouchedfor.co.uk. You can decide to opt-out at any time.
                    </Typography>
                </li>
                <li>
                    <Typography variant="subtitle1" color="primary" paragraph>
                        <b>The VouchedFor Standard</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        The VouchedFor Standard is a benchmark that advisers can achieve by passing all VouchedFor checks on an ongoing basis and collating over 10 positive client reviews.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        As conducting such rigorous checks takes a lot of time, only Verified and Unlimited members are eligible for The VouchedFor Standard.
                    </Typography>
                </li>
                <li>
                    <Typography variant="subtitle1" color="primary" paragraph>
                        <b>Pension Advice Vouchers</b>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Pension Advice Vouchers are electronic vouchers which allow a Client to be partially reimbursed for fees the Client has paid for pension advice. VouchedFor no longer offers Pension Advice Vouchers. The terms on which these were supplied are covered in historic versions of the Terms. If you have any questions about the Pension Advice Vouchers, please contact customer_service@vouchedfor.co.uk.
                    </Typography>
                </li>
            </ol>
        </Fragment>
    );
}

export default TAndC;