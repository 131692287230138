import { useListContext } from 'react-admin';
import { Button, Toolbar } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EmptyList from './EmptyList';

const useStyles = makeStyles({
    pageNav: {
        marginLeft: 'auto',
        marginRight: -12,
    },
});

const ListPagination = props => {
    const classes = useStyles();
    const { page, perPage, total, setPage } = useListContext();
    const { onClickForward, onClickBackwards, className } = props;
    const nbPages = Math.ceil(total / perPage) || 1;

    return nbPages > 1 ? (
        <section className={`${classes.pageNav} ${className}`}>
            <Toolbar>
                {page > 1 && (
                    <Button
                        color="secondary"
                        key="prev"
                        className="prev"
                        onClick={() => {
                            setPage(page - 1);
                            if (onClickBackwards) onClickBackwards();

                        }}>
                        <ChevronLeft />
                        Prev
                    </Button>
                )}
                {page !== nbPages && (
                    <Button
                        color="secondary"
                        key="next"
                        className="next"
                        onClick={() => {
                            setPage(page + 1);
                            if (onClickForward) onClickForward();
                        }}>
                        Next
                        <ChevronRight />
                    </Button>
                )}
            </Toolbar>
        </section>
    ) : total == 0 ? (
        <EmptyList />
    ) : null;
};

export default ListPagination;
