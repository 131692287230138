import React from 'react';
import PageActions from '../../../AppLayout/PageActions';
import SettingBillingTabs from './SettingsBillingTabs';

const SettingsBillingPageHeader = () => {
    return (
        <>
            <PageActions title="Settings & Billing" />
            <SettingBillingTabs />
        </>
    );
};

export default SettingsBillingPageHeader;
