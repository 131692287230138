import React from "react";
import _ from "lodash";
import cn from "classnames";
import { connect } from "react-redux";
import ServicesList from "../Services/ServicesList";
import MinimumInvestmentFields from "../Components/Inputs/MinimumInvestmentFields";
import LinkedSelect from "../Components/Inputs/LinkedSelect";
import ProfessionalPageWrapper from "./Components/ProfessionalPageWrapper";
import {
    PROFESSIONAL,
    PROMOTION_PARENT_ATTRIBUTE,
    SUPPORT_URL,
} from "../constants";
import { ExternalLink } from "../Components/Links";
import ServicesRemoveIntroductoryOfferButton from "../Services/Components/ServicesRemoveIntroductoryOfferButton";

import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";

const { FINANCIAL_ADVISER, LEGAL_ADVISER } = PROFESSIONAL.VERTICAL;

const useStyles = makeStyles(theme => ({
    row: {
        marginTop: theme.spacing(5),
        display: 'flex',
        width: '100%',
        alignItems: 'flex-start',

        [theme.breakpoints.only('xs')]: {
            flexDirection: 'column',
        }
    },
    introductoryOfferWrapper: {
        marginRight: theme.spacing(2),
        width: 360,

        [theme.breakpoints.only('xs')]: {
            width: '100%',
            marginRight: 0,
        }
    },
    button: {
        marginTop: theme.spacing(2),

        [theme.breakpoints.only('xs')]: {
            marginTop: 0,
        }
    },
}));

const Services = (props) => {
    const classes = useStyles();
    const {
        showNotification,
        vertical,
        hasPromoOffer,
        isFinancialAdviser,
        isLegalAdviser,
        isFinancialCoach,
        ...rest
    } = props;

    return (
        <ProfessionalPageWrapper {...rest}>
            <ServicesList {...rest} />
            {isFinancialAdviser && !isFinancialCoach && <MinimumInvestmentFields {...rest} />}
            {hasPromoOffer && (
                <>
                    <div className={cn({ [classes.row]: !isFinancialAdviser })}>
                        <div className={classes.introductoryOfferWrapper}>
                            <LinkedSelect
                                label="Minimum Introductory Offer"
                                source="attributes.introductory_offer"
                                resourceName="services-settings-choices"
                                resourceId={PROMOTION_PARENT_ATTRIBUTE[vertical]}
                                fullWidth
                            />
                        </div>
                        {isLegalAdviser && (
                            <div className={classes.button}>
                                <ServicesRemoveIntroductoryOfferButton />
                            </div>
                        )}
                    </div>
                </>
            )}
            <Typography variant="body1">
                Please note that a change in type of service or minimum
                wealth or mortgage criteria may affect how we charge or
                count the enquiries you receive.&nbsp;
                <ExternalLink href={SUPPORT_URL.ACCOUNT.PRICING_PLANS}>
                    Find out more about how we charge
                </ExternalLink>
            </Typography>
        </ProfessionalPageWrapper>
    );
}


const mapStateToProps = state => {
    const vertical = _.get(state, ['basicInfo', 'permissions', 'vertical']);

    const hasPromoOffer = _.get(
        state,
        ['basicInfo', 'permissions', 'promotional_offer'],
        false
    );

    return {
        vertical,
        hasPromoOffer,
        isFinancialAdviser: vertical === FINANCIAL_ADVISER,
        isLegalAdviser: vertical === LEGAL_ADVISER,
        isFinancialCoach: _.get(state, ['basicInfo', 'permissions', 'coach_mode']),
    };
};

export default connect(mapStateToProps)(Services);
