import React, { Component } from 'react';
import { connect } from 'react-redux';
import dataProvider from '../../dataProvider';
import BillingListContent from './Components/BillingListContent';
import BillingListFilter from './Components/BillingListFilter';
import SettingsBillingPageWrapper from './Components/SettingsBillingPageWrapper';

class BillingList extends Component {
    state = {
        selectableMonths: [],
        defaultValue: '',
        billingDetails: {},
    };

    componentDidMount() {
        dataProvider('GET_LIST', 'billing-months').then(result => {
            const months = result.data.reverse();
            let selectableMonths = [];

            months.forEach(month => {
                selectableMonths.push({
                    id: month.month_value,
                    name: month.month_string,
                    status: month.status,
                });
            });

            this.setState({
                selectableMonths: selectableMonths,
                defaultValue: selectableMonths[0].id,
                trigger: true,
            });
        });
    }

    render() {
        const { defaultValue, selectableMonths, billingDetails, trigger } = this.state;
        const { isPlanUpgradeSuccess, basicInfo, dispatch, permissions, ...props } = this.props;

        return (
            <SettingsBillingPageWrapper
                {...props}
                variant="list"
                pagination={null}
                filterDefaultValues={{ monthyear: defaultValue }}
                key={trigger}
                filters={
                    <BillingListFilter
                        permissions={permissions}
                        showBillingMessage={isPlanUpgradeSuccess}
                        selectableMonths={selectableMonths}
                        billingDetails={billingDetails}
                    />
                }
            >
                <BillingListContent
                    selectableMonths={selectableMonths}
                    billingDetails={billingDetails}
                    showBillingMessage={isPlanUpgradeSuccess}
                />
            </SettingsBillingPageWrapper>
        );
    }
}

const mapStateToProps = ({ basicInfo }) => {
    return {
        isPlanUpgradeSuccess: basicInfo.upgradeSuccess,
    };
};

export default connect(mapStateToProps)(BillingList);
