import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import dataProvider from '../../dataProvider';
import CopyTextButton from '../../Components/Buttons/CopyTextButton';
import * as analytics from '../../analytics';

import ReputationToolItem from './Components/ReputationToolItem';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    imageWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '-24px -24px 24px',
        minHeight: theme.spacing(23),
        [theme.breakpoints.down('sm')]: {
            position: 'inherit',
        },
    },
    buttons:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}));

const ProfessionalWidget = props => {
    const [widgetImage, setWidgetImage] = useState('');
    const [widgetCode, setWidgetCode] = useState('');
    const classes = useStyles();
    const {cardDisabled} = props;

    useEffect(() => {
        (async () => {
            const res = await dataProvider('GET_ONE', 'reputation/widget');

            setWidgetImage(_.get(res, 'data.url'));
            setWidgetCode(_.get(res, 'data.html'));
        })();
    }, []);

    const handleCopy = async () => {
        await dataProvider('CREATE', 'reputation/usage/copied-widget-code', {
            data: {},
        });

        sendAnalytics('Copied widget code');
    };

    const sendAnalytics = action => {
        analytics.track(action, {
            category: 'Profile promotion',
        });
    };

    return (
        <ReputationToolItem
            cardDisabled={cardDisabled}
            route="/reputation/widget"
            content={
                <>
                    <div className={classes.imageWrapper}>
                        <img src={widgetImage} alt="widget"/>
                    </div>
                    <div className={classes.buttons}>
                        <CopyTextButton
                            clipboardContent={widgetCode}
                            onClick={handleCopy}
                            style={{ marginRight: 8 }}
                            variant="contained"
                        >
                            Copy code
                        </CopyTextButton>
                    </div>
                </>
            }
        />
    );
};

export default ProfessionalWidget;
