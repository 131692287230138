import React from 'react';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    root: {
        marginBottom: theme.spacing(4),
    },
    titleWrapper: {
        width: '100%',
    },
});

const DashboardRow = ({ content, title, classes }) => (
    <Grid item md={12} className={classes.root}>
        <div className={classes.titleWrapper}>{title}</div>
        {content}
    </Grid>
);

export default withStyles(styles)(DashboardRow);
