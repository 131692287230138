import React from 'react';
import _ from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ReferenceManyField, Datagrid, TextField, DateField, DeleteButton, EditButton } from 'react-admin';
import { Typography } from '@material-ui/core';
import { FormSectionTitle } from '../Components/Typography/Headlines';
import { ChipUrl } from '../Components/Links';
import ProfessionalPageWrapper from './Components/ProfessionalPageWrapper';
import { ExternalLink } from '../Components/Links';
import { SUPPORT_URL } from '../constants';
import AddButton from '../Components/Buttons/AddButton';
import FormLongTextInput from '../Components/Inputs/Form/FormLongTextInput';

const QualificationsSection = props => {
    return (
        <>
            {!_.get(props, 'permissions.qualifications') ? 
                    <Typography variant="body1" gutterBottom>
                        You will be able to add your qualifications once you have upgraded to our Verified plan&nbsp;
                    </Typography> : (
                <>
                    <FormSectionTitle title="Education Summary" />
                    <Typography variant="body1" gutterBottom>
                        To give visitors to your profile even more confidence in you, display your
                        hard-earned qualifications.&nbsp;
                    </Typography>
                    <FormLongTextInput
                        label="Enter a summary of your education"
                        source="education"
                    />
                    <FormSectionTitle title="Qualifications" />
                    <ReferenceManyField
                        {...props}
                        label={null}
                        reference="qualifications"
                        target="professional_id">
                        <Datagrid>
                            <TextField label="Name" source="qualification.name" />
                            <ChipUrl label="View" />
                            <DateField label="Expiry" source="expiry" />
                            <DateField label="Date passed" source="date_passed" />
                            <DeleteButton redirect={false} />
                            <EditButton color="secondary" />
                        </Datagrid>
                    </ReferenceManyField>
                    <AddButton
                        resource="qualifications"
                        label="Add qualification"
                        to={{ pathname: '/qualifications/create' }}
                    />
                </>
            )}
        </>
    );
};

const QualificationsSectionWithPermissions = compose(
    connect(state => ({
        permissions: state.basicInfo.permissions,
    }))
)(QualificationsSection);

const QualificationsChild = props => (
    <div>
        <QualificationsSectionWithPermissions {...props} />
    </div>
);

const Qualifications = props => {
    return (
        <ProfessionalPageWrapper {...props}>
            <QualificationsChild {...props} />
        </ProfessionalPageWrapper>
    );
};

export default Qualifications;
