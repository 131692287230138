import React from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    root: {
        minHeight: 128,

        [theme.breakpoints.down('sm')]: {
            minHeight: 'unset',
        },
    },
});

const StatisticContent = ({ classes, children, ...rest }) => (
    <Typography className={classes.root} {...rest}>
        {children}
    </Typography>
);

export default withStyles(styles)(StatisticContent);
