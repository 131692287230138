import React from 'react';
import {
    Edit,
    SimpleForm,
    TopToolbar,
    TextField
} from 'react-admin';
import _ from 'lodash';
import { connect } from 'react-redux';
import { pastDateValidation } from '../validation';
import { FormSectionTitle } from '../Components/Typography/Headlines';
import BackButton from '../Components/Buttons/BackButton';
import FormDateInput from '../Components/Inputs/Form/FormDateInput';
import PreventUndoEdit from '../Components/PreventUndoEdit';


const QualificationsEdit = props => {
    const { qualificationsUrl, ...rest } = props;
    return (
        <PreventUndoEdit
            title={null}
            {...rest}
            actions={
                <TopToolbar>
                    <BackButton href={qualificationsUrl} />
                </TopToolbar>
            }>
            <SimpleForm redirect={qualificationsUrl}>
                <FormSectionTitle title="Edit Qualification" />
                <TextField source="qualification.name" label="Qualification" />
                <FormDateInput
                    label="Date passed"
                    validate={[pastDateValidation]}
                    source="date_passed"
                />
            </SimpleForm>
        </PreventUndoEdit>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        qualificationsUrl: `/professional/qualifications/${basicInfo.id}`,
    };
};

export default connect(mapStateToProps)(QualificationsEdit);
