import _ from 'lodash';
import { FEE_TYPE, FEE_SUB_TYPE, AVERAGE_FIVE_YEAR_COST } from '../constants';
import { formatCurrency } from '../utils';

const {
    INVESTMENT_AND_PENSION,
    INVESTMENT_AND_PENSION_WITH_PLANNING,
} = FEE_TYPE;
const { INITIAL_AND_ONGOING, FIXED_FEE_ONLY } = FEE_SUB_TYPE;
const {
    TOTAL,
    UK_WIDE,
    HIGHER_PERCENT,
    LOWER_PERCENT,
} = AVERAGE_FIVE_YEAR_COST;

export function getFeesCreateStatistics(values) {
    const data = [];
    const type = _.get(values, 'type', '');
    const fees = _.get(values, 'fees', []);

    if (type === INVESTMENT_AND_PENSION_WITH_PLANNING) {
        data.push({
            value: 109,
            label:
                'VouchedFor advisers with Financial Planning with Implementation & Ongoing Service fees',
        });
    }

    if (type === INVESTMENT_AND_PENSION) {
        data.push({
            value: 306,
            label:
                'VouchedFor advisers with Implementation & Ongoing Service (without Financial Planning) fees',
        });
    }

    if (fees.length) {
        values.fees.forEach(fee => {
            if (fee.type === INVESTMENT_AND_PENSION_WITH_PLANNING) {
                if (fee.subType === FIXED_FEE_ONLY) {
                    data.push({
                        value: '81%',
                        label: `also use Fixed Fees for ${getFeesCreateTypeLabel(
                            INVESTMENT_AND_PENSION_WITH_PLANNING
                        )}`,
                    });
                }

                if (fee.subType === INITIAL_AND_ONGOING) {
                    data.push({
                        value: '19%',
                        label: `also use Percentage of Assets for ${getFeesCreateTypeLabel(
                            INVESTMENT_AND_PENSION_WITH_PLANNING
                        )}`,
                    });
                }
            }

            if (fee.type === INVESTMENT_AND_PENSION) {
                if (fee.subType === FIXED_FEE_ONLY) {
                    data.push({
                        value: '8%',
                        label: `also use Fixed Fees for ${getFeesCreateTypeLabel(
                            INVESTMENT_AND_PENSION
                        )}`,
                    });
                }

                if (fee.subType === INITIAL_AND_ONGOING) {
                    data.push({
                        value: '92%',
                        label: `also use Percentage of Assets for ${getFeesCreateTypeLabel(
                            INVESTMENT_AND_PENSION
                        )}`,
                    });
                }

                if (_.has(fee, 'isWealthBased')) {
                    if (fee.isWealthBased === true) {
                        data.push({
                            value: '40%',
                            label: 'also tier their charges this way',
                        });
                    } else {
                        data.push({
                            value: '60%',
                            label: 'also tier their charges this way',
                        });
                    }
                }
            }
        });
    }

    return data;
}

export function getFeesCreateTypeLabel(type) {
    if (!type) return '';

    if (type === INVESTMENT_AND_PENSION) {
        return 'Implementation & Ongoing Service fees';
    }

    if (type === INVESTMENT_AND_PENSION_WITH_PLANNING) {
        return 'Financial Planning fees';
    }

    return '';
}

export function getFeesCreateFormLabels(
    type,
    withFinancialPlanning,
    isWidthDown = false
) {
    const typeLabel = getFeesCreateTypeLabel(type, withFinancialPlanning);
    let subType = `Please choose the model you'd like to use for ${typeLabel}`;
    let isWealthBased = `Please choose which way you tier your charges for ${typeLabel}`;

    if (isWidthDown) {
        subType = `Select model for ${typeLabel}`;
        isWealthBased = `How do you tier your charges for this model?`;
    }

    return { subType, isWealthBased };
}

export function getFeeLevelFormTitle(
    feeType,
    investmentFeeSubType,
    isCreateForm = false
) {
    const prefix = isCreateForm ? 'Add' : 'Edit';

    if (feeType === '') {
        return `${prefix} Fee Level`;
    }

    if (investmentFeeSubType === FIXED_FEE_ONLY) {
        return `${prefix} Fee Level: ${feeType}, Fixed Fee only`;
    }

    return `${prefix} Fee Level: ${feeType}, Percentage of Assets only`;
}

export function getFeesChargeLevelsStatistics(chargeLevels) {
    if (!chargeLevels) return [];

    const minimumAmountsList = chargeLevels.map(
        chargeLevel => chargeLevel.minimumAmount
    );

    if (minimumAmountsList.length === 0) {
        return [];
    }

    const minimumLevel = Math.min(...minimumAmountsList);

    if (!minimumLevel || minimumLevel === 0 || minimumLevel === null) {
        return [
            { value: '30%', label: 'of advisers have a similar minimum level' },
        ];
    }

    if (minimumLevel > 0 && minimumLevel <= 49999) {
        return [
            { value: '22%', label: 'of advisers have a similar minimum level' },
        ];
    }

    if (minimumLevel > 50000 && minimumLevel <= 99999) {
        return [
            { value: '28%', label: 'of advisers have a similar minimum level' },
        ];
    }

    if (minimumLevel > 100000 && minimumLevel <= 249999) {
        return [
            { value: '14%', label: 'of advisers have a similar minimum level' },
        ];
    }

    if (minimumLevel > 250000) {
        return [
            { value: '6%', label: 'of advisers have a similar minimum level' },
        ];
    }

    return [];
}

export function getChargeLevelsFromRecord(record, key) {
    return _.get(record, ['content', key, 'chargeLevels'], []);
}

export function getChargeLevelStatistics(record, hasFinancialPlanningFees) {
    const chargeLevelsFromRecord = getChargeLevelsFromRecord(
        record,
        hasFinancialPlanningFees ? 'financialPlanning' : 'investment'
    );

    return getFeesChargeLevelsStatistics(chargeLevelsFromRecord);
}

export function hasChargeLevels(record, key = '') {
    if (!record) return false;

    return getChargeLevelsFromRecord(record, key).length > 0;
}

export function getFeesStatisticFormattedValue(value, key) {
    switch (key) {
        case TOTAL:
        case UK_WIDE:
            return formatCurrency(value);
        case HIGHER_PERCENT:
        case LOWER_PERCENT:
            return `${value} %`;
        default:
            return value.toString();
    }
}

export function getFeesStatisticsRenderProps(isLoggedOut, assetLevel = null) {
    return {
        [TOTAL]: {
            stat: TOTAL,
            label: isLoggedOut && Boolean(assetLevel)
                ? `Your total 5 year cost for assets of ${getFeesStatisticFormattedValue(
                    assetLevel,
                    TOTAL
                )}`
                : 'Your total 5 year cost for this asset level',
            color: 'blue',
        },
        [UK_WIDE]: {
            stat: UK_WIDE,
            label: 'UK average 5 year cost',
            color: 'green'
        },
        [HIGHER_PERCENT]: {
            stat: HIGHER_PERCENT,
            label: 'of advisers have higher fees than you (or equal)',
            color: 'red',
        },
        [LOWER_PERCENT]: {
            stat: LOWER_PERCENT,
            label: 'of advisers have lower fees than you',
            color: 'yellow',
        },
    };
}
