import React from 'react';
import MinimumInvestmentFields from '../../Components/Inputs/MinimumInvestmentFields';

const BasicProfileFormInvestmentFields = props => {
    return (
        <MinimumInvestmentFields
            {...props}
            defaultValue={{
                minimum_investment_criteria: null,
                minimum_mortgage_criteria: null,
            }}
        />
    );
};

export default BasicProfileFormInvestmentFields;
