import React from 'react';
import PageTabs from '../../AppLayout/PageTabs';

import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {LOCAL_STORAGE} from "../../constants";
import _ from 'lodash';

const styles = theme => ({
    root: {
        marginTop: theme.spacing(2),
    },
});

const ReviewTabs = ({ classes, isFirmOnElevation }) => {
    const routes = [
        {
            label: 'Invite Reviews',
            link: '/invite-reviews',
        },
        {
            label: "See Who You've Invited",
            link: '/review/previous-invites',
        },
        {
            label: 'My Reviews',
            link: '/reviews',
        },
        {
            label: 'First Impressions',
            link: '/first-impressions',
        },
        {
            label: 'Annual Feedback',
            link: '/review-setting',
        },
    ];
    
    return (
        <div className={classes.root}>
            <PageTabs routes={routes} />
        </div>
    );
};


const mapStateToProps = ({ basicInfo }) => {
    return {
        isFirmOnElevation: _.get(basicInfo, 'permissions.is_firm_on_elevation', false),
    };
};

export default connect(mapStateToProps)(withStyles(styles)(ReviewTabs));
