import React from 'react';
import get from 'lodash/get';
import { Typography } from '@material-ui/core';
export const PrefixTextField = ({ source, record, prefix, ...props }) => {
    const value = get(record, source);

    return <Typography component="span">{value && prefix + value}</Typography>;
};

export const PostfixTextField = ({ source, record, postfix, ...props }) => {
    const value = get(record, source);

    return <Typography component="span">{value && value + postfix}</Typography>;
};

export const YesNoTextField = ({ source, record, ...props }) => {
    const value = get(record, source);

    return (
        <Typography component="span">
            {value === true ? 'Yes' : 'No'}
        </Typography>
    );
};

export const UnderscoreConvertTextField = ({ source, record, ...props }) => {
    let value = get(record, source);

    if (!value) return null;

    value = value.replace('_', ' ');

    return (
        <Typography className={props.className} component="span">
            {value}
        </Typography>
    );
};
