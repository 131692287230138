import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import EditButton from '../Components/Buttons/EditButton';
import AddButton from '../Components/Buttons/AddButton';
import { FormSectionTitle } from '../Components/Typography/Headlines';

import Typography from '@material-ui/core/Typography';

const FEES_COUNT_LIMIT = 3;

const FeesListActions = ({ isLoggedIn, hasFeesAdded, feesAddedCount }) => {
    if (!isLoggedIn) return null;

    return (
        <>
            {hasFeesAdded && (
                <>
                    <FormSectionTitle title="Notes on your fees" />
                    <Typography>
                        You can add notes to explain more about how your fees
                        work by clicking the EDIT button below.
                        <br />
                        If your fees don't fit the models we have available, you
                        can enter notes detailing how they work.
                    </Typography>
                    <EditButton
                        color="secondary"
                        href="/fees-edit"
                        label="Edit fee notes and types"
                    />
                </>
            )}
            {feesAddedCount < FEES_COUNT_LIMIT && (
                <AddButton
                    resource="fees"
                    label={
                        hasFeesAdded && feesAddedCount < FEES_COUNT_LIMIT
                            ? 'Add another fee'
                            : 'Add fees'
                    }
                    to="/fees/create"
                />
            )}
        </>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        isLoggedIn: _.get(basicInfo, 'is_logged_in', false),
    };
};

export default connect(mapStateToProps)(FeesListActions);
