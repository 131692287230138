import React from 'react';
import StatisticColumnBase from './StatisticColumnBase';
import StatisticContent from './StatisticContent';
import StatisticButton from './StatisticButton';
import { formatPlural } from '../../../utils';

const StatisticColumnReferrers = ({ data, loading, handleClick }) => {
    if (!data) return null;

    return (
        <StatisticColumnBase
            score={data.value ? data.value : '?'}
            name={formatPlural(data.value, 'Referrer', 'Referrers')}
            loading={loading}
        >
            <StatisticContent dangerouslySetInnerHTML={{ __html: data.content }} />
            {data.value && <StatisticButton onClick={handleClick} />}
        </StatisticColumnBase>
    );
};

export default StatisticColumnReferrers;
