import React from 'react';
import { connect } from 'react-redux';
import { Edit, SimpleForm, required, TopToolbar } from 'react-admin';
import BackButton from '../../Components/Buttons/BackButton';
import { FormSectionTitle } from '../../Components/Typography/Headlines';
import FormLongTextInput from '../../Components/Inputs/Form/FormLongTextInput';
import EmailSubject from '../../Components/Typography/EmailSubject';
import API_KEYS from '../../constants/api-keys';

const ReviewSettingTemplateEdit = ({
    staticContext,
    professionalName,
    ...props
}) => {
    const redirectUrl = `/review-setting`;
    const adviserUrlWithEnter = `${API_KEYS.consumer_url}/${props.permalink?.url}/write-review\n`;
    const adviserUrlWithSpace = `${API_KEYS.consumer_url}/${props.permalink?.url}/write-review `;

    const validateTemplate = (value) => {
        if (value.includes(adviserUrlWithEnter) || value.includes(adviserUrlWithSpace)) {
                return null;
        }
            return `Adviser's url is wrong or missing, please use ${adviserUrlWithSpace}`;
    };

    return (
        <Edit
            id="no-id"
            resource="review-setting/template"
            basePath="/review-setting/template"
            actions={<TopToolbar><BackButton href={redirectUrl} /></TopToolbar>}
            {...props}
            undoable={false}
        >
            <SimpleForm redirect={redirectUrl}>
                <>
                    <FormSectionTitle title="Edit Annual Invite Message" />
                    <EmailSubject content={`${professionalName} has requested that you leave them a follow-up review on VouchedFor`} />
                </>
                <FormLongTextInput
                    validate={[required(), validateTemplate]}
                    label="Dear <firstname> This will be automatically personalised for each client"
                    source="email_template"
                />
            </SimpleForm>
        </Edit>
    );
};


const mapStateToProps = state => {
    return {
        professionalName: state.basicInfo.first_name,
        permalink: state.basicInfo.permalink,
    };
};

export default connect(
    mapStateToProps,
    {}
)(ReviewSettingTemplateEdit);
