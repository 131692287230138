import React, { Component } from 'react';
import _ from 'lodash';
import dataProvider from '../dataProvider';
import { connect } from 'react-redux';
import { CONTACT } from '../constants';

import Grid from '@material-ui/core/Grid';
import Button from '../Components/Buttons/CustomButton';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    root: {
        padding: '40px 0',
        maxWidth: '470px',
        margin: '0 auto',
        textAlign: 'center',
        '& h3': {
            marginTop: 0,
            fontWeight: 700,
        },
        '& p': {
            marginBottom: '37px',
        },
        '& button': {
            minWidth: '288px',
        },
    },
});

class VerifyEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validToken: null,
            loading: true,
        };
    }

    componentDidMount() {
        const token = _.get(this.props, ['match', 'params', 'token']);
        const professional = _.get(this.props, ['match', 'params', 'professional']);

        dataProvider('GET_ONE', `professionals/verify/email/${token}/${professional}`)
            .then(res => {
                this.setState({
                    validToken: true,
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    validToken: false,
                    loading: false,
                });
            });
    }

    render() {
        const { loading, validToken } = this.state;
        const { classes, isLoggedIn } = this.props;

        if (loading) return null;

        return (
            <Grid container justify="center" className={classes.root}>
                {validToken && (
                    <Typography variant="subtitle1">Your email has been verified</Typography>
                )}
                <Typography variant="body1">
                    {validToken
                        ? 'Thank you for verifying your email. Click below to login!'
                        : `Sorry, it looks like this link has already been used before. Please click below to login to your account, or contact our team on ${CONTACT.PHONE_NUMBER} for help.`}
                </Typography>
                <Button color="tertiary" variant="contained" href={isLoggedIn ? '/' : '/login'}>
                    {isLoggedIn ? 'Return to Dashboard' : 'Log In'}
                </Button>
            </Grid>
        );
    }
}

const mapStateToProps = ({ basicInfo }) => {
    return {
        isLoggedIn: _.get(basicInfo, 'is_logged_in', false),
    };
};

export default connect(mapStateToProps)(withStyles(styles)(VerifyEmail));
