import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import { ExternalLink } from '../../Components/Links';
import { FormSectionTitle } from '../../Components/Typography/Headlines';
import { SUPPORT_URL } from '../../constants';

export const ReviewFAQs = () => (
    <>
        <br />
        <Card>
            <CardContent>
                <FormSectionTitle title="FAQs" />
                <ul>
                    <li>
                        <ExternalLink
                            href={SUPPORT_URL.REVIEW.WHY_ARE_REVIEWS_IMPORTANT}
                        >
                            Why are reviews so important?
                        </ExternalLink>
                    </li>
                    <li>
                        <ExternalLink
                            href={SUPPORT_URL.REVIEW.HOW_MANY_TO_SEND}
                        >
                            How many review requests should I send?
                        </ExternalLink>
                    </li>
                    <li>
                        <ExternalLink
                            href={SUPPORT_URL.REVIEW.HOW_TO_INVITE_CLIENTS}
                        >
                            How can I invite my clients to review me?
                        </ExternalLink>
                    </li>
                    <li>
                        <ExternalLink
                            href={SUPPORT_URL.REVIEW.WHAT_WILL_CLIENTS_RECEIVE}
                        >
                            What will my clients receive when I send them a
                            review request?
                        </ExternalLink>
                    </li>
                </ul>
            </CardContent>
        </Card>
    </>
);

export default ReviewFAQs;
