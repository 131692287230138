import React from 'react';
import { SelectInput } from "react-admin";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    prospect: {
        minWidth: 200
    },
}));

const FirstImpressionsProspectFilter = ({ ...rest }) => {
    const classes = useStyles();

    return(
            <SelectInput
                {...rest}
                margin="none"
                className={classes.prospect}
                variant="outlined"
            />
    );}

export default FirstImpressionsProspectFilter;
