import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';
import CircularProgressGradient from './CircularProgressGradient';

import withStyles from '@material-ui/core/styles/withStyles';

const propTypes = {
    professionalName: PropTypes.string,
    xs: PropTypes.bool,
    inlineBlock: PropTypes.bool,
    innerText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

const defaultProps = {
    professionalName: 'Profile',
    xs: false,
    inlineBlock: false,
    innerText: null,
};

const styles = theme => ({
    personImage: {
        width: 153,
        height: 153,
        margin: 21,
        borderRadius: '50%',
        position: 'absolute',
        top: 0,
        left: 0,

        '&.xs': {
            width: 50,
            height: 50,
            margin: `10px 16px`,
        },
        '&.md': {
            width: 84,
            height: 84,
            margin: 13,
        },
        '&.innerStroke': {
            border: `4px solid white`,
        },
    },
    avatarWrap: {
        position: 'relative',
        margin: theme.spacing(1),
    },
    circularProgress: {
        width: 182,
        height: 182,
        margin: 6,

        '&.xs': {
            width: 70,
            height: 70,
        },
        '&.md': {
            width: 100,
            height: 100,
        },
    },
    inlineBlock: {
        display: 'inline-block',
    },
    innerTextWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

class ScoreAvatar extends Component {
    render() {
        const {
            classes,
            image,
            ratingValue,
            ratingMax,
            inlineBlock,
            professionalName,
            xs,
            md,
            innerText,
            circularProgressProps,
        } = this.props;
        const xsClass = xs ? 'xs' : '';
        const mdClass = md ? 'md' : '';
        const strokeClass = _.get(circularProgressProps, 'innerStroke', false)
            ? 'innerStroke'
            : '';

        return (
            <div
                className={cn({
                    [classes.avatarWrap]: true,
                    [classes.inlineBlock]: inlineBlock,
                })}
            >
                <CircularProgressGradient
                    rating={ratingValue}
                    maxRating={ratingMax}
                    className={cn(
                        classes.circularProgress,
                        xsClass,
                        mdClass,
                        strokeClass
                    )}
                    {...circularProgressProps}
                />
                {innerText ? (
                    <div
                        className={cn(
                            classes.personImage,
                            xsClass,
                            mdClass,
                            strokeClass,
                            classes.innerTextWrapper
                        )}
                    >
                        {innerText}
                    </div>
                ) : (
                    <img
                        src={image}
                        alt={professionalName}
                        className={cn(classes.personImage, xsClass, mdClass)}
                    />
                )}
            </div>
        );
    }
}

ScoreAvatar.defaultProps = defaultProps;
ScoreAvatar.propTypes = propTypes;

export default withStyles(styles)(ScoreAvatar);
