import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Button from '../../Components/Buttons/CustomButton';
import { PRIVATE_MODE_STATISTICS_TEXT } from '../../constants';

const useStylesCustom = makeStyles(theme => ({
    statsContainer: {
        minHeight: '3em',
        height: 200,
        lineHeight: 1.5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    statistics: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    statisticsText: {
        fontWeight: '600!important',
    },
}));

const PrivateDashboardStats = ({ data }) => {
    const classes = useStylesCustom();

    const { statistics, review_count } = data;

    return (
        <Container
            maxWidth={false}
            component={Box}
            classes={classes.container}
            marginBottom="1rem"
            padding="2rem"
        >
            <Grid container spacing={3}>
                {Object.keys(PRIVATE_MODE_STATISTICS_TEXT).map(key => {
                    return (
                        <Grid key={key} item lg={4} md={6} xs={12}>
                            <Box className={classes.statsContainer}>
                                <Box className={classes.statistics}>
                                    <Box
                                        component={Typography}
                                        variant="h2"
                                        className={classes.statisticsText}
                                    >
                                        {key === 'overall_rating' ? (
                                            statistics[key]
                                        ) : (
                                            <>
                                                {statistics[key]
                                                    ? `${Math.round(
                                                          parseFloat(statistics[key]) * 100
                                                      )}%`
                                                    : '?%'}
                                            </>
                                        )}
                                    </Box>
                                    <Box
                                        component="p"
                                        marginTop="0"
                                        className={classes.statisticsText}
                                    >
                                        {key === 'overall_rating'
                                            ? `${PRIVATE_MODE_STATISTICS_TEXT[key].preText} ${review_count} ${PRIVATE_MODE_STATISTICS_TEXT[key].sucText}`
                                            : PRIVATE_MODE_STATISTICS_TEXT[key].text}
                                    </Box>
                                </Box>
                                <Button
                                    variant="outlined"
                                    color="tertiary"
                                    href='/reviews'
                                    disabled={statistics[key] === null || statistics[key] === '0.0'}
                                >
                                    Read reviews
                                </Button>
                            </Box>
                        </Grid>
                    );
                })}
            </Grid>
        </Container>
    );
};

export default PrivateDashboardStats;
