import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useForm } from 'react-final-form';
import { connect } from 'react-redux';
import { required } from 'react-admin';
import { Typography } from '@material-ui/core';
import { PROFESSIONAL, LOCAL_STORAGE, FINANCIAL_COACH_QUALIFICATIONS } from '../../constants';
import { FormSectionTitle } from '../../Components/Typography/Headlines';
import BasicProfileFormLegalAdviserFields from './BasicProfileFormLegalAdviserFields';
import BasicProfileFormFinancialAdviserFields from './BasicProfileFormFinancialAdviserFields';
import BasicProfileEditFirmFields from './BasicProfileEditFirmFields';
import BasicProfileFormDivider from './BasicProfileFormDivider';
import BasicProfileFormInvestmentFields from './BasicProfileFormInvestmentFields';
import BasicProfileFormAccountantFields from './BasicProfileFormAccountantFields';
import ServicesList from '../../Services/ServicesList';
import AddressFields from '../../Components/Inputs/AddressFields';
import TrueFalseInput from '../../Components/Forms/TrueFalseInput';
import dataProvider from '../../dataProvider';
import FormTextInput from '../../Components/Inputs/Form/FormTextInput';
import FormSelectInput from '../../Components/Inputs/Form/FormSelectInput';

const { BASIC_PROFILE_FORM_VALUES } = LOCAL_STORAGE;
const { FINANCIAL_ADVISER, ACCOUNTANT, LEGAL_ADVISER } = PROFESSIONAL.VERTICAL;

const BasicProfileEditFormContent = props => {
    const { isFinancialAdviser, isAccountant, isLegalAdviser, vertical, ...rest } = props;
    const [checkedFirmFallsUnderSMCR, setCheckedFirmFallsUnderSMCR] = useState(false);
    const [firmChoices, setFirmChoices] = useState([]);
    const form = useForm();

    const saveFormValues = () => {
        sessionStorage.setItem(BASIC_PROFILE_FORM_VALUES, JSON.stringify(form.getState().values));
    };

    useEffect(() => {
        const lsCheckedFirmFallsUnderSMCR = JSON.parse(
            sessionStorage.getItem('checked_firm_falls_under_smcr')
        );
        const lsFirmChoices = JSON.parse(sessionStorage.getItem('firm_choices'));

        if (lsCheckedFirmFallsUnderSMCR) {
            setCheckedFirmFallsUnderSMCR(Boolean(lsCheckedFirmFallsUnderSMCR));
        }

        if (lsFirmChoices) {
            setFirmChoices(lsFirmChoices);
        }

        return () => {
            saveFormValues();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFirmFallsUnderSMCRCheckboxChange = () => {
        const nextValue = !checkedFirmFallsUnderSMCR;

        setCheckedFirmFallsUnderSMCR(nextValue);

        form.change('no_personal_fca_id', nextValue);

        form.change('attributes.financial_adviser_personal_fca.value', null);

        sessionStorage.setItem('checked_firm_falls_under_smcr', nextValue);
    };

    const lookupFirmName = () => {
        const formValues = form.getState().values;
        const fcaNumber = _.get(formValues, 'attributes.financial_adviser_firm_fca.value');

        if (!fcaNumber) return;

        dataProvider('GET_LIST', 'firm/firm-fca', { filter: { fcaNumber } }).then(result => {
            const id = _.get(result, ['data', 0, 'id'], '');
            const firmChoices = result.data;

            form.change('firm.id', id);
            form.change('firm.name', null);

            setFirmChoices(firmChoices);

            sessionStorage.setItem('firm_choices', JSON.stringify(firmChoices));
        });
    };

    const manuallyAddFirmName = () => {
        setFirmChoices([]);

        form.change('firm.id', null);
        form.change('firm.name', null);

        sessionStorage.removeItem('firm_choices');
    };

    return (
        <>
            <FormSectionTitle title="Finish setting up your account" />
            <>
                <Typography gutterBottom>
                    We just need a few more details to get your account set up
                </Typography>
            </>
            <FormTextInput
                source="phone_direct"
                label="Personal phone number"
                defaultValue=""
                validate={[required()]}
            />
            {props.isCoach && (
                 <FormSelectInput
                 validate={[required()]}
                 label="Who are you trained or accredited by"
                 source="accredited_by"
                 defaultValue={sessionStorage.getItem(BASIC_PROFILE_FORM_VALUES) ? JSON.parse(sessionStorage.getItem(BASIC_PROFILE_FORM_VALUES)).accredited_by : "" }
                 choices={FINANCIAL_COACH_QUALIFICATIONS}
             />
            )}
            {isLegalAdviser && <BasicProfileFormLegalAdviserFields />}
            {isFinancialAdviser && (
                <BasicProfileFormFinancialAdviserFields
                    handleFirmFallsUnderSMCRCheckboxChange={handleFirmFallsUnderSMCRCheckboxChange}
                    checkedFirmFallsUnderSMCR={checkedFirmFallsUnderSMCR}
                    lookupFirmName={lookupFirmName}
                />
            )}
            {isAccountant && <BasicProfileFormAccountantFields />}
            <BasicProfileEditFirmFields
                firmChoices={firmChoices}
                manuallyAddFirmName={manuallyAddFirmName}
            />
            <BasicProfileFormDivider />
            <AddressFields />
            <BasicProfileFormDivider />
            <ServicesList {...rest} />
            {isFinancialAdviser && (
                <>
                    <br />
                    <BasicProfileFormInvestmentFields />
                </>
            )}
            <BasicProfileFormDivider />
            <FormSectionTitle title="Annual feedback" />
            <Typography>
                When I am inviting clients to review my services I would like them to automatically
                receive a follow up email in a year's time to provide a follow up review
            </Typography>
            <TrueFalseInput
                label="Turn on annual repeat-review invitations for everyone"
                source="auto_invite"
                defaultValue={true}
            />
        </>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    const vertical = _.get(basicInfo, 'permissions.vertical');
    const isCoach = _.get(basicInfo, 'permissions.coach_mode');

    return {
        isFinancialAdviser: vertical === FINANCIAL_ADVISER,
        isLegalAdviser: vertical === LEGAL_ADVISER,
        isAccountant: vertical === ACCOUNTANT,
        vertical,
        isCoach
    };
};

export default connect(mapStateToProps)(BasicProfileEditFormContent);
