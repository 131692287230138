import React from 'react';
import PropTypes from 'prop-types';
import Footer from './Footer';

import Card from '@material-ui/core/Card';
import withStyles from '@material-ui/core/styles/withStyles';

const propTypes = {
    type: PropTypes.oneOf(['card', 'default']),
};

const styles = theme => ({
    card: {
        maxWidth: 'fit-content',
        margin: `${theme.spacing(5)}px auto`,
        width: `calc(100% - ${theme.spacing(10)}px)`,

        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(2),
        },
    },
});

const PageContent = ({ type = 'default', children, classes }) => {
    if (type === 'card') {
        return (
            <React.Fragment>
                <Card className={classes.card}>{children}</Card>
                <Footer />
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {children}
            <Footer />
        </React.Fragment>
    );
};

PageContent.propTypes = propTypes;

export default withStyles(styles)(PageContent);
