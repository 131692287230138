import React from 'react';
import { Typography } from '@material-ui/core';
import Button from '../../Components/Buttons/CustomButton';

const MissingPensionQualificationErrorMessage = ({ message = '', ...rest }) => {
    if (!message) {
        return null;
    }

    return (
        <div {...rest}>
            <Typography color="error">
                {message}
            </Typography>
            <Button
                href="/qualifications/create"
                variant="contained"
                color="tertiary"
            >
                Add pension transfer qualification
            </Button>
        </div>
    );
};

export default MissingPensionQualificationErrorMessage;
