import React from 'react';

import CheckIcon from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        height: 52,
    },
    text: {
        marginLeft: theme.spacing(1),
        textAlign: 'left',
    },
});

const ReputationDoneAction = ({ label, classes }) => {
    return (
        <div className={classes.root} key={label}>
            <CheckIcon />
            <Typography className={classes.text}>{label}</Typography>
        </div>
    );
};

export default withStyles(styles)(ReputationDoneAction);
