import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    item: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    card: {
        width: '100%',
        padding: theme.spacing(3),
        textAlign: 'center',
        transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2.5),
        },
    },
    actionWrapper: {
        marginTop: theme.spacing(3),
        textAlign: 'center',
        '& button': {
            marginBottom: theme.spacing(1),
        }
    },
    overlay: {
        opacity: '0.31',
        pointerEvents: 'none',
    },
    imgWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '-24px -24px 24px',
        minHeight: theme.spacing(23),
        [theme.breakpoints.down('sm')]: {
            position: 'inherit',
        },
    },
}));

const ReputationToolItem = ( { cardDisabled=false ,content, actions = null, img = null, CardProps = {} }) => {
    const classes = useStyles();
    return (
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4} className={classes.item}>
            <Card className={classes.card} {...CardProps} variant="outlined" elevation={0}>
                <div className={cardDisabled ? classes.overlay : null}>
                {img && (
                    <div className={classes.imgWrapper}>
                        <img src={img} alt="" />
                    </div>
                )}
                <div>{content}</div>
                {actions && <div className={classes.actionWrapper}>{actions}</div>}
                </div>
            </Card>
        </Grid>
    );
};

export default ReputationToolItem;
