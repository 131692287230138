import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import dataProvider from '../../dataProvider';
import {
    fetchEnd,
    fetchStart,
    refreshView as refreshViewAction,
    showNotification,
} from 'react-admin';

import Button from '../Buttons/CustomButton';
import withStyles from '@material-ui/core/styles/withStyles';

const propTypes = {
    record: PropTypes.object.isRequired,
    resource: PropTypes.string.isRequired,
    onRequestEnd: PropTypes.func,
    label: PropTypes.string,
    type: PropTypes.string,
};

const styles = theme => ({
    error: {
        color: theme.palette.error.main,

        '&:hover': {
            backgroundColor: 'rgba(211, 91, 131, 0.08)',
        },
    },
});

class ActionButtonBase extends Component {
    handleCustomSubmit = e => {
        const { record } = this.props;
        const id = _.get(record, 'id', null);

        if (id) {
            record.id = id;
        }

        this.handleSubmit(record);
    };

    handleSubmit = record => {
        const {
            fetchStart,
            fetchEnd,
            showNotification,
            refreshView,
            type,
            resource,
            onRequestEnd,
        } = this.props;

        fetchStart();

        dataProvider(type, resource, { id: record.id, data: record })
            .then(() => refreshView())
            .catch(error => showNotification(error.message, 'warning'))
            .finally(() => {
                fetchEnd();
                onRequestEnd();
            });
    };

    getButtonProps = () => {
        const { type, classes } = this.props;

        if (type === 'DELETE') {
            return { className: classes.error };
        }

        return { variant: 'contained', color: 'tertiary' };
    };

    render() {
        const buttonProps = this.getButtonProps();

        return (
            <Button onClick={this.handleCustomSubmit} {...buttonProps}>
                {this.props.label}
            </Button>
        );
    }
}

const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification,
    refreshView: refreshViewAction,
};

const ActionButtonWithConnect = connect(
    null,
    mapDispatchToProps
)(withStyles(styles)(ActionButtonBase));

const ActionButton = ({
    record,
    resource,
    label = 'Update',
    type = 'UPDATE',
    onRequestEnd = () => {},
}) => {
    return (
        <ActionButtonWithConnect
            record={record}
            resource={resource}
            label={label}
            type={type}
            onRequestEnd={onRequestEnd}
        />
    );
};

ActionButton.propTypes = propTypes;

export default ActionButton;
