import React from 'react';
import {
    AutocompleteInput,
    Create,
    FileField,
    required,
    SimpleForm,
    FileInput,
    TopToolbar,
} from 'react-admin';
import _ from 'lodash';
import { connect } from 'react-redux';
import { futureDateValidation, pastDateValidation } from '../validation';
import { FormSectionTitle } from '../Components/Typography/Headlines';
import { fileSizeValidation } from '../validation';
import BackButton from '../Components/Buttons/BackButton';
import { MAX_FILE_UPLOAD_SIZE_TWO_MB, VERTICAL, VERTICAL_ID } from '../constants';
import FormReferenceInput from '../Components/Inputs/Form/FormReferenceField';
import FormDateInput from '../Components/Inputs/Form/FormDateInput';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    autocomplete: {
        '& .MuiOutlinedInput-input': {
            textTransform: 'capitalize',
        },
    },
}));

const QualificationsCreate = props => {
    const { dispatch, professionalId, qualificationsUrl, type, ...rest } = props;
    const classes = useStyles();
    const datePassedValidation =
        type && type === VERTICAL.FINANCIAL_COACH
            ? [pastDateValidation, required('Required')]
            : [pastDateValidation];

    return (
        <Create
            title={null}
            {...rest}
            actions={
                <TopToolbar>
                    <BackButton href={qualificationsUrl} />
                </TopToolbar>
            }>
            <SimpleForm redirect={qualificationsUrl}>
                <FormSectionTitle title="Add Qualification" />

                <FormReferenceInput
                    className={classes.autocomplete}
                    label="Qualifications"
                    source="qualification"
                    validate={[required()]}
                    reference="certificates"
                    filter={
                        VERTICAL_ID[_.get(rest, 'permissions.vertical')]
                            ? {
                                  vertical_id: VERTICAL_ID[_.get(rest, 'permissions.vertical')],
                              }
                            : null
                    }
                    filterToQuery={searchText => ({ name: searchText })}
                    perPage={1000}>
                    <AutocompleteInput
                        optionText="name"
                        options={{
                            /**
                             * @todo
                             * investigate why the popper requires an offset
                             * to be placed under the input
                             */
                            suggestionsContainerProps: {
                                modifiers: {
                                    offset: {
                                        offset: '280px',
                                    },
                                },
                                style: {
                                    textTransform: 'capitalize',
                                },
                            },
                        }}
                    />
                </FormReferenceInput>
                <FileInput
                    source="file"
                    label="Upload Certificate (max 2mb)"
                    validate={[required(), fileSizeValidation(MAX_FILE_UPLOAD_SIZE_TWO_MB)]}>
                    <FileField source="src" title="title" target="_blank" />
                </FileInput>
                <FormDateInput
                    label="Expiry date"
                    validate={[futureDateValidation]}
                    source="expiry"
                />
                <FormDateInput
                    label="Date passed"
                    validate={datePassedValidation}
                    source="date_passed"
                />
            </SimpleForm>
        </Create>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        qualificationsUrl: `/professional/qualifications/${basicInfo.id}`,
        type: basicInfo.type
    };
};

export default connect(mapStateToProps)(QualificationsCreate);
