import _ from 'lodash';
import { LOCAL_STORAGE, API_KEYS } from '../constants';
import { IMAGE_PREVIEW_SIZE } from '../Components/Inputs/Components/ImagePreviewCrop';

const { UNAUTHENTICATED_LEXIKTOKEN, LEXIKTOKEN } = LOCAL_STORAGE;

export function getAPIUrl(resource = '') {
    const resourceString = String(resource);

    if (
        [
            'jobs',
            'meeting-locations',
            'fees',
            'fees-edit',
            'fees/choices',
            'fee-charges',
            'mortgage-fee-levels',
            'investment-fee-levels',
        ].includes(resourceString) ||
        resourceString.includes('fees-insights')
    ) {
        return API_KEYS.professional;
    }

    if (resourceString === 'resetting/send-email') {
        return `${API_KEYS.public_api_url}/register`;
    }

    if (
        resourceString === 'register/fees' ||
        resourceString === 'professionals/resend-verification-email' ||
        resourceString.includes('enquiry/view') ||
        resourceString.includes('verify/email') ||
        resourceString.includes('unauthenticated-token')
    ) {
        return API_KEYS.public_api_url;
    }
    
    if (resourceString === 'adviser-dashboard') {
        return API_KEYS.roma_url;
    }

    if(resourceString.startsWith('elevation')) {
        return API_KEYS.elevation_url;
    }

    return API_KEYS.api_url;
}

export function getLexikToken() {
    const lexikToken = localStorage.getItem(LEXIKTOKEN);
    const unauthenticatedLexiktoken = localStorage.getItem(UNAUTHENTICATED_LEXIKTOKEN);

    if (lexikToken) {
        return lexikToken;
    }

    if (unauthenticatedLexiktoken) {
        return unauthenticatedLexiktoken;
    }

    return null;
}

export const convertFileToBase64 = (file, resource) => {
    return new Promise((resolve, reject) => {
        if (file.rawFile.type === 'image/jpeg') {
            const image = new Image();

            image.onload = () => {
                let drawImageParams = [];

                // convert jpg to png and return base64 encoded string
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                if (['firm', 'professional'].includes(resource)) {
                    canvas.width = IMAGE_PREVIEW_SIZE;
                    canvas.height = IMAGE_PREVIEW_SIZE;

                    drawImageParams = [
                        image,
                        0,
                        0,
                        image.width,
                        image.height,
                        0,
                        0,
                        IMAGE_PREVIEW_SIZE,
                        IMAGE_PREVIEW_SIZE,
                    ];
                } else {
                    canvas.width = image.width;
                    canvas.height = image.height;

                    drawImageParams = [image, 0, 0, image.width, image.height];
                }

                ctx.drawImage(...drawImageParams);

                resolve(canvas.toDataURL('image/png'));
            };

            image.src = file.src;
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file.rawFile);

            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
        }
    });
};

export function createFileParamsForUpload(params, resource) {
    const paramsCopy = _.cloneDeep(params);

    if (!_.has(paramsCopy, 'data.file')) {
        return paramsCopy;
    }

    if (!['firm', 'professional'].includes(resource)) {
        return paramsCopy;
    }

    paramsCopy.data.file.title = _.get(paramsCopy, 'data.file.rawFile.name');
    paramsCopy.data.file.rawFile = {
        preview: _.get(paramsCopy, 'data.file.rawFile.preview'),
    };

    return paramsCopy;
}
