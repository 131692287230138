import React from 'react';
import StatisticColumnBase from './StatisticColumnBase';
import StatisticContent from './StatisticContent';
import StatisticButton from './StatisticButton';
import { formatPlural } from '../../../utils';

const StatisticColumnInfluenced = ({ data, loading, handleClick }) => {
    if (!data) return null;

    return (
        <StatisticColumnBase
            score={data.value ? data.value : '?'}
            name={formatPlural(data.value, 'Influence', 'Influenced')}
            loading={loading}
            tooltipTitle="
                This is the number of reviewers who said they read your reviews before
                becoming a client,and said they found them helpful in deciding to work with you.
            "
        >
            <StatisticContent dangerouslySetInnerHTML={{ __html: data.content }} />
            <StatisticButton onClick={handleClick} />
        </StatisticColumnBase>
    );
};

export default StatisticColumnInfluenced;
