import React from 'react';
import Button from '../Buttons/CustomButton';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';

const AddButton = ({ to, variant = 'text', label }) => {
    return (
        <Button
            component={Link}
            color="tertiary"
            label={label}
            variant={variant}
            to={to}
        >
            {variant === 'text' && <AddIcon />}
            {label}
        </Button>
    );
};

export default AddButton;
