import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';

const propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
};

const defaultProps = {
    color: 'secondary',
    size: 100,
};

const styles = theme => ({
    root: {
        textAlign: 'center',
    },
});

const LoadingSpinner = ({ classes, className, style, size, color }) => {
    return (
        <div className={cn(classes.root, className)} style={style}>
            <CircularProgress size={size} color={color} />
        </div>
    );
};

LoadingSpinner.defaultProps = defaultProps;
LoadingSpinner.propsTypes = propTypes;

export default withStyles(styles)(LoadingSpinner);
