import React, { useState } from 'react';
import { connect } from 'react-redux';
import dataProvider from '../../dataProvider';
import { TopToolbar, SimpleForm, showNotification as showNotificationAction } from 'react-admin';
import FirstImpressionsQuestions from './FirstImpressionsQuestions';
import { EditPageTitle } from '../../Components/Typography/Headlines';
import PreventUndoEdit from '../../Components/PreventUndoEdit';
import BackButton from '../../Components/Buttons/BackButton';
import Button from '@material-ui/core/Button';
import { formatSimpleListDate } from '../../utils';
import ReviewReport from '../Report/ReviewReport';
import { ReviewDescription, ReviewsNote, ReviewTitle } from '../../Review/ReviewEdit';
import { ReportButton } from '../Content/ReviewEditActions';
import ReviewReply from '../Content/ReviewReply';
import { makeStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
    reviewWrapper: {
        display: 'flex',
    },
    replyButton: {
        marginBottom: '1rem',
        marginLeft: '1rem',
    }
}));

const EditActions = ({ basePath, data, resource, href, ...props }) => {
    const hasReported = (data && data.blame) || props.reported;

    return (
        <TopToolbar>
            <BackButton href={href} />
            {hasReported && <Button>Reported</Button>}
        </TopToolbar>
    );
};

const FirstImpressionQuestionContent = ({ record, showPrivate, showPublic }) => {
    return <FirstImpressionsQuestions record={record} showPrivate={showPrivate} showPublic={showPublic} questions={record.questions} />;
};

const FirstImpressionsDetails = ({ record }) => {
    const { client_first_name, client_last_name, client_email, submitted_at } = record;
    const name = client_last_name
        ? `${client_first_name} ${client_last_name}`
        : `${client_first_name}`;

    const date = formatSimpleListDate(submitted_at);
    const details = `${name} (${client_email}) on ${date}`
    return (
        <>
            <EditPageTitle
                titleText={'First Impression by ' + `${details}`}
                color={'textPrimary'}
                variant={'h4'}
            />
            <br/>
        </>
    );
}

const FirstImpressionsEdit = (props) => {
    const [reported, setReported] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [showReply, setShowReply] = useState(false);
    const classes = useStyles();
    const { showNotification, ...rest } = props;

    const reportFirstImpression = (firstImpressionId, formData) => {
        const { showNotification } = props;
        const params = { data: formData };
        dataProvider('UPDATE', `first-impressions/${firstImpressionId}/report`, params).then(
            res => {
                showNotification('An issue has been reported. Our team will be in touch!');

                setReported(true)
            }
        );
    };


    const handleDialogClose = () => {
        setShowDialog(false)
    }

    const handleDialogOpen = () => {
        setShowDialog(true)
    }

    const deleteReply = firstImpressionId => {
        const params = { data: {} };

        dataProvider('DELETE', `first-impressions/${firstImpressionId}/respond`, params).then(res =>
            window.location.replace(window.location.href)
        );
    };


    const handleReplyDialogClose = () => {
        setShowReply(false)
    };

    const handleReplyDialogOpen = () => {
        setShowReply(true)
    };


    const ReplyButton = ({record}) => {
        return <Button className={classes.replyButton} color="primary" variant="contained" onClick={handleReplyDialogOpen}>{record?.professional_response ? 'Edit/Delete Reply' : 'Reply'}</Button>
    }

    const ActionButtons = (props) => {
        return (
            <div className={classes.buttonWrapper}>                
                <ReportButton reportHandler={handleDialogOpen}  
                    label="Report"
                    reported={reported}
                    href="/first-impressions"/> 

                <ReplyButton {...props} handleReplyDialogOpen={handleReplyDialogOpen} />
            </div>    
        )
    }


    return (
        <PreventUndoEdit
            {...rest}
            actions={
                <EditActions
                    reported={reported}
                    href="/first-impressions"
                />
            }
        >
            <SimpleForm toolbar={null} redirect={false}>
                <FirstImpressionsDetails />
                <ReviewTitle />
                <ReviewDescription />
                <FirstImpressionQuestionContent showPrivate={false} showPublic={true} />
                <ActionButtons />
                <Divider style={{width:"auto"}}/>
                <FirstImpressionQuestionContent showPrivate={true} showPublic={false} />
                <br/>
                { showReply && <ReviewReply 
                        showDialog={showReply}
                        handleClose={handleReplyDialogClose}
                        deleteReply={deleteReply}
                        source="reply"
                        title="Reply to first impression"
                    />
                }
                {showDialog && <ReviewReport
                    showDialog={showDialog}
                    handleClose={handleDialogClose}
                    handleSend={reportFirstImpression}
                    reportType="firstImpression"
                    reportedSuccess={reported}
                />}
            </SimpleForm>
        </PreventUndoEdit>
    );
}

export default connect(
    null,
    {
        showNotification: showNotificationAction,
    }
)(FirstImpressionsEdit);
