import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import InvestmentFeesDataGrid from './InvestmentFeesDataGrid';
import AddFeeLevelButton from '../Forms/AddFeeLevelButton';
import FeesDeleteButton from '../Forms/FeesDeleteButton';
import InputPrefix from '../../Components/Inputs/InputPrefix';
import { FormSectionTitle } from '../../Components/Typography/Headlines';
import { FEE_SUB_TYPE } from '../../constants';

import Typography from '@material-ui/core/Typography';
import FormNumberInput from '../../Components/Inputs/Form/FormNumberInput';

const { HOURLY_CHARGE } = FEE_SUB_TYPE;

const FinancialPlanningFeesLevels = ({ isLoggedIn, ...props }) => {
    const feeType = 'Financial Planning';
    const investmentChargeLevels = _.get(
        props,
        ['record', 'content', 'financialPlanning', 'chargeLevels'],
        []
    );
    const investmentFeeSubType = _.get(
        props,
        ['record', 'content', 'financialPlanning', 'subType'],
        ''
    );
    const financialPlanningFeeId = _.get(props, ['record', 'financialPlanning', 'id'], null);

    return (
        <React.Fragment>
            <br />
            <FormSectionTitle title={`${feeType} Fees`} />
            {investmentFeeSubType === HOURLY_CHARGE ? (
                <FormNumberInput
                    disabled
                    source="content.financialPlanning.amount"
                    label="Your average hourly fee amount"
                    InputProps={{ startAdornment: <InputPrefix label="£" /> }}
                />
            ) : (
                <React.Fragment>
                    {_.isEmpty(investmentChargeLevels) ? (
                        <React.Fragment>
                            <Typography variant="body1">
                                You haven't added any financial planning fee levels
                            </Typography>
                        </React.Fragment>
                    ) : (
                        <InvestmentFeesDataGrid
                            feeId={financialPlanningFeeId}
                            feeType={feeType}
                            subType={investmentFeeSubType}
                            isLoggedIn={isLoggedIn}
                        />
                    )}
                    <AddFeeLevelButton
                        variant={
                            !_.isEmpty(investmentChargeLevels) && !isLoggedIn ? 'text' : 'contained'
                        }
                        to={{
                            pathname: isLoggedIn
                                ? '/investment-fee-levels/create'
                                : '/register',
                            state: {
                                feeType,
                                investmentFeeSubType,
                                feeId: financialPlanningFeeId,
                            },
                        }}
                    />
                </React.Fragment>
            )}
            {isLoggedIn && (
                <FeesDeleteButton
                    record={props.record}
                    feeTypeToDelete="Implementation & Ongoing Service"
                />
            )}
        </React.Fragment>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        isLoggedIn: _.get(basicInfo, 'is_logged_in', false),
    };
};

export default connect(mapStateToProps)(FinancialPlanningFeesLevels);
