import React, { useState } from 'react';
import cn from 'classnames';
import { useQuery } from 'react-admin';
import CopyTextButton from '../../Components/Buttons/CopyTextButton';
import ReputationToolItem from './Components/ReputationToolItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import * as analytics from '../../analytics';

const useStyles = makeStyles(theme => ({
    content: {
        textAlign: 'center',
        borderRadius: theme.spacing(2),
    },
    widgetWrapper: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        minHeight: 150,
    },
    widget: {
        position: 'absolute',
        transition: 'opacity 0.2s ease',
    },
    themeToggle: {
        marginBottom: theme.spacing(2),
        marginRight: 0,
        display: 'block',
        textAlign: 'right',
        color: theme.palette.primary.textPrimary,
    },
    themeToggleWhite: {
        color: theme.palette.primary.contrastText,
    },
    actions: {
        marginTop: theme.spacing(2),
    },
}));

const WidgetStars = ({ type, resource, buttonName, cardDisabled }) => {
    const classes = useStyles();
    const [variant, setVariant] = useState('dark');
    const { data } = useQuery({
        type: 'getOne',
        resource,
        payload: {
            filter: {
                category: type,
            },
        },
    });

    const handleCopyCode = () => {
        if (!data) {
            return;
        }

        const [widgetType, widgetCategory]= resource.includes("firm") ? ['firm', 'Firm' ]: ['professional', 'Professional'];

        const actionLookup = {
            panel: {
                dark: `Copied ${widgetType} stars widget code (panel dark)`,
                light: `Copied ${widgetType} stars widget code (panel light)`,
            },
            centre: {
                dark: `Copied ${widgetType} stars widget code (centre-aligned dark)`,
                light: `Copied ${widgetType} stars widget code (centre-aligned light)`,
            },
            left: {
                dark: `Copied ${widgetType} stars widget code (left-aligned dark)`,
                light: `Copied ${widgetType} stars widget code (left-aligned light)`,
            },
        };
        const action = actionLookup[type][variant];

        analytics.track(action, {
            category: `${widgetCategory} reputation tools`,
        });
    };

    if (!data) {
        return null;
    }

    return (
        <ReputationToolItem
            cardDisabled={cardDisabled}
            CardProps={{
                style: {
                    background: variant === 'dark' ? '#fff' : '#282828',
                },
            }}
            content={
                <div className={classes.content}>
                    <FormControlLabel
                        className={cn({
                            [classes.themeToggle]: true,
                            [classes.themeToggleWhite]: variant === 'light',
                        })}
                        label={variant === 'dark' ? "Switch to light colours" : "Switch to dark colours"}
                        control={
                            <Switch
                                checked={variant === 'light'}
                                onChange={() => setVariant(variant === 'light' ? 'dark' : 'light')}
                                name="variant-toggle"
                                color="secondary"
                            />
                        }
                    />
                    <div className={classes.widgetWrapper}>
                        <div
                            dangerouslySetInnerHTML={{ __html: data.light.html }}
                            className={classes.widget}
                            style={{
                                visibility: variant === 'light' ? 'visible' : 'hidden',
                                opacity: variant === 'light' ? 1 : 0,
                            }}
                        />
                        <div
                            dangerouslySetInnerHTML={{ __html: data.dark.html }}
                            className={classes.widget}
                            style={{
                                visibility: variant === 'dark' ? 'visible' : 'hidden',
                                opacity: variant === 'dark' ? 1 : 0,
                            }}
                        />
                    </div>
                    <div className={classes.actions}>
                        <CopyTextButton
                            clipboardContent={data[variant].code}
                            onClick={handleCopyCode}
                            style={{ margin: 4 }}
                            variant="contained"
                        >
                            {buttonName}
                        </CopyTextButton>
                    </div>
                </div>
            }
        />
    );
};

export default WidgetStars;
