import _ from 'lodash';
import { SET_FORM_ERROR } from '../types';

const INITIAL_STATE = {
    error: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_FORM_ERROR:
            return { ...state, error: _.get(action, 'payload', null) };
        default:
            return state;
    }
};
