import React from 'react';
import StatisticColumnBase from './StatisticColumnBase';
import StatisticContent from './StatisticContent';
import { formatPlural } from '../../../utils';

const StatisticColumnIndirectEnquirers = ({ data, loading }) => {
    if (!data) return null;

    return (
        <StatisticColumnBase
            score={data.value ? data.value : '?'}
            name={formatPlural(data.value, 'Potential Enquirer', 'Potential Enquirers')}
            loading={loading}
            tooltipTitle="
                    This represents the number of visitors to your VouchedFor profile who spent
                    more than 2 minutes looking at your profile before leaving the
                    VouchedFor site to go to another website, which will often be your own website.
                "
        >
            <StatisticContent>{data.content}</StatisticContent>
        </StatisticColumnBase>
    );
};

export default StatisticColumnIndirectEnquirers;
