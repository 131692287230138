import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
    showNotification as showNotificationAction,
    refreshView as refreshViewAction,
} from 'react-admin';
import ReviewMutationButton from './ReviewMutationButton';
import { EditViewButtons, DisabledEditViewButtons } from '../../Components/Buttons/Buttons';
import { showViewEditButton, onWorkflowStart, STATE } from '../../constants';
import {
    Button, DialogActions, DialogTitle,
    Dialog
} from '@material-ui/core';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    dialog: {
        '& .MuiDialog-paperWidthSm': {
            width: "auto"
        }
    },
    actions: {
        display: "flex",
        justifyContent: "space-between"
    },
    notAClientButton: {
        color: "#d35b83",
        '&:hover': {
            backgroundColor: 'rgba(211, 91, 131, 0.08)',
        },
    },
    dialogTitle: {
        padding: `0 0 ${theme.spacing(1)}px 0`,

        '& > h2': {
            padding: 0,
            color: theme.palette.secondary.main,
        },
    },
}));

const { DISPUTED_CLIENT, VERIFIED_BY_PROFESSIONAL, VERIFIED_FIRST_IMPRESSION} = STATE;

const VerifyAndRejectButton = props => {
    const classes = useStyles()
    const [showDialog, setShowDialog] = useState(false)

    const handleNotAClientClick = () => {
        setShowDialog(true);
    }

    const handleCloseClick = () => {
        setShowDialog(false);
    }

    const record = _.get(props, 'record', null);

    const setResourceVerified = () => {
        if (props.reviewType === "review") {
            return `reviews/${record.id}/change-state/${VERIFIED_BY_PROFESSIONAL}`;
        } else {
            return `first-impressions/verify/${record.id}/${VERIFIED_FIRST_IMPRESSION}`;
        }
    }

    const setResourceDisputed = () => {
        if (props.reviewType === "review") {
            return `reviews/${record.id}/change-state/${DISPUTED_CLIENT}`;
        } else {
            return `first-impressions/verify/${record.id}/${DISPUTED_CLIENT}`;
        }
    }

    if (record && record.current_review_state === DISPUTED_CLIENT) return null;

    if (record && record.review_disabled === true) {
        return <DisabledEditViewButtons {...props} />;
    }
    if (record && record.c_visible && showViewEditButton(record.current_review_state)) {
        return <EditViewButtons {...props} />;
    }

    const getClientOrProspectText = () => {
        if (props.reviewType === "review") {
            return 'client';
        } else {
            return 'prospect';
        }
    }

    if (record && onWorkflowStart(record)) {
        return (
            <>
                <ReviewMutationButton
                    label="Verify"
                    resource={setResourceVerified()}
                    verifyProps={{ ...props.verifyProps }}
                    options={{
                        onSuccess: {
                            notification: {
                                body: 'Review verified',
                                level: 'info',
                            },
                            refresh: true,
                        },
                        onError: {
                            notification: {
                                body: 'Error: Try again later',
                                level: 'warning',
                            },
                        },
                    }}
                />
                <Button className={classes.notAClientButton}
                    onClick={handleNotAClientClick}
                >
                    Not a {getClientOrProspectText()}
                </Button>
                <Dialog
                    className={classes.dialog}
                    open={showDialog}
                    onBackdropClick={handleCloseClick}
                >
                    <DialogTitle className={classes.dialogTitle}> Are you sure {record.client_first_name} is NOT a {getClientOrProspectText()}? </DialogTitle>
                    <DialogActions className={classes.actions}>
                        <Button
                            label="ra.action.cancel"
                            onClick={handleCloseClick}
                        >
                            Go back
                        </Button>
                        <ReviewMutationButton
                            label="Yes, I'm sure"
                            resource={setResourceDisputed()}
                            options={{
                                onSuccess: {
                                    notification: {
                                        body: 'Review rejected',
                                        level: 'info',
                                    },
                                    refresh: true,
                                },
                                onError: {
                                    notification: {
                                        body: 'Error: Try again later',
                                        level: 'warning',
                                    },
                                },
                            }}
                        />
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    return null;
};

const actions = {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
};

export default connect(
    null,
    actions
)(VerifyAndRejectButton);
