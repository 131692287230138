import React, { useState } from 'react';
import CustomChart from '../Charts/CustomChart';
import { palette } from '../../../../MyTheme';
import DashboardTable from '../Tables';
import * as analytics from '../../../../analytics';
import Tip from '../Tip';

const InfluencedStats = props => {
    const { data, lines, bars, filter } = props;
    const [colors] = useState([palette.primary.main]);
    const handleTableForwardNav = () => analytics.track('next', { category: 'influenced_table' });
    const handleTableBackwardNav = () => analytics.track('previous', { category: 'influenced_table' });
    return (
        <>
            <Tip text='"Influenced" refers to those who answered positively to whether they read your reviews before contacting you or becoming a client' />
            <CustomChart dataPoints={data} bars={bars} lines={lines} lineColors={colors} />
            <DashboardTable
                key={filter}
                filter={filter}
                onClickForward={handleTableForwardNav}
                onClickBackwards={handleTableBackwardNav}
                showCtaButton={true}
            />
        </>
    );
};

export default InfluencedStats;
