import React, { useState } from 'react';
import CircularProgressGradient from '../../../Components/ConsumerReactComponents/CircularProgressGradient';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import ContentTooltip from '../../../Components/ConsumerReactComponents/ContentTooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Badge from '@material-ui/core/Badge';

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        position: 'relative',
        width: 140,
        height: 140,
        margin: '0 auto',

        '& > div': {
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
        },
    },
    tooltipWrapper: {
        marginLeft: theme.spacing(5),
        marginTop: theme.spacing(2),
        zIndex: 10,
    },
    score: {
        fontSize: theme.spacing(4),
        marginTop: 0,
        marginBottom: 2,
    },
    label: {
        fontSize: theme.spacing(2),
        margin: 0,
        textTransform: 'uppercase',
        maxWidth: 132,
    },
    badge: {
        position: "absolute",
        top: "15px",
        right: "25px",
        '& > span': {
            color: "#fff",
            backgroundColor: theme.palette.error.main,
            height: "40px",
            width: "40px",
            borderRadius: "100%",
        }
    },
});

const CircularScore = ({ score, label, classes, loading, tooltipTitle, badge }) => {
    const [open, setOpen] = useState(false);

    const openTooltip = () => {
        setOpen(true);
    };

    const closeTooltip = () => {
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            {!loading && tooltipTitle && (
                <div className={classes.tooltipWrapper}>
                    <ClickAwayListener onClickAway={closeTooltip}>
                        <ContentTooltip
                            placement="bottom"
                            title={tooltipTitle}
                            open={open}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            onClick={open ? closeTooltip : openTooltip}
                            onClose={closeTooltip}
                        />
                    </ClickAwayListener>
                </div>
            )}
            {!loading && !tooltipTitle && badge && <Badge className={classes.badge} badgeContent={badge} color="tertiary"></Badge>}
            <Typography className={classes.score}>
                <b>{score}</b>
            </Typography>
            <Typography className={classes.label}>
                <b>{label}</b>
            </Typography>
            <CircularProgressGradient rating={1} maxRating={1} />
        </div>
    );
};

export default withStyles(styles)(CircularScore);
