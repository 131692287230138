import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';

const ReputationActionLoading = () =>
    ['', '', ''].map((_, i) => (
        <Skeleton height={52} animation="pulse" key={i} />
    ));

export default ReputationActionLoading;
