import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    children: PropTypes.any.isRequired,
    disable: PropTypes.bool,
};

const defaultProps = {
    disable: false,
};

const HighlightSocialShare = props => (
    <div>{props.children}</div>
);

HighlightSocialShare.defaultProps = defaultProps;
HighlightSocialShare.propTypes = propTypes;

export default HighlightSocialShare;
