import React from 'react';
import PropTypes from 'prop-types';
import { Edit } from 'react-admin';

import Card from '@material-ui/core/Card';
import withStyles from '@material-ui/core/styles/withStyles';
import CardContent from '@material-ui/core/CardContent';

const propTypes = {
    /**
     * The header component. In almost all cases this should be a usage of PageActions.js
     */
    header: PropTypes.any,
};

const defaultProps = {
    header: null,
};

const styles = () => ({
    root: {
        '& .MuiPaper-root': {
            padding: 0,
            boxShadow: 'none',
        },
        '& .simple-form > .MuiCardContent-root': {
            padding: 0,
        },
        '& .simple-form > .MuiToolbar-root': {
            paddingLeft: 0,
        },
    },
});

const AppLayoutEdit = ({ classes, children, header, ...rest }) => {
    return (
        <Card>
            <CardContent>
                {header}
                <Edit {...rest} className={classes.root}>
                    {children}
                </Edit>
            </CardContent>
        </Card>
    );
};

AppLayoutEdit.propTypes = propTypes;
AppLayoutEdit.defaultProps = defaultProps;

export default withStyles(styles)(AppLayoutEdit);
