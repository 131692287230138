import React, { useState } from 'react';
import _ from 'lodash';
import { required, useDataProvider } from 'react-admin';
import { FormControl, InputLabel, MenuItem, Select, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { useForm } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import FormTextInput from './Form/FormTextInput';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
    },
    postcodeRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: theme.spacing(4),
    },
    postcodeField: {
        width: 'auto !important',
        marginRight: theme.spacing(2),

        '& p': {
            display: 'none',
        },
    },
    formControl: {
        '& .MuiFormLabel-root.Mui-focused': {
            color: theme.palette.secondary.main,
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.secondary.main,
        },
    },
    selectInput: {
        marginBottom: theme.spacing(4),
    },
    addressFieldsRow: {
        display: 'flex',

        '@media screen and (max-width: 1450px)': {
            flexDirection: 'column',
        },
    },
}));

const AddressFields = () => {
    const form = useForm();
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const [state, setState] = useState({
        lookupList: [],
        selectedAddress: [],
    });

    const handleAddressChange = valuesString => {
        const values = JSON.parse(valuesString);

        form.batch(() => {
            form.change('address.address_line1', _.get(values, 'addressline1', ''));
            form.change('address.address_line2', _.get(values, 'addressline2', ''));
            form.change('address.town', _.get(values, 'posttown', ''));
        });

        setState({
            ...state,
            selectedAddress: _.get(values, 'posttown') ? values : [],
        });
    };

    const lookupAddress = async () => {
        const values = form.getState().values;
        const postcode = _.get(values, 'address.postcode');

        if (postcode) {
            const res = await dataProvider.getOne(`address/post-code/${postcode}`);
            const addresses = _.get(res, 'data.addresses', []);
            const hasResults = addresses.length > 0;

            if (hasResults) {
                handleAddressChange(hasResults ? JSON.stringify(addresses[0]) : []);
            }

            setState({
                ...state,
                lookupList: hasResults ? addresses : [],
            });
        }
    };

    const handleSelectChange = e => {
        handleAddressChange(e.target.value);
    };

    return (
        <div className={classes.root}>
            <div>
                <div className={classes.postcodeRow}>
                    <FormTextInput
                        validate={[required()]}
                        source="address.postcode"
                        label="Postcode"
                        defaultValue=""
                        className={classes.postcodeField}
                    />
                    <Button onClick={lookupAddress} color="secondary" variant="contained">
                        Lookup address
                    </Button>
                </div>
                {state.lookupList.length > 0 && (
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="select-address-label">Select Address</InputLabel>
                        <Select
                            labelId="select-address-label"
                            className={classes.selectInput}
                            value={JSON.stringify(state.selectedAddress)}
                            onChange={handleSelectChange}
                            label="Select Address"
                            id="select-address"
                        >
                            {state.lookupList.map(item => (
                                <MenuItem
                                    value={JSON.stringify(item)}
                                    key={_.get(item, 'addressline1', '')}
                                >
                                    {_.get(item, 'summaryline', '')}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </div>
            <div className={classes.addressFieldsRow}>
                <FormTextInput
                    inline
                    validate={[required()]}
                    source="address.address_line1"
                    defaultValue=""
                    label="Address Line 1"
                />
                <FormTextInput
                    inline
                    source="address.address_line2"
                    defaultValue=""
                    label="Address Line 2"
                />
                <FormTextInput
                    inline
                    validate={[required()]}
                    source="address.town"
                    defaultValue=""
                    label="Town"
                />
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        permissionsValues: state.basicInfo.permissions,
    };
};

export default connect(mapStateToProps)(AddressFields);
