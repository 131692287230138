import React, { useState } from 'react';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import ExtraCustomers from './ExtraCustomers';

const CustomersList = ({ customers, total, handleButtonClick }) => {
    const [buttonClicked, setButtonClicked] = useState(false);

    return (
        <>
            {customers.map(item => {
                const firstName = _.startCase(_.get(item, 'first_name', null));
                const lastName = _.startCase(_.get(item, 'last_name', null));
                const fullName = lastName
                    ? `${firstName} ${lastName.charAt(0)}.`
                    : firstName;

                return (
                    <Grid key={item.id} item xs={12}>
                        <Typography color="primary">{fullName}</Typography>
                    </Grid>
                );
            })}
            {total > 5 && !buttonClicked && (
                <Button
                    onClick={() => {
                        handleButtonClick(total);
                        setButtonClicked(true);
                    }}
                >
                    <ExtraCustomers
                        total={total}
                        currentSize={customers.length}
                    />
                </Button>
            )}
        </>
    );
};

export default CustomersList;
