import React from 'react';
import { useDispatch } from 'react-redux';
import { useDataProvider, showNotification } from 'react-admin';
import { Button } from '@material-ui/core';

import { impersonate } from '../state/impersonateActions';
import { history } from '../App';

const ImpersonateButtonComponent = ({ record }) => {
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();

    const handleClick = () => {
        dataProvider.create('firm/impersonate', { id: record.id })
            .then(impersonateBasicInfo => {
            dispatch(impersonate(impersonateBasicInfo));

            history.push('/');
        })
            .catch(error => {
                dispatch(showNotification("Professional is blocked", 'warning'))
            })
    }

    return (
        <Button color="primary" onClick={handleClick}>
            Login as adviser
        </Button>
    );
}

export default ImpersonateButtonComponent;
