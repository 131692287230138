import React from 'react';
import FeesPageWrapper from './Components/FeesPageWrapper';
import FeesInsightsContent from './Insights/FeesInsightsContent';

const FeesInsights = props => {
    return (
        <FeesPageWrapper
            id="fees-insights"
            resource="fees-insights"
            basePath="/fees-insights"
            {...props}
        >
            <FeesInsightsContent {...props} />
        </FeesPageWrapper>
    );
};

export default FeesInsights;
