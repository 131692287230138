import React, { Component } from 'react';
import _ from 'lodash';
import { ImageInput } from 'react-admin';
import FirmLogo from './FirmLogo';
import ImagePreviewCrop from '../../Components/Inputs/Components/ImagePreviewCrop';
import { MAX_FILE_UPLOAD_SIZE_FIVE_MB } from '../../constants/various';
import { withStyles } from '@material-ui/core';
import DialogButton from '../../Components/Dialogs/DialogButton';
import { ImageUploadHeading } from '../../Professional/Components/ProfileImage';

const styles = () => ({
    imageInput: {
        maxWidth: 800,
    },
});

class FirmLogoImage extends Component {
    state = {
        firmImage: {},
        imageCropComplete: false,
    };

    componentDidMount() {
        const firmImage = _.get(this.props, ['record', 'logo', 'urls', 'standard'], {});

        this.setState({
            firmImage,
        });
    }

    renderFirmLogo = () => {
        if (this.props.disabled && !this.state.firmImage) {
            return <span className={this.props.classes.inputLabel}>Firm logo</span>;
        }

        return null;
    };

    setImageCropComplete = (value) => {
        this.setState({
            imageCropComplete: value
        })
    }

    render() {
        const firmImage = _.get(this.props, ['record', 'logo', 'urls', 'standard'], {});
        const { isAdmin, classes } = this.props;

        return (
            <div>
                {!_.isEmpty(firmImage) && (
                    <FirmLogo src={firmImage} />
                )}
                {isAdmin ? (
                    <DialogButton
                        record={this.props.record}
                        type="upload-graphic"
                        name="Upload Photo"
                        resource="firm"
                        form={
                            <>
                                <ImageUploadHeading/>
                                <ImageInput
                                    source="file"
                                    label={null}
                                    accept="image/*"
                                    maxSize={MAX_FILE_UPLOAD_SIZE_FIVE_MB}
                                    className={classes.imageInput}
                                >
                                    <ImagePreviewCrop source="src" setImageCropComplete= {this.setImageCropComplete} />
                                </ImageInput>
                            </>
                        }
                        disableSave={!this.state.imageCropComplete}
                    />
                ) : (
                    this.renderFirmLogo()
                )}
            </div>
        );
    }
}

export default withStyles(styles)(FirmLogoImage);
