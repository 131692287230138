import React from 'react';
import CircularScore from './CircularScore';
import SkeletonWrapper from '../../../Components/SkeletonWrapper';

import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    root: {
        position: 'relative',
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(5),
        },
    },
    scoreLoadingWrapper: {
        margin: '0 auto',
        marginBottom: theme.spacing(1) / 2,
    },
    contentWrapper: {
        height: '100%',
        justifySelf: 'flex-start',
    },
    placeholderText: {
        fontSize: theme.spacing(1) * 1.5,
    },
});

const StatisticColumnBase = ({ score, name, children, classes, loading, tooltipTitle, badge }) => {
    return (
        <Grid item xs={12} lg={2}>
            <Grid container className={classes.root}>
                <Grid item xs={12}>
                    <SkeletonWrapper
                        loading={loading}
                        variant="circle"
                        height={148}
                        width={148}
                        className={classes.scoreLoadingWrapper}
                    >
                        <CircularScore
                            score={score}
                            label={name}
                            tooltipTitle={tooltipTitle}
                            loading={loading}
                            badge={badge}
                        />
                    </SkeletonWrapper>
                </Grid>
                <Grid item xs={12}>
                    {loading ? (
                        <>
                            <Skeleton animation="pulse" height={30} />
                            <Skeleton animation="pulse" height={30} />
                            <Skeleton animation="pulse" height={30} />
                        </>
                    ) : (
                        children
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(StatisticColumnBase);
