import React from 'react';

import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = () => ({
    root: {
        marginTop: 0,
        padding: 0,
        fontSize: 23,
    },
});

const DashboardCardTitle = ({ classes, children }) => (
    <Typography variant="h6" color="secondary" className={classes.root}>
        {children}
    </Typography>
);

export default withStyles(styles)(DashboardCardTitle);
