import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { ROOT_DOMAIN } from '../../constants';
import PageActions from '../../AppLayout/PageActions';
import Button from '../../Components/Buttons/CustomButton';

const ProfessionalPageActions = ({ permalink }) => {
    return (
        <PageActions title="Profile">
            <Button
                variant="contained"
                color="tertiary"
                href={permalink}
                target="_blank"
                rel="noopener noreferrer"
            >
                View Profile
            </Button>
        </PageActions>
    );
};


const mapStateToProps = ({ basicInfo }) => {
    return {
        permalink: `${ROOT_DOMAIN}/${_.get(basicInfo, ['permalink', 'url'], '')}`,
    };
};

export default connect(mapStateToProps)(ProfessionalPageActions);
