import React, { Component } from 'react';
import dataProvider from '../dataProvider';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { SimpleForm, CheckboxGroupInput, required, showNotification } from 'react-admin';
import { planUpgradeSuccess } from '../state/actions/basicProfileInfoActions';
import { validateRequiredCheckboxInputGroup } from '../validation';
import { FormSectionTitle } from '../Components/Typography/Headlines';
import BackButton from '../Components/Buttons/BackButton';
import FormToolbar from '../Components/Toolbars/FormToolbar';
import {
    CONTACT,
    SUPPORT_URL,
    TERMS_AND_CONDITIONS,
    VERTICAL,
    FA_BENEFITS,
    ACCOUNTANTS_BENEFITS,
} from '../constants';
import { BenefitIcon } from '../icons/icons';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import FormTextInput from '../Components/Inputs/Form/FormTextInput';

const styles = theme => ({
    section: {
        margin: `${theme.spacing(3)}px 0`,
    },
    formWrapper: {
        display: 'flex',
        flexDirection: 'column',
        margin: `${theme.spacing(3)}px 0`,
    },
    externalLink: {
        color: theme.palette.tertiary.main,
        textDecoration: 'none',
    },
    address: {
        margin: `${theme.spacing(3)}px 0`,
        fontSize: '14px',
    },
    formToolbar: {
        paddingLeft: 16,
    },
});

class DirectDebit extends Component {
    state = {
        mandateError: false,
    };

    renderErrorMessage = () => (
        <>
            <FormSectionTitle title="An Error Has Occurred" />
            <Typography variant="body1">
                We’re really sorry, but there’s been an error upgrading your account.
            </Typography>
            <Typography variant="body1">
                Please give our team a call on {CONTACT.PHONE_NUMBER}
            </Typography>
        </>
    );

    handleSubmit = values => {
        delete values.confirm;

        const postData = { data: values };
        const { planUpgradeSuccess, showNotification } = this.props;

        dataProvider('CREATE', 'professional/upgrade', postData)
            .then(() => {
                showNotification('Upgrade success!');
                planUpgradeSuccess();
            })
            .catch(() => this.setState({ mandateError: true }));
    };

    renderBenefits = benefit => (
        <Grid item xs={12} sm={6} md={4} style={{ textAlign: 'center' }} key={benefit.title}>
            <BenefitIcon />
            <FormSectionTitle title={benefit.title} />
            <Typography variant="body1">{benefit.content}</Typography>
        </Grid>
    );

    render() {
        const { mandateError } = this.state;
        const { classes, permissions } = this.props;
        const isAccountant = VERTICAL.ACCOUNTANT === permissions.vertical;
        const isCoach = _.get(permissions, 'coach_mode', false);

        const upgradeFee = () => {
            switch (true) {
                case isCoach:
                    return 'The cost is £18 +VAT per month for your first 2 years as a Financial Coach and after the two year period, we will charge £24 +VAT per month. ';
                default:
                    return `The cost is ${isAccountant ? '36' : '54'} +VAT per month (after any offers you're eligible for). `;
            }
        }

        return (
            <Card>
                <SimpleForm
                    save={this.handleSubmit}
                    toolbar={
                        <FormToolbar
                            label="Submit"
                            validForm={!mandateError}
                            className={classes.formToolbar}
                        />
                    }
                >
                    <>
                        <Grid container justify="space-between" alignItems="center">
                            <FormSectionTitle title="Upgrade Plan: Direct Debit Set Up" />
                            <BackButton />
                        </Grid>
                        <div className={classes.section}>
                            <Typography variant="body1">
                                Upgrade to our Verified plan using the form below. { upgradeFee() }&nbsp;
                                <a
                                    className={classes.externalLink}
                                    href={SUPPORT_URL.ACCOUNT.VERIFIED_MEMBERSHIP}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Read more about our Verified plan and the benefits here
                                </a>
                            </Typography>
                        </div>
                        <div className={classes.section}>
                            <FormSectionTitle title="What you’ll get as a Verified member:" />
                            <br />
                            <Grid
                                container
                                spacing={2}
                                direction="row"
                                justify="space-evenly"
                                alignItems="flex-start"
                            >
                                {isAccountant
                                    ? ACCOUNTANTS_BENEFITS.map(benefit =>
                                          this.renderBenefits(benefit)
                                      )
                                    : FA_BENEFITS.map(benefit => this.renderBenefits(benefit))}
                            </Grid>
                        </div>
                        {mandateError ? (
                            this.renderErrorMessage()
                        ) : (
                            <>
                                <div className={classes.formWrapper}>
                                    <FormSectionTitle title="Complete the form below to upgrade to a Verified account" />
                                    <FormTextInput
                                        className={classes.formInput}
                                        source="account_holder_name"
                                        label="Account Holder Name"
                                        validate={[required()]}
                                    />
                                    <FormTextInput
                                        className={classes.formInput}
                                        source="sort_code"
                                        label="Sort Code"
                                        validate={[required()]}
                                    />
                                    <FormTextInput
                                        className={classes.formInput}
                                        source="account_number"
                                        label="Account Number"
                                        validate={[required()]}
                                    />
                                    <CheckboxGroupInput
                                        className={classes.formInput}
                                        source="confirm"
                                        label=""
                                        validate={[required(), validateRequiredCheckboxInputGroup]}
                                        choices={[
                                            {
                                                id: 'confirm',
                                                name:
                                                    'I confirm that I am the account holder and am the only person required to authorise debits on this account.',
                                            },
                                        ]}
                                    />
                                </div>
                                <Divider />
                                <div>
                                    <div className={classes.bottomHeading}>
                                        <FormSectionTitle title="The Direct Debit Guarantee" />
                                    </div>
                                    <ul className={classes.bottomList}>
                                        <li>
                                            <Typography variant="body1">
                                                This Guarantee is offered by all banks and building
                                                societies that accept instructions to pay Direct
                                                Debits.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant="body1">
                                                If there are any changes to the amount, date or
                                                frequency of your Direct Debit, GoCardless Ltd re:
                                                VouchedFor will notify you 3 working days in advance
                                                of your account being debited or as otherwise
                                                agreed.If you request GoCardless Ltd re: VouchedFor
                                                to collect a payment, confirmation of the amount and
                                                date will be given to you at the time of the
                                                request.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant="body1">
                                                If an error is made in the payment of your Direct
                                                Debit by GoCardless Ltd re: VouchedFor or your bank
                                                or building society, you are entitled to a full and
                                                immediate refund of the amount paid from your bank
                                                or building society
                                            </Typography>
                                            <ul>
                                                <li>
                                                    <Typography variant="body1">
                                                        If you receive a refund you are not entitled
                                                        to, you must pay it back when GoCardless Ltd
                                                        re: VouchedFor asks you to.
                                                    </Typography>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <Typography variant="body1">
                                                You can cancel a Direct Debit at any time by simply
                                                contacting your bank or building society. Written
                                                confirmation may be required. Please also notify us.
                                            </Typography>
                                        </li>
                                    </ul>
                                    <Typography variant="body1" className={classes.address}>
                                        VouchedFor Ltd <br />
                                        8 Waldegrave Rd, <br />
                                        Teddington, <br />
                                        Middlesex, <br />
                                        TW11 8HT <br />
                                        {CONTACT.PHONE_NUMBER} <br />
                                        <a href={`mailto:${CONTACT.EMAIL}`}>{CONTACT.EMAIL}</a>
                                    </Typography>
                                    <Typography variant="body1" className={classes.terms}>
                                        By confirming, you agree to our{' '}
                                        <a href={TERMS_AND_CONDITIONS}>terms &amp; conditions</a>
                                    </Typography>
                                </div>
                            </>
                        )}
                    </>
                </SimpleForm>
            </Card>
        );
    }
}

const enhance = compose(
    connect(
        null,
        { showNotification, planUpgradeSuccess }
    ),
    withStyles(styles)
);

export default enhance(DirectDebit);
