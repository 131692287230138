import React from 'react';
import {
    ReferenceManyField,
    Datagrid,
    TextField,
    DeleteButton,
} from 'react-admin';

const LanguagesGrid = props => (
    <ReferenceManyField
        {...props}
        label={null}
        reference="languages"
        target="professional_id"
    >
        <Datagrid>
            <TextField label="Language" source="attribute_name" />
            <DeleteButton icon={null} redirect={false} />
        </Datagrid>
    </ReferenceManyField>
);

export default LanguagesGrid;
