import React from 'react';
import PropTypes from 'prop-types';
import { SaveButton } from 'react-admin';
import Toolbar from '../Forms/FormToolbar';

const propTypes = {
    label: PropTypes.string.isRequired,
    validForm: PropTypes.bool,
    style: PropTypes.object,
};

const defaultProps = {
    validForm: true,
    label: 'Save',
    style: null,
    submitOnEnter: true,
};

const FormToolbar = ({ label, validForm, style, submitOnEnter, ...props }) => {
    return (
        <Toolbar {...props}>
            <SaveButton
                label={label}
                disabled={!validForm}
                invalid={true}
                submitOnEnter={submitOnEnter}
                style={style}
            />
        </Toolbar>
    );
};

FormToolbar.propTypes = propTypes;
FormToolbar.defaultProps = defaultProps;

export default FormToolbar;
