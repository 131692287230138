import React from 'react';
import { Show, SimpleShowLayout, TopToolbar } from 'react-admin';
import { FormSectionTitle } from '../../Components/Typography/Headlines';
import { Grid, Typography } from '@material-ui/core';
import BackButton from '../../Components/Buttons/BackButton';
import { formatSimpleListDate } from '../../utils';

const BillingShowContent = props => {
    let billingItem = props.location.state.billingItem;

    billingItem.children[0].date = new Date(billingItem.children[0].date);

    billingItem.children[0].children.forEach((child, index) => {
        billingItem.children[0].children[index].date = new Date(child.date);
    });

    return (
        <div>
            <FormSectionTitle title={billingItem.description} />
            <Typography variant="body1">Invoice information:</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Typography>{formatSimpleListDate(billingItem.children[0].date)}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography>{billingItem.children[0].description}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography>
                        {billingItem.children[0].amount.replace('(', '').replace(')', '')}
                        {billingItem.children[0].amount_description}
                    </Typography>
                </Grid>
            </Grid>
            <Typography variant="body1">Details:</Typography>
            {billingItem.children[0].children.map((child, index) => (
                <Grid key={`container-${index}`} container spacing={3}>
                    <Grid key={`date-${index}`} item xs={4}>
                        <Typography>{formatSimpleListDate(child.date)}</Typography>
                    </Grid>
                    <Grid key={`description-${index}`} item xs={4}>
                        <Typography>{child.description}</Typography>
                    </Grid>
                </Grid>
            ))}
        </div>
    );
};

const BillingShow = props => (
    <Show
        title={null}
        actions={
            <TopToolbar>
                <BackButton />
            </TopToolbar>
        }
        {...props}
    >
        <SimpleShowLayout>
            <BillingShowContent {...props} />
        </SimpleShowLayout>
    </Show>
);

export default BillingShow;
