import React, { Component } from 'react';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import dataProvider from '../../dataProvider';
import ReputationToolCard from '../Card/ReputationToolCard';
import {REP_TOOL_REVIEW_STARS, REP_TOOL_REVIEW_STARS_VF_2023} from '../../constants';
import * as analytics from '../../analytics';

import Button from '../../Components/Buttons/CustomButton';

class ProfessionalShareReview extends Component {
    state = {
        latestReviewId: null,
        hasClickedShareButton: false,
    };

    componentDidMount() {
        this.setReview();
    }

    setReview = async () => {
        const params = {
            pagination: { perPage: 1 },
            sort: { field: 'id', order: 'DESC' },
        };

        const res = await dataProvider('GET_LIST', 'reviews', params);
        const latestReviewId = _.get(res, ['data', '0', 'id'], null);

        this.setState({ latestReviewId });
    };

    onClick = () => {
        this.setState({ hasClickedShareButton: true });

        analytics.track('Clicked share most recent review', {
            category: 'Profile promotion',
        });
    };

    getUpgradeProps = () => {
        if (_.isNull(this.state.latestReviewId)) {
            return {
                message: 'A review is required to access this feature',
                component: 'div',
                href: undefined,
            };
        }

        return undefined;
    };

    render() {
        const { hasClickedShareButton, latestReviewId } = this.state;

        if (hasClickedShareButton && latestReviewId) {
            return <Redirect to={`reviews/${latestReviewId}`} />;
        }

        return (
            <ReputationToolCard
                title="Share Your Latest Review"
                subTitle="Just received a great review? Why not show it off to your social networks!"
                image={this.props.showNewRepTool ? REP_TOOL_REVIEW_STARS_VF_2023 : REP_TOOL_REVIEW_STARS}
                disabled={_.isNull(latestReviewId)}
                upgradeProps={this.getUpgradeProps()}
                actions={
                    <Button
                        variant="contained"
                        color="tertiary"
                        onClick={this.onClick}
                    >
                        Share Latest Review
                    </Button>
                }
            />
        );
    }
}

export default ProfessionalShareReview;
