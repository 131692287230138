import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { FormDataConsumer, required } from 'react-admin';
import { PROFESSIONAL } from '../../constants';
import Button from '../../Components/Buttons/CustomButton';
import FormTextInput from '../../Components/Inputs/Form/FormTextInput';
import FormSelectInput from '../../Components/Inputs/Form/FormSelectInput';

const { FINANCIAL_ADVISER } = PROFESSIONAL.VERTICAL;

const BasicProfileEditFirmFields = props => {
    const { firmChoices, manuallyAddFirmName, isFinancialAdviser } = props;

    return (
        <FormDataConsumer>
            {({ formData }) => {
                const firmId = _.get(formData, 'firm.id');

                if (firmId) {
                    return (
                        <>
                            <FormSelectInput
                                validate={[required()]}
                                source="firm.id"
                                defaultValue=""
                                label="Firm name"
                                choices={firmChoices}
                            />
                            <Button color="tertiary" onClick={manuallyAddFirmName}>
                                Not listed? Create a new firm
                            </Button>
                        </>
                    );
                }

                return (
                    <FormTextInput
                        validate={[required()]}
                        source={isFinancialAdviser ? 'firm.name' : 'firm_name'}
                        label="Firm name"
                        defaultValue=""
                    />
                );
            }}
        </FormDataConsumer>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    const vertical = _.get(basicInfo, 'permissions.vertical');

    return {
        isFinancialAdviser: vertical === FINANCIAL_ADVISER,
    };
};

export default connect(mapStateToProps)(BasicProfileEditFirmFields);
