export const SERVICE_TYPE_FILTER_FA = {
    name: 'Financial advice',
    type: 1,
};
export const SERVICE_TYPE_FILTER_MA = {
    name: 'Mortgage advice',
    type: 2,
};
export const SERVICE_TYPE_FILTER_LEGAL = {
    name: 'Legal advice',
    type: 4,
};
export const SERVICE_TYPE_FILTER_PROTECTION = {
    name: 'Protection advice',
    type: 3,
};

export const SERVICE_TYPE_FILTER_TRANSFERRED_CLIENTS = {
    name: 'Transferred clients',
    type: 5,
};
export const SERVICE_TYPE_FILTER_EQUITY_RELEASE = {
    name: 'Equity release',
    type: 6,
};

export const SERVICE_TYPE_FILTERS = [
    SERVICE_TYPE_FILTER_FA,
    SERVICE_TYPE_FILTER_MA,
    SERVICE_TYPE_FILTER_LEGAL,
    SERVICE_TYPE_FILTER_PROTECTION,
    SERVICE_TYPE_FILTER_EQUITY_RELEASE
];
export const SERVICE_TYPE_FILTERS_EXCLUDING_LEGAL = [
    SERVICE_TYPE_FILTER_FA,
    SERVICE_TYPE_FILTER_MA,
    SERVICE_TYPE_FILTER_PROTECTION,
    SERVICE_TYPE_FILTER_EQUITY_RELEASE
];

export const GOALS = {
    1: "Prospect Conversion",
    2: "Client Advocacy",
    3: "Risk Mitigation"
}