export const SERVICE = {
    PENSION_TRANSFERS_NON_SPECIALIST: 557,
    PENSION_TRANSFERS_SPECIALIST: 635,
};

export const MORTGAGE_SERVICES_ATTRIBUTE_ID = 392;
export const INSURANCE_AND_PROTECTION_ATTRIBUTE_ID = 391;
export const PENSION_AND_RETIREMENT_ATTRIBUTE_ID = 393;
export const EQUITY_RELEASE_ATTRIBUTE_ID = 394;
export const ANNUITIES_ATTRIBUTE_ID = 395;
export const AUTO_ENROLMENT_ATTRIBUTE_ID = 396;
export const INVESTMENTS_AND_SAVINGS_SERVICES_ATTRIBUTE_ID = 397;
export const OCCUPATIONAL_PENSION_TRANSFERS_ATTRIBUTE_ID = 557;
export const INHERITANCE_PLANNING_ATTRIBUTE_ID = 558;
export const LONG_TERM_CARE_ATTRIBUTE_ID = 621;
export const WEALTH_CRITERIA_ASSOCIATED_SERVICES = [INVESTMENTS_AND_SAVINGS_SERVICES_ATTRIBUTE_ID, INSURANCE_AND_PROTECTION_ATTRIBUTE_ID, EQUITY_RELEASE_ATTRIBUTE_ID, ANNUITIES_ATTRIBUTE_ID, AUTO_ENROLMENT_ATTRIBUTE_ID, INHERITANCE_PLANNING_ATTRIBUTE_ID, LONG_TERM_CARE_ATTRIBUTE_ID,PENSION_AND_RETIREMENT_ATTRIBUTE_ID]


export const allServiceDescriptions = [
    {
        "id": 391,
        "gka": "financial_adviser_advice_areas_services_insurance_protection",
        "description": "Get help to understand the best level of life cover, critical illness cover and income protection for you.",
    },
    {
        "id": 392,
        "gka": "financial_adviser_advice_areas_services_mortgages",
        "description": "Get help with everything from buying your first property, to remortgaging, or funding investment in property.",
    },
    {
        "id": 393,
        "gka": "financial_adviser_advice_areas_services_pension_and_retirement",
        "description": "Get help on making a plan for a comfortable retirement; whether it's starting a pension or understanding how much you should be contributing to one.",
    },
    {
        "id": 394,
        "gka": "financial_adviser_advice_areas_services_equity_release",
        "description": "Get help with understanding your options for releasing equity from your home. Understand the main options, the costs associated and the long term implications of each option. Equity release will reduce the value of your estate and could affect eligibility to means tested benefits. Your adviser should be able to assess and explain this to you.",
    },
    {
        "id": 395,
        "gka": "financial_adviser_advice_areas_services_annuities",
        "description": "Get help understanding and evaluating annuities vs. drawdown, or discussing the other available retirement income options for you.",
    },
    {
        "id": 396,
        "gka": "financial_adviser_advice_areas_services_auto_enrolment",
        "description": "Get help with setting up auto enrolment as an employer, or understand any auto enrolment scheme you are signed up to as an employee.",
    },
    {
        "id": 397,
        "gka": "financial_adviser_advice_areas_services_investments_and_savings",
        "description": "Get help generating a better return on your savings, with a full range of investment options which could include equities, funds, bonds and alternative investments.",
    },
    {
        "id": 557,
        "gka": "financial_adviser_advice_areas_services_occupational_pension_transfers",
        "description": "Get advice on transferring pensions where you do not need a specialist, for example a standard personal pension or one through your employer that is made up ONLY of regular contributions from you and your employer (this should not be a defined benefit pension)",
    },
    {
        "id": 558,
        "gka": "financial_adviser_advice_areas_services_inheritance_planning",
        "description": "Get help with planning your inheritance and understanding the tax implications of an inheritance you leave, or accessing an inheritance you have received.",
    },
    {
        "id": 621,
        "gka": "financial_adviser_advice_areas_services_long_term_care",
        "description": "Get help with understanding the options you have to fund yours or a loved one's long term care.",
    },
    {
        "id": 635,
        "gka": "financial_adviser_advice_areas_services_pension_transfers_specialist",
        "description": "Get advice on transferring pensions where you need a specialist, for example where you want to transfer a Defined Benefit or Final Salary scheme (one that promises to pay a certain income), or where there are other safeguarded benefits. Transferring out of a Final Salary scheme is unlikely to be in the best interests of most people.",
    },
    {
        "id": 638,
        "gka": "financial_adviser_advice_areas_services_financial_planning",
        "description": "Get help with setting specific financial goals, seeing how close you are to achieving them and forming a plan to get on track; whether that is planning for an early retirement or planning to buy your first home.",
    },
    {
        "id": 639,
        "gka": "financial_adviser_advice_areas_services_financial_mentoring",
        "description": "Financial Mentoring gives you practical tools to help you make decisions. For example, setting a budget, assessing how much investment risk you are willing to take, or using a mortgage calculator to arrive at the best mortgage approach for you.",
    },
    {
        "id": 640,
        "gka": "financial_adviser_advice_areas_services_financial_coaching",
        "description": "Through the process of being asked insightful questions, financial coaching helps you develop a better relationship with money, feel calmer and more in control, and build habits that can help you achieve your financial goals.",
    },
    {
        "id": 2,
        "gka": "legal_adviser_services_accidents_and_injuries",
        "description": "Get help with personal injuries, environmental hazards, product liability, medical claims and negligence, road traffic accidents,mental health or criminal injury",
    },
    {
        "id": 10,
        "gka": "legal_adviser_services_business",
        "description": "Get help with business formation, company structure, commercial law and contracts, consumer and data protection, environmental regulations, financial regulations, health & safety, importing and exporting, marketing, shareholder and boardroom disputes, or shares and shareholders. ",
    },
    {
        "id": 17,
        "gka": "legal_adviser_services_disputes",
        "description": "Get help with arbitration, defamation, consumer disputes, mediation, professional negligence or contract disputes",
    },
    {
        "id": 23,
        "gka": "legal_adviser_services_crime",
        "description": "Get help with fraud and financial crimes, road traffic offences, drink driving offences, human rights, burglary and theft, drug related crimes, sexual offences and violent crimes",
    },
    {
        "id": 29,
        "gka": "legal_adviser_services_finance",
        "description": "Get help with mergers and acquisitions, insurance disputes, debt and bankruptcy, tax, insolvency, corporate finance, trusts, raising finance, welfare and benefits disputes",
    },
    {
        "id": 33,
        "gka": "legal_adviser_services_divorce_and_family",
        "description": "Get help with divorce, civil partnership dissolution, prenuptial or postnuptial agreements, children, restraining orders, spousal support, court of protection, domestic abuse",
    },
    {
        "id": 41,
        "gka": "legal_adviser_services_employment",
        "description": "Get help with employment contracts, dismissal and redundancy, settlements, discrimination, maternity and paternity leave, or disciplinary procedures",
    },
    {
        "id": 47,
        "gka": "legal_adviser_services_immigration",
        "description": "Get help with citizenship and a variety of visa applications, emigration to other countries, work permits, asylum and refugee issues",
    },
    {
        "id": 59,
        "gka": "legal_adviser_services_property_and_conveyancing",
        "description": "Get help with neighbour disputes, planning, conveyancing, transferring equity, landlord-tenant agreements or property related disputes",
    },
    {
        "id": 66,
        "gka": "legal_adviser_services_wills_and_probate",
        "description": "Get help with making or changing a Will",
    },
    {
        "id": 650,
        "gka": "legal_adviser_services_probate",
        "description": "Get legal help dealing with the estate of a person who has died",
    },
    {
        "id": 71,
        "gka": "legal_adviser_services_other",
        "description": "Get help with a range of other areas such as environmental issues, charities, aviation & maritime, travel & tourism, education, media, information technology and sport",
    },
    {
        "id": 582,
        "gka": "legal_adviser_services_intellectual_property",
        "description": "Get help with intellectual property, disputes, copyrights, patents and trademarks",
    },
    {
        "id": 295,
        "gka": "accountant_business_services_bankruptcy_and_debt",
        "description": "Get help with bankruptcy or individual voluntary arrangements",
    }, {
        "id": 298,
        "gka": "accountant_personal_services_tax",
        "description": "Get help with allowances and reliefs, capital gains tax, foreign earnings and overseas tax, planning, inheritance tax, national insurance, P11Ds completion, personal tax, property tax, self assessment, tax efficient investments and tax planning",
    },
    {
        "id": 315,
        "gka": "accountant_business_services_accounting_and_bookkeeping",
        "description": "Get help with accounts preparation, bookkeeping, budget preparation, cash flow forecasts, financial reporting, forming a company and payroll",
    },
    {
        "id": 320,
        "gka": "accountant_business_services_audit_and_assurance",
        "description": "Get help with audit, charities and not for profit audits, IFRS audits, internal audits, LLP audits, pensions audits, registered social landlords audits, SME audits, solicitor audits, or forensic accounting",
    },
    {
        "id": 334,
        "gka": "accountant_business_services_tax",
        "description": "Get help with capital allowances, construction industry scheme, contractors and IR35, corporate taxes, employment taxes, R&D tax credits, stamp and other indirect taxes or VAT",
    },
    {
        "id": 351,
        "gka": "accountant_business_services_insolvency",
        "description": "Get help with asset tracing and recovery, corporate insolvency, corporate recovery, liquidation, refinancing, reorganisation and operational restructuring",
    },
    {
        "id": 360,
        "gka": "accountant_business_services_financial_management",
        "description": "Get help with bank loans and overdrafts, equity issues, valuations and raising finance",
    },
    {
        "id": 371,
        "gka": "accountant_business_services_planning_and_consultancy",
        "description": "Get help with business planning, pensions schemes and auto enrolment or start up business advice",
    }
];

export const FINANCIAL_ADVISER_SERVICES = [
    'Auto Enrolment',
    'Equity Release',
    'Insurance & Protection',
    'Inheritance Planning',
    'Retirement income (annuities, drawdown)',
    'Investments and Savings',
    'Long Term Care',
    'Pension Transfers - Non Specialist',
    'Pensions',
];
