import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormDataConsumer, required, SimpleForm, TopToolbar } from 'react-admin';
import { FormSectionTitle } from '../Components/Typography/Headlines';
import PreventUndoEdit from '../Components/PreventUndoEdit';
import BackButton from '../Components/Buttons/BackButton';
import TrueFalseInput from '../Components/Forms/TrueFalseInput';
import FormTextInput from '../Components/Inputs/Form/FormTextInput';
import FormDateInput from '../Components/Inputs/Form/FormDateInput';
import { validateEndDate } from '../validation';

class JobsEdit extends Component {
    render() {
        const { dispatch, professionalId, ...rest } = this.props;
        const jobsUrl = `/professional/experience/${professionalId}`;

        return (
            <PreventUndoEdit
                actions={
                    <TopToolbar>
                        <BackButton href={jobsUrl} />
                    </TopToolbar>
                }
                {...rest}
            >
                <SimpleForm redirect={jobsUrl}>
                    <FormSectionTitle title="Edit Job" />
                    <FormTextInput label="Position" source="position" validate={[required()]} />
                    <FormTextInput label="Company" source="company" validate={[required()]} />
                    <FormDateInput label="Start date" source="startDate" validate={[required()]} />
                    <TrueFalseInput label="This is my current position" source="current" />
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !formData.current && (
                                <FormDateInput
                                    label="End date"
                                    source="endDate"
                                    validate={[validateEndDate, required()]}
                                    {...rest}
                                />
                            )
                        }
                    </FormDataConsumer>
                </SimpleForm>
            </PreventUndoEdit>
        );
    }
}

const mapStateToProps = ({ basicInfo }) => {
    return {
        professionalId: basicInfo.id,
    };
};

export default connect(mapStateToProps)(JobsEdit);
