import _ from 'lodash';
import { SUPPORT_URL } from '../../constants';
import * as analytics from "../../analytics";

const getSidebarItems = (professionalId, permissions) => {
    const defaultSidebarItems = [
        'dashboard',
        'profile',
        'firm',
        'reputation',
        'reviews',
        'enquiries',
        'settings',
        'help',
    ];
    const sidebarItems = _.get(permissions, 'sidebar_items', defaultSidebarItems);
    const canUseReferralGenerator = _.get(permissions, 'can_use_referral_generator');
    const onPrivateMode = _.get(permissions, 'elevation_private_mode');

    const lookup = {
        dashboard: {
            sort: 0,
            href: '/',
            label: 'Home',
            gka: 'dashboard',
        },
        profile: {
            sort: 1,
            label: 'Profile',
            href: `/professional/about/${professionalId}`,
            gka: 'profile',
            disabled: onPrivateMode,
        },
        fees: {
            sort: 2,
            label: 'Advice Fees',
            href: _.get(permissions, 'show_fees_insights_tab') ? '/fees-insights' : '/fees',
            gka: 'fees',
            disabled: onPrivateMode,
        },
        firm: {
            sort: 3,
            label: 'Firm',
            href: `/professional/firm/${professionalId}`,
            gka: 'firm',
            disabled: onPrivateMode,
        },
        reputation: {
            sort: 4,
            label: 'Reputation Tools',
            href: '/reputation',
            gka: 'reputation',
            disabled: onPrivateMode,
        },
        reviews: {
            sort: 5,
            label: 'Reviews',
            href: '/invite-reviews',
            gka: 'reviews',
        },
        referral: {
            sort: 6,
            label: 'Referral Generator',
            href: `/referral-generator/create`,
            gka: 'referral',
            disabled: !canUseReferralGenerator,
        },
        enquiries: {
            sort: 7,
            label: 'Enquiries',
            href: `/enquiries-list/${professionalId}`,
            gka: 'enquiries',
            disabled: onPrivateMode,
        },
        settings: {
            sort: 8,
            label: 'Settings & Billing',
            href: `/account-settings/${professionalId}`,
            gka: 'settings',
        },
        help: {
            sort: 9,
            label: 'Help centre',
            href: SUPPORT_URL.HELP_CENTRE,
            gka: 'help',
            external: true,
            onClick: () => {
                window.open(SUPPORT_URL.HELP_CENTRE, '_blank')
                analytics.track(`clicked 'help centre' in sidebar`, { category: 'Support' });
            },
        },
    };

    return sidebarItems
        .map(sidebarItemGka => lookup[sidebarItemGka])
        .sort((a, b) => a.sort - b.sort);
};

export default getSidebarItems;
