export const CONTACT = {
    EMAIL: 'customer_service@vouchedfor.co.uk',
    PHONE_NUMBER: '0203 111 0580',
    ACCOUNTS_TEAM_NUMBER: '0203 111 0583',
};

export const FA_MONTHLY_CHARGE = '£54 + VAT';
export const ACCOUNTS_MONTHLY_CHARGE = '£36 + VAT';

export const PASSWORD_ERROR = {
    NOTIFICATION:
        'Sorry, it looks like there was an error with your username or password. Please try again, or contact us',
    INPUT_MESSAGE:
        '* Your password must be at least 8 characters, with a combination of lower and upper case letters and numbers',
};

export const VERIFY_SERVICE_ROUTES = {
    PROFILE: 'basic-profile',
    VERIFY: 'verify-services',
    SERVICES: 'services',
    CHOICES: 'services-choices',
    SETTINGS: 'account-settings',
};

export const NO_REWARDS_ID = 4;

export const ALL_REVIEWERS_ALL_TIME = 'all_reviewers_all_time';

export const MAX_FILE_UPLOAD_SIZE_TWO_MB = 2000000;
export const MAX_FILE_UPLOAD_SIZE_FIVE_MB = 5000000;

export const ANALYTICS_LABEL = {
    no_reviews_for_30_days_send_invitations: 'Invite reviews',
    no_first_impression_reviews_for_21_days: 'Invite first impression',
};

export const DISABLED_MENU_TOOLTIP =
    'To access this feature you need to upgrade to Verified membership and set your profile to public';

export const PUBLIC_PROFILE_BENEFITS = [
    {
        primary: 'Get a public profile',
        secondary: 'ranking highly in Google',
    },
    {
        primary: 'Only the public elements of your reviews are shown',
        secondary: '(star rating + comment boxes)',
    },
    {
        primary: 'Access 7+ reputation tools,',
        secondary: 'including the ability to feature in our Top Rated Guide',
    },
    {
        primary: `Be fully 'Verified'`,
        secondary: 'with checks to drive confidence',
    },
    {
        primary: 'The cost of this is typically £54 +VAT monthly or £540 +VAT paid annually',
        secondary: '',
    },
];

export const PRIVATE_MODE_STATISTICS_TEXT = {
    overall_rating: {
        preText: 'Your rating, from',
        sucText: 'reviews',
    },
    client_advocacy: {
        text: 'of clients say they’re passionate advocates',
    },
    prospect_intentions: {
        text: 'of prospects say they will go on to become clients',
    },
};

export const LOCATION_CHANGE_DISCLAIMER =
    'Please note, when you change towns, your Google listing usually takes between 1-14 days to update. If it takes longer than this, please contact our customer service team';

export const ELEVATION_DASHBOARD_HEADER_TEXT =
    'Your firm is signed up to VouchedFor Elevation to support you in delivering the highest standards of client experience. Below is your report showing your performance across the three key goals and the drivers that contribute to each goal.';

export const FEATURE_ELEVATION_CHECKBOX = 'elevation_checkbox';
