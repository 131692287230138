import React from 'react';
import _ from 'lodash';
import { Query } from 'react-admin';
import DashboardCardBase from '../CardComponents/DashboardCardBase';
import DashboardCardTitle from '../CardComponents/DashboardCardTitle';
import DashboardFooterButton from '../CardComponents/DashboardFooterButton';
import DashboardCardRank from '../CardComponents/DashboardCardRank';
import SkeletonWrapper from '../../../Components/SkeletonWrapper';

const DashboardReviewRecencyRankCard = () => {
    return (
        <Query type="getOne" resource="dashboard/reviews-rank">
            {({ loading, data }) => (
                <DashboardCardBase
                    header={
                        <SkeletonWrapper loading={loading} height={56}>
                            <DashboardCardTitle>{_.get(data, 'title', '')}</DashboardCardTitle>
                        </SkeletonWrapper>
                    }
                    body={
                        <DashboardCardRank
                            rank={_.get(data, 'rank')}
                            content={_.get(data, 'content')}
                            loading={loading}
                        />
                    }
                    footer={
                        <DashboardFooterButton
                            content="Invite more reviews"
                            buttonHref="/invite-reviews"
                            analyticsAction="You get fewer reviews than some advisers"
                            analyticsCategory="Dashboard card"
                            analyticsLabel="Clicked Invite More Reviews button"
                        />
                    }
                />
            )}
        </Query>
    );
};

export default DashboardReviewRecencyRankCard;
