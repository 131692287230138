import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import BarChart from '../../Components/Charts/BarChart';
import * as analytics from '../../analytics';

import Button from '../../Components/Buttons/CustomButton';
import withStyles from '@material-ui/core/styles/withStyles';

const propTypes = {
    disabled: PropTypes.bool,
    data: PropTypes.array,
    title: PropTypes.string,
    xAxis: PropTypes.object,
};

const styles = theme => ({
    container: {
        position: 'relative',
    },
    overlayWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: 2,
        backgroundColor: 'rgba(255, 255, 255, 0.31)',
    },
});

class FeesChargeGraph extends Component {
    state = {
        hovered: false,
    };

    handleMouseEnter = () => {
        this.setState({ hovered: true });
    };

    handleMouseLeave = () => {
        this.setState({ hovered: false });
    };

    renderButton = () => {
        const { hasFeesChargeLevels, onStarterPlan } = this.props;
        const buttonProps = {
            component: Link,
            variant: 'contained',
        };

        if (!hasFeesChargeLevels) {
            return (
                <Button to="/fees" color="tertiary" {...buttonProps}>
                    Add a fee level to view this graph
                </Button>
            );
        }

        if (onStarterPlan) {
            return (
                <Button
                    to="/payment"
                    onClick={() =>
                        analytics.track('Clicked Upgrade', {
                            category: 'Fee Insights',
                        })
                    }
                    color="primary"
                    {...buttonProps}
                >
                    Upgrade to view graph
                </Button>
            );
        }
    };

    render() {
        const { hovered } = this.state;
        const { disabled, classes, data, title, xAxis } = this.props;
        const containerStyle = disabled && hovered ? classes.container : null;

        return (
            <div
                className={containerStyle}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            >
                {disabled && hovered && (
                    <div className={classes.overlayWrapper}>{this.renderButton()}</div>
                )}
                <BarChart
                    disabled={disabled}
                    data={data}
                    title={title}
                    dataKey="professional_count"
                    xAxis={xAxis}
                    yAxis={{
                        label: 'Number of advisers',
                        dataKey: 'professional_count',
                    }}
                    tooltip={{
                        formatter: value => [value, 'Number of advisers'],
                    }}
                />
            </div>
        );
    }
}

FeesChargeGraph.propTypes = propTypes;

export default withStyles(styles)(FeesChargeGraph);
