import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { getLexikToken } from '../../Helpers/provider';
import { API_KEYS } from '../../constants';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '../../Components/Buttons/CustomButton';
import FormControl from '@material-ui/core/FormControl';
import { useQuery } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        alignItems: 'flex-start !important',
        '& .MuiOutlinedInput-input': {
            padding: "10px 14px",
        },
        '& .MuiOutlinedInput-root': {
            marginRight: "1rem",
            marginTop: '1rem',
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        },
        [theme.breakpoints.down('ms')]: {
            flexDirection: 'column'
        },
    },
    button: {
        marginTop: '1rem',
    }
}));

const ReviewDownloadPdfButton = props => {

    const classes = useStyles()

    const menuItems = useQuery({
        type: 'getOne',
        resource: 'reputation/card/pdf-report-dropdown-options',
    });

    const [selectedRT, setSelectedRT] = useState('all');

    useEffect(() => {
        if (menuItems?.data) {
            let availableOptions = []
            Object.values(menuItems.data).map(menuItem => {
                if (typeof menuItem === 'object' && 'value' in menuItem) {
                    availableOptions = [...availableOptions, menuItem.value]
                }
            }) 
            if (availableOptions.length && !availableOptions.includes('all')) setSelectedRT(availableOptions[0])     
        }
    }, [menuItems])

    const onSelectChange = ({ target }) => {
        setSelectedRT(target.value);
    };

    if (!menuItems.data) return false;

    const { hasReviewPdfPermission } = props;

    if (!hasReviewPdfPermission) return null;

    const downloadPdf = () => {
        const lexikToken = getLexikToken();

        window.open(
            `${API_KEYS.api_url}/reviews/export/pdf?reviewtype=${selectedRT}&lexiktoken=${lexikToken}`,
            '_blank'
        );
    };

    return (
        <div className={classes.wrapper}>
            <Select
                variant="outlined"
                label={null}
                value={selectedRT}
                onChange={onSelectChange}
                inputProps={{
                    name: 'certificateExcellencePDF',
                    id: 'certificateExcellencePDF',
                }}>
                {Object.values(menuItems.data).map(menuItem => {
                    if (typeof menuItem === 'object' && 'value' in menuItem) {
                        return   (
                            <MenuItem key={menuItem.value} value={menuItem.value}>
                                {menuItem.text}
                            </MenuItem>
                        )
                    }
                    return null;
                }
                
              )}
            </Select>
            <Button className={classes.button} onClick={downloadPdf} variant="contained" color="tertiary">
                Download
            </Button>
        </div>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        hasReviewPdfPermission: Boolean(_.get(basicInfo, 'permissions.reputation_review_pdf')),
    };
};

export default connect(mapStateToProps)(ReviewDownloadPdfButton);
