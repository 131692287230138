import React from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { showNotification } from 'react-admin';
import { useFormState } from 'react-final-form';
import { Button } from '@material-ui/core';
import dataProvider from '../../../dataProvider';
import * as analytics from '../../../analytics';

const hasRequiredFields = values => {
    const type = _.get(values, 'type');
    const fees = _.get(values, 'fees', []);

    let feesComplete = true;
    if (fees && fees.length) {
        fees.map(fee => {
            if (!fee.subType) {
                feesComplete = false
            }
        })
    }

    if (
        !['investment_and_pension_with_financial_planning', 'investment_and_pension'].includes(type)
    ) {
        return false;
    }

    if (fees.length < 1 || !feesComplete) {
        return false;
    }

    return true;
};

const FeesCreateRegisterNextButton = ({ onSuccess }) => {
    const dispatch = useDispatch();
    const formValues = useFormState().values;

    const handleClick = () => {
        if (!hasRequiredFields(formValues)) {
            dispatch(showNotification('ra.validation.required', 'warning'));

            return;
        }

        dataProvider('CREATE', 'fees', { data: formValues })
            .then(() => {
                analytics.track('Clicked step 2', {
                    category: 'Fee sign up page',
                });

                onSuccess();
            })
            .catch(error => dispatch(showNotification(error.message, 'warning')));
    };

    return (
        <Button label="Next" onClick={handleClick} variant="contained" color="secondary">
            Next
        </Button>
    );
};

export default FeesCreateRegisterNextButton;
