import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    BarChart,
    ResponsiveContainer,
    Label,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
} from 'recharts';
import { XAxisTick, YAxisTick } from './AxisTick';
import { FormSectionTitle } from '../Typography/Headlines';
import { palette } from '../../MyTheme';

import Card from '@material-ui/core/Card';
import withWidth from '@material-ui/core/withWidth';
import withStyles from '@material-ui/core/styles/withStyles';

const propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.array,
};

const styles = theme => ({
    title: {
        marginTop: 0,
    },
    containerHeight: {
        position: 'relative',
        height: theme.spacing(40),

        [theme.breakpoints.down('xs')]: {
            overflowX: 'scroll',
        },
    },
});

const BarChartComponent = ({ classes, disabled, data = [], ...props }) => {
    return (
        <Card className={classes.containerHeight}>
            <FormSectionTitle title={props.title} className={classes.title} />
            <ResponsiveContainer
                width={props.width === 'xs' ? 500 : '100%'}
                height="100%"
            >
                <BarChart
                    data={data}
                    maxBarSize={80}
                    margin={{
                        top: 24,
                        right: 0,
                        left: 0,
                        bottom: 80,
                    }}
                >
                    <XAxis
                        dataKey={_.get(props, ['xAxis', 'dataKey'], '')}
                        tick={<XAxisTick disabled={disabled} />}
                    >
                        <Label
                            value={_.get(props, ['xAxis', 'label'], '???')}
                            position="bottom"
                            offset={0}
                        />
                    </XAxis>
                    <YAxis
                        dataKey={_.get(props, ['yAxis', 'dataKey'], '')}
                        tick={<YAxisTick disabled={disabled} />}
                    >
                        <Label
                            value={_.get(props, ['yAxis', 'label'], '???')}
                            position="insideLeft"
                            angle={-90}
                            style={{ textAnchor: 'middle' }}
                        />
                    </YAxis>
                    {!disabled && <Tooltip {...props.tooltip} />}
                    <Bar
                        dataKey={props.dataKey}
                        fill={
                            disabled
                                ? palette.tertiary.dark
                                : palette.secondary.dark
                        }
                    />
                </BarChart>
            </ResponsiveContainer>
        </Card>
    );
};

BarChartComponent.propTypes = propTypes;

export default withStyles(styles)(withWidth()(BarChartComponent));
