import React from 'react';
import cn from 'classnames';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    itemLabel: {
        color: theme.palette.secondary.dark,
    },
    itemValue: {
        whiteSpace: 'pre-wrap',
    },
    itemValueMessage: {
        marginTop: '1em',
        display: 'block',
    },
}));

const ViewEnquiryDetailItem = ({ label, value }) => {
    const classes = useStyles();

    return (
        <Typography>
            <span className={classes.itemLabel}>
                <b>{`${label}: `}</b>
            </span>
            <span
                className={cn({
                    [classes.itemValue]: true,
                    [classes.itemValueMessage]: label === 'Message',
                })}
            >
                {value}
            </span>
        </Typography>
    );
};

export default ViewEnquiryDetailItem;
