import React, { useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { useQueryWithStore, usePermissions } from 'react-admin';
import WelcomePanel from './DashboardV2/DashboardComponents/WelcomePanel';
import { showSidebar, fetchBasicInfo } from '../state/actions';
import { Link } from '../Components/Links';
import { CONTACT } from '../constants/various';
import { PLAN } from '../constants';
import * as analytics from '../analytics';

import GuideActionsCard from './GuideActionsCard';
import DashboardRow from './DashboardV2/DashboardComponents/DashboardRow';
import DashboardReputationProgressCard from './DashboardV2/Cards/DashboardReputationProgressCard';
import DashboardRowTitle from './DashboardV2/DashboardComponents/DashboardRowTitle';
import DashboardStatistics from './DashboardV2/StatisticComponents/DashboardStatistics';
import DashboardReviewRecencyRankCard from './DashboardV2/Cards/DashboardReviewRecencyRankCard';
import DashboardReputationToolRankCard from './DashboardV2/Cards/DashboardReputationToolUsageRankCard';
import DashboardReferrerInfluencedRow from './DashboardV2/Cards/DashboardReferrerInfluencedRow';
import OnboardingBanner from './OnboardingBanner/OnBoardingBanner';

import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import PrivateDashboard from './Elevation/PrivateDashboard';

const styles = () => ({
    container: {
        maxWidth: 1280,
    },
});

const Dashboard = props => {
    const { classes, showGuideActionsCard, showSidebar, fetchBasicInfo } = props;
    const { data } = useQueryWithStore({
        type: 'getOne',
        resource: 'dashboard',
        payload: { id: null },
    });

    const { permissions } = usePermissions();

    useEffect(() => {
        showSidebar();

        fetchBasicInfo();

        analytics.track('Viewed dashboard', { category: 'Dashboard card' });
    }, [showSidebar, fetchBasicInfo]);
    return (
        <>
            {_.includes(permissions?.professional_features, 'private_mode') ? (
                <PrivateDashboard />
            ) : (
                <>
                    <div className={classes.container}>
                        <OnboardingBanner dashboardData={data} />
                    </div>
                    <Card>
                        <CardContent>
                            <WelcomePanel data={data} />
                            <Grid container spacing={3} className={classes.container}>
                                {showGuideActionsCard && <GuideActionsCard />}
                                <DashboardStatistics dashboardData={data} />
                                {Boolean(_.get(data, 'show_rankings')) && (
                                    <DashboardRow
                                        title={
                                            <DashboardRowTitle>
                                                How you compare to other advisers:
                                            </DashboardRowTitle>
                                        }
                                        content={
                                            <Grid container spacing={3}>
                                                <Grid item md={6} sm={12}>
                                                    <DashboardReviewRecencyRankCard />
                                                </Grid>
                                                <Grid item md={6} sm={12}>
                                                    <DashboardReputationToolRankCard />
                                                </Grid>
                                            </Grid>
                                        }
                                    />
                                )}
                                <DashboardRow
                                    title={
                                        <DashboardRowTitle>
                                            Ways to improve your profile:
                                        </DashboardRowTitle>
                                    }
                                    content={<DashboardReputationProgressCard />}
                                />
                                <DashboardReferrerInfluencedRow />
                                <DashboardRow
                                    title={
                                        <DashboardRowTitle>
                                            Welcome to your new dashboard!
                                        </DashboardRowTitle>
                                    }
                                    content={
                                        <Typography variation="body1">
                                            We're continually working to bring you useful
                                            information on your VouchedFor account, to help you get
                                            the most of your membership. Please note this
                                            information is only visible to you. We'd love your
                                            feedback - just email{' '}
                                            <Link href={`mailto:${CONTACT.EMAIL}`}>
                                                {CONTACT.EMAIL}
                                            </Link>{' '}
                                            or call us on{' '}
                                            <Link href={`tel:${CONTACT.PHONE_NUMBER}`}>
                                                {CONTACT.PHONE_NUMBER}
                                            </Link>
                                        </Typography>
                                    }
                                />
                            </Grid>
                        </CardContent>
                    </Card>
                </>
            )}
        </>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        onStarterPlan: basicInfo.plan === PLAN.STARTER,
        showGuideActionsCard: false,
    };
};

const actions = {
    showSidebar,
    fetchBasicInfo,
};

export default connect(
    mapStateToProps,
    actions
)(withStyles(styles)(Dashboard));
