import React from 'react';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    info: {
        fontSize: theme.spacing(2.75),
        color: theme.palette.error.main,
    },
}));

const ReviewInviteProspectInfo = () => {
    const classes = useStyles();
    return (
        <div>
            <Typography variant="body1" color="textPrimary">
                <span className={classes.info}><strong>Prospects are not clients yet;</strong></span> but you have held an introductory meeting with them in the last 6 months. Covering their situation and goals, how you can help and your fees.
            </Typography>
            <Typography variant="body1" color="textPrimary">
                A Prospect Review allows you to find out:
                <ul>
                    <li>whether a prospect will use your services in the future</li>
                    <li>how their first experience with you was</li>
                    <li>whether they understood all your services and how you can help them</li>
                </ul>
            </Typography>
        </div>
    );
};

export default ReviewInviteProspectInfo;
