export const SURVEY_TYPE = {
    REVIEW: 'review',
    FIRST_IMPRESSION: 'first_impression',
};

export const STATE = {
    NOT_VERIFIED: 2,
    VERIFIED_BY_PROFESSIONAL: 5,
    VERIFIED_BY_VOUCHEDFOR: 6,
    VERIFIED_CLIENT: 8,
    DISPUTED_CLIENT: 9,
    CONTENT_ABUSE: 10,
    VERIFIED_FIRST_IMPRESSION: 12,
    UNVERIFIED_FIRST_IMPRESSION: 13,
};

export const FUTURE_USE_CHOICES = [
    {id: 2000, name: 'Yes'},
    {id: 2001, name: 'No'},
    {id: 2002, name: 'Maybe'},
];

export const showViewEditButton = state => {
    return (
        [
            STATE.VERIFIED_BY_PROFESSIONAL,
            STATE.VERIFIED_BY_VOUCHEDFOR,
            STATE.VERIFIED_CLIENT,
            STATE.CONTENT_ABUSE,
            STATE.VERIFIED_FIRST_IMPRESSION,
        ].indexOf(state) !== -1
    );
};

export const onWorkflowStart = (record = {}) => {
    if (record === {}) return false;

    const { professional_next_states } = record;

    if (professional_next_states) {
        return (
            (professional_next_states.includes(STATE.VERIFIED_BY_PROFESSIONAL) &&
            professional_next_states.includes(STATE.DISPUTED_CLIENT)) || (professional_next_states.includes(STATE.VERIFIED_FIRST_IMPRESSION) &&
            professional_next_states.includes(STATE.DISPUTED_CLIENT))
        );
    }

    return false;
};
